const themes = {
  "DATAINTELL": {
    color: "#0e5789",
    logo: "/CloudSoda.svg"
  },
  "SCALE_LOGIC": {
    color: "#000",
    logo: "/CloudSoda.svg"
  }
}

const themeId = window._env_.REACT_THEME_ID;
export const ACTIVE_THEME = themes[themeId] ? themes[themeId] : themes["DATAINTELL"];