import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";

function WizardModal({
  children,
  stepIndex,
  stepsLength,
  showWizard,
  title,
  subTitle,
  leftButtonName,
  rightButtonName,
  navigateSteps,
  disableNextStep,
  isProcessing,
  onWizardExit,
  showFooter = true,
  maxWidth,
  isCustomStep = false,
}) {
  return (
    <Modal
      toggle={isProcessing ? null : onWizardExit}
      isOpen={showWizard}
      style={{ maxWidth: maxWidth ? maxWidth : "" }}
    >
      <ModalHeader toggle={isProcessing ? null : onWizardExit}>
        <div>
          <h5>{title}</h5>
          <h6 style={{ color: "gray", marginBottom: "0px" }}>
            {subTitle} — Step {stepIndex}
            {isCustomStep && stepIndex === 1 ? "" : `/${stepsLength}`}
          </h6>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {showFooter && (
        <>
          <ModalFooter>
            <Button onClick={navigateSteps} disabled={isProcessing}>
              {leftButtonName}
            </Button>
            <Button
              color="primary"
              onClick={navigateSteps}
              disabled={disableNextStep || isProcessing}
            >
              {isProcessing ? <Spinner size="sm" /> : rightButtonName}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default WizardModal;
