import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function GeneralError({ customErrorMessage, apiErrorMessage = null, iconSize = null }) {
    const [showErrorDetails, setShowErrorDetails] = useState(false);

    return (
        <div className="d-flex justify-content-center align-items-center p-3" style={{ backgroundColor: "#ffe4e4" }}>
            <FontAwesomeIcon size={iconSize ? iconSize : "1x"} icon={faExclamationTriangle} style={{ marginRight: "15px" }} />
            {
                apiErrorMessage ?
                    <div>
                        <div className="mb-2">{customErrorMessage}</div>
                        <div style={{ cursor: "pointer" }} onClick={() => setShowErrorDetails(prev => !prev)}>
                            {
                                showErrorDetails ?
                                    <div>
                                        <span style={{ textDecoration: "underline", marginBottom: "10px" }}>Hide error details</span>&nbsp;&uarr;
                                        <div style={{ wordBreak: "break-all" }}>{apiErrorMessage}</div>
                                    </div> :
                                    <div>
                                        <span style={{ textDecoration: "underline", marginBottom: "10px" }}>Show error details</span>&nbsp;&darr;
                                    </div>
                            }
                        </div>
                    </div> :
                    <div>{customErrorMessage}</div>
            }
        </div>
    );
}

export default GeneralError;