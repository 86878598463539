import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NeedHelpLink(props) {
  const {
    hasTopDivider,
    linkText,
    linkHref,
    hasBottomDivider
  } = props;

  return (
    <>
      {hasTopDivider && <hr />}
      <span>
        <FontAwesomeIcon icon={faBook} className="me-2" />
        Need Help? Check Out the <a className="text-decoration-none" href={linkHref} target="_blank">{linkText}</a> Guide.
      </span>
      {hasBottomDivider && <hr />}
    </>
  );
}