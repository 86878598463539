import { useEffect, useState } from "react";
import StorageManagerCredentialsForm from "./StorageManagerCredentialsForm";
import TableActions from "../../../components/TableActions";
import TableResult from "../../../components/TableResult";
import ConfirmModal from "../../../components/ConfirmModal";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { createStorageManagerConfig, deleteStorageManagerConfig, getAllStorageManagerConfigsPaginated, updateStorageManagerConfig } from "../../../api/storageManager";
import { setAlert } from "../../../actions/alert";

export default function StorageManagerCredentialsList(props) {
  const {
    credentials,
    setCredentials
  } = props;

  const [total, setTotal] = useState(0);
  const [showCreateCredentialsForm, setShowCreateCredentialsForm] = useState(false);
  const [showUpdateCredentialsForm, setShowUpdateCredentialsForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsDataSourceStorageManagerCreds") ? Number(window.localStorage.getItem("numberOfElementsDataSourceStorageManagerCreds")) : 10,
    page: 1
  });

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    fetchCredentials(
      pagination.page, 
      pagination.size,
      pagination.sort, 
      pagination.direction
    );
  }, [pagination.page, pagination.sort, pagination.direction, pagination.size]);

  const fetchCredentials = async (page, size, sort, direction) => {
    setIsFetching(true);
    try {
      const res = await getAllStorageManagerConfigsPaginated(dispatch, auth, page, size, sort, direction);
      setTotal(res.total);
      setCredentials(res.elements);
      setIsFetching(false);
    } catch (err) {
      const message = err.message || "Unable to fetch Quantum StorNext credentials";
      dispatch(setAlert(message, "danger"));
      setIsFetching(false);
    }
  };

  const createCredentials = async (credentials) => {
    try {
      await createStorageManagerConfig(dispatch, auth, credentials);
      await fetchCredentials(
        pagination.page, 
        pagination.size,
        pagination.sort, 
        pagination.direction
      );
      dispatch(setAlert("Quantum StorNext credentials was created successfully", "success"));
    } catch (err) {
      const message = err.message || "Unable to create Quantum StorNext credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const updateCredentials = async (id, credentials) => {
    try {
      await updateStorageManagerConfig(dispatch, auth, id, credentials);
      await fetchCredentials(
        pagination.page, 
        pagination.size,
        pagination.sort, 
        pagination.direction
      );
      dispatch(setAlert("Quantum StorNext credentials was updated successfully", "success"));
    } catch (err) {
      const message = err.message || "Unable to update Quantum StorNext credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const deleteCredentials = async (id) => {
    try {
      await deleteStorageManagerConfig(dispatch, auth, id);
      await fetchCredentials(
        pagination.page, 
        pagination.size,
        pagination.sort, 
        pagination.direction
      );
      dispatch(setAlert("Quantum StorNext credentials was deleted successfully", "success"));
    } catch (err) {
      const message = err.message || "Unable to delete Quantum StorNext credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const numberOfPages = Math.ceil(total / pagination.size);
  const credentialsTitles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Action" }
  ];

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({ ...prev, sort: newSort, direction: newDirection }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsDataSourceStorageManagerCreds", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const listFormated = credentials.map((credential) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      credential.id,
      credential.name,
      <>
        <Button
          onClick={() => {
            setShowUpdateCredentialsForm(true);
            setId(credential.id);
            setName(credential.name);
            setUsername(credential.username);
            setPassword(credential.password);
            setUrl(credential.host);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(credential.id);
            setName(credential.name);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateCredentialsForm(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  const handleCloseModal = () => {
    setShowCreateCredentialsForm(false);
    setShowUpdateCredentialsForm(false);
    setShowDeleteConfirmation(false);
    setId(null);
    setName("");
    setUsername("");
    setPassword("");
    setUrl("");
  };

  return (
    <>
      <h5>Credentials</h5>
      {isFetching ? 
        <Loading /> :
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          />
          <TableResult
            data={listFormated}
            titles={credentialsTitles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>  
      }
      <ConfirmModal
        showConfirmation={showCreateCredentialsForm}
        title="Add Quantum StorNext Credentials"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createCredentials({
            name: name,
            host: url, 
            username: username === "" ? null : username,
            password: password === "" ? null : password
          });
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
        disableRightButton={disableRightButton}
      >
        <StorageManagerCredentialsForm
          name={name}
          setName={setName}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          url={url}
          setUrl={setUrl}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateCredentialsForm}
        title="Update Quantum StorNext Credentials"
        buttonColor="primary"
        buttonText="Update"
        confirmAction={() => {
          updateCredentials(id, {
            name: name,
            host: url, 
            username: username === "" ? null : username,
            password: password === "" ? null : password
          });
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
        disableRightButton={disableRightButton}
      >
        <StorageManagerCredentialsForm
          name={name}
          setName={setName}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          url={url}
          setUrl={setUrl}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteCredentials(id);
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
      >
        Are you sure you want to delete the credentials {name}?
      </ConfirmModal>
    </>
  );
}