import React, { Component } from "react";

import ArrowUp from "./icons/ArrowUp";
import ArrowDown from "./icons/ArrowDown";
import SizeBaseButton from "./SizeBaseButton";
import CondenseButton from "./CondenseButton";

class TableResult extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.getElementFormatted = this.getElementFormatted.bind(this);
  }

  handleClick(list) {
    if (this.props.handleClick) {
      this.props.handleClick(list);
    }
  }

  getElementFormatted(element) {
    if ("Invalid date" === element || null === element) {
      return "N/A";
    }
    return element;
  }

  render() {
    const {
      data,
      titles,
      sort,
      direction,
      changeOrder,
      setTargetTableRowIndex,
      hasSizeBaseButton,
      hasCondenseButton
    } = this.props;

    const lineFormated = (list, action) =>
      list.map((element, index) => {
        if (action && index > 0) {
          return (
            <td
              onClick={() => this.handleClick(action)}
              key={index}
              className="text-left"
            >
              {this.getElementFormatted(element)}
            </td>
          );
        } else {
          return (
            <td key={index} className="text-left">
              {this.getElementFormatted(element)}
            </td>
          );
        }
      });

    const listing = data.map((lineObject, index) => {
      if (lineObject.options.linkData) {
        lineObject.style.cursor = "pointer";
      }

      if (lineObject.options.actionColumn) {
        return (
          <tr
            style={lineObject.style}
            key={index}
            onMouseOver={() =>
              setTargetTableRowIndex ? setTargetTableRowIndex(index) : {}
            }
            onMouseLeave={() =>
              setTargetTableRowIndex ? setTargetTableRowIndex(null) : {}
            }
          >
            {lineFormated(lineObject.columnData, lineObject.options.linkData)}
          </tr>
        );
      } else {
        return (
          <tr
            style={lineObject.style}
            key={index}
            onMouseOver={() =>
              setTargetTableRowIndex ? setTargetTableRowIndex(index) : {}
            }
            onMouseLeave={() =>
              setTargetTableRowIndex ? setTargetTableRowIndex(null) : {}
            }
            onClick={() => this.handleClick(lineObject.options.linkData)}
          >
            {lineFormated(lineObject.columnData)}
          </tr>
        );
      }
    });

    return (
      <>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                {titles.map((title, index) => {
                  if (title.sort) {
                    let columnName = title.name;
                    if (title.sort === sort) {
                      columnName =
                        direction === "desc" ? (
                          <span>
                            {columnName} <ArrowDown />
                          </span>
                        ) : (
                          <span>
                            {columnName} <ArrowUp />
                          </span>
                        );
                    }
                    return (
                      <th
                        style={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          minWidth: (title.name === "Path" || title.name === "File Name" || title.name === "Folder Name") ? window.innerWidth * 0.12 : "",
                          borderColor: "inherit",
                          borderBottomWidth: "2px",
                          borderTopWidth: "1px"
                        }}
                        onClick={() => changeOrder(title.sort)}
                        key={index}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {columnName}
                        </span>{" "}
                        {hasSizeBaseButton && title.name === "Size" && (
                          <SizeBaseButton marginLeft={"5px"} />
                        )}
                        {hasCondenseButton && (title.name === "Path" || title.name === "File Name" || title.name === "Folder Name") && (
                          <CondenseButton condenseType={title.name === "Path" ? "PATH" : "NAME"} marginLeft={"5px"} />
                        )}
                      </th>
                    );
                  } else {
                    return (
                      <th 
                        style={{ 
                          whiteSpace: "nowrap",
                          borderColor: "inherit",
                          borderBottomWidth: "2px",
                          borderTopWidth: "1px"
                        }} 
                        key={index}
                      >
                        {title.name}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>{listing}</tbody>
          </table>
        </div>
      </>
    );
  }
}

export default TableResult;
