import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";

function ListFileTags(props) {
  const { tags } = props;

  return (
    <>
      {tags.length > 0 ?
        tags.sort().map(tag => {
          return (
            <span key={tag} style={{ fontSize: "1rem" }}>
              <Badge color="secondary" className="me-1">
                <FontAwesomeIcon className="me-1" icon={faTag} />{tag}
              </Badge>
            </span>
          );
        }) :
        <span>No tags found</span>
      }
    </>
  );
}

export default ListFileTags;
