// === Actions ===
const REQUEST_SET_SIZE_BASE_VALUE = "REQUEST_SET_SIZE_BASE_VALUE";
const REQUEST_SET_IS_NAME_CONDENSED = "REQUEST_SET_IS_NAME_CONDENSED";
const REQUEST_SET_IS_PATH_CONDENSED = "REQUEST_SET_IS_PATH_CONDENSED";

// === Reducers ===
export default function userSettings(
  state = {
    baseValue: window.localStorage.getItem("sizeBase") ?? "2",
    isNameCondensed: window.localStorage.getItem("isNameCondensed") ?? "false",
    isPathCondensed: window.localStorage.getItem("isPathCondensed") ?? "false"
  },
  action
) {
  switch (action.type) {
    case REQUEST_SET_SIZE_BASE_VALUE:
      return Object.assign({}, state, {
        baseValue: action.baseValue,
      });
    case REQUEST_SET_IS_NAME_CONDENSED:
      return Object.assign({}, state, {
        isNameCondensed: action.isNameCondensed,
      });
    case REQUEST_SET_IS_PATH_CONDENSED:
      return Object.assign({}, state, {
        isPathCondensed: action.isPathCondensed,
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestSetSizeBaseValue(baseValue) {
  return {
    type: REQUEST_SET_SIZE_BASE_VALUE,
    baseValue: baseValue
  };
}

function requestSetIsNameCondensed(isNameCondensed) {
  return {
    type: REQUEST_SET_IS_NAME_CONDENSED,
    isNameCondensed: isNameCondensed
  };
}

function requestSetIsPathCondensed(isPathCondensed) {
  return {
    type: REQUEST_SET_IS_PATH_CONDENSED,
    isPathCondensed: isPathCondensed
  };
}

// === Side Effects ===
export function setSizeBaseValue(baseValue) {
  window.localStorage.setItem("sizeBase", baseValue);
  return (dispatch) => dispatch(requestSetSizeBaseValue(baseValue));
}

export function setIsNameCondensed(isNameCondensed) {
  window.localStorage.setItem("isNameCondensed", isNameCondensed);
  return (dispatch) => dispatch(requestSetIsNameCondensed(isNameCondensed));
}

export function setIsPathCondensed(isPathCondensed) {
  window.localStorage.setItem("isPathCondensed", isPathCondensed);
  return (dispatch) => dispatch(requestSetIsPathCondensed(isPathCondensed));
}
