import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  createProject,
  searchProjetPaths,
  resetProjectErrors,
} from "../ducks/project";
import { fetchVolumes } from "../actions";

import ProjectForm from "./components/ProjectForm";
import Loading from "../components/Loading";
import { setAlert } from "../actions/alert";
import { useNavigate } from "react-router-dom";

function NewProject(props) {
  const {
    projectPathSuggestions, 
    projectHandling, 
    volumeList, 
    dispatch
  } = props;

  const [hasBeenCreated, setHasBeenCreated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetProjectErrors());
    dispatch(fetchVolumes());
  }, []);

  useEffect(() => {
    if (hasBeenCreated && !projectHandling.isFetching && projectHandling.isValid) {
      navigate(`/project/${projectHandling.id}`);
      setHasBeenCreated(false);
    }
  }, [projectHandling.isFetching]);

  const saveProject = (name, description, pathList, allocatedSize) => {
    dispatch(createProject(name, description, pathList, allocatedSize));
    setHasBeenCreated(true);
  }

  const searchPathSuggestions = (name) => {
    dispatch(searchProjetPaths(name));
  }

  const goBack = () => {
    navigate(-1);
  }
    
  return (
    <>
      {projectHandling.isFetching && <Loading />}
      <div
        style={{
          visibility: projectHandling.isFetching ? "hidden" : "visible",
        }}
      >
        <h1 className="page-header">New Project</h1>
        <ProjectForm
          setAlert={(message, type, timer) =>
            dispatch(setAlert(message, type, timer))
          }
          volumeList={volumeList}
          projectPathSuggestions={projectPathSuggestions.items}
          saveProject={saveProject}
          goBack={goBack}
          searchPathSuggestions={searchPathSuggestions}
          isFetching={projectPathSuggestions.isFetching}
          errors={projectHandling.errors}
        />
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { projectPathSuggestions, projectHandling, volumes } = state;

  const volumeList = volumes.items;
  return {
    projectPathSuggestions,
    projectHandling,
    volumeList,
  };
}

export default connect(mapStateToProps)(NewProject);
