import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SubSection from "./components/SubSection";
import StoragePriceList from "./components/StoragePriceList";
import ServerList from "./components/ServerList";
import {
  fetchServerList,
  createServer,
  updateServer,
  deleteServer,
} from "../ducks/server";
import {
  fetchStoragePriceList,
  createStoragePriceSetting,
  updateStoragePriceSetting,
  deleteStoragePriceSetting,
} from "../ducks/storagePrice";
import Buckets from "./components/Buckets";
import Archiwares from "./components/Archiwares";
import PropTypes from "prop-types";
import {
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HasBundle from "../validation/HasBundle";
import {
  ARCHIWARE_BUNDLE,
  ICONIK_BUNDLE,
  SODA_BUNDLE,
  STORAGE_MANAGER_BUNDLE,
} from "../constants/bundles";
import DataSourceSelection from "./dataSource/DataSourceSelection";
import VolumeWizard from "./dataSource/volume/VolumeWizard";
import BucketWizard from "./dataSource/bucket/BucketWizard";
import ArchiwareWizard from "./dataSource/archiware/ArchiwareWizard";
import SodaIntegration from "./dataSource/soda/SodaIntegration";
import OnPremises from "./components/OnPremises";
import StorageManagerIntegration from "./dataSource/storageManager/StorageManagerIntegration";
import IconikIntegration from "./dataSource/iconik/IconikIntegration";
import DellPowerScale from "./dataSource/dellPowerScale/DellPowerScale";
import BetaBadge from "../components/beta/BetaBadge";

Data.propTypes = {
  dispatch: PropTypes.func,
  servers: PropTypes.object,
  storagePrices: PropTypes.object,
};

function Data(props) {
  const { dispatch, servers, storagePrices } = props;

  const [showWizard, setShowWizard] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [showDataSourceWizardsSelection, setShowDataSourceWizardsSelection] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const closeDataSourceSelection = () => {
    setShowDataSourceWizardsSelection(false);
  };

  const manageDataSelectionAndTheirWizard = (wizardName) => {
    setDataSource(wizardName);
    setShowDataSourceWizardsSelection(false);
    setShowWizard(true);
  };

  const openChosenWizardFromSection = (wizardName) => {
    setDataSource(wizardName);
    setShowWizard(true);
  };

  useEffect(() => {
    dispatch(fetchServerList());
    dispatch(fetchStoragePriceList());
  }, [dispatch]);

  return (
    <>
      <div className="d-flex mb-2">
        <h1 className="page-header">Data Source Settings</h1>
        <Button
          id="AddNewDataRessourceButton"
          onClick={() => setShowDataSourceWizardsSelection(true)}
          color="success"
          className="ms-auto h-75"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <UncontrolledTooltip
          placement="auto"
          target="AddNewDataRessourceButton"
        >
          Add a new data source
        </UncontrolledTooltip>
      </div>
      <SubSection
        title={"On-Premises Storage"}
        subTitle={
          "Add, edit or remove On-Premises storage to be scanned by DataIntell."
        }
        isOpen={true}
      >
        <OnPremises
          dispatch={dispatch}
          serverList={servers.items}
          storagePriceList={storagePrices.items}
          openChosenWizardFromSection={openChosenWizardFromSection}
        />
      </SubSection>
      <hr />
      <SubSection
        title={"Cloud Storage"}
        subTitle={
          "Add, edit or remove Cloud storage to be scanned by DataIntell."
        }
        isOpen={false}
      >
        <Buckets
          dispatch={dispatch}
          storagePriceList={storagePrices.items}
          openChosenWizardFromSection={openChosenWizardFromSection}
        />
      </SubSection>
      <hr />
      <SubSection
        title={<>Dell PowerScale Storage <BetaBadge /></>}
        subTitle={
          "Add, edit or remove Dell PowerScale storage to be scanned by DataIntell."
        }
        isOpen={true}
      >
        <DellPowerScale />
      </SubSection>
      <hr />
      <SubSection
        title={"Storage Type"}
        subTitle={"Add, edit or remove storage type."}
        isOpen={false}
      >
        <StoragePriceList
          storagePrices={storagePrices.items}
          errors={storagePrices.errors}
          createStoragePriceSetting={(description, currencySymbol, price) =>
            dispatch(
              createStoragePriceSetting(description, currencySymbol, price)
            )
          }
          updateStoragePriceSetting={(id, description, currencySymbol, price) =>
            dispatch(
              updateStoragePriceSetting(id, description, currencySymbol, price)
            )
          }
          deleteStoragePriceSetting={(id) =>
            dispatch(deleteStoragePriceSetting(id))
          }
        />
      </SubSection>
      <hr />
      <SubSection
        title={"Servers"}
        subTitle={"Configure multiple locations for scanners."}
        isOpen={false}
      >
        <ServerList
          servers={servers.items}
          errors={servers.errors}
          createServerFunc={(name) => dispatch(createServer(name))}
          updateServerFunc={(id, name) => dispatch(updateServer(id, name))}
          deleteServerFunc={(id) => dispatch(deleteServer(id))}
        />
      </SubSection>
      <hr />
      <HasBundle
        requiredBundle={ARCHIWARE_BUNDLE}
        message="Unlock Archiware P5 Bundle"
        hasOverlay={true}
      >
        <SubSection
          title={"Archiware P5 Integration"}
          subTitle={
            "Configure Archive Index and launch manual scans. Configure storage mapping for P5 Archive & Restore."
          }
          isOpen={false}
        >
          <HasBundle requiredBundle={ARCHIWARE_BUNDLE}>
            <Archiwares
              dispatch={dispatch}
              serverList={servers.items}
              storagePriceList={storagePrices.items}
              openChosenWizardFromSection={openChosenWizardFromSection}
            />
          </HasBundle>
        </SubSection>
      </HasBundle>
      <hr />
      <HasBundle
        requiredBundle={SODA_BUNDLE}
        message="Unlock CloudSoda Bundle"
        hasOverlay={true}
      >
        <SubSection
          title={"CloudSoda Integration"}
          subTitle={
            "Configure your CloudSoda basic configuration and the storage mapping."
          }
          isOpen={false}
        >
          <HasBundle requiredBundle={SODA_BUNDLE}>
            <SodaIntegration dispatch={dispatch} />
          </HasBundle>
        </SubSection>
      </HasBundle>
      <hr />
      <HasBundle
        requiredBundle={STORAGE_MANAGER_BUNDLE}
        message="Unlock Quantum StorNext Integration"
        hasOverlay={true}
      >
        <SubSection
          title={"Quantum StorNext Integration"}
          subTitle={
            "Configure your StorNext credentials and the storage mappings."
          }
          isOpen={false}
        >
          <HasBundle requiredBundle={STORAGE_MANAGER_BUNDLE}>
            <StorageManagerIntegration />
          </HasBundle>
        </SubSection>
      </HasBundle>
      <hr />
      <HasBundle
        requiredBundle={ICONIK_BUNDLE}
        message="Unlock Iconik Integration"
        hasOverlay={true}
      >
        <SubSection
          title={"Iconik Integration"}
          subTitle={"Configure your Iconik basic configuration."}
          isOpen={false}
        >
          <HasBundle requiredBundle={ICONIK_BUNDLE}>
            <IconikIntegration />
          </HasBundle>
        </SubSection>
      </HasBundle>

      <Modal
        toggle={closeDataSourceSelection}
        isOpen={showDataSourceWizardsSelection}
      >
        <ModalHeader toggle={closeDataSourceSelection}>
          Select a Data Source
        </ModalHeader>

        <ModalBody>
          <DataSourceSelection
            manageDataSelectionAndTheirWizard={
              manageDataSelectionAndTheirWizard
            }
          />
        </ModalBody>
      </Modal>

      {
        {
          volume: (
            <VolumeWizard
              showWizard={showWizard}
              setShowWizard={setShowWizard}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          ),
          bucket: (
            <BucketWizard
              showWizard={showWizard}
              setShowWizard={setShowWizard}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          ),
          archiware: (
            <ArchiwareWizard
              showWizard={showWizard}
              setShowWizard={setShowWizard}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
            />
          ),
        }[dataSource]
      }
    </>
  );
}

function mapStateToProps(state) {
  const { servers, storagePrices } = state;
  return {
    servers,
    storagePrices,
  };
}

export default connect(mapStateToProps)(Data);
