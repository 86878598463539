import { fetchWithAuth } from "../actions/requestHelper";

export const search = async (
  dispatch,
  auth,
  indexingDate,
  searchFilters,
  page = 1,
  size = 10,
  sort = "size",
  direction = "ASC"
) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/search?indexingDate=${indexingDate}&page=${page}&size=${size}&sort=${sort}&direction=${direction}`,
    auth.accessToken,
    "POST",
    JSON.stringify(searchFilters)
  );
};

export const searchSize = async (
  dispatch,
  auth,
  indexingDate,
  searchFilters
) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/search/sizes?indexingDate=${indexingDate}`,
    auth.accessToken,
    "POST",
    JSON.stringify(searchFilters)
  );
};