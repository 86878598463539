import { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
  Input,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import CircleChart from "../../components/CircleChart";
import Loading from "../../components/Loading";
import HistogramChart from "../../components/HistogramChart";
import { faChartBar, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PDFChart from "../../components/PDFChartButton";
import { CHART_COLORS } from "../../constants/chartColors";
import { useSelector } from "react-redux";
import { formatSize } from "../../components/FormatSize";

const DATA_SIZE_OPTIONS = [
  <option key="five" value={5}>
    5
  </option>,
  <option key="ten" value={10}>
    10
  </option>,
  <option key="fifteen" value={15}>
    15
  </option>,
  <option key="twenty" value={20}>
    20
  </option>,
  <option key="twentyFive" value={25}>
    25
  </option>,
];

export default function ChartCard(props) {
  const {
    setRef = () => { },
    setSubtitle = () => { },
    setSizeShownData = () => { },
    setSizeItems = () => { },
    sizeOfFiles,
    title,
    elements = [],
    emptyStringValue,
    addFilterFunc,
    isFetching,
    componentId,
    defaultDataSize = 10,
    isCircleChartShownByDefault = true,
    hasDataSizePicker = true,
  } = props;

  const [dataSize, setDataSize] = useState(defaultDataSize);
  // State to keep track of shown vs hidden values so we can adjust the unit
  const [shownValues, setShownValues] = useState([]);
  const [isCircleChart, setIsCircleChart] = useState(
    isCircleChartShownByDefault
  );

  const barChartRef = useRef();
  const circleChartRef = useRef();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  useEffect(() => {
    setShownValues(elements);
  }, [elements]);

  useEffect(() => {
    if (isCircleChart) {
      setRef(circleChartRef);
    } else {
      setRef(barChartRef);
    }
  }, [isCircleChart]);

  useEffect(() => {
    setSubtitle(filesizeObject.unit);
  }, [shownValues]);
  useEffect(() => {
    setSizeItems(dataSize);
  }, [dataSize]);
  useEffect(() => {
    setSizeShownData(
      elements.slice(0, dataSize).reduce((a, b) => a + b.size, 0)
    );
  }, [dataSize, elements]);

  const filesizeObject = formatSize(
    shownValues.length > 0
      ? shownValues.reduce((a, b) => a + b.size, 0) / shownValues.length
      : 0,
    baseSize,
    null,
    "object"
  );
  const data = elements
    .slice(0, dataSize)
    .map(
      (element) =>
        formatSize(element.size, baseSize, filesizeObject.exponent, "object")
          .value
    );

  const dataSizeValues = elements
    .slice(0, dataSize)
    .map((element) => element.size);

  const dataCount = elements.slice(0, dataSize).map((element) => element.count);
  const labels = elements
    .slice(0, dataSize)
    .map((element) =>
      element.field === "" ? emptyStringValue : element.field
    );

  const getDataSizePicker = () => {
    return (
      <Input
        type="select"
        value={dataSize}
        onChange={(e) => setDataSize(e.target.value)}
        bsSize="sm"
      >
        {DATA_SIZE_OPTIONS}
      </Input>
    );
  };

  const updateFilesize = (text, isVisible) => {
    if (null !== text) {
      const validateText = text === emptyStringValue ? "" : text;
      const value = elements.find((value) => validateText === value.field);
      if (isVisible) {
        setShownValues((prev) => prev.concat(value));
      } else {
        setShownValues((prev) => [
          ...prev.filter((prevValue) => prevValue !== value),
        ]);
      }
    }
  };

  const toggleChart = () => {
    setShownValues([...elements]);
    setIsCircleChart((prev) => !prev);
  };

  const getChart = () => {
    if (isCircleChart) {
      return (
        <CircleChart
          onClick={addFilterFunc}
          onLegendClick={updateFilesize}
          data={data}
          dataCount={dataCount}
          title={title}
          labels={labels}
          unit={filesizeObject.unit}
          color={CHART_COLORS}
          customLegendId={`legend-${componentId}`}
        />
      );
    }
    return (
      <HistogramChart
        onClick={addFilterFunc}
        data={data}
        dataCount={dataCount}
        title={title}
        labels={labels}
        color={CHART_COLORS}
        hoverColor={CHART_COLORS.map((color) => color + "BD")}
        sizeOfFiles={sizeOfFiles}
        dataSizeValues={dataSizeValues}
        height={300}
        unit={filesizeObject.unit}
        showRatio
        horizontal
      />
    );
  };

  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardHeader>
          <CardTitle tag="h5">
            <b>{title}</b>
            <div className="float-end" style={{ paddingLeft: 10 }}>
              <PDFChart
                chartRef={isCircleChart ? circleChartRef : barChartRef}
                title={title}
                subTitle={filesizeObject.unit}
                sizeItems={dataSize}
                sizeOfFiles={sizeOfFiles}
                sizeShownData={elements
                  .slice(0, dataSize)
                  .reduce((a, b) => a + b.size, 0)}
              />
            </div>
            <Button
              id={componentId}
              color="primary"
              outline
              size="sm"
              className="float-end"
              onClick={toggleChart}
              style={{ marginLeft: 10 }}
            >
              <FontAwesomeIcon icon={isCircleChart ? faChartBar : faChartPie} />
            </Button>
            <UncontrolledTooltip target={componentId} placement="auto">
              Change to {isCircleChart ? "bar" : "circle"} chart
            </UncontrolledTooltip>
            {hasDataSizePicker && elements.length >= 5 ? (
              <span className="float-end">{getDataSizePicker()}</span>
            ) : null}
          </CardTitle>
          <CardSubtitle tag="h6">
            <span className="mb-2 text-muted">
              Size of files ({filesizeObject.unit})
            </span>
          </CardSubtitle>
        </CardHeader>
        <CardBody>{isFetching ? <Loading /> : getChart()}</CardBody>
      </Card>
      {/* This is an hidden chart that is used for the PDF export */}
      <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
        <CircleChart
          height={300}
          width={500}
          circleChartRef={circleChartRef}
          showTotal={false}
          data={data}
          dataCount={dataCount}
          title={title}
          labels={labels}
          unit={filesizeObject.unit}
          isCustomLabel={true}
          color={CHART_COLORS}
        />
      </div>
      <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
        <HistogramChart
          histogramRef={barChartRef}
          onClick={addFilterFunc}
          data={data}
          dataCount={dataCount}
          title={title}
          labels={labels}
          color={CHART_COLORS}
          hoverColor={CHART_COLORS.map((color) => color + "BD")}
          height={500}
          width={1000}
          unit={filesizeObject.unit}
          sizeOfFiles={sizeOfFiles}
          dataSizeValues={dataSizeValues}
          showRatio
          isCustomLabel={true}
          horizontal
        />
      </div>
    </>
  );
}
