import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faSearch,
  faChartLine
} from "@fortawesome/free-solid-svg-icons";
import FolderDetails from "./FolderDetails";
import { Link } from "react-router-dom";

function FolderDetailsModal(props) {
  const {
    folder,
    showModal,
    close,
    addFilters,
    updateFolder,
    updateTags,
    volumeType
  } = props;

  return (
    <>
      {folder.path && (
        <Modal size="lg" toggle={close} isOpen={showModal}>
          <ModalHeader toggle={close}>Folder Details</ModalHeader>
          <ModalBody>
            <FolderDetails
              folder={folder}
              updateFolder={updateFolder}
              updateTags={updateTags}
              volumeType={volumeType}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              id="applyFilter"
              color="info"
              onClick={() => {
                addFilters(folder.path);
                close();
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
            <UncontrolledTooltip placement="auto" target="applyFilter">
              Search in folder
            </UncontrolledTooltip>
            <Link
              to={`/filemanagement?path=${folder.path}&volumeId=${folder.volumeId}`}
            >
              <Button
                id="goToFilemanagement"
                color="info"
              >
                <FontAwesomeIcon icon={faServer} />
              </Button>
            </Link>
            <UncontrolledTooltip placement="auto" target="goToFilemanagement">
              Show folder in File Management
            </UncontrolledTooltip>
            <Link
              to={`/folders?volumeId=${folder.volumeId}&path=${folder.path}`}
            >
              <Button
                id="goToFolderAnalytics"
                color="info"
              >
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
            </Link>
            <Button onClick={close} className="ms-auto">
              Close
            </Button>
            <UncontrolledTooltip placement="auto" target="goToFolderAnalytics">
              Show folder in Folder Analytics
            </UncontrolledTooltip>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default FolderDetailsModal;
