// basic check to see if two objects are equal
export function checkIfTwoObjectsAreEqual(obj1, obj2) {
    // check if the parameters are literal objects. Ex: {}
    const isObj1ALiteralObj = (!!obj1) && (obj1.constructor === Object);
    const isObj2ALiteralObj = (!!obj2) && (obj2.constructor === Object);

    if (!isObj1ALiteralObj || !isObj2ALiteralObj) return false;

    // check if they have the same number of keys first
    if (Object.keys(obj1).length === Object.keys(obj2).length) {
        for (const key in obj1) {
            if (obj1[key] !== obj2[key]) {
                return false
            }
        }
        return true;
    } else {
        return false;
    }
}