import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import SodaLogo from "./soda/SodaLogo";

export default function SodaActionButton({ onClickFunction }) {
  const [isIconHovered, setIsIconHovered] = useState(false);

  return (
    <>
      <span
        id="sodaActionButton"
        onClick={onClickFunction}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
      >
        <SodaLogo isIconHovered={isIconHovered} />
      </span>
      <UncontrolledTooltip target={"sodaActionButton"} placement="auto">
        Perform a CloudSoda transfer
      </UncontrolledTooltip>
    </>
  );
}
