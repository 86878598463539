import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
} from "reactstrap";
import { connect } from "react-redux";
import { validatePositiveStringInteger } from "../../utils/validateNumber";
import { validateEmail } from "../../utils/validateEmail";
import ConfirmModal from "../../components/ConfirmModal";

function EmailConfiguration(props) {
  const {
    email,
    hostname,
    username,
    from,
    port,
    tlsEnable,
    tlsRequired,
    sslEnable,
    smtpAuth,
    updateConfigurations,
    testEmailConfigurations,
  } = props;
  const serverEndpoint = `${window.location.origin}`;
  const profileUrl = `${serverEndpoint}/profile`;
  const [hostnameObj, setHostnameObj] = useState(hostname);
  const [usernameObj, setUsernameObj] = useState(username);
  const [fromObj, setFromObj] = useState(from);
  const [portObj, setPortObj] = useState(port);
  const [tlsEnableObj, setTlsEnableObj] = useState(tlsEnable);
  const [tlsRequiredObj, setTlsRequiredObj] = useState(tlsRequired);
  const [sslEnableObj, setSslEnableObj] = useState(sslEnable);
  const [smtpAuthObj, setSmtpAuthObj] = useState(smtpAuth);
  const [invalidHostname, setInvalidHostname] = useState(false);
  const [invalidPort, setInvalidPort] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidFrom, setInvalidFrom] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [isUsernameChanged, setIsUsernameChanged] = useState(false);
  const [isFromChanged, setIsFromChanged] = useState(false);
  const [isHostnameChanged, setIsHostnameChanged] = useState(false);
  const [isPortChanged, setIsPortChanged] = useState(false);

  useEffect(() => {
    hostname.value === ""
      ? setInvalidHostname(true)
      : setInvalidHostname(false);
    username.value === ""
      ? setInvalidUsername(true)
      : setInvalidUsername(false);
    port.value === "" ? setInvalidPort(true) : setInvalidPort(false);

    if (from.value !== "") {
      validateEmail(from.value) ? setInvalidFrom(false) : setInvalidFrom(true);
    } else {
      setInvalidFrom(false);
    }

    setHostnameObj(hostname);
    setUsernameObj(username);
    setFromObj(from);
    setPortObj(port);
    setTlsEnableObj({
      ...tlsEnable,
      value: tlsEnable.value === "true" ? "true" : "false",
    });
    setTlsRequiredObj({
      ...tlsRequired,
      value: tlsRequired.value === "true" ? "true" : "false",
    });
    setSslEnableObj({
      ...sslEnable,
      value: sslEnable.value === "true" ? "true" : "false",
    });
    setSmtpAuthObj({
      ...smtpAuth,
      value: smtpAuth.value === "true" ? "true" : "false",
    });
  }, [
    hostname,
    username,
    from,
    port,
    tlsEnable,
    tlsRequired,
    sslEnable,
    smtpAuth,
  ]);

  const input = () => {
    return (
      <>
        <FormGroup row>
          <Label for="hostname" sm={2}>
            Hostname
          </Label>
          <Col sm={6}>
            <Input
              id="hostname"
              name="hostname"
              type="text"
              placeholder="Ex: smtp.office365.com"
              onChange={(e) => {
                setIsHostnameChanged(true);
                setHostnameObj({ ...hostnameObj, value: e.target.value });
                setInvalidHostname(e.target.value.length === 0);
              }}
              value={hostnameObj.value}
              invalid={isHostnameChanged ? invalidHostname : false}
              valid={isHostnameChanged ? !invalidHostname : false}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Hostname can't be empty</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="username" sm={2}>
            Username
          </Label>
          <Col sm={6}>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Ex: notificationemail@email.com"
              onChange={(e) => {
                setIsUsernameChanged(true);
                setUsernameObj({ ...usernameObj, value: e.target.value });
                setInvalidUsername(e.target.value.length === 0);
              }}
              value={usernameObj.value}
              invalid={isUsernameChanged ? invalidUsername : false}
              valid={isUsernameChanged ? !invalidUsername : false}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Username can't be empty</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="from" sm={2}>
            From (optional)
          </Label>
          <Col sm={6}>
            <Input
              id="from"
              name="from"
              type="email"
              placeholder="Ex: notificationemail@email.com"
              onChange={(e) => {
                setIsFromChanged(true);
                setFromObj({ ...fromObj, value: e.target.value });

                if (e.target.value.length !== 0) {
                  setInvalidFrom(!validateEmail(e.target.value));
                } else {
                  setInvalidFrom(false);
                }
              }}
              value={fromObj.value}
              invalid={isFromChanged ? invalidFrom : false}
              valid={isFromChanged ? !invalidFrom : false}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>From must be a well-formed email address</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="password" sm={2}>
            Password
          </Label>
          <Col sm={6}>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder=""
              disabled
              value="•••••"
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="port" sm={2}>
            Port Number
          </Label>

          <Col sm={6}>
            <Input
              id="port"
              name="port"
              type="number"
              min="1"
              onChange={(e) => {
                setIsPortChanged(true);
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setPortObj({
                  ...portObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidPort(!isValueValid);
              }}
              value={portObj.value}
              invalid={isPortChanged ? invalidPort : false}
              valid={isPortChanged ? !invalidPort : false}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="smtpAuth" sm={2}>
            Enable SMTP AUTH
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="smtpAuth"
                name="smtpAuth"
                onChange={(e) => {
                  setSmtpAuthObj({
                    ...smtpAuth,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={smtpAuthObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="sslEnable" sm={2}>
            Enable SSL Protocol
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="sslEnable"
                name="sslEnable"
                onChange={(e) => {
                  setSslEnableObj({
                    ...sslEnableObj,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={sslEnableObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="tlsEnable" sm={2}>
            Enable TLS Protocol
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="tlsEnable"
                name="tlsEnable"
                onChange={(e) => {
                  setTlsEnableObj({
                    ...tlsEnableObj,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={tlsEnableObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="tlsRequired" sm={2}>
            TLS Protocol Required
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="tlsRequired"
                name="tlsRequired"
                onChange={(e) => {
                  setTlsRequiredObj({
                    ...tlsRequired,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={tlsRequiredObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>
      </>
    );
  };

  return (
    <>
      <Form>
        {input()}
        <FormGroup>
          <Button
            disabled={
              invalidHostname || invalidUsername || invalidPort || invalidFrom
            }
            onClick={() => {
              updateConfigurations([
                hostnameObj,
                usernameObj,
                fromObj,
                portObj,
                tlsEnableObj,
                tlsRequiredObj,
                sslEnableObj,
                smtpAuthObj,
              ]);
            }}
            outline
            color="primary"
          >
            Update configurations
          </Button>
          <Button
            onClick={() => setShowPasswordModal(true)}
            outline
            color="primary"
            style={{ marginLeft: "5px" }}
          >
            Update password
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            disabled={
              invalidHostname || invalidUsername || invalidPort || invalidFrom
            }
            onClick={() => {
              setShowTestModal(true);
            }}
            outline
            color="primary"
          >
            Test configurations
          </Button>
        </FormGroup>
      </Form>

      <ConfirmModal
        showConfirmation={showPasswordModal}
        title="Update password"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateConfigurations(
            [
              {
                name: "spring.mail.password",
                description: "Password for Email",
                value: password,
              },
            ],
            {
              message: `The password was updated.`,
              type: "success",
              timer: 8000
            },
            "Unable to update the password"
          );
          setShowPasswordModal(false);
          setPassword("");
        }}
        closeConfirmation={() => {
          setShowPasswordModal(false);
          setPassword("");
        }}
      >
        <Form>
          <FormGroup row>
            <Label for="password" sm={4}>
              Password
            </Label>
            <Col sm={8}>
              <Input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
          </FormGroup>
        </Form>
      </ConfirmModal>

      <ConfirmModal
        showConfirmation={showTestModal}
        title="Test configurations"
        size="xs"
        buttonColor="primary"
        buttonText="Confirm"
        confirmAction={() => {
          testEmailConfigurations(), setShowTestModal(false);
        }}
        closeConfirmation={() => {
          setShowTestModal(false);
        }}
      >
        <p>
          {" "}
          This will send a test email to your account email address:{" "}
          <strong className="me-1">{email}</strong>
        </p>
        <p>
          To change your account email address go to{" "}
          <a className="text-decoration-none" href={profileUrl}>profile</a>
        </p>
      </ConfirmModal>
    </>
  );
}

function mapStateToProps(state) {
  const { email } = state.auth;
  return {
    email,
  };
}

export default connect(mapStateToProps)(EmailConfiguration);
