import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CredentialForm from "../../components/CredentialForm";
import {
  AWS_REGIONS,
  ORACLE_OBJECT_STORAGE_REGIONS,
  B2_REGIONS,
  WASABI_REGIONS,
} from "./cloudProvidersRegions";

function BucketStep1({
  bucketObj,
  setBucketObj,
  setDisableForwardActionButton,
  showCredentialsForm,
  setshowCredentialsForm,
  bucketCredentialsObj,
  setbucketCredentialsObj,
  bucketCredentials,
  bucketCredentialsToCreate,
}) {
  const bucketErrors = useSelector(
    ({ bucketCredentials }) => bucketCredentials.errors
  );
  const isBucketTypeOracle = bucketObj.type === "ORACLE_OBJECT_STORAGE";
  const previousBucketType = useRef(bucketObj.type);

  const [inputErrors, setInputErrors] = useState({
    credentials: "",
    region: "",
    namespace: "",
  });

  const checkIfInputsAreValid = useCallback(
    (bucketObj) => {
      if (showCredentialsForm) return;

      const oracleCredentialsInputValidation =
        bucketObj.type.length > 0 &&
        bucketObj.region.length > 0 &&
        bucketObj.credentialsId.length > 0 &&
        bucketObj.namespace.length > 0;

      const otherCredentialsInputValidation =
        bucketObj.type.length > 0 &&
        (bucketObj.type === "OTHER" ||
          bucketObj.type === "AZURE" ||
          bucketObj.type === "DROPBOX" ||
          bucketObj.region.length > 0) &&
        bucketObj.credentialsId.length > 0;

      switch (bucketObj.type) {
        case "ORACLE_OBJECT_STORAGE":
          if (oracleCredentialsInputValidation) {
            setDisableForwardActionButton(false);
          } else {
            setDisableForwardActionButton(true);
          }
          break;

        default:
          if (otherCredentialsInputValidation) {
            setDisableForwardActionButton(false);
          } else {
            setDisableForwardActionButton(true);
          }
          break;
      }
    },
    [setDisableForwardActionButton, showCredentialsForm]
  );

  const handleValidation = (inputType, inputValue) => {
    switch (inputType) {
      case "credentialsId":
        if (inputValue === "") {
          setInputErrors((prev) => ({
            ...prev,
            credentials: "Credentials can't be empty",
          }));
        } else {
          setInputErrors((prev) => ({
            ...prev,
            credentials: "",
          }));
        }
        break;

      case "region":
        if (inputValue === "") {
          setInputErrors((prev) => ({
            ...prev,
            region: "Region can't be empty",
          }));
        } else {
          setInputErrors((prev) => ({
            ...prev,
            region: "",
          }));
        }
        break;

      case "namespace":
        if (inputValue === "") {
          setInputErrors((prev) => ({
            ...prev,
            namespace: "Namespace can't be empty",
          }));
        } else {
          setInputErrors((prev) => ({
            ...prev,
            namespace: "",
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    const inputType = e.target.name;

    if (inputValue === "add new credentials") {
      setshowCredentialsForm(true);
      return;
    }

    handleValidation(inputType, inputValue);

    setBucketObj((prev) => {
      const newObj = {
        ...prev,
        [inputType]: inputValue,
      };

      if (inputType === "type") {
        setbucketCredentialsObj((prev) => ({
          ...prev,
          type: inputValue,
        }));
      }

      checkIfInputsAreValid(newObj);

      return newObj;
    });
  };

  const handleBucketCredentialsInputChange = (name, value) => {
    setbucketCredentialsObj((prev) => {
      const newObj = {
        ...prev,
        [name]: value,
      };

      const credentialsExist = bucketCredentials.find(
        (credential) => credential.name === value
      );

      if (
        !!credentialsExist ||
        newObj.name.length === 0 ||
        newObj.type.length === 0 ||
        newObj.accessKey.length === 0 ||
        (newObj.type !== "AZURE" &&
          newObj.type !== "DROPBOX" &&
          newObj.privateKey.length === 0) ||
        (newObj.type === "OTHER" && newObj.url.length === 0)
      ) {
        setDisableForwardActionButton(true);
      } else {
        setDisableForwardActionButton(false);
      }

      return newObj;
    });
  };

  const preSelectInputFromCredentialsCreation = (name, value) => {
    if (name === "type" && bucketObj.type !== value) {
      setBucketObj((prev) => ({
        ...prev,
        type: value,
      }));
      previousBucketType.current = value;
    }
  };

  useEffect(() => {
    if (bucketObj.type !== previousBucketType.current) {
      previousBucketType.current = bucketObj.type;
      setBucketObj((prev) => ({
        ...prev,
        credentialsId: "",
      }));
    }
    checkIfInputsAreValid(bucketObj);
  }, [bucketObj, checkIfInputsAreValid, setBucketObj]);

  const getRegionListForBucketType = (type) => {
    switch (type) {
      case "S3":
        return AWS_REGIONS;
      case "ORACLE_OBJECT_STORAGE":
        return ORACLE_OBJECT_STORAGE_REGIONS;
      case "BACKBLAZE_B2":
        return B2_REGIONS;
      case "WASABI":
        return WASABI_REGIONS;
      default:
        return [];
    }
  };

  return (
    <>
      {showCredentialsForm ? (
        <CredentialForm
          name={bucketCredentialsObj.name}
          type={bucketCredentialsObj.type}
          bucketAccessKey={bucketCredentialsObj.accessKey}
          bucketPrivateKey={bucketCredentialsObj.privateKey}
          bucketUrl={bucketCredentialsObj.url}
          handleChange={handleBucketCredentialsInputChange}
          errors={bucketErrors}
          setDisableCreateButton={setDisableForwardActionButton}
          preSelectInputFromCredentialsCreation={
            preSelectInputFromCredentialsCreation
          }
        />
      ) : (
        <Form>
          <FormGroup>
            <Label for="type">Cloud Provider</Label>
            <Input
              name="type"
              type="select"
              value={bucketObj.type}
              onChange={handleOnChange}
            >
              <option value="S3">AWS S3</option>
              <option value="ORACLE_OBJECT_STORAGE">
                Oracle Object Storage
              </option>
              <option value="BACKBLAZE_B2">BackBlaze B2</option>
              <option value="WASABI">Wasabi</option>
              <option value="AZURE">Azure</option>
              <option value="DROPBOX">Dropbox</option>
              <option value="OTHER">Other</option>
            </Input>
            <div className="mt-2">
              {bucketObj.type === "BACKBLAZE_B2" && (
                <p>
                  <strong>Note:</strong> BackBlaze B2 buckets created prior to
                  May 4th, 2020 are not compatible. If that is the case, create
                  a new bucket.
                </p>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="credentialsId">
              Credentials
              <FontAwesomeIcon
                id="WizardAwsCredentialsLabel"
                className="ms-sm-1"
                icon={faQuestionCircle}
              />
            </Label>
            <Input
              name="credentialsId"
              type="select"
              value={bucketObj.credentialsId}
              onChange={handleOnChange}
              invalid={Boolean(inputErrors["credentials"].length)}
            >
              <option value={""}>Select credentials</option>
              {bucketCredentials
                .filter((credential) => credential.type === bucketObj.type)
                .map((credential) => {
                  const optionValue =
                    credential.id || credential.id === 0
                      ? credential.id
                      : credential.name;
                  return (
                    <option key={optionValue} value={optionValue}>
                      {credential.name}
                    </option>
                  );
                })}
              {bucketCredentialsToCreate.length ? null : (
                <option value="add new credentials">Add new credentials</option>
              )}
            </Input>
            <FormFeedback>{inputErrors["credentials"]}</FormFeedback>
            <UncontrolledTooltip
              placement="right"
              target="WizardAwsCredentialsLabel"
            >
              The credentials used to communicate with the cloud provider
            </UncontrolledTooltip>
          </FormGroup>
          {isBucketTypeOracle ? (
            <FormGroup>
              <Label>
                Namespace
                <FontAwesomeIcon
                  id="WizardNameLabel"
                  className="ms-sm-1"
                  icon={faQuestionCircle}
                />
              </Label>
              <Input
                name="namespace"
                type="text"
                placeholder={"Ex: axaxnpcrorw5"}
                value={bucketObj.namespace}
                onChange={handleOnChange}
                invalid={Boolean(inputErrors["namespace"].length)}
              />
              <FormFeedback>{inputErrors["namespace"]}</FormFeedback>
              <UncontrolledTooltip placement="right" target="WizardNameLabel">
                The namespace is found under the Bucket Information section in
                Oracle Object Storage
              </UncontrolledTooltip>
            </FormGroup>
          ) : null}
          {bucketObj.type !== "OTHER" &&
            bucketObj.type !== "AZURE" &&
            bucketObj.type !== "DROPBOX" && (
              <FormGroup>
                <Label for="region">Region</Label>
                <Input
                  name="region"
                  type="select"
                  value={bucketObj.region}
                  onChange={handleOnChange}
                >
                  <option value={""}>Select region</option>
                  {getRegionListForBucketType(bucketObj.type).map(
                    (region, index) => {
                      return (
                        <option key={bucketObj.type + " - " + index}>
                          {region}
                        </option>
                      );
                    }
                  )}
                </Input>
                <FormFeedback>{inputErrors["region"]}</FormFeedback>
              </FormGroup>
            )}
        </Form>
      )}
    </>
  );
}

export default BucketStep1;
