import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { searchFiles, getSizeOfSearchedFiles } from "../../api/file";
import { searchDirectories } from "../../api/directory";
import IconikActionReview from "./IconikActionReview";
import { setAlert } from "../../actions/alert";

export default function IconikSearchReview(props) {
  const {
    iconikActionObj,
    searchText,
    setDisableForwardActionButton,
    isDirectory,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFolders, setTotalNumberOfFolders] = useState(0);
  const [validNumberOfFolders, setValidNumberOfFolders] = useState(0);
  const [totalSizeOfFolders, setTotalSizeOfFolders] = useState(0);
  const [validSizeOfFolders, setValidSizeOfFolders] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const dispatch = useDispatch();

  const volumes = useSelector(({ volumes }) => volumes.items);
  const search = useSelector(({ search }) => search);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [volumes]);

  const getListOfValidVolumeIds = (searchVolumeIds) => {
    const prefilteredVolumes = volumes.filter(volume => volume.type === "crawl.files");

    if (searchVolumeIds) {
      const filteredVolumes = prefilteredVolumes.filter(volume => searchVolumeIds.includes(volume.id));
      setActiveVolumes(filteredVolumes.map((volume) => volume.name));
      return filteredVolumes.map((volume) => volume.id);
    }

    setActiveVolumes(prefilteredVolumes.map((volume) => volume.name));
    return prefilteredVolumes.map((volume) => volume.id);
  };

  const calculateReviewValues = async () => {
    const validVolumeIds = getListOfValidVolumeIds(search.filters.volumeIds);

    if (!validVolumeIds.length) {
      setDisableForwardActionButton(true);
      setValidNumberOfFolders(0);
      setValidSizeOfFolders(0);
      setIsFetching(false);
      return;
    }

    const searchFilters = Object.assign({}, search.filters);
    searchFilters.volumeIds = validVolumeIds;

    try {
      const searchSizeForValidFiles = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        searchFilters
      );

      const fileSearchResult = await searchFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const folderSearchResult = await searchDirectories(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const totalSearchSize = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        search.filters
      );

      setTotalNumberOfFolders(isDirectory ? search.folders.total : search.total);
      setValidNumberOfFolders(
        isDirectory ? folderSearchResult.total : fileSearchResult.total
      );
      setTotalSizeOfFolders(totalSearchSize);
      setValidSizeOfFolders(searchSizeForValidFiles);

      if (isDirectory) {
        if (folderSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      } else {
        if (fileSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      }
    } catch (e) {
      dispatch(setAlert(e.message, "danger", 7000));
    }

    setIsFetching(false);
  };

  return (
    <IconikActionReview
      isFetching={isFetching}
      activeVolumes={activeVolumes}
      totalNumberOfFolders={totalNumberOfFolders}
      validNumberOfFolders={validNumberOfFolders}
      totalSizeOfFolders={totalSizeOfFolders}
      validSizeOfFolders={validSizeOfFolders}
      isDirectory={isDirectory}
      collectionTitle={iconikActionObj.collection.title}
      storageName={iconikActionObj.storage.name}
    />
  );
}
