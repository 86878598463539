export function formatError(errors) {
  switch (errors.message) {
    case "Validation errors":
      return validationErrors(errors.subErrors);
    default:
      return {};
  }
}

export function validationErrors(subErrors) {
  const errorMap = {};
  subErrors.forEach(subError => {
    errorMap[subError.field] = `${subError.field} ${subError.message}`;
  });
  return errorMap;
}
