import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import { updateConfigurations } from "../../../ducks/configurations";
import UpdateSodaConfigurationModal from "./UpdateSodaConfigurationModal";
import ChangeSodaSecretModal from "./ChangeSodaSecretModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey } from "@fortawesome/free-solid-svg-icons";

export default function SodaConfiguration(props) {
  const { isConfigurationSet, configurations } = props;

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isChangeSecretModalOpen, setIsChangeSecretModalOpen] = useState(false);

  const dispatch = useDispatch();

  return (
    <div style={{ maxWidth: "800px" }}>
      <Card style={{ marginBottom: "1rem" }}>
        <CardHeader>
          <CardTitle tag="h6">
            <span>Configurations</span>
            <div className="float-end">
              <Button
                className="me-2"
                onClick={() => setIsChangeSecretModalOpen(true)}
                color="warning"
                id="changeSecretButton"
              >
                <FontAwesomeIcon icon={faKey} />
              </Button>
              <Button
                onClick={() => setIsUpdateModalOpen(true)}
                id="updateConfigurationButton"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <UncontrolledTooltip target="changeSecretButton">
                Change the CloudSoda client secret
              </UncontrolledTooltip>
              <UncontrolledTooltip target="updateConfigurationButton">
                Update the CloudSoda configurations
              </UncontrolledTooltip>
            </div>
          </CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Set your basic configuration for CloudSoda.
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          {isConfigurationSet ? (
            <>
              <p>
                <strong>URL:</strong> {configurations.url.value}
              </p>
              <p>
                <strong>Deployment Id:</strong>{" "}
                {configurations.deploymentId.value}
              </p>
              <p>
                <strong>Client Id:</strong> {configurations.clientId.value}
              </p>
              <p>
                <strong>Client Secret:</strong> •••••
              </p>
              <p>
                <strong>Number of Threads per Job:</strong>{" "}
                {configurations.numberOfThreads.value}
              </p>
            </>
          ) : (
            <p>CloudSoda integration is not configured properly.</p>
          )}
        </CardBody>
      </Card>
      <UpdateSodaConfigurationModal
        url={configurations.url}
        deploymentId={configurations.deploymentId}
        clientId={configurations.clientId}
        numberOfThreads={configurations.numberOfThreads}
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        updateConfigurationsFunc={(value) =>
          dispatch(updateConfigurations(value))
        }
      />
      <ChangeSodaSecretModal
        isOpen={isChangeSecretModalOpen}
        setIsOpen={setIsChangeSecretModalOpen}
        updateClientSecretFunc={(value) =>
          dispatch(updateConfigurations(value))
        }
      />
    </div>
  );
}
