import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from "reactstrap";
import DataTableLite from "../DataTableLite";
import { useNavigate } from "react-router-dom";

export default function ReportLargestElements(props) {
  const { reportTag, fileFoundElements, folderFoundElements } = props;

  const navigate = useNavigate();

  return (
    <Row>
      <Col md={6} className="pb-3">
        <Card>
          <CardHeader tag="h5">
            <span>Largest Files</span>
            <div className="float-end">
              <Button
                id="searchFiles"
                size="sm"
                color="info"
                onClick={() => navigate(`/search?report=${reportTag}`)}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <UncontrolledTooltip placement="auto" target="searchFiles">
                Show content as Search result
              </UncontrolledTooltip>
            </div>
          </CardHeader>
          <CardBody>
            <DataTableLite data={fileFoundElements} isFile />
          </CardBody>
        </Card>
      </Col>
      <Col md={6} className="pb-3">
        <Card>
          <CardHeader tag="h5">
            <span>Largest Folders</span>
            <div className="float-end">
              <Button
                id="searchFolders"
                size="sm"
                color="info"
                onClick={() => navigate(`/search?report=${reportTag}&activeTab=1`)}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <UncontrolledTooltip placement="auto" target="searchFolders">
                Show content as Search result
              </UncontrolledTooltip>
            </div>
          </CardHeader>
          <CardBody>
            <DataTableLite data={folderFoundElements} isFile={false} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
