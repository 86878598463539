import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
export const scanOnPremiseStorages = async (
  dispatch,
  auth,
  ids,
  cloneIndex = true
) => {
  await fetchWithAuth(
    dispatch,
    `/v1/scans/volumes/ids?cloneIndex=${cloneIndex}`,
    auth.accessToken,
    "POST",
    JSON.stringify(ids)
  );
};

export const scanArchiwares = async (
  dispatch,
  auth,
  ids,
  cloneIndex = true,
  deltaScan = true
) => {
  await fetchWithAuth(
    dispatch,
    `/v1/scans/archiwares/ids?cloneIndex=${cloneIndex}&isDeltaScan=${deltaScan}`,
    auth.accessToken,
    "POST",
    JSON.stringify(ids)
  );
};

export const scanBuckets = async (dispatch, auth, ids, cloneIndex = true) => {
  await fetchWithAuth(
    dispatch,
    `/v1/scans/buckets/ids?cloneIndex=${cloneIndex}`,
    auth.accessToken,
    "POST",
    JSON.stringify(ids)
  );
};

export const scanDellPowerScales = async (dispatch, auth, ids, cloneIndex = true, deltaScan = true) => {
  await fetchWithAuthV2(
    dispatch,
    `/v1/scans/powerScales/ids?cloneIndex=${cloneIndex}&isDeltaScan=${deltaScan}`,
    auth.accessToken,
    {},
    "POST",
    JSON.stringify(ids)
  );
};
