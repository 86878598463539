import EditFileTags from "../components/tags/EditFileTags";

export default function OptionalTagsStep(props) {
  const {
    optionalTags,
    setOptionalTags
  } = props;

  return (
    <>
      <p>
        Enhance your reports by adding optional tags to your files. Tags help you categorize and identify 
        files more easily, making it simpler to organize and filter your data. You can add as many tags 
        as needed to provide additional context.
      </p>
      <EditFileTags
        fileTags={optionalTags}
        setSelectedTagsParent={setOptionalTags}
        useSearchResult={false}
        maxHeight="450px"
      />
    </>
  );
}