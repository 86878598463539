import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import NewFolderTreeWrapperPathPicker from "../folderTree/FolderTreeWrapperPathPicker";
import { fetchArchiwareVolumeMappingList } from "../../ducks/archiwareVolumeMapping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { getArchiwareVolumeMappingByVolumeId } from "../../api/archiware";

export default function SelectLocation(props) {
  const {
    isSpecifyLocationChecked,
    onCheck,
    volumes,
    volumeTypes,
    location,
    setLocation,
    isRestore = false,
  } = props;

  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);
  const [locationPath, setLocationPath] = useState("");
  const [locationVolume, setLocationVolume] = useState({});
  const [globalMapping, setGlobalMapping] = useState(null);

  const dispatch = useDispatch();

  const archiwareVolumeMappings = useSelector(
    ({ archiwareVolumeMappings }) => archiwareVolumeMappings.items
  );
  const volumeSettings = useSelector(
    ({ volumeSettings }) => volumeSettings.items
  );
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(fetchArchiwareVolumeMappingList());
    getArchiwareVolumeMappingByVolumeId(dispatch, auth, -1)
      .then((res) => setGlobalMapping(res))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (locationVolume.id) {
      const folderPath = locationPath === "/" ? "" : locationPath;
      let volumePath;

      if (isRestore) {
        const archiwareVolumeMapping =
          archiwareVolumeMappings.find(
            (mapping) => mapping.volumeId === locationVolume.id
          ) ?? globalMapping;
        const archiwareVolumeMappingPath =
          archiwareVolumeMapping?.archiwarePath;

        const volumeMappingPath = volumes.find(
          (volume) => volume.id === locationVolume.id
        )
          ? volumes.find((volume) => volume.id === locationVolume.id).path
          : "";

        volumePath = archiwareVolumeMappingPath ?? volumeMappingPath;
      } else {
        const volumeSetting = volumeSettings.find(
          (volume) => volume.id === locationVolume.id
        );
        volumePath = volumeSetting ? volumeSetting.path : "";
      }

      let path = volumePath + folderPath;
      path = path.startsWith("//") ? path.substring(1) : path;

      setLocation(path);
      setIsLocationPickerOpen(false);
    }
  }, [locationPath, locationVolume]);

  return (
    <>
      <FormGroup check className="mt-3">
        <Label check>
          <Input
            type="checkbox"
            checked={isSpecifyLocationChecked}
            onChange={(e) => onCheck(e.target.checked)}
          />
          Specify{" "}
          {isRestore
            ? "the target restore location"
            : "the location in the archive index"}
        </Label>
      </FormGroup>
      {isSpecifyLocationChecked && (
        <FormGroup className="mt-2">
          <Label>
            Location{" "}
            {isRestore
              ? "on the Archiware P5 server file system"
              : "in the archive index"}
          </Label>
          <InputGroup>
            <Input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <Button onClick={() => setIsLocationPickerOpen(true)}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </Button>
          </InputGroup>
        </FormGroup>
      )}
      <NewFolderTreeWrapperPathPicker
        showCheckbox={false}
        isOpen={isLocationPickerOpen}
        setIsOpen={setIsLocationPickerOpen}
        volumeIds={volumes
          .filter((volume) => volumeTypes.includes(volume.type))
          .map((filteredVolume) => filteredVolume.id)}
        setCheckedPath={setLocationPath}
        setCheckedVolume={setLocationVolume}
        modalTitle="Select location"
      />
    </>
  );
}
