import { Card, CardBody, CardHeader } from "reactstrap";
import Loading from "../../components/Loading";
import CircleChart from "../../components/CircleChart";
import { formatSize } from "../../components/FormatSize";
import { formatName } from "../../utils/formatString";

export default function ReportCircleChart(props) {
  const {
    filesizeObject,
    baseSize,
    numberOfFilesFound,
    numberOfFilesTotal,
    numberOfFoldersFound,
    numberOfFoldersTotal,
    sizeOfFilesFound,
    sizeOfFilesTotal,
    reportType,
  } = props;

  const labelMap = {
    SEARCH_QUERY: "Other",
    UNIQUE: "Duplicate",
    DUPLICATED: "Unique",
  };

  return (
    <Card style={{ height: "350px" }}>
      <CardHeader tag="h5">
        <span>Chart</span>
      </CardHeader>
      <CardBody>
        {filesizeObject === null ||
        sizeOfFilesFound === null ||
        sizeOfFilesTotal == null ? (
          <Loading />
        ) : (
          <CircleChart
            data={[
              formatSize(
                sizeOfFilesFound,
                baseSize,
                filesizeObject.exponent,
                "object"
              ).value.toFixed(2),
              (
                formatSize(
                  sizeOfFilesTotal,
                  baseSize,
                  filesizeObject.exponent,
                  "object"
                ).value -
                formatSize(
                  sizeOfFilesFound,
                  baseSize,
                  filesizeObject.exponent,
                  "object"
                ).value
              ).toFixed(2),
            ]}
            dataCount={[
              numberOfFilesFound,
              numberOfFilesTotal - numberOfFilesFound,
            ]}
            dataCountFolder={[
              numberOfFoldersFound,
              numberOfFoldersTotal - numberOfFoldersFound,
            ]}
            labels={[
              `${formatName(reportType)} Data`,
              `${labelMap[reportType]} Data`,
            ]}
            color={["#2C4D6B", "#4A80B5"]}
            height={220}
            unit={filesizeObject.unit}
            maintainAspectRatio={false}
            valueCenterOfChart={
              ((sizeOfFilesFound / sizeOfFilesTotal) * 100).toFixed(2) + " %"
            }
          />
        )}
      </CardBody>
    </Card>
  );
}
