export const getLoginConfigurations = async () => {
  const url = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}/v1/auth/configurations`;

  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });
};

export const getAppInfo = async () => {
  const url = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}/actuator/info`;

  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });
};