import { faBook, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import NeedHelpLink from "../../../components/NeedHelpLink";

export default function StorageManagerMappingForm(props) {
  const {
    storageId,
    setStorageId,
    credentialId,
    setCredentialId,
    dataIntellPath,
    setDataIntellPath,
    storageManagerPath,
    setStorageManagerPath,
    volumes,
    credentials,
    setDisableCreateButton,
    mappings,
  } = props;

  const [isStorageIdInvalid, setIsStorageIdInvalid] = useState(false);
  const [isCredentialIdInvalid, setIsCredentialIdInvalid] = useState(false);
  const [isDataIntellPathInvalid, setIsDataIntellPathInvalid] = useState(false);
  const [isStorageManagerPathInvalid, setIsStorageManagerPathInvalid] = useState(false);
  const [filteredVolumes, setFilteredVolumes] = useState([...mappings]);

  useEffect(() => {
    if (
      (storageId && storageId.length === 0) ||
      (credentialId && credentialId.length === 0) ||
      dataIntellPath.length === 0 ||
      storageManagerPath.length === 0
    ) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [storageId, credentialId, dataIntellPath, storageManagerPath]);

  useEffect(() => {
    setFilteredVolumes(
      volumes.filter(
        (volume) =>
          volume.id === storageId ||
          !mappings.some((mapping) => mapping.volumeId === volume.id)
      )
    );
  }, [mappings]);

  return (
    <>
      <Form>
        <FormGroup row>
          <Label for="storage" sm={4}>
            On-Premises Storage
            <FontAwesomeIcon
              id="storageLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <UncontrolledTooltip placement="auto" target="storageLabel">
              The mount path of the On-Premises storage for DataIntell scanner.
            </UncontrolledTooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="storage"
              name="storage"
              type="select"
              value={storageId}
              onChange={(e) => {
                const value = e.target.value;
                setStorageId(value);
                setIsStorageIdInvalid(value.length === 0);

                const targetVolume = filteredVolumes.find(
                  (volume) => volume.id === parseInt(value)
                );
                if (targetVolume) {
                  setDataIntellPath(targetVolume.path);
                  setIsDataIntellPathInvalid(false);
                }
              }}
              invalid={isStorageIdInvalid}
            >
              <option value={""}>Select On-Premises Storage</option>
              {filteredVolumes.map((volume) => {
                return (
                  <option key={`stornextvol-${volume.id}`} value={volume.id}>
                    {volume.name}
                  </option>
                );
              })}
            </Input>
            <FormFeedback>On-Premises Storage must not be empty</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="credential" sm={4}>
            Quantum StorNext Credentials
            <FontAwesomeIcon
              id="storageLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <UncontrolledTooltip placement="auto" target="storageLabel">
              The path of the storage as seen on the Quantum StorNext
              application.
            </UncontrolledTooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="credential"
              name="credential"
              type="select"
              value={credentialId}
              onChange={(e) => {
                const value = e.target.value;
                setCredentialId(value);
                setIsCredentialIdInvalid(value.length === 0);
              }}
              invalid={isCredentialIdInvalid}
            >
              <option value={""}>Select Quantum StorNext Credentials</option>
              {credentials.map((credential) => {
                return (
                  <option
                    key={`stornextcred-${credential.id}`}
                    value={credential.id}
                  >
                    {credential.name}
                  </option>
                );
              })}
            </Input>
            <FormFeedback>
              Quantum StorNext Credentials must not be empty
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="dataintellPath" sm={4}>
            DataIntell Path
          </Label>
          <Col sm={8}>
            <Input
              id="dataintellPath"
              name="dataintellPath"
              type="text"
              placeholder="Ex: /Volumes/NASX"
              value={dataIntellPath}
              onChange={(e) => {
                const value = e.target.value;
                setDataIntellPath(value);
                setIsDataIntellPathInvalid(value.length === 0);
              }}
              invalid={isDataIntellPathInvalid}
            />
            <FormFeedback>DataIntell Path must not be empty</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="storageManagerPath" sm={4}>
            Quantum StorNext Path
          </Label>
          <Col sm={8}>
            <Input
              id="storageManagerPath"
              name="storageManagerPath"
              type="text"
              placeholder="Ex: /Volumes/NASX"
              value={storageManagerPath}
              onChange={(e) => {
                const value = e.target.value;
                setStorageManagerPath(value);
                setIsStorageManagerPathInvalid(value.length === 0);
              }}
              invalid={isStorageManagerPathInvalid}
            />
            <FormFeedback>Quantum StorNext Path must not be empty</FormFeedback>
          </Col>
        </FormGroup>
      </Form>
      <NeedHelpLink
        linkText="Storage Path Configurations"
        linkHref="https://support.cloudsoda.io/hc/en-us/articles/27834613666715-Quantum-StorNext-Integration-Storage-path-configurations"
        hasTopDivider
      />
    </>
  );
}
