import React, { Component } from "react";
import Breadcrumbs from "./Breadcrumbs";
import FolderList from "./FolderList";
import VolumeList from "./VolumeList";

class FolderNavigation extends Component {
  render() {
    const {
      volumeId,
      volumes,
      folders,
      changePath,
      selectFoldersPage,
      setNumberOfElements,
      selectVolume,
      changeVolumesOrder,
      changeFoldersOrder,
      getVolumeAnalytics,
      AddFolderComponent,
      volumeSettings,
      updateFolder,
      updateTags,
      hideActionColumn,
      listVolumes,
      path,
      addBreadcrumb,
    } = this.props;

    if (volumeId) {
      return (
        <>
          {addBreadcrumb && (
            <Breadcrumbs
              handleClick={changePath}
              volume={volumes.items.find(
                (volume) => volume.id === parseInt(volumeId)
              )}
              listVolumes={listVolumes}
              path={path}
            />
          )}
          <FolderList
            folders={folders.items}
            page={folders.page}
            total={folders.total}
            size={folders.size}
            sort={folders.sort}
            direction={folders.direction}
            changePath={changePath}
            selectPage={selectFoldersPage}
            changeOrder={changeFoldersOrder}
            AddFolderComponent={AddFolderComponent}
            showNumberOfFolders={true}
            volumeSettings={volumeSettings}
            updateFolder={updateFolder}
            updateTags={updateTags}
            setNumberOfElements={setNumberOfElements}
            isLoading={folders.isFetching}
          />
        </>
      );
    } else {
      return (
        <VolumeList
          volumes={volumes.items}
          isFetching={volumes.isFetching}
          direction={volumes.direction}
          sort={volumes.sort}
          selectVolume={selectVolume}
          changeOrder={changeVolumesOrder}
          getVolumeAnalytics={getVolumeAnalytics}
          hideActionColumn={hideActionColumn}
          AddFolderComponent={AddFolderComponent}
        />
      );
    }
  }
}

export default FolderNavigation;
