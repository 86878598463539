import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import IconikActionReview from "./IconikActionReview";

export default function IconikManualReview(props) {
  const {
    iconikActionObj,
    selectedFolders,
    setDisableForwardActionButton,
    isDirectory,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFolders, setTotalNumberOfFolders] = useState(0);
  const [validNumberOfFolders, setValidNumberOfFolders] = useState(0);
  const [totalSizeOfFolders, setTotalSizeOfFolders] = useState(0);
  const [validSizeOfFolders, setValidSizeOfFolders] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const volumes = useSelector(({ volumes }) => volumes.items);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [volumes]);

  const calculateReviewValues = () => {
    let validVolumes = {};
    volumes.forEach((volume) => {
      validVolumes[volume.id] = volume.name;
    });

    let currentValidNumberOfFolders = 0;
    let currentValidSizeOfFolders = 0;
    let activeVolume = {};

    selectedFolders.forEach((folder) => {
      if (validVolumes[folder.volumeId] && !folder.isDirectory) {
        currentValidNumberOfFolders++;
        currentValidSizeOfFolders += folder.size;
        activeVolume[folder.volumeId] = folder.volumeName;
      }
    });

    setTotalNumberOfFolders(selectedFolders.length);
    setValidNumberOfFolders(currentValidNumberOfFolders);
    setTotalSizeOfFolders(selectedFolders.reduce((a, b) => a + b.size, 0));
    setValidSizeOfFolders(currentValidSizeOfFolders);
    setActiveVolumes(Object.values(activeVolume));

    if (currentValidNumberOfFolders > 0) {
      setDisableForwardActionButton(false);
    }

    setIsFetching(false);
  };

  return (
    <IconikActionReview
      volumes={volumes}
      isFetching={isFetching}
      activeVolumes={activeVolumes}
      totalNumberOfFolders={totalNumberOfFolders}
      validNumberOfFolders={validNumberOfFolders}
      totalSizeOfFolders={totalSizeOfFolders}
      validSizeOfFolders={validSizeOfFolders}
      isDirectory={isDirectory}
      collectionTitle={iconikActionObj.collection.title}
      storageName={iconikActionObj.storage.name}
    />
  );
}
