import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { formatLabel } from "./FileFolderDetailsTable";

import {
  getStorageManagerVolumeMappings,
  getFileById,
} from "../api/storageManager";
import { setAlert } from "../../src/actions/alert";

export default function FileInfoStorageManager(props) {
  const { file } = props;

  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const [fileInfo, setFileInfo] = useState(null);

  useEffect(() => {
    if (auth.bundles.some((bundle) => bundle === "storageManager")) {
      fetchFileById();
    }
  }, [file]);

  const isStorageManagerVolume = (volumeId, volumeMappings) => {
    return volumeMappings.some((v) => v.volumeId === volumeId);
  };

  const fetchFileById = async () => {
    try {
      const storageManagerVolumeMappings =
        await fetchStorgeManagerVolumeMappings();
      if (
        isStorageManagerVolume(
          file.volumeId,
          storageManagerVolumeMappings.elements
        )
      ) {
        setIsFetching(true);
        const result = await getFileById(
          dispatch,
          auth,
          file.fileId,
          indexingDate
        );
        setFileInfo(result);
        setTimeout(() => setIsFetching(false), 100);
      }
    } catch (err) {
      const message = err.message || "Unable to get the file";
      dispatch(setAlert(message, "danger"));
      setTimeout(() => setIsFetching(false), 100);
    }
  };

  const fetchStorgeManagerVolumeMappings = async () => {
    try {
      const result = await getStorageManagerVolumeMappings(dispatch, auth);

      return result;
    } catch (err) {
      const message =
        err.message ||
        "Unable to fetch Quantum StorNext storage path configurations";
      dispatch(setAlert(message, "danger"));
    }
  };

  const renderMetadataLabelsAndValues = (name, index) => {
    const metadata = fileInfo.metadata;
    const value = metadata[name];

    if (value) {
      return (
        <React.Fragment key={name + index}>
          <div>{formatLabel(name)}</div>
          <div>
            <strong>{value}</strong>
          </div>
        </React.Fragment>
      );
    }

    return null;
  };

  const renderMetadata = () => {
    const metadataElements = Object.keys(fileInfo.metadata).map((type, index) =>
      renderMetadataLabelsAndValues(type, index)
    );
    return metadataElements;
  };

  return isFetching ? (
    <div>
      Fetching data...
      <FontAwesomeIcon
        icon={faSpinner}
        className={`mx-1 ${isFetching ? "fa-spin" : ""}`}
      />
    </div>
  ) : (
    fileInfo && fileInfo.metadata && renderMetadata()
  );
}
