import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export default function StorageManagerActionButton({
  onClickFunction
}) {
  const [isIconHovered, setIsIconHovered] = useState(false);

  return (
    <>
      <span
        onClick={onClickFunction}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
      >
        <img
          id="storageManagerActionButton"
          src="/icons/StorageManager.png"
          alt="Storage Manager logo"
          className="img-fluid"
          width="20px"
          height="20px"
          style={{ "opacity": isIconHovered ? "60%" : "100%"}}
        />
      </span>
      <UncontrolledTooltip target="storageManagerActionButton" placement="auto">
        Perform a Storage Manager action
      </UncontrolledTooltip>
    </>
  );
}