import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { setAlert } from "../../actions/alert";
import { fetchWithAuth } from "../../actions/requestHelper";

function ResetPasswordModal({
    showResetPasswordModal,
    closeResetPasswordModal
}) {
    const cleanPasswordObj = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    };
    const [passwordObj, setPasswordObj] = useState(cleanPasswordObj);
    const [resetPasswordErrorObj, setResetPasswordErrorObj] = useState({});

    const authObj = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const url = "/v1/profiles/password";
        const body = JSON.stringify({
            currentPassword: passwordObj.currentPassword,
            newPassword: passwordObj.newPassword,
            confirmPassword: passwordObj.confirmPassword
        });

        fetchWithAuth(dispatch, url, authObj.accessToken, "PUT", body)
            .then(() => {
                dispatch(setAlert("New password saved successfully"));
                setPasswordObj(cleanPasswordObj);
                setResetPasswordErrorObj({});
                closeResetPasswordModal();
            })
            .catch(err => {
                if (err.subErrors.length) {
                    const newErrObj = {};
                    err.subErrors.forEach(e => {
                        newErrObj[e.field] = e;
                    });
                    setResetPasswordErrorObj(newErrObj);
                } else {
                    setResetPasswordErrorObj({});
                }
            });
    }

    const handlePasswordInputChange = (e) => {
        e.persist();
        setPasswordObj(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    return (
        <Modal
            toggle={() => {
                setPasswordObj(cleanPasswordObj);
                setResetPasswordErrorObj({})
                closeResetPasswordModal();
            }} isOpen={showResetPasswordModal}
        >
            <ModalHeader
                toggle={() => {
                    setPasswordObj(cleanPasswordObj);
                    setResetPasswordErrorObj({})
                    closeResetPasswordModal();
                }}
            >
                Reset Password
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="inputCurrentPassword">
                            Current Password
                        </Label>
                        <Input
                            value={passwordObj.currentPassword}
                            type="password"
                            name="currentPassword"
                            id="inputCurrentPassword"
                            required
                            autoFocus
                            onChange={handlePasswordInputChange}
                            invalid={!!resetPasswordErrorObj["currentPassword"]}
                        />
                        <FormFeedback>current password {resetPasswordErrorObj["currentPassword"] && resetPasswordErrorObj["currentPassword"]["message"]}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inputNewPassword">
                            New Password
                        </Label>
                        <Input
                            value={passwordObj.newPassword}
                            type="password"
                            id="inputNewPassword"
                            name="newPassword"
                            required
                            onChange={handlePasswordInputChange}
                            invalid={!!resetPasswordErrorObj["newPassword"]}
                        />
                        <FormFeedback>new password {resetPasswordErrorObj["newPassword"] && resetPasswordErrorObj["newPassword"]["message"]}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inputConfirmPassword">
                            Confirm New Password
                        </Label>
                        <Input
                            value={passwordObj.confirmNewPassword}
                            type="password"
                            id="inputConfirmPassword"
                            name="confirmPassword"
                            required
                            onChange={handlePasswordInputChange}
                            invalid={!!resetPasswordErrorObj["confirmPassword"]}
                        />
                        <FormFeedback>confirm password {resetPasswordErrorObj["confirmPassword"] && resetPasswordErrorObj["confirmPassword"]["message"]}</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    setPasswordObj(cleanPasswordObj);
                    setResetPasswordErrorObj({})
                    closeResetPasswordModal();
                }}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ResetPasswordModal;

