import DellPowerScaleCredentialList from "./DellPowerScaleCredentialList";
import DellPowerScaleStorageList from "./DellPowerScaleStorageList";

export default function DellPowerScale(props) {
  const {

  } = props;

  return (
    <>
      <DellPowerScaleStorageList />
      <DellPowerScaleCredentialList />
    </>
  );
}