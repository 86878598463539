import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArchiwareStep1 from "./ArchiwareStep1";
import ArchiwareStep2 from "./ArchiwareStep2";
import ArchiwareReviewPage from "./ArchiwareReviewPage";
import StoragePriceConfiguration from "../../components/StoragePriceConfiguration";
import WizardModal from "../../../components/WizardModal";
import { fetchWithAuth } from "../../../actions/requestHelper";
import { fetchStoragePriceList } from "../../../ducks/storagePrice";
import { receiveInvalidServerList } from "../../../ducks/storagePrice";
import { setAlert } from "../../../actions/alert";
import { fetchArchiwareList } from "../../../ducks/archiware";
import { fetchArchiwareCredentialList } from "../../../ducks/archiwareCredential";
import { fetchVolumeSettings } from "../../../ducks/volumeSettings";
import ServerSelectionConfiguration from "../../components/ServerSelectionConfiguration";
import { getArchiwareP5Credentials } from "../../../api/archiware";

function ArchiwareWizard({
  showWizard,
  setShowWizard,
  isProcessing,
  setIsProcessing,
}) {
  const dispatch = useDispatch();
  const archiwares = useSelector(({ archiwares }) => archiwares);
  const servers = useSelector(({ servers }) => servers.items);
  const [serverName, setServerName] = useState(
    servers.length ? servers[0].name : ""
  );
  const auth = useSelector(({ auth }) => auth);
  const toKeepStoragePricesUpdated = useSelector(
    ({ storagePrices }) => storagePrices.items
  );
  const toKeepArchiwareCredentialsUpdated = useSelector(
    ({ archiwareCredentials }) => archiwareCredentials.total
  );
  const [archiwareCredentials, setArchiwareCredentials] = useState([]);

  const [stepIndex, setStepIndex] = useState(0);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);

  const emptyArchiwareObj = {
    credentialsId: "",
    index: "",
    path: "",
    server: null,
    serverName: "",
    name: "",
    storageName: "",
    storageType: null,
  };
  const [archiwareObj, setArchiwareObj] = useState(emptyArchiwareObj);
  const emptyLocalArchiwareObjForValidation = {
    credentialsId: "",
    path: "",
    name: "",
    index: "",
  };
  const localArchiwareObjForValidation = useRef(
    emptyLocalArchiwareObjForValidation
  );

  const [showCreateStorageType, setShowCreateStorageType] = useState(false);
  const [storagePrices, setStoragePrices] = useState(
    toKeepStoragePricesUpdated
  );
  const [selectedStorageType, setSelectedStorageType] = useState(
    toKeepStoragePricesUpdated.length ? toKeepStoragePricesUpdated[0] : ""
  );

  const emptyStorageTypeObj = {
    description: "",
    currencySymbol: "",
    price: "",
  };
  const [storageTypeObj, setStorageTypeObj] = useState(emptyStorageTypeObj);
  const [storageToCreate, setStorageToCreate] = useState([]);
  // credentials states
  const emptyArchiwareCredentialsObj = {
    name: "",
    username: "",
    password: "",
  };
  // url for credential
  const emptyUrl = "http://";

  const [url, setUrl] = useState(emptyUrl);
  const [archiwareCredentialsObj, setarchiwareCredentialsObj] = useState(
    emptyArchiwareCredentialsObj
  );
  const [showCredentialsForm, setshowCredentialsForm] = useState(false);
  const [archiwareCredentialsToCreate, setarchiwareCredentialsToCreate] =
    useState([]);

  useEffect(() => {
    if (toKeepStoragePricesUpdated.length) {
      setStoragePrices(toKeepStoragePricesUpdated);
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }
  }, [toKeepStoragePricesUpdated]);
  useEffect(() => {
    getArchiwareP5Credentials(dispatch, auth, 1, 500)
      .then((res) => setArchiwareCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the archiware credentials",
            "danger"
          )
        )
      );
  }, [toKeepArchiwareCredentialsUpdated]);

  useEffect(() => {
    if (servers.length) setServerName(servers[0].name);
  }, [servers]);

  const STEP_TITLES = [
    "Select Server ",
    "Select Credentials",
    "Configure Archiware P5 Index",
    "Select Storage Price",
    "Review ",
  ];

  const steps = [
    <ServerSelectionConfiguration
      dataObj={archiwareObj}
      setDataObj={setArchiwareObj}
      servers={servers}
      serverName={serverName}
      setServerName={setServerName}
      dataSource="Archiware P5 Index"
    />,
    <ArchiwareStep1
      archiwareObj={archiwareObj}
      setArchiwareObj={setArchiwareObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
      localArchiwareObjForValidation={localArchiwareObjForValidation.current}
      showCredentialsForm={showCredentialsForm}
      setshowCredentialsForm={setshowCredentialsForm}
      archiwareCredentialsObj={archiwareCredentialsObj}
      setarchiwareCredentialsObj={setarchiwareCredentialsObj}
      url={url}
      setUrl={setUrl}
      archiwareCredentials={archiwareCredentials}
      setArchiwareCredentials={setArchiwareCredentials}
      archiwareCredentialsToCreate={archiwareCredentialsToCreate}
      setarchiwareCredentialsToCreate={setarchiwareCredentialsToCreate}
    />,
    <ArchiwareStep2
      archiwareObj={archiwareObj}
      setArchiwareObj={setArchiwareObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
      localArchiwareObjForValidation={localArchiwareObjForValidation.current}
    />,
    <StoragePriceConfiguration
      setDataObject={setArchiwareObj}
      showCreateStorageType={showCreateStorageType}
      setShowCreateStorageType={setShowCreateStorageType}
      storageTypeObj={storageTypeObj}
      setStorageTypeObj={setStorageTypeObj}
      storagePrices={storagePrices}
      setDisableForwardActionButton={setDisableForwardActionButton}
      selectedStorageType={selectedStorageType}
      setSelectedStorageType={setSelectedStorageType}
      storageToCreate={storageToCreate}
      setStorageToCreate={setStorageToCreate}
      setStoragePrices={setStoragePrices}
    />,
    <ArchiwareReviewPage archiwareObj={archiwareObj} />,
  ];
  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setArchiwareObj(emptyArchiwareObj);
    setServerName("");
    if (toKeepStoragePricesUpdated.length !== 0) {
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }
    setStoragePrices((prev) =>
      prev.filter((storage) => storage.hasOwnProperty("id"))
    );
    setStorageToCreate([]);
    setShowCreateStorageType(false);
    setarchiwareCredentialsToCreate([]);
    setshowCredentialsForm(false);
    setarchiwareCredentialsObj(emptyArchiwareCredentialsObj);
    setUrl(emptyUrl);
    if (archiwareCredentialsToCreate.length) {
      setArchiwareCredentials((prev) =>
        prev.filter((credential) => credential.hasOwnProperty("id"))
      );
    }
    localArchiwareObjForValidation.current =
      emptyLocalArchiwareObjForValidation;
    setShowWizard(false);
    setStepIndex(0);
    setDisableForwardActionButton(false);
  };
  const createArchiwareCredentialsInWizard = async (
    archiwareCredentialsInfoObj
  ) => {
    const archiwareCredentialsBody = {
      name: archiwareCredentialsInfoObj.name,
      username: archiwareCredentialsInfoObj.username,
      password: archiwareCredentialsInfoObj.password,
      url: url,
    };
    setIsProcessing(true);

    try {
      const newArchiwareCredentials = await fetchWithAuth(
        dispatch,
        "/v1/archiwares/credentials",
        auth.accessToken,
        "POST",
        JSON.stringify(archiwareCredentialsBody)
      );
      dispatch(fetchArchiwareCredentialList());
      setArchiwareCredentials((prev) => [...prev, newArchiwareCredentials]);
      setarchiwareCredentialsToCreate((prev) => [
        ...prev,
        archiwareCredentialsBody,
      ]);
      setarchiwareCredentialsObj(emptyArchiwareCredentialsObj);
      setArchiwareObj((prev) => ({
        ...prev,
        credentialsId: String(newArchiwareCredentials.id),
      }));
      setshowCredentialsForm(false);
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      const message = err.message || "Unable to create archiware credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const saveArchiware = async () => {
    setIsProcessing(true);

    const newlyCreatedStorageType = [];

    if (storageToCreate.length) {
      try {
        for (const storage of storageToCreate) {
          const storageBody = {
            description: storage.description,
            currencySymbol: storage.currencySymbol,
            price: storage.price,
          };
          const storageResponse = await fetchWithAuth(
            dispatch,
            "/v1/storage-prices",
            auth.accessToken,
            "POST",
            JSON.stringify(storageBody)
          );
          newlyCreatedStorageType.push(storageResponse);
        }
        dispatch(fetchStoragePriceList());
      } catch (e) {
        if (e.subErrors) {
          dispatch(receiveInvalidServerList(e));
        } else {
          dispatch(setAlert(e.message, "danger"));
        }
      }
    }

    try {
      const archiwareBody = {
        indexId: archiwareObj.index,
        path: archiwareObj.path,
        name: archiwareObj.name,
        archiwareP5Credential: { id: archiwareObj.credentialsId },
        storagePrice: { id: archiwareObj.storageType },
        serverId: archiwareObj.server,
      };

      // get the back end generated id if the storage was created during the wizard
      if (archiwareObj.storageType === undefined) {
        const correctId = newlyCreatedStorageType.find(
          (storage) => storage.description === archiwareObj.storageName
        ).id;
        archiwareBody.storagePrice.id = correctId;
      }
      //
      await fetchWithAuth(
        dispatch,
        "/v1/archiwares",
        auth.accessToken,
        "POST",
        JSON.stringify(archiwareBody)
      );
      dispatch(
        fetchArchiwareList(
          1,
          archiwares.sort,
          archiwares.direction,
          archiwares.size
        )
      );
      dispatch(fetchVolumeSettings());
      dispatch(fetchStoragePriceList());

      setShowWizard(false);
      setIsProcessing(false);
      onWizardExit();
    } catch (e) {
      setIsProcessing(false);
      const message = e.message || "Unable to create archiware";
      dispatch(setAlert(message, "danger"));
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        if (stepIndex === 1) setDisableForwardActionButton(false);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Save":
        saveArchiware();
        break;

      case "Cancel storage type creation":
        setStorageTypeObj(emptyStorageTypeObj);
        setShowCreateStorageType(false);
        setDisableForwardActionButton(false);
        break;

      case "Add storage type":
        setStoragePrices((prev) => [...prev, storageTypeObj]);
        setStorageToCreate((prev) => [...prev, storageTypeObj]);
        setStorageTypeObj(emptyStorageTypeObj);
        setSelectedStorageType(storageTypeObj);
        setShowCreateStorageType(false);
        break;

      case "Cancel credentials creation":
        setarchiwareCredentialsObj(emptyArchiwareCredentialsObj);
        setshowCredentialsForm(false);
        setUrl(emptyUrl);
        setarchiwareCredentialsObj(emptyArchiwareCredentialsObj);
        if (archiwareObj.credentialsId.length === 0) {
          setDisableForwardActionButton(true);
        } else {
          setDisableForwardActionButton(false);
        }
        break;

      case "Create credentials":
        createArchiwareCredentialsInWizard(archiwareCredentialsObj);
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (showCreateStorageType) {
      return "Cancel storage type creation";
    } else if (showCredentialsForm) {
      return "Cancel credentials creation";
    } else if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (showCreateStorageType) {
      return "Add storage type";
    } else if (showCredentialsForm) {
      return "Create credentials";
    } else if (stepIndex === STEPS_LENGTH - 1) {
      return "Save";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      onWizardExit={onWizardExit}
      stepIndex={stepIndex + 1}
      stepsLength={STEPS_LENGTH}
      title="Add Archiware P5 Index"
      subTitle={STEP_TITLES[stepIndex]}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      isProcessing={isProcessing}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}

export default ArchiwareWizard;
