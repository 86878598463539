export const handleVolumeAndPaths = (volumesAndPaths, volumeList) => {
  const volumeAndPathList = [];
  if (volumesAndPaths && volumeList) {
    volumesAndPaths.forEach((volumeAndPath) => {
      const splittedVolumeAndPath = volumeAndPath.split(":");
      const volume = getVolume(splittedVolumeAndPath[0], volumeList);
      if (volume) {
        volumeAndPathList.push(` ${volume.name}${splittedVolumeAndPath[1]}`);
      } else {
        volumeAndPathList.push(volumeAndPath);
      }
    });
  }
  return volumeAndPathList;
};

export const handleVolumeNames = (volumeIds, volumeList) => {
  let volumeSelected;
  if (volumeIds && volumeList) {
    volumeSelected = "";
    volumeIds.forEach((volumeId, index) => {
      volumeSelected += index > 0 ? ", " : "";
      const volume = getVolume(volumeId, volumeList);
      if (volume) {
        volumeSelected += volume.name;
      } else {
        volumeSelected += "Undefined";
      }
    });
  }
  return volumeSelected;
};

const getVolume = (volumeId, volumeList) => {
  return volumeList.find((volume) => {
    return parseInt(volume.id) === parseInt(volumeId);
  });
};
