import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Spinner } from "reactstrap";
import GeneralError from "../GeneralError";
import { getArchiwareClients } from "../../api/archiware";
import Select from "react-select";
import SelectLocation from "./SelectLocation";

export default function SelectArchiwareClientStep({
  userInputObj,
  setClient,
  setDisableForwardActionButton,
  archiwareClients,
  setArchiwareClients,
  previousPlanId,
  previousServerId,
  restoring = false,
  allArchiwareClients,
  setAllArchiwareClients,
  allServers,
  location,
  setLocation,
  isSpecifyLocationChecked,
  setIsSpecifyLocationChecked,
  selectedFiles,
  fileTypeToOnlyInclude,
  fileTypesToOnlyInclude,
  isDirectory,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [isFetching, setIsFetching] = useState(
    archiwareClients.length ? false : true
  );
  const [serverOptions, setServerOptions] = useState([]);
  const [errorObj, setErrorObj] = useState({
    error: false,
    apiErrorMessage: null,
  });
  const [
    hasAnythingElseThanFileTypeToInclude,
    setHasAnythingElseThanFileTypeToInclude,
  ] = useState(false);

  const volumes = useSelector(({ volumes }) => volumes.items);

  useEffect(() => {
    setDisableForwardActionButton(false);

    if (restoring) {
      checkIfSelectionIncludesOtherThanOnPremises();
    }

    if (
      previousPlanId !== undefined &&
      previousServerId.current !== userInputObj.serverId
    ) {
      previousPlanId.current = userInputObj.planId;
      previousServerId.current = userInputObj.serverId;
      filterClients(allArchiwareClients);
    }

    if (archiwareClients.length === 0) {
      setDisableForwardActionButton(true);
      getArchiveClients();
    }
  }, []);

  useEffect(() => {
    getServerOptions();
  }, [archiwareClients]);

  const fileOrFolder = isDirectory ? "folders" : "files";

  const checkIfSelectionIncludesOtherThanOnPremises = () => {
    const volumeIdsHashMap = {};

    volumes
      .filter((volume) =>
        fileTypesToOnlyInclude
          ? fileTypesToOnlyInclude.includes(volume.type)
          : volume.type === fileTypeToOnlyInclude
      )
      .forEach((volume) => {
        volumeIdsHashMap[volume.id] = volume.id;
      });

    setHasAnythingElseThanFileTypeToInclude(
      selectedFiles.some((file) => !volumeIdsHashMap[file.volumeId])
    );
  };

  const getArchiveClients = async () => {
    try {
      const allArchiwareClients = await getArchiwareClients(dispatch, auth);
      setAllArchiwareClients(allArchiwareClients);
      filterClients(allArchiwareClients);
      setIsFetching(false);
    } catch (err) {
      setErrorObj({
        error: true,
        apiErrorMessage: err.message,
      });
      setIsFetching(false);
    }
  };

  const filterClients = (clients) => {
    const archiwareClientList = restoring
      ? clients
      : clients.filter(
          (archiwareClient) =>
            userInputObj.serverId === archiwareClient.server.id
        );
    if (archiwareClientList.length) {
      setArchiwareClients(archiwareClientList);
      setClient(archiwareClientList);
      setDisableForwardActionButton(false);
    }
  };
  const formatGroupLabel = (groupedOption) => {
    return (
      <>
        Server : <span>{groupedOption.label}</span>
      </>
    );
  };

  const getServerOptions = () => {
    if (archiwareClients.length !== 0) {
      const options = [];

      if (restoring) {
        allServers.forEach((server) => {
          const result = formatGroupServer(server.id);
          if (result) {
            options.push(result);
          }
        });
      } else {
        const result = formatGroupServer(userInputObj.serverId);
        if (result) {
          options.push(result);
        }
      }

      setServerOptions(options);
    }
  };

  const formatGroupServer = (serverId) => {
    const group = [];
    let archiwareClientServerName = "";
    archiwareClients.forEach((archiwareClientObj) => {
      if (archiwareClientObj.server.id === serverId) {
        archiwareClientServerName = archiwareClientObj.server.name;
        group.push({
          value: `${archiwareClientObj.clientName},${archiwareClientObj.server.id}`,
          label: archiwareClientObj.clientName,
        });
      }
    });

    if (group.length !== 0) {
      return { label: archiwareClientServerName, options: group };
    }
  };

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : errorObj.error ? (
        <GeneralError
          customErrorMessage="There was an error while fetching the data"
          apiErrorMessage={errorObj.apiErrorMessage}
        />
      ) : (
        <>
          {hasAnythingElseThanFileTypeToInclude && restoring && (
            <div className="mb-3">
              <span style={{ color: "#eda003" }}>Warning:</span>
              <strong>
                {` You have non Archiware P5 ${fileOrFolder} in your ${fileOrFolder} selection. Only Archiware P5 ${fileOrFolder} will be restored.`}
              </strong>
            </div>
          )}

          <Label>Select an Archiware P5 client</Label>
          {archiwareClients.length ? (
            <Select
              value={[
                {
                  value: `${userInputObj.clientId},${userInputObj.serverId}`,
                  label: userInputObj.clientId,
                },
              ]}
              options={serverOptions}
              formatGroupLabel={formatGroupLabel}
              onChange={(e) => {
                setClient(e.value);
              }}
            />
          ) : (
            <GeneralError
              customErrorMessage={
                "It seems you have no client on Archiware. Please verify and try again."
              }
            />
          )}
          {restoring && (
            <SelectLocation
              isSpecifyLocationChecked={isSpecifyLocationChecked}
              onCheck={(isChecked) => {
                if (!isChecked) {
                  setLocation("");
                }
                setIsSpecifyLocationChecked(isChecked);
              }}
              volumes={volumes}
              volumeTypes={["crawl.files", "NetworkVolume", "DellPowerScale"]}
              location={location}
              setLocation={setLocation}
              isRestore
            />
          )}
        </>
      )}
    </>
  );
}
