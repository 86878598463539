import { Fragment, useEffect, useState } from "react";
import { Alert, Badge, Button, UncontrolledTooltip, Spinner } from "reactstrap";
import packageJson from "../../../package.json";
import UpdateLicense from "./UpdateLicense";
import PropTypes from "prop-types";
import ConfirmModal from "../../components/ConfirmModal";
import { restartServers, getAllServerInfos } from "../../api/server";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClipboard, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setAlert } from "../../actions/alert";
import { getDataIntellVersion } from "../../api/wordPress";
import semver from "semver";
import { updateDataIntell } from "../../api/administration";
import OperationModal from "../../components/OperationModal";
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import moment from "moment";
import BetaWrapper from "../../components/beta/BetaWrapper";

AppInfo.propTypes = {
  version: PropTypes.string,
  licenseExpirationDate: PropTypes.string,
  licenseIsValid: PropTypes.bool,
  downloadLogsFun: PropTypes.func,
  license: PropTypes.object,
  updateLicenseFunc: PropTypes.func,
};

function AppInfo(props) {
  const {
    version,
    licenseExpirationDate,
    licenseIsValid,
    downloadLogsFun,
    license,
    updateLicenseFunc,
  } = props;

  const [isRestartAPIOpen, setIsRestartAPIOpen] = useState(false);
  const [isUpdateDataIntellOpen, setIsUpdateDataIntellOpen] = useState(false);
  const [macAddresses, setMacAddresses] = useState([]);
  const [isMoreRecentVersionAvailable, setIsMoreRecentVersionAvailable] =
    useState(false);
  const [isDownloadingLogs, setIsDownloadingLogs] = useState(false);
  const [latestVersion, setLatestVersion] = useState("1.0.0");
  const [updateDataIntellId, setUpdateDataIntellId] = useState(1);
  const [updateDataIntellName, setUpdateDataIntellName] = useState("API & Client");
  const [servers, setServers] = useState([]);
  const [areServersFetching, setAreServersFetching] = useState(true);

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getServers();
  }, []);

  useEffect(() => {
    if (version) {
      getDataIntellVersion()
        .then((res) => {
          if (typeof res === "string") {
            setIsMoreRecentVersionAvailable(semver.gt(res, version));
            setLatestVersion(res);
          } else {
            console.error(
              "Error while getting the latest version of DataIntell from the web."
            );
            setIsMoreRecentVersionAvailable(false);
          }
        })
        .catch((err) => {
          console.error(
            "Error while getting the latest version of DataIntell from the web.",
            err
          );
          setIsMoreRecentVersionAvailable(false);
        });
    }
  }, [version]);

  const serverEndpoint = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}`;
  const apiInfoUrl = `${serverEndpoint}/actuator/info`;
  const dailyLogUrl = `${serverEndpoint}/actuator/logfile`;
  const apiDocumentationUrl = `${serverEndpoint}/swagger-ui/index.html?configUrl=/api-docs/swagger-config`;

  function licenseValidation() {
    if (licenseExpirationDate === "CLOUD") {
      return (
        <p>
          Valid Cloud License{" "}
          <span aria-label="valid" role="img">
            ✅
          </span>
        </p>
      );
    }
    return (
      <p>
        License Expiration Date: {licenseExpirationDate}{" "}
        {licenseIsValid ? "✅" : "❌"}
      </p>
    );
  }

  const downloadLogs = async () => {
    setIsDownloadingLogs(true);
    await downloadLogsFun();
    setIsDownloadingLogs(false);
  };

  const getServers = () => {
    getAllServerInfos(dispatch, auth)
      .then((res) => {
        setServers(res);
        setMacAddresses(res.find(server => server.id === 1).macAddresses);
        setAreServersFetching(false);
      })
      .catch((err) => {
        dispatch(setAlert("Error while getting the list of servers", "danger", 8000));
        console.error(err);
        setAreServersFetching(false);
      });
  };

  const copyContent = () => {
    const element = document.getElementById("macAddresses");

    navigator.clipboard
      .writeText(element.innerText)
      .then((res) =>
        dispatch(
          setAlert("Mac Addresses copied to the clipboard", "info", 2500)
        )
      )
      .catch((err) =>
        dispatch(
          setAlert(
            "Issue while copying Mac Addresses to the clipboard",
            "danger",
            2500
          )
        )
      );
  };

  const formatDate = (value) => moment(value).format("lll");

  const getFormattedServers = () => {
    const values = [];

    {
      servers.sort((a, b) => a.id - b.id).forEach(server => {
        if (server.id !== 1) {
          values.push(
            <span>
              {server.id} - {server.name} <Badge color="info">v{server.version}</Badge> :
            </span>
          );

          const doesScannerRequireUpdate = !!server.version ? semver.gt(latestVersion, server.version) : null;
          const isUpdatingAvailable = !!server.version ? semver.gte(server.version, "1.11.0") : null;

          values.push(
            <span>
              {server.isActive ?
                <FontAwesomeIcon icon={faCircle} color="#28a745" /> :
                server.isActive === false ?
                  <FontAwesomeIcon icon={faCircle} color="#dc3545" /> :
                  <FontAwesomeIcon icon={faCircle} color="#ffc107" />
              }{" "}
              <span>Last Checked: {returnNAOnNull(server.lastCheckedDate, formatDate)}</span>
              {!server.isActive && <span className="ms-2">Last Active: {returnNAOnNull(server.lastActiveDate, formatDate)}</span>}
              <BetaWrapper>
                <Button
                  style={{ marginLeft: "15px" }}
                  color="primary"
                  size="sm"
                  disabled={
                    !doesScannerRequireUpdate ||
                    !isUpdatingAvailable ||
                    server.isActive === false ||
                    isMoreRecentVersionAvailable
                  }
                  onClick={() => {
                    setUpdateDataIntellId(server.id);
                    setUpdateDataIntellName("Scanner " + server.name);
                    setIsUpdateDataIntellOpen(true);
                  }}
                >
                  {
                    doesScannerRequireUpdate === false ?
                      "Scanner is up-to-date" :
                      (server.isActive === false ?
                        "Activate scanner first" :
                        (isMoreRecentVersionAvailable ?
                          "Update DataIntell API first" :
                          (!isUpdatingAvailable ?
                            "Update scanner manually" :
                            `Update scanner to version ${latestVersion}`
                          )
                        )
                      )
                  }
                </Button>
              </BetaWrapper>
            </span>
          );
        }
      })
    }

    return (
      <div
        style={{
          display: "grid",
          columnGap: "15px",
          rowGap: "5px",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center"
        }}
      >
        {values}
      </div>
    );
  };

  return (
    <>
      <Alert color="secondary">
        <h4 className="alert-heading">DataIntell API</h4>
        <Badge color="info">v{version}</Badge>
        <p>
          API Endpoint:{" "}
          <a className="text-decoration-none" href={apiInfoUrl} target="blank">
            {serverEndpoint}
          </a>
          <br />
          <a className="text-decoration-none" href={apiDocumentationUrl} target="blank">
            API documentation
          </a>
          <br />
          <a className="text-decoration-none" href={dailyLogUrl} target="blank">
            Logs of the day
          </a>
        </p>
        <p>
          Mac Addresses {areServersFetching && <FontAwesomeIcon icon={faSpinner} className="fa-spin ms-1" />}
          {!areServersFetching &&
            <>
              <FontAwesomeIcon
                id="copyMacAddresses"
                icon={faClipboard}
                className="ms-1"
                onClick={copyContent}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip target="copyMacAddresses" placement="auto">
                Copy Mac Addresses
              </UncontrolledTooltip>
              <br />
              <span id="macAddresses">
                {macAddresses.map((macAddress) => {
                  if (
                    macAddress.ip.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/)
                  ) {
                    return (
                      <Fragment key={macAddress.ip}>
                        <span>
                          {macAddress.macAddress} : {macAddress.ip}
                        </span>
                        <br />
                      </Fragment>
                    );
                  }
                })}
              </span>
            </>
          }
        </p>
        {licenseValidation()}
        <Button
          color="primary"
          onClick={() => {
            setUpdateDataIntellId(1);
            setUpdateDataIntellName("API & Client");
            setIsUpdateDataIntellOpen(true);
          }}
          className="mb-3"
          disabled={!isMoreRecentVersionAvailable}
        >
          {isMoreRecentVersionAvailable ? `Update API to version ${latestVersion}` : "API is up-to-date"}
        </Button>
        <br />
        <UpdateLicense
          license={license}
          updateLicenseFunc={updateLicenseFunc}
        />
        <Button
          outline
          color="primary"
          disabled={isDownloadingLogs}
          onClick={() => {
            downloadLogs();
          }}
          style={{ marginTop: "1rem" }}
        >
          Download all logs {isDownloadingLogs && <Spinner size="sm" />}
        </Button>
        <br />
        <Button
          outline
          color="primary"
          onClick={() => setIsRestartAPIOpen(true)}
          style={{ marginTop: "1rem" }}
        >
          Restart API
        </Button>
        <hr />
        <span>
          <h4 className="alert-heading">DataIntell Scanners {areServersFetching && <FontAwesomeIcon icon={faSpinner} className="fa-spin ms-2" />}</h4>
        </span>
        {!areServersFetching && getFormattedServers()}
        <hr />
        <h4 className="alert-heading">DataIntell Client</h4>
        <Badge color="info">v{packageJson.version}</Badge>
      </Alert>
      <ConfirmModal
        showConfirmation={isRestartAPIOpen}
        title="Restart API"
        closeConfirmation={() => setIsRestartAPIOpen(false)}
        buttonColor="primary"
        buttonText="Confirm"
        confirmAction={() => {
          restartServers(dispatch, auth, [{ id: 1, name: "DataIntell" }])
            .then((res) =>
              dispatch(
                setAlert(
                  "DataIntell API will restart in 15 seconds. Please note that it will be down for a couple of minutes.",
                  "success",
                  5000
                )
              )
            )
            .catch((err) =>
              dispatch(
                setAlert("Unable to restart DataIntell API.", "danger", 5000)
              )
            );
          setIsRestartAPIOpen(false);
        }}
      >
        Are you sure you want to restart DataIntell API? This will cancel all
        current running jobs.
      </ConfirmModal>
      <OperationModal
        isOpen={isUpdateDataIntellOpen}
        title={`Update DataIntell ${updateDataIntellName}`}
        closeFunc={() => setIsUpdateDataIntellOpen(false)}
        initialStateChildren={
          <>
            Are you sure you want to update DataIntell {updateDataIntellName}?
            <br />
            <br />
            You can read the release note{" "}
            <a href="https://dataintell.io/release-note" target="_blank">
              here
            </a>
            .
          </>
        }
        loadingStateText={`Updating DataIntell ${updateDataIntellName} to version ${latestVersion}.`}
        successStateChildren={`DataIntell ${updateDataIntellName} has successfully been updated to version ${latestVersion}.`}
        failureStateChildren={`DataIntell ${updateDataIntellName} has failed to update to version ${latestVersion}.`}
        timeoutStateChildren={
          "The update is taking longer than expected, it might be stuck."
        }
        launchJobFunc={() => {
          return new Promise((resolve) => {
            updateDataIntell(dispatch, auth, latestVersion, updateDataIntellId)
              .then((res) => resolve(res))
              .catch((err) => {
                console.error(
                  "Error while getting the update job from the application.",
                  err
                );
                dispatch(setAlert("Error while getting the update job from the application.", "danger", 8000));
              });
          });
        }}
        retryAmountCap={60}
      />
    </>
  );
}

export default AppInfo;
