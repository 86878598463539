import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { getJobJobTypes } from "../../api/job";
import { FormGroup, Input, Label } from "reactstrap";

const Control = ({ children, ...props }) => {
  return (
    <components.Control { ...props }>
      <FontAwesomeIcon icon={faFilter} style={{ color: "#495057", opacity: "0.5" }} />
      {children}
    </components.Control>
  );
};

export default function JobFilters(props) {
  const {
    selectedJobTypes,
    setSelectedJobTypes
  } = props;

  const [jobTypes, setJobTypes] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getJobJobTypes(dispatch, auth)
      .then(res => setJobTypes(res.map(type => ({ value: type, label: type }))))
      .catch(err => console.error("Error while getting job types"));
  }, []);

  useEffect(() => {
    setIsSelectAllChecked(selectedJobTypes.length === jobTypes.length);
  }, [selectedJobTypes, jobTypes]);

  const styles = { control: (css) => ({ ...css, paddingLeft: '1rem', minWidth: '30rem', maxWidth: '90rem' }) }

  return (
    <FormGroup className="mb-0">
      <Select
        components={{ Control }}
        isMulti
        styles={styles}
        options={jobTypes}
        value={selectedJobTypes}
        onChange={(e) => setSelectedJobTypes(e)}
        placeholder={"Filter jobs by type"}
      />
      <FormGroup check inline className="mt-1">
        <Label check>
          <Input
            type="checkbox"
            checked={isSelectAllChecked}
            onChange={e => {
              setSelectedJobTypes([ ...jobTypes ]);
              setIsSelectAllChecked(true);
            }}
          />
          Select all job types
        </Label>
      </FormGroup>
    </FormGroup>
  );
}