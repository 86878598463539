import { useSelector } from "react-redux";
import CreateReportWizard from "./CreateReportWizard";

export default function UpdateReportModal(props) {
  const {
    reportTemplate,
    showUpdateReportModal,
    fetchReports,
    closeModal
  } = props;

  const volumes = useSelector(({ volumes }) => volumes.items);
  const projects = useSelector(({ projectList }) => projectList.items);

  const getUpdatedReportTemplate = () => {
    const references = reportTemplate.parameters.references ? reportTemplate.parameters.references : [];
    const comparison = reportTemplate.parameters.comparison ? reportTemplate.parameters.comparison : [];

    const updatedReferences = references.map((reference) => {
      let volumeName;

      if (reference.type === "VOLUME") {
        volumeName = volumes.find(
          (volume) => volume.id === reference.id
        )?.name;
      } else {
        volumeName = projects.find(
          (project) => project.id === reference.id
        )?.name;
      }

      return { ...reference, volumeName: volumeName };
    });

    const updatedComparison = comparison.map((element) => {
      let volumeName;

      if (element.type === "VOLUME") {
        volumeName = volumes.find(
          (volume) => volume.id === element.id
        )?.name;
      } else {
        volumeName = projects.find(
          (project) => project.id === element.id
        )?.name;
      }

      return { ...element, volumeName: volumeName };
    });

    return { ...reportTemplate, parameters: { ...reportTemplate.parameters, references: updatedReferences, comparison: updatedComparison } };
  };

  return (
    <CreateReportWizard
      showCreateReportWizard={showUpdateReportModal}
      fetchReports={fetchReports}
      closeWizard={closeModal}
      reportTemplate={getUpdatedReportTemplate()}
      isUpdate
    />
  );
}