import React, { useState } from "react";

function ArchiwareReviewPage({ archiwareObj }) {
  return (
    <ul style={{ listStyle: "none", lineHeight: "2", paddingLeft: "0" }}>
      <li>
        Path: &nbsp; <strong>{archiwareObj.path}</strong>
      </li>
      <li>
        Archive Index: &nbsp; <strong>{archiwareObj.index}</strong>
      </li>
      <li>
        DataIntell Name: &nbsp; <strong>{archiwareObj.name}</strong>
      </li>
      <li>
        Server: &nbsp; <strong>{archiwareObj.serverName}</strong>
      </li>
      <li>
        Storage Type: &nbsp; <strong>{archiwareObj.storageName}</strong>
      </li>
    </ul>
  );
}

export default ArchiwareReviewPage;
