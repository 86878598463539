import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import ChartCard from "./ChartCard";

export default function GroupChart(props) {
  const {
    filters,
    search,
    addFilters,
    lastAnalyticsSearch,
    setRef = () => {},
    setSubtitle = () => {},
    setSizeItems = () => {},
    setSizeShownData = () => {},
    sizeOfFiles,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [groupFiles, setGroupFiles] = useState([]);

  const dispatch = useDispatch();

  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);

  const fetchData = async () => {
    setIsFetching(true);
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName: "group",
      size: 50,
      indexingDate: activeDate,
    };

    const groups = await fetchWithAuthV2(dispatch, url, accessToken, {
      ...queryParams,
      ...searchParams,
    });
    const sortedBySizeGroups = groups.sort((a, b) => b.size - a.size);
    setGroupFiles(sortedBySizeGroups);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [lastAnalyticsSearch]);

  const addExtensionFilter = (index) => {
    if (null !== index) {
      const fieldValue = groupFiles[index].field;

      if ("" === fieldValue) return;

      const name = ["group"];
      const value = [fieldValue];
      addFilters(name, value);
    }
  };

  return (
    <ChartCard
      title="Groups"
      elements={groupFiles}
      emptyStringValue="Empty Group"
      addFilterFunc={addExtensionFilter}
      isFetching={isFetching}
      setRef={setRef}
      setSubtitle={setSubtitle}
      setSizeItems={setSizeItems}
      setSizeShownData={setSizeShownData}
      componentId="groupChartId"
      isCircleChartShownByDefault={false}
      sizeOfFiles={sizeOfFiles}
    />
  );
}
