import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

export default function Breadcrumbs(props) {
  const {
    path,
    volume,
    handleClick,
    listVolumes
  } = props;

  const volumeName = volume ? volume.name : "";
  let pathSplitted = [];

  if (path !== "/" || path !== "/") {
    pathSplitted = path.split("/");
  }

  let newPath = "";

  const listing = pathSplitted.map(name => {
    if (name === "" || name === "/") {
      return "";
    }
    newPath += `/${name}`;
    const link = newPath;
    return (
      <BreadcrumbItem key={link}>
        <a className="text-decoration-none" href="#" onClick={() => handleClick(link)}>{name}</a>
      </BreadcrumbItem>
    );
  });

  return (
    <Breadcrumb 
      tag="nav" 
      listTag="div" 
      style={{ 
        padding: ".75rem 1rem", 
        backgroundColor: "#e9ecef",
        borderRadius: ".25rem",
        marginBottom: "1rem"
      }}
      listClassName="mb-0"
    >
      <BreadcrumbItem onClick={() => listVolumes()} tag="a" href="#">
        <FontAwesomeIcon icon={faHome} size={"lg"} />
      </BreadcrumbItem>
      <BreadcrumbItem>
        <a className="text-decoration-none" href="#" onClick={() => handleClick("/")}>
          <strong>{volumeName}</strong>
        </a>
      </BreadcrumbItem>
      {listing}
    </Breadcrumb>
  );
}
