import React, { useState } from "react";
import { UncontrolledTooltip } from 'reactstrap';
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getColor = isDeleteTag => {
  return isDeleteTag ? "#DC3545" : "#007BFF";
};

const getTooltip = isDeleteTag => {
  return (isDeleteTag ? "Remove" : "Add") + " tags " + (isDeleteTag ? "from" : "to") + " selection";
};

export default function TagMultipleFilesButton(props) {
  const { id, isMultipleTagsOpen, deleteTag = false } = props;

  const [iconColor, setIconColor] = useState(getColor(deleteTag));

  return (
    <>
      <FontAwesomeIcon
        id={id}
        icon={faTags}
        className="ms-1"
        color={isMultipleTagsOpen ? "#000" : iconColor}
        size="lg"
        style={{ cursor: "pointer" }}
        onMouseOver={() => setIconColor("#000")}
        onMouseOut={() => setIconColor(getColor(deleteTag))}
      />
      <UncontrolledTooltip target={id} placement="auto">
        {getTooltip(deleteTag)}
      </UncontrolledTooltip>
    </>
  );
}