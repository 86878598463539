import { fetchWithAuth } from "../actions/requestHelper";

export const getServers = async (dispatch, auth) => {
  const url = `/servers`;

  return fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const restartServers = async (dispatch, auth, servers) => {
  const url = `/v1/servers/restart`;

  const body = { serverList: servers };

  return fetchWithAuth(dispatch, url, auth.accessToken, "POST", JSON.stringify(body));
}

export const getServerInfos = async (dispatch, auth, id) => {
  const url = `/v1/servers/${id}/infos`;

  return fetchWithAuth(dispatch, url, auth.accessToken, "GET");
}

export const getAllServerInfos = async (dispatch, auth) => {
  const url = `/v1/servers/infos`;

  return fetchWithAuth(dispatch, url, auth.accessToken, "GET");
}
