import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardModal from "../../../components/WizardModal";
import VolumeStep2 from "./VolumeStep2";
import VolumeReviewPage from "./VolumeReviewPage";
import StoragePriceConfiguration from "../../components/StoragePriceConfiguration";
import { setAlert } from "../../../actions/alert";
import { fetchDataVolumeSettings } from "../../../ducks/dataVolumeSettings";
import { fetchWithAuth } from "../../../actions/requestHelper";
import { fetchStoragePriceList } from "../../../ducks/storagePrice";
import { receiveInvalidServerList } from "../../../ducks/storagePrice";
import { fetchVolumeSettings } from "../../../ducks/volumeSettings";
import ServerSelectionConfiguration from "../../components/ServerSelectionConfiguration";
import {
  createNetworkVolume,
  createNetworkVolumeCredentials,
} from "../../../api/networkVolume";

function VolumeWizard({
  showWizard,
  setShowWizard,
  isProcessing,
  setIsProcessing,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const dataVolumeSettings = useSelector(
    ({ dataVolumeSettings }) => dataVolumeSettings
  );
  const servers = useSelector(({ servers }) => servers.items);
  const toKeepStoragePricesUpdated = useSelector(
    ({ storagePrices }) => storagePrices.items
  );
  const [serverName, setServerName] = useState(
    servers.length ? servers[0].name : ""
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);

  const emptyVolumeObj = {
    name: "",
    path: "",
    server: null,
    serverName: "",
    storageName: "",
    storageType: null,
    mountType: "Local",
    host: "",
    shareName: "",
    options: "",
    credentialsId: "",
    scanType: "VOLUME_MULTITHREAD"
  };
  const [volumeObj, setVolumeObj] = useState(emptyVolumeObj);
  const [showCreateStorageType, setShowCreateStorageType] = useState(false);
  const [storagePrices, setStoragePrices] = useState(
    toKeepStoragePricesUpdated
  );
  const [selectedStorageType, setSelectedStorageType] = useState(
    toKeepStoragePricesUpdated.length ? toKeepStoragePricesUpdated[0] : ""
  );

  const emptyStorageTypeObj = {
    description: "",
    currencySymbol: "",
    price: "",
  };
  const [storageTypeObj, setStorageTypeObj] = useState(emptyStorageTypeObj);
  const [storageToCreate, setStorageToCreate] = useState([]);

  const emptyCredentialsObj = {
    name: "",
    username: "",
    password: "",
  };
  const [credentialsObj, setCredentialsObj] = useState(emptyCredentialsObj);
  const [showCreateCredentials, setShowCreateCredentials] = useState(false);

  useEffect(() => {
    if (toKeepStoragePricesUpdated.length) {
      setStoragePrices(toKeepStoragePricesUpdated);
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }

    if (servers.length) setServerName(servers[0].name);
  }, [servers, toKeepStoragePricesUpdated]);

  const STEP_TITLES = [
    "Select Server",
    "Configure On-Premises Storage",
    "Select Storage Price",
    "Review",
  ];

  const steps = [
    <ServerSelectionConfiguration
      dataObj={volumeObj}
      setDataObj={setVolumeObj}
      servers={servers}
      serverName={serverName}
      setServerName={setServerName}
      dataSource="On-Premises Storage"
    />,
    <VolumeStep2
      volumeObj={volumeObj}
      setVolumeObj={setVolumeObj}
      credentialsObj={credentialsObj}
      setCredentialsObj={setCredentialsObj}
      showCreateCredentials={showCreateCredentials}
      setShowCreateCredentials={setShowCreateCredentials}
      setDisableForwardActionButton={setDisableForwardActionButton}
    />,
    <StoragePriceConfiguration
      setDataObject={setVolumeObj}
      showCreateStorageType={showCreateStorageType}
      setShowCreateStorageType={setShowCreateStorageType}
      storageTypeObj={storageTypeObj}
      setStorageTypeObj={setStorageTypeObj}
      storagePrices={storagePrices}
      setDisableForwardActionButton={setDisableForwardActionButton}
      selectedStorageType={selectedStorageType}
      setSelectedStorageType={setSelectedStorageType}
      storageToCreate={storageToCreate}
      setStorageToCreate={setStorageToCreate}
      setStoragePrices={setStoragePrices}
    />,
    <VolumeReviewPage volumeObj={volumeObj} />,
  ];
  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setVolumeObj(emptyVolumeObj);
    setServerName("");
    if (toKeepStoragePricesUpdated.length !== 0) {
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }
    setStoragePrices((prev) =>
      prev.filter((storage) => storage.hasOwnProperty("id"))
    );
    setStorageToCreate([]);
    setShowCreateStorageType(false);
    setShowWizard(false);
    setStepIndex(0);
    setDisableForwardActionButton(false);
    setCredentialsObj(emptyCredentialsObj);
  };

  const createOnPremisesCredentials = () => {
    setIsProcessing(true);

    createNetworkVolumeCredentials(dispatch, auth, credentialsObj)
      .then((res) => {
        setVolumeObj((prev) => ({ ...prev, credentialsId: res.id }));
        setShowCreateCredentials(false);
        setIsProcessing(false);
      })
      .catch((err) => {
        const message =
          err.message || "Unable to create On-Premises credentials";
        dispatch(setAlert(message, "danger"));
        setIsProcessing(false);
      });
  };

  const saveVolume = async () => {
    setIsProcessing(true);

    const newlyCreatedStorageType = [];

    if (storageToCreate.length) {
      try {
        for (const storage of storageToCreate) {
          const storageBody = {
            description: storage.description,
            currencySymbol: storage.currencySymbol,
            price: storage.price,
          };
          const storageResponse = await fetchWithAuth(
            dispatch,
            "/v1/storage-prices",
            auth.accessToken,
            "POST",
            JSON.stringify(storageBody)
          );
          newlyCreatedStorageType.push(storageResponse);
        }
        dispatch(fetchStoragePriceList());
      } catch (e) {
        if (e.subErrors) {
          dispatch(receiveInvalidServerList(e));
        } else {
          dispatch(setAlert(e.message, "danger"));
        }
      }
    }

    try {
      const volumeBody = {
        name: volumeObj.name,
        path: volumeObj.path,
        type: "crawl.files",
        server: { id: volumeObj.server },
        storagePrice: { id: volumeObj.storageType },
        scanType: volumeObj.scanType
      };

      const networkVolumeBody = {
        host: volumeObj.host,
        shareName: volumeObj.shareName,
        options: volumeObj.options,
        dataintellName: volumeObj.name,
        path: volumeObj.path,
        type: volumeObj.mountType,
        serverId: volumeObj.server,
        storagePrice: { id: volumeObj.storageType },
        scanType: volumeObj.scanType,
        networkVolumeCredential:
          volumeObj.credentialsId === ""
            ? null
            : { id: volumeObj.credentialsId },
      };

      // get the back end generated id if the storage was created during the wizard
      if (volumeObj.storageType === undefined) {
        const correctId = newlyCreatedStorageType.find(
          (storage) => storage.description === volumeObj.storageName
        ).id;
        volumeBody.storagePrice.id = correctId;
      }

      if (volumeObj.mountType === "Local") {
        await fetchWithAuth(
          dispatch,
          "/volumeSettings",
          auth.accessToken,
          "POST",
          JSON.stringify(volumeBody)
        );

        updateOnPremStorage();
      } else {
        createNetworkVolume(dispatch, auth, networkVolumeBody)
          .then((res) => {
            updateOnPremStorage();
          })
          .catch((err) => {
            const message =
              err.message || "Unable to create On-Premises storage";
            dispatch(setAlert(message, "danger"));
            setIsProcessing(false);
          });
      }
    } catch (e) {
      setIsProcessing(false);
      dispatch(
        setAlert(
          `Unable to create an On-Premises Storage for [${volumeObj.name}]. Possible reasons: ${e}`,
          "danger"
        )
      );
    }
  };

  const updateOnPremStorage = () => {
    dispatch(
      fetchDataVolumeSettings(
        1,
        dataVolumeSettings.sort,
        dataVolumeSettings.direction,
        dataVolumeSettings.size
      )
    );
    dispatch(fetchVolumeSettings());
    dispatch(fetchStoragePriceList());

    setShowWizard(false);
    setIsProcessing(false);
    onWizardExit();
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        if (stepIndex === 1) setDisableForwardActionButton(false);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Save":
        saveVolume();
        break;

      case "Cancel storage type creation":
        setStorageTypeObj(emptyStorageTypeObj);
        setShowCreateStorageType(false);
        setDisableForwardActionButton(false);
        break;

      case "Add storage type":
        setStoragePrices((prev) => [...prev, storageTypeObj]);
        setStorageToCreate((prev) => [...prev, storageTypeObj]);
        setStorageTypeObj(emptyStorageTypeObj);
        setSelectedStorageType(storageTypeObj);
        setShowCreateStorageType(false);
        break;

      case "Cancel credentials creation":
        setCredentialsObj(emptyCredentialsObj);
        setShowCreateCredentials(false);
        break;

      case "Create credentials":
        createOnPremisesCredentials();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (showCreateStorageType) {
      return "Cancel storage type creation";
    } else if (showCreateCredentials) {
      return "Cancel credentials creation";
    } else if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (showCreateStorageType) {
      return "Add storage type";
    } else if (showCreateCredentials) {
      return "Create credentials";
    } else if (stepIndex === STEPS_LENGTH - 1) {
      return "Save";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      onWizardExit={onWizardExit}
      stepIndex={stepIndex + 1}
      stepsLength={STEPS_LENGTH}
      title="Add On-Premises Storage"
      subTitle={STEP_TITLES[stepIndex]}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      isProcessing={isProcessing}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}

export default VolumeWizard;
