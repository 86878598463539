import React from "react";
import { Badge } from "reactstrap";

function StorageTypeReview({
    selectedStorageType,
    storageToCreate,
    setDataObject,
    setSelectedStorageType,
    setStorageToCreate,
    setStoragePrices,
    storagePrices
}) {
    return (
        <div>
            <ul style={{ marginTop: "20px", listStyle: "none", lineHeight: "2", paddingLeft: "0" }}>
                <li>Price: <strong>{selectedStorageType && selectedStorageType.price}</strong></li>
                <li>Currency: <strong>{selectedStorageType && selectedStorageType.currencySymbol}</strong></li>
            </ul>

            {
                storageToCreate.length ?
                    <div className="mt-2">
                        <p>The storage type that is going to be created with the data source:</p>

                        {storageToCreate.map((storage, index) => {
                            return (
                                <Badge key={index} style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }} color="primary">
                                    {storage.description}
                                    <Badge
                                        style={{ cursor: "pointer", marginLeft: 10 }}
                                        color="light"
                                        className="text-dark"
                                        onClick={() => {
                                            const filteredStorageToCreate = storageToCreate.filter(inFilterStorage => inFilterStorage.description !== storage.description);
                                            const filteredStoragePrices = storagePrices.filter(inFilterStorage => inFilterStorage.description !== storage.description);
                                            const isLastSelectedStorageExist = filteredStoragePrices.some(storage => storage.description === selectedStorageType.description);

                                            if (!isLastSelectedStorageExist) {
                                                setDataObject(prev => ({
                                                    ...prev,
                                                    storageType: storagePrices[0].id,
                                                    storageName: storagePrices[0].description
                                                }));
                                                setSelectedStorageType(storagePrices[0]);
                                            }

                                            setStorageToCreate(filteredStorageToCreate);
                                            setStoragePrices(filteredStoragePrices);
                                        }}
                                    >
                                        X
                                    </Badge>
                                </Badge>
                            );
                        })}
                    </div> :
                    null
            }
        </div>
    );
}

export default StorageTypeReview;