import { fetchWithAuth } from "./requestHelper";
import { setAlert } from "./alert";

export const REQUEST_ALERTCONFIGURATION_LIST =
  "REQUEST_ALERTCONFIGURATION_LIST";
export const RECEIVE_ALERTCONFIGURATION_LIST =
  "RECEIVE_ALERTCONFIGURATION_LIST";

export const REQUEST_CREATE_ALERTCONFIGURATION =
  "REQUEST_CREATE_ALERTCONFIGURATION";
export const RECEIVE_CREATE_ALERTCONFIGURATION =
  "RECEIVE_CREATE_ALERTCONFIGURATION";
export const RECEIVE_INVALID_ALERTCONFIGURATION =
  "RECEIVE_INVALID_ALERTCONFIGURATION";

export const REQUEST_DELETE_ALERTCONFIGURATION =
  "REQUEST_DELETE_ALERTCONFIGURATION";
export const RECEIVE_DELETE_ALERTCONFIGURATION =
  "RECEIVE_DELETE_ALERTCONFIGURATION";

export const REQUEST_UPDATE_ALERTCONFIGURATION =
  "REQUEST_UPDATE_ALERTCONFIGURATION";
export const RECEIVE_UPDATE_ALERTCONFIGURATION =
  "RECEIVE_UPDATE_ALERTCONFIGURATION";

function requestAlertConfigurationList() {
  return {
    type: REQUEST_ALERTCONFIGURATION_LIST
  };
}

function receiveAlertConfigurationList(alertConfigurationList) {
  return {
    type: RECEIVE_ALERTCONFIGURATION_LIST,
    alertConfigurationList
  };
}

export function fetchAlertConfigurationList(
  page = 1,
  sort = "user_id",
  direction = "asc"
) {
  return (dispatch, getState) => {
    dispatch(requestAlertConfigurationList());
    return fetchWithAuth(
      dispatch,
      `/alertConfigurations/users/${
      getState().auth.userId
      }?page=${page}&sort=${sort},${direction}`,
      getState().auth.accessToken
    )
      .then(alertConfigurationList => {
        dispatch(receiveAlertConfigurationList(alertConfigurationList));
      })
      .catch(err => {
        dispatch(setAlert(err.message, "danger"));
      });
  };
}

function requestCreateAlertConfiguration() {
  return {
    type: REQUEST_CREATE_ALERTCONFIGURATION
  };
}

function receiveCreateAlertConfiguration() {
  return {
    type: RECEIVE_CREATE_ALERTCONFIGURATION
  };
}

function requestUpdateAlertConfiguration() {
  return {
    type: REQUEST_UPDATE_ALERTCONFIGURATION
  };
}

function receiveUpdateAlertConfiguration() {
  return {
    type: RECEIVE_UPDATE_ALERTCONFIGURATION
  };
}

function receiveInvalidAlertConfiguration(errors) {
  return {
    type: RECEIVE_INVALID_ALERTCONFIGURATION,
    errors
  };
}

function requestDeleteAlertConfiguration(id) {
  return {
    type: REQUEST_DELETE_ALERTCONFIGURATION,
    id
  };
}

function receiveDeleteAlertConfiguration(id) {
  return {
    type: RECEIVE_DELETE_ALERTCONFIGURATION,
    id
  };
}

export function deleteAlertConfiguration(id) {
  return (dispatch, getState) => {
    dispatch(requestDeleteAlertConfiguration(id));
    return fetchWithAuth(
      dispatch,
      `/alertConfigurations/${id}`,
      getState().auth.accessToken,
      "DELETE"
    )
      .then(() => {
        dispatch(setAlert("Alert configuration was deleted successfully", "success"));
        dispatch(fetchAlertConfigurationList());
        dispatch(receiveDeleteAlertConfiguration(id));
      })
      .catch(err => {
        dispatch(setAlert(err.message, "danger"));
      });
  };
}

export function createAlertConfiguration(type, frequency, threshold) {
  return (dispatch, getState) => {
    const body = {
      user: { id: getState().auth.userId },
      type,
      frequency,
      threshold
    };

    dispatch(requestCreateAlertConfiguration());
    return fetchWithAuth(
      dispatch,
      "/alertConfigurations",
      getState().auth.accessToken,
      "POST",
      JSON.stringify(body)
    )
      .then(() => {
        dispatch(setAlert("Alert configuration was created successfully", "success"));
        dispatch(fetchAlertConfigurationList());
        dispatch(receiveCreateAlertConfiguration());
      })
      .catch(err => {
        if (err.subErrors) {
          dispatch(receiveInvalidAlertConfiguration(err));
        } else {
          dispatch(setAlert(err.message, "danger"));
        }
      });
  };
}

export function updateAlertConfiguration(id, type, frequency, threshold) {
  return (dispatch, getState) => {
    const body = {
      id,
      user: { id: getState().auth.userId },
      type,
      frequency,
      threshold
    };
    dispatch(requestUpdateAlertConfiguration());
    return fetchWithAuth(
      dispatch,
      `/alertConfigurations/${id}`,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then(text => {
        dispatch(setAlert(`Alert configuration for id[${text.id}] was updated successfully`, "success"));
        dispatch(fetchAlertConfigurationList());
        dispatch(receiveUpdateAlertConfiguration());
      })
      .catch(err => {
        if (err.subErrors) {
          dispatch(receiveInvalidAlertConfiguration(err));
        } else {
          dispatch(setAlert(err.message, "danger"));
        }
      });
  };
}