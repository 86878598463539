import React from "react";
import TableResult from "../components/TableResult";
import Loading from "./Loading";
import VolumeIcon from "./VolumeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import FormatSize from "./FormatSize";

function VolumeList(props) {
  const {
    volumes,
    isFetching,
    sort,
    direction,
    changeOrder,
    selectVolume,
    getVolumeAnalytics,
    hideActionColumn,
    AddFolderComponent,
  } = props;

  let volumeTitles = [
    { name: "Storage Name", sort: "name" },
    { name: "Used Space (%)" },
    { name: "Total Size of Files", sort: "sizeOfFiles" },
    { name: "Total Capacity", sort: "total" },
    { name: "Available Space", sort: "free" },
    { name: "Used Space", sort: "used" },
  ];

  if (AddFolderComponent) {
    volumeTitles.unshift({ name: "Actions" });
  }

  if (!hideActionColumn) volumeTitles.push({ name: "Actions" });

  const listFormated = volumes.map((volume) => {
    const volumeSizePercentage = volume.free
      ? `${Math.round((volume.used / volume.total) * 100)} %`
      : "N/A";
    const lineObject = {
      columnData: [
        <>
          <VolumeIcon volumeType={volume.type} size="sm" /> {volume.name}
        </>,
        volumeSizePercentage,
        volume.sizeOfFiles ? <FormatSize>{volume.sizeOfFiles}</FormatSize> : "N/A",
        <FormatSize>{volume.total}</FormatSize>,
        volume.free ? <FormatSize>{volume.free}</FormatSize> : "N/A",
        <FormatSize>{volume.used}</FormatSize>,
      ],
      style: {},
      options: {
        linkData: volume.id,
      },
    };

    if (AddFolderComponent) {
      lineObject.columnData.unshift(<AddFolderComponent folder={volume} />);
      lineObject.options.actionColumn = false;
    }

    if (!hideActionColumn)
      lineObject.columnData.push(
        <>
          <Button
            outline
            id={`volumeAnalytics-${volume.id}`}
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              getVolumeAnalytics(volume.id);
            }}
            size="sm"
          >
            <FontAwesomeIcon icon={faChartLine} />
          </Button>
          <UncontrolledTooltip
            placement="auto"
            target={`volumeAnalytics-${volume.id}`}
          >
            See analytics of storage
          </UncontrolledTooltip>
        </>
      );

    return lineObject;
  });

  const showResult = () => {
    return volumes.length > 0 ? (
      <TableResult
        handleClick={selectVolume}
        title={"Volumes"}
        data={listFormated}
        titles={volumeTitles}
        sort={sort}
        direction={direction}
        changeOrder={changeOrder}
      />
    ) : (
      <h5 className="pt-2">There is no data for this indexing date</h5>
    );
  };

  return <div className="py-2">{isFetching ? <Loading /> : showResult()}</div>;
}

export default VolumeList;
