import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_ROLE = "REQUEST_CREATE_ROLE";
const RECEIVE_CREATE_ROLE = "RECEIVE_CREATE_ROLE";
// // READ
const REQUEST_ROLES = "REQUEST_ROLE_LIST";
const RECEIVE_ROLES = "RECEIVE_ROLE_LIST";
// // UPDATE
const REQUEST_UPDATE_ROLE = "REQUEST_UPDATE_ROLE";
const RECEIVE_UPDATE_ROLE = "RECEIVE_UPDATE_ROLE";
// // DELETE
const REQUEST_DELETE_ROLE = "REQUEST_DELETE_ROLE";
const RECEIVE_DELETE_ROLE = "RECEIVE_DELETE_ROLE";
// // ERROR
const RECEIVE_INVALID_ROLE = "RECEIVE_INVALID_ROLE";

// === Reducers ===
export default function role(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_ROLES:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_ROLES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.roles.elements,
        page: action.roles.page,
        total: action.roles.total,
        size: action.roles.size,
        sort: action.roles.sort,
        direction: action.roles.direction,
        errors: {}
      });
    case RECEIVE_INVALID_ROLE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: formatError(action.errors)
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestRoles() {
  return {
    type: REQUEST_ROLES
  };
}

function receiveRoles(roles) {
  return {
    type: RECEIVE_ROLES,
    roles
  };
}

function requestCreateRole(role) {
  return {
    type: REQUEST_CREATE_ROLE,
    role: role
  };
}

function receiveCreateRole(createdRole) {
  return {
    type: RECEIVE_CREATE_ROLE,
    role: createdRole
  };
}

function requestUpdateRole(role) {
  return {
    type: REQUEST_UPDATE_ROLE,
    role: role
  };
}

function receiveUpdateRole(role) {
  return {
    type: RECEIVE_UPDATE_ROLE,
    role: role
  };
}

function requestDeleteRole(id) {
  return {
    type: REQUEST_DELETE_ROLE,
    id
  };
}

function receiveDeleteRole(id) {
  return {
    type: RECEIVE_DELETE_ROLE,
    id
  };
}

// === Side Effects ===
export function fetchRoles(page = 1, sort = "id", direction = "asc", size = 200) {
  return async (dispatch, getState) => {
    dispatch(requestRoles());
    const url = `/v1/roles?page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
    try {
      const json = await fetchWithAuth(dispatch, url, getState().auth.accessToken);
      dispatch(receiveRoles(json));
    } catch (err) {
      const message = err.message || `Unable to get the list of roles`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createRole(role) {
  return async (dispatch, getState) => {
    dispatch(requestCreateRole(role));
    try {
      const json = await fetchWithAuth(dispatch, "/v1/roles", getState().auth.accessToken, "POST", JSON.stringify(role));
      dispatch(receiveCreateRole(json));
      dispatch(fetchRoles());
    } catch (err) {
      const message = err.message || "Unable to create role";
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function updateRole(role) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateRole(role));
    try {
      const json = await fetchWithAuth(dispatch, `/v1/roles/${role.id}`, getState().auth.accessToken, "PUT", JSON.stringify(role));
      dispatch(receiveUpdateRole(json));
      dispatch(fetchRoles());
    } catch (err) {
      const message = err.message || "Unable to update role";
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function deleteRole(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteRole(id));
    try {
      await fetchWithAuth(dispatch, `/v1/roles/${id}`, getState().auth.accessToken, "DELETE");
      dispatch(receiveDeleteRole(id));
      dispatch(fetchRoles());
    } catch (err) {
      const message = err.message || "Unable to delete role";
      dispatch(setAlert(message, "danger"));
    }
  };
}
