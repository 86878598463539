import React, { useEffect, useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import TableResult from "../components/TableResult";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlay,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import TableActions from "../components/TableActions";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../actions/alert";
import { getAllReportTemplatesPaginated } from "../api/report";
import CreateReportWizard from "./CreateReportWizard";
import LaunchReportModal from "./LaunchReportModal";
import UpdateReportModal from "./UpdateReportModal";
import DeleteReportModal from "./DeleteReportModal";
import { fetchVolumes } from "../actions";
import { fetchProjectList } from "../ducks/project";
import { formatName } from "../utils/formatString";
import { useNavigate } from "react-router-dom";
import { fetchTags } from "../ducks/tag";

export const EMPTY_REPORT_TEMPLATE_OBJ = {
  id: null,
  name: "",
  type: "",
  tags: [],
  retention: 30,
  parameters: {
    references: [],
    comparison: [],
    searchQueryNode: null,
  },
};

export default function Reports(props) {
  const [reports, setReports] = useState({
    elements: [],
    page: 1,
    total: 0,
    size: 25,
    sort: "name",
    direction: "asc",
  });
  const [isFetching, setIsFetching] = useState(true);
  const [size, setSize] = useState(25);
  const [reportTemplate, setReportTemplate] = useState(
    EMPTY_REPORT_TEMPLATE_OBJ
  );
  const [showCreateReportWizard, setShowCreateReportWizard] = useState(false);
  const [showLaunchReportModal, setShowLaunchReportModal] = useState(false);
  const [showUpdateReportModal, setShowUpdateReportModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);

  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const numberOfElementsReport = window.localStorage.getItem(
      "numberOfElementsReport"
    )
      ? Number(window.localStorage.getItem("numberOfElementsReport"))
      : 25;
    setSize(numberOfElementsReport);

    fetchReports(
      reports.page,
      numberOfElementsReport,
      window.localStorage.getItem("reportSort") ?? "name",
      window.localStorage.getItem("reportDirection") ?? "asc"
    );

    dispatch(fetchVolumes());
    dispatch(fetchProjectList(1, "name", "asc", 200));
    dispatch(fetchTags());
  }, [reports.page]);

  const fetchReports = (page, size, sort, direction) => {
    setIsFetching(true);
    getAllReportTemplatesPaginated(
      dispatch,
      auth,
      page,
      size,
      sort,
      direction
    )
      .then((res) => {
        setReports(res);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(
          setAlert("Error while fetching the list of reports", "danger")
        );
        setIsFetching(false);
      });
  };

  const changeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === reports.sort) {
      newDirection = reports.direction === "asc" ? "desc" : "asc";
    }
    window.localStorage.setItem("reportSort", newSort);
    window.localStorage.setItem("reportDirection", newDirection);

    fetchReports(reports.page, size, newSort, newDirection);
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsReport", numberOfElements);
    setSize(numberOfElements);

    fetchReports(1, numberOfElements, reports.sort, reports.direction);
  };

  const reportsListFormated = reports.elements.map((report) => {
    let lineObject = {
      columnData: [
        report.name,
        formatName(report.type),
        moment(report.lastExecutionDate).format("lll"),
        <>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setReportTemplate(report);
              setShowLaunchReportModal(true);
            }}
            color="success"
          >
            <FontAwesomeIcon icon={faPlay} />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setReportTemplate(report);
              setShowUpdateReportModal(true);
            }}
            style={{ marginLeft: 10 }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setReportTemplate(report);
              setShowDeleteReportModal(true);
            }}
            color="danger"
            style={{ marginLeft: 10 }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>,
      ],
      style: {},
      options: {
        linkData: report.id,
      },
    };

    return lineObject;
  });

  const reportListTitles = [
    { name: "Name", sort: "name" },
    { name: "Type", sort: "type" },
    { name: "Last Run", sort: "lastExecutionDate" },
    { name: "Actions" },
  ];

  return (
    <>
      <div className="d-flex">
        <h1 className="page-header">Reports</h1>
        <div className="ms-auto">
          <Button
            id="createReport"
            color="success"
            onClick={() => setShowCreateReportWizard(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <UncontrolledTooltip placement="auto" target="createReport">
            Create new report
          </UncontrolledTooltip>
        </div>
      </div>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={reports.page}
            numberOfPages={Math.ceil(reports.total / size)}
            selectPage={(page) =>
              setReports((prev) => ({ ...prev, page: page }))
            }
            numberOfElements={size}
            setNumberOfElements={handleChangeNumberOfElements}
          />
          <TableResult
            handleClick={(reportId) => navigate("/report/" + reportId)}
            changeOrder={changeOrder}
            sort={reports.sort}
            title={"Reports"}
            direction={reports.direction}
            data={reportsListFormated}
            titles={reportListTitles}
          />

          <CreateReportWizard
            showCreateReportWizard={showCreateReportWizard}
            fetchReports={() =>
              fetchReports(1, reports.size, reports.sort, reports.direction)
            }
            closeWizard={() => setShowCreateReportWizard(false)}
          />

          <LaunchReportModal
            id={reportTemplate.id}
            name={reportTemplate.name}
            showLaunchReportModal={showLaunchReportModal}
            closeModal={() => {
              setReportTemplate(EMPTY_REPORT_TEMPLATE_OBJ);
              setShowLaunchReportModal(false);
            }}
          />

          <UpdateReportModal
            reportTemplate={reportTemplate}
            showUpdateReportModal={showUpdateReportModal}
            fetchReports={() =>
              fetchReports(1, reports.size, reports.sort, reports.direction)
            }
            closeModal={() => {
              setReportTemplate(EMPTY_REPORT_TEMPLATE_OBJ);
              setShowUpdateReportModal(false);
            }}
          />

          <DeleteReportModal
            id={reportTemplate.id}
            name={reportTemplate.name}
            showDeleteReportModal={showDeleteReportModal}
            fetchReports={() =>
              fetchReports(1, reports.size, reports.sort, reports.direction)
            }
            closeModal={() => {
              setReportTemplate(EMPTY_REPORT_TEMPLATE_OBJ);
              setShowDeleteReportModal(false);
            }}
          />
        </div>
      )}
    </>
  );
}
