import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FolderTree from "../components/folderTree/FolderTree";

export default function FolderModal(props) {
  const { isOpen, setIsOpen, onCheckboxClick, selectedFolders, modalTitle } =
    props;

  const volumes = useSelector(({ volumes }) => volumes.items);

  return (
    <Modal size="xl" toggle={() => setIsOpen(false)} isOpen={isOpen}>
      <ModalHeader toggle={() => setIsOpen(false)}>{modalTitle}</ModalHeader>

      <ModalBody style={{ maxHeight: "77vh", overflow: "auto" }}>
          <FolderTree
            selectedFolders={selectedFolders}
            volumes={volumes}
            hasFiles={false}
            hasRowBackground={false}
            isDefaultOpen={false}
            hasActions={false}
            onCheckboxClick={(e) => onCheckboxClick(e)}
          />
        <br />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
