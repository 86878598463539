import {
  faServer,
  faArchive,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";
import HasBundle from "../../validation/HasBundle";
import { ARCHIWARE_BUNDLE } from "../../constants/bundles";
import SourceSelectionItem from "../../components/SourceSelectionItem";

function DataSourceSelection({ manageDataSelectionAndTheirWizard }) {
  return (
    <>
      <SourceSelectionItem
        itemIcon={faServer}
        iconSize={"3x"}
        onClickFunction={() => manageDataSelectionAndTheirWizard("volume")}
        title={"On-Premises Storage"}
        description={
          "An On-Premises Storage is a data source that is scanned by a DataIntell scanner"
        }
      />

      <SourceSelectionItem
        itemIcon={faCloud}
        iconSize={"3x"}
        onClickFunction={() => manageDataSelectionAndTheirWizard("bucket")}
        title={"Cloud Storage"}
        description={
          "A Cloud Storage is an object storage service provided by cloud providers"
        }
      />

      <HasBundle
        requiredBundle={ARCHIWARE_BUNDLE}
        message="Unlock Archiware P5 Bundle"
        hasOverlay={true}
      >
        <SourceSelectionItem
          itemIcon={faArchive}
          iconSize={"3x"}
          onClickFunction={() => manageDataSelectionAndTheirWizard("archiware")}
          title={"Archiware P5 Index"}
          description={"An Index of archive Data provided by Archiware P5"}
        />
      </HasBundle>
    </>
  );
}

export default DataSourceSelection;
