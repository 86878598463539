import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";

const BORDER_STYLE = {
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "5px",
  padding: "16px"
};

export default function SearchQueryNode(props) {
  const {
    isSearchQueries,
    operator,
    numberOfFilters = 1,
    position,
    setUpdatedSearchQueryNode,
    setUpdatedRootSearchQueryNode,
    removeSearchQueryNode,
    children
  } = props;

  const update = (searchOperator) => position.length === 1 ? setUpdatedRootSearchQueryNode(searchOperator, !isSearchQueries) : setUpdatedSearchQueryNode(searchOperator);

  return (
    <div style={numberOfFilters > 1 ? BORDER_STYLE : {}}>
      <Row>
        <Col sm={10}>
          {children}
        </Col>
        <Col sm={2}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            {(numberOfFilters <= 1 || operator === "AND") &&
              <Button className="me-1" onClick={() => update("AND")}>AND</Button>
            }
            {(numberOfFilters <= 1 || operator === "OR") &&
              <Button className="me-1" onClick={() => update("OR")}>OR</Button>
            }
            {position.length > 1 &&
              <Button
                color="danger"
                outline
                onClick={() => removeSearchQueryNode()}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}