import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function EulaModal({ isModalOpen, acceptEula, rejectEula }) {

    const style = {
        headerli: { textAlign: "left", marginBottom: "10.0pt", marginTop: "10.0pt", listStyle: "none" },
        headerspan: { fontStyle: "normal", fontWeight: "bold", textDecoration: "underline" }
    }

    return (
        <div className="static-modal">
            <Modal size="lg" isOpen={isModalOpen}>
                <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center font-weight-bold' }}>End User Licence Agreement</ModalHeader>

                <ModalBody>
                    <ol style={{ height: "500px", overflow: "scroll" }} start="1">
                        <li style={style.headerli}><span
                            style={style.headerspan}>Licence</span><span><br /></span>
                        </li>
                        <li value="1"><span>Under this End User Licence Agreement (the "Agreement"),
                            Ordigraphe Inc. (the "Vendor") grants to the user (the "Licensee") a non-exclusive and non-transferable
                            licence (the "Licence") to use DataIntell (the "Software").</span><span><br /></span>
                        </li>
                        <li value="2"><span>"Software" includes the executable computer programs and any
                            related printed, electronic and online documentation and any other files that may accompany the
                            product.</span><span><br /></span>
                        </li>
                        <li value="3"><span>Title, copyright, intellectual property rights and
                            distribution rights of the Software remain exclusively with the Vendor. Intellectual property rights
                            include the look and feel of the Software. This Agreement constitutes a licence for use only and is not in
                            any way a transfer of ownership rights to the Software.</span><span><br /></span>
                        </li>
                        <li value="4"><span>The Software may be loaded onto no more than one computer. A
                            single copy may be made for backup purposes only.</span><span ><br /></span>
                        </li>
                        <li value="5"><span>The rights and obligations of this Agreement are personal
                            rights granted to the Licensee only. The Licensee may not transfer or assign any of the rights or
                            obligations granted under this Agreement to any other person or legal entity. The Licensee may not make
                            available the Software for use by one or more third parties.</span><span ><br /></span>
                        </li>
                        <li value="6"><span>The Software may not be modified, reverse-engineered, or
                            de-compiled in any manner through current or future available technologies.</span><span
                            ><br /></span>
                        </li>
                        <li value="7"><span>Failure to comply with any of the terms under the Licence
                            section will be considered a material breach of this Agreement.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Licence Fee</span><span
                            ><br /></span>
                        </li>
                        <li value="8"><span>The original purchase price paid by the Licensee will
                            constitute the entire licence fee and is the full consideration for this Agreement.</span><span
                            ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Limitation of Liability</span><span
                            ><br /></span>
                        </li>
                        <li value="9"><span>The Software is provided by the Vendor and accepted by the
                            Licensee "as is". Liability of the Vendor will be limited to a maximum of the original purchase price of
                            the Software. The Vendor will not be liable for any general, special, incidental or consequential damages
                            including, but not limited to, loss of production, loss of profits, loss of revenue, loss of data, or any
                            other business or economic disadvantage suffered by the Licensee arising out of the use or failure to use
                            the Software.</span><span ><br /></span>
                        </li>
                        <li value="10"><span>The Vendor makes no warranty expressed or implied regarding
                            the fitness of the Software for a particular purpose or that the Software will be suitable or appropriate
                            for the specific requirements of the Licensee.</span><span ><br /></span>
                        </li>
                        <li value="11"><span>The Vendor does not warrant that use of the Software will
                            be uninterrupted or error-free. The Licensee accepts that software in general is prone to bugs and flaws
                            within an acceptable level as determined in the industry.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Warrants and
                            Representations</span><span ><br /></span>
                        </li>
                        <li value="12"><span>The Vendor warrants and represents that it is the copyright
                            holder of the Software. The Vendor warrants and represents that granting the licence to use this Software
                            is not in violation of any other agreement, copyright or applicable statute.</span><span
                            ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Acceptance</span><span
                            ><br /></span>
                        </li>
                        <li value="13"><span>All terms, conditions and obligations of this Agreement
                            will be deemed to be accepted by the Licensee ("Acceptance") on installation of the Software.</span><span
                            ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>User Support</span><span
                            ><br /></span>
                        </li>
                        <li value="14"><span>The Licensee will be entitled to the duration of the
                            license of email support available 9:00 AM to 5:00 PM Eastern, Weekdays only, at no additional
                            cost.</span><span ><br /></span>
                        </li>
                        <li value="15"><span>The Licensee will be entitled to maintenance upgrades and
                            bug fixes, at no additional cost, for the duration of the license.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Term</span><span
                            ><br /></span>
                        </li>
                        <li value="16"><span>The term of this Agreement will begin on Acceptance and is
                            perpetual.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Termination</span><span
                            ><br /></span>
                        </li>
                        <li value="17"><span>This Agreement will be terminated and the Licence forfeited
                            where the Licensee has failed to comply with any of the terms of this Agreement or is in breach of this
                            Agreement. On termination of this Agreement for any reason, the Licensee will promptly destroy the
                            Software or return the Software to the Vendor.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Force Majeure</span><span
                            ><br /></span>
                        </li>
                        <li value="18"><span>The Vendor will be free of liability to the Licensee where
                            the Vendor is prevented from executing its obligations under this Agreement in whole or in part due to
                            Force Majeure, such as earthquake, typhoon, flood, fire, and war or any other unforeseen and
                            uncontrollable event where the Vendor has taken any and all appropriate action to mitigate such an
                            event.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Governing Law</span><span
                            ><br /></span>
                        </li>
                        <li value="19"><span>The Parties to this Agreement submit to the jurisdiction of
                            the courts of the Province of Quebec in Canada for the enforcement of this Agreement or any arbitration
                            award or decision arising from this Agreement. This Agreement will be enforced or construed according to
                            the laws of the Province of Quebec in Canada.</span><span ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Miscellaneous</span><span
                            ><br /></span>
                        </li>
                        <li value="20"><span>This Agreement can only be modified in writing signed by
                            both the Vendor and the Licensee.</span><span ><br /></span>
                        </li>
                        <li value="21"><span>This Agreement does not create or imply any relationship in
                            agency or partnership between the Vendor and the Licensee.</span><span ><br /></span>
                        </li>
                        <li value="22"><span>Headings are inserted for the convenience of the parties
                            only and are not to be considered when interpreting this Agreement. Words in the singular mean and include
                            the plural and vice versa. Words in the masculine gender include the feminine gender and vice versa. Words
                            in the neuter gender include the masculine gender and the feminine gender and vice versa.</span><span
                            ><br /></span>
                        </li>
                        <li value="23"><span>If any term, covenant, condition or provision of this
                            Agreement is held by a court of competent jurisdiction to be invalid, void or unenforceable, it is the
                            parties' intent that such provision be reduced in scope by the court only to the extent deemed necessary
                            by that court to render the provision reasonable and enforceable and the remainder of the provisions of
                            this Agreement will in no way be affected, impaired or invalidated as a result.</span><span
                            ><br /></span>
                        </li>
                        <li value="24"><span>This Agreement contains the entire agreement between the
                            parties. All understandings have been included in this Agreement. Representations which may have been made
                            by any party to this Agreement may in some way be inconsistent with this final written Agreement. All such
                            statements are declared to be of no value in this Agreement. Only the written terms of this Agreement will
                            bind the parties.</span><span ><br /></span>
                        </li>
                        <li value="25"><span>This Agreement and the terms and conditions contained in
                            this Agreement apply to and are binding upon the Vendor's successors and assigns.</span><span
                            ><br /></span>
                        </li>
                        <li style={style.headerli}><span
                            style={style.headerspan}>Notices</span><span
                            ><br /></span>
                        </li>
                        <li value="26"><span>All notices to the Vendor under this Agreement are to be
                            provided at the following address:<br />Ordigraphe Inc.: 1225 chemin St-Jean, La Prairie, J5R 2L6, Qc,
                            Canada</span><span ><br /></span>
                        </li>
                    </ol>
                </ModalBody>

                <ModalFooter>
                    <Button outline color="secondary" onClick={rejectEula}>
                        Decline
                    </Button>{" "}
                    <Button color="primary" onClick={acceptEula}>
                        Accept
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}