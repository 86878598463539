import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import ChartCard from "./ChartCard";

export default function DuplicateChart(props) {
  const {
    filters,
    search,
    addFilters,
    lastAnalyticsSearch,
    setRef = () => {},
    setSubtitle = () => {},
    setSizeItems = () => {},
    sizeOfFiles,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);

  const dispatch = useDispatch();

  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);

  const fetchData = async () => {
    setIsFetching(true);
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName: "isDupe",
      size: 50,
      indexingDate: activeDate
    };
    
    const duplicates = await fetchWithAuthV2(dispatch, url, accessToken, { ...queryParams, ...searchParams });
    duplicates.forEach((duplicateObj) => {
      if (duplicateObj.field === "1") {
        duplicateObj.field = "Duplicate Data";
      } else {
        duplicateObj.field = "Unique Data";
      }
    });
    setDuplicateFiles(duplicates);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [lastAnalyticsSearch]);

  const addDuplicateFilter = (index) => {
    if (null !== index) {
      addFilters(
        ["onlyDuplicates"],
        [duplicateFiles[index].field === "Duplicate Data" ? "true" : "false"]
      );
    }
  };

  return (
    <ChartCard
      title="Duplicate Data"
      elements={duplicateFiles}
      addFilterFunc={addDuplicateFilter}
      isFetching={isFetching}
      setRef={setRef}
      setSubtitle={setSubtitle}
      setSizeItems={setSizeItems}
      componentId="duplicateChartId"
      sizeOfFiles={sizeOfFiles}
    />
  );
}
