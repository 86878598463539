import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithAuth } from "../actions/requestHelper";
import TableResult from "../components/TableResult";
import Loading from "../components/Loading";
import { fetchServerList } from "../ducks/server";
import moment from "moment";
import GeneralError from "../components/GeneralError";
import JobsModal from "../components/JobsModal";
import JobStatusColorScheme from "./components/JobStatusColorScheme";
import TableTitleTooltip from "../components/TableTitleTooltip";
import JobsDuration from "./components/JobsDuration";
import TableActions from "../components/TableActions";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faSync } from "@fortawesome/free-solid-svg-icons";
import { get, update } from "../api/job";
import { setAlert } from "../actions/alert";
import ConfirmModal from "../components/ConfirmModal";
import JobFilters from "./components/JobFilters";
import RelaunchJobModal from "./components/RelaunchJobModal";
import CancelJobsModal from "./components/CancelJobsModal";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export default function Jobs() {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const servers = useSelector(({ servers }) => servers.items);
  const [jobs, setJobs] = useState([]);
  const [totalJobsPage, setTotalJobsPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [jobFetchError, setJobFetchError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [showRelaunchJobModal, setShowRelaunchJobModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [autoRefresh, setAutoRefresh] = useState(0);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [showCancelJobsModal, setShowCancelJobsModal] = useState(false);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "desc",
    size: window.localStorage.getItem("numberOfElementsJobs")
      ? Number(window.localStorage.getItem("numberOfElementsJobs"))
      : 25,
    page: 1,
  });
  const [activeTab, setActiveTab] = useState("0");

  const location = useLocation();

  const DELAY = 30;
  let timer = null;

  useEffect(() => {
    dispatch(fetchServerList());

    const queryParams = queryString.parse(location.search);

    if (queryParams.id) {
      get(dispatch, auth, queryParams.id)
        .then((res) => {
          setSelectedJob(res);
          setShowModal(true);
        })
        .catch((err) => {
          console.error(err);
          dispatch(setAlert(`Error while getting job with id [${queryParams.id}]`, "danger", 8000));
        });
    }
  }, []);

  useEffect(() => {
    getJobList(
      pagination.page,
      pagination.size,
      pagination.sort,
      pagination.direction
    );
  }, [
    selectedJobTypes,
    pagination.page,
    pagination.size,
    pagination.sort,
    pagination.direction,
  ]);

  useEffect(() => {
    if (selectedJob.id) {
      setSelectedJob(getUpdatedJob(selectedJob.id));
    }
  }, [jobs]);

  useEffect(() => {
    if (!showRelaunchJobModal) {
      if (autoRefresh % DELAY === 0 && autoRefresh !== 0) {
        getJobList(
          pagination.page,
          pagination.size,
          pagination.sort,
          pagination.direction
        );
      }

      startTime();
    }
  }, [autoRefresh, showRelaunchJobModal]);

  const startTime = () => {
    timer = setTimeout(() => setAutoRefresh(autoRefresh + 1), 1000);
  };

  const updateJob = async (job) => {
    job.status = "CANCELLED";
    try {
      await update(dispatch, auth, job, job.id);
      dispatch(setAlert("Job was cancelled successfully", "success"));
    } catch (err) {
      const message = err.message || "Unable to cancel job";
      dispatch(setAlert(message, "danger"));
    }
  };

  const getFormattedTypes = () => {
    return selectedJobTypes
      .map((jobTypeObj) => `&types=${jobTypeObj.value}`)
      .join("");
  };

  const getJobList = async (
    page = 1,
    size = 10,
    sort = "id",
    direction = "desc"
  ) => {
    setFetching(true);
    setJobFetchError(false);
    try {
      const res = await fetchWithAuth(
        dispatch,
        `/v1/jobs?page=${page}&size=${size}&sort=${sort}&direction=${direction}${getFormattedTypes()}`,
        auth.accessToken,
        "GET"
      );
      setJobs(res.elements);
      setTotalJobsPage(Math.ceil(res.total / res.size));
    } catch (e) {
      setJobFetchError(true);
      setFetching(false);
      console.error(e.message);
    }
    setFetching(false);
  };
  const getUpdatedJob = (jobId) => {
    return jobs.find((job) => job.id === jobId);
  };

  const sortColumn = (sortBy) => {
    const direction =
      pagination.direction === "asc" && sortBy === pagination.sort
        ? "desc"
        : "asc";
    setPagination((prev) => ({ ...prev, sort: sortBy, direction: direction }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsJobs", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const findServerName = (job) => {
    const serverName = servers.find(
      (server) => job.serverId === server.id
    )?.name;
    return serverName ? serverName : "N/A";
  };

  const listFormated = jobs.map((job) => {
    const lineObject = {
      columnData: [],
      style: {
        cursor: "pointer",
      },
      options: {
        linkData: job,
      },
    };

    lineObject.columnData = [
      job.id,
      job.type,
      findServerName(job),
      moment(job.createdAt).format("lll"),
      moment(job.startedAt).format("lll"),
      moment(job.updatedAt).format("lll"),
      <JobsDuration
        startTime={new Date(job.startedAt).getTime()}
        updateTime={new Date(job.updatedAt).getTime()}
        status={job.status}
      />,
      <JobStatusColorScheme
        status={job.status}
        action={() => setShowCancelJobModal(true)}
        isRelaunchable={job.type === "SCAN_ON_PREMISES"}
        relaunch={() => {
          setShowRelaunchJobModal(true);
        }}
      />,
    ];

    return lineObject;
  });

  const updateTimeFragment = (
    <>
      Update Time{" "}
      <TableTitleTooltip target="jobUpdateTime">
        Last time the status of the job was updated
      </TableTitleTooltip>
    </>
  );

  const fieldTitles = [
    { name: "Id", sort: "id" },
    { name: "Type", sort: "type" },
    { name: "Server Name", sort: "serverId" },
    { name: "Creation Time", sort: "createdAt" },
    { name: "Start Time", sort: "startedAt" },
    { name: updateTimeFragment, sort: "updatedAt" },
    { name: "Duration" },
    { name: "Status", sort: "status" },
  ];

  return (
    <>
      <div className="d-flex">
        <h1>Jobs</h1>
        <div className="ms-auto" style={{ marginTop: "7px" }}>
          The content of this page will be refreshed in{" "}
          {DELAY - (autoRefresh % DELAY)} seconds
        </div>
        <div className="ms-2">
          <Button
            id="refreshJobList"
            color="secondary"
            onClick={() => {
              getJobList(
                pagination.page,
                pagination.size,
                pagination.sort,
                pagination.direction
              );
              clearTimeout(timer);
              setAutoRefresh(0);
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
          <UncontrolledTooltip placement="auto" target="refreshJobList">
            Force refresh job list
          </UncontrolledTooltip>
        </div>

        <div className="ms-2">
          <Button
            id="cancelJobs"
            color="danger"
            onClick={() => {
              setShowCancelJobsModal(true);
            }}
          >
            <FontAwesomeIcon icon={faCancel} />
          </Button>
          <UncontrolledTooltip placement="auto" target="cancelJobs">
            Cancel jobs
          </UncontrolledTooltip>
        </div>
      </div>

      {fetching ? (
        <Loading />
      ) : jobFetchError ? (
        <GeneralError
          customErrorMessage={"Something went wrong while fetching the jobs"}
        />
      ) : (
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={totalJobsPage}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(page) =>
              setPagination((prev) => ({ ...prev, page: page }))
            }
          >
            <JobFilters
              selectedJobTypes={selectedJobTypes}
              setSelectedJobTypes={setSelectedJobTypes}
            />
          </TableActions>
          <TableResult
            data={listFormated}
            titles={fieldTitles}
            changeOrder={sortColumn}
            sort={pagination.sort}
            direction={pagination.direction}
            handleClick={(job) => {
              setSelectedJob(job);
              setShowModal(true);
              setActiveTab(job.status === "RUNNING" ? "1" : "0");
            }}
          />
        </div>
      )}

      {showRelaunchJobModal && (
        <RelaunchJobModal
          closeFunction={() => {
            setShowRelaunchJobModal(false);
            setShowModal(false);
            setSelectedJob({});
          }}
          id={selectedJob.id}
        />
      )}

      <ConfirmModal
        showConfirmation={showCancelJobModal}
        title="Remove Confirmation"
        buttonColor="danger"
        buttonText="Remove"
        confirmAction={() => {
          updateJob(selectedJob);
          setShowModal(false);
          setShowCancelJobModal(false);
          setSelectedJob({});
        }}
        closeConfirmation={() => {
          setShowModal(false);
          setShowCancelJobModal(false);
          setSelectedJob({});
        }}
      >
        Are you sure you want to remove from queue the job with id{" "}
        {selectedJob.id}?
      </ConfirmModal>

      <JobsModal
        isOpen={showModal && !showCancelJobModal && !showRelaunchJobModal}
        closeFunction={() => {
          setSelectedJob({});
          setShowModal(false);
        }}
        selectedJob={selectedJob}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setShowCancelJobModal={setShowCancelJobModal}
        setShowRelaunchJobModal={setShowRelaunchJobModal}
        findServerName={findServerName}
      />

      <CancelJobsModal
        isModalOpen={showCancelJobsModal}
        onCloseModal={() => {
          setShowCancelJobsModal(false);
          getJobList(
            pagination.page,
            pagination.size,
            pagination.sort,
            pagination.direction
          );
        }}
      />
    </>
  );
}
