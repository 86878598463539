import { useEffect, useState } from "react";
import TableActions from "../../../components/TableActions";
import TableResult from "../../../components/TableResult";
import ConfirmModal from "../../../components/ConfirmModal";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  createStorageManagerMapping,
  deleteStorageManagerMapping,
  getStorageManagerVolumeMappings,
  updateStorageManagerMapping,
} from "../../../api/storageManager";
import { setAlert } from "../../../actions/alert";
import StorageManagerMappingForm from "./StorageManagerMappingForm";

export default function StorageManagerMappingList(props) {
  const { credentials } = props;

  const [total, setTotal] = useState(0);
  const [showCreateMappingsForm, setShowCreateMappingsForm] = useState(false);
  const [showUpdateMappingsForm, setShowUpdateMappingsForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [id, setId] = useState(null);
  const [storageId, setStorageId] = useState(null);
  const [credentialId, setCredentialId] = useState(null);
  const [dataIntellPath, setDataIntellPath] = useState("");
  const [storageManagerPath, setStorageManagerPath] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [mappings, setMappings] = useState([]);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem(
      "numberOfElementsDataSourceStorageManager"
    )
      ? Number(
          window.localStorage.getItem(
            "numberOfElementsDataSourceStorageManager"
          )
        )
      : 10,
    page: 1,
  });

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const volumes = useSelector(({ volumeSettings }) =>
    volumeSettings.items.filter(
      (volume) =>
        volume.type === "crawl.files" ||
        volume.type === "NetworkVolume" ||
        volume.type === "DellPowerScale"
    )
  );

  useEffect(() => {
    fetchMappings(
      pagination.page,
      pagination.size,
      pagination.sort,
      pagination.direction
    );
  }, [pagination.page, pagination.sort, pagination.direction, pagination.size]);

  const fetchMappings = async (page, size, sort, direction) => {
    setIsFetching(true);
    try {
      const res = await getStorageManagerVolumeMappings(
        dispatch,
        auth,
        page,
        sort,
        direction,
        size
      );
      setTotal(res.total);
      setMappings(res.elements);
      setIsFetching(false);
    } catch (err) {
      const message =
        err.message ||
        "Unable to fetch Quantum StorNext storage path configurations";
      dispatch(setAlert(message, "danger"));
      setIsFetching(false);
    }
  };

  const createMapping = async (mapping) => {
    try {
      await createStorageManagerMapping(dispatch, auth, mapping);
      await fetchMappings(
        pagination.page,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert(
          "Quantum StorNext storage path configuration was created successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to create Quantum StorNext storage path configuration";
      dispatch(setAlert(message, "danger"));
    }
  };

  const updateMapping = async (id, mapping) => {
    try {
      await updateStorageManagerMapping(dispatch, auth, id, mapping);
      await fetchMappings(
        pagination.page,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert(
          "Quantum StorNext storage path configuration was updated successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to update Quantum StorNext storage path configuration";
      dispatch(setAlert(message, "danger"));
    }
  };

  const deleteMapping = async (id) => {
    try {
      await deleteStorageManagerMapping(dispatch, auth, id);
      await fetchMappings(
        pagination.page,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert(
          "Quantum StorNext storage path configuration was deleted successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to delete Quantum StorNext storage path configuration";
      dispatch(setAlert(message, "danger"));
    }
  };

  const numberOfPages = Math.ceil(total / pagination.size);
  const mappingTitles = [
    { name: "Id", sort: "id" },
    { name: "On-Premises Storage" },
    { name: "Quantum StorNext Credentials" },
    { name: "DataIntell Path" },
    { name: "Quantum StorNext Path" },
    { name: "Action" },
  ];

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({
      ...prev,
      sort: newSort,
      direction: newDirection,
    }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem(
      "numberOfElementsDataSourceStorageManager",
      numberOfElements
    );
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const listFormated = mappings.map((mapping) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      mapping.id,
      volumes.find((volume) => volume.id === mapping.volumeId)?.name,
      credentials.find(
        (credential) => credential.id === mapping.storageManagerConfigId
      )?.name,
      mapping.dataintellPath,
      mapping.storageManagerPath,
      <>
        <Button
          onClick={() => {
            setShowUpdateMappingsForm(true);
            setId(mapping.id);
            setStorageId(mapping.volumeId);
            setCredentialId(mapping.storageManagerConfigId);
            setDataIntellPath(mapping.dataintellPath);
            setStorageManagerPath(mapping.storageManagerPath);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(mapping.id);
            setStorageId(mapping.volumeId);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateMappingsForm(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  const handleCloseModal = () => {
    setShowCreateMappingsForm(false);
    setShowUpdateMappingsForm(false);
    setShowDeleteConfirmation(false);
    setId(null);
    setStorageId("");
    setCredentialId("");
    setDataIntellPath("");
    setStorageManagerPath("");
  };

  return (
    <>
      <h5>Storage Path Configurations</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) =>
              setPagination((prev) => ({ ...prev, page: newPage }))
            }
          />
          <TableResult
            data={listFormated}
            titles={mappingTitles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      )}
      <ConfirmModal
        showConfirmation={showCreateMappingsForm}
        title="Add Storage Path Configurations"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createMapping({
            volumeId: storageId,
            storageManagerConfigId: credentialId,
            dataintellPath: dataIntellPath,
            storageManagerPath: storageManagerPath,
          });
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
        disableRightButton={disableRightButton}
      >
        <StorageManagerMappingForm
          storageId={storageId}
          setStorageId={setStorageId}
          credentialId={credentialId}
          setCredentialId={setCredentialId}
          dataIntellPath={dataIntellPath}
          setDataIntellPath={setDataIntellPath}
          storageManagerPath={storageManagerPath}
          setStorageManagerPath={setStorageManagerPath}
          volumes={volumes}
          credentials={credentials}
          setDisableCreateButton={setDisableRightButton}
          mappings={mappings}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateMappingsForm}
        title="Update Storage Path Configurations"
        buttonColor="primary"
        buttonText="Update"
        confirmAction={() => {
          updateMapping(id, {
            volumeId: storageId,
            storageManagerConfigId: credentialId,
            dataintellPath: dataIntellPath,
            storageManagerPath: storageManagerPath,
          });
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
        disableRightButton={disableRightButton}
      >
        <StorageManagerMappingForm
          storageId={storageId}
          setStorageId={setStorageId}
          credentialId={credentialId}
          setCredentialId={setCredentialId}
          dataIntellPath={dataIntellPath}
          setDataIntellPath={setDataIntellPath}
          storageManagerPath={storageManagerPath}
          setStorageManagerPath={setStorageManagerPath}
          volumes={volumes}
          credentials={credentials}
          setDisableCreateButton={setDisableRightButton}
          mappings={mappings}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteMapping(id);
          handleCloseModal();
        }}
        closeConfirmation={() => handleCloseModal()}
      >
        Are you sure you want to delete the storage path configuration{" "}
        {volumes.find((volume) => volume.id === storageId)?.name}?
      </ConfirmModal>
    </>
  );
}
