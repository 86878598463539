import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Collapse,
  Card,
  CardBody,
  UncontrolledTooltip,
  Input,
  InputGroup,
} from "reactstrap";

import {
  searchFiles,
  searchFolders,
  searchSize,
  setSearchFilters,
  clearSearchFilterValues,
  setSearchFilterValue,
  updateSearchFile,
  updateSearchFolder,
} from "../ducks/search";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare, faFilter } from "@fortawesome/free-solid-svg-icons";
import HistogramDateChart from "./components/HistogramDateChart";
import { fetchVolumes } from "../actions";
import FileList from "../components/FileList";
import AdvancedSearchFilters from "./components/AdvancedSearchFilters";
import ActiveFilters from "./components/ActiveFilters";
import queryString from "query-string";
import Tabs from "../components/Tabs";
import FileDetailsModal from "../components/FileDetailsModal";
import FolderList from "../components/FolderList";
import FolderDetailsModal from "../components/FolderDetailsModal";
import { fetchFolderDetailsByHash } from "../ducks/folderDetails";
import { fetchTags, updateTags } from "../ducks/tag";
import { StorageTypeChart } from "./components/StorageTypeChart";
import { fetchStoragePriceList } from "../ducks/storagePrice";
import ExtensionChart from "./components/ExtensionChart";
import DuplicateChart from "./components/DuplicateChart";
import GroupChart from "./components/GroupChart";
import VolumeChart from "./components/VolumeChart";
import OwnerChart from "./components/OwnerChart";
import ShareModal from "./components/ShareModal";
import SearchPDF from "./components/SearchPDF";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      showFileDetailsModal: false,
      activeFile: {},
      showFolderDetailsModal: false,
      activeFolder: {},
      activeTab: "0",
      lastAnalyticsSearch: null,
      lastFileSearch: null,
      lastFolderSearch: null,
      isFiltersCardOpen: false,
      selectedDataSourceOptions: [],
      checkboxStatus: {
        volume: false,
        bucket: false,
        archive: false,
      },
      showShareModal: false,
      size: 50,
      creationRef: null,
      lastAccessRef: null,
      lastModifiedRef: null,
      extensionRef: null,
      volumesRef: null,
      ownersRef: null,
      groupsRef: null,
      duplicateRef: null,
      storageTypeRef: null,
      subtitleCreation: null,
      timeUnitCreation: null,
      timeUnitLastAccess: null,
      timeUnitLastModified: null,
      subtitleStorageType: null,
      subtitleLastAccess: null,
      subtitleLastModified: null,
      subtitleExtension: null,
      subtitleVolumes: null,
      subtitleOwners: null,
      subtitleGroups: null,
      subtitleDuplicate: null,
      sizeItemsExtension: null,
      sizeItemsVolumes: null,
      sizeItemsOwners: null,
      sizeItemsGroups: null,
      sizeItemsDuplicate: null,
      timeUnitStorageType: null,
      sizeShownDataCreation: null,
      sizeShownDataLastAccess: null,
      sizeShownDataLastModified: null,
      sizeShownDataExtension: null,
      sizeShownDataVolumes: null,
      sizeShownDataOwners: null,
      sizeShownDataGroups: null,
    };

    this.searchFiles = this.searchFiles.bind(this);
    this.changeFilesOrder = this.changeFilesOrder.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.handleChangeNumberOfElements =
      this.handleChangeNumberOfElements.bind(this);
    this.creationRef = this.setCreationRef.bind(this);
    this.lastAccessRef = this.setLastAccessRef.bind(this);
    this.lastModifiedRef = this.setLastModifiedRef.bind(this);
    this.extensionRef = this.setExtensionRef.bind(this);
    this.volumesRef = this.setVolumesRef.bind(this);
    this.ownersRef = this.setOwnersRef.bind(this);
    this.groupsRef = this.setGroupsRef.bind(this);
    this.duplicateRef = this.setDuplicateRef.bind(this);
    this.storageTypeRef = this.setStorageTypeRef.bind(this);
    this.subtitleCreation = this.setSubtitleCreation.bind(this);
    this.subtitleStorageType = this.setSubtitleStorageType.bind(this);
    this.subtitleLastAccess = this.setSubtitleLastAccess.bind(this);
    this.subtitleLastModified = this.setSubtitleLastModified.bind(this);
    this.subtitleExtension = this.setSubtitleExtension.bind(this);
    this.subtitleVolumes = this.setSubtitleVolumes.bind(this);
    this.subtitleOwners = this.setSubtitleOwners.bind(this);
    this.subtitleGorups = this.setSubtitleGroups.bind(this);
    this.subtitleDuplicate = this.setSubtitleDuplicate.bind(this);
    this.sizeItemsExtension = this.setSizeItemsExtension.bind(this);
    this.sizeItemsVolumes = this.setSizeItemsVolumes.bind(this);
    this.sizeItemsOwners = this.setSizeItemsOwners.bind(this);
    this.sizeItemsGorups = this.setSizeItemsGroups.bind(this);
    this.sizeItemsDuplicate = this.setSizeItemsDuplicate.bind(this);
    this.timeUnitLastAccess = this.setTimeUnitLastAccess.bind(this);
    this.timeUnitLastModified = this.setTimeUnitLastModified.bind(this);
    this.timeUnitCreation = this.setTimeUnitCreation.bind(this);
    this.timeUnitStorageType = this.setTimeUnitStorageType.bind(this);
    this.sizeShownDataCreation = this.setSizeShownDataCreation.bind(this);
    this.sizeShownDataLastAccess = this.setSizeShownDataLastAccess.bind(this);
    this.sizeShownDataLastModified =
      this.setSizeShownDataLastModified.bind(this);

    this.sizeShownDataExtension = this.setSizeShownDataExtension.bind(this);
    this.sizeShownDataOwners = this.setSizeShownDataOwners.bind(this);
    this.sizeShownDataVolumes = this.setSizeShownDataVolumes.bind(this);
    this.sizeShownDataGroups = this.setSizeShownDataGroups.bind(this);
  }
  //TODO: Use an Object of Refs with subtitle for each Ref

  setTimeUnitLastAccess(element) {
    this.setState({ timeUnitLastAccess: element });
  }
  setTimeUnitLastModified(element) {
    this.setState({ timeUnitLastModified: element });
  }
  setSubtitleLastAccess(element) {
    this.setState({ subtitleLastAccess: element });
  }
  setSubtitleLastModified(element) {
    this.setState({ subtitleLastModified: element });
  }
  setSizeItemsExtension(element) {
    this.setState({ sizeItemsExtension: element });
  }
  setSizeItemsVolumes(element) {
    this.setState({ sizeItemsVolumes: element });
  }
  setSizeItemsOwners(element) {
    this.setState({ sizeItemsOwners: element });
  }
  setSizeItemsGroups(element) {
    this.setState({ sizeItemsGroups: element });
  }
  setSizeItemsDuplicate(element) {
    this.setState({ sizeItemsDuplicate: element });
  }
  setSubtitleExtension(element) {
    this.setState({ subtitleExtension: element });
  }
  setSubtitleVolumes(element) {
    this.setState({ subtitleVolumes: element });
  }
  setSubtitleOwners(element) {
    this.setState({ subtitleOwners: element });
  }
  setSubtitleGroups(element) {
    this.setState({ subtitleGroups: element });
  }
  setSubtitleDuplicate(element) {
    this.setState({ subtitleDuplicate: element });
  }

  setTimeUnitCreation(element) {
    this.setState({ timeUnitCreation: element });
  }
  setSubtitleCreation(element) {
    this.setState({ subtitleCreation: element });
  }
  setSubtitleStorageType(element) {
    this.setState({ subtitleStorageType: element });
  }
  setTimeUnitStorageType(element) {
    this.setState({ timeUnitStorageType: element });
  }
  setCreationRef(element) {
    this.setState({ creationRef: element });
  }
  setLastAccessRef(element) {
    this.setState({ lastAccessRef: element });
  }
  setLastModifiedRef(element) {
    this.setState({ lastModifiedRef: element });
  }
  setExtensionRef(element) {
    this.setState({ extensionRef: element });
  }
  setVolumesRef(element) {
    this.setState({ volumesRef: element });
  }
  setOwnersRef(element) {
    this.setState({ ownersRef: element });
  }
  setGroupsRef(element) {
    this.setState({ groupsRef: element });
  }
  setDuplicateRef(element) {
    this.setState({ duplicateRef: element });
  }
  setStorageTypeRef(element) {
    this.setState({ storageTypeRef: element });
  }
  setSizeShownDataCreation(element) {
    this.setState({ sizeShownDataCreation: element });
  }
  setSizeShownDataLastAccess(element) {
    this.setState({ sizeShownDataLastAccess: element });
  }
  setSizeShownDataLastModified(element) {
    this.setState({ sizeShownDataLastModified: element });
  }
  setSizeShownDataExtension(element) {
    this.setState({ sizeShownDataExtension: element });
  }
  setSizeShownDataVolumes(element) {
    this.setState({ sizeShownDataVolumes: element });
  }
  setSizeShownDataOwners(element) {
    this.setState({ sizeShownDataOwners: element });
  }
  setSizeShownDataGroups(element) {
    this.setState({ sizeShownDataGroups: element });
  }

  search = (search, page, size, filters, sort, direction, activeTab) => {
    const { dispatch } = this.props;
    const { lastAnalyticsSearch, lastFileSearch, lastFolderSearch } =
      this.state;
    const searchObject = JSON.stringify({
      search,
      page,
      size,
      filters,
      sort,
      direction,
    });

    if (activeTab === "0" && lastFileSearch !== searchObject) {
      dispatch(searchFiles(search, page, size, filters, sort, direction));
      dispatch(searchSize(search, filters));
      this.setState({ lastFileSearch: searchObject });
    } else if (activeTab === "1" && lastFolderSearch !== searchObject) {
      dispatch(searchFolders(search, page, size, filters, sort, direction));
      dispatch(searchSize(search, filters));
      this.setState({ lastFolderSearch: searchObject });
    } else if (activeTab === "2" && lastAnalyticsSearch !== searchObject) {
      if (lastFileSearch !== searchObject) {
        dispatch(searchSize(search, filters));
      }
      this.setState({ lastAnalyticsSearch: searchObject });
    }
  };

  formatVolumeIdsFromPath = (volumeIds) => {
    if (Array.isArray(volumeIds)) {
      return volumeIds;
    } else if (volumeIds) {
      return [volumeIds];
    } else {
      return "";
    }
  };

  getFiltersFromPath(search = window.location.search) {
    const queryParams = queryString.parse(search);
    if (typeof queryParams.volumeAndPath === "string")
      queryParams.volumeAndPath = [queryParams.volumeAndPath];
    if (typeof queryParams.ignorePattern === "string")
      queryParams.ignorePattern = [queryParams.ignorePattern];
    if (typeof queryParams.tag === "string")
      queryParams.tags = [queryParams.tag];
    if (typeof queryParams.report === "string")
      queryParams.reports = [queryParams.report];

    if (queryParams) {
      this.setState({ search: queryParams.search || "" });
      return {
        activeTab: queryParams.activeTab || "0",
        startingPath: queryParams.startingPath || "",
        lastAccessAfter: queryParams.lastAccessAfter || "",
        lastAccessBefore: queryParams.lastAccessBefore || "",
        owner: queryParams.owner || "",
        extension: queryParams.extension || "",
        volumeIds: this.formatVolumeIdsFromPath(queryParams.volumeId),
        lastModifiedAfter: queryParams.lastModifiedAfter || "",
        lastModifiedBefore: queryParams.lastModifiedBefore || "",
        group: queryParams.group || "",
        onlyDuplicates: queryParams.onlyDuplicates || "",
        minimumSize: queryParams.minimumSize || "",
        minimumSizeUnit: queryParams.minimumSizeUnit || "B",
        maximumSize: queryParams.maximumSize || "",
        maximumSizeUnit: queryParams.maximumSizeUnit || "B",
        showOnlyDeletedFiles: queryParams.showOnlyDeletedFiles || "",
        creationAfter: queryParams.creationAfter || "",
        creationBefore: queryParams.creationBefore || "",
        volumesAndPaths: queryParams.volumeAndPath || [""],
        ignorePatterns: queryParams.ignorePattern || [""],
        tags: queryParams.tags,
        reports: queryParams.reports,
        name: queryParams.name || "",
        minimumNumberOfFiles: queryParams.minimumNumberOfFiles || "",
        maximumNumberOfFiles: queryParams.maximumNumberOfFiles || "",
        minimumNumberOfFolders: queryParams.minimumNumberOfFolders || "",
        maximumNumberOfFolders: queryParams.maximumNumberOfFolders || "",
      };
    }
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });

      const { files } = this.props;

      const fileSortValue = window.localStorage.getItem("fileSort");
      const fileDirectionValue = window.localStorage.getItem("fileDirection");
      const folderSortValue = window.localStorage.getItem("folderSort");
      const folderDirectionValue =
        window.localStorage.getItem("folderDirection");

      if (tab === "1") {
        this.search(
          this.state.search,
          1,
          this.state.size,
          files.filters,
          folderSortValue ?? files.folders.sort,
          folderDirectionValue ?? files.folders.direction,
          tab
        );
      } else {
        this.search(
          this.state.search,
          1,
          this.state.size,
          files.filters,
          fileSortValue ?? files.sort,
          fileDirectionValue ?? files.direction,
          tab
        );
      }
    }
  };

  getSearchWord() {
    return queryString.parse(window.location.search).search || "";
  }

  showFolderDetails = (folder, page, size, sort, direction) => {
    const { dispatch } = this.props;
    this.setState({ showFolderDetailsModal: true, activeFolder: folder });
    dispatch(
      fetchFolderDetailsByHash(
        folder.identificationHash,
        page,
        size,
        sort,
        direction
      )
    );
  };

  componentDidMount() {
    const { dispatch, files } = this.props;
    const filtersFromPath = this.getFiltersFromPath();
    const fileSortValue = window.localStorage.getItem("fileSort");
    const fileDirectionValue = window.localStorage.getItem("fileDirection");
    const numberOfElements = window.localStorage.getItem(
      "numberOfElementsSearch"
    );

    if (filtersFromPath) {
      this.setState({
        activeTab: filtersFromPath ? filtersFromPath.activeTab : "0",
      });
    }

    if (numberOfElements) {
      this.setState({ size: numberOfElements });
    }

    if (files.prevFilters) {
      dispatch(setSearchFilters(files.prevFilters));
    } else if (filtersFromPath) {
      dispatch(setSearchFilters(filtersFromPath));
    }

    dispatch(fetchVolumes());
    dispatch(fetchStoragePriceList());
    this.search(
      this.getSearchWord(),
      1,
      numberOfElements ? numberOfElements : 50,
      files.prevFilters ?? filtersFromPath ?? files.filters,
      fileSortValue ?? files.sort,
      fileDirectionValue ?? files.direction,
      filtersFromPath ? filtersFromPath.activeTab : "0"
    );
    dispatch(fetchTags());
  }

  searchFiles(event) {
    const { files } = this.props;

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });

    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      if (this.state.activeTab === "1") {
        this.search(
          value,
          1,
          this.state.size,
          files.filters,
          files.folders.sort,
          files.folders.direction,
          this.state.activeTab
        );
      } else {
        this.search(
          value,
          1,
          this.state.size,
          files.filters,
          files.sort,
          files.direction,
          this.state.activeTab
        );
      }
    }, 1000);
  }

  changeFilesOrder(newSort) {
    const { files } = this.props;
    let newDirection = "asc";
    if (this.state.activeTab === "1") {
      if (newSort === files.folders.sort) {
        newDirection = files.folders.direction === "asc" ? "desc" : "asc";
      }

      window.localStorage.setItem("folderSort", newSort);
      window.localStorage.setItem("folderDirection", newDirection);
    } else {
      if (newSort === files.sort) {
        newDirection = files.direction === "asc" ? "desc" : "asc";
      }

      window.localStorage.setItem("fileSort", newSort);
      window.localStorage.setItem("fileDirection", newDirection);
    }

    this.search(
      this.state.search,
      1,
      this.state.size,
      files.filters,
      newSort,
      newDirection,
      this.state.activeTab
    );
  }

  applyFilters(filters) {
    const { dispatch, files } = this.props;
    dispatch(setSearchFilters(filters));
    this.setState({ isFiltersCardOpen: false });

    if (this.state.activeTab === "1") {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.folders.sort,
        files.folders.direction,
        this.state.activeTab
      );
    } else {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.sort,
        files.direction,
        this.state.activeTab
      );
    }
  }

  clearFilters() {
    const { dispatch, files } = this.props;
    dispatch(clearSearchFilterValues());
    this.setState({
      selectedDataSourceOptions: [],
      checkboxStatus: {
        volume: false,
        bucket: false,
        archive: false,
      },
    });

    this.setState({ search: "" });
    if (this.state.activeTab === "1") {
      this.search(
        "",
        1,
        25,
        {},
        files.folders.sort,
        files.folders.direction,
        this.state.activeTab
      );
    } else {
      this.search(
        "",
        1,
        25,
        {},
        files.sort,
        files.direction,
        this.state.activeTab
      );
    }
  }

  getVolumeIdByName = (volumeName) => {
    const { volumeList } = this.props;
    let volumeId;
    volumeList.forEach((volume) => {
      if (volume.name === volumeName) {
        volumeId = volume.id;
      }
    });
    return volumeId;
  };

  addFilters = (names, values) => {
    const { dispatch, files } = this.props;
    const filters = files.filters;
    names.forEach((name, index) => {
      if (name === "volumeName") {
        filters["volumeIds"] = [this.getVolumeIdByName(values[index])];
      } else {
        filters[name] = values[index];
      }
    });

    dispatch(setSearchFilters(filters));
    if (this.state.activeTab === "1") {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.folders.sort,
        files.folders.direction,
        this.state.activeTab
      );
    } else {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.sort,
        files.direction,
        this.state.activeTab
      );
    }
  };

  removeFilter(filterName, index) {
    const { dispatch, files } = this.props;
    let filters = files.filters;
    if (index >= 0 && filters[filterName].length !== 1) {
      filters[filterName].splice(index, 1);
    } else if (
      index === 0 &&
      filters[filterName].length === 1 &&
      (filterName === "tags" || filterName === "reports")
    ) {
      filters[filterName] = [];
      dispatch(setSearchFilterValue(filterName, []));
    } else if (index === 0 && filters[filterName].length === 1) {
      filters[filterName] = [""];
      dispatch(setSearchFilterValue(filterName, [""]));
    } else {
      filters[filterName] = "";
      dispatch(setSearchFilterValue(filterName, ""));
    }
    if (this.state.activeTab === "1") {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.folders.sort,
        files.folders.direction,
        this.state.activeTab
      );
    } else {
      this.search(
        this.state.search,
        1,
        this.state.size,
        filters,
        files.sort,
        files.direction,
        this.state.activeTab
      );
    }

    if (filterName === "volumeIds") {
      this.setState({
        selectedDataSourceOptions: [],
        checkboxStatus: {
          volume: false,
          bucket: false,
          archive: false,
        },
      });
    }
  }

  handleChangeNumberOfElements(numberOfElements, sort, direction) {
    window.localStorage.setItem("numberOfElementsSearch", numberOfElements);
    this.setState({ size: numberOfElements });

    this.search(
      this.state.search,
      1,
      numberOfElements,
      this.props.files.filters,
      sort,
      direction,
      this.state.activeTab
    );
  }

  render() {
    const { files, volumeList, dispatch, volumeSettings } = this.props;
    const {
      showFileDetailsModal,
      activeFile,
      showFolderDetailsModal,
      activeFolder,
      activeTab,
      lastAnalyticsSearch,
      search,
      selectedDataSourceOptions,
      checkboxStatus,
      showShareModal,
      size,
    } = this.state;

    return (
      <>
        <div className="d-flex">
          <h1 className="page-header">Search</h1>
          <div className="ms-auto">
            <Button
              id="searchShareButton"
              onClick={() => {
                this.setState({
                  showShareModal: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faShareSquare} />
            </Button>
          </div>
          <div className="ms-1">
            <SearchPDF
              foldersIsFetching={files.folders.isFetching}
              filesIsFetching={files.isFetching}
              sizeShownDataCreation={this.state.sizeShownDataCreation}
              sizeShownDataLastAccess={this.state.sizeShownDataLastAccess}
              sizeShownDataLastModified={this.state.sizeShownDataLastModified}
              sizeShownDataExtension={this.state.sizeShownDataExtension}
              sizeShownDataVolumes={this.state.sizeShownDataVolumes}
              sizeShownDataOwners={this.state.sizeShownDataOwners}
              sizeShownDataGroups={this.state.sizeShownDataGroups}
              sizeItemsExtension={this.state.sizeItemsExtension}
              sizeItemsVolumes={this.state.sizeItemsVolumes}
              sizeItemsOwners={this.state.sizeItemsOwners}
              sizeItemsGroups={this.state.sizeItemsGroups}
              sizeItemsDuplicate={this.state.sizeItemsDuplicate}
              timeUnitLastAccess={this.state.timeUnitLastAccess}
              timeUnitCreation={this.state.timeUnitCreation}
              timeUnitLastModified={this.state.timeUnitLastModified}
              timeUnitStorageType={this.state.timeUnitStorageType}
              subtitleLastAccess={this.state.subtitleLastAccess}
              subtitleLastModified={this.state.subtitleLastModified}
              subtitleExtension={this.state.subtitleExtension}
              subtitleVolumes={this.state.subtitleVolumes}
              subtitleOwners={this.state.subtitleOwners}
              subtitleGroups={this.state.subtitleGroups}
              subtitleDuplicate={this.state.subtitleDuplicate}
              subtitleStorageType={this.state.subtitleStorageType}
              subtitleCreation={this.state.subtitleCreation}
              storageTypeRef={this.state.storageTypeRef}
              duplicateRef={this.state.duplicateRef}
              groupsRef={this.state.groupsRef}
              ownersRef={this.state.ownersRef}
              volumesRef={this.state.volumesRef}
              lastModifiedRef={this.state.lastModifiedRef}
              extensionRef={this.state.extensionRef}
              creationRef={this.state.creationRef}
              lastAccessRef={this.state.lastAccessRef}
              search={this.state.search}
              searchFilters={files.filters}
              sizeOfFiles={files.searchSize}
              numberOfFiles={files.total}
              numberOfFolders={files.folders.total}
              volumeList={volumeList}
              getStatisticsAndCharts={() => {
                this.search(
                  this.state.search,
                  1,
                  this.state.size,
                  files.filters,
                  window.localStorage.getItem("folderSort") ??
                  files.folders.sort,
                  window.localStorage.getItem("folderDirection") ??
                  files.folders.direction,
                  "1"
                );
                this.toggleTab("2");
              }}
            ></SearchPDF>
          </div>
          <ShareModal
            showModal={showShareModal}
            onCloseModal={() => {
              this.setState({
                showShareModal: false,
              });
            }}
            search={this.state.search}
            files={files}
          />
          <UncontrolledTooltip placement="auto" target="searchShareButton">
            Sharing options
          </UncontrolledTooltip>
        </div>

        <InputGroup>
          <Button
            id="filterButton"
            color="primary"
            onClick={() =>
              this.setState({
                isFiltersCardOpen: !this.state.isFiltersCardOpen,
              })
            }
          >
            <FontAwesomeIcon icon={faFilter} />
          </Button>
          <UncontrolledTooltip placement="auto" target="filterButton">
            Filter search result
          </UncontrolledTooltip>
          <Input
            placeholder="Ex: dataIntell"
            type="text"
            name="search"
            onChange={this.searchFiles}
            value={this.state.search}
          />
        </InputGroup>

        <ActiveFilters
          filters={files.filters}
          volumeList={volumeList}
          removeFilter={this.removeFilter}
          clearFilters={() => this.clearFilters()}
        />
        <Collapse isOpen={this.state.isFiltersCardOpen}>
          <Card>
            <CardBody>
              <AdvancedSearchFilters
                applyFilters={this.applyFilters}
                filters={files.filters}
                selectedDataSources={selectedDataSourceOptions}
                setSelectedDataSources={(dataSources) =>
                  this.setState({ selectedDataSourceOptions: dataSources })
                }
                checkboxStatus={checkboxStatus}
                setCheckboxStatus={(status) =>
                  this.setState({ checkboxStatus: status })
                }
              />
            </CardBody>
          </Card>
        </Collapse>

        <div style={{ marginTop: 10 }}>
          <Tabs
            toggleTab={this.toggleTab}
            activeTab={activeTab}
            titles={["Files", "Folders", "Analytics"]}
          >
            <FileList
              files={files.items}
              page={files.page}
              total={files.total}
              size={size}
              setNumberOfElements={(numberOfElements) =>
                this.handleChangeNumberOfElements(
                  numberOfElements,
                  files.sort,
                  files.direction
                )
              }
              sort={files.sort}
              direction={files.direction}
              searchSize={files.searchSize}
              selectPage={(page) => {
                if (files.page !== page) {
                  this.search(
                    this.state.search,
                    page,
                    size,
                    files.filters,
                    files.sort,
                    files.direction,
                    this.state.activeTab
                  );
                }
              }}
              handleClick={(file) =>
                this.setState({ showFileDetailsModal: true, activeFile: file })
              }
              changeOrder={this.changeFilesOrder}
              volumeSettings={volumeSettings}
              updateFile={(id, file) => dispatch(updateSearchFile(id, file))}
              updateTags={(tags) => dispatch(updateTags(tags))}
              search={search}
              isLoading={files.isFetching}
            />

            <FolderList
              folders={files.folders.items}
              page={files.folders.page}
              total={files.folders.total}
              size={size}
              setNumberOfElements={(numberOfElements) =>
                this.handleChangeNumberOfElements(
                  numberOfElements,
                  files.folders.sort,
                  files.folders.direction
                )
              }
              sort={files.folders.sort}
              direction={files.folders.direction}
              changeOrder={this.changeFilesOrder}
              selectPage={(page) => {
                if (files.folders.page !== page) {
                  this.search(
                    this.state.search,
                    page,
                    size,
                    files.filters,
                    files.folders.sort,
                    files.folders.direction,
                    this.state.activeTab
                  );
                }
              }}
              showFolderDetails={this.showFolderDetails}
              showNumberOfFolders
              volumeSettings={volumeSettings}
              updateFolder={(id, folder) =>
                dispatch(updateSearchFolder(id, folder))
              }
              updateTags={(tags) => dispatch(updateTags(tags))}
              search={search}
              isLoading={files.folders.isFetching}
            />
            {lastAnalyticsSearch !== null && (
              <Row>
                <Col md={4}>
                  <HistogramDateChart
                    filters={files.filters}
                    addFilters={this.addFilters}
                    search={search}
                    field="creation"
                    setRef={(value) => {
                      this.setCreationRef(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleCreation(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataCreation(value);
                    }}
                    setTimeUnit={(value) => {
                      this.setTimeUnitCreation(value);
                    }}
                    title="Creation Date"
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>
                <Col md={4}>
                  <HistogramDateChart
                    filters={files.filters}
                    addFilters={this.addFilters}
                    search={search}
                    field="lastAccess"
                    setRef={(value) => {
                      this.setLastAccessRef(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleLastAccess(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataLastAccess(value);
                    }}
                    setTimeUnit={(value) => {
                      this.setTimeUnitLastAccess(value);
                    }}
                    title={"Last Access Date"}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>
                <Col md={4}>
                  <HistogramDateChart
                    filters={files.filters}
                    addFilters={this.addFilters}
                    search={search}
                    field="lastModified"
                    setRef={(value) => {
                      this.setLastModifiedRef(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleLastModified(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataLastModified(value);
                    }}
                    setTimeUnit={(value) => {
                      this.setTimeUnitLastModified(value);
                    }}
                    title={"Last Modified Date"}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>

                <Col md={4}>
                  <ExtensionChart
                    filters={files.filters}
                    search={search}
                    setRef={(value) => {
                      this.setExtensionRef(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataExtension(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleExtension(value);
                    }}
                    setSizeItems={(value) => {
                      this.setSizeItemsExtension(value);
                    }}
                    addFilters={this.addFilters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>
                <Col md={4}>
                  <VolumeChart
                    filters={files.filters}
                    search={search}
                    setRef={(value) => {
                      this.setVolumesRef(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataVolumes(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleVolumes(value);
                    }}
                    setSizeItems={(value) => {
                      this.setSizeItemsVolumes(value);
                    }}
                    addFilters={this.addFilters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>
                <Col md={4}>
                  <OwnerChart
                    filters={files.filters}
                    search={search}
                    setRef={(value) => {
                      this.setOwnersRef(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataOwners(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleOwners(value);
                    }}
                    setSizeItems={(value) => {
                      this.setSizeItemsOwners(value);
                    }}
                    addFilters={this.addFilters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                    sizeOfFiles={files.searchSize}
                  />
                </Col>

                <Col md={4}>
                  <GroupChart
                    sizeOfFiles={files.searchSize}
                    filters={files.filters}
                    search={search}
                    setRef={(value) => {
                      this.setGroupsRef(value);
                    }}
                    setSizeShownData={(value) => {
                      this.setSizeShownDataGroups(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleGroups(value);
                    }}
                    setSizeItems={(value) => {
                      this.setSizeItemsGroups(value);
                    }}
                    addFilters={this.addFilters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                  />
                </Col>
                <Col md={4}>
                  <DuplicateChart
                    sizeOfFiles={files.searchSize}
                    filters={files.filters}
                    search={search}
                    setRef={(value) => {
                      this.setDuplicateRef(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleDuplicate(value);
                    }}
                    setSizeItems={(value) => {
                      this.setSizeItemsDuplicate(value);
                    }}
                    addFilters={this.addFilters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                  />
                </Col>
                <Col md={4}>
                  <StorageTypeChart
                    title="Storage Types"
                    search={search}
                    setRef={(value) => {
                      this.setStorageTypeRef(value);
                    }}
                    setSubtitle={(value) => {
                      this.setSubtitleStorageType(value);
                    }}
                    setTimeUnit={(value) => {
                      this.setTimeUnitStorageType(value);
                    }}
                    addFilters={this.addFilters}
                    filters={files.filters}
                    lastAnalyticsSearch={this.state.lastAnalyticsSearch}
                  />
                </Col>
              </Row>
            )}
          </Tabs>
        </div>
        <FileDetailsModal
          file={activeFile}
          updateFile={(id, file) => dispatch(updateSearchFile(id, file))}
          updateTags={(tags) => dispatch(updateTags(tags))}
          volumeType={
            volumeList.find((volume) => volume.id === activeFile.volumeId)?.type
          }
          close={() => this.setState({ showFileDetailsModal: false })}
          showModal={showFileDetailsModal}
          isInSearchSection
        />
        <FolderDetailsModal
          folder={activeFolder}
          showModal={showFolderDetailsModal}
          close={() => this.setState({ showFolderDetailsModal: false })}
          addFilters={(path) => this.addFilters(["startingPath"], [path])}
          updateFolder={(id, file) => dispatch(updateSearchFolder(id, file))}
          updateTags={(tags) => dispatch(updateTags(tags))}
          volumeType={
            volumeList.find((volume) => volume.id === activeFolder.volumeId)
              ?.type
          }
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { search, volumes, folderDetails, volumeSettings, tags } = state;
  const files = search;
  const volumeList = volumes.items;

  return {
    files,
    volumeList,
    folderDetails,
    volumeSettings,
    tags,
  };
}

export default connect(mapStateToProps)(Search);
