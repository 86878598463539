import React, { Component } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import CollapseText from "./CollapseText";
import { UncontrolledTooltip } from "reactstrap";

class CrawlVolumeDetails extends Component {
  render() {
    const { volumeName, elements } = this.props;

    const strongStyle = { color: "black" };
    const errorStyle = { color: "red" };
    const hiddenText = [];
    let sumOfDurations = 0;

    const warningIcon = () => {
      return (
        <span className="ps-2">
          <FontAwesomeIcon id="volumesTooltip" icon={faExclamationCircle} />
          <UncontrolledTooltip placement="auto" target="volumesTooltip">
            The scan took less than a second, you should check the volume configuration if it's not a normal behavior.
          </UncontrolledTooltip>
        </span>
      );
    };

    elements.forEach((element, index) => {
      if (element.crawlDuration === null || element.crawlDuration === -1) {
        hiddenText.push(
          <span key={`${element.id}-${index}`} style={{ paddingLeft: 15 }}>
            On{" "}
            <strong style={strongStyle}>
              {moment(element.indexingDate).format("lll")}
            </strong>{" "}
            the scan <strong style={errorStyle}>failed</strong> or was{" "}
            <strong style={errorStyle}>interrupted</strong>
            <br />
          </span>
        );
      } else if (element.crawlDuration < 1000) {
        sumOfDurations += element.crawlDuration;
        hiddenText.push(
          <span key={`${element.id}-${index}`} style={{ paddingLeft: 15 }}>
            On{" "}
            <strong style={strongStyle}>
              {moment(element.indexingDate).format("lll")}
            </strong>{" "}
            and took{" "}
            <strong style={strongStyle}>{element.crawlDuration} ms.</strong>
            {warningIcon()}
            <br />
          </span>
        );
      } else {
        sumOfDurations += element.crawlDuration;
        hiddenText.push(
          <span key={`${element.id}-${index}`} style={{ paddingLeft: 15 }}>
            On{" "}
            <strong style={strongStyle}>
              {moment(element.indexingDate).format("lll")}
            </strong>{" "}
            and took{" "}
            <strong style={strongStyle}>
              {moment.duration(element.crawlDuration).humanize()}
            </strong>
            <br />
          </span>
        );
      }
    });

    const text = (
      <span>
        {elements.length} {elements.length > 1 ? "scans" : "scan"} with{" "}
        {moment.duration(sumOfDurations / elements.length).humanize()} average
      </span>
    );

    if (elements[0].crawlDuration === null) {
      const duration = moment().diff(moment(elements[0].indexingDate));
      return (
        <span>
          <h6>{volumeName}</h6>
          <span>
            Last scan started on{" "}
            <strong style={strongStyle}>
              {moment(elements[0].indexingDate).format("lll")}
            </strong>{" "}
            has been running for{" "}
            <strong style={strongStyle}>
              {moment.duration(duration).humanize()}
            </strong>
            <br />
            <CollapseText text={text} hiddenText={hiddenText} />
          </span>
        </span>
      );
    } else if (elements[0].crawlDuration === -1) {
      return (
        <span>
          <h6>{volumeName}</h6>
          <span>
            Last scan started on{" "}
            <strong style={strongStyle}>
              {moment(elements[0].indexingDate).format("lll")}
            </strong>{" "}
            and <strong style={errorStyle}>failed</strong>
            <br />
            <CollapseText text={text} hiddenText={hiddenText} />
          </span>
        </span>
      );
    } else if (elements[0].crawlDuration < 1000) {
      return (
        <span>
          <h6>{volumeName}</h6>
          <span>
            Last scan started on{" "}
            <strong style={strongStyle}>
              {moment(elements[0].indexingDate).format("lll")}
            </strong>{" "}
            took{" "}
            <strong style={strongStyle}>{elements[0].crawlDuration} ms.</strong>
            {warningIcon()}
            <br />
            <CollapseText text={text} hiddenText={hiddenText} />
          </span>
        </span>
      );
    } else {
      return (
        <span>
          <h6>{volumeName}</h6>
          <span>
            Last scan started on{" "}
            <strong style={strongStyle}>
              {moment(elements[0].indexingDate).format("lll")}
            </strong>{" "}
            took{" "}
            <strong style={strongStyle}>
              {moment.duration(elements[0].crawlDuration).humanize()}
            </strong>
            <br />
            <CollapseText text={text} hiddenText={hiddenText} />
          </span>
        </span>
      );
    }
  }
}

export default CrawlVolumeDetails;
