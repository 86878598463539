import React, { Component } from "react";
import { connect } from "react-redux";

import Variation from "../components/Variations";
import {
  fetchBiggestVolumeVariations,
  setVolumeVariationDuration,
} from "../../actions";

class VolumeVariations extends Component {
  constructor(props) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;

    const mostActiveVolumesThreshold = window.localStorage.getItem(
      "mostActiveVolumesThreshold"
    );

    if (mostActiveVolumesThreshold) {
      this.props.dispatch(
        setVolumeVariationDuration(mostActiveVolumesThreshold)
      );
    }

    dispatch(fetchBiggestVolumeVariations());
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, duration } = nextProps;

    if (duration !== this.props.duration) {
      dispatch(fetchBiggestVolumeVariations());
    }
  }

  handleSelectChange(event) {
    const target = event.target;
    const value = target.value;

    window.localStorage.setItem("mostActiveVolumesThreshold", value);
    this.props.dispatch(setVolumeVariationDuration(value));
  }

  render() {
    const { variations, duration, handlePathChange, isFetching } = this.props;

    const formattedVariations = variations.map((variation) => {
      return {
        variation: variation.variation,
        object1Used: variation.object1.used,
        object1Free: variation.object1.free,
        object2Used: variation.object2.used,
        object2Free: variation.object2.free,
        numberOfDays: variation.numberOfDays,
        name: variation.object1.name,
        links: [
          `/filemanagement?path=/&volumeId=${variation.object1.id}`,
          `/filemanagement?path=/&volumeId=${variation.object1.id}`,
          `/filemanagement?path=/&volumeId=${variation.object1.id}`,
        ],
      };
    });

    return (
      <Variation
        variations={formattedVariations}
        duration={duration}
        isFetching={isFetching}
        handleSelectChange={this.handleSelectChange}
        handlePathChange={handlePathChange}
        title={"Most Active Storage"}
        subtitle={"Largest size fluctuations over time"}
      />
    );
  }
}

function mapStateToProps(state) {
  const { volumeVariations } = state;

  const { variations, duration, isFetching } = volumeVariations;

  return {
    variations,
    duration,
    isFetching,
  };
}

export default connect(mapStateToProps)(VolumeVariations);
