import { useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "./Tabs";
import RelatedFilesList from "./RelatedFilesList";

export default function RelatedFoldersForArchive(props) {
  const { folder } = props;

  const [activeTab, setActiveTab] = useState("0");

  const volumes = useSelector(({ volumes }) => volumes.items);

  const sizeVariation = 1024 * folder.numberOfFiles;

  const onPremVolumeIds = volumes
    .filter(
      (volume) =>
        "crawl.files" === volume.type ||
        "NetworkVolume" === volume.type ||
        "DellPowerScale" === volume.type
    )
    .map((v) => v.id);
  const onPremDuplicateBody = JSON.stringify([
    {
      volumeId: { values: onPremVolumeIds },
      name: { value: folder.name },
      lastModifiedDate: { after: folder.lastModified },
      size: {
        min: folder.size - sizeVariation,
        max: folder.size + sizeVariation,
      },
      numberOfFiles: { value: folder.numberOfFiles },
      numberOfFolders: { value: folder.numberOfFolders },
      isDirectory: true,
    },
  ]);

  const cloudVolumeIds = volumes
    .filter((volume) => "AWS-S3" === volume.type)
    .map((v) => v.id);
  const cloudDuplicateBody = JSON.stringify([
    {
      volumeId: { values: cloudVolumeIds },
      name: { value: folder.name },
      size: {
        min: folder.size - sizeVariation,
        max: folder.size + sizeVariation,
      },
      numberOfFiles: { value: folder.numberOfFiles },
      numberOfFolders: { value: folder.numberOfFolders },
      isDirectory: true,
    },
  ]);

  const archiveVolumeIds = volumes
    .filter(
      (volume) => "Archives" === volume.type || "Archiware" === volume.type
    )
    .map((v) => v.id);
  const archiveDuplicateBody = JSON.stringify([
    {
      volumeId: { values: archiveVolumeIds },
      identificationHash: { value: folder.identificationHash },
      isDirectory: true,
      isDeleted: false,
    },
    {
      fileId: { value: folder.fileId },
      excludeFilter: true,
    },
  ]);

  const archiveDeletedBody = JSON.stringify([
    {
      volumeId: { values: archiveVolumeIds },
      identificationHash: { value: folder.identificationHash },
      isDirectory: true,
      isDeleted: true,
    },
  ]);

  return (
    <Tabs
      toggleTab={(tab) => setActiveTab(tab)}
      activeTab={activeTab}
      titles={["Archive", "Cloud", "On-Premises", "Removed"]}
    >
      <RelatedFilesList
        searchBody={archiveDuplicateBody}
        description="Archive folders are folders with the same name, same last modified date and with a similar size."
        boldElements={{ name: true, size: true, lastModified: true }}
        emptyListString="No archive folders found."
        tabPosition="0"
        currentActiveTab={activeTab}
        isDirectory
      />

      <RelatedFilesList
        searchBody={cloudDuplicateBody}
        description={
          <>
            Related Cloud folders are folders with the same name, same number of
            files, same number of folders and with a similar size. Folders shown
            in red are no longer in their original location.
          </>
        }
        boldElements={{
          name: true,
          size: true,
          numberOfFiles: true,
          numberOfFolders: true,
        }}
        emptyListString="No cloud folders found."
        tabPosition="1"
        currentActiveTab={activeTab}
        isRedBackground
        isDirectory
      />

      <RelatedFilesList
        searchBody={onPremDuplicateBody}
        description={
          <>
            Related On-Premises folders are folders with the same name, same
            last modified date, same number of files, same number of folders and
            with a similar size. Folders shown in red are no longer in their
            original location.
          </>
        }
        boldElements={{
          name: true,
          size: true,
          lastModified: true,
          numberOfFiles: true,
          numberOfFolders: true,
        }}
        emptyListString="No On-Premises folders found."
        tabPosition="2"
        currentActiveTab={activeTab}
        isRedBackground
        isDirectory
      />

      <RelatedFilesList
        searchBody={archiveDeletedBody}
        description="Archive folders with the same name, same size, same last modified date and that is no longer in its original location."
        boldElements={{
          name: true,
          size: true,
          lastModified: true,
          indexingDate: true,
        }}
        emptyListString="No deleted folders found."
        tabPosition="3"
        currentActiveTab={activeTab}
        isDirectory
      />
    </Tabs>
  );
}
