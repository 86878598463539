import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_USER = "REQUEST_CREATE_USER";
const RECEIVE_CREATE_USER = "RECEIVE_CREATE_USER";
const RECEIVE_INVALID_USER = "RECEIVE_INVALID_USER";
// READ
const REQUEST_USER_LIST = "REQUEST_USER_LIST";
const RECEIVE_USER_LIST = "RECEIVE_USER_LIST";
// UPDATE
const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
const RECEIVE_UPDATE_USER = "RECEIVE_UPDATE_USER";
const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
const RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD";
const RECEIVE_INVALID_RESET_PASSWORD = "RECEIVE_INVALID_RESET_PASSWORD";
// DELETE
const REQUEST_DELETE_USER = "REQUEST_DELETE_USER";
const RECEIVE_DELETE_USER = "RECEIVE_DELETE_USER";

// === Reducers ===
export default function userList(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: false,
    resetPasswordErrors: {},
    resetPasswordValid: true
  },
  action
) {
  switch (action.type) {
    case REQUEST_USER_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_USER_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.users.elements,
        page: action.users.page,
        total: action.users.total,
        size: action.users.size,
        sort: action.users.sort,
        direction: action.users.direction.toLowerCase()
      });
    case RECEIVE_INVALID_USER:
      return Object.assign({}, state, {
        formValid: false,
        errors: formatError(action.errors)
      });
    case RECEIVE_CREATE_USER:
    case RECEIVE_UPDATE_USER:
      return Object.assign({}, state, {
        formValid: true,
        errors: {}
      });
    case REQUEST_CREATE_USER:
    case REQUEST_UPDATE_USER:
      return Object.assign({}, state, {
        formValid: false,
        errors: {}
      });
    case REQUEST_RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPasswordValid: false,
        resetPasswordErrors: {}
      });
    case RECEIVE_RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPasswordValid: true,
        resetPasswordErrors: {}
      });
    case RECEIVE_INVALID_RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPasswordValid: false,
        resetPasswordErrors: formatError(action.resetPasswordErrors)
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestUserList() {
  return {
    type: REQUEST_USER_LIST
  };
}

function receiveUserList(users) {
  return {
    type: RECEIVE_USER_LIST,
    users
  };
}

function requestCreateUser() {
  return {
    type: REQUEST_CREATE_USER
  };
}

function receiveCreateUser() {
  return {
    type: RECEIVE_CREATE_USER
  };
}

function requestUpdateUser() {
  return {
    type: REQUEST_UPDATE_USER
  };
}

function receiveUpdateUser() {
  return {
    type: RECEIVE_UPDATE_USER
  };
}

function receiveInvalidUser(errors) {
  return {
    type: RECEIVE_INVALID_USER,
    errors
  };
}

function requestDeleteUser(id) {
  return {
    type: REQUEST_DELETE_USER,
    id
  };
}

function receiveDeleteUser(id) {
  return {
    type: RECEIVE_DELETE_USER,
    id
  };
}

function requestResetPassword() {
  return {
    type: REQUEST_RESET_PASSWORD
  };
}

function receiveResetPassword() {
  return {
    type: RECEIVE_RESET_PASSWORD
  };
}

function receiveInvalidResetPassword(resetPasswordErrors) {
  return {
    type: RECEIVE_INVALID_RESET_PASSWORD,
    resetPasswordErrors
  };
}

function formatUserCreationErrorMessage(errorMessage) {
  switch (errorMessage) {
    case "Error while trying to authenticate the email user":
      return "The user was created, but there was an error sending the DataIntell login information email.";
    default:
      return errorMessage;
  }
}

// === Side Effects ===
export function fetchUserList(page = 1, sort = "name", direction = "asc", size = 200) {
  return (dispatch, getState) => {
    dispatch(requestUserList());
    return fetchWithAuth(
      dispatch,
      `/v1/users?page=${page}&sort=${sort},${direction}&size=${size}`,
      getState().auth.accessToken
    )
      .then(users => {
        dispatch(receiveUserList(users));
      })
      .catch(err => {
        const message = err.message || "Unable to get the list of users";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function createUser(name, username, email, password, roles) {
  return (dispatch, getState) => {
    const body = {
      name,
      username,
      email,
      password,
      roles
    };

    dispatch(requestCreateUser());
    return fetchWithAuth(
      dispatch,
      "/v1/users",
      getState().auth.accessToken,
      "POST",
      JSON.stringify(body)
    )
      .then(json => {
        dispatch(setAlert("User was created successfully", "success"));
        dispatch(fetchUserList());
        dispatch(receiveCreateUser());
      })
      .catch(err => {
        if (err.subErrors) {
          dispatch(receiveInvalidUser(err));
        } else if (err.message) {
          dispatch(setAlert(formatUserCreationErrorMessage(err.message), "danger", 10000));
          dispatch(fetchUserList());
        } else {
          dispatch(setAlert(err, "danger"));
        }
        return err;
      });
  };
}

export function updateUser(id, name, username, email, roles) {
  return (dispatch, getState) => {
    const body = {
      id,
      name,
      username,
      email,
      roles
    };
    dispatch(requestUpdateUser());
    return fetchWithAuth(
      dispatch,
      `/v1/users/${id}`,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then(json => {
        dispatch(setAlert(`User with id [${id}] was updated successfully`, "success"));
        dispatch(fetchUserList());
        dispatch(receiveUpdateUser());
      })
      .catch(err => {
        if (err.subErrors) {
          dispatch(receiveInvalidUser(err));
        } else if (err.message) {
          dispatch(setAlert(err.message, "danger"));
        } else {
          dispatch(setAlert(err, "danger"));
        }
      });
  };
}

export function resetPassword(id, newPassword, confirmPassword) {
  return (dispatch, getState) => {
    const body = {
      newPassword,
      confirmPassword
    };
    dispatch(requestResetPassword());
    return fetchWithAuth(
      dispatch,
      `/v1/users/${id}/password`,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then(json => {
        dispatch(setAlert(`Password of user with id [${id}] was updated successfully`, "success"));
        dispatch(receiveResetPassword());
      })
      .catch(err => {
        if (err.subErrors) {
          dispatch(receiveInvalidResetPassword(err));
        } else {
          const message = err.message || `Unable to reset password for user with id [${id}]`;
          dispatch(setAlert(message, "danger"));
        }
      });
  };
}

export function deleteUser(id) {
  return (dispatch, getState) => {
    dispatch(requestDeleteUser(id));
    return fetchWithAuth(
      dispatch,
      `/users/${id}`,
      getState().auth.accessToken,
      "DELETE"
    )
      .then(json => {
        dispatch(setAlert(`User with id [${id}] was deleted successfully`, "success"));
        dispatch(fetchUserList());
        dispatch(receiveDeleteUser(id));
      })
      .catch(err => {
        if (err.message) {
          dispatch(setAlert(err.message, "danger"));
        } else {
          dispatch(setAlert(err, "danger"));
        }
      });
  };
}
