import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FolderIcon from "../../components/icons/Folder";
import BucketAndArchiwareFolder from "./BucketAndArchiwareFolder";
import VolumeFolder from "./VolumeFolder";
import PropTypes from "prop-types";

Folder.propTypes = {
  item: PropTypes.object,
  handleDetailClick: PropTypes.func,
  type: PropTypes.string,
  indexingDate: PropTypes.string
};

function Folder(props) {
  const {
    item,
    handleDetailClick,
    type,
    indexingDate
  } = props;
  let content;

  if (item.numberOfFiles === 0) {
    content = (
      <div className="row">
        <div className="col-sm-12">
          <h6>The directory is empty</h6>
        </div>
      </div>
    );
  } else if (type === "AWS-S3" || type == "Archiware") {
    content = (
      <BucketAndArchiwareFolder
        handleDetailClick={handleDetailClick}
        item={item}
        indexingDate={indexingDate}
      />
    );
  } else {
    content = (
      <VolumeFolder
        handleDetailClick={handleDetailClick}
        item={item}
        indexingDate={indexingDate}
      />
    );
  }

  return (
    <div 
      style={{ 
        padding: "10px",
        marginBottom: "2rem",
        backgroundColor: "#e9ecef",
        borderRadius: ".3rem"
      }}
    >
      <div className="d-flex">
        <h5
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleDetailClick(
              `/folders?volumeId=${item.volumeId}&path=${item.path}`
            )
          }
        >
          <FolderIcon /> {item.path}
        </h5>
        <div className="ms-auto">
          <Button outline
            color="info"
            id={`a${item.id}`}
            className="me-1"
            style={{ width: 40 }}
            onClick={() =>
              handleDetailClick(
                `/search?volumeId=${item.volumeId}&startingPath=${item.path}`
              )
            }
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <UncontrolledTooltip placement="auto" target={`a${item.id}`}>
            Show files in folder
          </UncontrolledTooltip>
        </div>
      </div>
      {content}
    </div>
  );
}

export default Folder;
