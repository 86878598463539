import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";

const FILTER_ICON_STYLE = {
  position: "absolute",
  marginLeft: "0.75rem",
  height: "2.375rem",
  pointerEvents: "none",
  color: "#495057",
  opacity: "0.5",
  width: "1em",
};

function InputFilter(props) {
  const { placeholder, action } = props;
  let timer = null;

  return (
    <>
      <FontAwesomeIcon icon={faFilter} style={FILTER_ICON_STYLE} />
      <Input
        size={27}
        placeholder={placeholder}
        onChange={(e) => {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => action(e.target.value), 1000);
        }}
        style={{ paddingLeft: "2rem" }}
        tabIndex="1"
      />
    </>
  );
}
export default InputFilter;
