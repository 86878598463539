import {
  REQUEST_ALERTCONFIGURATION_LIST,
  RECEIVE_ALERTCONFIGURATION_LIST,
  RECEIVE_INVALID_ALERTCONFIGURATION,
  REQUEST_CREATE_ALERTCONFIGURATION,
  RECEIVE_CREATE_ALERTCONFIGURATION,
  REQUEST_UPDATE_ALERTCONFIGURATION,
  RECEIVE_UPDATE_ALERTCONFIGURATION
} from "../actions/alertConfiguration";

import { formatError } from "./formatError";

function alertConfigurationList(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: false
  },
  action
) {
  switch (action.type) {
    case REQUEST_ALERTCONFIGURATION_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_ALERTCONFIGURATION_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.alertConfigurationList.elements,
        page: action.alertConfigurationList.page,
        total: action.alertConfigurationList.total,
        size: action.alertConfigurationList.size,
        sort: action.alertConfigurationList.sort,
        direction: action.alertConfigurationList.direction.toLowerCase()
      });
    case RECEIVE_INVALID_ALERTCONFIGURATION:
      return Object.assign({}, state, {
        formValid: false,
        errors: formatError(action.errors)
      });
    case RECEIVE_CREATE_ALERTCONFIGURATION:
    case RECEIVE_UPDATE_ALERTCONFIGURATION:
      return Object.assign({}, state, {
        formValid: true,
        errors: {}
      });
    case REQUEST_CREATE_ALERTCONFIGURATION:
    case REQUEST_UPDATE_ALERTCONFIGURATION:
      return Object.assign({}, state, {
        formValid: false,
        errors: {}
      });
    default:
      return state;
  }
}

export default alertConfigurationList;
