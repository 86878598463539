import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Form,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faCog,
  faSearch,
  faChartBar,
  faSitemap,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import Authorization from "../validation/Authorization";
import { useMsal } from "@azure/msal-react";
import CloudSodaLogo from "./soda/CloudSodaLogo";

const NAV_CLASS_NAME_FOR_CURRENT_THEME =
  window._env_.REACT_THEME_ID === "SCALE_LOGIC"
    ? "nav-scalelogic-link"
    : "nav-navigation-link";

function Header(props) {
  const {
    signOut,
    name,
    activePath,
    handleChange,
    activeDate,
    dates,
    backgroundColor,
    logo,
  } = props;

  const [isDateSelectorOpen, setDateSelectorOpen] = useState(false);
  const [isProfileSubMenuOpen, setProfileSubMenuOpen] = useState(false);
  const [isSettingsSubMenuOpen, setSettingsSubMenuOpen] = useState(false);

  const { instance } = useMsal();

  return (
    <Navbar
      style={{ backgroundColor: backgroundColor }}
      expand="xl"
      className="fixed-top flex-md shadow"
      dark
    >
      <NavbarBrand id="logo" href="/">
        <CloudSodaLogo />
      </NavbarBrand>
      <NavbarToggler
        onClick={() => setDateSelectorOpen(!isDateSelectorOpen)}
        className="me-2"
      />
      <Collapse isOpen={isDateSelectorOpen} navbar>
        <Nav className="px-3" navbar>
          <NavItem>
            <Link
              className={`nav-link ${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${"/" === activePath ? "active" : ""
                }`}
              to="/"
            >
              <FontAwesomeIcon icon={faChartBar} /> Dashboard
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={`nav-link ${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/filemanagement") ? "active" : ""
                }`}
              to="/filemanagement"
            >
              <FontAwesomeIcon icon={faServer} /> File Management
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={`nav-link ${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/project") ? "active" : ""
                }`}
              to="/projects"
            >
              <FontAwesomeIcon icon={faSitemap} /> Projects
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={`nav-link ${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/report") ? "active" : ""
                }`}
              to="/reports"
            >
              <FontAwesomeIcon icon={faFileInvoice} /> Reports
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={`nav-link ${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/search") ? "active" : ""
                }`}
              to="/search"
            >
              <FontAwesomeIcon icon={faSearch} /> Search
            </Link>
          </NavItem>
          <NavItem />
        </Nav>
        <Nav className="ms-auto px-3" navbar>
          <NavItem>
            <Form style={{ padding: "8px" }}>
              <Input
                style={{ margin: "auto", minWidth: "120px" }}
                onChange={handleChange}
                type="select"
                bsSize="sm"
                value={activeDate}
              >
                {dates.map((date, index) => {
                  return (
                    <option key={index} value={date}>
                      {date}
                    </option>
                  );
                })}
              </Input>
            </Form>
          </NavItem>
          <Authorization>
            <Dropdown
              nav
              isOpen={isSettingsSubMenuOpen}
              toggle={() => setSettingsSubMenuOpen(!isSettingsSubMenuOpen)}
              className={`${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/settings") ? "active" : ""
                }`}
            >
              <DropdownToggle nav>
                <FontAwesomeIcon icon={faCog} size="lg" />
              </DropdownToggle>
              <DropdownMenu>
                <Link className="text-decoration-none" to="/settings">
                  <DropdownItem>General</DropdownItem>
                </Link>
                <DropdownItem divider />
                <Link className="text-decoration-none" to="/settings/data">
                  <DropdownItem>Data</DropdownItem>
                </Link>
                <DropdownItem divider />
                <Link className="text-decoration-none" to="/settings/users">
                  <DropdownItem>Users</DropdownItem>
                </Link>
                <DropdownItem divider />
                <Link className="text-decoration-none" to="/settings/schedules">
                  <DropdownItem>Schedules</DropdownItem>
                </Link>
                <DropdownItem divider />
                <Link className="text-decoration-none" to="/settings/jobs">
                  <DropdownItem>Jobs</DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
          </Authorization>
          <Dropdown
            nav
            isOpen={isProfileSubMenuOpen}
            toggle={() => setProfileSubMenuOpen(!isProfileSubMenuOpen)}
            className={`${NAV_CLASS_NAME_FOR_CURRENT_THEME} ${activePath.startsWith("/profile") ? "active" : ""
              }`}
          >
            <DropdownToggle nav caret>
              {name}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/profile">Profile</DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                href="https://support.cloudsoda.io/hc/en-us/sections/27809116305691-Data-Intelligence-Documentation"
                target="_blank"
              >
                Help
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                href="https://support.cloudsoda.io/hc/en-us/requests/new"
                target="_blank"
              >
                Contact Support
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (instance && instance["browserStorage"]) {
                    instance["browserStorage"].clear();
                  }
                  signOut();
                }}
              >
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;
