import { useState, useEffect } from "react";
import { FormGroup, Label, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { getCollections, getStorages } from "../../api/iconik";
import Select from "react-select";

export default function TargetIconikActionStep({
  iconikActionObj,
  setIconikActionObj,
  setDisableForwardActionButton
}) {
  const [collections, setCollections] = useState([]);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [storages, setStorages] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);

  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    getCollections(dispatch, auth)
      .then(res => {
        setCollections(res);
        getCollectionOptions(res);
      })
      .catch(err => setAlert("Unable to get the list of collections from Iconik.", "danger", 8000));
    
    getStorages(dispatch, auth)
      .then(res => {
        setStorages(res);
        getStorageOptions(res);
      })
      .catch(err => setAlert("Unable to get the list of storages from Iconik.", "danger", 8000));
  }, []);

  useEffect(() => {
    setDisableForwardActionButton(iconikActionObj.storage.id === "");
  }, [iconikActionObj.storage.id]);

  const getCollectionOptions = (collectionList) => {
    const options = [{
      value: "", 
      label: "None" 
    }];

    collectionList.forEach(collection => (options.push({ 
      value: collection.id, 
      label: collection.title 
    })));

    setCollectionOptions(options);
  };

  const getStorageOptions = (storageList) => {
    const options = [];
    const storageAndTypeMap = {};

    storageList.map(storage => storage.method).forEach(type => {
      if (!storageAndTypeMap[type]) {
        storageAndTypeMap[type] = { group: [] };
      }
    });

    storageList.forEach(storage => {
      if (storageAndTypeMap[storage.method]) {
        storageAndTypeMap[storage.method].group.push({
          value: storage.id,
          label: storage.name
        });
      }
    });

    Object.keys(storageAndTypeMap).forEach(type => {
      options.push({
        label: type,
        options: storageAndTypeMap[type].group
      });
    });

    setStorageOptions(options);
  };

  const formatGroupLabel = (groupedOption) => {
    return (
      <>
        Type : <span>{groupedOption.label}</span>
      </>
    );
  };

  return (
    <>
      <FormGroup row>
        <Label for="collection" sm={3}>
          Collection
        </Label>
        <Col>
          <Select
            value={[
              {
                value: iconikActionObj.collection.id,
                label: iconikActionObj.collection.title
              }
            ]}
            options={collectionOptions}
            onChange={(event) => {
              const collection = collections.find(collection => collection.id === event.value);
              setIconikActionObj((prev) => ({
                ...prev,
                collection: {
                  id: event.value,
                  title: collection ? collection.title : "None"
                }
              }));
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="storage" sm={3}>
          Storage
        </Label>
        <Col>
          <Select
            value={[
              {
                value: iconikActionObj.storage.id,
                label: iconikActionObj.storage.name
              }
            ]}
            options={storageOptions}
            formatGroupLabel={formatGroupLabel}
            onChange={(event) => {
              const storage = storages.find(storage => storage.id === event.value);
              setIconikActionObj((prev) => ({
                ...prev,
                storage: {
                  id: event.value,
                  name: storage?.name,
                  type: storage?.method
                }
              }));
            }}
          />
        </Col>
      </FormGroup>
    </>
  );
}
