import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { setAlert } from "../../actions/alert";
import { fetchWithAuth } from "../../actions/requestHelper";
import { dismissAlert } from "../../actions/alert";
import { signOut } from "../../ducks/login";

function UpdateProfileInfoModal({
    showUpdateProfileInfoModal,
    closeUpdateProfileInfoModal,
    name,
    username,
    email
}) {
    const cleanProfileObj = {
        name: name,
        username: username,
        email: email
    };
    const [profileObj, setProfileObj] = useState(cleanProfileObj);
    const [updateProfileInfoErrorObj, setUpdateProfileInfoErrorObj] = useState({});

    const authObj = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const url = "/v1/profiles";
        const body = JSON.stringify({
            name: profileObj.name,
            username: profileObj.username,
            email: profileObj.email
        });

        fetchWithAuth(dispatch, url, authObj.accessToken, "PUT", body)
            .then(() => {
                dispatch(setAlert(
                    <div>
                        Profile info updated successfully. To see the changes, please log back in the application.
                        <span style={{
                            marginLeft: "5px",
                            color: "#007bff",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                            onClick={() => { dispatch(signOut()); dispatch(dismissAlert()) }}
                        >
                            Sign out
                        </span>
                    </div>, "warning", 13000));
                setUpdateProfileInfoErrorObj({});
                closeUpdateProfileInfoModal();
            })
            .catch(err => {
                if (err.subErrors.length) {
                    const newErrObj = {};
                    err.subErrors.forEach(e => {
                        newErrObj[e.field] = e;
                    });
                    setUpdateProfileInfoErrorObj(newErrObj);
                } else {
                    setUpdateProfileInfoErrorObj({});
                }
            });
    }

    const handleProfileInputChange = (e) => {
        e.persist();
        setProfileObj(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    return (
        <Modal
            toggle={() => {
                setUpdateProfileInfoErrorObj({});
                closeUpdateProfileInfoModal();
            }} isOpen={showUpdateProfileInfoModal}
        >
            <ModalHeader
                toggle={() => {
                    setUpdateProfileInfoErrorObj({});
                    closeUpdateProfileInfoModal();
                }}
            >
                Update Profile
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="inputNewName">
                            Name
                        </Label>
                        <Input
                            value={profileObj.name}
                            type="text"
                            id="inputNewName"
                            name="name"
                            onChange={handleProfileInputChange}
                            invalid={!!updateProfileInfoErrorObj["name"]}
                        />
                        <FormFeedback>name {updateProfileInfoErrorObj["name"] && updateProfileInfoErrorObj["name"]["message"]}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inputNewUserName">
                            Username
                        </Label>
                        <Input
                            value={profileObj.username}
                            type="text"
                            id="inputNewUserName"
                            name="username"
                            onChange={handleProfileInputChange}
                            invalid={!!updateProfileInfoErrorObj["username"]}
                        />
                        <FormFeedback>username {updateProfileInfoErrorObj["username"] && updateProfileInfoErrorObj["username"]["message"]}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="inputNewEmail">
                            Email
                        </Label>
                        <Input
                            value={profileObj.email}
                            type="text"
                            id="inputNewEmail"
                            name="email"
                            onChange={handleProfileInputChange}
                            invalid={!!updateProfileInfoErrorObj["email"]}
                        />
                        <FormFeedback>email {updateProfileInfoErrorObj["email"] && updateProfileInfoErrorObj["email"]["message"]}</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    setUpdateProfileInfoErrorObj({});
                    closeUpdateProfileInfoModal();
                }}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default UpdateProfileInfoModal;
