import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { signin, signInWithEntraId, signInWithOkta, signOut } from "../ducks/login";
import { fetchAppInfo } from "../ducks/appInfo";
import { Card, CardBody, Alert, Button } from "reactstrap";
import { ACTIVE_THEME } from "../components/Theme";
import { fetchWithAuth } from "../actions/requestHelper";
import EulaModal from "./components/EulaModal";
import PropTypes from "prop-types";
import { useMsal } from "@azure/msal-react";
import EntraIdLoginButton from "../components/EntraIdLoginButton";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setAlert } from "../actions/alert";
import OktaIcon from "../components/OktaIcon";
import { OktaContext, useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
const MILLISECONDS_IN_A_DAY = 86400000;

const SIDE_BARS_STYLE = {
  border: "1px solid rgba(255,255,255,0.1)",
  width: "35%",
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: 15,
  marginLeft: 15
};

LoginContainer.propTypes = {
  connected: PropTypes.bool,
  expirationDate: PropTypes.string,
  isValid: PropTypes.bool,
  dispatch: PropTypes.func,
};

function LoginContainer(props) {
  const {
    connected,
    expirationDate,
    isValid,
    dispatch,
    eulaValidation,
    name,
    email,
    userId,
    username,
    accessToken,
    version,
    isAppLoginActive,
    isEntraIdLoginActive,
    isOktaLoginActive
  } = props;
  const daysLeftBeforeLicenseExpiration = Math.ceil(
    (new Date(expirationDate) - new Date() + MILLISECONDS_IN_A_DAY) /
    MILLISECONDS_IN_A_DAY
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordValidationEula, setPasswordValidationEula] = useState("");
  const [usernameValidationEula, setUsernameValidationEula] = useState("");
  const [isEulaDeclined, setIsEulaDeclined] = useState(false);

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const oktaContext = useContext(OktaContext);
  const { oktaAuth, authState } = oktaContext ? useOktaAuth() : { oktaAuth: null, authState: null };

  const navigate = useNavigate();

  const body = {
    id: userId,
    name: name,
    username: username,
    email: email,
    eulaValidation: eulaValidation,
  };

  // useEffect for Entra ID
  useEffect(() => {
    if (activeAccount && !connected && !isEulaDeclined) {
      handleSignInWithEntraId();
    }
  }, [activeAccount]);

  // useEffect for Okta
  useEffect(() => {
    if (oktaAuth && (!authState || !authState.isAuthenticated)) {
      oktaAuth.handleLoginRedirect()
        .catch(e => {
          console.error(e);
          dispatch(setAlert("Error while trying to log in using Okta.", "danger", 8000));
        });
    }

    if (oktaAuth && authState && authState.isAuthenticated && !isEulaDeclined) {
      handleSignInWithOkta();
    }
  }, [oktaAuth, authState]);

  useEffect(() => {
    dispatch(fetchAppInfo());
    if (connected) {
      if (!isValid || eulaValidation) {
        navigate("/");
      } else {
        setModalOpen(true);
      }
    }
  }, [dispatch, connected, eulaValidation, isValid]);

  const isNoLoginAvailable = () => {
    return isAppLoginActive.value === "" && isEntraIdLoginActive.value === "" && isOktaLoginActive.value === "";
  };

  const handleSignIn = (username, password) => {
    dispatch(signin(username, password));
  };

  const handleSignInWithEntraId = () => {
    instance.acquireTokenSilent({ scopes: ['user.read'] }).then(res => {
      dispatch(signInWithEntraId(res.idToken, res.accessToken));
    }).catch(err => {
      if (err instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect({ scopes: ['user.read'] });
      } else {
        dispatch(setAlert("Error while getting the Entra Id token required for using the application. Please contact the administrator.", "danger", 10000));
      }
    });
  };

  const handleSignInWithOkta = () => {
    dispatch(signInWithOkta(authState.accessToken.accessToken, authState.idToken.claims));
  };

  const acceptEula = () => {
    setModalOpen(false);
    setIsEulaDeclined(false);
    body.eulaValidation = true;
    fetchWithAuth(
      dispatch,
      `/v1/profiles`,
      accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then((res) => {
        if (activeAccount) {
          handleSignInWithEntraId();
        } else if (authState.isAuthenticated) {
          handleSignInWithOkta();
        } else {
          handleSignIn(usernameValidationEula, passwordValidationEula);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  const rejectEula = () => {
    setModalOpen(false);
    dispatch(signOut());
    setIsEulaDeclined(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSignIn(event.target.username.value, event.target.password.value);
  };

  const updatePassword = (e) => setPasswordValidationEula(e.target.value);
  const updateUsername = (e) => setUsernameValidationEula(e.target.value);

  const handleLoginEntraId = () => {
    instance
      .loginRedirect({
        scopes: ['user.read'],
        prompt: "create"
      })
      .then((res) => handleSignInWithEntraId())
      .catch((error) => dispatch(setAlert("Error while opening the Entra Id connection portal. Please contact the administrator.", "danger", 10000)));
  };

  const handleLoginOkta = () => {
    oktaAuth.signInWithRedirect();
  };

  return (
    <>
      <div className="text-center">
        <Card
          style={{
            maxWidth: "450px",
            margin: "auto",
            marginTop: "20px",
            backgroundColor: "#343a40",
            color: "white",
          }}
        >
          <CardBody>
            <form onSubmit={handleSubmit} className="form-signin">
              <img
                className="mt-3 mb-4"
                src={ACTIVE_THEME.logo}
                alt=""
                width="300px"
              />
              <h1 className="h3 b-3 font-weight-normal">Please sign in</h1>
              {(isAppLoginActive.value === "true" || isNoLoginAvailable()) &&
                <>
                  <label htmlFor="inputEmail" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="inputEmail"
                    onChange={updateUsername}
                    className="form-control"
                    placeholder="Email address"
                    required
                    autoFocus
                  />
                  <label htmlFor="inputPassword" className="visually-hidden">
                    Password
                  </label>
                  <input
                    type="password"
                    id="inputPassword"
                    onChange={updatePassword}
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    required
                  />
                  <div className="d-grid">
                    <button
                      className="btn btn-lg btn-primary"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </div>
                </>
              }
              {(isEntraIdLoginActive.value === "true" || isOktaLoginActive.value === "true") &&
                <span className="mt-3" style={{ width: "100%", textAlign: "center", display: "inline-block" }}>
                  <span style={SIDE_BARS_STYLE} />OR<span style={SIDE_BARS_STYLE} />
                </span>
              }
              {isEntraIdLoginActive.value === "true" &&
                <div
                  className="mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleLoginEntraId}
                >
                  <EntraIdLoginButton />
                </div>
              }
              {isOktaLoginActive.value === "true" &&
                <Button
                  color="primary"
                  className="mt-3 mx-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={handleLoginOkta}
                >
                  <OktaIcon />
                  <span className="ms-2">Sign in with Okta</span>
                </Button>
              }
              {isValid && daysLeftBeforeLicenseExpiration <= 20 && (
                <Alert color="warning" className="mt-4">
                  License expires in {daysLeftBeforeLicenseExpiration} day
                  {daysLeftBeforeLicenseExpiration === 1 ? "" : "s"}. Please
                  contact the administrator.
                </Alert>
              )}
              {!isValid && (
                <Alert color="danger" className="mt-4">
                  The license has expired, please contact the administrator.
                </Alert>
              )}
              <p className="mt-5 mb-1 text-muted">
                {!isNoLoginAvailable() && `Version ${version}`}
                <br />© {new Date().getFullYear()} CloudSoda, All rights reserved.
              </p>
            </form>
          </CardBody>
        </Card>
      </div>
      {isValid && modalOpen && (
        <EulaModal
          acceptEula={acceptEula}
          rejectEula={rejectEula}
          isModalOpen={modalOpen}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { auth, app, configurations } = state;
  const {
    connected,
    eulaValidation,
    userId,
    email,
    username,
    name,
    accessToken,
  } = auth;

  const { expirationDate, isValid, version } = app;

  const { isAppLoginActive, isEntraIdLoginActive, isOktaLoginActive } = configurations;
  return {
    connected,
    eulaValidation,
    expirationDate,
    isValid,
    userId,
    email,
    username,
    name,
    accessToken,
    version,
    isAppLoginActive,
    isEntraIdLoginActive,
    isOktaLoginActive
  };
}

export default connect(mapStateToProps)(LoginContainer);
