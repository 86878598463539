import React, { useEffect } from 'react';
import { Input, Label } from "reactstrap";
import StorageTypeForm from './StorageTypeForm';
import StorageTypeReview from './StorageTypeReview';

function StoragePriceConfiguration({
    setDataObject,
    showCreateStorageType,
    setShowCreateStorageType,
    storageTypeObj,
    setStorageTypeObj,
    storagePrices,
    setDisableForwardActionButton,
    selectedStorageType,
    setSelectedStorageType,
    storageToCreate,
    setStorageToCreate,
    setStoragePrices
}) {
    useEffect(() => {
        setDataObject(prev => ({
            ...prev,
            storageType: selectedStorageType && selectedStorageType.id,
            storageName: selectedStorageType && selectedStorageType.description
        }));
    }, [selectedStorageType, setDataObject]);

    useEffect(() => {
        const storageNameDoNotFitCharacterRestriction = (storageTypeObj.description.length === 0 || storageTypeObj.description.length > 25);
        const storageCurrencySymbolDoNotFitCharacterRestriction = (storageTypeObj.currencySymbol.length === 0 || storageTypeObj.currencySymbol.length > 20);
        const isStoragePriceEmpty = storageTypeObj.price.length === 0;
        const isStorageNameNotUnique = storagePrices.some(storage => storage.description === storageTypeObj.description);

        if (
            showCreateStorageType &&
            (
                storageNameDoNotFitCharacterRestriction ||
                storageCurrencySymbolDoNotFitCharacterRestriction ||
                isStoragePriceEmpty ||
                isStorageNameNotUnique
            )
        ) setDisableForwardActionButton(true);
    }, [storageTypeObj, setDisableForwardActionButton, showCreateStorageType, storagePrices]);

    useEffect(() => {
        if (storagePrices.length === 0) setShowCreateStorageType(true);
    }, [setShowCreateStorageType, storagePrices]);

    return (
        <div>
            {
                !showCreateStorageType &&
                <>
                    <Label for="storage">The storage price helps you evaluate the ownership cost of projects and files in the infrastructure</Label>
                    <Input
                        type="select"
                        name="storage"
                        value={selectedStorageType ? selectedStorageType.description : ""}
                        onChange={(e) => {
                            const inputValue = e.target.value;

                            if (inputValue === "AddStorageType") {
                                setShowCreateStorageType(true);
                                return;
                            }

                            const storageId = storagePrices.find(storage => storage.description === inputValue).id;
                            const storageObj = isNaN(storageId) ?
                                storagePrices.find(storage => storage.description === inputValue) :
                                storagePrices.find(storage => storage.id === storageId);

                            setDataObject(prev => ({
                                ...prev,
                                storageType: storageId,
                                storageName: storageObj.description
                            }));
                            setSelectedStorageType(storageObj);
                        }}
                    >
                        {
                            storagePrices.map((storage, index) => (
                                <option key={index} value={storage.description}>{storage.description}</option>
                            ))
                        }

                        {
                            storageToCreate.length >= 1 ?
                                null :
                                <option value="AddStorageType">Add new storage type</option>
                        }
                    </Input>
                </>
            }

            {
                !showCreateStorageType &&
                <StorageTypeReview
                    selectedStorageType={selectedStorageType}
                    storageToCreate={storageToCreate}
                    setDataObject={setDataObject}
                    setSelectedStorageType={setSelectedStorageType}
                    setStorageToCreate={setStorageToCreate}
                    setStoragePrices={setStoragePrices}
                    storagePrices={storagePrices}
                />
            }

            {
                showCreateStorageType &&
                <StorageTypeForm
                    setStorageTypeObj={setStorageTypeObj}
                    storagePrices={storagePrices}
                    storageTypeObj={storageTypeObj}
                    setDisableForwardActionButton={setDisableForwardActionButton}
                />
            }
        </div>
    );
}

export default StoragePriceConfiguration;