import React, { useEffect, } from 'react';
import { useSelector } from "react-redux";
import { Input, Label, Form, FormGroup, FormFeedback, UncontrolledTooltip } from "reactstrap";
import ArchiwareCredentialForm from "../../components/ArchiwareCredentialForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function ArchiwareStep1({ archiwareObj, setArchiwareObj, setDisableForwardActionButton, localArchiwareObjForValidation, showCredentialsForm,
    setshowCredentialsForm,
    archiwareCredentialsObj,
    setarchiwareCredentialsObj,
    url,
    setUrl,
    archiwareCredentials,
    archiwareCredentialsToCreate }) {

    const archiwareErrors = useSelector(({ archiwareCredentials }) => archiwareCredentials.errors);
    useEffect(() => {
        if (archiwareObj.credentialsId == "") { setDisableForwardActionButton(true); }
        else { setDisableForwardActionButton(false); }
    }, [archiwareObj]);

    const handleArchiwareCredentialsInputChange = (name, value) => {
        const archiwareObjCopy = { ...archiwareCredentialsObj };
        archiwareObjCopy[name] = value;
        if (name === "url") {
            setUrl(value)
        } else {
            setarchiwareCredentialsObj(archiwareObjCopy);
        }
    }

    const handleOnChange = (e) => {
        const inputValue = e.target.value;


        if (inputValue === "add new credentials") {
            setshowCredentialsForm(true);
            return;
        }


        setArchiwareObj(prev => ({ ...prev, credentialsId: inputValue }));
    }

    return (
        <>
            {
                showCredentialsForm ?
                    <ArchiwareCredentialForm
                        name={archiwareCredentialsObj.name}
                        username={archiwareCredentialsObj.username}
                        password={archiwareCredentialsObj.password}
                        url={url}
                        handleChange={handleArchiwareCredentialsInputChange}
                        errors={archiwareErrors}
                        setDisableCreateButton={setDisableForwardActionButton}
                    />
                    :

                    <Form>
                        <p>Please enter your Archiware P5 credentials and the URL to reach the Archiware P5 application.</p>

                        <FormGroup>
                            <Label for="credentialsId">Credentials
                                <FontAwesomeIcon id="WizardAwsCredentialsLabel" className="ms-sm-1" icon={faQuestionCircle} />
                            </Label>
                            <Input
                                name="credentialsId"
                                type="select"
                                value={archiwareObj.credentialsId}
                                onChange={handleOnChange}
                            >
                                <option value={""}>Select credentials</option>
                                {archiwareCredentials.map(credential => {
                                    const optionValue = (credential.id || credential.id === 0) ? credential.id : credential.name;
                                    return (
                                        <option
                                            key={optionValue}
                                            value={optionValue}
                                        >
                                            {credential.name}
                                        </option>
                                    );
                                })}
                                {
                                    archiwareCredentialsToCreate.length ?
                                        null :
                                        <option value="add new credentials">Add new credentials</option>
                                }
                            </Input>
                        </FormGroup>
                    </Form>
            }
        </>
    );
}

export default ArchiwareStep1;