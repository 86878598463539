import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReviewStep from "./ReviewStep";

export default function ManualReview(props) {
  const {
    relocate,
    setRelocate,
    storageManagerActionObj,
    setDisableForwardActionButton,
    storageManagerVolumeMappings,
    selectedFiles,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFiles, setTotalNumberOfFiles] = useState(0);
  const [validNumberOfFiles, setValidNumberOfFiles] = useState(0);
  const [totalSizeOfFiles, setTotalSizeOfFiles] = useState(0);
  const [validSizeOfFiles, setValidSizeOfFiles] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);
  const [numberOfValidFiles, setNumberOfValideFiles] = useState(0);

  const volumes = useSelector(({ volumes }) => volumes.items);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [storageManagerVolumeMappings]);

  const calculateReviewValues = () => {
    let validVolumes = {};
    storageManagerVolumeMappings.forEach((volume) => {
      validVolumes[volume.volumeId] = volumes.find(
        (v) => v.id === volume.volumeId
      ).name;
    });

    let currentValidNumberOfFiles = 0;
    let currentValidSizeOfFiles = 0;
    let numberOfValidFiles = 0;
    let activeVolume = {};

    selectedFiles.forEach((file) => {
      if (validVolumes[file.volumeId]) {
        currentValidNumberOfFiles++;
        currentValidSizeOfFiles += file.size;
        activeVolume[file.volumeId] = file.volumeName;
        if (!file.directory) {
          numberOfValidFiles++;
        }
      }
    });

    setNumberOfValideFiles(numberOfValidFiles);
    setTotalNumberOfFiles(selectedFiles.length);
    setValidNumberOfFiles(currentValidNumberOfFiles);
    setTotalSizeOfFiles(selectedFiles.reduce((a, b) => a + b.size, 0));
    setValidSizeOfFiles(currentValidSizeOfFiles);
    setActiveVolumes(Object.values(activeVolume));

    if (currentValidNumberOfFiles > 0) {
      setDisableForwardActionButton(false);
    }

    setIsFetching(false);
  };

  return (
    <ReviewStep
      numberOfValidFiles={numberOfValidFiles}
      relocate={relocate}
      setRelocate={setRelocate}
      storageManagerActionObj={storageManagerActionObj}
      isFetching={isFetching}
      activeVolumes={activeVolumes}
      totalNumberOfFiles={totalNumberOfFiles}
      validNumberOfFiles={validNumberOfFiles}
      totalSizeOfFiles={totalSizeOfFiles}
      validSizeOfFiles={validSizeOfFiles}
    />
  );
}
