import React, { useState } from "react";
import Folder from './Folder'
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { buildSearchUrl } from "../../search/searchBuilder";
import { getCost, getCostFormatted } from "../../utils/pricePerVolume";
import InfoTooltip from "../../components/InfoTooltip";
import VolumeIcon from "../../components/VolumeIcon";
import FormatSize from "../../components/FormatSize";
import { STORAGE_COST_INTERVALS } from "../../constants/storageCostInterval";

FolderGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  folderSize: PropTypes.number,
  duplicateSize: PropTypes.number,
  unusedSize: PropTypes.number,
  volumeId: PropTypes.string,
  projectItems: PropTypes.array,
  allocatedSpaceInBytes: PropTypes.number,
  filteredSearchFunc: PropTypes.func,
  storagePrice: PropTypes.object,
  indexingDate: PropTypes.string
};

function FolderGroup(props) {
  const {
    name,
    type,
    folderSize,
    duplicateSize,
    unusedSize,
    volumeId,
    projectItems,
    allocatedSpaceInBytes,
    storagePrice,
    filteredSearchFunc,
    indexingDate,
    selectedCostInterval
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  // pricing
  const price = storagePrice ? storagePrice.price : 0;
  const currencySymbol = storagePrice ? storagePrice.currencySymbol : "$";
  const storageCostInterval = selectedCostInterval;
  const storageConstIntervalValue = STORAGE_COST_INTERVALS[storageCostInterval];

  const cost = getCostFormatted(folderSize, price, currencySymbol, storageConstIntervalValue);
  const isDuplicateFilesCostOver0 = getCost(duplicateSize, price, storageConstIntervalValue) > 0.01;
  const duplicateFilesCostFormatted = getCostFormatted(duplicateSize, price, currencySymbol, storageConstIntervalValue);
  const isUnusedFilesCostOver0 = getCost(unusedSize, price, storageConstIntervalValue) > 0.01;
  const unusedFilesCostFormatted = getCostFormatted(unusedSize, price, currencySymbol, storageConstIntervalValue);

  return (
    <div className="pb-4">
      <div className="d-flex" onClick={() => setIsOpen(!isOpen)}>
        <h3><VolumeIcon volumeType={type} size="sm" />{name}</h3>
        <div className="ms-auto">
          <Button outline
            color="info"
            id={`searchFolderGroup-${volumeId}`}
            style={{ width: 40 }}
            onClick={() => filteredSearchFunc(buildSearchUrl(projectItems))}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <UncontrolledTooltip placement="auto" target={`searchFolderGroup-${volumeId}`}>
            Show files
          </UncontrolledTooltip>
          <Button className="ms-1" outline color="secondary">
            {isOpen ? "Collapse" : "Expand"}
          </Button>
        </div>
      </div>
      <div onClick={() => setIsOpen(!isOpen)}>
        Represents <strong>{Math.round((folderSize / allocatedSpaceInBytes) * 100)} %</strong> of project, totalling <strong><FormatSize>{folderSize}</FormatSize></strong>,
        for a {storageCostInterval.toLowerCase()} cost of <strong>{`${cost}`}</strong>.
        <br />
        {(isDuplicateFilesCostOver0 || isUnusedFilesCostOver0) &&
          <>
            You could save <strong>{`${duplicateFilesCostFormatted}`}</strong> in duplicate files and
            <strong>{` ${unusedFilesCostFormatted}`}</strong> in unused files.{" "}
            <InfoTooltip
              id="unusedFilesTooltip"
              text="A file is considered unused when untouched for 6 months or more"
            />
          </>}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="mt-3 row">
          {projectItems.map(item =>
            <div className="col-lg-6 col-md-12" key={item.id}>
              <Folder
                handleDetailClick={filteredSearchFunc}
                item={item}
                type={type}
                indexingDate={indexingDate}
              />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default FolderGroup;
