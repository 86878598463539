import { setAlert, dismissAlert } from "../actions/alert";
import jwt from "jsonwebtoken";

function validateConnection(accessToken) {
  return new Date(jwt.decode(accessToken).exp * 1000) > new Date();
}

function getRoles(authorities) {
  const roles = [];

  if (authorities === undefined) {
    return roles;
  }

  authorities.forEach(role => {
    roles.push(role.authority);
  });

  return roles;
}

// === Actions ===
const REQUEST_ACCESS_TOKEN = "REQUEST_ACCESS_TOKEN";
const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
const REMOVE_ACCESS_TOKEN = "REMOVE_ACCESS_TOKEN";

// === Reducers ===
export default function auth(state = {
  accessToken: "",
  connected: false,
  userId: "",
  username: "",
  name: "",
  email: "",
  roles: "",
  eulaValidation: false,
  bundles: [],
  type: ""
}, action) {
  switch (action.type) {
    case RECEIVE_ACCESS_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.accessToken,
        connected: validateConnection(action.accessToken),
        userId: jwt.decode(action.accessToken).userId,
        username: jwt.decode(action.accessToken).username,
        name: jwt.decode(action.accessToken).name,
        email: jwt.decode(action.accessToken).email,
        eulaValidation: jwt.decode(action.accessToken).eulaValidation,
        roles: getRoles(jwt.decode(action.accessToken).role),
        bundles: jwt.decode(action.accessToken).bundles,
        type: jwt.decode(action.accessToken).type
      });
    case REMOVE_ACCESS_TOKEN:
      window.localStorage.removeItem("accessToken");
      return Object.assign({}, state, {
        accessToken: "",
        connected: false
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestAccessToken() {
  return {
    type: REQUEST_ACCESS_TOKEN
  };
}

export function receiveAccessToken(accessToken) {
  return {
    type: RECEIVE_ACCESS_TOKEN,
    accessToken
  };
}

export function signOut() {
  return {
    type: REMOVE_ACCESS_TOKEN
  };
}

// === Side Effects ===
export function signin(username, password) {
  const body = {
    usernameOrEmail: username,
    password: password
  };
  return (dispatch) => {
    dispatch(requestAccessToken());
    const url = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}/auth/signin`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.status === 401 || response.status === 404) {
          dispatch(
            setAlert(
              "The username and password you entered are invalid",
              "danger"
            )
          );
          return;
        } else {
          return response.json();
        }
      })
      .then(json => {
        if (!json) {
          return;
        }
        dispatch(receiveAccessToken(json.accessToken));
        window.localStorage.setItem("accessToken", json.accessToken);
        dispatch(dismissAlert());
      })
      .catch(error => {
        dispatch(
          setAlert(
            `Signin failure. Unable to reach API at ${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}`,
            "danger"
          )
        )
      });
  };
}

export function signInWithEntraId(entraIdToken, entraAccessToken) {
  return (dispatch) => {
    dispatch(requestAccessToken());
    const url = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}/v1/auth/signin/entraid`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        idToken: entraIdToken,
        accessToken: entraAccessToken
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch(receiveAccessToken(json.accessToken));
        window.localStorage.setItem("accessToken", json.accessToken);
      })
      .catch(error => {
        console.error("Error with Entra ID login", error);
      });
  };
}

export function signInWithOkta(token, oktaUser) {
  return (dispatch) => {
    dispatch(requestAccessToken());
    const url = `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}/v1/auth/signin/okta`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({ ...oktaUser, token: token }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch(receiveAccessToken(json.accessToken));
        window.localStorage.setItem("accessToken", json.accessToken);
      })
      .catch(error => {
        console.error("Error with Okta login", error);
      });
  };
}