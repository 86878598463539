import { useEffect, useState } from "react";
import CircleChart from "./CircleChart";
import { CHART_COLORS } from "../constants/chartColors";
import { useSelector } from "react-redux";
import { formatSize } from "./FormatSize";
import Loading from "./Loading";

const OVERFLOW_COLORS = ["#D61E1E", "#C91D1E", "#B01919", "#8A1313", "#4A0A0A"];

const DEFAULT_CIRCLE_CHART_DATASET = {
  fill: false,
  lineTension: 0.1,
  borderColor: "rgba(0,0,0,0)",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: [],
  pointBackgroundColor: "#fff",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: [],
  pointHoverBorderColor: [],
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 10,
};

export default function MultiDatasetsCircleChart(props) {
  const {
    datasetsValues,
    allocatedSize,
    totalSize,
    titleOverLimit,
    chartRef,
    onClick = () => {},
  } = props;

  const [filesizeObject, setFilesizeObject] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  useEffect(() => {
    setIsFetching(true);
    setFilesizeObject(formatSize(allocatedSize, baseSize, null, "object"));
    setTimeout(() => setIsFetching(false), 100);
  }, [allocatedSize]);

  const labels = [];
  const data = [];
  const colors = [];
  const datasets = [];

  let totalSizeCalculated = totalSize;

  const getDatasets = (
    currentSize,
    currentIteration,
    shares,
    currentStorageIndex,
    isVolumeOverflowing
  ) => {
    const dataToGet =
      currentIteration === 0
        ? []
        : new Array(
            isVolumeOverflowing
              ? datasets[currentIteration - 1].data.length - 1
              : datasets[currentIteration - 1].data.length
          );
    const colorsToGet =
      currentIteration === 0
        ? []
        : new Array(
            isVolumeOverflowing
              ? datasets[currentIteration - 1].data.length - 1
              : datasets[currentIteration - 1].data.length
          );

    if (currentSize <= allocatedSize) {
      shares.forEach((share, index) => {
        dataToGet.push(
          formatSize(
            share.sizeOfFiles,
            baseSize,
            filesizeObject.exponent,
            "object"
          ).value
        );
        colorsToGet.push(CHART_COLORS[currentStorageIndex + index]);
      });

      dataToGet.push(
        formatSize(
          allocatedSize - currentSize,
          baseSize,
          filesizeObject.exponent,
          "object"
        ).value
      );
      colorsToGet.push("#FFFFFF");

      datasets.push({
        data: [...dataToGet],
        colors: [...colorsToGet],
      });

      return;
    }

    let sizeToUse = allocatedSize;

    while (sizeToUse > 0) {
      if (shares[0].sizeOfFiles > sizeToUse) {
        dataToGet.push(
          formatSize(sizeToUse, baseSize, filesizeObject.exponent, "object")
            .value
        );
        colorsToGet.push(CHART_COLORS[currentStorageIndex]);

        shares[0].sizeOfFiles -= sizeToUse;
        sizeToUse = 0;
        isVolumeOverflowing = true;
      } else {
        sizeToUse -= shares[0].sizeOfFiles;

        dataToGet.push(
          formatSize(
            shares[0].sizeOfFiles,
            baseSize,
            filesizeObject.exponent,
            "object"
          ).value
        );
        colorsToGet.push(CHART_COLORS[currentStorageIndex]);

        shares.shift();
        currentStorageIndex++;
        isVolumeOverflowing = false;
      }
    }

    datasets.push({
      data: [...dataToGet],
      colors: [...colorsToGet],
    });

    getDatasets(
      currentSize - allocatedSize,
      ++currentIteration,
      shares,
      currentStorageIndex,
      isVolumeOverflowing
    );
  };

  let shares = datasetsValues.map((share) => ({
    name: share.volumeName,
    sizeOfFiles: share.sizeOfFiles,
  }));

  const getSizePercentage = () => {
    return Math.round(
      (totalSizeCalculated / allocatedSize) * 100
    );
  };

  const initializeDatasets = (ci) => {
    const sizePercentage = getSizePercentage();

    if (
      labels.length > 0 &&
      labels[labels.length - 1] === "Space Left" &&
      sizePercentage >= 100 &&
      shares.length > 0 &&
      !ci.getDataVisibility(labels.length - 1)
    ) {
      ci.toggleDataVisibility(labels.length - 1);
    }

    labels.length = 0;
    data.length = 0;
    colors.length = 0;
    datasets.length = 0;

    if (sizePercentage >= 500) {
      for (var i = 0; i < 5; i++) {
        datasets.push({
          data: [
            formatSize(
              allocatedSize,
              baseSize,
              filesizeObject.exponent,
              "object"
            ).value,
          ],
          colors: [OVERFLOW_COLORS[i]],
        });
      }
    } else if (sizePercentage >= 100 && shares.length > 0) {
      getDatasets(totalSizeCalculated, 0, shares, 0, false);

      for (var i = datasets[0].data.length; i < datasetsValues.length; i++) {
        datasets[0].data.push(undefined);
        datasets[0].colors.push(CHART_COLORS[i]);
      }

      datasetsValues.forEach((share, index) => {
        data.push(
          formatSize(
            share.sizeOfFiles,
            baseSize,
            filesizeObject.exponent,
            "object"
          ).value
        );
        labels.push(share.volumeName);
        colors.push(CHART_COLORS[index]);
      });
    } else {
      datasetsValues.forEach((share, index) => {
        data.push(
          formatSize(
            share.sizeOfFiles,
            baseSize,
            filesizeObject.exponent,
            "object"
          ).value
        );
        labels.push(share.volumeName);
        colors.push(CHART_COLORS[index]);
      });

      data.push(
        formatSize(
          allocatedSize - totalSizeCalculated,
          baseSize,
          filesizeObject.exponent,
          "object"
        ).value
      );
      labels.push("Space Left");
      colors.push("#CCCCCC");
    }
  };

  initializeDatasets();

  const circleChartOptions = {
    onHover: (event, items) => {
      if (items.length > 0 && items[0].index !== datasetsValues.length && 
        getSizePercentage() < 500 && onClick.toString() != (() => {}).toString()) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        title: {
          display: totalSize > allocatedSize,
          text: "",
          padding: 3,
        },
        labels: {
          boxWidth: 12
        },
        onHover: (event, legendItem, legend) => {
          event.native.target.style.cursor = "pointer";
          legend.chart.data.datasets.forEach(dataset => {
            dataset.backgroundColor.forEach((color, index, colors) => {
              if (color !== undefined) {
                colors[index] = index === legendItem.index || color.length === 9 ? color : color + '4D';
              }
            });
          });
          legend.chart.update();
        },
        onLeave: (event, legendItem, legend) => {
          event.native.target.style.cursor = "default";
          legend.chart.data.datasets.forEach(dataset => {
            dataset.backgroundColor.forEach((color, index, colors) => {
              if (color !== undefined) {
                colors[index] = color.length === 9 ? color.slice(0, -2) : color;
              }
            });
          });
          legend.chart.update();
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.index;
          const ci = legend.chart;
          ci.toggleDataVisibility(index);

          if (totalSize >= allocatedSize) {
            totalSizeCalculated = totalSize;
            const visibleValues = [];
            datasetsValues.forEach((datasetsValue, index) =>
              visibleValues.push(ci.getDataVisibility(index))
            );

            shares = datasetsValues.map((share, i) => {
              totalSizeCalculated -= visibleValues[i] ? 0 : share.sizeOfFiles;
              return {
                name: share.volumeName,
                sizeOfFiles: visibleValues[i] ? share.sizeOfFiles : 0,
              };
            });

            initializeDatasets(ci);

            if (datasets.length === 0) {
              datasets.push({ data: data, colors: colors });
            }

            if (datasets.length > ci.data.datasets.length) {
              for (
                var i = 0;
                i < datasets.length - ci.data.datasets.length;
                i++
              ) {
                ci.data.datasets.push({
                  ...DEFAULT_CIRCLE_CHART_DATASET,
                  data: [],
                  backgroundColor: [],
                });
              }
            }

            ci.data.datasets.forEach((dataset, index) => {
              if (index > datasets.length - 1) {
                ci.data.datasets.splice(index, 1);
              } else {
                dataset.data.forEach((data, i) => {
                  ci.data.datasets[index].data[i] = datasets[index].data[i];
                  ci.data.datasets[index].backgroundColor[i] =
                    datasets[index].colors[i];
                });
              }

              if (
                index <= datasets.length - 1 &&
                datasets[index].data.length >
                  ci.data.datasets[index].data.length
              ) {
                for (
                  var i = ci.data.datasets[index].data.length;
                  i < datasets[index].data.length;
                  i++
                ) {
                  ci.data.datasets[index].data.push(datasets[index].data[i]);
                  ci.data.datasets[index].backgroundColor.push(
                    datasets[index].colors[i]
                  );
                }
              }
            });
          }

          ci.update();
        },
      },
      tooltip: {
        filter: (tooltipItem) => tooltipItem.label !== "",
        callbacks: {
          title: (context) => {
            if (context.length > 0) {
              return [context[0].label];
            }
          },
          label: (context) => [
            `Size: ${data[context.dataIndex]} ${filesizeObject.unit}`,
          ],
        },
      },
      datalabels: {
        color: "white",
        display: function (context) {
          const totalValue = context.dataset.data
            .map((value, index) =>
              context.chart.getDataVisibility(index) ? parseInt(value) : 0
            )
            .reduce((prev, curr) => prev + curr);
          return (
            totalSizeCalculated < allocatedSize &&
            context.dataset.data[context.dataIndex] / totalValue > 0.05
          );
        },
      },
      title: {
        display: totalSize > allocatedSize,
        text: titleOverLimit,
        color: "black",
        font: {
          size: 14,
        },
      },
    },
  };

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <CircleChart
          circleChartRef={chartRef}
          data={data}
          title={"Size (GB)"}
          labels={labels}
          color={colors}
          height={220}
          unit={filesizeObject.unit}
          datasets={datasets}
          options={circleChartOptions}
          maintainAspectRatio={false}
          onClick={(e) => totalSize / allocatedSize < 5 && onClick(e)}
        />
      )}
    </>
  );
}
