import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
export const REQUEST_STORAGE_PRICE_LIST = "REQUEST_STORAGE_PRICE_LIST";
export const RECEIVE_STORAGE_PRICE_LIST = "RECEIVE_STORAGE_PRICE_LIST";
export const RECEIVE_INVALID_STORAGE_PRICE = "RECEIVE_INVALID_STORAGE_PRICE";

// === Reducers ===
export default function storagePrices(
  state = {
    items: [],
    isFetching: false,
    errors: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_STORAGE_PRICE_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_STORAGE_PRICE_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.storagePrices,
        errors: {}
      });
    case RECEIVE_INVALID_STORAGE_PRICE:
      return Object.assign({}, state, {
        errors: formatError(action.errors)
      });
    default:
      return state;
  }
}

// === Action Creators ===
export function requestServerList() {
  return {
    type: REQUEST_STORAGE_PRICE_LIST
  };
}

export function receiveServerList(storagePrices) {
  return {
    type: RECEIVE_STORAGE_PRICE_LIST,
    storagePrices
  };
}

export function receiveInvalidServerList(errors) {
  return {
    type: RECEIVE_INVALID_STORAGE_PRICE,
    errors
  };
}

// === Side Effects ===
export function fetchStoragePriceList() {
  return (dispatch, getState) => {
    dispatch(requestServerList());
    const url = `/v1/storage-prices`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then(json => {
        dispatch(receiveServerList(json));
      })
      .catch(err => {
        const message = err.message || "Unable to get storage price";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function createStoragePriceSetting(description, currencySymbol, price) {
  return (dispatch, getState) => {
    dispatch(requestServerList());
    const body = {
      description,
      currencySymbol,
      price
    };
    const url = `/v1/storage-prices`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "POST",
      JSON.stringify(body)
    )
      .then(response => {
        dispatch(setAlert("Storage price was created successfully", "success"));
        dispatch(fetchStoragePriceList());
      })
      .catch(err => {
        if (err.subErrors) { dispatch(receiveInvalidServerList(err)) }
        else { dispatch(setAlert(err.message, "danger")) }
      })
  }
}

export function updateStoragePriceSetting(id, description, currencySymbol, price) {
  return (dispatch, getState) => {
    dispatch(requestServerList());
    const body = {
      id,
      description,
      currencySymbol,
      price
    };
    const url = `/v1/storage-prices/${id}`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then(response => {
        dispatch(setAlert(`Storage price with id [${id}] was updated successfully`, "success"));
        dispatch(fetchStoragePriceList());
      })
      .catch(err => {
        if (err.subErrors) { dispatch(receiveInvalidServerList(err)) }
        else { dispatch(setAlert(err.message, "danger")) }
      })
  }
}

export function deleteStoragePriceSetting(id) {
  return (dispatch, getState) => {
    dispatch(requestServerList());
    const url = `/v1/storage-prices/${id}`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "DELETE"
    )
      .then(response => {
        dispatch(setAlert(`Storage price with id [${id}] was deleted successfully`, "success"));
        dispatch(fetchStoragePriceList());
      })
      .catch(err => {
        if (err.message) {
          dispatch(setAlert(err.message, "danger"));
        } else {
          dispatch(setAlert(err, "danger"));
        }
      });
  }
}