import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import FolderNode from "./FolderNode";
import { getDeltaFolders, getDirectoriesByPath } from "../../api/directory";
import Loading from "../Loading";
import FolderTreeActions from "./FolderTreeActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import LoadMoreNode from "./LoadMoreNode";
import SizeBaseButton from "../SizeBaseButton";
import DateLabelWithTooltip from "../DateLabelWithTooltip";

const dynamicColumnEnum = {
  numberOfFiles: "Number of Files",
  numberOfFolders: "Number of Folders",
  owner: "Owner",
  group: "Group",
  creationDate: "Creation Date",
  lastAccess: <DateLabelWithTooltip dateLabel="lastAccess" />,
  lastModified: <DateLabelWithTooltip dateLabel="lastModified" />,
  lastAccessFolder: <DateLabelWithTooltip dateLabel="lastAccessFolder" />,
  lastModifiedFolder: <DateLabelWithTooltip dateLabel="lastModifiedFolder" />,
  indexingDate: "Indexing Date",
  monthlyCost: "Monthly Cost",
  customTag: "Tags",
};

export const getTreeNodeChildrenStyle = (file) => {
  let color = "";
  let fontWeight = "";

  if (file.isCreated) {
    color = "#375C82";
    fontWeight = "bold";
  } else if (file.isRemoved) {
    color = "#8BA6C1";
  }

  return {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: color,
    fontWeight: fontWeight,
  };
};

export const ROW_COLOR = {
  onHover: "rgba(0,0,0,.2)",
  isFound: "#17a2b8",
  isOdd: "#f5f5f5",
  isEven: "#e9ecef",
};

export const getChildNodeFromTreeNodes = (
  children,
  positionInTree,
  index = 0
) => {
  for (var i = 0; i < children.length; i++) {
    const child = children[i];
    if (i === positionInTree[index]) {
      if (index === positionInTree.length - 1) {
        return child;
      } else {
        return getChildNodeFromTreeNodes(
          child.children,
          positionInTree,
          index + 1
        );
      }
    }
  }
};

export const getFormattedDeltaFiles = (deltaFiles, currentFiles = []) => {
  const formattedList = [];
  const currentFileIds = currentFiles.reduce(
    (prev, curr) => ({ ...prev, [curr.fileId]: curr.fileId }),
    {}
  );

  for (var deltaFile of deltaFiles) {
    let formattedFile = deltaFile.currentFile;

    if (deltaFile.currentFile === null) {
      formattedFile = deltaFile.previousFile;
      formattedFile.isRemoved = true;
      formattedFile.delta = -deltaFile.previousFile.size;
      formattedFile.size = -1;
    } else if (deltaFile.previousFile === null) {
      formattedFile.isCreated = true;
      formattedFile.delta = deltaFile.currentFile.size;
    } else {
      formattedFile.delta =
        deltaFile.currentFile.size - deltaFile.previousFile.size;
    }

    if (currentFileIds[formattedFile.fileId] !== undefined) continue;

    formattedList.push(formattedFile);
  }

  return formattedList;
};

const MoreOptionsIcon = ({ children }) => {
  return (
    <span
      style={{
        border: "1px solid #6c757d",
        borderRadius: "5px",
        float: "right",
        color: "#6c757d",
        width: "18px",
        fontSize: "10px",
        marginLeft: "auto",
        order: 3
      }}
    >
      {children}
    </span>
  );
};

export default function FolderTree(props) {
  const {
    volumes = [],
    path,
    selectedFolders = [],
    isProject,
    filterString = "",
    previousIndexingDate,
    isOpenable = true,
    isDefaultOpen = true,
    hasCheckboxes = true,
    hasFiles = true,
    hasHeader = true,
    hasRowBackground = true,
    showOnlyFirstColumn = false,
    hasActions = true,
    hasDeltaChartColumn = false,
    isSortable = true,
    defaultSort,
    defaultDirection,
    hideLastColumn = false,
    onNameClickFile = null,
    onNameClickFolder = null,
    onNameDoubleClickFile = () => {},
    onNameDoubleClickFolder = () => {},
    onCheckboxClick = () => {},
    fileFetchSize = 500,
    folderFetchSize = 500,
    volumeSelector = false,
    setShownFileDetails = () => {},
    onMountAction = () => {},
    maxHeight = "1000px",
  } = props;

  const [isFetching, setIsFetching] = useState(true);
  const [isTagFileOpen, setIsTagFileOpen] = useState(false);
  const [treeNodes, setTreeNodes] = useState([]);
  const [isSelectAllVisibleTriggered, setIsSelectAllVisibleTriggered] =
    useState(false);
  const [isSelectNoneTriggered, setIsSelectNoneTriggered] = useState(false);
  const [sort, setSort] = useState("");
  const [direction, setDirection] = useState("");
  const [dynamicColumnTitle, setDynamicColumnTitle] = useState("");
  const [isOpenDynamicColumnDropdown, setIsOpenDynamicColumnMenuDropdown] =
    useState(false);
  const [isOpenNameColumnDropdown, setIsOpenNameColumnDropdown] =
    useState(false);
  const [isShowHiddenFolders, setIsShowHiddenFolders] = useState(false);
  const [isLastColumnHidden, setIsLastColumnHidden] = useState(false);
  const [currentFolderFetchPage, setCurrentFolderFetchPage] = useState(1);
  const [isFetchingNextPageForFolders, setIsFetchingNextPageForFolders] =
    useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  useEffect(() => {
    const isLastColumnHiddenValue =
      window.localStorage.getItem("isLastColumnHidden");
    if (isLastColumnHiddenValue) {
      setIsLastColumnHidden(isLastColumnHiddenValue === "true");
    }

    const showHiddenFolders = window.localStorage.getItem("showHiddenFoldersFileManagement");
    if (showHiddenFolders) {
      setIsShowHiddenFolders(showHiddenFolders === "true");
    }

    const folderTreeSortValue = window.localStorage.getItem("folderTreeSort");
    const folderTreeDirectionValue = window.localStorage.getItem(
      "folderTreeDirection"
    );
    const folderTreeLastColumnValue = window.localStorage.getItem(
      "folderTreeLastColumn"
    );

    setSort(defaultSort ?? folderTreeSortValue ?? "size");
    setDirection(defaultDirection ?? folderTreeDirectionValue ?? "desc");
    setDynamicColumnTitle(
      hasDeltaChartColumn
        ? "deltaChartColumn"
        : folderTreeLastColumnValue ?? "lastModified"
    );
  }, []);

  useEffect(() => {
    if (volumes.length === 0) {
      return;
    }

    setIsFetching(true);
    if (hasDeltaChartColumn) {
      if (previousIndexingDate !== null) {
        getDeltaFolders(
          dispatch,
          auth,
          indexingDate,
          previousIndexingDate,
          path,
          volumes[0].id,
          1,
          2,
          "name",
          "ASC",
          isShowHiddenFolders
        )
          .then((res) => {
            const folders = getFormattedDeltaFiles(res);
            const folder = folders.find((f) => f.path === path);

            setTreeNodes([
              {
                file: folder,
                isChecked: false,
                children: [],
              },
            ]);
            setTimeout(() => setIsFetching(false), 100);
          })
          .catch((err) => {
            console.error(err);
            setTimeout(() => setIsFetching(false), 100);
          });
      }
    } else {
      if (volumeSelector) {
        setTreeNodes(
          volumes.map((volume) => {
            let item = {
              name: "",
              type: volume.type,
              volumeId: volume.id,
              volumeName: volume.description ?? volume.name,
              path: "/",
              size: volume.sizeOfFiles
            };

            return {
              file: item,
              isChecked: getCheckedFile(item, true),
              children: [],
            };
          })
        );
        setTimeout(() => setIsFetching(false), 100);
      } else {
        getDirectoriesByPath(
          dispatch,
          auth,
          indexingDate,
          volumes.map((volume) => volume.id),
          path,
          1,
          folderFetchSize,
          sort,
          direction
        )
          .then((res) => {
            setTreeNodes(
              res.elements.map((folder) => {
                return {
                  file: folder,
                  isChecked: getCheckedFile(folder, true),
                  children: [],
                };
              })
            );
            onMountAction(res.elements[0]);
            setTimeout(() => setIsFetching(false), 100);
          })
          .catch((err) => {
            console.error(err);
            setTimeout(() => setIsFetching(false), 100);
          });
      }
    }
  }, [volumes.length, path, previousIndexingDate, sort, direction, isShowHiddenFolders]);

  const fetchNextPageForFolders = () => {
    setIsFetchingNextPageForFolders(true);

    getDirectoriesByPath(
      dispatch,
      auth,
      indexingDate,
      volumes.map((volume) => volume.id),
      path,
      currentFolderFetchPage + 1,
      folderFetchSize,
      sort,
      direction
    )
      .then((res) => {
        setCurrentFolderFetchPage((prev) => prev + 1);
        setTreeNodes((prev) => [
          ...prev,
          ...res.elements.map((folder) => {
            return {
              file: folder,
              isChecked: getCheckedFile(folder, true),
              children: [],
            };
          }),
        ]);
        setTimeout(() => setIsFetchingNextPageForFolders(false), 100);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => setIsFetchingNextPageForFolders(false), 100);
      });
  };

  const getCheckedFile = (file, isRoot) => {
    if (selectedFolders.length) {
      const isSelected = selectedFolders.some(
        (folder) =>
          folder.volumeSetting?.id === file.volumeId &&
          folder.path === (isRoot ? file.name : file.path)
      );

      return isSelected;
    }
    return false;
  };

  const getIsFound = (name) => {
    const formattedName = name.toLowerCase();
    const formattedFilterString = filterString.toLowerCase();

    if (filterString !== "" && formattedName.includes(formattedFilterString)) {
      return true;
    }

    return false;
  };

  const getMoreOptionsNameColumnIcon = () => {
    return (
      <MoreOptionsIcon>
        <Dropdown
          group
          isOpen={isOpenNameColumnDropdown}
          toggle={(e) => {
            e.stopPropagation();
            setIsOpenNameColumnDropdown((prev) => !prev);
          }}
          direction="right"
        >
          <DropdownToggle data-toggle="dropdown" tag="span">
            <FontAwesomeIcon icon={faEllipsisVertical} style={{ paddingLeft: ".44rem", paddingRight: ".5rem" }} />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                window.localStorage.setItem("showHiddenFoldersFileManagement", !isShowHiddenFolders);
                setIsShowHiddenFolders((prev) => !prev);
                // Fetch
              }}
            >
              {isShowHiddenFolders ? "Hide" : "Show"} hidden folders
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </MoreOptionsIcon>
    );
  };

  const getMoreOptionsDynamicColumnIcon = () => {
    return (
      <MoreOptionsIcon>
        <Dropdown
          group
          isOpen={isOpenDynamicColumnDropdown}
          toggle={(e) => {
            e.stopPropagation();
            setIsOpenDynamicColumnMenuDropdown((prev) => !prev);
          }}
          direction="left"
        >
          <DropdownToggle data-toggle="dropdown" tag="span">
            <FontAwesomeIcon icon={faEllipsisVertical} style={{ paddingLeft: ".44rem", paddingRight: ".5rem" }} />
          </DropdownToggle>
          <DropdownMenu>
            {!isLastColumnHidden && (
              <>
                <DropdownItem
                  onClick={() => {
                    setIsLastColumnHiddenValue(true);
                  }}
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,.125)",
                    marginBottom: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  Hide
                </DropdownItem>
              </>
            )}
            {Object.entries(dynamicColumnEnum).map((entry, index) => {
              return (
                <DropdownItem
                  key={entry + "-" + index}
                  onClick={() => {
                    setDynamicColumnTitle(entry[0]);
                    window.localStorage.setItem(
                      "folderTreeLastColumn",
                      entry[0]
                    );
                    setIsLastColumnHiddenValue(false);
                  }}
                >
                  {entry[1]}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </MoreOptionsIcon>
    );
  };

  const getSortIcon = () => {
    const sortIcon = direction === "asc" ? faCaretUp : faCaretDown;
    return <FontAwesomeIcon className="mx-1" icon={sortIcon} color={isSortable ? "" : "#adb3b8"} />;
  };

  const getSortAndDirection = (newSort) => {
    const currentSort = sort;

    if (currentSort === newSort) {
      const newDirection = direction === "asc" ? "desc" : "asc";
      setDirection(newDirection);
      window.localStorage.setItem("folderTreeDirection", newDirection);
    } else {
      setSort(newSort);
      window.localStorage.setItem("folderTreeSort", newSort);
    }
  };

  const showLastColumn = !isLastColumnHidden || hasDeltaChartColumn;

  const getTableHeadColumn = (columnSize, columnName) => {
    let shownColumnName;
    let paddingLeftStyle = "";
    let paddingRightStyle = "";
    let canBeSorted = isSortable;
    let isDynamic = false;

    if (columnName === "name") {
      paddingLeftStyle = "10px";
      shownColumnName = "Name";
    } else if (columnName === "size") {
      shownColumnName = `${hasDeltaChartColumn ? "Current " : ""}Size`;
    } else {
      const isDeltaChartColumn = columnName === "deltaChartColumn";
      paddingRightStyle = "5px";
      shownColumnName = isDeltaChartColumn
        ? `Variation Between ${
            previousIndexingDate ?? "Previous Date"
          } and ${indexingDate}`
        : dynamicColumnEnum[columnName];
      canBeSorted = isSortable && !isDeltaChartColumn;
      isDynamic = !isDeltaChartColumn;
    }

    return (
      <Col
        sm={columnSize}
        onClick={() => canBeSorted && getSortAndDirection(columnName)}
        style={{
          cursor: canBeSorted ? "pointer" : "",
          paddingLeft: paddingLeftStyle,
          paddingRight: paddingRightStyle,
          borderLeft: columnName === "size" && "1px solid rgba(0,0,0,.125)",
          borderRight:
            columnName === "size" &&
            !hideLastColumn &&
            showLastColumn &&
            "1px solid rgba(0,0,0,.125)",
        }}
      >
        <strong style={{ display: "flex", alignItems: "center" }}>
          {shownColumnName} {columnName === sort && getSortIcon()}{" "}
          {columnName === "name" && getMoreOptionsNameColumnIcon()}
          {columnName === "size" && !showLastColumn && getMoreOptionsDynamicColumnIcon()}
          {columnName === "size" && <SizeBaseButton marginRight={!showLastColumn && "10px"} marginLeft={"auto"} />}
          {isDynamic && getMoreOptionsDynamicColumnIcon()}
        </strong>
      </Col>
    );
  };

  const setIsLastColumnHiddenValue = (value) => {
    setIsLastColumnHidden(value);
    window.localStorage.setItem("isLastColumnHidden", value);
  };

  return (
    <Container fluid="sm" style={{ maxWidth: "100%" }}>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {hasActions && (
            <FolderTreeActions
              treeNodes={treeNodes}
              setTreeNodes={setTreeNodes}
              setIsSelectAllVisibleTriggered={(isTriggered) =>
                setIsSelectAllVisibleTriggered(isTriggered)
              }
              setIsSelectNoneTriggered={(isTriggered) =>
                setIsSelectNoneTriggered(isTriggered)
              }
            />
          )}
          {hasHeader && (
            <Row
              style={{
                borderBottom: "1px solid rgba(0,0,0,.125)",
                paddingBottom: "4px",
                marginBottom: "8px",
              }}
            >
              {getTableHeadColumn((!hideLastColumn && showLastColumn) ? 6 : 9, "name")}
              {getTableHeadColumn((!hideLastColumn && showLastColumn) ? 2 : 3, "size")}
              {!hideLastColumn && showLastColumn && getTableHeadColumn(4, dynamicColumnTitle)}
            </Row>
          )}
          <Row style={{ overflowY: "auto", maxHeight: maxHeight }}>
            <Col sm={12}>
              {treeNodes.length > 0 ? (
                <>
                  {treeNodes.map((node, index) => {
                    const folder = node.file;
                    return (
                      folder && (
                        <FolderNode
                          key={folder.fileId + "-" + index}
                          getCheckedFile={getCheckedFile}
                          folder={folder}
                          volumeType={
                            volumes.find(
                              (volume) => volume.id === folder.volumeId
                            )?.type
                          }
                          isProject={isProject}
                          isOpenable={isOpenable}
                          filterString={filterString}
                          previousIndexingDate={previousIndexingDate}
                          showOnlyFirstColumn={showOnlyFirstColumn}
                          isDefaultOpen={isDefaultOpen}
                          hasCheckboxes={hasCheckboxes}
                          hasRowBackground={hasRowBackground}
                          hasFiles={hasFiles}
                          hasActions={hasActions}
                          onNameClickFile={onNameClickFile}
                          onNameClickFolder={onNameClickFolder}
                          onNameDoubleClickFile={(file) =>
                            onNameDoubleClickFile(file)
                          }
                          onNameDoubleClickFolder={(file) =>
                            onNameDoubleClickFolder(file)
                          }
                          onCheckboxClick={onCheckboxClick}
                          fileFetchSize={fileFetchSize}
                          folderFetchSize={folderFetchSize}
                          valueToMarge={hasCheckboxes ? 1 : 0}
                          treeNodes={treeNodes}
                          setTreeNodes={setTreeNodes}
                          positionInTree={[index]}
                          isTagFileOpen={isTagFileOpen}
                          setIsTagFileOpen={setIsTagFileOpen}
                          isFound={getIsFound(
                            folder.name === "" ? folder.volumeName : folder.name
                          )}
                          getIsFound={(fileName) => getIsFound(fileName)}
                          isSelectAllVisibleTriggered={
                            isSelectAllVisibleTriggered
                          }
                          setIsSelectAllVisibleTriggered={(isTriggered) =>
                            setIsSelectAllVisibleTriggered(isTriggered)
                          }
                          isSelectNoneTriggered={isSelectNoneTriggered}
                          setIsSelectNoneTriggered={(isTriggered) =>
                            setIsSelectNoneTriggered(isTriggered)
                          }
                          sort={sort}
                          direction={direction}
                          isShowHiddenFolders={isShowHiddenFolders}
                          dynamicColumnTitle={dynamicColumnTitle}
                          isRootFolderNode
                          setShownFileDetails={(file) =>
                            setShownFileDetails(file)
                          }
                          showLastColumn={!hideLastColumn && showLastColumn}
                        />
                      )
                    );
                  })}
                  <LoadMoreNode
                    numberOfChildren={treeNodes.length}
                    sizeToFetch={folderFetchSize}
                    currentPage={currentFolderFetchPage}
                    valueToMarge={hasCheckboxes ? 1.8 : 1}
                    loadMoreFunction={fetchNextPageForFolders}
                    isFetching={isFetchingNextPageForFolders}
                  />
                </>
              ) : (
                <p>No folder found</p>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
