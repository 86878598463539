import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchBucketCredentialList,
  createBucketCredential,
  createDropboxCredential,
  updateBucketCredential,
  updateDropboxCredential,
  deleteBucketCredential,
} from "../../ducks/bucketCredential";
import { fetchBucketList } from "../../ducks/bucket";
import CredentialList from "./CredentialList";
import BucketList from "./BucketList";
import PropTypes from "prop-types";

Buckets.propTypes = {
  dispatch: PropTypes.func,
  storagePriceList: PropTypes.array,
  bucketCredentials: PropTypes.object,
  buckets: PropTypes.object,
};

function Buckets(props) {
  const {
    dispatch,
    storagePriceList,
    bucketCredentials,
    buckets,
    openChosenWizardFromSection,
  } = props;

  useEffect(() => {
    dispatch(fetchBucketCredentialList());
    dispatch(fetchBucketList());
  }, []);

  return (
    <>
      <BucketList
        buckets={buckets.items}
        page={buckets.page}
        size={buckets.size}
        sort={buckets.sort}
        total={buckets.total}
        totalBucketCredentials={bucketCredentials.total}
        direction={buckets.direction}
        storagePriceList={storagePriceList}
        errors={buckets.errors}
        formValid={buckets.formValid}
        isFetching={buckets.isFetching}
        openChosenWizardFromSection={openChosenWizardFromSection}
      />
      <br />
      <CredentialList
        credentials={bucketCredentials.items}
        total={bucketCredentials.total}
        page={bucketCredentials.page}
        size={bucketCredentials.size}
        sort={bucketCredentials.sort}
        direction={bucketCredentials.direction}
        createCredential={(credential) => {
          if (credential.type === "DROPBOX") {
            dispatch(createDropboxCredential(credential));
          } else {
            dispatch(createBucketCredential(credential));
          }
        }}
        updateCredential={(id, credential, page, sort, direction, size) => {
          if (credential.type === "DROPBOX") {
            dispatch(updateDropboxCredential(id, credential));
          } else {
            dispatch(updateBucketCredential(id, credential));
          }
        }}
        deleteCredential={(id) => dispatch(deleteBucketCredential(id))}
        fetchBucketCredentialsListPage={(page, sort, direction, size) =>
          dispatch(fetchBucketCredentialList(page, sort, direction, size))
        }
        errors={bucketCredentials.errors}
        formValid={bucketCredentials.formValid}
        isFetching={bucketCredentials.isFetching}
      />
    </>
  );
}

function mapStateToProps(state) {
  const { bucketCredentials, buckets } = state;
  return { bucketCredentials, buckets };
}

export default connect(mapStateToProps)(Buckets);
