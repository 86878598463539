import React, { useEffect, useState } from "react";
import TableResult from "../../components/TableResult";
import CronHelper from "../../components/CronHelper";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../components/ConfirmModal";
import { Input, FormGroup, FormFeedback, Label, Badge, Col } from "reactstrap";

const VOLUME_SPACE =
  "io.dataintell.visionapi.schedule.job.SendVolumeSpaceAlertJob";
const PROJECT_SPACE =
  "io.dataintell.visionapi.schedule.job.SendProjectSpaceAlertJob";
const DEFAULT_THRESHOLD = 80;
const DAILY_CRON_VALUE = "0 0 0 * * ?";
const WEEKLY_CRON_VALUE = "0 0 0 ? * MON";
const MONTHLY_CRON_VALUE = "0 0 0 1 * ?";
const CUSTOM_CRON_VALUE = "0 0 12 * * ?";
const PREMADE_CRON = [
  { label: "DAILY", value: DAILY_CRON_VALUE },
  { label: "WEEKLY", value: WEEKLY_CRON_VALUE },
  { label: "MONTHLY", value: MONTHLY_CRON_VALUE },
  { label: "CUSTOM", value: CUSTOM_CRON_VALUE },
];

export default function AlertConfigurations(props) {
  const {
    schedules,
    projects,
    volumes,
    userId,
    errors,
    formValid,
    deleteFunc,
    createFunc,
    updateFunc,
  } = props;

  // show modal state
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [alertId, setAlertId] = useState("");

  // new schedule state
  const [newAlertName, setNewAlertName] = useState("");
  const [newAlertCron, setNewAlertCron] = useState(PREMADE_CRON[0].value);
  const [newAlertCustomCron, setNewAlertCustomCron] = useState(
    PREMADE_CRON[0].value
  );
  const [isCustomCron, setIsCustomCron] = useState(false);
  const [newAlertTask, setNewAlertTask] = useState(PROJECT_SPACE);
  const [newAlertThreshold, setNewAlertThreshold] = useState(DEFAULT_THRESHOLD);
  const [newAlertResourceIds, setNewAlertResourceIds] = useState([]);
  const [newAlertProjects, setNewAlertProjects] = useState(projects);
  const [newAlertVolumes, setNewAlertVolumes] = useState(volumes);
  const [newAlertResourceInvalid, setNewAlertResourceInvalid] = useState(false);
  const [newAlertThresholdInvalid, setNewAlertThresholdInvalid] =
    useState(false);

  // effects
  useEffect(() => {
    setNewAlertProjects(projects);
    setNewAlertVolumes(volumes);
  }, [projects, volumes]);

  // utils
  const resetNewAlertValues = () => {
    setNewAlertName("");
    setNewAlertCron(PREMADE_CRON[0].value);
    setNewAlertTask(PROJECT_SPACE);
    setNewAlertThreshold(DEFAULT_THRESHOLD);
    setNewAlertResourceIds([]);
    setNewAlertProjects(projects);
    setNewAlertVolumes(volumes);
    setIsCustomCron(false);
    setNewAlertResourceInvalid(false);
    setNewAlertThresholdInvalid(false);
    setShowCreateModal(false);
    setShowUpdateModal(false);
  };

  useEffect(() => {
    if (formValid) {
      setShowCreateModal(false);
      setShowUpdateModal(false);
      setNewAlertName("");
      setNewAlertCron(PREMADE_CRON[0].value);
      setNewAlertTask(PROJECT_SPACE);
      setNewAlertThreshold(DEFAULT_THRESHOLD);
      setNewAlertResourceIds([]);
      setNewAlertProjects(projects);
      setNewAlertVolumes(volumes);
      setIsCustomCron(false);
      setNewAlertResourceInvalid(false);
      setNewAlertThresholdInvalid(false);
    }
  }, [projects, volumes, formValid]);

  const isValidThreshold = (value) => value >= 0 && value <= 100;

  const formatCron = (cron) => {
    let formattedCron = cron;
    PREMADE_CRON.forEach((obj) => {
      if (obj.value === cron) {
        formattedCron = obj.label;
      }
    });
    return formattedCron;
  };

  const formatResourceId = (jobClass, id) => {
    let resourceName = id;
    if (PROJECT_SPACE === jobClass) {
      resourceName = "projectName";
      const proj = projects.find((p) => p.id === id);
      if (proj) resourceName = proj.name;
    } else if (VOLUME_SPACE === jobClass) {
      resourceName = "volumeName";
      const vol = volumes.find((v) => v.id === id);
      if (vol) resourceName = vol.name;
    }
    return resourceName;
  };

  const formatAlertTypes = (jobClass) => {
    if (PROJECT_SPACE === jobClass) {
      return "Project Space";
    } else if (VOLUME_SPACE === jobClass) {
      return "Volume Space";
    } else {
      return "Unknown type";
    }
  };

  const listFormated = schedules.map((schedule) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      schedule.name,
      formatAlertTypes(schedule.jobClass),
      schedule.payload.threshold,
      schedule.payload.resourceIds.map(
        (id) => `${formatResourceId(schedule.jobClass, id)}, `
      ),
      formatCron(schedule.cron),
      <>
        <Button
          onClick={() => {
            setAlertId(schedule.id);
            setNewAlertName(schedule.name);
            setNewAlertCron(schedule.cron);
            setNewAlertTask(schedule.jobClass);
            setNewAlertThreshold(schedule.payload.threshold);
            setNewAlertResourceIds(schedule.payload.resourceIds);
            if (
              DAILY_CRON_VALUE !== schedule.cron &&
              WEEKLY_CRON_VALUE !== schedule.cron &&
              MONTHLY_CRON_VALUE !== schedule.cron
            ) {
              setNewAlertCron(CUSTOM_CRON_VALUE);
              setIsCustomCron(true);
              setNewAlertCustomCron(schedule.cron);
            }
            if (PROJECT_SPACE === schedule.jobClass) {
              setNewAlertProjects(
                newAlertProjects.filter(
                  (p) => !schedule.payload.resourceIds.includes(p.id)
                )
              );
            } else if (VOLUME_SPACE === schedule.jobClass) {
              setNewAlertVolumes(
                newAlertVolumes.filter(
                  (v) => !schedule.payload.resourceIds.includes(v.id)
                )
              );
            }
            setShowUpdateModal(true);
          }}
          color="secondary"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setAlertId(schedule.id);
            setShowDeleteModal(true);
          }}
          color="danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateModal(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h3>Alert Configurations</h3>
      <p>
        Setup email alerts on Project/Volumes space threshold to better monitor
        your resources. Alerts can be configured to check for threshold breaks
        whenever you want via cron configuration.
        <br />
        <span style={{ color: "gray" }}>
          For example, you could setup alerts to monitor once a day, or every 5
          minutes.
        </span>
      </p>
      <TableResult
        title={"Alerts"}
        titles={[
          { name: "Name" },
          { name: "Type" },
          { name: "Threshold (%)" },
          { name: "Targeted Resources" },
          { name: "When" },
          { name: "Actions" },
        ]}
        data={listFormated}
      />
      <ConfirmModal
        showConfirmation={showCreateModal}
        title="Alert Creation"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          if (
            newAlertResourceIds.length > 0 &&
            newAlertThresholdInvalid === false
          ) {
            createFunc({
              name: newAlertName,
              jobGroup: "ALERT",
              jobClass: newAlertTask,
              cron: newAlertCustomCron,
              payload: {
                userId: userId,
                threshold: newAlertThreshold,
                resourceIds: newAlertResourceIds,
              },
            });
          } else if (newAlertResourceIds.length === 0) {
            setNewAlertResourceInvalid(true);
          }
        }}
        closeConfirmation={() => {
          resetNewAlertValues();
        }}
      >
        <FormGroup row>
          <Label for="type" sm={3}>
            Type
          </Label>
          <Col sm={9}>
            <Input
              id="type"
              name="type"
              type="select"
              value={newAlertTask}
              onChange={(event) => {
                setNewAlertTask(event.target.value);
                setNewAlertResourceIds([]);
                // add back to selectables
                setNewAlertProjects(projects);
                setNewAlertVolumes(volumes);
              }}
              disabled={false}
            >
              <option value={PROJECT_SPACE}>Project Space</option>
              <option value={VOLUME_SPACE}>Volume Space</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="ids" sm={3}>
            Resources
          </Label>
          <Col sm={9}>
            {newAlertResourceIds.length > 0 && (
              <>
                {newAlertResourceIds.map((value, index) => {
                  return (
                    <Badge
                      key={index}
                      style={{
                        marginRight: 5,
                        marginBottom: 5,
                        fontSize: "100%",
                      }}
                      color="primary"
                    >
                      {formatResourceId(newAlertTask, value)}
                      <Badge
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        className="text-dark"
                        color="light"
                        onClick={() => {
                          // remove from selections
                          setNewAlertResourceIds(
                            newAlertResourceIds.filter((id) => id !== value)
                          );
                          // add back to selectables
                          if (PROJECT_SPACE === newAlertTask) {
                            const p = projects.find((p) => p.id === value);
                            setNewAlertProjects([...newAlertProjects, p]);
                          } else if (VOLUME_SPACE === newAlertTask) {
                            const v = volumes.find((v) => v.id === value);
                            setNewAlertVolumes([...newAlertVolumes, v]);
                          }
                        }}
                      >
                        X
                      </Badge>
                    </Badge>
                  );
                })}
                <Badge
                  key="clear"
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                    fontSize: "100%",
                    cursor: "pointer",
                  }}
                  color="danger"
                  onClick={() => {
                    // remove from selections
                    setNewAlertResourceIds([]);
                    // add back to selectables
                    setNewAlertProjects(projects);
                    setNewAlertVolumes(volumes);
                  }}
                >
                  Clear all
                </Badge>
              </>
            )}

            {PROJECT_SPACE === newAlertTask && (
              <Input
                name="ids"
                type="select"
                value=""
                onChange={(event) => {
                  // add to state
                  setNewAlertResourceIds([
                    ...newAlertResourceIds,
                    Number(event.target.value),
                  ]);
                  // remove from selectables
                  setNewAlertProjects(
                    newAlertProjects.filter(
                      (p) => p.id !== Number(event.target.value)
                    )
                  );
                  setNewAlertResourceInvalid(false);
                }}
                invalid={newAlertResourceInvalid}
              >
                <option value="">Select project</option>
                {newAlertProjects.map((project, index) => {
                  return (
                    <option key={index} value={project.id}>
                      {project.name}
                    </option>
                  );
                })}
              </Input>
            )}

            {VOLUME_SPACE === newAlertTask && (
              <Input
                name="ids"
                type="select"
                value=""
                onChange={(event) => {
                  // add to state
                  setNewAlertResourceIds([
                    ...newAlertResourceIds,
                    Number(event.target.value),
                  ]);
                  // remove from selectables
                  setNewAlertVolumes(
                    newAlertVolumes.filter(
                      (v) => v.id !== Number(event.target.value)
                    )
                  );
                  setNewAlertResourceInvalid(false);
                }}
                invalid={newAlertResourceInvalid}
              >
                <option value="">Select volumes</option>
                {newAlertVolumes.map((volume, index) => {
                  return (
                    <option key={index} value={volume.id}>
                      {volume.name}
                    </option>
                  );
                })}
              </Input>
            )}
            <FormFeedback>{"You must select at least 1 resource"}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="threshold" sm={3}>
            Threshold (%)
          </Label>
          <Col sm={9}>
            <Input
              id="threshold"
              name="threshold"
              type="number"
              min={0}
              max={100}
              placeholder="Ex: 80"
              value={newAlertThreshold}
              invalid={newAlertThresholdInvalid}
              onChange={(event) => {
                setNewAlertThreshold(Number(event.target.value));
                setNewAlertThresholdInvalid(
                  !isValidThreshold(Number(event.target.value))
                );
              }}
            />
            <FormFeedback>{"Threshold must be between 0 and 100"}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="cron" sm={3}>
            When {isCustomCron && <CronHelper></CronHelper>}
          </Label>
          <Col sm={9}>
            <Input
              id="cron"
              name="cron"
              type="select"
              value={newAlertCron}
              onChange={(event) => {
                setNewAlertCron(event.target.value);
                setNewAlertCustomCron(event.target.value);
                if (
                  "CUSTOM" ===
                  event.target.options[event.target.selectedIndex].text
                ) {
                  setIsCustomCron(true);
                } else {
                  setIsCustomCron(false);
                }
              }}
            >
              {PREMADE_CRON.map((cron, index) => {
                return (
                  <option key={index} value={cron.value}>
                    {cron.label}
                  </option>
                );
              })}
            </Input>
          </Col>
          {isCustomCron && (
            <>
              <Col sm={3}></Col>
              <Col sm={9}>
                <Input
                  id="custom-cron"
                  name="custom-cron"
                  type="text"
                  value={newAlertCustomCron}
                  onChange={(event) => {
                    setNewAlertCustomCron(event.target.value);
                  }}
                  invalid={errors["cron"] !== undefined}
                />
                <FormFeedback>{errors["cron"]}</FormFeedback>
              </Col>
            </>
          )}
        </FormGroup>
        <FormGroup row>
          <Label for="name" sm={3}>
            Name
          </Label>
          <Col sm={9}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Ex: My Useful A"
              value={newAlertName}
              onChange={(event) => {
                setNewAlertName(event.target.value);
              }}
              invalid={errors["name"] !== undefined}
            />
            <FormFeedback>{errors["name"]}</FormFeedback>
          </Col>
        </FormGroup>
      </ConfirmModal>

      <ConfirmModal
        showConfirmation={showUpdateModal}
        title="Alert Modification"
        buttonColor="primary"
        buttonText="Update"
        confirmAction={() => {
          if (
            newAlertResourceIds.length > 0 &&
            newAlertThresholdInvalid === false
          ) {
            updateFunc(alertId, {
              name: newAlertName,
              jobGroup: "ALERT",
              jobClass: newAlertTask,
              cron: newAlertCustomCron,
              payload: {
                userId: userId,
                threshold: newAlertThreshold,
                resourceIds: newAlertResourceIds,
              },
            });
          } else if (newAlertResourceIds.length === 0) {
            setNewAlertResourceInvalid(true);
          }
        }}
        closeConfirmation={() => {
          resetNewAlertValues();
        }}
      >
        <FormGroup row>
          <Label for="type" sm={3}>
            Type
          </Label>
          <Col sm={9}>
            <Input
              id="type"
              name="type"
              type="select"
              value={newAlertTask}
              onChange={(event) => {
                setNewAlertTask(event.target.value);
                setNewAlertResourceIds([]);
              }}
              disabled={true}
            >
              <option value={PROJECT_SPACE}>Project Space</option>
              <option value={VOLUME_SPACE}>Volume Space</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="ids" sm={3}>
            Resources
          </Label>
          <Col sm={9}>
            {newAlertResourceIds.length > 0 && (
              <>
                {newAlertResourceIds.map((value, index) => {
                  return (
                    <Badge
                      key={index}
                      style={{
                        marginRight: 5,
                        marginBottom: 5,
                        fontSize: "100%",
                      }}
                      color="primary"
                    >
                      {formatResourceId(newAlertTask, value)}
                      <Badge
                        style={{ cursor: "pointer", marginLeft: 10 }}
                        color="light"
                        className="text-dark"
                        onClick={() => {
                          // remove from selections
                          setNewAlertResourceIds(
                            newAlertResourceIds.filter((id) => id !== value)
                          );
                          // add back to selectables
                          if (PROJECT_SPACE === newAlertTask) {
                            const p = projects.find((p) => p.id === value);
                            setNewAlertProjects([...newAlertProjects, p]);
                          } else if (VOLUME_SPACE === newAlertTask) {
                            const v = volumes.find((v) => v.id === value);
                            setNewAlertVolumes([...newAlertVolumes, v]);
                          }
                        }}
                      >
                        X
                      </Badge>
                    </Badge>
                  );
                })}
                <Badge
                  key="clear"
                  style={{
                    marginRight: 5,
                    marginBottom: 5,
                    fontSize: "100%",
                    cursor: "pointer",
                  }}
                  color="danger"
                  onClick={() => {
                    // remove from selections
                    setNewAlertResourceIds([]);
                    // add back to selectables
                    setNewAlertProjects(projects);
                    setNewAlertVolumes(volumes);
                  }}
                >
                  Clear all
                </Badge>
              </>
            )}

            {PROJECT_SPACE === newAlertTask && (
              <Input
                name="ids"
                type="select"
                value=""
                onChange={(event) => {
                  // add to state
                  setNewAlertResourceIds([
                    ...newAlertResourceIds,
                    Number(event.target.value),
                  ]);
                  // remove from selectables
                  setNewAlertProjects(
                    newAlertProjects.filter(
                      (p) => p.id !== Number(event.target.value)
                    )
                  );
                  setNewAlertResourceInvalid(false);
                }}
                invalid={newAlertResourceInvalid}
              >
                <option value="">Select project</option>
                {newAlertProjects.map((project, index) => {
                  return (
                    <option key={index} value={project.id}>
                      {project.name}
                    </option>
                  );
                })}
              </Input>
            )}

            {VOLUME_SPACE === newAlertTask && (
              <Input
                name="ids"
                type="select"
                value=""
                onChange={(event) => {
                  // add to state
                  setNewAlertResourceIds([
                    ...newAlertResourceIds,
                    Number(event.target.value),
                  ]);
                  // remove from selectables
                  setNewAlertVolumes(
                    newAlertVolumes.filter(
                      (v) => v.id !== Number(event.target.value)
                    )
                  );
                  setNewAlertResourceInvalid(false);
                }}
                invalid={newAlertResourceInvalid}
              >
                <option value="">Select volumes</option>
                {newAlertVolumes.map((volume, index) => {
                  return (
                    <option key={index} value={volume.id}>
                      {volume.name}
                    </option>
                  );
                })}
              </Input>
            )}
            <FormFeedback>{"You must select at least 1 resource"}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="threshold" sm={3}>
            Threshold (%)
          </Label>
          <Col sm={9}>
            <Input
              id="threshold"
              name="threshold"
              type="number"
              min={0}
              max={100}
              placeholder="Ex: 80"
              value={newAlertThreshold}
              invalid={newAlertThresholdInvalid}
              onChange={(event) => {
                setNewAlertThreshold(Number(event.target.value));
                setNewAlertThresholdInvalid(
                  !isValidThreshold(Number(event.target.value))
                );
              }}
            />
            <FormFeedback>{"Threshold must be between 0 and 100"}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="cron" sm={3}>
            When {isCustomCron && <CronHelper></CronHelper>}
          </Label>
          <Col sm={9}>
            <Input
              id="cron"
              name="cron"
              type="select"
              value={newAlertCron}
              onChange={(event) => {
                setNewAlertCron(event.target.value);
                setNewAlertCustomCron(event.target.value);
                if (
                  "CUSTOM" ===
                  event.target.options[event.target.selectedIndex].text
                ) {
                  setIsCustomCron(true);
                } else {
                  setIsCustomCron(false);
                }
              }}
            >
              {PREMADE_CRON.map((cron, index) => {
                return (
                  <option key={index} value={cron.value}>
                    {cron.label}
                  </option>
                );
              })}
            </Input>
          </Col>
          {isCustomCron && (
            <>
              <Col sm={3}></Col>
              <Col sm={9}>
                <Input
                  id="custom-cron"
                  name="custom-cron"
                  type="text"
                  value={newAlertCustomCron}
                  onChange={(event) => {
                    setNewAlertCustomCron(event.target.value);
                  }}
                  invalid={errors["cron"] !== undefined}
                />
                <FormFeedback>{errors["cron"]}</FormFeedback>
              </Col>
            </>
          )}
        </FormGroup>
        <FormGroup row>
          <Label for="name" sm={3}>
            Name
          </Label>
          <Col sm={9}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Ex: My Useful A"
              value={newAlertName}
              onChange={(event) => {
                setNewAlertName(event.target.value);
              }}
              invalid={errors["name"] !== undefined}
            />
            <FormFeedback>{errors["name"]}</FormFeedback>
          </Col>
        </FormGroup>
      </ConfirmModal>

      <ConfirmModal
        showConfirmation={showDeleteModal}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteFunc(alertId);
          setShowDeleteModal(false);
        }}
        closeConfirmation={() => setShowDeleteModal(false)}
      >
        Are you sure you want to delete this alert id[{alertId}] ?
      </ConfirmModal>
    </>
  );
}
