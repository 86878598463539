import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Row,
} from "reactstrap";

function SodaVolumeMappingForm(props) {
  const {
    sodaStorages = [],
    volumeSettings = [],
    volumeMappings = [],
    dataIntellStorage,
    value,
    rule,
    sodaStorage,
    handleChange,
    isUpdate,
    setDisableCreateButton,
  } = props;

  const [filterVolumes, setFilterVolumes] = useState([...volumeSettings]);

  const [localErrors, setLocalErrors] = useState({
    dataIntellStorage: "",
    value: "",
    sodaStorage: "",
  });

  useEffect(() => {
    if (
      dataIntellStorage.length === 0 ||
      (value.length === 0 && rule !== "None") ||
      sodaStorage.length === 0
    ) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [dataIntellStorage, sodaStorage, rule, value, setDisableCreateButton]);

  useEffect(() => {
    const volumeCopy = volumeSettings.filter(
      (volume) =>
        !volumeMappings.some(
          (volumeMapping) => volumeMapping.volumeId === volume.id
        ) || (isUpdate ? volume.id === dataIntellStorage : false)
    );
    setFilterVolumes(volumeCopy);
  }, [volumeSettings, isUpdate, volumeMappings]);

  const handleLocalErrors = (name, value) => {
    switch (name) {
      case "dataIntellStorage":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            dataIntellStorage: "DataIntell Storage must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            dataIntellStorage: "",
          }));
        }
        break;
      case "value":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            value: "Value must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            value: "",
          }));
        }
        break;
      case "sodaStorage":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            sodaStorage: "CloudSoda Storage must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            sodaStorage: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    handleChange(name, value);
    handleLocalErrors(name, value);
  };

  return (
    <Form>
      <FormGroup row>
        <Label for="dataIntellStorage" sm={4}>
          DataIntell Storage
        </Label>
        <Col sm={8}>
          <Input
            id="dataIntellStorage"
            name="dataIntellStorage"
            type="select"
            value={dataIntellStorage}
            onChange={handleInputChange}
            invalid={Boolean(localErrors["dataIntellStorage"].length)}
          >
            <option value={""}>Select DataIntell Storage</option>
            {filterVolumes.map((volume) => {
              return (
                <option key={volume.id} value={volume.id}>
                  {volume.type === "AWS-S3"
                    ? volume.name.replace("S3-BUCKET-", "")
                    : volume.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{localErrors["dataIntellStorage"]}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="sodaStorage" sm={4}>
          CloudSoda Storage
        </Label>
        <Col sm={8}>
          <Input
            id="sodaStorage"
            name="sodaStorage"
            type="select"
            value={sodaStorage}
            onChange={handleInputChange}
            invalid={Boolean(localErrors["sodaStorage"].length)}
          >
            <option value={""}>Select CloudSoda Storage</option>
            {sodaStorages.map((sodaStorages) => {
              return (
                <option key={sodaStorages.id} value={sodaStorages.id}>
                  {sodaStorages.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{localErrors["sodaStorage"]}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="rule" sm={4}>
          Rule
        </Label>
        <Col sm={8}>
          <Input
            id="rule"
            name="rule"
            type="select"
            value={rule}
            onChange={handleInputChange}
          >
            <option value="None">None</option>
            <option value="Append">Append</option>
            <option value="Ignore">Ignore</option>
          </Input>
        </Col>
      </FormGroup>

      <div hidden={rule === "None"}>
        <FormGroup row>
          <Label for="value" sm={4}>
            Value
          </Label>
          <Col sm={8}>
            <Input
              id="value"
              name="value"
              placeholder="Ex: /data"
              type="text"
              value={value}
              onChange={handleInputChange}
              invalid={Boolean(localErrors["value"].length)}
            />
            <FormFeedback>{localErrors["value"]}</FormFeedback>
          </Col>
        </FormGroup>
      </div>

      <Row>
        {rule === "Append" && (
          <Col sm={12}>
            <span style={{ color: "gray" }}>
              <strong>Example</strong>
              <br />
              Volume on DataIntell server is configured with:
              <strong> /mnt/NAS/data</strong>
              <br />
              Volume on CloudSoda agent is configured with:{" "}
              <strong>/mnt/NAS</strong>
              <br />
              The append value should be:<strong> /data</strong>
            </span>
          </Col>
        )}

        {rule === "Ignore" && (
          <Col sm={12}>
            <span style={{ color: "gray" }}>
              <strong>Example</strong>
              <br />
              Volume on DataIntell server is configured with:
              <strong> /mnt/NAS</strong>
              <br />
              Volume on CloudSoda agent is configured with:
              <strong> /mnt/NAS/data</strong>
              <br /> The ignore value should be: <strong> /data</strong>
            </span>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default SodaVolumeMappingForm;
