import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { searchFiles, getSizeOfSearchedFiles } from "../../api/file";
import { searchDirectories } from "../../api/directory";
import ReviewStep from "./ReviewStep";

export default function SearchReview(props) {
  const {
    relocate,
    setRelocate,
    storageManagerActionObj,
    setDisableForwardActionButton,
    searchText,
    storageManagerVolumeMappings,
    isDirectory,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFiles, setTotalNumberOfFiles] = useState(0);
  const [validNumberOfFiles, setValidNumberOfFiles] = useState(0);
  const [totalSizeOfFiles, setTotalSizeOfFiles] = useState(0);
  const [validSizeOfFiles, setValidSizeOfFiles] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const dispatch = useDispatch();

  const volumes = useSelector(({ volumes }) => volumes.items);
  const search = useSelector(({ search }) => search);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [storageManagerVolumeMappings]);

  const getListOfValidVolumeIds = (searchVolumeIds) => {
    if (searchVolumeIds) {
      return searchVolumeIds.filter((id) =>
        storageManagerVolumeMappings.some(
          (mapping) => mapping.volumeId === Number(id)
        )
      );
    }
    return storageManagerVolumeMappings.map((mapping) => mapping.volumeId);
  };

  const calculateReviewValues = async () => {
    const validVolumeIds = getListOfValidVolumeIds(search.filters.volumeIds);
    setActiveVolumes(validVolumeIds);

    if (!validVolumeIds.length) {
      setDisableForwardActionButton(true);
      setValidNumberOfFiles(0);
      setValidSizeOfFiles(0);
      setIsFetching(false);
      return;
    }

    const searchFilters = Object.assign({}, search.filters);
    searchFilters.volumeIds = validVolumeIds;

    try {
      const searchSizeForValidFiles = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        searchFilters
      );

      const fileSearchResult = await searchFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const folderSearchResult = await searchDirectories(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const totalSearchSize = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        search.filters
      );

      setTotalNumberOfFiles(isDirectory ? search.folders.total : search.total);
      setValidNumberOfFiles(
        isDirectory ? folderSearchResult.total : fileSearchResult.total
      );
      setTotalSizeOfFiles(totalSearchSize);
      setValidSizeOfFiles(searchSizeForValidFiles);

      if (isDirectory) {
        if (folderSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      } else {
        if (fileSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      }
    } catch (e) {
      dispatch(setAlert(e.message, "danger", 7000));
    }

    setIsFetching(false);
  };

  return (
    <ReviewStep
      relocate={relocate}
      setRelocate={setRelocate}
      storageManagerActionObj={storageManagerActionObj}
      isFetching={isFetching}
      activeVolumes={activeVolumes.map((id) => {
        volumes.find((volume) => volume.id === Number(id))?.name;
      })}
      totalNumberOfFiles={totalNumberOfFiles}
      validNumberOfFiles={validNumberOfFiles}
      totalSizeOfFiles={totalSizeOfFiles}
      validSizeOfFiles={validSizeOfFiles}
      isDirectory={isDirectory}
      numberOfValidFiles={isDirectory ? 0 : validNumberOfFiles}
    />
  );
}
