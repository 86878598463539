import React from "react";
import { FormGroup, Label, Input, Badge } from "reactstrap";
import CreateGroupForm from "./CreateGroupForm";

function GroupManagement({
    availableGroups,
    selectedGroups,
    showCreateGroupInWizard,
    existingGroupsAndGroupsBeforeCreation,
    groupObjForWizard,
    setGroupObjForWizard,
    setDisableAddGroup,
    handleGroupSelectInput,
    removeGroup
}) {
    return (
        <>
            {
                showCreateGroupInWizard ?
                    <CreateGroupForm
                        groupObjForWizard={groupObjForWizard}
                        setGroupObjForWizard={setGroupObjForWizard}
                        setDisableConfirmAction={setDisableAddGroup}
                        existingGroupsAndGroupsBeforeCreation={existingGroupsAndGroupsBeforeCreation}
                    /> :
                    <FormGroup>
                        <Label for="group">Groups</Label>
                        <Input
                            type="select"
                            value=""
                            onChange={e => {
                                const inputValue = e.target.value;
                                handleGroupSelectInput(inputValue);
                            }}
                            name="group"
                            id="group"
                            style={{ marginBottom: 10 }}
                        >
                            <option value="">Add group</option>
                            <option value="create group">Create group</option>
                            {availableGroups.map((group, index) => {
                                return (
                                    <option key={index} value={index}>{group.name}</option>
                                );
                            })}
                        </Input>
                        {selectedGroups.map((group, index) => {
                            return (
                                <Badge key={index} style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }} color="primary">
                                    {group.name}
                                    <Badge
                                        style={{ cursor: "pointer", marginLeft: 10 }}
                                        color="light"
                                        className="text-dark"
                                        onClick={() => {
                                            removeGroup(group);
                                        }}
                                    >
                                        X
                                    </Badge>
                                </Badge>
                            );
                        })}
                    </FormGroup>
            }
        </>
    );
}

export default GroupManagement;