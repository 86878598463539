import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import URLInput from "../../components/URLInput";

export default function StorageManagerCredentialsForm(props) {
  const {
    name,
    setName,
    username,
    setUsername,
    password,
    setPassword,
    url,
    setUrl,
    setDisableCreateButton
  } = props;

  const [isNameInvalid, setIsNameInvalid] = useState(false);

  useEffect(() => {
    if (name.length === 0) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [name]);

  return (
    <Form>
      <FormGroup row>
        <Label for="name" sm={3}>
          Name
          <FontAwesomeIcon
            id="nameLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <UncontrolledTooltip placement="auto" target="nameLabel">
            User readable name for the Quantum StorNext credentials
          </UncontrolledTooltip>
        </Label>
        <Col sm={9}>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Ex: name"
            value={name}
            onChange={e => {
              const value = e.target.value;
              setName(value);
              setIsNameInvalid(value.length === 0);
            }}
            invalid={isNameInvalid}
          />
          <FormFeedback>Name must be between 1 and 255 characters</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="username" sm={3}>
          Username
          <FontAwesomeIcon
            id="usernameLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <UncontrolledTooltip placement="auto" target="usernameLabel">
            It can be left empty if the authentication type is set to None in Quantum StorNext Web Services (V2) section
          </UncontrolledTooltip>
        </Label>
        <Col sm={9}>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="Ex: username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="password" sm={3}>
          Password
          <FontAwesomeIcon
            id="passwordLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <UncontrolledTooltip placement="auto" target="passwordLabel">
            It can be left empty if the authentication type is set to None in Quantum StorNext Web Services (V2) section
          </UncontrolledTooltip>
        </Label>
        <Col sm={9}>
          <Input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="url" sm={3}>URL</Label>
        <Col sm={9}>
          <URLInput
            urlConfiguration={url}
            updateURL={setUrl}
          />
        </Col>
      </FormGroup>
    </Form>
  );
}