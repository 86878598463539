import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_ARCHIWARE_VOLUME_MAPPING =
  "REQUEST_CREATE_ARCHIWARE_VOLUME_MAPPING";
const RECEIVE_CREATE_ARCHIWARE_VOLUME_MAPPING =
  "RECEIVE_CREATE_ARCHIWARE_VOLUME_MAPPING";
// READ
const REQUEST_ARCHIWARE_VOLUME_MAPPING_LIST =
  "REQUEST_ARCHIWARE_VOLUME_MAPPING_LIST";
const RECEIVE_ARCHIWARE_VOLUME_MAPPING_LIST =
  "RECEIVE_ARCHIWARE_VOLUME_MAPPING_LIST";
// UPDATE
const REQUEST_UPDATE_ARCHIWARE_VOLUME_MAPPING =
  "REQUEST_UPDATE_ARCHIWARE_VOLUME_MAPPING";
const RECEIVE_UPDATE_ARCHIWARE_VOLUME_MAPPING =
  "RECEIVE_UPDATE_ARCHIWARE_VOLUME_MAPPING";
// DELETE
const REQUEST_DELETE_ARCHIWARE_VOLUME_MAPPING =
  "REQUEST_DELETE_ARCHIWARE_VOLUME_MAPPING";
const RECEIVE_DELETE_ARCHIWARE_VOLUME_MAPPING =
  "RECEIVE_DELETE_ARCHIWARE_VOLUME_MAPPING";
// ERRORS
const RECEIVE_INVALID_ARCHIWARE_VOLUME_MAPPING =
  "RECEIVE_INVALID_ARCHIWARE_VOLUME_MAPPING";

// === Reducers ===
export default function archiwareVolumeMappings(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: true,
  },
  action
) {
  switch (action.type) {
    case REQUEST_ARCHIWARE_VOLUME_MAPPING_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ARCHIWARE_VOLUME_MAPPING_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        page: action.archiwareVolumeMappings.page,
        items: action.archiwareVolumeMappings.elements,
        total: action.archiwareVolumeMappings.total,
        size: action.archiwareVolumeMappings.size,
        sort: action.archiwareVolumeMappings.sort,
        direction: action.archiwareVolumeMappings.direction,
        errors: {},
        formValid: true,
      });
    case RECEIVE_INVALID_ARCHIWARE_VOLUME_MAPPING:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false,
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestArchiwareVolumeMappingList() {
  return {
    type: REQUEST_ARCHIWARE_VOLUME_MAPPING_LIST,
  };
}

function receiveArchiwareVolumeMappingList(archiwareVolumeMappings) {
  return {
    type: RECEIVE_ARCHIWARE_VOLUME_MAPPING_LIST,
    archiwareVolumeMappings,
  };
}

function receiveInvalidArchiwareVolumeMapping(errors) {
  return {
    type: RECEIVE_INVALID_ARCHIWARE_VOLUME_MAPPING,
    errors,
  };
}

function requestCreateArchiwareVolumeMapping() {
  return {
    type: REQUEST_CREATE_ARCHIWARE_VOLUME_MAPPING,
  };
}

function receiveCreateArchiwareVolumeMapping(createdArchiwareVolumeMapping) {
  return {
    type: RECEIVE_CREATE_ARCHIWARE_VOLUME_MAPPING,
    archiwareVolumeMapping: createdArchiwareVolumeMapping,
  };
}

function requestUpdateArchiwareVolumeMapping() {
  return {
    type: REQUEST_UPDATE_ARCHIWARE_VOLUME_MAPPING,
  };
}

function receiveUpdateArchiwareVolumeMapping(updatedArchiwareVolumeMapping) {
  return {
    type: RECEIVE_UPDATE_ARCHIWARE_VOLUME_MAPPING,
    archiwareVolumeMapping: updatedArchiwareVolumeMapping,
  };
}

function requestDeleteArchiwareVolumeMapping(id) {
  return {
    type: REQUEST_DELETE_ARCHIWARE_VOLUME_MAPPING,
    id,
  };
}

function receiveDeleteArchiwareVolumeMapping(id) {
  return {
    type: RECEIVE_DELETE_ARCHIWARE_VOLUME_MAPPING,
    id,
  };
}

// === Side Effects ===
export function fetchArchiwareVolumeMappingList(
  page = 1,
  sort = "id",
  direction = "asc",
  size = window.localStorage.getItem(
    "numberOfElementsDataSourceArchiwareMapping"
  )
    ? Number(
        window.localStorage.getItem(
          "numberOfElementsDataSourceArchiwareMapping"
        )
      )
    : 10
) {
  return async (dispatch, getState) => {
    window.localStorage.setItem(
      "numberOfElementsDataSourceArchiwareMapping",
      size
    );
    dispatch(requestArchiwareVolumeMappingList());
    const url = `/v1/archiwares/volumeMapping?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
    try {
      const json = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      dispatch(receiveArchiwareVolumeMappingList(json));
    } catch (err) {
      const message =
        err.statusText ||
        `Unable to get the list of On-Premises storage path configurations`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createArchiwareVolumeMapping(archiwareVolumeMapping) {
  return async (dispatch, getState) => {
    dispatch(requestCreateArchiwareVolumeMapping());
    try {
      const newArchiwareVolumeMapping = await fetchWithAuth(
        dispatch,
        "/v1/archiwares/volumeMapping",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(archiwareVolumeMapping)
      );
      dispatch(receiveCreateArchiwareVolumeMapping(newArchiwareVolumeMapping));
      dispatch(
        fetchArchiwareVolumeMappingList(
          1,
          getState().archiwareVolumeMappings.sort,
          getState().archiwareVolumeMappings.direction,
          getState().archiwareVolumeMappings.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidArchiwareVolumeMapping(err));
      else {
        const message =
          err.message ||
          "Unable to create On-Premises storage path configuration";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateArchiwareVolumeMapping(id, archiwareVolumeMapping) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateArchiwareVolumeMapping());
    try {
      const updatedArchiwareVolumeMapping = await fetchWithAuth(
        dispatch,
        `/v1/archiwares/volumeMapping/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(archiwareVolumeMapping)
      );
      dispatch(
        receiveUpdateArchiwareVolumeMapping(updatedArchiwareVolumeMapping)
      );
      dispatch(
        fetchArchiwareVolumeMappingList(
          1,
          getState().archiwareVolumeMappings.sort,
          getState().archiwareVolumeMappings.direction,
          getState().archiwareVolumeMappings.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidArchiwareVolumeMapping(err));
      else {
        const message =
          err.message ||
          "Unable to update On-Premises storage path configuration";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function deleteArchiwareVolumeMapping(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteArchiwareVolumeMapping(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/archiwares/volumeMapping/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(
        fetchArchiwareVolumeMappingList(
          1,
          getState().archiwareVolumeMappings.sort,
          getState().archiwareVolumeMappings.direction,
          getState().archiwareVolumeMappings.size
        )
      );
      dispatch(receiveDeleteArchiwareVolumeMapping(id));
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}
