import { Spinner } from "reactstrap";
import SourceReview from "../archiwareArchiveRestore/SourceReview";

const TRANSFER_ACTION_MAP = {
  OVERWRITE: "Overwrite Target File",
  RENAME: "Rename Transferred File",
  SKIP: "Skip Source File",
};

export default function SodaActionReview({
  sodaActionObj,
  volumes,
  isFetching,
  activeVolumes,
  totalNumberOfFolders,
  validNumberOfFolders,
  totalSizeOfFolders,
  validSizeOfFolders,
  isDirectory
}) {
  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-2">
          <SourceReview
            fileOrFolder={isDirectory ? "folder" : "file"}
            action={sodaActionObj.action}
            fileType="mapped CloudSoda"
            activeVolumes={activeVolumes}
            totalNumberOfFilesOrFolders={totalNumberOfFolders}
            numberOfValidFileOrFolders={validNumberOfFolders}
            totalFileSize={totalSizeOfFolders}
            validTotalFileSize={validSizeOfFolders}
          >
            <li>
              Job alias:{" "}
              <strong>
                {sodaActionObj.jobAlias === ""
                  ? "Going to be generated by CloudSoda"
                  : sodaActionObj.jobAlias}
              </strong>
            </li>
            <li>
              Transfer Type:{" "}
              <strong>
                {sodaActionObj.action[0].toUpperCase() +
                  sodaActionObj.action.slice(1)}
              </strong>
            </li>
          </SourceReview>

          <hr />

          <h3>Target</h3>
          <ul
            style={{
              listStyle: "none",
              lineHeight: "2",
              paddingLeft: "0",
              marginBottom: "0",
            }}
          >
            <li>
              Storage:{" "}
              <strong>
                {
                  volumes.find(
                    (volume) => volume.id === sodaActionObj.target.volume
                  ).name
                }
              </strong>
            </li>
            <li>
              Path: <strong>{sodaActionObj.target.path}</strong>
            </li>
            <li>
              Conflict Handling:{" "}
              <strong>
                {TRANSFER_ACTION_MAP[sodaActionObj.target.conflictHandling]}
              </strong>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
