import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import VolumeSettingForm from "./VolumeSettingForm";

function UpdateVolumeSettingFormModal(props) {
  const {
    id,
    save,
    closeModal,
    networkVolumes,
    volumes,
    getIgnoredPathsFromVolumeIgnoreRules,
    showUpdateVolumeSettingForm,
    serverList,
    storagePriceList,
    isProcessing,
  } = props;

  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editPath, setEditPath] = useState("");
  const [editServerId, setEditServerId] = useState(1);
  const [editStoragePriceId, setEditStoragePriceId] = useState(1);
  const [editIgnoredPaths, setEditIgnoredPaths] = useState([]);
  const [editHost, setEditHost] = useState("");
  const [editShareName, setEditShareName] = useState("");
  const [editOptions, setEditOptions] = useState("");
  const [editScanType, setEditScanType] = useState("VOLUME_MULTITHREAD")
  const [editIndexFolderOnlyPaths, setEditIndexFolderOnlyPaths] = useState([]);
  const [editCredential, setEditCredential] = useState("");
  const [editMountType, setEditMountType] = useState("Local");
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (id) {
      editVolumeSetting(id);
    }
  }, [id]);

  const handleChange = (name, value) => {
    switch (name) {
      case "name":
        setEditName(value);
        break;
      case "path":
        setEditPath(value);
        break;
      case "server":
        setEditServerId(value);
        break;
      case "storagePrice":
        setEditStoragePriceId(value);
        break;
      case "ignoredPaths":
        setEditIgnoredPaths(value);
        break;
      case "host":
        setEditHost(value);
        break;
      case "shareName":
        setEditShareName(value);
        break;
      case "options":
        setEditOptions(value);
        break;
      case "scanType":
        setEditScanType(value);
        break;
      case "indexFolderOnlyPaths":
        setEditIndexFolderOnlyPaths(value);
        break;
      case "credential":
        setEditCredential(value);
        break;
      default:
    }
  };

  const editNetworkVolume = (networkVolume) => {
    setEditId(networkVolume.id);
    setEditName(networkVolume.dataintellName);
    setEditPath(networkVolume.path);
    setEditServerId(networkVolume.serverId);
    setEditStoragePriceId(networkVolume.storagePrice.id);
    setEditIgnoredPaths(networkVolume.volumeIgnoreRules);
    setEditHost(networkVolume.host);
    setEditShareName(networkVolume.shareName);
    setEditOptions(networkVolume.options);
    setEditScanType(networkVolume.scanType);
    setEditIndexFolderOnlyPaths(networkVolume.volumeIndexFolderOnlyPaths)
    setEditCredential(
      networkVolume.networkVolumeCredential
        ? networkVolume.networkVolumeCredential.id
        : ""
    );
    setEditMountType(networkVolume.type);
  };

  const editVolumeSetting = (id) => {
    const networkVolume = networkVolumes.find((nv) => nv.volumeId === id);

    if (networkVolume !== undefined) {
      editNetworkVolume(networkVolume);
    } else {
      const volumeToUpdate = volumes.find((volume) => volume.id === id);

      const ignoredPaths = getIgnoredPathsFromVolumeIgnoreRules(
        volumeToUpdate.volumeIgnoreRules
      );

      setEditId(volumeToUpdate.id);
      setEditName(volumeToUpdate.name);
      setEditPath(volumeToUpdate.path);
      setEditServerId(volumeToUpdate.server.id);
      setEditStoragePriceId(volumeToUpdate.storagePrice.id);
      setEditIgnoredPaths(ignoredPaths);
      setEditScanType(volumeToUpdate.scanType)
      setEditIndexFolderOnlyPaths(volumeToUpdate.volumeIndexFolderOnlyPaths.map(volumeIndexFolderOnlyPath => volumeIndexFolderOnlyPath.path));
      setEditMountType("Local");
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setEditId(null);
    setEditName("");
    setEditServerId("");
    setEditStoragePriceId("");
    setEditIgnoredPaths([]);
    setEditHost("");
    setEditShareName("");
    setEditOptions("");
    setEditScanType("VOLUME_MULTITHREAD")
    setEditIndexFolderOnlyPaths([]);
    setEditCredential("");
    setEditMountType("Local");
    setIsSaveButtonEnabled(true);
  };

  return (
    <>
      <ConfirmModal
        showConfirmation={showUpdateVolumeSettingForm}
        title="Update On-Premises Storage"
        buttonColor="primary"
        buttonText="Save"
        isProcessing={isProcessing}
        confirmAction={() =>
          save(
            editId,
            editName,
            editPath,
            editServerId,
            editStoragePriceId,
            editIgnoredPaths,
            editCredential,
            editMountType,
            editShareName,
            editHost,
            editOptions,
            editScanType,
            editIndexFolderOnlyPaths
          )
        }
        closeConfirmation={() => handleCloseModal()}
        disableRightButton={!isSaveButtonEnabled}
      >
        <VolumeSettingForm
          name={editName}
          path={editPath}
          serverList={serverList}
          serverId={editServerId}
          storagePriceList={storagePriceList}
          storagePriceId={editStoragePriceId}
          ignoredPaths={editIgnoredPaths}
          host={editHost}
          shareName={editShareName}
          options={editOptions}
          scanType={editScanType}
          indexFolderOnlyPaths={editIndexFolderOnlyPaths}
          credential={editCredential}
          mountType={editMountType}
          handleChange={handleChange}
          setIsSaveButtonEnabled={setIsSaveButtonEnabled}
        />
      </ConfirmModal>
    </>
  );
}

export default UpdateVolumeSettingFormModal;
