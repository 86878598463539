import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableResult from "../../components/TableResult";
import { Badge, Button, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  fetchDataVolumeSettings,
  deleteVolumeSetting,
  updateVolumeSetting,
} from "../../ducks/dataVolumeSettings";
import ScanButton from "./ScanButton";
import TableActions from "../../components/TableActions";
import UpdateVolumeSettingFormModal from "./UpdateVolumeSettingFormModal";
import PerformanceModeWarning from "./PerformanceModeWarning";
import {
  deleteNetworkVolume,
  getAllNetworkVolumesPaginated,
  updateNetworkVolume,
} from "../../api/networkVolume";
import { scanOnPremiseStorages } from "../../api/scan";
import { getVolumeSettings } from "../../api/volume";
import { setAlert } from "../../actions/alert";
import { fetchVolumeSettings } from "../../ducks/volumeSettings";
import Loading from "../../components/Loading";
import DeleteVolumeSettingFormModal from "./DeleteVolumeSettingFormModal";
import { formatName } from "../../utils/formatString";

VolumeSettingList.propTypes = {
  volumes: PropTypes.array,
  serverList: PropTypes.array,
  storagePriceList: PropTypes.array,
  page: PropTypes.number,
  total: PropTypes.number,
  isFetching: PropTypes.boolean,
};

function VolumeSettingList(props) {
  const {
    volumes,
    serverList,
    storagePriceList,
    total,
    size,
    page,
    sort,
    direction,
    openChosenWizardFromSection,
    isFetching,
  } = props;

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showUpdateVolumeSettingForm, setShowUpdateVolumeSettingForm] =
    useState(false);
  const [showDeleteVolumeSettingForm, setShowDeleteVolumeSettingForm] =
    useState(false);
  const [networkVolumes, setNetworkVolumes] = useState([]);
  const [allOnPremises, setAllOnPremises] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  const getUpdatedVolumeSettings = (
    updatedPage = page,
    updatedSort = sort,
    updatedDirection = direction,
    updatedSize = size
  ) => {
    dispatch(
      fetchDataVolumeSettings(
        updatedPage,
        updatedSort,
        updatedDirection,
        updatedSize
      )
    );
  };

  useEffect(() => {
    getAllNetworkVolumesPaginated(dispatch, auth)
      .then((res) => setNetworkVolumes(res.elements))
      .catch((err) => console.error(err));

    getUpdatedVolumeSettings(page, sort, direction, size);
    getVolumeSettings(dispatch, auth, 1, sort, direction, 500, [
      "crawl.files",
      "NetworkVolume",
    ])
      .then((res) => setAllOnPremises(res.elements))
      .catch((err) => console.error(err));
  }, [volumes.length]);

  const changeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === sort) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    getUpdatedVolumeSettings(page, newSort, newDirection, size);
  };

  const getIgnoredPathsFromVolumeIgnoreRules = (volumeIgnoreRules) => {
    return volumeIgnoreRules.map((rule) => rule.path);
  };

  const saveUpdatedVolumeSetting = (
    id,
    name,
    path,
    serverId,
    storagePriceId,
    ignorePaths,
    credential,
    mountType,
    shareName,
    host,
    options,
    scanType,
    indexFolderOnlyPaths
  ) => {
    setIsProcessing(true);
    if (mountType === "Local") {
      dispatch(
        updateVolumeSetting(
          id,
          name,
          path,
          serverId,
          storagePriceId,
          ignorePaths,
          scanType,
          indexFolderOnlyPaths
        )
      )
        .then(() => {
          setShowUpdateVolumeSettingForm(false);
          setIsProcessing(false);
        })
        .catch((err) => {
          setIsProcessing(false);
          const message =
            err.message || `Unable to update volume for id [${id}]`;
          dispatch(setAlert(message, "danger"));
        });
    } else {
      updateNetworkVolume(dispatch, auth, id, {
        host: host,
        shareName: shareName,
        options: options,
        dataintellName: name,
        path: path,
        networkVolumeCredential: credential === "" ? null : { id: credential },
        serverId: serverId,
        storagePrice: { id: storagePriceId },
        volumeIgnoreRules: ignorePaths,
        type: mountType,
        scanType: scanType,
        volumeIndexFolderOnlyPaths: indexFolderOnlyPaths
      })
        .then((res) => {
          setIsProcessing(false);
          setShowUpdateVolumeSettingForm(false);
          getUpdatedVolumeSettings();
          dispatch(fetchVolumeSettings());
        })
        .catch((err) => {
          setIsProcessing(false);
          const message =
            err.message || `Unable to update volume for id [${id}]`;
          dispatch(setAlert(message, "danger"));
        });
    }
  };

  const deleteVolume = async (deleteId, mountType) => {
    setIsProcessing(true);
    if (mountType === "Local") {
      await dispatch(deleteVolumeSetting(deleteId));
      setShowDeleteVolumeSettingForm(false);
      setIsProcessing(false);
    } else {
      deleteNetworkVolume(dispatch, auth, deleteId)
        .then((res) => {
          getUpdatedVolumeSettings();
          dispatch(fetchVolumeSettings());
          setShowDeleteVolumeSettingForm(false);
          setIsProcessing(false);
        })
        .catch((err) => {
          setShowDeleteVolumeSettingForm(false);
          setIsProcessing(false);
          const message =
            err.message || `Unable to delete volume for id [${deleteId}]`;
          dispatch(setAlert(message, "danger"));
        });
    }
  };

  const ignoredPathBadges = (ignoredPaths) => {
    return (
      <div style={{ maxWidth: "300px" }}>
        {ignoredPaths.map((path) => {
          return (
            <Badge color="primary" style={{ marginRight: 5 }} key={path}>
              {path}
            </Badge>
          );
        })}
      </div>
    );
  };

  const numberOfPages = Math.ceil(total / size);

  const getVolumeMountType = (volume) => {
    if (volume.type === "NetworkVolume") {
      const networkVolume = networkVolumes.find(
        (networkVolume) => networkVolume.volumeId === volume.id
      );

      if (networkVolume !== undefined) {
        return networkVolume.type;
      }
    }

    return "Local";
  };

  const listFormated = volumes.map((volume) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      volume.id,
      volume.name,
      volume.path,
      formatName(volume.scanType),
      getVolumeMountType(volume),
      volume.server ? volume.server.name : "N/A",
      volume.storagePrice ? volume.storagePrice.description : "N/A",
      ignoredPathBadges(
        getIgnoredPathsFromVolumeIgnoreRules(volume.volumeIgnoreRules)
      ),
      ignoredPathBadges(volume.volumeIndexFolderOnlyPaths.map(indexFolderOnlyPath => indexFolderOnlyPath.path)),
      <>
        <Button
          onClick={() => {
            setEditId(volume.id);
            setShowUpdateVolumeSettingForm(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setDeleteId(volume.id);
            setShowDeleteVolumeSettingForm(true);
          }}
          color="danger"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <ScanButton
          title="Scan"
          volumeIds={[volume.id]}
          volumes={allOnPremises}
          scan={(volumeIds, cloneIndex) =>
            scanOnPremiseStorages(dispatch, auth, volumeIds, cloneIndex)
          }
          hasCloneIndex
        >
          <PerformanceModeWarning />
          <p>
            Scanning an On-Premises Storage can take between a couple minutes
            and several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      <>
        <Button
          onClick={() => {
            openChosenWizardFromSection("volume");
          }}
          color="success"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <ScanButton
          title="Scan All"
          volumes={allOnPremises}
          volumeIds={allOnPremises.map((onPremise) => onPremise.id)}
          scan={(volumeIds, cloneIndex) =>
            scanOnPremiseStorages(dispatch, auth, volumeIds, cloneIndex)
          }
          hasCloneIndex
        >
          <PerformanceModeWarning />
          <p>
            Scanning all On-Premises Storages can take between a couple minutes
            and several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ],
    style: {},
    options: {},
  });

  const fileTitles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Path", sort: "path" },
    { name: "Scan Type", sort: "scanType" },
    { name: "Mount Type" },
    { name: "Server", sort: "server.name" },
    { name: "Storage Type", sort: "storagePrice.description" },
    { name: "Ignored Paths" },
    { name: "Index Folder Only Paths" },
    { name: "Action" },
  ];

  return (
    <>
      <h5>On-Premises</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={page}
            numberOfPages={numberOfPages}
            numberOfElements={size}
            setNumberOfElements={(numberOfElements) =>
              getUpdatedVolumeSettings(1, sort, direction, numberOfElements)
            }
            selectPage={(newPage) => {
              getUpdatedVolumeSettings(newPage, sort, direction, size);
            }}
          />
          <TableResult
            title={"Files"}
            data={listFormated}
            titles={fileTitles}
            sort={sort}
            direction={direction}
            changeOrder={changeOrder}
          />
        </div>
      )}

      <UpdateVolumeSettingFormModal
        showUpdateVolumeSettingForm={showUpdateVolumeSettingForm}
        save={saveUpdatedVolumeSetting}
        closeModal={() => {
          setShowUpdateVolumeSettingForm(false);
          setEditId("");
        }}
        id={editId}
        networkVolumes={networkVolumes}
        volumes={volumes}
        getIgnoredPathsFromVolumeIgnoreRules={
          getIgnoredPathsFromVolumeIgnoreRules
        }
        serverList={serverList}
        storagePriceList={storagePriceList}
        isProcessing={isProcessing}
      />

      <DeleteVolumeSettingFormModal
        showDeleteVolumeSettingForm={showDeleteVolumeSettingForm}
        deleteVolume={deleteVolume}
        closeModal={() => setShowDeleteVolumeSettingForm(false)}
        id={deleteId}
        isProcessing={isProcessing}
        networkVolumes={networkVolumes}
        volumes={volumes}
      />
    </>
  );
}

export default VolumeSettingList;
