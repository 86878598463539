import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import { Spinner } from "reactstrap";

function DeleteVolumeSettingFormModal(props) {
  const {
    showDeleteVolumeSettingForm,
    networkVolumes,
    volumes,
    deleteVolume,
    closeModal,
    isProcessing,
    id,
  } = props;

  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [deleteMountType, setDeleteMountType] = useState("Local");

  useEffect(() => {
    if (id) {
      setDeleteVolumeSetting(id);
    }
  }, [id]);

  const handleCloseModal = () => {
    closeModal();
    setDeleteId(null);
    setDeleteName("");
  };

  const setDeleteVolumeSetting = (id) => {
    const networkVolume = networkVolumes.find((nv) => nv.volumeId === id);

    if (networkVolume !== undefined) {
      setDeleteId(networkVolume.id);
      setDeleteName(networkVolume.dataintellName);
      setDeleteMountType(networkVolume.type);
    } else {
      const volumeToDelete = volumes.find((volume) => volume.id === id);

      setDeleteId(volumeToDelete.id);
      setDeleteName(volumeToDelete.name);
      setDeleteMountType("Local");
    }
  };

  return (
    <>
      <ConfirmModal
        showConfirmation={showDeleteVolumeSettingForm}
        title="Delete Confirmation"
        disableRightButton={isProcessing}
        isProcessing={isProcessing}
        closeConfirmation={() => handleCloseModal()}
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteVolume(deleteId, deleteMountType);
        }}
      >
        <p>Are you sure you want to delete On-Premises Storage {deleteName}?</p>
        <p>
          This process will delete the configuration and the indexed data for
          the last index. It could take several minutes.
        </p>
      </ConfirmModal>
    </>
  );
}

export default DeleteVolumeSettingFormModal;
