import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import ChartCard from "./ChartCard";

export default function OwnerChart(props) {
  const {
    filters,
    search,
    addFilters,
    lastAnalyticsSearch,
    setRef = () => {},
    setSubtitle = () => {},
    setSizeItems = () => {},
    setSizeShownData = () => {},
    sizeOfFiles,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [owners, setOwners] = useState([]);

  const dispatch = useDispatch();

  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);

  const fetchData = async () => {
    setIsFetching(true);
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName: "owner",
      size: 50,
      indexingDate: activeDate,
    };

    const owners = await fetchWithAuthV2(dispatch, url, accessToken, {
      ...queryParams,
      ...searchParams,
    });
    const sortedBySizeOwners = owners.sort((a, b) => b.size - a.size);
    setOwners(sortedBySizeOwners);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [lastAnalyticsSearch]);

  const addExtensionFilter = (index) => {
    if (null !== index) {
      const fieldValue = owners[index].field;

      if ("" === fieldValue) return;

      const name = ["owner"];
      const value = [fieldValue];
      addFilters(name, value);
    }
  };

  return (
    <ChartCard
      title="Owners"
      elements={owners}
      emptyStringValue="Empty Owner"
      addFilterFunc={addExtensionFilter}
      isFetching={isFetching}
      setRef={setRef}
      setSubtitle={setSubtitle}
      setSizeItems={setSizeItems}
      setSizeShownData={setSizeShownData}
      sizeOfFiles={sizeOfFiles}
      componentId="ownerChartId"
      isCircleChartShownByDefault={false}
    />
  );
}
