import React, { useState, useEffect } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Collapse,
} from "reactstrap";
import { setAlert } from "../../actions/alert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import FolderTree from "../../components/folderTree/FolderTree";

function ScanButton(props) {
  const {
    closeParent = () => { },
    isAutoOpen = false,
    title,
    scan,
    hasCloneIndex,
    hasDeltaScan,
    children,
    volumeIds = [],
    volumes = [],
  } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [cloneIndex, setCloneIndex] = useState(true);
  const [isDeltaScan, setIsDeltaScan] = useState(true);
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);
  const [selectedVolumes, setSelectedVolumes] = useState([]);
  const [disableRightButton, setDisableRightButton] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setDisableRightButton(volumes.length === 0 || volumeIds.length === 0);
    selectVolumes(volumeIds);
  }, [volumeIds]);

  useEffect(() => {
    if (isAutoOpen) {
      setShowConfirmation(true);
    }
    setCloneIndex(true);
    setIsDeltaScan(true);
  }, [showConfirmation]);

  useEffect(() => {
    if (hasDeltaScan && isDeltaScan === true) {
      setCloneIndex(true);
    }
  }, [isDeltaScan]);

  const updateSelectedVolumes = (volume) => {
    const updatedSelectedItems = [...selectedVolumes];

    const itemIndex = updatedSelectedItems.findIndex(
      (item) => item.volumeSetting.id === volume.volumeId
    );

    if (itemIndex !== -1) {
      updatedSelectedItems.splice(itemIndex, 1);
    } else {
      updatedSelectedItems.push({
        volumeSetting: { id: volume.volumeId },
        path: "",
      });
    }

    setSelectedVolumes(updatedSelectedItems);
    setDisableRightButton(updatedSelectedItems.length === 0);
  };

  const selectVolumes = (volumeIds) => {
    setSelectedVolumes(
      volumeIds.map((id) => {
        return {
          volumeSetting: { id: id },
          path: "",
        };
      })
    );
  };

  return (
    <>
      {!isAutoOpen && (
        <Button
          onClick={() => {
            selectVolumes(volumeIds);
            setShowConfirmation(true);
          }}
          color="primary"
        >
          {title}
        </Button>
      )}

      <ConfirmModal
        disableRightButton={disableRightButton}
        showConfirmation={showConfirmation}
        title={title}
        closeConfirmation={() => {
          setShowConfirmation(false);
          closeParent();
        }}
        confirmAction={() => {
          const volumeIds = selectedVolumes.map(
            (volume) => volume.volumeSetting.id
          );
          hasDeltaScan
            ? scan(volumeIds, cloneIndex, isDeltaScan)
            : scan(volumeIds, cloneIndex);
          setShowConfirmation(false);
          closeParent();
          dispatch(
            setAlert(
              <>
                Scanner for the job(s) is now running. You can monitor the
                job(s) by clicking{" "}
                <a
                  className="text-primary"
                  onClick={() => navigate("/settings/jobs")}
                >
                  here
                </a>
              </>,
              "success",
              8000
            )
          );
        }}
        buttonColor="primary"
        buttonText="Confirm"
      >
        {children}
        <hr />
        <div
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <FolderTree
            selectedFolders={selectedVolumes}
            volumes={volumes}
            volumeSelector={true}
            hasFiles={false}
            hasRowBackground={false}
            isDefaultOpen={false}
            hasActions={false}
            showOnlyFirstColumn={true}
            hasHeader={false}
            onCheckboxClick={(e) => {
              updateSelectedVolumes(e);
            }}
            onNameClickFolder={() => { }}
            isOpenable={false}
            maxHeight="206px"
          />
        </div>

        <hr />
        <FontAwesomeIcon icon={faClock} className="me-2" />
        <a className="text-primary" onClick={() => navigate("/settings/schedules")}>
          <span>Schedule scan.</span>
        </a>
        <hr />
        <span
          className="d-flex"
          onClick={() => setIsAdvancedOptionsOpen((prev) => !prev)}
          style={{ cursor: "pointer" }}
        >
          Advanced options
          <FontAwesomeIcon
            className="ms-auto"
            icon={isAdvancedOptionsOpen ? faChevronDown : faChevronRight}
          />
        </span>
        <Collapse className="mt-3" isOpen={isAdvancedOptionsOpen}>
          {hasCloneIndex && (
            <>
              <Form inline>
                <FormGroup switch>
                  <Input
                    type="switch"
                    disabled={hasDeltaScan && isDeltaScan}
                    id="customSwitchIndex"
                    name="index"
                    checked={cloneIndex}
                    onChange={() => setCloneIndex(!cloneIndex)}
                  />
                  <Label for="index" className="me-sm-2">
                    Clone Index
                  </Label>
                </FormGroup>
              </Form>
              <p>
                Whether or not to clone the index of the last scanned day.
                Ignored if there is already one for the day.
              </p>
            </>
          )}
          {hasDeltaScan && (
            <>
              <hr />
              <Form inline>
                <FormGroup switch>
                  <Input
                    type="switch"
                    id="customSwitchDeltaScan"
                    name="deltaScan"
                    checked={isDeltaScan}
                    onChange={() => setIsDeltaScan(!isDeltaScan)}
                  />
                  <Label for="deltaScan" className="me-sm-2">
                    Delta Scan
                  </Label>
                </FormGroup>
              </Form>
              <p>
                This is a partial scan instead of a full scan. It is a faster
                approach that only update the files that have changed since the
                previous scan.
              </p>
            </>
          )}
        </Collapse>
      </ConfirmModal>
    </>
  );
}

export default ScanButton;
