import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, Row } from "reactstrap";
import { ROW_COLOR } from "./FolderTree";

export default function LoadMoreNode(props) {
  const {
    numberOfChildren,
    sizeToFetch,
    currentPage,
    valueToMarge,
    isOdd,
    loadMoreFunction,
    isFetching,
  } = props;

  const [isRowHovered, setIsRowHovered] = useState(false);

  const getRowColor = () => {
    if (isRowHovered) {
      return ROW_COLOR.onHover;
    } else if (isOdd) {
      return ROW_COLOR.isOdd;
    } else if (isOdd === undefined) {
      return "";
    } else {
      return ROW_COLOR.isEven;
    }
  };

  return (
    <>
      {numberOfChildren >= sizeToFetch * currentPage && (
        <Row
          onMouseEnter={() => setIsRowHovered(true)}
          onMouseLeave={() => setIsRowHovered(false)}
          style={{ backgroundColor: getRowColor() }}
        >
          <Badge
            style={{ marginLeft: `${valueToMarge}rem`, cursor: "pointer" }}
            className="my-1"
            onClick={() => loadMoreFunction(currentPage + 1, sizeToFetch)}
          >
            <FontAwesomeIcon
              icon={faArrowsRotate}
              className={`mx-1 ${isFetching ? "fa-spin" : ""}`}
            />{" "}
            <span className="me-1">Load more...</span>
          </Badge>
        </Row>
      )}
    </>
  );
}
