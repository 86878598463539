import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Button,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { generatePassword } from "../../utils/generateRandomValue";
import { hasBundle } from "../../validation/HasBundle";
import { ARCHIWARE_BUNDLE, SODA_BUNDLE, STORAGE_MANAGER_BUNDLE } from "../../constants/bundles";
import { validateEmail } from "../../utils/validateEmail";

const LABEL_FOR_ROLE = require("../../constants/labelForRole.json");

// Role types for filtering
const SYSTEM = "SYSTEM";
const GROUP = "GROUP";

UserForm.propTypes = {
  userFormObj: PropTypes.object,
  setUserFormObj: PropTypes.func,
  errors: PropTypes.object,
  userGroups: PropTypes.array,
  groupList: PropTypes.array,
  isCreateForm: PropTypes.bool,
  handleAddGroup: PropTypes.func,
  handleRemoveGroup: PropTypes.func,
};

function UserForm(props) {
  const {
    userFormObj,
    setUserFormObj,
    errors,
    setIsFormValid,
    userGroups,
    groupList,
    isCreateForm,
    handleAddGroup,
    handleRemoveGroup,
    isBlurredOnce,
    setIsBlurredOnce = () => { },
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [errorFields, setErrorFields] = useState({
    name: errors["name"] ?? "",
    username: errors["username"] ?? "",
    email: errors["email"] ?? "",
    password: errors["password"] ?? "",
  });
  const [availableGroups, setAvailableGroups] = useState(
    groupList.filter((group) => GROUP === group.type)
  );
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    setSelectedGroups(userGroups.filter((group) => GROUP === group.type));
    setAvailableGroups((currGroups) =>
      currGroups.filter(
        (group) => !userGroups.some((userGroup) => userGroup.id === group.id)
      )
    );
  }, [userGroups]);

  useEffect(() => {
    if (setIsFormValid) {
      if (
        Object.values(isBlurredOnce).includes(false) ||
        !Object.values(errorFields).every((field) => "" === field)
      ) {
        setIsFormValid(false);
      } else {
        setIsFormValid(true);
      }
    }
  }, [isBlurredOnce, errorFields, setIsFormValid]);

  const handleFormInput = (e) => {
    e.persist();
    setUserFormObj((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const fieldValidation = (fieldName, fieldValue) => {
    setIsBlurredOnce((prev) => ({ ...prev, [fieldName]: true }));
    switch (fieldName) {
      case "name":
        if (fieldValue.length > 255 || fieldValue.length < 1) {
          setErrorFields((prev) => ({
            ...prev,
            name: "Name size must be between 1 and 255 characters",
          }));
        } else {
          setErrorFields((prev) => ({ ...prev, name: "" }));
        }
        break;
      case "username":
        if (fieldValue.length > 255 || fieldValue.length < 3) {
          setErrorFields((prev) => ({
            ...prev,
            username: "Username size must be between 3 and 255 characters",
          }));
        } else {
          setErrorFields((prev) => ({ ...prev, username: "" }));
        }
        break;
      case "email":
        if (fieldValue.length > 255 || fieldValue.length < 1) {
          setErrorFields((prev) => ({
            ...prev,
            email: "Email must be between 1 and 255 characters",
          }));
        } else if (!validateEmail(fieldValue)) {
          setErrorFields((prev) => ({
            ...prev,
            email: "Email must be in a valid email format",
          }));
        } else {
          setErrorFields((prev) => ({ ...prev, email: "" }));
        }
        break;
      case "password":
        if (fieldValue.length > 255 || fieldValue.length < 6) {
          setErrorFields((prev) => ({
            ...prev,
            password: "Password size must be between 6 and 255 characters",
          }));
        } else {
          setErrorFields((prev) => ({ ...prev, password: "" }));
        }
        break;
      default:
        setErrorFields((prev) => ({ ...prev, [fieldName]: "" }));
        break;
    }
  };

  const handleRolesShown = () => {
    let prefilteredRoles = [...groupList];

    if (!hasBundle(ARCHIWARE_BUNDLE)) {
      prefilteredRoles = [
        ...prefilteredRoles.filter((role) => !role.name.includes("ARCHIWARE")),
      ];
    }

    if (!hasBundle(SODA_BUNDLE)) {
      prefilteredRoles = [
        ...prefilteredRoles.filter((role) => !role.name.includes("SODA")),
      ];
    }

    if (!hasBundle(STORAGE_MANAGER_BUNDLE)) {
      prefilteredRoles = [
        ...prefilteredRoles.filter((role) => !role.name.includes("STORAGE")),
      ];
    }

    return prefilteredRoles
      .filter((role) => SYSTEM === role.type)
      .map((role) => {
        return (
          <FormGroup key={role.name} check>
            <Label check>
              <Input
                type="checkbox"
                checked={userGroups.some((r) => role.name === r.name)}
                onChange={(e) =>
                  e.target.checked
                    ? handleAddGroup(role)
                    : handleRemoveGroup(role)
                }
                disabled={
                  role.name !== "ROLE_ADMIN" &&
                  userGroups.some((roles) => roles.name === "ROLE_ADMIN")
                }
              />
              {LABEL_FOR_ROLE[role.name] ?? role.name}
              <FontAwesomeIcon
                id={role.name}
                className="ms-sm-2"
                icon={faQuestionCircle}
              />
              <UncontrolledTooltip placement="right" target={role.name}>
                {role.description}
              </UncontrolledTooltip>
            </Label>
          </FormGroup>
        );
      });
  };

  return (
    <Form>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input
          onChange={handleFormInput}
          value={userFormObj.name}
          type="text"
          name="name"
          id="name"
          placeholder="John Doe"
          onBlur={() => fieldValidation("name", userFormObj.name)}
          invalid={errorFields["name"] !== ""}
        />
        <FormFeedback>{errorFields["name"]}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="username">Username</Label>
        <Input
          type="text"
          name="username"
          value={userFormObj.username}
          id="username"
          onChange={handleFormInput}
          onBlur={() => fieldValidation("username", userFormObj.username)}
          invalid={errorFields["username"] !== ""}
        />
        <FormFeedback>{errorFields["username"]}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          value={userFormObj.email}
          id="email"
          onChange={handleFormInput}
          placeholder="name@email.com"
          onBlur={() => fieldValidation("email", userFormObj.email)}
          invalid={errorFields["email"] !== ""}
        />
        <FormFeedback>{errorFields["email"]}</FormFeedback>
      </FormGroup>
      <FormGroup tag="fieldset">
        <Label style={{ marginBottom: 0 }}>Roles</Label>
        <p style={{ color: "gray" }}>
          By default, a user has read-only permissions
        </p>
        {handleRolesShown()}
      </FormGroup>
      {!isCreateForm && (
        <FormGroup>
          <Label for="group">Groups</Label>
          <Input
            type="select"
            value=""
            onChange={(e) => {
              handleAddGroup(availableGroups[e.target.value]);
              setAvailableGroups(
                availableGroups.filter((group) => group !== e.target.value)
              );
            }}
            name="group"
            id="group"
            style={{ marginBottom: 10 }}
          >
            <option value="">Add group</option>
            {availableGroups.map((group, index) => {
              return (
                <option key={index} value={index}>
                  {group.name}
                </option>
              );
            })}
          </Input>
          {selectedGroups.map((group, index) => {
            return (
              <Badge
                key={index}
                style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }}
                color="primary"
              >
                {group.name}
                <Badge
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  color="light"
                  className="text-dark"
                  onClick={() => {
                    setAvailableGroups([...availableGroups, group]);
                    handleRemoveGroup(group);
                  }}
                >
                  X
                </Badge>
              </Badge>
            );
          })}
        </FormGroup>
      )}
      {isCreateForm && (
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            onChange={handleFormInput}
            type={showPassword ? "text" : "password"}
            value={userFormObj.password}
            name="password"
            id="password"
            onBlur={() => fieldValidation("password", userFormObj.password)}
            invalid={errorFields["password"] !== ""}
          />
          <FontAwesomeIcon
            style={{
              marginTop: "-25px",
              marginRight: errorFields["password"] ? "35px" : "20px",
              cursor: "pointer",
            }}
            className="float-end"
            icon={showPassword ? faEye : faEyeSlash}
            onClick={() => setShowPassword(!showPassword)}
          />
          <FormFeedback>{errorFields["password"]}</FormFeedback>
          <Button
            className="mt-2"
            onClick={() => {
              setIsBlurredOnce((prev) => ({ ...prev, password: true }));
              setUserFormObj((prev) => ({
                ...prev,
                password: generatePassword(),
              }));
              setErrorFields((prev) => ({ ...prev, password: "" }));
            }}
          >
            Generate password?
          </Button>
        </FormGroup>
      )}
    </Form>
  );
}

export default UserForm;
