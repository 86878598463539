import React from "react";
import { Alert } from "reactstrap";

function AlertComponent({
  type,
  message,
  dismissAlert,
  visible,
}) {
  return (
    <Alert color={type} isOpen={visible} toggle={dismissAlert}>
      {message}
    </Alert>
  );
}

export default AlertComponent;
