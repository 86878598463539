import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

export default function ConfirmModal(props) {
  const {
    size = "md",
    customButton,
    confirmAction,
    showConfirmation,
    children,
    title,
    closeConfirmation,
    buttonColor,
    buttonText,
    cancelText = "Cancel",
    disableRightButton = false,
    modalBodyStyle,
    isProcessing = false,
  } = props;

  return (
    <div className="static-modal">
      <Modal size={size} toggle={closeConfirmation} isOpen={showConfirmation}>
        <ModalHeader toggle={closeConfirmation}>{title}</ModalHeader>

        <ModalBody style={modalBodyStyle}>{children}</ModalBody>

        <ModalFooter>
          <Button outline color="secondary" onClick={closeConfirmation}>
            {cancelText}
          </Button>{" "}
          {customButton ?? (
            <Button
              disabled={disableRightButton}
              color={buttonColor}
              onClick={() => confirmAction()}
            >
              {isProcessing ? <Spinner size="sm" /> : buttonText}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
