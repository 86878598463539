import React, { useState } from "react";
import { Collapse, Button } from "reactstrap";

export default function SubSection(props) {
    const {
        title,
        subTitle,
        isOpen,
        children
    } = props;
    const [isSectionOpen, setIsSectionOpen] = useState(isOpen);
    const toggleIsSectionOpen = () => setIsSectionOpen(!isSectionOpen);

    return (
        <div className="row">
            <div className="col-sm-6" onClick={toggleIsSectionOpen} style={{ cursor: "pointer" }}>
                <h3>{title}</h3>
            </div>
            <div className="col-sm-6" onClick={toggleIsSectionOpen} style={{ cursor: "pointer" }}>
                <Button className="float-end" outline color="secondary">
                    {isSectionOpen ? "Collapse" : "Expand"}
                </Button>
            </div>
            <div className="col-sm-12">
                <span>{subTitle}</span>
                <Collapse isOpen={isSectionOpen} style={{ marginTop: "1rem" }}>
                    {children}
                </Collapse>
            </div>
        </div>
    );
}
