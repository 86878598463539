import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import {
  downloadCsv,
  downloadCsvForFolder,
  getSearchRequestParams,
} from "../../ducks/search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import BrowserURLclipper from "../../components/BrowserURLclipper";

export default function ShareModal({ showModal, onCloseModal, search, files }) {
  const dispatch = useDispatch();

  const getFormattedSearchUrl = (filters) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/search?search=${search}${getSearchRequestParams(
      filters, true
    )}`;
  };

  return (
    <Modal toggle={onCloseModal} isOpen={showModal}>
      <ModalHeader toggle={onCloseModal}>
        <span>Export</span>
      </ModalHeader>

      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <span>Download file search as CSV</span>
          {files.csvFile.isFetching ? (
            <Spinner className="ms-auto" />
          ) : (
            <Button
              className="ms-auto"
              id="downloadCsvFile"
              onClick={() =>
                dispatch(
                  downloadCsv(
                    search,
                    1,
                    25,
                    files.filters,
                    files.sort,
                    files.direction,
                    files.total
                  )
                )
              }
              disabled={files.csvFile.isFetching}
            >
              <FontAwesomeIcon icon={faFileCsv} />
            </Button>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <span>Download folder search as CSV</span>
          {files.csvFolder.isFetching ? (
            <Spinner className="ms-auto" />
          ) : (
            <Button
              className="ms-auto"
              id="downloadCsvFolder"
              onClick={() =>
                dispatch(
                  downloadCsvForFolder(
                    search,
                    1,
                    25,
                    files.filters,
                    files.sort,
                    files.direction,
                    files.total
                  )
                )
              }
              disabled={files.csvFolder.isFetching}
            >
              <FontAwesomeIcon icon={faFileCsv} />
            </Button>
          )}
        </div>
        <div>
          <span>Search URL</span>
          <BrowserURLclipper url={`${getFormattedSearchUrl(files.filters)}`} />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onCloseModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
