import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TableResult from "../../components/TableResult";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../components/ConfirmModal";

import {
  fetchRoles,
  deleteRole,
  createRole,
  updateRole,
} from "../../ducks/role";
import CreateGroupForm from "./CreateGroupForm";
import TableActions from "../../components/TableActions";
import Loading from "../../components/Loading";

// Role type for filtering
const GROUP = "GROUP";

function mapStateToProps(state) {
  const { roles } = state;
  return { roles };
}

function GroupList(props) {
  const { dispatch, roles } = props;

  const { total, items, isFetching } = roles;

  const emptyGroup = { name: "", description: "", privileges: [] };
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showUpdateForm, setshowUpdateForm] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(emptyGroup);
  const [disableConfirmAction, setDisableConfirmAction] = useState(true);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsGroups") ? Number(window.localStorage.getItem("numberOfElementsGroups")) : 50,
    page: 1
  });

  useEffect(() => {
    dispatch(fetchRoles(
      pagination.page, 
      pagination.sort, 
      pagination.direction, 
      pagination.size
    ));
  }, [dispatch, pagination.page, pagination.sort, pagination.direction, pagination.size]);

  const changeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({ ...prev, sort: newSort, direction: newDirection }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsGroups", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const listFormated = items
    .filter((group) => GROUP === group.type)
    .map((group) => {
      const data = [
        group.id,
        group.name,
        group.description,
        <>
          <Button
            color="secondary"
            onClick={() => {
              setCurrentGroup(group);
              setshowUpdateForm(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>

          <Button
            color="danger"
            style={{ marginLeft: 10 }}
            onClick={() => {
              setCurrentGroup(group);
              setShowConfirmDelete(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>,
      ];

      return {
        columnData: data,
        style: {},
        options: {},
      };
    });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      <Button
        onClick={() => {
          setCurrentGroup(emptyGroup);
          setShowCreationForm(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  const titles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Description", sort: "description" },
    { name: "Action" },
  ];

  return (
    <>
      {isFetching ? <Loading /> :
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={Math.ceil(total / pagination.size)}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          />
          <TableResult
            title={"Privileges"}
            data={listFormated}
            titles={titles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={changeOrder}
          />
        </div>
      }
      <ConfirmModal
        showConfirmation={showCreationForm}
        title="Create Group"
        closeConfirmation={() => {
          setShowCreationForm(false);
          setDisableConfirmAction(true);
        }}
        buttonColor="primary"
        buttonText="Create"
        confirmAction={async () => {
          dispatch(createRole(currentGroup));
          setShowCreationForm(false);
          setDisableConfirmAction(true);
        }}
        disableRightButton={disableConfirmAction}
      >
        <CreateGroupForm
          groupObjForWizard={currentGroup}
          setGroupObjForWizard={setCurrentGroup}
          setDisableConfirmAction={setDisableConfirmAction}
          existingGroupsAndGroupsBeforeCreation={items.filter(
            (group) => GROUP === group.type
          )}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateForm}
        title="Update Group"
        closeConfirmation={() => {
          setshowUpdateForm(false);
        }}
        buttonColor="success"
        buttonText="Save"
        confirmAction={() => {
          setshowUpdateForm(false);
          dispatch(updateRole(currentGroup));
          setDisableConfirmAction(true);
        }}
      >
        <CreateGroupForm
          groupObjForWizard={currentGroup}
          setGroupObjForWizard={setCurrentGroup}
          setDisableConfirmAction={setDisableConfirmAction}
          existingGroupsAndGroupsBeforeCreation={items.filter(
            (group) => GROUP === group.type
          )}
          isUpdateForm={true}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showConfirmDelete}
        title="Delete Confirmation"
        closeConfirmation={() => setShowConfirmDelete(false)}
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          dispatch(deleteRole(currentGroup.id));
          setShowConfirmDelete(false);
        }}
      >{`Are you sure you want to delete group [${currentGroup.name}]?`}</ConfirmModal>
    </>
  );
}

export default connect(mapStateToProps)(GroupList);
