import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WizardModal from "../WizardModal";
import { setAlert } from "../../actions/alert";
import { Spinner } from "reactstrap";
import {
  archiveListOfFiles,
  archiveSearchResult,
  getStorageManagerVolumeMappings,
  restoreListOfFiles,
  restoreSearchResult,
} from "../../api/storageManager";
import SelectActionStep from "./SelectActionStep";
import SearchReview from "./SearchReview";
import ManualReview from "./ManualReview";

const emptyStorageManagerActionObj = {
  action: "",
};

export default function StorageManagerActionWizard(props) {
  const {
    showWizard,
    setShowWizard,
    selectedFiles,
    useSearchResultForActions,
    search,
    isDirectory,
  } = props;

  const [stepIndex, setStepIndex] = useState(0);
  const [storageManagerActionObj, setStorageManagerActionObj] = useState(
    emptyStorageManagerActionObj
  );
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [relocate, setRelocate] = useState(null);
  const [storageManagerVolumeMappings, setStorageManagerVolumeMappings] =
    useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector((state) => state.dates.activeDate);
  const searchFilters = useSelector((state) => state.search.filters);

  useEffect(() => {
    getStorageManagerVolumeMappings(dispatch, auth).then((res) =>
      setStorageManagerVolumeMappings(res.elements)
    );
  }, []);

  const STEP_TITLES = ["Select Action", "Review"];

  const steps = [
    <SelectActionStep
      storageManagerActionObj={storageManagerActionObj}
      setStorageManagerActionObj={setStorageManagerActionObj}
      setStepIndex={setStepIndex}
      setDisableForwardActionButton={setDisableForwardActionButton}
    />,
    useSearchResultForActions ? (
      <SearchReview
        relocate={relocate}
        setRelocate={setRelocate}
        storageManagerActionObj={storageManagerActionObj}
        setDisableForwardActionButton={setDisableForwardActionButton}
        searchText={search}
        storageManagerVolumeMappings={storageManagerVolumeMappings}
        isDirectory={isDirectory}
      />
    ) : (
      <ManualReview
        relocate={relocate}
        setRelocate={setRelocate}
        storageManagerActionObj={storageManagerActionObj}
        setDisableForwardActionButton={setDisableForwardActionButton}
        storageManagerVolumeMappings={storageManagerVolumeMappings}
        selectedFiles={selectedFiles}
      />
    ),
  ];

  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setStorageManagerActionObj(emptyStorageManagerActionObj);
    setStepIndex(0);
    setShowWizard(false);
    setIsFetching(false);
    setDisableForwardActionButton(false);
  };

  const launchAction = async () => {
    setIsFetching(true);
    setDisableForwardActionButton(true);

    if (storageManagerActionObj.action === "archive") {
      launchArchiveAction();
    } else {
      launchRestoreAction();
    }
  };

  const launchArchiveAction = () => {
    if (useSearchResultForActions) {
      archiveSearchResult(
        dispatch,
        auth,
        indexingDate,
        search,
        isDirectory,
        searchFilters
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    } else {
      archiveListOfFiles(dispatch, auth, selectedFiles)
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    }
  };

  const launchRestoreAction = () => {
    if (useSearchResultForActions) {
      restoreSearchResult(
        dispatch,
        auth,
        indexingDate,
        search,
        isDirectory,
        searchFilters,
        relocate
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    } else {
      restoreListOfFiles(dispatch, auth, selectedFiles, relocate)
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    }
  };

  const resolvedPromiseActions = (res) => {
    dispatch(
      setAlert(
        <>
          A job for the Storage Manager action is now running. You can monitor
          the job by clicking
          <a
            className="text-primary"
            onClick={() => navigate("/settings/jobs")}
          >
            {" "}
            here
          </a>
        </>,
        "success",
        9000
      )
    );
    onWizardExit();
  };

  const rejectedPromiseActions = (err) => {
    console.log(err);
    dispatch(
      setAlert(
        "Something went wrong while trying to start the Storage Manager job. Please try again.",
        "danger",
        9000
      )
    );
    onWizardExit();
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Start":
        launchAction();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (stepIndex === STEPS_LENGTH - 1) {
      return "Start";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      title="Storage Manager"
      subTitle={STEP_TITLES[stepIndex]}
      onWizardExit={onWizardExit}
      stepsLength={STEPS_LENGTH}
      stepIndex={stepIndex + 1}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={isFetching ? <Spinner /> : handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      showFooter={stepIndex > 0}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}
