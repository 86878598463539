export const AWS_REGIONS = [
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-east-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-southeast-3",
  "ap-southeast-4",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-northeast-3",
  "ap-south-1",
  "ap-south-2",
  "ca-central-1",
  "cn-north-1",
  "cn-northwest-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-central-1",
  "eu-central-2",
  "eu-north-1",
  "eu-south-1",
  "eu-south-2",
  "me-south-1",
  "me-central-1",
  "sa-east-1",
  "us-gov-east-1",
  "us-gov-west-1"
];

export const ORACLE_OBJECT_STORAGE_REGIONS = [
  "us-ashburn-1",
  "us-phoenix-1",
  "us-sanjose-1",
  "ca-toronto-1",
  "ca-montreal-1",
  "uk-london-1",
  "uk-cardiff-1",
  "eu-frankfurt-1",
  "eu-zurich-1",
  "eu-amsterdam-1",
  "eu-marseille-1",
  "eu-milan-1",
  "eu-stockholm-1",
  "me-jeddah-1",
  "me-dubai-1",
  "me-abudhabi-1",
  "il-jerusalem-1",
  "sa-saopaulo-1",
  "sa-santiago-1",
  "sa-vinhedo-1",
  "ap-tokyo-1",
  "ap-osaka-1",
  "ap-seoul-1",
  "ap-chuncheon-1",
  "ap-sydney-1",
  "ap-melbourne-1",
  "ap-mumbai-1",
  "ap-hyderabad-1",
  "ap-singapore-1",
  "af-johannesburg-1"
];

export const B2_REGIONS = [
  "us-west-000",
  "us-west-001",
  "us-west-002",
  "eu-central-003",
  "us-west-004",
  "us-east-005"
];

export const WASABI_REGIONS = [
  "us-east-1",
  "us-east-2",
  "us-central-1",
  "us-west-1",
  "ca-central-1",
  "eu-central-1",
  "eu-central-2",
  "eu-west-1",
  "eu-west-2",
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2"
];