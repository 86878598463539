import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { removeProject } from "../ducks/project";

import {
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import ProjectDetails from "./components/ProjectDetails";
import Authorization from "../validation/Authorization";
import Loading from "../components/Loading";
import {
  faFileExcel,
  faEdit,
  faCircleNotch,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../components/ConfirmModal";
import FolderGroup from "./components/FolderGroup";
import { buildSearchUrl } from "../search/searchBuilder";
import ProjectStorageCost from "./components/ProjectStorageCost";
import Tabs from "../components/Tabs";
import ProjectSizeChart from "./components/ProjectSizeChart";
import { ROLE_PROJECT_MANAGER } from "../constants/roles";
import Insights from "./components/Insights";
import PDFChartButton from "../components/PDFChartButton";
import ReportModal from "./components/ReportModal";
import { formatSize } from "../components/FormatSize";
import MultiDatasetsCircleChart from "../components/MultiDatasetsCircleChart";
import { getProjectById } from "../api/project";
import { setAlert } from "../actions/alert";
import { useParams, useNavigate } from "react-router-dom";

function Project(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [filesizeObject, setFilesizeObject] = useState({});
  const [isRedrawChart, setIsRedrawChart] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [selectedCostInterval, setSelectedCostInterval] = useState(
    window.localStorage.getItem("storageCostIntervalProject") ?? "Monthly"
  );

  const { dispatch, volumeSettings, projectHandling } = props;

  const [project, setProject] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  const chartRef = useRef();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const auth = useSelector(({ auth }) => auth);

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if (!isFetching) {
      setIsRedrawChart(true);
      setFilesizeObject(
        formatSize(project.allocatedSize, baseSize, null, "object")
      );
      setTimeout(() => {
        setIsRedrawChart(false);
      }, 100);
    }
  }, [project.allocatedSize, isFetching]);

  useEffect(() => {
    setIsFetching(true);
    getProjectById(dispatch, auth, params.id, indexingDate)
      .then((res) => {
        setProject(res);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(setAlert("Error while fetching the project", "danger"));
        setIsFetching(false);
        navigate("/projects");
      });
  }, [dispatch, params.id]);

  const deleteProject = () => {
    dispatch(removeProject(params.id));
    setTimeout(function () {
      navigate("/projects");
    }, 1000);
  };

  const filteredSearch = (path) => {
    navigate(path);
  };

  const searchFilesInProject = (projectItems) => {
    const url = buildSearchUrl(projectItems);
    filteredSearch(url);
  };

  const excelIcon = project.loadingReport ? (
    <FontAwesomeIcon icon={faCircleNotch} spin />
  ) : (
    <FontAwesomeIcon icon={faFileExcel} />
  );

  return (
    <>
      {isFetching || projectHandling.isFetching || volumeSettings.isFetching ? (
        <div style={{ marginTop: 100 }}>
          <Loading />
        </div>
      ) : (
        <>
          <div className="d-flex">
            <h1>{project.name}</h1>
            <div className="ms-auto">
              <Button
                color="info"
                id="projectSearch"
                style={{ width: 40 }}
                onClick={() =>
                  searchFilesInProject(
                    project.projectShares.flatMap((share) => share.projectItems)
                  )
                }
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <UncontrolledTooltip placement="auto" target="projectSearch">
                Show files in project
              </UncontrolledTooltip>
              <Button
                color="success"
                id="projectExcel"
                className="ms-1"
                style={{ width: 40 }}
                onClick={() => setIsReportModalOpen(true)}
              >
                {excelIcon}
              </Button>
              <UncontrolledTooltip placement="auto" target="projectExcel">
                Download Excel report
              </UncontrolledTooltip>
              <Authorization requiredAuthorizations={[ROLE_PROJECT_MANAGER]}>
                <Button
                  color="secondary"
                  id="projectEdit"
                  className="ms-1"
                  style={{ width: 40 }}
                  onClick={() => navigate("/project/edit/" + params.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <UncontrolledTooltip placement="auto" target="projectEdit">
                  Edit project
                </UncontrolledTooltip>
                <Button
                  color="danger"
                  id="projectDelete"
                  className="ms-1"
                  style={{ width: 40 }}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <UncontrolledTooltip placement="auto" target="projectDelete">
                  Delete project
                </UncontrolledTooltip>
              </Authorization>
            </div>
          </div>
          <Tabs
            toggleTab={(tab) => {
              if (activeTab !== tab) setActiveTab(tab);
            }}
            activeTab={activeTab}
            titles={["Project Details", "Timeline"]}
          >
            <div className="row">
              <div className="col-md-6  col-lg-4" style={{ paddingBottom: 10 }}>
                <ProjectDetails
                  size={project.sizeOfFiles}
                  sizePercentage={Math.round(
                    (project.sizeOfFiles / project.allocatedSize) * 100
                  )}
                  allocatedSize={project.allocatedSize}
                  numberOfFiles={project.numberOfFiles}
                  lastAccessDate={project.lastAccessDate}
                  lastModifiedDate={project.lastModifiedDate}
                  description={project.description}
                />
              </div>
              <div className="col-md-6 col-lg-4">
                <Card style={{ marginBottom: 10, height: 370 }}>
                  <CardHeader tag="h5">
                    <span>Size Chart</span>
                    <div className="float-end">
                      <PDFChartButton
                        chartRef={chartRef}
                        title="Size Chart"
                        subTitle={filesizeObject.unit}
                      />
                    </div>
                  </CardHeader>
                  <CardBody>
                    {isRedrawChart ? (
                      <Loading />
                    ) : (
                      <MultiDatasetsCircleChart
                        datasetsValues={project.projectShares}
                        allocatedSize={project.allocatedSize}
                        totalSize={project.sizeOfFiles}
                        titleOverLimit={
                          `The project is ${
                            formatSize(
                              project.sizeOfFiles,
                              baseSize,
                              filesizeObject.exponent,
                              "object"
                            ).value
                          } ${filesizeObject.unit}, which is ` +
                          `${
                            formatSize(
                              project.sizeOfFiles - project.allocatedSize,
                              baseSize,
                              filesizeObject.exponent,
                              "object"
                            ).value
                          } ${filesizeObject.unit} over the allocated space.`
                        }
                        chartRef={chartRef}
                        onClick={(e) =>
                          e !== null &&
                          project.projectShares.length > e &&
                          searchFilesInProject(
                            project.projectShares[e].projectItems
                          )
                        }
                      />
                    )}
                  </CardBody>

                  <p
                    className="px-3 "
                    style={{ fontSize: "12px", textAlign: "center" }}
                  >
                    Click a donut segment to view files. Use the legend to
                    toggle chart data.
                  </p>
                </Card>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-md-6 col-lg-12">
                    <ProjectStorageCost
                      projectShares={project.projectShares}
                      detailedProjectPaths={project.projectShares.flatMap(
                        (share) => share.projectItems
                      )}
                      volumeSettings={volumeSettings}
                      selectedCostInterval={selectedCostInterval}
                      setSelectedCostInterval={setSelectedCostInterval}
                    />
                  </div>
                  <div className="col-md-6 col-lg-12">
                    <Insights
                      projectShares={project.projectShares}
                      detailedProjectPaths={project.projectShares.flatMap(
                        (share) => share.projectItems
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ProjectSizeChart
              projectId={params.id}
              allocatedSize={project.allocatedSize}
            />
          </Tabs>
          <hr />
          {project.projectShares.map((share) => {
            const volumeSetting = volumeSettings.items.find(
              (v) => share.volumeId === v.id
            );
            const storagePrice = volumeSetting
              ? volumeSetting.storagePrice
              : null;
            const volumeType = volumeSetting
              ? volumeSetting.type
              : "crawl.files";
            return (
              <FolderGroup
                key={share.volumeId.toString() + "-" + share.volumeName}
                name={share.volumeName}
                type={volumeType}
                folderSize={share.sizeOfFiles}
                duplicateSize={
                  share.statistics.DUPLICATE &&
                  share.statistics.DUPLICATE.duplicate
                    ? share.statistics.DUPLICATE.duplicate.sizeOfFiles
                    : 0
                }
                unusedSize={
                  share.statistics.ACCESS_DATE &&
                  share.statistics.ACCESS_DATE["6 month"]
                    ? share.statistics.ACCESS_DATE["6 month"].sizeOfFiles
                    : 0
                }
                volumeId={share.volumeId.toString()}
                projectItems={share.projectItems}
                allocatedSpaceInBytes={project.allocatedSize}
                filteredSearchFunc={(path) => filteredSearch(path)}
                storagePrice={storagePrice}
                indexingDate={project.indexingDate}
                selectedCostInterval={selectedCostInterval}
              />
            );
          })}
          <ConfirmModal
            showConfirmation={showDeleteConfirmation}
            title="Delete Confirmation"
            closeConfirmation={() => setShowDeleteConfirmation(false)}
            buttonColor="danger"
            buttonText="Delete"
            confirmAction={() => {
              setShowDeleteConfirmation(false);
              deleteProject();
            }}
          >
            {`Are you sure you want to delete project: ${project.name}?`}
          </ConfirmModal>
          <ReportModal
            id={params.id}
            projectName={project.name}
            isOpen={isReportModalOpen}
            onClose={() => setIsReportModalOpen(false)}
          />
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { volumeSettings, projectHandling } = state;

  return {
    volumeSettings,
    projectHandling,
  };
}

export default connect(mapStateToProps)(Project);
