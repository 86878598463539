import { downloadFileWithAuth, fetchWithAuth } from "../actions/requestHelper";

export const getProjectById = async (dispatch, auth, id, indexingDate) => {
  const url = `/v1/projects/${id}?indexingDate=${indexingDate}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const getProjects = async (
  dispatch,
  auth,
  indexingDate,
  page = 1,
  size = 10,
  sort = "name",
  direction = "asc",
  filter = ""
) => {
  const url = `/v1/projects?indexingDate=${indexingDate}&page=${page}&size=${size}&sort=${sort}&direction=${direction}&filter=${filter}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const getMostActiveProjects = async (
  dispatch,
  auth,
  indexingDate,
  duration = 30,
  size = 10,
  type = "days"
) => {
  const url = `/v1/projects/mostActiveProjects?indexingDate=${indexingDate}&size=${size}&type=${type}&duration=${duration}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const getReportForProject = async (
  dispatch,
  auth,
  id,
  projectName,
  indexingDate,
  type = "OVERVIEW"
) => {
  const fileName = `${projectName}-${indexingDate}.xlsx`;
  const url = `/v1/projects/${id}/reports?indexingDate=${indexingDate}&type=${type}`;
  const result = await downloadFileWithAuth(
    dispatch,
    url,
    fileName,
    auth.accessToken
  );

  return result;
};

export const getProjectHistoryById = async (
  dispatch,
  auth,
  id,
  indexingDate,
  size = 20
) => {
  const url = `/v1/projects/${id}/history?indexingDate=${indexingDate}&size=${size}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const getDetailedProjectHistoryById = async (
  dispatch,
  auth,
  id,
  indexingDate,
  size = 20
) => {
  const url = `/v1/projects/${id}/history?size=${size}&indexingDate=${indexingDate}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};
