import React, { useRef, useEffect } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import CircleChart from "../../components/CircleChart";
import PDFChartButton from "../../components/PDFChartButton";
import { formatSize } from "../../components/FormatSize";
import { useSelector } from "react-redux";

const VolumeSizeCard = ({ latestDetailedVolume, setSizeChartRef }) => {
  const chartRef = useRef();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  useEffect(() => {
    setSizeChartRef(chartRef);
  }, []);

  const filesizeObject = formatSize(
    latestDetailedVolume.total,
    baseSize,
    null,
    "object"
  );

  return (
    <Card style={{ marginBottom: 10, height: 370 }}>
      <CardHeader tag="h5">
        <span>Size Chart</span>
        <div className="float-end">
          <PDFChartButton
            chartRef={chartRef}
            title="Size Chart"
            subTitle={filesizeObject.unit}
          />
        </div>
      </CardHeader>
      <CardBody>
        <CircleChart
          data={[
            formatSize(
              latestDetailedVolume.used,
              baseSize,
              filesizeObject.exponent,
              "object"
            ).value,
            formatSize(
              latestDetailedVolume.free,
              baseSize,
              filesizeObject.exponent,
              "object"
            ).value,
          ]}
          title={`Size (${filesizeObject.unit})`}
          labels={[
            `Used Space (${filesizeObject.unit})`,
            `Available Space (${filesizeObject.unit})`,
          ]}
          color={["#5F9EA0", "rgba(1,1,1,0.2)"]}
          height={220}
          maintainAspectRatio={false}
          unit={filesizeObject.unit}
        />

        <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          <CircleChart
            circleChartRef={chartRef}
            data={[
              formatSize(
                latestDetailedVolume.used,
                baseSize,
                filesizeObject.exponent,
                "object"
              ).value,
              formatSize(
                latestDetailedVolume.free,
                baseSize,
                filesizeObject.exponent,
                "object"
              ).value,
            ]}
            title={`Size (${filesizeObject.unit})`}
            labels={[
              `Used Space (${filesizeObject.unit})`,
              `Available Space (${filesizeObject.unit})`,
            ]}
            color={["#5F9EA0", "rgba(1,1,1,0.2)"]}
            width={400}
            maintainAspectRatio={true}
            unit={filesizeObject.unit}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default VolumeSizeCard;
