import { useEffect, useState } from "react";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import ScheduleForm from "./ScheduleForm";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faPlus,
  faCheckCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import PerformanceModeWarning from "./PerformanceModeWarning";

ScheduleList.propTypes = {
  schedules: PropTypes.array,
  servers: PropTypes.array,
  volumes: PropTypes.array,
  buckets: PropTypes.array,
  archiwares: PropTypes.array,
  createSchedule: PropTypes.func,
  updateSchedule: PropTypes.func,
  deleteSchedule: PropTypes.func,
};

function ScheduleList(props) {
  const {
    schedules,
    servers,
    volumes,
    buckets,
    dellPowerScaleStorages,
    archiwares,
    createSchedule,
    updateSchedule,
    deleteSchedule,
  } = props;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [cron, setCron] = useState("");
  const [active, setActive] = useState(true);
  const [job, setJob] = useState("");
  const [serverId, setServerId] = useState(0);
  const [volumesId, setVolumesId] = useState([]);
  const [bucketsId, setBucketsId] = useState([]);
  const [dellPowerScaleIds, setDellPowerScaleIds] = useState([]);
  const [archiwareIds, setArchiwareIds] = useState([]);
  const [cloneIndex, setCloneIndex] = useState(true);
  const [deltaScan, setDeltaScan] = useState(true);
  const [showCreateScheduleForm, setShowCreateScheduleForm] = useState(false);
  const [showUpdateScheduleForm, setShowUpdateScheduleForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);

  useEffect(() => {
    if (deltaScan) {
      setCloneIndex(true);
    }
  }, [deltaScan]);

  const readableJobNames = {
    "io.dataintell.visionapi.schedule.job.ScanAllJob":
      "Scan all On-Premise Storages",
    "io.dataintell.visionapi.schedule.job.ScanServerJob":
      "Scan On-Premise Storages by servers",
    "io.dataintell.visionapi.schedule.job.ScanVolumesJob":
      "Scan On-Premise Storages",
    "io.dataintell.visionapi.schedule.job.ScanBucketsJob":
      "Scan all Cloud Storages",
    "io.dataintell.visionapi.schedule.job.ScanAllBucketsJob":
      "Scan Cloud Storages",
    "io.dataintell.visionapi.schedule.job.ScanAllArchiwaresJob":
      "Scan all Archiware P5 Indexes",
    "io.dataintell.visionapi.schedule.job.ScanArchiwaresJob":
      "Scan Archiware P5 Indexes",
    "io.dataintell.visionapi.schedule.job.ScanAllDellPowerScalesJob":
      "Scan all Dell PowerScale Storages",
    "io.dataintell.visionapi.schedule.job.ScanDellPowerScalesJob":
      "Scan Dell PowerScale Storages",
  };

  const scheduleTitles = [
    { name: "Id" },
    { name: "Name" },
    { name: "Cron" },
    { name: "Job" },
    { name: "Active" },
    { name: "Action" },
  ];

  const handlePayloadStructure = () => {
    if (job === "io.dataintell.visionapi.schedule.job.ScanServerJob") {
      return {
        serverId: serverId,
        cloneIndex: cloneIndex,
      };
    } else if (job === "io.dataintell.visionapi.schedule.job.ScanVolumesJob") {
      return {
        volumes: volumesId,
        cloneIndex: cloneIndex,
      };
    } else if (job === "io.dataintell.visionapi.schedule.job.ScanBucketsJob") {
      return {
        bucketsId: bucketsId,
        cloneIndex: cloneIndex,
      };
    } else if (
      job === "io.dataintell.visionapi.schedule.job.ScanDellPowerScalesJob"
    ) {
      return {
        dellPowerScaleIds: dellPowerScaleIds,
        cloneIndex: cloneIndex,
        isDeltaScan: deltaScan,
      };
    } else if (
      job === "io.dataintell.visionapi.schedule.job.ScanAllDellPowerScalesJob"
    ) {
      return {
        cloneIndex: cloneIndex,
        isDeltaScan: deltaScan,
      };
    } else if (
      job === "io.dataintell.visionapi.schedule.job.ScanArchiwaresJob"
    ) {
      return {
        archiwareIds: archiwareIds,
        cloneIndex: cloneIndex,
        isDeltaScan: deltaScan,
      };
    } else if (
      job === "io.dataintell.visionapi.schedule.job.ScanAllArchiwaresJob"
    ) {
      return {
        cloneIndex: cloneIndex,
        isDeltaScan: deltaScan,
      };
    } else {
      return {
        cloneIndex: cloneIndex,
      };
    }
  };

  const handleChange = (name, value) => {
    if (name === "name") {
      setName(value);
    } else if (name === "job") {
      setJob(value);
    } else if (name === "cron") {
      setCron(value);
    } else if (name === "active") {
      setActive(value);
    } else if (name === "cloneIndex") {
      setCloneIndex(value);
    } else if (name === "deltaScan") {
      setDeltaScan(value);
    } else if (name === "serverId") {
      setServerId(parseInt(value));
    }
  };

  const handleCloseModal = () => {
    setShowCreateScheduleForm(false);
    setShowUpdateScheduleForm(false);
    setShowDeleteConfirmation(false);
    setId("");
    setName("");
    setCron("");
    setActive(true);
    setJob("");
    setServerId(0);
    setVolumesId([]);
    setBucketsId([]);
    setDellPowerScaleIds([]);
    setArchiwareIds([]);
    setCloneIndex(true);
    setDeltaScan(true);
  };

  const listFormated = schedules.map((schedule) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      schedule.id,
      schedule.name,
      schedule.cron,
      readableJobNames[schedule.jobClass],
      schedule.paused ? (
        <FontAwesomeIcon color="#BBBBBB" icon={faCircle} />
      ) : (
        <FontAwesomeIcon color="#2255FF" icon={faCheckCircle} />
      ),
      <>
        <Button
          onClick={() => {
            setShowUpdateScheduleForm(true);
            setId(schedule.id);
            setName(schedule.name);
            setCron(schedule.cron);
            setActive(!schedule.paused);
            setJob(schedule.jobClass);
            setServerId(schedule.payload.serverId);
            setVolumesId(schedule.payload.volumes ?? []);
            setBucketsId(schedule.payload.bucketsId ?? []);
            setDellPowerScaleIds(schedule.payload.dellPowerScaleIds ?? []);
            setArchiwareIds(schedule.payload.archiwareIds ?? []);
            setCloneIndex(schedule.payload.cloneIndex);
            setDeltaScan(schedule.payload.isDeltaScan);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(schedule.id);
            setName(schedule.name);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      <Button onClick={() => setShowCreateScheduleForm(true)} color="success">
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <TableResult
        title={"Schedules"}
        data={listFormated}
        titles={scheduleTitles}
      />
      <ConfirmModal
        disableRightButton={disableRightButton}
        showConfirmation={showCreateScheduleForm}
        title="Schedule a Job"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createSchedule({
            name: name,
            jobGroup: "SCANNER",
            jobClass: job,
            cron: cron,
            payload: handlePayloadStructure(),
            paused: !active,
          });
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        <PerformanceModeWarning />
        <ScheduleForm
          setDisableRightButton={(value) => {
            setDisableRightButton(value);
          }}
          name={name}
          cron={cron}
          active={active}
          job={job}
          serverId={serverId}
          volumesId={volumesId}
          bucketsId={bucketsId}
          dellPowerScaleIds={dellPowerScaleIds}
          archiwareIds={archiwareIds}
          cloneIndex={cloneIndex}
          deltaScan={deltaScan}
          servers={servers}
          volumes={volumes}
          buckets={buckets}
          dellPowerScaleStorages={dellPowerScaleStorages}
          archiwares={archiwares}
          handleChange={handleChange}
          isUpdateScheduleForm={false}
          setVolumeIds={(value) => {
            setVolumesId(value);
          }}
          setBucketIds={(value) => {
            setBucketsId(value);
          }}
          setDellPowerScaleIds={(value) => {
            setDellPowerScaleIds(value);
          }}
          setArchiwareIds={(value) => {
            setArchiwareIds(value);
          }}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateScheduleForm}
        disableRightButton={disableRightButton}
        title="Schedule a Job"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateSchedule(id, {
            name: name,
            jobGroup: "SCANNER",
            jobClass: job,
            cron: cron,
            payload: handlePayloadStructure(),
            paused: !active,
          });
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        <ScheduleForm
          setDisableRightButton={(value) => {
            setDisableRightButton(value);
          }}
          name={name}
          cron={cron}
          active={active}
          job={job}
          serverId={serverId}
          volumesId={volumesId}
          bucketsId={bucketsId}
          dellPowerScaleIds={dellPowerScaleIds}
          archiwareIds={archiwareIds}
          cloneIndex={cloneIndex}
          deltaScan={deltaScan}
          servers={servers}
          volumes={volumes}
          buckets={buckets}
          dellPowerScaleStorages={dellPowerScaleStorages}
          archiwares={archiwares}
          handleChange={handleChange}
          isUpdateScheduleForm={true}
          setVolumeIds={(value) => {
            setVolumesId(value);
          }}
          setBucketIds={(value) => {
            setBucketsId(value);
          }}
          setDellPowerScaleIds={(value) => {
            setDellPowerScaleIds(value);
          }}
          setArchiwareIds={(value) => {
            setArchiwareIds(value);
          }}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteSchedule(id);
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        Are you sure you want to delete schedule {name}?
      </ConfirmModal>
    </>
  );
}

export default ScheduleList;
