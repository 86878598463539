import {
  faArchive,
  faCloud,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
export function getVolumeIcon(volumeType) {
  // All s3 buckets are tagged like this from an ElasticSearch volume perspective
  const AWS_S3_BUCKET = "AWS-S3";
  // The other types of buckets
  const S3_BUCKET = "S3";
  const B2_BUCKET = "BACKBLAZE_B2";
  const WASABI_BUCKET = "WASABI";
  const ORACLE_BUCKET = "ORACLE_OBJECT_STORAGE";
  const AZURE_BUCKET = "AZURE";
  const DROPBOX_BUCKET = "DROPBOX";
  const OTHER_BUCKET = "OTHER";
  //Volume type for all archive volumes from ElasticSearch
  const ARCHIVE_VOLUME = "Archives";
  //Volume type for all Achiware P5 volumes
  const ARCHIWARE_VOLUME = "Archiware";

  switch (volumeType) {
    case AWS_S3_BUCKET:
    case S3_BUCKET:
    case B2_BUCKET:
    case WASABI_BUCKET:
    case ORACLE_BUCKET:
    case AZURE_BUCKET:
    case DROPBOX_BUCKET:
    case OTHER_BUCKET:
      return faCloud;
    case ARCHIVE_VOLUME:
    case ARCHIWARE_VOLUME:
      return faArchive;
    default:
      return faServer;
  }
}
