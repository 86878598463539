import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { DATE_FORMAT_OPTIONS } from "../../constants/dateFormatting";
import { getFormattedTime } from "../../utils/getFormattedTime";
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllNetworkVolumesPaginated } from "../../api/networkVolume";
import FormatSize from "../../components/FormatSize";

const VolumeDetailCard = ({ latestDetailedVolume }) => {
  const [networkVolume, setNetworkVolume] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getAllNetworkVolumesPaginated(dispatch, auth).then((res) =>
      setNetworkVolume(
        res.elements.find(
          (networkVolume) => networkVolume.volumeId === latestDetailedVolume.id
        )
      )
    );
  }, [latestDetailedVolume.id]);

  const formatedTime = getFormattedTime(latestDetailedVolume.crawlDuration);

  const handleVolumeType = () => {
    switch (latestDetailedVolume.type) {
      case "crawl.files":
      case "NetworkVolume":
      case "DellPowerScale":
        return "On-Premises Storage";

      case "AWS-S3":
        return "Cloud Storage";

      case "Archives":
      case "Archiware":
        return "Archiware P5 Index";

      default:
        break;
    }
  };

  const handleStorageType = () => {
    switch (latestDetailedVolume.type) {
      case "crawl.files":
        return "Local";
      case "NetworkVolume":
        return networkVolume !== null ? networkVolume.type : "N/A";
      default:
        return returnNAOnNull(latestDetailedVolume.productName);
    }
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader tag="h5">Storage Details</CardHeader>
      <CardBody>
        <div
          style={{
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "140px auto",
          }}
        >
          <div>Name:</div>
          <div>
            <strong>{latestDetailedVolume.name}</strong>
          </div>
          {!["Archives", "Archiware", "AWS-S3"].includes(
            latestDetailedVolume.type
          ) && (
            <>
              <div>Total Capacity:</div>
              <div>
                <strong>
                  <FormatSize>{latestDetailedVolume.total}</FormatSize>
                </strong>
              </div>
              <div>Available Space:</div>
              <div>
                <strong>
                  {latestDetailedVolume.free ? (
                    <FormatSize>{latestDetailedVolume.free}</FormatSize>
                  ) : (
                    "N/A"
                  )}
                </strong>
              </div>

              <div>Used Space:</div>
              <div>
                <strong>
                  <FormatSize>{latestDetailedVolume.used}</FormatSize>
                </strong>
              </div>
            </>
          )}
          <div>Total Size Of Files:</div>
          <div>
            <strong>
              <FormatSize>{latestDetailedVolume.sizeOfFiles}</FormatSize>
            </strong>
          </div>
          {latestDetailedVolume.numberOfFiles !== undefined &&
            latestDetailedVolume.numberOfFolders !== undefined && (
              <>
                <div>Number Of Files:</div>
                <div>
                  <strong>
                    {latestDetailedVolume.numberOfFiles !== null
                      ? latestDetailedVolume.numberOfFiles.toLocaleString(
                          "en-US"
                        )
                      : "N/A"}
                  </strong>
                </div>
                <div>Number Of Folders:</div>
                <div>
                  <strong>
                    {latestDetailedVolume.numberOfFolders !== null
                      ? latestDetailedVolume.numberOfFolders.toLocaleString(
                          "en-US"
                        )
                      : "N/A"}
                  </strong>
                </div>
              </>
            )}

          <div>Last Indexing Date:</div>
          <div>
            <strong>
              {new Date(latestDetailedVolume.indexingDate).toLocaleDateString(
                "en-CA",
                DATE_FORMAT_OPTIONS
              )}
            </strong>
          </div>
          <div>Last Scan Duration:</div>
          <div>
            <strong>{formatedTime}</strong>
          </div>
          <div>Type:</div>
          <div>
            <strong>{handleVolumeType()}</strong>
          </div>
          <div>Storage Type:</div>
          <div>
            <strong>{handleStorageType()}</strong>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default VolumeDetailCard;
