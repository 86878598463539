import React from "react";
import RectangleChart from "../../components/RectangleChart";
import moment from "moment";
import PropTypes from "prop-types";
import FormatSize from "../../components/FormatSize";

BucketAndArchiwareFolder.propTypes = {
  item: PropTypes.object,
  handleDetailClick: PropTypes.func,
  indexingDate: PropTypes.string
};

function BucketAndArchiwareFolder(props) {
  const { item, handleDetailClick, indexingDate } = props;
  
  const duplicateSize = item.statistics.DUPLICATE && item.statistics.DUPLICATE.duplicate ? item.statistics.DUPLICATE.duplicate.sizeOfFiles : 0;
  const duplicateNumberOfFiles = item.statistics.DUPLICATE && item.statistics.DUPLICATE.duplicate ? item.statistics.DUPLICATE.duplicate.numberOfFiles : 0;
  const lastModifiedOneYearSize = item.statistics.MODIFIED_DATE && item.statistics.MODIFIED_DATE["1 year"] ? item.statistics.MODIFIED_DATE["1 year"].sizeOfFiles : 0;
  const lastModifiedSixMonthsSize = item.statistics.MODIFIED_DATE && item.statistics.MODIFIED_DATE["6 month"] ? item.statistics.MODIFIED_DATE["6 month"].sizeOfFiles : 0;
  const lastModifiedThreeMonthsSize = item.statistics.MODIFIED_DATE && item.statistics.MODIFIED_DATE["3 month"] ? item.statistics.MODIFIED_DATE["3 month"].sizeOfFiles : 0;
  const lastModifiedOneMonthSize = item.statistics.MODIFIED_DATE && item.statistics.MODIFIED_DATE["1 month"] ? item.statistics.MODIFIED_DATE["1 month"].sizeOfFiles : 0;
  const lastModifiedOneWeekSize = item.statistics.MODIFIED_DATE && item.statistics.MODIFIED_DATE["1 week"] ? item.statistics.MODIFIED_DATE["1 week"].sizeOfFiles : 0;

  // Setting up values for duplicate data RectangleChart
  const labelsDuplicate = [
    <>Duplicate data: <FormatSize>{duplicateSize}</FormatSize></>,
    <>Unique data: <FormatSize>{item.sizeOfFiles - duplicateSize}</FormatSize></>
  ];
  const dataDuplicate = [
    duplicateSize,
    item.sizeOfFiles - duplicateSize
  ];
  const colorDuplicate = ["rgba(255,243,37,1)", "#00B23C"];
  const linksDuplicate = [
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&onlyDuplicates=true`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path}`
  ];

  // Setting up values for last modified data RectangleChart
  const lastModified6Months = lastModifiedSixMonthsSize - lastModifiedOneYearSize;
  const lastModified3months = lastModifiedThreeMonthsSize - lastModifiedSixMonthsSize;
  const lastModified1month = lastModifiedOneMonthSize - lastModifiedThreeMonthsSize;
  const lastModified1week = lastModifiedOneWeekSize - lastModifiedOneMonthSize;
  const other = item.sizeOfFiles - lastModifiedOneWeekSize;

  const labelsLastModified = [
    <>Last modified {">"} 1 year: <FormatSize>{lastModifiedOneYearSize}</FormatSize></>,
    <>Last modified between 6 months and 1 year: <FormatSize>{lastModified6Months}</FormatSize></>,
    <>Last modified between 3 months and 6 months: <FormatSize>{lastModified3months}</FormatSize></>,
    <>Last modified between 1 month and 3 months: <FormatSize>{lastModified1month}</FormatSize></>,
    <>Last modified between 1 week and 1 month: <FormatSize>{lastModified1week}</FormatSize></>,
    <>Last modified {"<"} 1 week: <FormatSize>{other}</FormatSize></>
  ];
  const dataLastModified = [
    lastModifiedOneYearSize,
    lastModified6Months,
    lastModified3months,
    lastModified1month,
    lastModified1week,
    other
  ];
  const colorLastModified = [
    "#C91D1E",
    "#D37039",
    "#D3963E",
    "#FAE556",
    "#7FB227",
    "#00B23C"
  ];
  const linksLastModified = [
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedBefore=${moment(indexingDate)
      .subtract(1, "years")
      .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedBefore=${moment(indexingDate)
      .subtract(6, "months")
      .format("YYYY-MM-DD")}&lastModifiedAfter=${moment(indexingDate)
        .subtract(1, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedBefore=${moment(indexingDate)
      .subtract(3, "months")
      .format("YYYY-MM-DD")}&lastModifiedAfter=${moment(indexingDate)
        .subtract(6, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedBefore=${moment(indexingDate)
      .subtract(1, "months")
      .format("YYYY-MM-DD")}&lastModifiedAfter=${moment(indexingDate)
        .subtract(3, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedBefore=${moment(indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}&lastModifiedAfter=${moment(indexingDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path
    }&lastModifiedAfter=${moment(indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}`
  ];

  return (
    <>
      <h5 className="mb-0">Duplicate data</h5>
      <div className="mb-2">
        <RectangleChart
          links={linksDuplicate}
          handleClick={handleDetailClick}
          labels={labelsDuplicate}
          data={dataDuplicate}
          colors={colorDuplicate}
        />
      </div>

      <h5 className="mb-0">Last modified data</h5>
      <div className="mb-2">
        <RectangleChart
          links={linksLastModified}
          handleClick={handleDetailClick}
          labels={labelsLastModified}
          data={dataLastModified}
          colors={colorLastModified}
        />
      </div>

      <div className="row">
        <div className="col-sm-6">
          <h6>
            Size: <strong><FormatSize>{item.sizeOfFiles}</FormatSize></strong>
          </h6>
          <h6>
            Last modified date:{" "}
            <strong>{moment(item.lastModifiedDate).format("lll")}</strong>
          </h6>
          <h6>
            Number of files: <strong>{item.numberOfFiles}</strong>
          </h6>
          <h6>
            Duplicate data:{" "}
            <strong><FormatSize>{duplicateSize}</FormatSize></strong>
          </h6>
          <h6>
            Number of duplicate files:{" "}
            <strong>{duplicateNumberOfFiles}</strong>
          </h6>
        </div>
        <div className="col-sm-6">
          <h6>
            Last modified date {">"} 1 Year:{" "}
            <strong>
              <FormatSize>{lastModifiedOneYearSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last modified date {">"} 6 Months:{" "}
            <strong>
              <FormatSize>{lastModifiedSixMonthsSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last modified date {">"} 3 Months:{" "}
            <strong>
              <FormatSize>{lastModifiedThreeMonthsSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last modified date {">"} 1 Month:{" "}
            <strong>
              <FormatSize>{lastModifiedOneMonthSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last modified date {">"} 1 Week:{" "}
            <strong>
              <FormatSize>{lastModifiedOneWeekSize}</FormatSize>
            </strong>
          </h6>
        </div>
      </div>
    </>
  );
}

export default BucketAndArchiwareFolder;
