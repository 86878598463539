import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_SCHEDULE = "REQUEST_CREATE_SCHEDULE";
const RECEIVE_CREATE_SCHEDULE = "RECEIVE_CREATE_SCHEDULE";
// READ
const REQUEST_SCHEDULE_LIST = "REQUEST_SCHEDULE_LIST";
const RECEIVE_SCHEDULE_LIST = "RECEIVE_SCHEDULE_LIST";
// UPDATE
const REQUEST_UPDATE_SCHEDULE = "REQUEST_UPDATE_SCHEDULE";
const RECEIVE_UPDATE_SCHEDULE = "RECEIVE_UPDATE_SCHEDULE";
// DELETE
const REQUEST_DELETE_SCHEDULE = "REQUEST_DELETE_SCHEDULE";
const RECEIVE_DELETE_SCHEDULE = "RECEIVE_DELETE_SCHEDULE";
// ERRORS
const RECEIVE_INVALID_SCHEDULE = "RECEIVE_INVALID_SCHEDULE";

// === Reducers ===
export default function schedules(
  state = {
    items: [],
    isFetching: false,
    errors: {},
    formValid: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_SCHEDULE_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_SCHEDULE_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.schedules,
        formValid: true,
        errors: {},
      });
    case RECEIVE_INVALID_SCHEDULE:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestScheduleList() {
  return {
    type: REQUEST_SCHEDULE_LIST
  };
}

function receiveScheduleList(schedules) {
  return {
    type: RECEIVE_SCHEDULE_LIST,
    schedules
  };
}

function requestCreateSchedule() {
  return {
    type: REQUEST_CREATE_SCHEDULE
  };
}

function receiveCreateSchedule(createdSchedule) {
  return {
    type: RECEIVE_CREATE_SCHEDULE,
    schedule: createdSchedule
  };
}

function requestUpdateSchedule() {
  return {
    type: REQUEST_UPDATE_SCHEDULE
  };
}

function receiveUpdateSchedule(updatedSchedule) {
  return {
    type: RECEIVE_UPDATE_SCHEDULE,
    schedule: updatedSchedule
  };
}

function requestDeleteSchedule(id) {
  return {
    type: REQUEST_DELETE_SCHEDULE,
    id
  };
}

function receiveDeleteSchedule(id) {
  return {
    type: RECEIVE_DELETE_SCHEDULE,
    id
  };
}

function receiveInvalidSchedule(errors) {
  return {
    type: RECEIVE_INVALID_SCHEDULE,
    errors
  };
}

// === Side Effects ===

// === SCANNER ===
export function fetchScheduleList(jobGroup = "SCANNER") {
  return async (dispatch, getState) => {
    dispatch(requestScheduleList());
    const url = `/v1/schedules?jobGroup=${jobGroup}`;
    try {
      const json = await fetchWithAuth(dispatch, url, getState().auth.accessToken);
      dispatch(receiveScheduleList(json));
    } catch (err) {
      const message = err.statusText || `Unable to get the list of schedules`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createSchedule(schedule) {
  return async (dispatch, getState) => {
    dispatch(requestCreateSchedule());
    try {
      const newSchedule = await fetchWithAuth(
        dispatch,
        "/v1/schedules",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(schedule)
      );
      dispatch(receiveCreateSchedule(newSchedule));
      dispatch(fetchScheduleList());
    } catch (err) {
      const message = err.message || "Unable to create schedule";
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function updateSchedule(id, schedule) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateSchedule());
    try {
      const updatedSchedule = await fetchWithAuth(
        dispatch,
        `/v1/schedules/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(schedule)
      );
      dispatch(receiveUpdateSchedule(updatedSchedule));
      dispatch(fetchScheduleList());
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}

export function deleteSchedule(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteSchedule(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/schedules/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(fetchScheduleList());
      dispatch(receiveDeleteSchedule(id));
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}

// === USER ALERT ===
export function fetchScheduleListByUser(jobGroup = "SCANNER", userId = "") {
  return async (dispatch, getState) => {
    dispatch(requestScheduleList());
    const url = `/v1/schedules?jobGroup=${jobGroup}&userId=${userId}`;
    try {
      const json = await fetchWithAuth(dispatch, url, getState().auth.accessToken);
      dispatch(receiveScheduleList(json));
    } catch (err) {
      const message = err.statusText || `Unable to get the list of schedules`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createUserSchedule(schedule, userId) {
  return async (dispatch, getState) => {
    dispatch(requestCreateSchedule());
    try {
      const newSchedule = await fetchWithAuth(
        dispatch,
        "/v1/schedules",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(schedule)
      );
      dispatch(receiveCreateSchedule(newSchedule));
      dispatch(fetchScheduleListByUser("ALERT", userId));
    } catch (err) {
      if (err.subErrors) {
        dispatch(receiveInvalidSchedule(err));
      } else {
        const message = err.message || "Unable to create schedule";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateUserSchedule(id, schedule, userId) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateSchedule());
    try {
      const updatedSchedule = await fetchWithAuth(
        dispatch,
        `/v1/schedules/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(schedule)
      );
      dispatch(receiveUpdateSchedule(updatedSchedule));
      dispatch(fetchScheduleListByUser("ALERT", userId));
    } catch (err) {
      if (err.subErrors) {
        dispatch(receiveInvalidSchedule(err));
      } else {
        const message = err.message || "Unable to update schedule";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function deleteScheduleForUser(id, userId) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteSchedule(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/schedules/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(receiveDeleteSchedule(id));
      dispatch(fetchScheduleListByUser("ALERT", userId));
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}