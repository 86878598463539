// 1000ms * 60s * 60m * 24h
export const DAY_IN_MILLISECONDS = 86400000;
export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_FORMAT_OPTIONS = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};
export const CALENDAR_ICON_STYLE = {
  position: "absolute",
  marginLeft: "0.75rem",
  pointerEvents: "none",
  color: "#495057"
};