import { useEffect, useState } from "react";
import DellPowerScaleCredentialForm from "./DellPowerScaleCredentialForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteDellPowerScaleCredentials, getAllDellPowerScaleCredentialsPaginated } from "../../../api/dellPowerScale";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import TableActions from "../../../components/TableActions";
import TableResult from "../../../components/TableResult";
import ConfirmModal from "../../../components/ConfirmModal";
import { setAlert } from "../../../actions/alert";

export default function DellPowerScaleCredentialList() {
  const [showCreateCredentialsForm, setShowCreateCredentialsForm] = useState(false);
  const [showUpdateCredentialsForm, setShowUpdateCredentialsForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [dellPowerScaleCredentials, setDellPowerScaleCredentials] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleCredentials") ? Number(window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleCredentials")) : 10,
    page: 1
  });
  const [id, setId] = useState(null);
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    fetchDellPowerScaleCredentials(
      pagination.page,
      pagination.size,
      pagination.sort,
      pagination.direction
    );
  }, [pagination.page, pagination.size, pagination.sort, pagination.direction]);

  const fetchDellPowerScaleCredentials = async (
    page = pagination.page, 
    size = pagination.size, 
    sort = pagination.sort, 
    direction = pagination.direction
  ) => {
    setIsFetching(true);
    try {
      const res = await getAllDellPowerScaleCredentialsPaginated(dispatch, auth, page, size, sort, direction);
      setTotal(res.total);
      setDellPowerScaleCredentials(res.elements);
      setIsFetching(false);
    } catch (err) {
      console.error(err);
      dispatch(setAlert("Unable to fetch Dell PowerScale credentials", "danger", 8000));
      setIsFetching(false);
    }
  };

  const numberOfPages = Math.ceil(total / pagination.size);

  const titles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Action" }
  ];

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({ ...prev, sort: newSort, direction: newDirection }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsDataSourceDellPowerScaleCredentials", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const listFormated = dellPowerScaleCredentials.map((credential) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };

    lineObject.columnData = [
      credential.id,
      credential.name,
      <>
        <Button
          onClick={() => {
            setId(credential.id);
            setShowUpdateCredentialsForm(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(credential.id);
            setName(credential.name);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      <>
        <Button
          onClick={() => {
            setShowCreateCredentialsForm(true);
          }}
          color="success"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </>,
    ],
    style: {},
    options: {},
  });

  const handleCloseModal = () => {
    setShowCreateCredentialsForm(false);
    setShowUpdateCredentialsForm(false);
    setShowDeleteConfirmation(false);
    setId(null);
    setName("");
  };

  const handleDelete = () => {
    deleteDellPowerScaleCredentials(dispatch, auth, id)
      .then(() => {
        fetchDellPowerScaleCredentials();
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        dispatch(setAlert("Unable to delete Dell PowerScale credentials", "danger", 8000));
      });
  };

  return (
    <>
      <h5>Credentials</h5>
      {isFetching ?
        <Loading /> :
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          />
          <TableResult
            data={listFormated}
            titles={titles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      }
      <DellPowerScaleCredentialForm
        isOpen={showCreateCredentialsForm}
        handleConfirmationFunction={fetchDellPowerScaleCredentials}
        handleClose={handleCloseModal}
      />
      <DellPowerScaleCredentialForm
        isOpen={showUpdateCredentialsForm}
        handleConfirmationFunction={fetchDellPowerScaleCredentials}
        handleClose={handleCloseModal}
        id={id}
      />
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => handleDelete()}
        closeConfirmation={() => handleCloseModal()}
      >
        Are you sure you want to delete the Dell PowerScale credentials {name}?
      </ConfirmModal>
    </>
  );
}