import moment from "moment";

export const getDeltaInfo = (sizes, labels, unit) => {
  const hasData = sizes.length > 0 || labels.length > 0;
  if (!hasData) {
    return "";
  }
  const dataDifference = sizes[sizes.length - 1] - sizes[0];
  const formattedDifference = parseFloat(dataDifference.toFixed(2));
  const startDate = moment(labels[0]).format("YYYY-MM-DD");
  const endDate = moment(labels[labels.length - 1]).format("YYYY-MM-DD");
  return `${
    hasData && dataDifference > 0 ? "+" : ""
  }${formattedDifference} ${unit} Between ${startDate} and ${endDate}`;
};
