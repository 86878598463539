import moment from "moment";
import React from "react";
import { returnNAOnNull } from "../utils/returnNAOnNull";
import { getMonthlyCostByVolume } from "../utils/pricePerVolume";
import { useSelector } from "react-redux";
import FileInfoStorageManager from "./FileInfoStorageManager";
import FormatSize from "./FormatSize";
import DateLabelWithTooltip from "./DateLabelWithTooltip";

export function formatLabel(value) {
  const result = value.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1) + ":";
}

export default function FileFolderDetailsTable({ dataObj }) {
  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings);

  const formatDate = (value) => moment(value).format("lll");

  const renderMetadataLabelsAndValues = (name, index) => {
    const metadata = dataObj.metadata;
    const value = metadata[name];

    if (value) {
      return (
        <React.Fragment key={name + index}>
          <div>{formatLabel(name)}</div>
          <div>
            <strong>
              {name === "backupDate" ? moment(value).format("lll") : value}
            </strong>
          </div>
        </React.Fragment>
      );
    }
    return null;
  };

  const renderMetadata = () => {
    const metadataElements = Object.keys(dataObj.metadata).map((type, index) =>
      renderMetadataLabelsAndValues(type, index)
    );
    return metadataElements;
  };
  return (
    <div
      style={{
        display: "grid",
        columnGap: "20px",
        gridTemplateColumns: "210px auto",
      }}
    >
      <div>Name:</div>
      <div>
        <strong>{dataObj.name}</strong>
      </div>
      <div>Path:</div>
      <div>
        <strong>{dataObj.path}</strong>
      </div>
      <div>Volume Name:</div>
      <div>
        <strong>{dataObj.volumeName}</strong>
      </div>
      <div>Size:</div>
      <div>
        <strong>
          <FormatSize>{dataObj.size}</FormatSize>
        </strong>
      </div>
      <div>Owner:</div>
      <div>
        <strong>{dataObj.owner}</strong>
      </div>
      <div>Group:</div>
      <div>
        <strong>{dataObj.group}</strong>
      </div>
      <div>Creation Date:</div>
      <div>
        <strong>{returnNAOnNull(dataObj.creationDate, formatDate)}</strong>
      </div>
      <div>
        {dataObj.isDirectory ? (
          <DateLabelWithTooltip dateLabel={"lastAccess"} />
        ) : (
          "Last Access Date"
        )}
        :
      </div>
      <div>
        <strong>{returnNAOnNull(dataObj.lastAccess, formatDate)}</strong>
      </div>
      <div>
        {dataObj.isDirectory ? (
          <DateLabelWithTooltip dateLabel={"lastModified"} />
        ) : (
          "Last Modified Date"
        )}
        :
      </div>
      <div>
        <strong>{moment(dataObj.lastModified).format("lll")}</strong>
      </div>
      <div>Indexing Date:</div>
      <div>
        <strong>{moment(dataObj.indexingDate).format("lll")}</strong>
      </div>
      <div>Monthly Cost:</div>
      <div>
        <strong>
          {getMonthlyCostByVolume(
            volumeSettings.items,
            dataObj.volumeId,
            dataObj.size
          )}
        </strong>
      </div>
      {dataObj && dataObj.metadata && renderMetadata()}
      {!dataObj.isDirectory && <FileInfoStorageManager file={dataObj} />}
    </div>
  );
}
