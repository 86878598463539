import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle } from "reactstrap";
import Loading from "../../components/Loading";

import CrawlVolumeDetails from "../components/CrawlVolumeDetails";

import { fetchAllVolumes } from "../../actions";

class CrawlDuration extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchAllVolumes());
  }

  render() {
    const { volumesByName, isFetching } = this.props;

    const cardContent = () => {
      if (isFetching === true) {
        return <Loading />;
      } else {
        return Object.keys(volumesByName).map((volumeName, index) => {
          return (
            <span key={`${index}-${volumeName}`}>
              {index !== 0 && <hr />}

              <CrawlVolumeDetails
                volumeName={volumeName}
                elements={volumesByName[volumeName]}
              />
            </span>
          );
        });
      }
    };

    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Scans</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">Information about daily scan time</CardSubtitle>
        </CardHeader>
        <CardBody style={{ overflowY: "auto", height: "500px", textAlign: "left" }}>
          {cardContent()}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard } = state;

  const volumesByName = dashboard.volumesByName;
  const isFetching = dashboard.isFetching;

  return {
    volumesByName,
    isFetching
  };
}

export default connect(mapStateToProps)(CrawlDuration);
