import React, { useState, useEffect } from "react";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import ArchiwareCredentialForm from "./ArchiwareCredentialForm";
import TableActions from "../../components/TableActions";
import Loading from "../../components/Loading";

ArchiwareCredentialList.propTypes = {
  credentials: PropTypes.array,
  total: PropTypes.number,
  createCredential: PropTypes.func,
  updateCredential: PropTypes.func,
  deleteCredential: PropTypes.func,
  fetchArchiwareCredentialsListPage: PropTypes.func,
  errors: PropTypes.object,
  formValid: PropTypes.bool,
  isFetching: PropTypes.bool,
};

function ArchiwareCredentialList(props) {
  const {
    credentials,
    total,
    page,
    size,
    sort,
    direction,
    createCredential,
    updateCredential,
    deleteCredential,
    fetchArchiwareCredentialsListPage,
    errors,
    formValid,
    isFetching,
  } = props;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [url, setUrl] = useState("");
  const [showCreateCredentialForm, setShowCreateCredentialForm] =
    useState(false);
  const [showUpdateCredentialForm, setShowUpdateCredentialForm] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);

  const numberOfPages = Math.ceil(total / size);
  const credentialTitles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Action" },
  ];

  useEffect(() => {
    if (formValid && !isFetching) {
      handleCloseModal();
    }
  }, [formValid, isFetching]);

  const handleCloseModal = () => {
    setShowCreateCredentialForm(false);
    setShowUpdateCredentialForm(false);
    setShowDeleteConfirmation(false);
    setId("");
    setName("");
    setUsername("");
    setPassword("");
    setUrl("");
  };

  const handleChange = (name, value) => {
    if (name === "name") {
      setName(value);
    } else if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "url") {
      setUrl(value);
    }
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === sort) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }

    fetchArchiwareCredentialsListPage(page, newSort, newDirection, size);
  };

  const listFormated = credentials.map((credential) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      credential.id,
      credential.name,
      <>
        <Button
          onClick={() => {
            setShowUpdateCredentialForm(true);
            setId(credential.id);
            setName(credential.name);
            setUsername(credential.username);
            setPassword("");
            setUrl(credential.url);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(credential.id);
            setName(credential.name);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateCredentialForm(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h5>Credentials</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={page}
            numberOfPages={numberOfPages}
            numberOfElements={size}
            setNumberOfElements={(numberOfElements) =>
              fetchArchiwareCredentialsListPage(
                1,
                sort,
                direction,
                numberOfElements
              )
            }
            selectPage={(newPage) =>
              fetchArchiwareCredentialsListPage(newPage, sort, direction, size)
            }
          />
          <TableResult
            title={"Credentials"}
            data={listFormated}
            titles={credentialTitles}
            sort={sort}
            direction={direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      )}
      <ConfirmModal
        showConfirmation={showCreateCredentialForm}
        title="Add Archiware P5 Credentials"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createCredential({
            name: name,
            username: username,
            password: password,
            url: url,
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <ArchiwareCredentialForm
          name={name}
          username={username}
          password={password}
          url={url}
          handleChange={handleChange}
          errors={errors}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateCredentialForm}
        title="Update Archiware P5 Credentials"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateCredential(id, {
            name: name,
            username: username,
            password: password,
            url: url,
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <ArchiwareCredentialForm
          name={name}
          username={username}
          password={password}
          url={url}
          handleChange={handleChange}
          errors={errors}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteCredential(id);
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        Are you sure you want to delete credential {name}?
      </ConfirmModal>
    </>
  );
}

export default ArchiwareCredentialList;
