import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { setIsNameCondensed, setIsPathCondensed } from "../ducks/userSettings";
import { useState } from "react";

export default function CondenseButton(props) {
  const { condenseType, marginRight, marginLeft } = props;

  const [isHovered, setIsHovered] = useState(false);

  const isNameCondensed = useSelector(({ userSettings }) => userSettings.isNameCondensed);
  const isPathCondensed = useSelector(({ userSettings }) => userSettings.isPathCondensed);
  const dispatch = useDispatch();

  const getIsCondensed = (condenseType) => {
    if (condenseType === "NAME") {
      return isNameCondensed === "true";
    } else if (condenseType === "PATH") {
      return isPathCondensed === "true";
    } else {
      return false;
    }
  };

  const setIsCondensed = (condenseType, value) => {
    const updatedValue = value ? "true" : "false";

    if (condenseType === "NAME") {
      dispatch(setIsNameCondensed(updatedValue));
    } else if (condenseType === "PATH") {
      dispatch(setIsPathCondensed(updatedValue));
    }
  };

  return (
    <>
      <span
        id={`condense${condenseType}`}
        onClick={e => {
          e.stopPropagation();
          setIsCondensed(condenseType, !getIsCondensed(condenseType));
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          border: "1px solid #6c757d",
          borderRadius: "5px",
          textAlign: "center",
          color: "#6c757d",
          width: "18px",
          display: "inline-block",
          marginRight: marginRight,
          marginLeft: marginLeft,
          fontSize: "10px",
          order: 2
        }}
      >
        {isHovered ?
          <FontAwesomeIcon icon={getIsCondensed(condenseType) ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter} /> :
          <FontAwesomeIcon icon={getIsCondensed(condenseType) ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} />
        }
      </span>
      <UncontrolledTooltip target={`condense${condenseType}`} placement="auto">
        Change to {getIsCondensed(condenseType) ? "full" : "condensed"} view
      </UncontrolledTooltip>
    </>
  );
}