import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import FolderList from "./FolderList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import TagFilePanel from "./tags/TagFilePanel";
import FileFolderDetailsTable from "./FileFolderDetailsTable";
import RelatedFoldersForOnPrem from "./RelatedFoldersForOnPrem";
import RelatedFoldersForArchive from "./RelatedFoldersForArchive";
import RelatedFoldersForCloud from "./RelatedFoldersForCloud";
import Loading from "./Loading";
import { getFoldersByHash } from "../api/directory";
import { fetchFolderDetails } from "../ducks/folderDetails";

export default function FolderDetails(props) {
  const {
    folder,
    updateFolder,
    updateTags,
    volumeType,
    isInModal = true,
  } = props;

  const [folderDetails, setFolderDetails] = useState({
    elements: [],
    page: 1,
    total: 0,
    size: 10,
    sort: "name",
    direction: "asc",
  });
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings);

  useEffect(() => {
    const folderSortValue = window.localStorage.getItem("folderSort");
    const folderDirectionValue = window.localStorage.getItem("folderDirection");

    fetchFoldersByHash(
      folderDetails.page,
      folderDetails.size,
      folderSortValue ?? folderDetails.sort,
      folderDirectionValue ?? folderDetails.direction
    );
  }, [folder]);

  const fetchFoldersByHash = (page, size, sort, direction) => {
    setIsFetching(true);
    getFoldersByHash(
      dispatch,
      auth,
      indexingDate,
      folder.identificationHash,
      page,
      size,
      sort,
      direction
    )
      .then((res) => {
        setFolderDetails(res);
        setTimeout(() => setIsFetching(false), 100);
      })
      .catch((err) => {
        console.error("Exception while getting folder details");
        setTimeout(() => setIsFetching(false), 100);
      });
  };

  const changeFoldersOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === folderDetails.sort) {
      newDirection = folderDetails.direction === "asc" ? "desc" : "asc";
    }

    window.localStorage.setItem("folderSort", newSort);
    window.localStorage.setItem("folderDirection", newDirection);

    fetchFolderDetails(
      folderDetails.page,
      folderDetails.size,
      newSort,
      newDirection
    );
  };

  const getRelatedFoldersSection = () => {
    switch (volumeType) {
      case "crawl.files":
      case "NetworkVolume":
      case "DellPowerScale":
        return <RelatedFoldersForOnPrem folder={folder} />;
      case "Archives":
      case "Archiware":
        return <RelatedFoldersForArchive folder={folder} />;
      case "AWS-S3":
        return <RelatedFoldersForCloud folder={folder} />;
      default:
        break;
    }
  };

  const getContent = () => {
    if (isInModal) {
      return (
        <>
          <Row>
            <Col lg={8} md={12} className="mb-3 mb-lg-0">
              <FileFolderDetailsTable dataObj={folder} />
            </Col>
            <Col lg={4} md={12}>
              <TagFilePanel
                file={folder}
                updateFile={(tags) => {
                  updateFile(folder.fileId, { ...folder, customTag: tags });
                }}
                updateTags={(tags) => updateTags(tags)}
              />
            </Col>
          </Row>
          <hr className="mx-3 mt-4" />
          <h5>Related Folders</h5>
          {getRelatedFoldersSection()}
        </>
      );
    }

    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faFolder} className="me-2" />
          <span className="ms-1">{folder.name}</span>
        </h3>
        <Row>
          <Col sm={12} className="mb-3">
            <Card>
              <CardHeader tag="h5">Details</CardHeader>
              <CardBody>
                <Row>
                  <Col lg={7} className="mb-3 mb-lg-0">
                    <FileFolderDetailsTable dataObj={folder} />
                  </Col>
                  <Col lg={5}>
                    <TagFilePanel
                      file={folder}
                      updateFile={(tags) => {
                        updateFolder(folder.fileId, {
                          ...folder,
                          customTag: tags,
                        });
                      }}
                      updateTags={(tags) => updateTags(tags)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardHeader tag="h5">Related Folders</CardHeader>
              <CardBody>{getRelatedFoldersSection()}</CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return <>{isFetching ? <Loading /> : getContent()}</>;
}
