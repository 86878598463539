import React from "react";
import { formatName } from "../../../utils/formatString";

function VolumeReviewPage({ volumeObj }) {
  return (
    <ul style={{ listStyle: "none", lineHeight: "2", paddingLeft: "0" }}>
      <li>
        Name: &nbsp; <strong>{volumeObj.name}</strong>
      </li>
      <li>
        Path: &nbsp; <strong>{volumeObj.path}</strong>
      </li>
      <li>
        Scan Type: &nbsp; <strong>{formatName(volumeObj.scanType)}</strong>
      </li>
      <li>
        Mount Type: &nbsp; <strong>{volumeObj.mountType}</strong>
      </li>

      {volumeObj.mountType !== "Local" && (
        <>
          <li>
            Host: &nbsp; <strong>{volumeObj.host}</strong>
          </li>
          <li>
            Share Name: &nbsp; <strong>{volumeObj.shareName}</strong>
          </li>
          <li>
            Options: &nbsp; <strong>{volumeObj.options}</strong>
          </li>
        </>
      )}

      <li>
        Server: &nbsp; <strong>{volumeObj.serverName}</strong>
      </li>
      <li>
        Storage Type: &nbsp; <strong>{volumeObj.storageName}</strong>
      </li>
    </ul>
  );
}

export default VolumeReviewPage;
