import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

function DropdownButton({ children, dropdownTitle, buttonColor }) {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color={buttonColor}>
        {dropdownTitle}&nbsp;
      </DropdownToggle>
      <DropdownMenu>
        {children}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default DropdownButton;
