import { useState, useEffect, useRef } from "react";
import PageResultBadge from "./PageResultBadge";
import PagesNavigation from "./PagesNavigation";
import { Badge } from "reactstrap";
import { formatSize } from "./FormatSize";
import { useSelector } from "react-redux";

export default function TableActions(props) {
  const {
    page,
    numberOfPages,
    selectPage,
    isFile,
    showBadge,
    total,
    searchSize,
    numberOfElements,
    setNumberOfElements,
    children,
    selectedFiles = [],
    isDirectory = false,
    useSearchResultForActions = false,
    isInModal = false,
    showPagination = true,
    marginXTableAction = "0px",
    marginRightSelectionBadge = "12px"
  } = props;

  const [tableActionYPosition, setTableActionYposition] = useState(null);
  const tableActionHTMLRef = useRef();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const isPluralForFileFolder = selectedFiles.length > 1;

  const handleStickyTableAction = () => {
    return {
      position: "sticky",
      backgroundColor: tableActionYPosition && tableActionYPosition <= 65 ? isInModal ? "#fbfbfb" : "#ececec" : "",
      zIndex: tableActionYPosition && tableActionYPosition <= 65 ? 10 : 1,
      top: "65px",
      marginLeft: marginXTableAction,
      marginRight: marginXTableAction
    }
  }

  // Default behaviour, use scroll event
  useEffect(() => {
    if (!isInModal) {
      const handleScroll = e => {
        setTableActionYposition(tableActionHTMLRef.current && tableActionHTMLRef.current.getBoundingClientRect().y);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  // In a modal, use the useEffect on the position as the scroll event is overridden by the modal
  useEffect(() => {
    if (isInModal) {
      setTableActionYposition(tableActionHTMLRef.current && tableActionHTMLRef.current.getBoundingClientRect().y);
    }
  }, [tableActionHTMLRef.current?.getBoundingClientRect().y]);

  return (
    <div ref={tableActionHTMLRef} style={handleStickyTableAction()}>
      <div className="d-flex text-align-center py-2">
        {children}
        <div className="ms-auto">
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "nowrap", justifyContent: "end" }}>
            <Badge style={{ "marginRight": marginRightSelectionBadge }} color="primary" pill>
              {
                useSearchResultForActions &&
                <span>All {isDirectory ? "folders" : "files"} selected</span>
              }
              {
                selectedFiles.length > 0 &&
                <span>{selectedFiles.length} {isDirectory ? `folder${isPluralForFileFolder ? "s" : ""}` : `file${isPluralForFileFolder ? "s" : ""}`} selected - {formatSize(selectedFiles.reduce((a, b) => a + b.size, 0), baseSize)}</span>
              }
            </Badge>
            {showPagination &&
              <>
                <PageResultBadge
                  className="me-3"
                  isFile={isFile}
                  showBadge={showBadge}
                  total={total}
                  searchSize={searchSize}
                />
                <PagesNavigation
                  page={page}
                  numberOfPages={numberOfPages}
                  selectPage={selectPage}
                  numberOfElements={numberOfElements}
                  setNumberOfElements={setNumberOfElements}
                />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}