import { useState, useEffect } from "react";
import { getFormattedTime } from "../../utils/getFormattedTime";

export default function JobsDuration({
    startTime,
    updateTime,
    status
}) {
    const [jobElapsedTime, setJobElapsedTime] = useState(getFormattedTime(Date.now() - startTime));

    useEffect(() => {
        if (status === "RUNNING" || status === "NEW") {

            const interval = setInterval(async () => {
                const now = Date.now();
                setJobElapsedTime(getFormattedTime(now - startTime));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, []);

    if (status === "RUNNING") {
        return jobElapsedTime;
    } else if (startTime === 0) {
        return "N/A";
    } else {
        return getFormattedTime(updateTime - startTime)
    }
}