import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithAuth } from "../../../actions/requestHelper";
import { fetchStoragePriceList } from "../../../ducks/storagePrice";
import { receiveInvalidServerList } from "../../../ducks/storagePrice";
import { setAlert } from "../../../actions/alert";
import { fetchBucketList } from "../../../ducks/bucket";
import { fetchVolumeSettings } from "../../../ducks/volumeSettings";
import { fetchBucketCredentialList } from "../../../ducks/bucketCredential";
import { getAllCredentials } from "../../../api/bucket";
import BucketStep1 from "./BucketStep1";
import BucketStep2 from "./BucketStep2";
import BucketReviewPage from "./BucketReviewPage";
import StoragePriceConfiguration from "../../components/StoragePriceConfiguration";
import WizardModal from "../../../components/WizardModal";

function BucketWizard({
  showWizard,
  setShowWizard,
  isProcessing,
  setIsProcessing,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const toKeepStoragePricesUpdated = useSelector(
    ({ storagePrices }) => storagePrices.items
  );

  const toKeepBucketCredentialsUpdated = useSelector(
    ({ bucketCredentials }) => bucketCredentials.total
  );

  const buckets = useSelector(({ buckets }) => buckets);

  const [bucketCredentials, setBucketCredentials] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);

  // storage states
  const [showCreateStorageType, setShowCreateStorageType] = useState(false);
  const [storagePrices, setStoragePrices] = useState(
    toKeepStoragePricesUpdated
  );
  const [selectedStorageType, setSelectedStorageType] = useState(
    toKeepStoragePricesUpdated.length ? toKeepStoragePricesUpdated[0] : ""
  );

  // Bucket states
  const emptyBucketObj = {
    name: "",
    namespace: "",
    description: "",
    region: "",
    type: "S3",
    credentialsId: "",
    storageName: "",
    storageType: null,
    path: "",
  };
  const [bucketObj, setBucketObj] = useState(emptyBucketObj);
  const [bucketNameList, setBucketNameList] = useState([]);
  const emptyBucketStep1InputsCopy = {
    namespace: "",
    region: "",
    type: "S3",
    credentialsId: "",
  };
  const bucketStep1InputsCopy = useRef(emptyBucketStep1InputsCopy);
  const emptyBucketNamesError = {
    hasError: false,
    errorMessage: "",
  };
  const [bucketNamesError, setBucketNamesError] = useState(
    emptyBucketNamesError
  );

  // credentials states
  const emptyBucketCredentialsObj = {
    type: "S3",
    name: "",
    accessKey: "",
    privateKey: "",
    url: "",
  };
  const [bucketCredentialsObj, setbucketCredentialsObj] = useState(
    emptyBucketCredentialsObj
  );
  const [showCredentialsForm, setshowCredentialsForm] = useState(false);
  const [bucketCredentialsToCreate, setbucketCredentialsToCreate] = useState(
    []
  );

  // storage states
  const emptyStorageTypeObj = {
    description: "",
    currencySymbol: "",
    price: "",
  };
  const [storageTypeObj, setStorageTypeObj] = useState(emptyStorageTypeObj);
  const [storageToCreate, setStorageToCreate] = useState([]);

  useEffect(() => {
    if (toKeepStoragePricesUpdated.length) {
      setStoragePrices(toKeepStoragePricesUpdated);
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }
  }, [toKeepStoragePricesUpdated]);
  useEffect(() => {
    getAllCredentials(dispatch, auth, 1, 500)
      .then((res) => setBucketCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the bucket credentials",
            "danger"
          )
        )
      );
  }, [toKeepBucketCredentialsUpdated]);

  const STEP_TITLES = [
    "Configure Cloud Credentials",
    "Configure Bucket",
    "Select Storage Price",
    "Review",
  ];

  const steps = [
    <BucketStep1
      bucketObj={bucketObj}
      setBucketObj={setBucketObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
      showCredentialsForm={showCredentialsForm}
      setshowCredentialsForm={setshowCredentialsForm}
      bucketCredentialsObj={bucketCredentialsObj}
      setbucketCredentialsObj={setbucketCredentialsObj}
      bucketCredentials={bucketCredentials}
      setBucketCredentials={setBucketCredentials}
      bucketCredentialsToCreate={bucketCredentialsToCreate}
      setbucketCredentialsToCreate={setbucketCredentialsToCreate}
    />,
    <BucketStep2
      bucketObj={bucketObj}
      setBucketObj={setBucketObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
      bucketStep1InputsCopy={bucketStep1InputsCopy}
      bucketNameList={bucketNameList}
      setBucketNameList={setBucketNameList}
      emptyBucketStep1InputsCopy={emptyBucketStep1InputsCopy}
      bucketNamesError={bucketNamesError}
      setBucketNamesError={setBucketNamesError}
    />,
    <StoragePriceConfiguration
      setDataObject={setBucketObj}
      showCreateStorageType={showCreateStorageType}
      setShowCreateStorageType={setShowCreateStorageType}
      storageTypeObj={storageTypeObj}
      setStorageTypeObj={setStorageTypeObj}
      storagePrices={storagePrices}
      setDisableForwardActionButton={setDisableForwardActionButton}
      selectedStorageType={selectedStorageType}
      setSelectedStorageType={setSelectedStorageType}
      storageToCreate={storageToCreate}
      setStorageToCreate={setStorageToCreate}
      setStoragePrices={setStoragePrices}
    />,
    <BucketReviewPage bucketObj={bucketObj} />,
  ];
  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setBucketObj(emptyBucketObj);
    if (toKeepStoragePricesUpdated.length !== 0) {
      setSelectedStorageType(toKeepStoragePricesUpdated[0]);
    }
    setStoragePrices((prev) =>
      prev.filter((storage) => storage.hasOwnProperty("id"))
    );
    setStorageToCreate([]);
    setShowCreateStorageType(false);
    bucketStep1InputsCopy.current = emptyBucketStep1InputsCopy;
    setbucketCredentialsToCreate([]);
    setshowCredentialsForm(false);
    setbucketCredentialsObj(emptyBucketCredentialsObj);
    if (bucketCredentialsToCreate.length) {
      setBucketCredentials((prev) =>
        prev.filter((credential) => credential.hasOwnProperty("id"))
      );
    }
    setBucketNamesError(emptyBucketNamesError);
    setShowWizard(false);
    setStepIndex(0);
    setDisableForwardActionButton(false);
  };

  const createBucketCredentialsInWizard = async (bucketCredentialsInfoObj) => {
    setIsProcessing(true);
    const credentialObj = bucketCredentialsInfoObj;
    let url = "/v1/bucket-credentials";
    if (bucketCredentialsInfoObj.type === "DROPBOX") {
      url = "/v1/dropbox-credentials";
      credentialObj.code = credentialObj.accessKey;
    }

    try {
      const newBucketCredentials = await fetchWithAuth(
        dispatch,
        url,
        auth.accessToken,
        "POST",
        JSON.stringify(credentialObj)
      );
      dispatch(fetchBucketCredentialList());
      setBucketCredentials((prev) => [...prev, newBucketCredentials]);
      setbucketCredentialsToCreate((prev) => [...prev, bucketCredentialsObj]);
      setbucketCredentialsObj(emptyBucketCredentialsObj);
      setBucketObj((prev) => ({
        ...prev,
        credentialsId: String(newBucketCredentials.id),
      }));
      setshowCredentialsForm(false);
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
      const message = err.message || "Unable to create bucket credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const saveBucket = async () => {
    setIsProcessing(true);

    const newlyCreatedStorageType = [];

    if (storageToCreate.length) {
      try {
        for (const storage of storageToCreate) {
          const storageBody = {
            description: storage.description,
            currencySymbol: storage.currencySymbol,
            price: storage.price,
          };
          const storageResponse = await fetchWithAuth(
            dispatch,
            "/v1/storage-prices",
            auth.accessToken,
            "POST",
            JSON.stringify(storageBody)
          );
          newlyCreatedStorageType.push(storageResponse);
        }
        dispatch(fetchStoragePriceList());
      } catch (e) {
        if (e.subErrors) {
          dispatch(receiveInvalidServerList(e));
        } else {
          dispatch(setAlert(e.message, "danger"));
        }
      }
    }

    try {
      const bucketBody = {
        name: bucketObj.name,
        description: bucketObj.description,
        path: bucketObj.path,
        region: bucketObj.region,
        type: bucketObj.type,
        awsCredentialsId: bucketObj.credentialsId,
        storagePrice: { id: bucketObj.storageType },
      };
      if (bucketObj.type === "ORACLE_OBJECT_STORAGE") {
        bucketBody.namespace = bucketObj.namespace;
        bucketBody.name = `${bucketObj.namespace}.${bucketBody.name}`;
      }

      // get the back end generated id if the storage was created during the wizard
      if (bucketObj.storageType === undefined) {
        const correctId = newlyCreatedStorageType.find(
          (storage) => storage.description === bucketObj.storageName
        ).id;
        bucketBody.storagePrice.id = correctId;
      }

      await fetchWithAuth(
        dispatch,
        "/v1/buckets",
        auth.accessToken,
        "POST",
        JSON.stringify(bucketBody)
      );

      dispatch(
        fetchBucketList(1, buckets.sort, buckets.direction, buckets.size)
      );
      dispatch(fetchVolumeSettings());
      dispatch(fetchStoragePriceList());

      setShowWizard(false);
      setIsProcessing(false);
      onWizardExit();
    } catch (e) {
      setIsProcessing(false);

      if (e.message.includes("Bucket created")) {
        dispatch(
          fetchBucketList(1, buckets.sort, buckets.direction, buckets.size)
        );
        dispatch(fetchVolumeSettings());
        dispatch(fetchStoragePriceList());
        setShowWizard(false);
        onWizardExit();
        dispatch(setAlert(e.message, "warning"));
      } else {
        setIsProcessing(false);
        const message = e.message || "Unable to create bucket";
        dispatch(setAlert(message, "danger"));
      }
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        if (stepIndex === 1) setDisableForwardActionButton(false);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Save":
        saveBucket();
        break;

      case "Cancel storage type creation":
        setStorageTypeObj(emptyStorageTypeObj);
        setShowCreateStorageType(false);
        setDisableForwardActionButton(false);
        break;

      case "Add storage type":
        setStoragePrices((prev) => [...prev, storageTypeObj]);
        setStorageToCreate((prev) => [...prev, storageTypeObj]);
        setStorageTypeObj(emptyStorageTypeObj);
        setSelectedStorageType(storageTypeObj);
        setShowCreateStorageType(false);
        break;

      case "Cancel credentials creation":
        setbucketCredentialsObj(emptyBucketCredentialsObj);
        setshowCredentialsForm(false);
        if (
          bucketObj.credentialsId.length === 0 ||
          bucketObj.region.length === 0
        ) {
          setDisableForwardActionButton(true);
        } else {
          setDisableForwardActionButton(false);
        }
        break;

      case "Create credentials":
        createBucketCredentialsInWizard(bucketCredentialsObj);
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (showCreateStorageType) {
      return "Cancel storage type creation";
    } else if (showCredentialsForm) {
      return "Cancel credentials creation";
    } else if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (showCreateStorageType) {
      return "Add storage type";
    } else if (stepIndex === STEPS_LENGTH - 1) {
      return "Save";
    } else if (showCredentialsForm) {
      return "Create credentials";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      onWizardExit={onWizardExit}
      stepIndex={stepIndex + 1}
      stepsLength={STEPS_LENGTH}
      title="Add Cloud Storage"
      subTitle={STEP_TITLES[stepIndex]}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      isProcessing={isProcessing}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}

export default BucketWizard;
