import React, { Component } from "react";
import { connect } from "react-redux";

import { dismissAlert } from "../actions/alert";

import Alert from "../components/Alert";

class AlertContainer extends Component {
  componentDidUpdate(prevProps) {
    const { type, timer, dispatch } = this.props;

    if (prevProps.type !== type) {
      setTimeout(() => dispatch(dismissAlert()), timer);
    }
  }
  render() {
    const { message, type, visible, dispatch } = this.props;

    return (
      <span
        style={{ top: 10, position: "fixed", zIndex: "9999", width: "100%" }}
      >
        <Alert
          type={type}
          message={message}
          dismissAlert={() => dispatch(dismissAlert())}
          visible={visible}
        />
      </span>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;

  const { message, type, timer, visible } = alert;
  return { message, type, timer, visible };
}

export default connect(mapStateToProps)(AlertContainer);
