import { SET_ALERT, DISMISS_ALERT } from "../actions/alert";

function alert(state = { message: "", type: "", timer: 8000, visible: false }, action) {
  switch (action.type) {
    case SET_ALERT:
      return Object.assign({}, state, {
        message: action.message,
        type: action.alertType,
        timer: action.timer,
        visible: true,
      });
    case DISMISS_ALERT:
      return Object.assign({}, state, {
        message: "",
        type: "",
        timer: 8000,
        visible: false
      });
    default:
      return state;
  }
}

export default alert;
