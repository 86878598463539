import { useState, useEffect } from "react";
import { Input, Form, FormGroup, Label, Col, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import IgnorePathsInput from "./IgnorePathsInput";
import { useDispatch, useSelector } from "react-redux";
import { getAllNetworkVolumeCredentialsPaginated } from "../../api/networkVolume";
import { setAlert } from "../../actions/alert";
import { SCAN_TYPE } from "../dataSource/volume/ScanType";
import { formatName } from "../../utils/formatString";
import IndexFolderOnlyPathsInput from "./IndexFolderOnlyPathsInput";

VolumeSettingForm.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  serverList: PropTypes.array,
  serverId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storagePriceList: PropTypes.array,
  storagePriceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ignoredPaths: PropTypes.array,
  host: PropTypes.string,
  shareName: PropTypes.string,
  options: PropTypes.string,
  scanType: PropTypes.string,
  indexFolderOnlyPaths: PropTypes.array,
  credential: PropTypes.string,
  mountType: PropTypes.string,
  handleChange: PropTypes.func,
  setIsSaveButtonEnabled: PropTypes.func,
};

function VolumeSettingForm(props) {
  const {
    name,
    path,
    serverList,
    serverId,
    storagePriceList,
    storagePriceId,
    ignoredPaths,
    host,
    shareName,
    options,
    scanType,
    indexFolderOnlyPaths,
    credential,
    mountType,
    handleChange,
    setIsSaveButtonEnabled,
  } = props;

  const [fieldError, setFieldError] = useState({
    name: "",
    path: "",
    server: "",
    storagePrice: "",
    host: "",
    shareName: "",
    options: "",
  });
  const [onPremisesCredentials, setOnPremisesCredentials] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getAllNetworkVolumeCredentialsPaginated(dispatch, auth)
      .then((res) => setOnPremisesCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the On-Premises credentials",
            "danger"
          )
        )
      );
  }, []);

  useEffect(() => {
    setIsSaveButtonEnabled(
      fieldError.name === "" &&
        fieldError.path === "" &&
        fieldError.server === "" &&
        fieldError.storagePrice === "" &&
        fieldError.host === "" &&
        fieldError.shareName === "" &&
        fieldError.options === ""
    );
  }, [
    fieldError.name,
    fieldError.path,
    fieldError.server,
    fieldError.storagePrice,
    fieldError.host,
    fieldError.shareName,
    fieldError.options,
  ]);

  const handleFieldError = (field, value) => {
    let fieldName = "";
    let reason = "";
    if (field === "options") {
      if (value.length > 10000) {
        reason = " must be between 0 and 10000 characters";
      }
    } else {
      if (value === "") {
        reason = " can't be empty";
      }
    }
    switch (field) {
      case "name":
        fieldName = "Name";
        break;
      case "path":
        fieldName = "Path";
        break;
      case "server":
        fieldName = "Server";
        break;
      case "storagePrice":
        fieldName = "Storage Type";
        break;
      case "host":
        fieldName = "Host";
        break;
      case "shareName":
        fieldName = "Share Name";
      case "options":
        fieldName = "Options";
        break;
      default:
        break;
    }

    setFieldError((prev) => ({
      ...prev,
      [field]: reason === "" ? "" : fieldName + reason,
    }));
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    handleChange(name, value);
    handleFieldError(name, value);
  };

  return (
    <Form>
      {mountType !== "Local" && (
        <>
          <FormGroup row>
            <Label for="host" sm={4}>
              Host
            </Label>
            <Col sm={8}>
              <Input
                id="host"
                name="host"
                type="text"
                placeholder="Ex: 127.0.0.1"
                value={host}
                onChange={handleInputChange}
                invalid={fieldError.host !== ""}
              />
              <FormFeedback>{fieldError.host}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="shareName" sm={4}>
              Share Name
            </Label>
            <Col sm={8}>
              <Input
                id="shareName"
                name="shareName"
                type="text"
                placeholder="Ex: NASW"
                value={shareName}
                onChange={handleInputChange}
                invalid={fieldError.shareName !== ""}
              />
              <FormFeedback>{fieldError.shareName}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="credential" sm={4}>
              Credentials
            </Label>
            <Col sm={8}>
              <Input
                id="credential"
                name="credential"
                type="select"
                value={credential}
                onChange={handleInputChange}
              >
                <option value="">Guest</option>
                {onPremisesCredentials.map((cred) => (
                  <option value={cred.id}>{cred.name}</option>
                ))}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="options" sm={4}>
              Options (optional)
            </Label>
            <Col sm={8}>
              <Input
                id="options"
                name="options"
                type="text"
                placeholder="Ex: option1=value1,option2=value2"
                value={options}
                onChange={handleInputChange}
                invalid={fieldError.options !== ""}
              />
              <FormFeedback>{fieldError.options}</FormFeedback>
            </Col>
          </FormGroup>
        </>
      )}
      <FormGroup row>
        <Label for="name" sm={4}>
          Name
        </Label>
        <Col sm={8}>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Ex: SAN"
            value={name}
            onChange={handleInputChange}
            invalid={fieldError.name !== ""}
          />
          <FormFeedback>{fieldError.name}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="path" sm={4}>
          Path
        </Label>
        <Col sm={8}>
          <Input
            id="path"
            name="path"
            type="text"
            placeholder="Ex: /mnt/SAN"
            value={path}
            onChange={handleInputChange}
            invalid={fieldError.path !== ""}
          />
          <FormFeedback>{fieldError.path}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="scanType" sm={4}>
          Scan Type
        </Label>
        <Col sm={8}>
          <Input
            id="scanType"
            name="scanType"
            type="select"
            value={scanType}
            onChange={handleInputChange}
          >
            {SCAN_TYPE.map((type, index) => <option key={type + "-" + index} value={type}>{formatName(type)}</option>)}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="server" sm={4}>
          Server
        </Label>
        <Col sm={8}>
          <Input
            name="server"
            type="select"
            value={serverId}
            onChange={handleInputChange}
            invalid={fieldError.server !== ""}
          >
            <option value={""}>Select server</option>
            {serverList.map((server) => {
              return (
                <option key={server.id} value={server.id}>
                  {server.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{fieldError.server}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="storagePrice" sm={4}>
          Storage Type
        </Label>
        <Col sm={8}>
          <Input
            name="storagePrice"
            type="select"
            value={storagePriceId}
            onChange={handleInputChange}
            invalid={fieldError.storagePrice !== ""}
          >
            <option value={""}>Select storage type</option>
            {storagePriceList.map((storagePrice) => {
              return (
                <option key={storagePrice.id} value={storagePrice.id}>
                  {storagePrice.description}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{fieldError.storagePrice}</FormFeedback>
        </Col>
      </FormGroup>
      <IgnorePathsInput
        ignoredPaths={ignoredPaths}
        handleChange={handleChange}
      />
      <IndexFolderOnlyPathsInput 
        indexFolderOnlyPaths={indexFolderOnlyPaths}
        handleChange={handleChange}
      />
    </Form>
  );
}

export default VolumeSettingForm;
