import { useDispatch, useSelector } from "react-redux";
import { deleteReportTemplate } from "../api/report";
import ConfirmModal from "../components/ConfirmModal";
import { setAlert } from "../actions/alert";

export default function DeleteReportModal(props) {
  const {
    id,
    name,
    showDeleteReportModal,
    fetchReports,
    closeModal,
    onDelete = () => {},
  } = props;
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  const deleteReportFunc = () => {
    deleteReportTemplate(dispatch, auth, id)
      .then((res) => {
        closeModal();
        onDelete();
        fetchReports();
        dispatch(
          setAlert(
            `Report template [${name}] has been deleted successfully.`,
            "success"
          )
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setAlert(
            `An error occurred while deleting the report template [${name}].`,
            "danger",
            8000
          )
        );
      });
  };

  return (
    <ConfirmModal
      showConfirmation={showDeleteReportModal}
      title="Delete Confirmation"
      closeConfirmation={() => closeModal()}
      buttonColor="danger"
      buttonText="Delete"
      confirmAction={() => deleteReportFunc()}
    >
      <p>Are you sure you want to delete the report template {name}?</p>
      <span>
        Note that deleting the report template will not remove the tag from the
        files and folders.
      </span>
    </ConfirmModal>
  );
}
