import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";
import { updateConfigurations } from "../../../ducks/configurations";

export default function UpdateIconikConfigurationModal(props) {
  const { isOpen, setIsOpen } = props;

  const [appId, setAppId] = useState("");

  const dispatch = useDispatch();

  const configurations = useSelector(({ configurations }) => configurations);

  useEffect(() => {
    if (isOpen) {
      setAppId(configurations.iconikAppId.value);
    }
  }, [isOpen, configurations.iconikAppId]);

  return (
    <ConfirmModal
      confirmAction={() => {
        dispatch(updateConfigurations([{ ...configurations.iconikAppId, value: appId }]));
        setIsOpen(false);
      }}
      showConfirmation={isOpen}
      title="Update Configurations"
      closeConfirmation={() => setIsOpen(false)}
      buttonColor="primary"
      buttonText="Save"
    >
      <Form>
        <FormGroup row>
          <Label sm={4}>Application ID</Label>
          <Col sm={8}>
            <Input
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
            />
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}