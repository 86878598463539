export function buildSearchUrl(folders) {
    let url = "/search?";
    folders.forEach(element => {
        const volumeId = element.volumeId;
        const path = element.path === "" ? "/" : element.path;
        if (url.charAt(url.length - 1) !== "?") {
            url = url.concat("&");
        }
        url = url.concat(`volumeAndPath=${volumeId}:${path}`);
    })
    return url;
};