import SizeValidation from "./SizeValidation";
import FileTypeValidation from "./FileTypeValidation";
import { Badge } from "reactstrap";
import FormatSize from "../FormatSize";

export default function SourceReview({
  fileOrFolder = "file",
  numberOfValidFileOrFolders,
  validTotalFileSize,
  totalFileSize,
  action,
  fileType,
  totalNumberOfFilesOrFolders,
  addP5Icon = false,
  activeVolumes = [],
  children,
}) {
  return (
    <>
      <div className="d-flex">
        <div>
          <h3>Source</h3>
          <ul
            style={{
              listStyle: "none",
              lineHeight: "2",
              paddingLeft: "0",
              marginBottom: "0",
            }}
          >
            <SizeValidation
              validTotalFileSize={validTotalFileSize}
              action={action}
            />

            {children}

            <li>
              Number of selected elements:{" "}
              <strong>{numberOfValidFileOrFolders}</strong>
            </li>
            <li>
              Total size: <strong><FormatSize>{validTotalFileSize}</FormatSize></strong>
            </li>
            {activeVolumes.length > 0 && (
              <li>
                Storages:{" "}
                {activeVolumes.map((volume, index) => (
                  <span key={index} className="me-1">
                    <Badge color="primary">{volume}</Badge>
                  </span>
                ))}
              </li>
            )}

            <FileTypeValidation
              validTotalFileSize={validTotalFileSize}
              totalFileSize={totalFileSize}
              fileType={fileType}
              numberOfValidFileOrFolders={numberOfValidFileOrFolders}
              totalNumberOfFilesOrFolders={totalNumberOfFilesOrFolders}
              fileOrFolder={fileOrFolder}
            />
          </ul>
        </div>
        {addP5Icon && (
          <div className="ms-auto align-self-center">
            <img
              src="/icons/P5.png"
              alt="Archiware P5 logo"
              className="img-fluid"
              width="60px"
              height="60px"
            />
          </div>
        )}
      </div>
    </>
  );
}
