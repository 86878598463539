import { useEffect } from "react";
import SourceSelectionItem from "../SourceSelectionItem";
import { faCopy, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function SelectSodaActionStep({
  sodaActionObj,
  setSodaActionObj,
  setStepIndex,
  stepIndex,
  setDisableForwardActionButton,
}) {
  useEffect(() => {
    if (!sodaActionObj.selectedAction) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  }, []);

  return (
    <>
      <div>
        <SourceSelectionItem
          itemIcon={faCopy}
          iconSize={"3x"}
          onClickFunction={() => {
            setDisableForwardActionButton(false);
            setStepIndex(++stepIndex);
            setSodaActionObj((prev) => ({ ...prev, action: "copy" }));
          }}
          title={"Copy"}
          description={
            "Create new copies of the selected items to a new destination"
          }
        />
      </div>

      <div>
        <SourceSelectionItem
          itemIcon={faArrowRight}
          iconSize={"3x"}
          onClickFunction={() => {
            setDisableForwardActionButton(false);
            setStepIndex(++stepIndex);
            setSodaActionObj((prev) => ({ ...prev, action: "move" }));
          }}
          title={"Move"}
          description={"Move the selected items to a new destination"}
        />
      </div>
    </>
  );
}
