import { fetchWithAuth } from "../actions/requestHelper";

// === Actions ===
export const REQUEST_APP_INFO = "REQUEST_APP_INFO";
export const RECEIVE_APP_INFO = "RECEIVE_APP_INFO";
export const SET_IS_LICENSE_VALID = "SET_IS_LICENSE_VALID";

// === Reducers ===
export default function app(state = { version: "", expirationDate: "", isValid: true }, action) {
    switch (action.type) {
        case RECEIVE_APP_INFO:
            return Object.assign({}, state, {
                version: action.info.build.version,
                expirationDate: action.info.license ? action.info.license.expirationDate : "CLOUD",
                isValid: action.info.license ? action.info.license.isValid : true
            });
        case SET_IS_LICENSE_VALID:
            return Object.assign({}, state, {
                isValid: action.isValid
            });
        default:
            return state;
    }
}

// === Action Creators ===
function requestAppInfo() {
    return {
        type: REQUEST_APP_INFO
    };
}

function receiveAppInfo(info) {
    return {
        type: RECEIVE_APP_INFO,
        info
    };
}

export function setIsLicenseValid(isValid) {
    return {
        type: SET_IS_LICENSE_VALID,
        isValid
    }
}

// === Side Effects ===
export function fetchAppInfo() {
    return (dispatch, getState) => {
        dispatch(requestAppInfo());
        const url = `/actuator/info`;
        return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
            json => {
                dispatch(receiveAppInfo(json));
            }
        ).catch(
            error => {
                if (error.debugMessage === "Invalid License") {
                    dispatch(setIsLicenseValid(false));
                }
            }
        );
    };
}