import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function SizeOfPageSelector({ numberOfElements, setNumberOfElements }) {
  return (
    <>
      <Input
        type="select"
        style={{ width: "70px", textAlign: "center" }}
        bsSize="sm"
        value={numberOfElements}
        onChange={(e) => setNumberOfElements(Number(e.target.value))}
      >
        <option>10</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
        <option>500</option>
      </Input>
      <span className="ms-2">per page</span>
    </>
  );
}

function PageSelector({
  currentPage,
  setCurrentPage,
  numberOfPages,
  numberOfElements,
}) {
  const [currPage, setCurrPage] = useState(currentPage);

  const prevPageRef = useRef(currPage);

  useEffect(() => {
    if (prevPageRef.current !== currPage) {
      const currTimeout = setTimeout(
        () => setCurrentPage(currPage < 1 ? 1 : Number(currPage)),
        800
      );
      prevPageRef.current = currPage;
      return () => clearTimeout(currTimeout);
    }
  }, [currPage]);

  const maxNumberOfPages = Math.min(
    numberOfPages,
    Math.ceil(10000 / numberOfElements)
  );

  const handleChange = (value) => {
    const newValue = value.replace(/\D/g, "");

    if (Number(newValue) > maxNumberOfPages) {
      setCurrPage(maxNumberOfPages);
    } else {
      setCurrPage(newValue);
    }
  };

  return (
    <>
      <Button
        outline
        disabled={currPage === 1}
        onClick={() => setCurrPage(currPage - 1)}
        size="sm"
        style={{ border: 0 }}
        className="me-1"
      >
        <FontAwesomeIcon id="previousPage" icon={faChevronLeft} />
      </Button>
      <span className="me-2">Page</span>
      <Input
        type="text"
        value={currPage}
        onChange={(e) => handleChange(e.target.value)}
        style={{ width: "50px", textAlign: "center" }}
        bsSize="sm"
      />
      {numberOfPages < 100 && <span className="ms-2">of {numberOfPages}</span>}
      <Button
        outline
        disabled={currPage === maxNumberOfPages || numberOfPages === 0}
        onClick={() => setCurrPage(currPage + 1)}
        size="sm"
        style={{ border: 0 }}
        className="ms-1 me-2"
      >
        <FontAwesomeIcon id="nextPage" icon={faChevronRight} />
      </Button>
    </>
  );
}

export default function PagesNavigation(props) {
  const {
    page,
    numberOfPages,
    selectPage,
    numberOfElements,
    setNumberOfElements,
  } = props;

  const showPageNav = 0 !== numberOfPages;

  return showPageNav ? (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <PageSelector
        currentPage={page}
        setCurrentPage={selectPage}
        numberOfPages={numberOfPages}
        numberOfElements={numberOfElements}
      />
      <SizeOfPageSelector
        numberOfElements={numberOfElements}
        setNumberOfElements={setNumberOfElements}
      />
    </div>
  ) : null;
}
