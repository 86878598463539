import { REQUEST_DUPLICATE_FILES, RECEIVE_DUPLICATE_FILES } from "../actions";

function files(statistics) {
  let state = { isFetching: false };
  statistics.forEach(element => {
    state[element.indexingDate] = {
      numberOfFiles: element.numberOfFiles,
      size: element.size
    };
  });

  return state;
}

function duplicateFilesByIndex(state = { isFetching: false }, action) {
  switch (action.type) {
    case RECEIVE_DUPLICATE_FILES:
      return Object.assign({}, state, files(action.statistics));
    case REQUEST_DUPLICATE_FILES:
      return { isFetching: true };
    default:
      return state;
  }
}

export default duplicateFilesByIndex;
