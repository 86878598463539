import { useEffect, useState } from "react";
import DellPowerScaleStorageForm from "./DellPowerScaleStorageForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteDellPowerScaleStorage, getAllDellPowerScaleStoragesPaginated } from "../../../api/dellPowerScale";
import { setAlert } from "../../../actions/alert";
import { Badge, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ScanButton from "../../components/ScanButton";
import { getVolumeSettings } from "../../../api/volume";
import { scanDellPowerScales } from "../../../api/scan";
import PerformanceModeWarning from "../../components/PerformanceModeWarning";
import Loading from "../../../components/Loading";
import TableActions from "../../../components/TableActions";
import TableResult from "../../../components/TableResult";
import ConfirmModal from "../../../components/ConfirmModal";

export default function DellPowerScaleStorageList() {
  const [showCreateStorageForm, setShowCreateStorageForm] = useState(false);
  const [showUpdateStorageForm, setShowUpdateStorageForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [dellPowerScaleStorages, setDellPowerScaleStorages] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleStorages") ? Number(window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleStorages")) : 10,
    page: 1
  });
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [allDellPowerScaleStorages, setAllDellPowerScaleStorages] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const servers = useSelector(({ servers }) => servers.items);

  useEffect(() => {
    fetchDellPowerScaleStorages(
      pagination.page,
      pagination.size,
      pagination.sort,
      pagination.direction
    );
  }, [pagination.page, pagination.size, pagination.sort, pagination.direction]);

  const fetchDellPowerScaleStorages = async (
    page = pagination.page, 
    size = pagination.size, 
    sort = pagination.sort, 
    direction = pagination.direction
  ) => {
    setIsFetching(true);

    // Get all the Dell PowerScale volumeSettings
    getVolumeSettings(dispatch, auth, 1, "id", "asc", 500, ["DellPowerScale"])
      .then((res) => setAllDellPowerScaleStorages(res.elements))
      .catch((err) => {
        console.error(err);
        dispatch(setAlert("Unable to fetch all Dell PowerScale storages", "danger", 8000));
      });

    try {
      const res = await getAllDellPowerScaleStoragesPaginated(dispatch, auth, page, size, sort, direction);
      setTotal(res.total);
      setDellPowerScaleStorages(res.elements);
      setIsFetching(false);
    } catch (err) {
      console.error(err);
      dispatch(setAlert("Unable to fetch Dell PowerScale storages", "danger", 8000));
      setIsFetching(false);
    }
  };

  const ignoredPathBadges = (ignoredPaths) => {
    return (
      <div style={{ maxWidth: "300px" }}>
        {ignoredPaths.map((path) => {
          return (
            <Badge color="primary" style={{ marginRight: 5 }} key={path}>
              {path}
            </Badge>
          );
        })}
      </div>
    );
  };

  const numberOfPages = Math.ceil(total / pagination.size);

  const titles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "dataintellName" },
    { name: "Path", sort: "path" },
    { name: "Server", sort: "volumeSettingEntity.server.name" },
    { name: "Storage Type", sort: "volumeSettingEntity.storagePrice.description" },
    { name: "Ignored Paths" },
    { name: "Action" }
  ];

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({ ...prev, sort: newSort, direction: newDirection }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsDataSourceDellPowerScaleStorages", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const handleScanVolumeIds = (volumeIds) => {
    return dellPowerScaleStorages
      .filter((element) => volumeIds.includes(element.volumeSettingId))
      .map((element) => element.id);
  };

  const listFormated = dellPowerScaleStorages.map((storage) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };

    lineObject.columnData = [
      storage.id,
      storage.dataintellName,
      storage.path,
      servers.find(server => storage.serverId === server.id)?.name,
      storage.storagePrice ? storage.storagePrice.description : "N/A",
      ignoredPathBadges(storage.volumeIgnoreRules),
      <>
        <Button
          onClick={() => {
            setId(storage.id);
            setShowUpdateStorageForm(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(storage.id);
            setName(storage.dataintellName);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <ScanButton
          title="Scan"
          volumeIds={[storage.volumeSettingId]}
          volumes={allDellPowerScaleStorages}
          scan={(storageIds, cloneIndex, deltaScan) =>
            scanDellPowerScales(dispatch, auth, handleScanVolumeIds(storageIds), cloneIndex, deltaScan)
          }
          hasCloneIndex
          hasDeltaScan
        >
          <PerformanceModeWarning />
          <p>
            Scanning a Dell PowerScale Storage can take between a couple minutes
            and several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      "",
      <>
        <Button
          onClick={() => {
            setShowCreateStorageForm(true);
          }}
          color="success"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <ScanButton
          title="Scan All"
          volumes={allDellPowerScaleStorages}
          volumeIds={allDellPowerScaleStorages.map((storage) => storage.id)}
          scan={(storageIds, cloneIndex, deltaScan) =>
            scanDellPowerScales(dispatch, auth, handleScanVolumeIds(storageIds), cloneIndex, deltaScan)
          }
          hasCloneIndex
          hasDeltaScan
        >
          <PerformanceModeWarning />
          <p>
            Scanning all Dell PowerScale Storages can take between a couple minutes
            and several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ],
    style: {},
    options: {},
  });

  const handleCloseModal = () => {
    setShowCreateStorageForm(false);
    setShowUpdateStorageForm(false);
    setShowDeleteConfirmation(false);
    setId(null);
    setName("");
  };

  const handleDelete = () => {
    deleteDellPowerScaleStorage(dispatch, auth, id)
      .then(() => {
        fetchDellPowerScaleStorages();
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        dispatch(setAlert("Unable to delete Dell PowerScale storage", "danger", 8000));
      });
  };

  return (
    <>
      <h5>Storages</h5>
      {isFetching ?
        <Loading /> :
        <div>
          <TableActions 
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          />
          <TableResult 
            data={listFormated}
            titles={titles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      }
      <DellPowerScaleStorageForm
        isOpen={showCreateStorageForm}
        handleConfirmationFunction={fetchDellPowerScaleStorages}
        handleClose={handleCloseModal}
      />
      <DellPowerScaleStorageForm 
        isOpen={showUpdateStorageForm}
        handleConfirmationFunction={fetchDellPowerScaleStorages}
        handleClose={handleCloseModal}
        id={id}
      />
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => handleDelete()}
        closeConfirmation={() => handleCloseModal()}
      >
        Are you sure you want to delete the Dell PowerScale storage {name}?
      </ConfirmModal>
    </>
  );
}