import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import moment from "moment";

// === Actions ===
// READ
export const REQUEST_FOLDER_DETAILS = "REQUEST_FOLDER_DETAILS";
export const RECEIVE_FOLDER_DETAILS = "RECEIVE_FOLDER_DETAILS";
export const REQUEST_FOLDER_STATISTICS = "REQUEST_FOLDER_STATISTICS";
export const RECEIVE_FOLDER_STATISTICS = "RECEIVE_FOLDER_STATISTICS";
export const REQUEST_FOLDER_DETAILS_BY_HASH = "REQUEST_FOLDER_DETAILS_BY_HASH";
export const RECEIVE_FOLDER_DETAILS_BY_HASH = "RECEIVE_FOLDER_DETAILS_BY_HASH";

// === Reducers ===
const initialState = {
  items: [],
  isFetching: false,
  total: 0,
  size: 0,
  page: 0,
  dates: [],
  statistics: {}
};

function getDates(elements) {
  return elements.map(element => moment(element.indexingDate).format("lll"));
}

export default function folderDetails(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FOLDER_DETAILS:
    case REQUEST_FOLDER_DETAILS_BY_HASH:
      return Object.assign({}, state, {
        isFetching: true,
        items: [],
        dates: []
      });
    case RECEIVE_FOLDER_DETAILS:
    case RECEIVE_FOLDER_DETAILS_BY_HASH:
      return Object.assign({}, state, {
        items: action.folders.elements,
        isFetching: false,
        total: action.folders.total,
        size: action.folders.size,
        page: action.folders.page,
        sort: action.folders.sort,
        direction: action.folders.direction,
        dates: getDates(action.folders.elements).reverse()
      });
    case REQUEST_FOLDER_STATISTICS:
      return Object.assign({}, state, {
        statistics: {}
      });
    case RECEIVE_FOLDER_STATISTICS:
      return Object.assign({}, state, {
        statistics: action.statistics
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestFolderDetails() {
  return {
    type: REQUEST_FOLDER_DETAILS
  };
}

function receiveFolderDetails(json) {
  return {
    type: RECEIVE_FOLDER_DETAILS,
    folders: json
  };
}

function requestFolderStatistics() {
  return {
    type: REQUEST_FOLDER_STATISTICS
  };
}

function receiveFolderStatistics(statistics) {
  return {
    type: RECEIVE_FOLDER_STATISTICS,
    statistics
  };
}

function requestFolderDetailsByHash(hash) {
  return {
    type: REQUEST_FOLDER_DETAILS_BY_HASH,
    hash
  };
}

function receiveFolderDetailsByHash(folderhash, json) {
  return {
    type: RECEIVE_FOLDER_DETAILS_BY_HASH,
    folders: json,
    folderhash
  };
}

// === Side Effects ===
export function fetchFolderDetails(volumeId, path) {
  return (dispatch, getState) => {
    dispatch(requestFolderDetails());
    const url = "/directories/details";
    
    const queryParams = {
      path,
      volumeId,
      size: 100,
      indexingDate: getState().dates.activeDate
    };
    
    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, queryParams)
      .then(json => {
        dispatch(receiveFolderDetails(json));
      })
      .catch(err => {
        let message =
          err.statusText ||
          `Unable to get detail information about folder with path ${path} and volumeId ${volumeId}`;
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function fetchFolderStatistics(volumeId, path) {
  return (dispatch, getState) => {
    dispatch(requestFolderStatistics());
    const url = "/directories/statistics";
    
    const queryParams = {
      path,
      volumeId
    };
    
    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, queryParams)
      .then(json => {
        dispatch(receiveFolderStatistics(json));
      })
      .catch(err => {
        let message =
          err.statusText ||
          `Unable to get statistics information about folder with path ${path} and volumeId ${volumeId}`;
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function fetchFolderDetailsByHash(
  folderhash,
  page = 1,
  size = 5,
  sort = "name",
  direction = "asc"
) {
  return (dispatch, getState) => {
    dispatch(requestFolderDetailsByHash(folderhash));
    const url = `/directories/hash/${folderhash}?page=${page}&size=${size}&sort=${sort},${direction}&indexingDate=${getState().dates.activeDate
      }`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      json => {
        dispatch(receiveFolderDetailsByHash(folderhash, json));
      }
    ).catch(err => console.error(err));
  };
}
