import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import RectangleChart from "../../components/RectangleChart";
import { getStatistics } from "../../utils/getStatistics";
import FormatSize, { getSymbolFromBase } from "../../components/FormatSize";
import { useSelector } from "react-redux";

const COLOR_SIZE = [
  "#000000",
  "#070D12",
  "#0F1A24",
  "#162636",
  "#1E3348",
  "#25405B",
  "#2C4D6B",
  "#34597F",
  "#3B6691",
  "#3F6D97",
  "#4373A3",
  "#4A80B5",
  "#5C8CBC",
  "#6E99C4",
  "#80A6CB",
  "#89AFCF",
  "#92B3D3",
  "#A4BFDA",
  "#B7CCE1",
  "#C9D9E9",
  "#DBE6F0",
  "#E4EFF4",
  "#EDF2F8",
  "#FBFBFB",
];

export default function VolumeSizeChart(props) {
  const { latestDetailedVolume } = props;

  const navigate = useNavigate();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const getSizeOfFiles = (key) => {
    return latestDetailedVolume.statistics.SIZE &&
      latestDetailedVolume.statistics.SIZE[key]
      ? latestDetailedVolume.statistics.SIZE[key].sizeOfFiles
      : 0;
  };

  const size1TB = getSizeOfFiles("1TB");
  const size100GB = getSizeOfFiles("100GB") - getSizeOfFiles("1TB");
  const size10GB = getSizeOfFiles("10GB") - getSizeOfFiles("100GB");
  const size5GB = getSizeOfFiles("5GB") - getSizeOfFiles("10GB");
  const size2GB = getSizeOfFiles("2GB") - getSizeOfFiles("5GB");
  const size1GB = getSizeOfFiles("1GB") - getSizeOfFiles("2GB");
  const size500MB = getSizeOfFiles("500MB") - getSizeOfFiles("1GB");
  const size250MB = getSizeOfFiles("250MB") - getSizeOfFiles("500MB");
  const size100MB = getSizeOfFiles("100MB") - getSizeOfFiles("250MB");
  const size50MB = getSizeOfFiles("50MB") - getSizeOfFiles("100MB");
  const size25MB = getSizeOfFiles("25MB") - getSizeOfFiles("50MB");
  const size10MB = getSizeOfFiles("10MB") - getSizeOfFiles("25MB");
  const size5MB = getSizeOfFiles("5MB") - getSizeOfFiles("10MB");
  const size2MB = getSizeOfFiles("2MB") - getSizeOfFiles("5MB");
  const size1MB = getSizeOfFiles("1MB") - getSizeOfFiles("2MB");
  const size512KB = getSizeOfFiles("512KB") - getSizeOfFiles("1MB");
  const size256KB = getSizeOfFiles("256KB") - getSizeOfFiles("512KB");
  const size128KB = getSizeOfFiles("128KB") - getSizeOfFiles("256KB");
  const size64KB = getSizeOfFiles("64KB") - getSizeOfFiles("128KB");
  const size32KB = getSizeOfFiles("32KB") - getSizeOfFiles("64KB");
  const size16KB = getSizeOfFiles("16KB") - getSizeOfFiles("32KB");
  const size4KB = getSizeOfFiles("4KB") - getSizeOfFiles("16KB");
  const other = latestDetailedVolume.sizeOfFiles - getSizeOfFiles("4KB");

  const linksForChart = [
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=TiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=100&minimumSizeUnit=GiB&maximumSize=1&maximumSizeUnit=TiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=10&minimumSizeUnit=GiB&maximumSize=100&maximumSizeUnit=GiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=5&minimumSizeUnit=GiB&maximumSize=10&maximumSizeUnit=GiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=2&minimumSizeUnit=GiB&maximumSize=5&maximumSizeUnit=GiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=GiB&maximumSize=2&maximumSizeUnit=GiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=500&minimumSizeUnit=MiB&maximumSize=1&maximumSizeUnit=GiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=250&minimumSizeUnit=MiB&maximumSize=500&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=100&minimumSizeUnit=MiB&maximumSize=250&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=50&minimumSizeUnit=MiB&maximumSize=100&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=25&minimumSizeUnit=MiB&maximumSize=50&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=10&minimumSizeUnit=MiB&maximumSize=25&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=5&minimumSizeUnit=MiB&maximumSize=10&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=2&minimumSizeUnit=MiB&maximumSize=5&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=MiB&maximumSize=2&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=512&minimumSizeUnit=KiB&maximumSize=1&maximumSizeUnit=MiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=256&minimumSizeUnit=KiB&maximumSize=512&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=128&minimumSizeUnit=KiB&maximumSize=256&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=64&minimumSizeUnit=KiB&maximumSize=128&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=32&minimumSizeUnit=KiB&maximumSize=64&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=16&minimumSizeUnit=KiB&maximumSize=32&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&minimumSize=4&minimumSizeUnit=KiB&maximumSize=16&maximumSizeUnit=KiB`,
    `/search?volumeId=${latestDetailedVolume.id}&maximumSize=4&maximumSizeUnit=KiB`,
  ];

  const linksForLabels = {
    "1TB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=TB`,
    "100GB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=100&minimumSizeUnit=GiB`,
    "10GB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=10&minimumSizeUnit=GiB`,
    "5GB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=5&minimumSizeUnit=GiB`,
    "2GB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=2&minimumSizeUnit=GiB`,
    "1GB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=GiB`,
    "500MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=500&minimumSizeUnit=MiB`,
    "250MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=250&minimumSizeUnit=MiB`,
    "100MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=100&minimumSizeUnit=MiB`,
    "50MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=50&minimumSizeUnit=MiB`,
    "25MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=25&minimumSizeUnit=MiB`,
    "10MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=10&minimumSizeUnit=MiB`,
    "5MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=5&minimumSizeUnit=MiB`,
    "2MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=2&minimumSizeUnit=MiB`,
    "1MB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=1&minimumSizeUnit=MiB`,
    "512KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=512&minimumSizeUnit=KiB`,
    "256KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=256&minimumSizeUnit=KiB`,
    "128KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=128&minimumSizeUnit=KiB`,
    "64KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=64&minimumSizeUnit=KiB`,
    "32KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=32&minimumSizeUnit=KiB`,
    "16KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=16&minimumSizeUnit=KiB`,
    "4KB": `/search?volumeId=${latestDetailedVolume.id}&minimumSize=4&minimumSizeUnit=KiB`,
    total: `/search?volumeId=${latestDetailedVolume.id}`,
  };

  const labels = [
    <>
      {`Larger than 1 ${getSymbolFromBase("TB", baseSize)}: `}
      <FormatSize>{size1TB}</FormatSize>
    </>,
    <>
      {`Between 100 ${getSymbolFromBase(
        "GB",
        baseSize
      )} and 1 ${getSymbolFromBase("TB", baseSize)}: `}
      <FormatSize>{size100GB}</FormatSize>
    </>,
    <>
      {`Between 10 ${getSymbolFromBase(
        "GB",
        baseSize
      )} and 100 ${getSymbolFromBase("GB", baseSize)}: `}
      <FormatSize>{size10GB}</FormatSize>
    </>,
    <>
      {`Between 5 ${getSymbolFromBase(
        "GB",
        baseSize
      )} and 10 ${getSymbolFromBase("GB", baseSize)}: `}
      <FormatSize>{size5GB}</FormatSize>
    </>,
    <>
      {`Between 2 ${getSymbolFromBase(
        "GB",
        baseSize
      )} and 5 ${getSymbolFromBase("GB", baseSize)}: `}
      <FormatSize>{size2GB}</FormatSize>
    </>,
    <>
      {`Between 1 ${getSymbolFromBase(
        "GB",
        baseSize
      )} and 2 ${getSymbolFromBase("GB", baseSize)}: `}
      <FormatSize>{size1GB}</FormatSize>
    </>,
    <>
      {`Between 500 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 1 ${getSymbolFromBase("GB", baseSize)}: `}
      <FormatSize>{size500MB}</FormatSize>
    </>,
    <>
      {`Between 250 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 500 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size250MB}</FormatSize>
    </>,
    <>
      {`Between 100 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 250 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size100MB}</FormatSize>
    </>,
    <>
      {`Between 50 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 100 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size50MB}</FormatSize>
    </>,
    <>
      {`Between 25 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 50 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size25MB}</FormatSize>
    </>,
    <>
      {`Between 10 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 25 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size10MB}</FormatSize>
    </>,
    <>
      {`Between 5 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 10 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size5MB}</FormatSize>
    </>,
    <>
      {`Between 2 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 5 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size2MB}</FormatSize>
    </>,
    <>
      {`Between 1 ${getSymbolFromBase(
        "MB",
        baseSize
      )} and 2 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size1MB}</FormatSize>
    </>,
    <>
      {`Between 512 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 1 ${getSymbolFromBase("MB", baseSize)}: `}
      <FormatSize>{size512KB}</FormatSize>
    </>,
    <>
      {`Between 256 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 512 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size256KB}</FormatSize>
    </>,
    <>
      {`Between 128 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 256 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size128KB}</FormatSize>
    </>,
    <>
      {`Between 64 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 256 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size64KB}</FormatSize>
    </>,
    <>
      {`Between 32 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 64 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size32KB}</FormatSize>
    </>,
    <>
      {`Between 16 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 32 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size16KB}</FormatSize>
    </>,
    <>
      {`Between 4 ${getSymbolFromBase(
        "KB",
        baseSize
      )} and 16 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{size16KB}</FormatSize>
    </>,
    <>
      {`Smaller than 4 ${getSymbolFromBase("KB", baseSize)}: `}
      <FormatSize>{other}</FormatSize>
    </>,
  ];

  const data = [
    size1TB,
    size100GB,
    size10GB,
    size5GB,
    size2GB,
    size1GB,
    size500MB,
    size250MB,
    size100MB,
    size50MB,
    size25MB,
    size10MB,
    size5MB,
    size2MB,
    size1MB,
    size512KB,
    size256KB,
    size128KB,
    size64KB,
    size32KB,
    size16KB,
    size4KB,
    other,
  ];

  return (
    <>
      {latestDetailedVolume.statistics.SIZE && (
        <Card className="mb-3">
          <CardHeader>
            <CardTitle tag="h5">
              <b>File Size Distribution</b>
            </CardTitle>
            <CardSubtitle tag="h6">
              <span className="mb-2 text-muted">Size of files</span>
            </CardSubtitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={12} className="mb-2">
                <RectangleChart
                  links={linksForChart}
                  handleClick={(path) => navigate(path)}
                  labels={labels}
                  data={data}
                  colors={COLOR_SIZE}
                />
              </Col>

              {getStatistics({
                [`Larger than 1 ${getSymbolFromBase("TB", baseSize)}`]: {
                  size: getSizeOfFiles("1TB"),
                  link: linksForLabels["1TB"],
                },
                [`Larger than 100 ${getSymbolFromBase("GB", baseSize)}`]: {
                  size: getSizeOfFiles("100GB"),
                  link: linksForLabels["100GB"],
                },
                [`Larger than 10 ${getSymbolFromBase("GB", baseSize)}`]: {
                  size: getSizeOfFiles("10GB"),
                  link: linksForLabels["10GB"],
                },
                [`Larger than 5 ${getSymbolFromBase("GB", baseSize)}`]: {
                  size: getSizeOfFiles("5GB"),
                  link: linksForLabels["5GB"],
                },
                [`Larger than 2 ${getSymbolFromBase("GB", baseSize)}`]: {
                  size: getSizeOfFiles("2GB"),
                  link: linksForLabels["2GB"],
                },
                [`Larger than 1 ${getSymbolFromBase("GB", baseSize)}`]: {
                  size: getSizeOfFiles("1GB"),
                  link: linksForLabels["1GB"],
                },
                [`Larger than 500 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("500MB"),
                  link: linksForLabels["500MB"],
                },
                [`Larger than 250 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("250MB"),
                  link: linksForLabels["250MB"],
                },
                [`Larger than 100 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("100MB"),
                  link: linksForLabels["100MB"],
                },
                [`Larger than 50 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("50MB"),
                  link: linksForLabels["50MB"],
                },
                [`Larger than 25 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("25MB"),
                  link: linksForLabels["25MB"],
                },
                [`Larger than 10 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("10MB"),
                  link: linksForLabels["10MB"],
                },
                [`Larger than 5 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("5MB"),
                  link: linksForLabels["5MB"],
                },
                [`Larger than 2 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("2MB"),
                  link: linksForLabels["2MB"],
                },
                [`Larger than 1 ${getSymbolFromBase("MB", baseSize)}`]: {
                  size: getSizeOfFiles("1MB"),
                  link: linksForLabels["1MB"],
                },
                [`Larger than 512 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("512KB"),
                  link: linksForLabels["512KB"],
                },
                [`Larger than 512 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("512KB"),
                  link: linksForLabels["512KB"],
                },
                [`Larger than 256 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("256KB"),
                  link: linksForLabels["256KB"],
                },
                [`Larger than 128 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("128KB"),
                  link: linksForLabels["128KB"],
                },
                [`Larger than 64 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("64KB"),
                  link: linksForLabels["64KB"],
                },
                [`Larger than 32 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("32KB"),
                  link: linksForLabels["32KB"],
                },
                [`Larger than 16 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("16KB"),
                  link: linksForLabels["16KB"],
                },
                [`Larger than 4 ${getSymbolFromBase("KB", baseSize)}`]: {
                  size: getSizeOfFiles("4KB"),
                  link: linksForLabels["4KB"],
                },

                Total: {
                  size: latestDetailedVolume.sizeOfFiles,
                  link: linksForLabels["total"],
                },
              })}
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
}
