import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_SERVER = "REQUEST_CREATE_SERVER";
const RECEIVE_CREATE_SERVER = "RECEIVE_CREATE_SERVER";
// READ
const REQUEST_SERVER_LIST = "REQUEST_SERVER_LIST";
const RECEIVE_SERVER_LIST = "RECEIVE_SERVER_LIST";
// UPDATE
const REQUEST_UPDATE_SERVER = "REQUEST_UPDATE_SERVER";
const RECEIVE_UPDATE_SERVER = "RECEIVE_UPDATE_SERVER";
// DELETE
const REQUEST_DELETE_SERVER = "REQUEST_DELETE_SERVER";
const RECEIVE_DELETE_SERVER = "RECEIVE_DELETE_SERVER";
// ERRORS
const RECEIVE_INVALID_SERVER = "RECEIVE_INVALID_SERVER";

// === Reducers ===
export default function servers(
  state = {
    items: [],
    isFetching: false,
    errors: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_SERVER_LIST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_SERVER_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.servers,
        errors: {}
      });
    case RECEIVE_INVALID_SERVER:
      return Object.assign({}, state, {
        errors: formatError(action.errors)
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestServerList() {
  return {
    type: REQUEST_SERVER_LIST
  };
}

function receiveServerList(servers) {
  return {
    type: RECEIVE_SERVER_LIST,
    servers
  };
}

function requestCreateServer() {
  return {
    type: REQUEST_CREATE_SERVER
  };
}

function receiveCreateServer(createdServer) {
  return {
    type: RECEIVE_CREATE_SERVER,
    server: createdServer
  };
}

function requestUpdateServer() {
  return {
    type: REQUEST_UPDATE_SERVER
  };
}

function receiveUpdateServer(updatedServer) {
  return {
    type: RECEIVE_UPDATE_SERVER,
    server: updatedServer
  };
}

function requestDeleteServer(id) {
  return {
    type: REQUEST_DELETE_SERVER,
    id
  };
}

function receiveDeleteServer(id) {
  return {
    type: RECEIVE_DELETE_SERVER,
    id
  };
}

function receiveInvalidServerList(errors) {
  return {
    type: RECEIVE_INVALID_SERVER,
    errors
  };
}

// === Side Effects ===
export function fetchServerList() {
  return async (dispatch, getState) => {
    dispatch(requestServerList());
    const url = `/servers`;
    try {
      const json = await fetchWithAuth(dispatch, url, getState().auth.accessToken);
      dispatch(receiveServerList(json));
    } catch (err) {
      const message = err.statusText || `Unable to get the list of servers`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createServer(name) {
  return async (dispatch, getState) => {
    const body = { name };

    dispatch(requestCreateServer());
    try {
      const newServer = await fetchWithAuth(
        dispatch,
        "/servers",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(body)
      );
      dispatch(receiveCreateServer(newServer));
      dispatch(fetchServerList());
    } catch (err) {
      if (err.subErrors) { dispatch(receiveInvalidServerList(err)) }
      else { dispatch(setAlert(err.message, "danger")) }
    }
  };
}

export function updateServer(id, name) {
  return async (dispatch, getState) => {
    const body = {
      id,
      name,
    };
    dispatch(requestUpdateServer());
    try {
      const updatedServer = await fetchWithAuth(
        dispatch,
        `/servers/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(body)
      );
      dispatch(receiveUpdateServer(updatedServer));
      dispatch(fetchServerList());
    } catch (resp) {
      dispatch(setAlert(resp.message, "danger"));
    }
  };
}

export function deleteServer(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteServer(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/servers/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(fetchServerList());
      dispatch(receiveDeleteServer(id));
    } catch (resp) {
      dispatch(setAlert(resp.message, "danger"));
    }
  };
}
