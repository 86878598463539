import { Spinner } from "reactstrap";
import SourceReview from "../archiwareArchiveRestore/SourceReview";

export default function IconikActionReview({
  isFetching,
  activeVolumes,
  totalNumberOfFolders,
  validNumberOfFolders,
  totalSizeOfFolders,
  validSizeOfFolders,
  isDirectory,
  collectionTitle,
  storageName
}) {
  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-2">
          <SourceReview
            fileOrFolder={isDirectory ? "folder" : "file"}
            fileType="On-Premises"
            activeVolumes={activeVolumes}
            totalNumberOfFilesOrFolders={totalNumberOfFolders}
            numberOfValidFileOrFolders={validNumberOfFolders}
            totalFileSize={totalSizeOfFolders}
            validTotalFileSize={validSizeOfFolders}
          >
            <span>Collection: <strong>{collectionTitle}</strong></span><br />
            <span>Storage: <strong>{storageName}</strong></span>
          </SourceReview>
        </div>
      )}
    </>
  );
}
