import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setAlert } from "../../actions/alert";
import { searchFiles, getSizeOfSearchedFiles } from "../../api/file";
import { searchDirectories } from "../../api/directory";
import SodaActionReview from "./SodaActionReview";

export default function SodaSearchReview(props) {
  const {
    sodaActionObj,
    setDisableForwardActionButton,
    searchText = "",
    sodaVolumeMappings,
    isDirectory
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFolders, setTotalNumberOfFolders] = useState(0);
  const [validNumberOfFolders, setValidNumberOfFolders] = useState(0);
  const [totalSizeOfFolders, setTotalSizeOfFolders] = useState(0);
  const [validSizeOfFolders, setValidSizeOfFolders] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const dispatch = useDispatch();

  const volumes = useSelector(({ volumes }) => volumes.items);
  const search = useSelector(({ search }) => search);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [sodaVolumeMappings]);

  const getListOfValidVolumeIds = (searchVolumeIds) => {
    if (searchVolumeIds) {
      return searchVolumeIds.filter((id) =>
        sodaVolumeMappings.some((mapping) => mapping.volumeId === id)
      );
    }
    return sodaVolumeMappings.map((mapping) => mapping.volumeId);
  };

  const calculateReviewValues = async () => {
    const validVolumeIds = getListOfValidVolumeIds(search.filters.volumeIds);
    setActiveVolumes(validVolumeIds);

    if (!validVolumeIds.length) {
      setDisableForwardActionButton(true);
      setValidNumberOfFolders(0);
      setValidSizeOfFolders(0);
      setIsFetching(false);
      return;
    }

    const searchFilters = Object.assign({}, search.filters);
    searchFilters.volumeIds = validVolumeIds;

    try {
      const searchSizeForValidFolders = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        searchFilters
      );

      const fileSearchResult = await searchFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const folderSearchResult = await searchDirectories(
        dispatch,
        auth,
        indexingDate,
        searchText,
        0,
        0,
        searchFilters,
        "size",
        "desc"
      );

      const totalSearchSize = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        search.filters
      );

      setTotalNumberOfFolders(
        isDirectory ? search.folders.total : search.total
      );
      setValidNumberOfFolders(
        isDirectory ? folderSearchResult.total : fileSearchResult.total
      );
      setTotalSizeOfFolders(totalSearchSize);
      setValidSizeOfFolders(searchSizeForValidFolders);

      if (isDirectory) {
        if (folderSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      } else {
        if (fileSearchResult.total > 0) {
          setDisableForwardActionButton(false);
        }
      }
    } catch (e) {
      dispatch(setAlert(e.message, "danger", 7000));
    }

    setIsFetching(false);
  };

  return (
    <SodaActionReview
      sodaActionObj={sodaActionObj}
      volumes={volumes}
      isFetching={isFetching}
      activeVolumes={activeVolumes.map(
        (id) => volumes.find((volume) => volume.id === id).name
      )}
      totalNumberOfFolders={totalNumberOfFolders}
      validNumberOfFolders={validNumberOfFolders}
      totalSizeOfFolders={totalSizeOfFolders}
      validSizeOfFolders={validSizeOfFolders}
      isDirectory={isDirectory}
    />
  );
}
