import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableResult from "../../../components/TableResult";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  getAllNotifications,
  getNotificableJobTypes,
  createJobNotification,
  editJobNotification,
  deleteJobNotification,
} from "../../../api/jobNotification";
import ConfirmModal from "../../../components/ConfirmModal";
import NotificationModal from "./NotificationModal";
import { setAlert } from "../../../actions/alert";
import { Spinner } from "reactstrap";

const jobNotificationType = require("../../../constants/jobNotificationType.json");
const jobNotificationStatus = require("../../../constants/jobNotificationStatus.json");

const emptyNotificationObj = {
  id: "",
  type: "",
  status: "ALWAYS",
};

const NOTIFICATION_MODAL_TYPE = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
};

export default function NotificationConfiguration() {
  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [notificationObj, setNotificationObj] = useState(emptyNotificationObj);
  const [notificableJobTypes, setNotificableJobTypes] = useState([]);

  const listFormated = notifications.map((notification) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };

    lineObject.columnData = [
      jobNotificationType[notification.type] ?? notification.type,
      jobNotificationStatus[notification.status],
      <>
        <Button
          color="secondary"
          style={{ marginRight: 10 }}
          onClick={() => {
            setShowModal(true);
            setNotificationObj({
              id: notification.id,
              type: notification.type,
              status: notification.status,
            });
            setSelectedModalType(NOTIFICATION_MODAL_TYPE.EDIT);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          color="danger"
          onClick={() => {
            setShowModal(true);
            setNotificationObj({
              id: notification.id,
              type: notification.type,
              status: notification.status,
            });
            setSelectedModalType(NOTIFICATION_MODAL_TYPE.DELETE);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      <Button
        onClick={() => {
          setShowModal(true);
          setSelectedModalType(NOTIFICATION_MODAL_TYPE.CREATE);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  const fetchNotifications = async () => {
    try {
      const notifications = await getAllNotifications(dispatch, auth);
      setNotifications(notifications);
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchNotificableJobTypes = async () => {
    try {
      const notificableJobTypes = await getNotificableJobTypes(dispatch, auth);
      setNotificableJobTypes(notificableJobTypes);
    } catch (e) {
      console.log(e.message);
    }
  };

  const confirmCreate = async () => {
    setIsFetching(true);

    const createObjCopy = { ...notificationObj };

    if (createObjCopy.type === "") {
      createObjCopy.type = notificableJobTypes[0];
    }

    try {
      await createJobNotification(
        dispatch,
        auth.accessToken,
        "POST",
        JSON.stringify(createObjCopy)
      );
      dispatch(setAlert("The job notification has been created", "success"));
    } catch (e) {
      dispatch(setAlert(e.message, "danger"));
    }

    fetchNotifications();

    closeConfirmCreate();
    setIsFetching(false);
  };

  const confirmEdit = async () => {
    setIsFetching(true);

    try {
      await editJobNotification(
        dispatch,
        auth.accessToken,
        "PUT",
        notificationObj.id,
        JSON.stringify({
          type: notificationObj.type,
          status: notificationObj.status,
        })
      );
      dispatch(setAlert("The job notification has been updated", "success"));
    } catch (e) {
      dispatch(setAlert(e.message, "danger"));
    }

    fetchNotifications();

    closeConfirmEditOrDelete();
    setIsFetching(false);
  };

  const confirmDelete = async () => {
    setIsFetching(true);

    try {
      await deleteJobNotification(
        dispatch,
        auth.accessToken,
        notificationObj.id,
        "DELETE"
      );
      dispatch(setAlert("The job notification has been deleted", "success"));
    } catch (e) {
      dispatch(setAlert(e.message, "danger"));
    }

    fetchNotifications();

    closeConfirmEditOrDelete();
    setIsFetching(false);
  };

  const closeConfirmCreate = () => {
    setNotificationObj(emptyNotificationObj);
    setShowModal(false);
  };

  const closeConfirmEditOrDelete = () => {
    setNotificationObj(emptyNotificationObj);
    setShowModal(false);
  };

  const handleNotificationModal = () => {
    switch (selectedModalType) {
      case "edit":
        return (
          <ConfirmModal
            showConfirmation={showModal}
            title={"Edit a Job Notification"}
            buttonColor={"primary"}
            buttonText={isFetching ? <Spinner /> : "Update"}
            confirmAction={confirmEdit}
            closeConfirmation={closeConfirmEditOrDelete}
            disableRightButton={isFetching}
          >
            <NotificationModal
              notificationObj={notificationObj}
              setNotificationObj={setNotificationObj}
              notificableJobTypes={notificableJobTypes}
            />
          </ConfirmModal>
        );

      case "delete":
        return (
          <ConfirmModal
            showConfirmation={showModal}
            title={"Delete Confirmation"}
            buttonColor={"danger"}
            buttonText={isFetching ? <Spinner /> : "Delete"}
            confirmAction={confirmDelete}
            closeConfirmation={closeConfirmEditOrDelete}
            disableRightButton={isFetching}
          >
            <span>
              Are you sure you want to delete the notification of type&nbsp;
              <strong>
                {jobNotificationType[notificationObj.type] ??
                  notificationObj.type}
              </strong>
              &nbsp; with status{" "}
              <strong>
                {notificationObj.status &&
                  jobNotificationStatus[notificationObj.status]}
              </strong>
              ?
            </span>
          </ConfirmModal>
        );

      default:
        return (
          <ConfirmModal
            showConfirmation={showModal}
            title={"Add a Job Notification"}
            buttonColor={"primary"}
            buttonText={isFetching ? <Spinner /> : "Add"}
            confirmAction={confirmCreate}
            closeConfirmation={closeConfirmCreate}
            disableRightButton={isFetching}
          >
            <NotificationModal
              notificationObj={notificationObj}
              setNotificationObj={setNotificationObj}
              notificableJobTypes={notificableJobTypes}
            />
          </ConfirmModal>
        );
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchNotificableJobTypes();
  }, []);

  return (
    <>
      <h3>Job Notifications</h3>
      <p>
        Receive email notifications when a specific job type ends.
        <br />
        <span style={{ color: "gray" }}>
          For example, an On-Premises scan that has failed.
        </span>
      </p>

      <TableResult
        titles={[{ name: "Type" }, { name: "Status" }, { name: "Actions" }]}
        data={listFormated}
      />

      {handleNotificationModal()}
    </>
  );
}
