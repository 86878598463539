import { useState, useEffect } from "react";
import {
  Input,
  FormGroup,
  Label,
  Col,
  Button,
  InputGroup
} from "reactstrap";
import NewFolderTreeWrapperPathPicker from "../folderTree/FolderTreeWrapperPathPicker";
import { getSodaVolumeMappings } from "../../api/soda";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { setAlert } from "../../../src/actions/alert";

const CONFLICT_OPTIONS = [
  {
    trueValue: "OVERWRITE",
    viewValue: "Overwrite Target File",
  },
  {
    trueValue: "RENAME",
    viewValue: "Rename Transferred File",
  },
  {
    trueValue: "SKIP",
    viewValue: "Skip Source File",
  },
];

export default function TargetSodaActionStep({
  sodaActionObj,
  setSodaActionObj,
  setDisableForwardActionButton
}) {
  const [isPathPickerOpen, setIsPathPickerOpen] = useState(false);
  const [volumeMappings, setVolumeMappings] = useState([]);
  const [checkedPath, setCheckedPath] = useState("");
  const [checkedVolume, setCheckedVolume] = useState({});

  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings.items);

  useEffect(() => {
    fetchSodaVolumeMappings();
    setDisableForwardActionButton(sodaActionObj.target.path === "");
  }, []);

  useEffect(() => {
    if (checkedVolume.id) {
      setSodaActionObj((prev) => ({
        ...prev,
        target: {
          ...prev.target,
          path: checkedPath,
          volume: parseInt(checkedVolume.id),
          volumeName: checkedVolume.name,
        },
      }));
      setIsPathPickerOpen(false);
      setDisableForwardActionButton(false);
    }
  }, [checkedPath, checkedVolume]);

  useEffect(() => {
    setDisableForwardActionButton(!(sodaActionObj.target.path.length > 0 && sodaActionObj.target.volume));
  }, [sodaActionObj.target.path, sodaActionObj.target.volume]);

  const getAvailableTargets = (volumeMappings) => {
    return volumeMappings.map((volumeMapping) => volumeMapping.volumeId);
  };

  const getVolumeName = (id) => {
    return volumeSettings.find(vol => vol.id === id)?.name;
  };

  const fetchSodaVolumeMappings = async () => {
    try {
      const result = await getSodaVolumeMappings(dispatch, auth);

      setVolumeMappings(result.elements);
    } catch (err) {
      const message =
        err.message || "Unable to get the list of Soda path configurations";
      dispatch(setAlert(message, "danger"));
    }
  };

  return (
    <>
      <FormGroup row>
        <Label for="name" sm={3}>
          Job alias (optional)
        </Label>
        <Col>
          <Input
            id="actionJobAlias"
            name="actionJobAlias"
            type="input"
            value={sodaActionObj.jobAlias}
            onChange={(event) => {
              setSodaActionObj((prev) => ({
                ...prev,
                jobAlias: event.target.value,
              }));
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="name" sm={3}>
          Conflict Handling
        </Label>
        <Col>
          <Input
            id="actionConflictHandling"
            name="actionConflictHandling"
            type="select"
            value={sodaActionObj.target.conflictHandling}
            onChange={(event) => {
              setSodaActionObj((prev) => ({
                ...prev,
                target: {
                  ...prev.target,
                  conflictHandling: event.target.value,
                },
              }));
            }}
          >
            {CONFLICT_OPTIONS.map((conflictMethod, index) => (
              <option key={index} value={conflictMethod.trueValue}>
                {conflictMethod.viewValue}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="volume" sm={3}>
          Target Storage
        </Label>
        <Col sm={9}>
          <Input
            id="actionVolume"
            type="select"
            name="actionVolume"
            value={sodaActionObj.target.volume}
            onChange={(e) => {
              const value = e.target.value === "" ? "" : parseInt(e.target.value);

              setSodaActionObj((prev) => ({
                ...prev,
                target: {
                  ...prev.target,
                  path: value === "" ? "" : "/",
                  volume: value,
                  volumeName: value === "" ? value : getVolumeName(value)
              }}));
            }
          }
          >
            <option value={""}>Select Target Storage</option>
            {getAvailableTargets(volumeMappings).map((id, index) => (
              <option key={id + "-" + index} value={id}>
                {getVolumeName(id)}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="name" sm={3}>
          Target Path
        </Label>
        <Col sm={9}>
          <InputGroup>
            <Input
              id="actionTargetPath"
              type="text"
              name="actionTargetPath"
              value={sodaActionObj.target.path}
              onChange={(e) => setSodaActionObj((prev) => ({
                ...prev,
                target: {
                  ...prev.target,
                  path: e.target.value
              }}))}
            />
            <Button onClick={() => setIsPathPickerOpen(true)}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </Button>
          </InputGroup>
        </Col>
      </FormGroup>

      <NewFolderTreeWrapperPathPicker
        showCheckbox={false}
        isOpen={isPathPickerOpen}
        setIsOpen={setIsPathPickerOpen}
        volumeIds={getAvailableTargets(volumeMappings)}
        setCheckedPath={setCheckedPath}
        setCheckedVolume={setCheckedVolume}
        modalTitle="Select target folder"
      />
    </>
  );
}
