import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_ARCHIWARE_CREDENTIAL =
  "REQUEST_CREATE_ARCHIWARE_CREDENTIAL";
const RECEIVE_CREATE_ARCHIWARE_CREDENTIAL =
  "RECEIVE_CREATE_ARCHIWARE_CREDENTIAL";
// READ
const REQUEST_ARCHIWARE_CREDENTIAL_LIST = "REQUEST_ARCHIWARE_CREDENTIAL_LIST";
const RECEIVE_ARCHIWARE_CREDENTIAL_LIST = "RECEIVE_ARCHIWARE_CREDENTIAL_LIST";
// UPDATE
const REQUEST_UPDATE_ARCHIWARE_CREDENTIAL =
  "REQUEST_UPDATE_ARCHIWARE_CREDENTIAL";
const RECEIVE_UPDATE_ARCHIWARE_CREDENTIAL =
  "RECEIVE_UPDATE_ARCHIWARE_CREDENTIAL";
// DELETE
const REQUEST_DELETE_ARCHIWARE_CREDENTIAL =
  "REQUEST_DELETE_ARCHIWARE_CREDENTIAL";
const RECEIVE_DELETE_ARCHIWARE_CREDENTIAL =
  "RECEIVE_DELETE_ARCHIWARE_CREDENTIAL";
// ERRORS
const RECEIVE_INVALID_ARCHIWARE_CREDENTIAL =
  "RECEIVE_INVALID_ARCHIWARE_CREDENTIAL";

// === Reducers ===
export default function archiwareCredentials(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: true,
  },
  action
) {
  switch (action.type) {
    case REQUEST_ARCHIWARE_CREDENTIAL_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ARCHIWARE_CREDENTIAL_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.archiwareCredentials.elements,
        page: action.archiwareCredentials.page,
        total: action.archiwareCredentials.total,
        size: action.archiwareCredentials.size,
        sort: action.archiwareCredentials.sort,
        direction: action.archiwareCredentials.direction,
        errors: {},
        formValid: true,
      });
    case RECEIVE_INVALID_ARCHIWARE_CREDENTIAL:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false,
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestArchiwareCredentialList() {
  return {
    type: REQUEST_ARCHIWARE_CREDENTIAL_LIST,
  };
}

function receiveArchiwareCredentialList(archiwareCredentials) {
  return {
    type: RECEIVE_ARCHIWARE_CREDENTIAL_LIST,
    archiwareCredentials,
  };
}

function receiveInvalidArchiwareCredential(errors) {
  return {
    type: RECEIVE_INVALID_ARCHIWARE_CREDENTIAL,
    errors,
  };
}

function requestCreateArchiwareCredential() {
  return {
    type: REQUEST_CREATE_ARCHIWARE_CREDENTIAL,
  };
}

function receiveCreateArchiwareCredential(createdArchiwareCredential) {
  return {
    type: RECEIVE_CREATE_ARCHIWARE_CREDENTIAL,
    archiwareCredential: createdArchiwareCredential,
  };
}

function requestUpdateArchiwareCredential() {
  return {
    type: REQUEST_UPDATE_ARCHIWARE_CREDENTIAL,
  };
}

function receiveUpdateArchiwareCredential(updatedArchiwareCredential) {
  return {
    type: RECEIVE_UPDATE_ARCHIWARE_CREDENTIAL,
    archiwareCredential: updatedArchiwareCredential,
  };
}

function requestDeleteArchiwareCredential(id) {
  return {
    type: REQUEST_DELETE_ARCHIWARE_CREDENTIAL,
    id,
  };
}

function receiveDeleteArchiwareCredential(id) {
  return {
    type: RECEIVE_DELETE_ARCHIWARE_CREDENTIAL,
    id,
  };
}

// === Side Effects ===
export function fetchArchiwareCredentialList(
  page = 1,
  sort = "name",
  direction = "asc",
  size = window.localStorage.getItem("numberOfElementsDataSourceArchiwareCreds")
    ? Number(
        window.localStorage.getItem("numberOfElementsDataSourceArchiwareCreds")
      )
    : 10
) {
  return async (dispatch, getState) => {
    window.localStorage.setItem(
      "numberOfElementsDataSourceArchiwareCreds",
      size
    );
    dispatch(requestArchiwareCredentialList());
    const url = `/v1/archiwares/credentials?page=${page}&sort=${sort},${direction}&size=${size}`;
    try {
      const json = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      dispatch(receiveArchiwareCredentialList(json));
    } catch (err) {
      console.log(err);
      const message =
        err.statusText || `Unable to get the list of archiware credentials`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createArchiwareCredential(archiwareCredential) {
  return async (dispatch, getState) => {
    dispatch(requestCreateArchiwareCredential());
    try {
      const newArchiwareCredential = await fetchWithAuth(
        dispatch,
        "/v1/archiwares/credentials",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(archiwareCredential)
      );
      dispatch(receiveCreateArchiwareCredential(newArchiwareCredential));
      dispatch(
        fetchArchiwareCredentialList(
          1,
          getState().archiwareCredentials.sort,
          getState().archiwareCredentials.direction,
          getState().archiwareCredentials.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidArchiwareCredential(err));
      else {
        const message = err.message || "Unable to create archiware credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateArchiwareCredential(id, archiwareCredential) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateArchiwareCredential());
    try {
      const updatedArchiwareCredential = await fetchWithAuth(
        dispatch,
        `/v1/archiwares/credentials/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(archiwareCredential)
      );
      dispatch(receiveUpdateArchiwareCredential(updatedArchiwareCredential));
      dispatch(
        fetchArchiwareCredentialList(
          1,
          getState().archiwareCredentials.sort,
          getState().archiwareCredentials.direction,
          getState().archiwareCredentials.size
        )
      );
    } catch (err) {
      console.log(err);
      if (err.subErrors) dispatch(receiveInvalidArchiwareCredential(err));
      else {
        const message = err.message || "Unable to update archiware credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function deleteArchiwareCredential(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteArchiwareCredential(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/archiwares/credentials/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(
        fetchArchiwareCredentialList(
          1,
          getState().archiwareCredentials.sort,
          getState().archiwareCredentials.direction,
          getState().archiwareCredentials.size
        )
      );
      dispatch(receiveDeleteArchiwareCredential(id));
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}
