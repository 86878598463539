import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { getKeys, getFilterValues } from "./SearchFilterKeys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { DATE_FORMAT } from "../../constants/dateFormatting";
import { generateUniqueId } from "../../utils/generateRandomValue";
import { useSelector } from "react-redux";
import { formatSize, getSymbolFromBase } from "../FormatSize";
import xbytes from "xbytes";
import SearchMultiSelect from "../../search/components/SearchMultiSelect";

const SIDE_BARS_STYLE = {
  border: "1px solid rgba(0,0,0,0.1)",
  width: "35%",
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: 30,
  marginLeft: 30,
};

export default function SearchFilter(props) {
  const {
    searchQueryId,
    operator,
    keyInput,
    filter,
    input,
    isExcluded = false,
    setUpdatedSearchQueryNodes,
    removeSearchFilter,
  } = props;

  const [keyValue, setKeyValue] = useState(keyInput);
  const [keys, setKeys] = useState({});
  const [filterValue, setFilterValue] = useState(filter);
  const [filterType, setFilterType] = useState("String");
  const [inputValue, setInputValue] = useState(input);
  const [isExcludeFilter, setIsExcludeFilter] = useState(isExcluded);
  const [unit, setUnit] = useState("B");
  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [checkboxStatus, setCheckboxStatus] = useState({});

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const volumes = useSelector(({ volumes }) => volumes.items);

  // Initial setup of values for the inputs
  useEffect(() => {
    const fetchedKeys = getKeys();

    setKeys(fetchedKeys);
    setKeyValue(keyInput);
    setIsExcludeFilter(isExcluded);

    if (keyInput === "size") {
      const convertedSize = formatSize(
        parseInt(input) ? parseInt(input) : 0,
        baseSize,
        null,
        "object"
      );

      setInputValue(String(convertedSize.value));
      setUnit(convertedSize.unit);
    } else {
      setInputValue(input);
    }
  }, []);

  useEffect(() => {
    if (keyInput === "volumeId" && volumes.length > 0) {
      setSelectedDataSources(
        input.map((i) => {
          const vol = volumes.find((v) => v.id === Number(i));

          if (vol !== undefined) {
            return {
              value: vol.id,
              label: vol.name,
              dataType:
                vol.type === "crawl.files"
                  ? "volume"
                  : vol.type === "AWS-S3"
                  ? "bucket"
                  : "archive",
            };
          }

          return { value: i, label: `${i}  Invalid`, invalid: true };
        })
      );
    }
  }, [volumes.length]);

  // Initial setup of values for the inputs + change filterValue and filterType on keyInput change
  useEffect(() => {
    const fetchedFilterType = keys[keyInput] ? keys[keyInput].type : "String";
    const filterValues = getFilterValues(fetchedFilterType);

    setFilterValue(
      filterValues[filter] === undefined ? Object.keys(filterValues)[0] : filter
    );
    setFilterType(fetchedFilterType);
  }, [keyInput, keys]);

  useEffect(() => {
    if (filterType === "Boolean") {
      setUpdatedSearchQueryNodes({
        id: searchQueryId,
        [keyValue]: typeof inputValue === "string" ? false : inputValue,
        excludeFilter: isExcludeFilter,
      });
    } else if (keyValue === "size") {
      setUpdatedSearchQueryNodes({
        id: searchQueryId,
        [keyValue]: { [filterValue]: getSizeInBytes() },
        excludeFilter: isExcludeFilter,
      });
    } else {
      setUpdatedSearchQueryNodes({
        id: searchQueryId,
        [keyValue]: { [filterValue]: inputValue },
        excludeFilter: isExcludeFilter,
      });
    }
  }, [keyValue, filterValue, inputValue, isExcludeFilter, unit]);

  const getSizeInBytes = () =>
    unit === "B"
      ? inputValue
      : String(xbytes.parseSize(`${inputValue} ${unit}`));

  const keyOptions = Object.entries(keys).map(([key, value]) => ({
    value: key,
    label: value.label,
  }));

  const filterOptions = Object.entries(getFilterValues(filterType)).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  );

  const unitOptions = [
    { value: getSymbolFromBase("B", baseSize), label: "Bytes" },
    {
      value: getSymbolFromBase("KB", baseSize),
      label: getSymbolFromBase("KB", baseSize),
    },
    {
      value: getSymbolFromBase("MB", baseSize),
      label: getSymbolFromBase("MB", baseSize),
    },
    {
      value: getSymbolFromBase("GB", baseSize),
      label: getSymbolFromBase("GB", baseSize),
    },
    {
      value: getSymbolFromBase("TB", baseSize),
      label: getSymbolFromBase("TB", baseSize),
    },
    {
      value: getSymbolFromBase("PB", baseSize),
      label: getSymbolFromBase("PB", baseSize),
    },
  ];

  const getDateInput = () => {
    return (
      <DatePicker
        wrapperClassName="datePicker"
        className="form-control"
        type="text"
        selected={
          !isNaN(new Date(inputValue)) ? Date.parse(new Date(inputValue)) : null
        }
        onChange={(date) =>
          setInputValue(date === null || date === "" ? "" : Date.parse(date))
        }
        dateFormat={DATE_FORMAT}
        popperPlacement="auto-start"
        isClearable
      />
    );
  };

  const getInput = () => {
    switch (filterType) {
      case "Date":
        return (
          <Col sm={5} style={{ display: "flex", alignItems: "center" }}>
            {getDateInput()}
          </Col>
        );
      case "Boolean":
        return (
          <Col sm={7} style={{ display: "flex", alignItems: "center" }}>
            <FormGroup switch>
              <Input
                id={generateUniqueId()}
                type="switch"
                onChange={(e) => setInputValue(e.target.checked)}
                checked={typeof inputValue === "string" ? false : inputValue}
              />
            </FormGroup>
          </Col>
        );
      case "Long":
        if (keyValue === "size") {
          return (
            <>
              <Col sm={3} style={{ display: "flex", alignItems: "center" }}>
                <Input
                  type="number"
                  min={0}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </Col>
              <Col sm={2}>
                <Select
                  value={[
                    {
                      value: unit,
                      label:
                        unit === "B" ? "Bytes" : unit === "kB" ? "KB" : unit,
                    },
                  ]}
                  options={unitOptions}
                  onChange={(e) => {
                    const value = e.value;
                    setUnit(value);
                  }}
                />
              </Col>
            </>
          );
        }
      case "Integer":
        return (
          <Col sm={5} style={{ display: "flex", alignItems: "center" }}>
            <Input
              type="number"
              min={0}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Col>
        );
      case "List":
        if (keyValue === "volumeId") {
          return (
            <Col sm={7}>
              <SearchMultiSelect
                setVolumeIds={(volumeIds) => setInputValue(volumeIds)}
                selectedDataSources={selectedDataSources}
                setSelectedDataSources={(dataSources) =>
                  setSelectedDataSources(dataSources)
                }
                checkboxStatus={checkboxStatus}
                setCheckboxStatus={(checkboxes) =>
                  setCheckboxStatus(checkboxes)
                }
                hasLabel={false}
              />
            </Col>
          );
        }
      case "String":
      case "Other":
      default:
        return (
          <Col sm={5} style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Col>
        );
    }
  };

  return (
    <>
      <Row>
        <Col sm={3}>
          <Select
            value={[
              {
                value: keyValue,
                label: keys[keyValue]?.label,
              },
            ]}
            options={keyOptions}
            onChange={(e) => {
              const value = e.value;

              setKeyValue(value);
              setFilterType(keys[value].type);
            }}
          />
        </Col>
        {filterType !== "Boolean" && keyValue !== "volumeId" && (
          <Col sm={2}>
            <Select
              value={[
                {
                  value: filterValue,
                  label: getFilterValues(filterType)[filterValue],
                },
              ]}
              options={filterOptions}
              onChange={(e) => setFilterValue(e.value)}
            />
          </Col>
        )}
        {getInput()}
        <Col sm={1} style={{ display: "flex", alignItems: "center" }}>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={isExcludeFilter}
                onChange={(e) => setIsExcludeFilter(e.target.checked)}
              />
              Exclude
            </Label>
          </FormGroup>
        </Col>
        {!!removeSearchFilter && (
          <Col sm={1} style={{ display: "flex", alignItems: "center" }}>
            <Button
              size="sm"
              color="danger"
              outline
              onClick={() => removeSearchFilter()}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </Col>
        )}
      </Row>
      {operator && (
        <span
          className="my-3"
          style={{
            width: "100%",
            textAlign: "center",
            display: "inline-block",
          }}
        >
          <span style={SIDE_BARS_STYLE} />
          {operator}
          <span style={SIDE_BARS_STYLE} />
        </span>
      )}
    </>
  );
}
