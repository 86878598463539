import { faFolder, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { getVolumeIcon } from "../../utils/getVolumeIcon";
import React from "react";

export default function ParameterList(props) {
  const { references, comparison, reportType, searchQueryNode } = props;

  const volumes = useSelector(({ volumes }) => volumes.items);
  const projects = useSelector(({ projectList }) => projectList.items);

  const getParameterForVolume = (element) => {
    const volumeName = volumes.find((volume) => volume.id === element.id)?.name;
    const volumeType = volumes.find((volume) => volume.id === element.id)?.type;

    return (
      <React.Fragment key={`${element.path}-${element.id}`}>
        <span className="ms-2">
          <FontAwesomeIcon
            className="mx-2"
            color={volumeName ? "#007bff" : "red"}
            icon={getVolumeIcon(volumeType)}
          />
          {volumeName ?? "Invalid"}
          <FontAwesomeIcon
            className="mx-2"
            color={volumeName ? "#007bff" : "red"}
            icon={faFolder}
          />
          {element.path}
        </span>
        <br />
      </React.Fragment>
    );
  };

  const getParameterForProject = (element) => {
    const projectName = projects.find(
      (volume) => volume.id === element.id
    )?.name;

    return (
      <React.Fragment key={`${projectName}-${element.id}`}>
        <span className="ms-2">
          <FontAwesomeIcon
            className="mx-2"
            color={projectName ? "#007bff" : "red"}
            icon={faSitemap}
          />
          {projectName ?? "Invalid"}
        </span>
        <br />
      </React.Fragment>
    );
  };

  const getParameterList = (parameter) => {
    return parameter.map((element) =>
      element.type === "VOLUME"
        ? getParameterForVolume(element)
        : getParameterForProject(element)
    );
  };

  const getComparisonDescription = () => {
    if (reportType === "DUPLICATED") {
      return "With a copy on any of: ";
    } else if (reportType === "UNIQUE") {
      return "That do not have a copy on any of: ";
    } else {
      return "Compared to: ";
    }
  };

  return (
    <>
      {reportType !== "SEARCH_QUERY" ? (
        <span>
          Tag files and folders from: <br />
          {getParameterList(references)} <br />
          {getComparisonDescription()}
          <br />
          {getParameterList(comparison)}
        </span>
      ) : (
        <>
          {searchQueryNode ? (
            <span>
              Tag files and folders that match this search query:
              <div
                style={{
                  height: "220px",
                  overflow: "scroll",
                  background: "#f2f2f2",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  marginTop: "10px",
                  display: "grid",
                }}
              >
                <pre className="mt-2 ms-2">
                  {JSON.stringify(searchQueryNode, null, 2)}
                </pre>
              </div>
            </span>
          ) : (
            "None"
          )}
        </>
      )}
    </>
  );
}
