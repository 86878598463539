import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

ArchiwareVolumeMappingForm.propTypes = {
  onPremisesStorage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataIntellPath: PropTypes.string,
  volumes: PropTypes.array,
  volumeMappings: PropTypes.array,
  showAllFields: PropTypes.bool,
  isUpdate: PropTypes.bool,
  archiwareP5Path: PropTypes.string,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
};

function ArchiwareVolumeMappingForm(props) {
  const {
    volumes = [],
    volumeMappings = [],
    showAllFields = true,
    onPremisesStorage,
    dataIntellPath,
    archiwareP5Path,
    handleChange,
    isUpdate,
    errors,
    setDisableCreateButton,
  } = props;

  const [filterVolumes, setFilterVolumes] = useState([...volumes]);

  const [isDataIntellPathTooltipOpen, setIsDataIntellPathTooltipOpen] =
    useState(false);
  const [isArchiwareP5PathTooltipOpen, setIsArchiwareP5PathTooltipOpen] =
    useState(false);

  const [localErrors, setLocalErrors] = useState({
    onPremisesStorage: "",
    dataIntellPath: "",
    archiwareP5Path: "",
  });

  useEffect(() => {
    if (
      onPremisesStorage.length === 0 ||
      dataIntellPath.length === 0 ||
      archiwareP5Path.length === 0
    ) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [
    onPremisesStorage,
    dataIntellPath,
    archiwareP5Path,
    setDisableCreateButton,
  ]);

  useEffect(() => {
    if (!isUpdate) {
      const volumeCopy = volumes.filter(
        (volume) =>
          !volumeMappings.some(
            (volumeMapping) => volumeMapping.volumeId === volume.id
          )
      );
      setFilterVolumes(volumeCopy);
    }
  }, [volumes, isUpdate, volumeMappings]);

  const handleLocalErrors = (name, value) => {
    switch (name) {
      case "onPremisesStorage":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            onPremisesStorage: "On-Premises Storage must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            onPremisesStorage: "",
          }));
        }
        break;
      case "dataIntellPath":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            dataIntellPath: "DataIntell Path must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            dataIntellPath: "",
          }));
        }
        break;
      case "archiwareP5Path":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            archiwareP5Path: "Archiware P5 Path must not be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            archiwareP5Path: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (value !== "" && target.name === "onPremisesStorage") {
      const volumeName = volumes.filter(
        (volume) => volume.id === parseInt(value)
      );
      handleChange("dataIntellPath", volumeName[0].path);
    }
    handleChange(name, value);
    handleLocalErrors(name, value);
  };

  return (
    <Form>
      <FormGroup row>
        <Label for="onPremisesStorage" sm={4}>
          On-Premises Storage
        </Label>
        <Col sm={8}>
          <Input
            id="onPremisesStorage"
            name="onPremisesStorage"
            type="select"
            disabled={isUpdate}
            value={onPremisesStorage}
            onChange={handleInputChange}
            invalid={
              errors["onPremisesStorage"] !== undefined ||
              Boolean(localErrors["onPremisesStorage"].length)
            }
          >
            <option value={""}>Select On-Premises Storage</option>
            {filterVolumes.map((volume) => {
              return (
                <option key={volume.id} value={volume.id}>
                  {volume.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>
            {errors["onPremisesStorage"]} {localErrors["onPremisesStorage"]}
          </FormFeedback>
        </Col>
      </FormGroup>
      <div hidden={!showAllFields}>
        <FormGroup row>
          <Label for="dataIntellPath" sm={4}>
            DataIntell Path
            <FontAwesomeIcon
              id="dataIntellPathLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isDataIntellPathTooltipOpen}
              target="dataIntellPathLabel"
              toggle={() =>
                setIsDataIntellPathTooltipOpen(!isDataIntellPathTooltipOpen)
              }
            >
              The mount path of the On-Premises storage for DataIntell scanner.
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="dataIntellPath"
              name="dataIntellPath"
              placeholder="Ex: /Volumes/NASX"
              type="text"
              value={dataIntellPath}
              onChange={handleInputChange}
              invalid={
                errors["dataIntellPath"] !== undefined ||
                Boolean(localErrors["dataIntellPath"].length)
              }
            />
            <FormFeedback>
              {errors["dataIntellPath"]} {localErrors["dataIntellPath"]}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="archiwareP5Path" sm={4} hidden={!showAllFields}>
            Archiware P5 Path
            <FontAwesomeIcon
              id="archiwareP5PathLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isArchiwareP5PathTooltipOpen}
              target="archiwareP5PathLabel"
              toggle={() =>
                setIsArchiwareP5PathTooltipOpen(!isArchiwareP5PathTooltipOpen)
              }
            >
              The mount path of the On-Premises storage for Archiware P5 server.
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="archiwareP5Path"
              name="archiwareP5Path"
              placeholder="Ex: /Volumes/NASX"
              type="text"
              value={archiwareP5Path}
              onChange={handleInputChange}
              invalid={
                errors["archiwareP5Path"] !== undefined ||
                Boolean(localErrors["archiwareP5Path"].length)
              }
            />
            <FormFeedback>
              {errors["archiwareP5Path"]} {localErrors["archiwareP5Path"]}
            </FormFeedback>
          </Col>
        </FormGroup>
      </div>
    </Form>
  );
}

export default ArchiwareVolumeMappingForm;
