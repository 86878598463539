import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Row,
  Col
} from "reactstrap";
import DateCard from "./DateCard";
import FormatSize from "../../components/FormatSize";
import DateLabelWithTooltip from "../../components/DateLabelWithTooltip";

export class FolderStatistics extends Component {
  render() {
    const { statistics } = this.props;

    return (
      <Card style={{ marginBottom: 15 }}>
        <CardHeader>
          <CardTitle tag="h5">Summary</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">Insights about the folder for all the indexing dates</CardSubtitle>
        </CardHeader>
        <CardBody>
        <Row>
          <Col sm="4" className="mb-3">
            <DateCard
              dateTitle={<DateLabelWithTooltip dateLabel={"lastAccess"} />}
              dateText={"last access date"}
              numberOfDifferentDates={statistics.numberOfDifferentLastAccessDates}
              oldestDate={statistics.oldestLastAccessDate}
            />
          </Col>
          <Col sm="4" className="mb-3">
            <DateCard
              dateTitle="Last Creation Date"
              dateText={"last creation date"}
              numberOfDifferentDates={statistics.numberOfDifferentCreationDates}
              oldestDate={statistics.oldestCreationDate}
            />
          </Col>
          <Col sm="4" className="mb-3">
            <DateCard
              dateTitle={<DateLabelWithTooltip dateLabel={"lastModified"} />}
              dateText={"last modified date"}
              numberOfDifferentDates={statistics.numberOfDifferentModifiedDates}
              oldestDate={statistics.oldestModifiedDate}
            />
          </Col>
          <Col sm="4" className="mb-3">
            <Card>
              <CardBody>
                <CardTitle>Size</CardTitle>
                <CardText>
                  The size of this folder changed{" "}
                  <strong>{statistics.numberOfDifferentSizes}</strong> times.
                </CardText>
                <CardText>
                  The smallest size of this folder was{" "}
                  {statistics.smallestSize && (
                    <strong><FormatSize>{statistics.smallestSize}</FormatSize></strong>
                  )}
                </CardText>
                <CardText>
                  The biggest size of this folder was{" "}
                  {statistics.biggestSize && (
                    <strong><FormatSize>{statistics.biggestSize}</FormatSize></strong>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4" className="mb-3">
            <Card>
              <CardBody>
                <CardTitle>Rights</CardTitle>
                <CardText>
                  This folder had{" "}
                  <strong>{statistics.numberOfDifferentOwners}</strong> differents
                  owners.
                </CardText>
                <CardText>
                  This folder was part of{" "}
                  <strong>{statistics.numberOfDifferentGroups}</strong> different
                  groups
                </CardText>
              </CardBody>
            </Card>
          </Col>
         </Row>
        </CardBody>
      </Card>
    );
  }
}

export default FolderStatistics;
