import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileCode, faFileArchive, faFilePdf, faFileImage, faFileAudio, faFileVideo, faFileExcel, faFileWord, faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

export default function FileIcon(props) {
  const {
    extension
  } = props;

  if (!extension) {
    return <FontAwesomeIcon className="me-2" icon={faFile} />
  }

  switch (extension.toLowerCase()) {
    case "js":
    case "jar":
    case "py":
    case "java":
    case "c":
    case "class":
    case "pl":
    case "bat":
    case "json":
    case "asp":
    case "sh":
    case "p":
    case "sql":
    case "cs":
    case "yaml":
    case "cc":
    case "docker":
    case "service":
    case "rpm":
      return <FontAwesomeIcon className="me-2" icon={faFileCode} />
    case "tar":
    case "tar.gz":
    case "zip":
    case "rar":
    case "gz":
    case "bz2":
    case "bz2":
      return <FontAwesomeIcon className="me-2" icon={faFileArchive} />
    case "pdf":
      return <FontAwesomeIcon color="#f40f02" className="me-2" icon={faFilePdf} />
    case "jpg":
    case "png":
    case "gif":
    case "webp":
    case "flif":
    case "cr2":
    case "tif":
    case "bmp":
    case "jxr":
    case "psd":
    case "exr":
    case "dpx":
    case "raw":
      return <FontAwesomeIcon className="me-2" icon={faFileImage} />
    case "mp3":
      return <FontAwesomeIcon className="me-2" icon={faFileAudio} />
    case "mov":
    case "mp4":
    case "m4v":
    case "mkv":
    case "avi":
    case "webm":
    case "mxf":
      return <FontAwesomeIcon className="me-2" icon={faFileVideo} />
    case "xlsx":
    case "xls":
      return <FontAwesomeIcon color="#217346" className="me-2" icon={faFileExcel} />
    case "docx":
    case "doc":
      return <FontAwesomeIcon color="#2b579a" className="me-2" icon={faFileWord} />
    case "pptx":
    case "ppt":
      return <FontAwesomeIcon color="#d24726" className="me-2" icon={faFilePowerpoint} />
    case "csv":
      return <FontAwesomeIcon className="me-2" icon={faFileCsv} />
    default:
      return <FontAwesomeIcon className="me-2" icon={faFile} />
  }

}
