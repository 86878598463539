import { useEffect, useState } from "react";
import SelectComparisonDataStep from "./SelectComparisonDataStep";
import ReportReviewStep from "./ReportReviewStep";
import {
  createReportTemplate,
  generateReport,
  updateReportTemplate,
} from "../api/report";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../actions/alert";
import WizardModal from "../components/WizardModal";
import { Spinner } from "reactstrap";
import ConfigureReportTemplateStep from "./ConfigureReportTemplateStep";
import SelectReferenceDataStep from "./SelectReferenceDataStep";
import { useNavigate } from "react-router-dom";
import { EMPTY_REPORT_TEMPLATE_OBJ } from "./Reports";
import SearchQueryStep from "./SearchQueryStep";
import OptionalTagsStep from "./OptionalTagsStep";

export default function CreateReportWizard(props) {
  const {
    showCreateReportWizard,
    fetchReports,
    closeWizard,
    reportTemplate,
    isUpdate = false,
  } = props;

  const [stepIndex, setStepIndex] = useState(0);
  const [reportTemplateObj, setReportTemplateObj] = useState(
    EMPTY_REPORT_TEMPLATE_OBJ
  );
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLaunchedAfterCreation, setIsLaunchedAfterCreation] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (reportTemplate) {
      setReportTemplateObj({
        ...reportTemplate,
        retention: reportTemplate.retention ?? 30,
      });
    } else {
      setReportTemplateObj(EMPTY_REPORT_TEMPLATE_OBJ);
    }
  }, [showCreateReportWizard]);

  const createReportTemplateFunc = () => {
    setIsFetching(true);
    setDisableForwardActionButton(true);

    createReportTemplate(dispatch, auth, reportTemplateObj)
      .then((res) => {
        if (isLaunchedAfterCreation) {
          generateReport(dispatch, auth, res.id)
            .then((jobRes) => {
              onWizardExit();
              fetchReports();
              dispatch(
                setAlert(
                  <>
                    A job for the report template {res.name} has been launched
                    successfully. You can monitor the job by clicking
                    <a
                      className="text-primary"
                      onClick={() => navigate(`/settings/jobs?id=${jobRes.id}`)}
                    >
                      {" "}
                      here
                    </a>
                  </>,
                  "success",
                  9000
                )
              );
            })
            .catch((err) => {
              console.error(err);
              dispatch(
                setAlert(
                  `An error occurred while launching the report template [${res.name}].`,
                  "danger",
                  8000
                )
              );
            });
        } else {
          onWizardExit();
          fetchReports();
          dispatch(
            setAlert(
              `Report template [${reportTemplateObj.name}] has been created successfully.`,
              "success"
            )
          );
        }
      })
      .catch((err) => {
        setIsFetching(false);
        setDisableForwardActionButton(false);
        console.error(err);
        dispatch(
          setAlert(
            `Something went wrong while trying to create the report template. ${
              err.message ? err.message + "." : ""
            }`,
            "danger",
            8000
          )
        );
      });
  };

  const updateReportTemplateFunc = () => {
    setIsFetching(true);
    setDisableForwardActionButton(true);

    updateReportTemplate(
      dispatch,
      auth,
      reportTemplateObj.id,
      reportTemplateObj
    )
      .then((res) => {
        if (isLaunchedAfterCreation) {
          generateReport(dispatch, auth, res.id)
            .then((jobRes) => {
              onWizardExit();
              fetchReports();
              dispatch(
                setAlert(
                  <>
                    A job for the report template {res.name} has been launched
                    successfully. You can monitor the job by clicking
                    <a
                      className="text-primary"
                      onClick={() => navigate(`/settings/jobs?id=${jobRes.id}`)}
                    >
                      {" "}
                      here
                    </a>
                  </>,
                  "success",
                  9000
                )
              );
            })
            .catch((err) => {
              console.error(err);
              dispatch(
                setAlert(
                  `An error occurred while launching the report template [${res.name}].`,
                  "danger",
                  8000
                )
              );
            });
        } else {
          onWizardExit();
          fetchReports();
          dispatch(
            setAlert(
              `Report template [${reportTemplateObj.name}] has been updated successfully.`,
              "success"
            )
          );
        }
      })
      .catch((err) => {
        setIsFetching(false);
        setDisableForwardActionButton(false);
        console.error(err);
        dispatch(
          setAlert(
            `Something went wrong while trying to create the report template. ${
              err.message ? err.message + "." : ""
            }`,
            "danger",
            8000
          )
        );
      });
  };

  const getTitles = () => {
    switch (reportTemplateObj.type) {
      case "DUPLICATED":
      case "UNIQUE":
        return [
          "Configure Report Template",
          "Select Reference Data",
          "Select Comparison Data",
          "Add Search Queries",
          "Add Optional Tags",
          "Review",
        ];

      default:
        return [
          "Configure Report Template",
          "Add Search Queries",
          "Add Optional Tags",
          "Review",
        ];
    }
  };

  const getSteps = () => {
    switch (reportTemplateObj.type) {
      case "DUPLICATED":
      case "UNIQUE":
        return [
          <ConfigureReportTemplateStep
            reportTemplateObj={reportTemplateObj}
            setReportTemplateObj={setReportTemplateObj}
            setDisableForwardActionButton={setDisableForwardActionButton}
            isUpdate={isUpdate}
          />,
          <SelectReferenceDataStep
            reportTemplateObj={reportTemplateObj}
            setReportTemplateObj={setReportTemplateObj}
            setDisableForwardActionButton={setDisableForwardActionButton}
          />,
          <SelectComparisonDataStep
            reportTemplateObj={reportTemplateObj}
            setReportTemplateObj={setReportTemplateObj}
            setDisableForwardActionButton={setDisableForwardActionButton}
          />,
          <SearchQueryStep
            searchQueryNodes={reportTemplateObj.parameters.searchQueryNode}
            setSearchQueryNodes={(updatedNodes) =>
              setReportTemplateObj((prev) => ({
                ...prev,
                parameters: {
                  ...prev.parameters,
                  searchQueryNode: updatedNodes,
                },
              }))
            }
            isOptional
          />,
          <OptionalTagsStep
            optionalTags={reportTemplateObj.tags}
            setOptionalTags={(updatedTags) =>
              setReportTemplateObj((prev) => ({ ...prev, tags: updatedTags }))
            }
          />,
          <ReportReviewStep
            reportTemplateObj={reportTemplateObj}
            isLaunchedAfterCreation={isLaunchedAfterCreation}
            setIsLaunchedAfterCreation={setIsLaunchedAfterCreation}
            isUpdate={isUpdate}
            searchQueryNodes={reportTemplateObj.parameters.searchQueryNode}
            tags={reportTemplateObj.tags}
          />,
        ];
      default:
        return [
          <ConfigureReportTemplateStep
            reportTemplateObj={reportTemplateObj}
            setReportTemplateObj={setReportTemplateObj}
            setDisableForwardActionButton={setDisableForwardActionButton}
            isUpdate={isUpdate}
          />,
          <SearchQueryStep
            searchQueryNodes={reportTemplateObj.parameters.searchQueryNode}
            setSearchQueryNodes={(updatedNodes) =>
              setReportTemplateObj((prev) => ({
                ...prev,
                parameters: {
                  ...prev.parameters,
                  searchQueryNode: updatedNodes,
                },
              }))
            }
          />,
          <OptionalTagsStep
            optionalTags={reportTemplateObj.tags}
            setOptionalTags={(updatedTags) =>
              setReportTemplateObj((prev) => ({ ...prev, tags: updatedTags }))
            }
          />,
          <ReportReviewStep
            reportTemplateObj={reportTemplateObj}
            isLaunchedAfterCreation={isLaunchedAfterCreation}
            setIsLaunchedAfterCreation={setIsLaunchedAfterCreation}
            isUpdate={isUpdate}
            searchQueryNodes={reportTemplateObj.parameters.searchQueryNode}
            tags={reportTemplateObj.tags}
          />,
        ];
    }
  };

  const onWizardExit = () => {
    setReportTemplateObj(EMPTY_REPORT_TEMPLATE_OBJ);
    setStepIndex(0);
    setIsFetching(false);
    setDisableForwardActionButton(false);
    setIsLaunchedAfterCreation(true);
    closeWizard();
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) {
          setStepIndex((prev) => --prev);
        }
        break;

      case "Next":
        if (stepIndex < getSteps().length - 1) {
          setStepIndex((prev) => ++prev);
        }

        break;

      case "Save":
        isUpdate ? updateReportTemplateFunc() : createReportTemplateFunc();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (stepIndex === getSteps().length - 1) {
      return "Save";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showCreateReportWizard}
      title={`${isUpdate ? "Update" : "Create"} Report Template`}
      subTitle={getTitles()[stepIndex]}
      onWizardExit={onWizardExit}
      stepsLength={getSteps().length}
      stepIndex={stepIndex + 1}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={isFetching ? <Spinner /> : handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      maxWidth="1200px"
      isCustomStep
    >
      {getSteps()[stepIndex]}
    </WizardModal>
  );
}
