import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../components/Loading";
import UserList from "./users/UserList";
import GroupList from "./users/GroupList";
import SubSection from "./components/SubSection";
import {
  fetchUserList,
  createUser,
  deleteUser,
  updateUser,
  resetPassword,
} from "../ducks/user";
import { fetchProjectList } from "../ducks/project";
import PropTypes from "prop-types";
import { fetchRoles } from "../ducks/role";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, UncontrolledTooltip } from "reactstrap";

Users.propTypes = {
  dispatch: PropTypes.func,
  userList: PropTypes.object,
  volumeSettings: PropTypes.object,
  projectList: PropTypes.object,
  userId: PropTypes.number,
  roles: PropTypes.object,
};

function Users(props) {
  const { dispatch, userList, userId, roles } = props;

  const [isCreateForm, setIsCreateForm] = useState(true);
  const [showWizard, setShowWizard] = useState(false);

  useEffect(() => {
    dispatch(fetchUserList());
    dispatch(fetchRoles());
    dispatch(fetchProjectList(1, "name", "asc", 200));
  }, [dispatch]);

  return (
    <>
      <div className="d-flex mb-2">
        <h1 className="page-header">User Settings</h1>
        <Button
          id="AddNewUserButton"
          onClick={() => {
            setIsCreateForm(true);
            setShowWizard(true);
          }}
          color="success"
          className="ms-auto h-75"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <UncontrolledTooltip placement="auto" target="AddNewUserButton">
          Add a new user
        </UncontrolledTooltip>
      </div>
      <SubSection
        title={"Users"}
        subTitle={
          "Create, edit or delete users. Use roles, groups and privileges to tailor access to your data."
        }
        isOpen={true}
      >
        <UserList
          userList={userList.items}
          groupList={roles.items}
          total={userList.total}
          currentUserId={userId}
          formValid={userList.formValid}
          errors={userList.errors}
          resetPasswordValid={userList.resetPasswordValid}
          resetPasswordErrors={userList.resetPasswordErrors}
          deleteUser={(id) => dispatch(deleteUser(id))}
          updateUserList={(page, sort, direction, size) =>
            dispatch(fetchUserList(page, sort, direction, size))
          }
          createUser={(name, username, email, password, roles) =>
            dispatch(createUser(name, username, email, password, roles))
          }
          updateUser={(id, name, username, email, roles) =>
            dispatch(updateUser(id, name, username, email, roles))
          }
          resetPassword={(id, newPassword, confirmPassword) =>
            dispatch(resetPassword(id, newPassword, confirmPassword))
          }
          isCreateForm={isCreateForm}
          setIsCreateForm={setIsCreateForm}
          showWizard={showWizard}
          setShowWizard={setShowWizard}
          isFetching={userList.isFetching}
        />
      </SubSection>
      <hr />
      <SubSection
        title={"Groups"}
        subTitle={
          "Group privileges to better manage users permissions. Then, assign groups to your users accordingly."
        }
        isOpen={false}
      >
        <GroupList />
      </SubSection>
    </>
  );
}

function mapStateToProps(state) {
  const { userList, volumeSettings, projectList, auth, roles } = state;
  const { userId } = auth;
  return { userList, volumeSettings, projectList, userId, roles };
}

export default connect(mapStateToProps)(Users);
