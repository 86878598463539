import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Variation from "../components/Variations";
import { getMostActiveProjects } from "../../api/project";
import { setAlert } from "../../actions/alert";

export default function ProjectVariations(props) {
  const { handlePathChange } = props;

  const [isFetching, setIsFetching] = useState(true);
  const [variations, setVariations] = useState([]);
  const [duration, setDuration] = useState(
    window.localStorage.getItem("mostActiveProjectsThreshold") ?? 30
  );

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  useEffect(() => {
    setIsFetching(true);
    getMostActiveProjects(dispatch, auth, indexingDate, duration)
      .then((res) => {
        setVariations(res);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(
          setAlert("Error while getting the most active projects", "danger")
        );
        setIsFetching(false);
      });
  }, [duration]);

  const handleSelectChange = (event) => {
    const target = event.target;
    const value = target.value;

    window.localStorage.setItem("mostActiveProjectsThreshold", value);
    setDuration(value);
  };

  const formattedVariations = variations.map((variation) => {
    return {
      variation: variation.variation,
      object1Used: variation.object1.sizeOfFiles,
      object1Free:
        variation.object1.allocatedSize - variation.object1.sizeOfFiles,
      object2Used: variation.object2.sizeOfFiles,
      object2Free:
        variation.object1.allocatedSize - variation.object1.sizeOfFiles,
      numberOfDays: variation.numberOfDays,
      name: variation.object1.name,
      links: [
        `/project/${variation.object1.id}`,
        `/project/${variation.object1.id}`,
        `/project/${variation.object1.id}`,
      ],
    };
  });

  return (
    <Variation
      variations={formattedVariations}
      duration={duration}
      handleSelectChange={handleSelectChange}
      handlePathChange={handlePathChange}
      isFetching={isFetching}
      title={"Most Active Projects"}
      subtitle={"Largest size fluctuations over time"}
    />
  );
}
