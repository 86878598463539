import { fetchWithAuthText } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";

// === Actions ===
export const REQUEST_UPDATE_LICENSE = "REQUEST_UPDATE_LICENSE";
export const RECEIVE_UPDATE_LICENSE = "RECEIVE_UPDATE_LICENSE";
export const INVALID_UPDATE_LICENSE = "INVALID_UPDATE_LICENSE";

// === Reducers ===
export default function license(state = { isFetching: false, isValid: true }, action) {
    switch (action.type) {
        case REQUEST_UPDATE_LICENSE:
            return Object.assign({}, state, {
                isFetching: true,
                isValid: true
            });
        case RECEIVE_UPDATE_LICENSE:
            return Object.assign({}, state, {
                isFetching: false,
                isValid: true
            });
        case INVALID_UPDATE_LICENSE:
            return Object.assign({}, state, {
                isFetching: false,
                isValid: false
            });
        default:
            return state;
    }
}

// === Action Creators ===
function requestUpdateLicense() {
    return {
        type: REQUEST_UPDATE_LICENSE
    };
}

function receiveUpdateLicense() {
    return {
        type: RECEIVE_UPDATE_LICENSE
    };
}

function invalidUpdateLicense() {
    return {
        type: INVALID_UPDATE_LICENSE
    }
}

// === Side Effects ===
export function updateLicense(license) {
    return (dispatch, getState) => {
        dispatch(requestUpdateLicense());
        const url = "/v1/license"
        return fetchWithAuthText(dispatch, url, getState().auth.accessToken, "PUT", license)
        .then(json => {
            dispatch(receiveUpdateLicense());
            dispatch(setAlert("License updated successfully", "success"));
        })
        .catch(err => {
            dispatch(invalidUpdateLicense());
            const message = err.message || "Error while trying to update license";
            dispatch(setAlert(message, "danger"));
        })
    };
}