import { usePDF } from "@react-pdf/renderer";
import { Button } from "reactstrap";
import PDFPreview from "./PDFDocument";

// Create Document Component
function DownloadPDFDocument(props) {
  const {
    title,
    subTitle,
    deltaInfo,
    data,
    timeUnit,
    sizeItems,
    action = () => {},
    sizeOfFiles,
    sizeShownData,
  } = props;
  const Doc = (
    <PDFPreview
      data={data}
      title={title}
      subTitle={subTitle}
      deltaInfo={deltaInfo}
      timeUnit={timeUnit}
      sizeItems={sizeItems}
      sizeOfFiles={sizeOfFiles}
      sizeShownData={sizeShownData}
    />
  );
  const [instance, updateInstance] = usePDF({ document: Doc });
  return (
    <a
      href={instance.url}
      download={`${title}.pdf`}
      style={{ color: "white", textDecoration: "none" }}
    >
      <Button color="primary" onClick={action}>
        Download
      </Button>
    </a>
  );
}
export default DownloadPDFDocument;
