import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { fetchVolumes } from "../actions";
import {
  fetchProject,
  updateProject,
  searchProjetPaths,
  resetProjectErrors,
} from "../ducks/project";

import ProjectForm from "./components/ProjectForm";
import Loading from "../components/Loading";
import { setAlert } from "../actions/alert";
import { getProjectById } from "../api/project";
import { useParams, useNavigate } from "react-router-dom";

function EditProject(props) {
  const {
    projectPathSuggestions,
    projectHandling,
    volumeList,
    dispatch,
    auth,
    indexingDate
  } = props;

  const [projectEntity, setProjectEntity] = useState({ projectShares: [] });
  const [isFetching, setIsFetching] = useState(true);
  const [hasUpdated, setHasUpdated] = useState(false);

  const navigate = useNavigate();

  const params = useParams();

  const project = useSelector(({ projectById }) => projectById[params.id] || { isFetching: true });

  useEffect(() => {
    dispatch(resetProjectErrors());
    dispatch(fetchVolumes());
    dispatch(fetchProject(params.id));
    getProjectById(dispatch, auth, params.id, indexingDate)
      .then((res) => {
        setProjectEntity(res);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(setAlert("Error while getting projectEntity", "danger"));
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    if (hasUpdated && !projectHandling.isFetching && projectHandling.isValid) {
      navigate(`/project/${params.id}`);
      setHasUpdated(false);
    }
  }, [projectHandling.isFetching]);

  const saveProject = (name, description, pathList, allocatedSize) => {
    dispatch(
      updateProject(params.id, name, description, pathList, allocatedSize)
    );
    setHasUpdated(true);
  }

  const searchPathSuggestions = (name) => {
    dispatch(searchProjetPaths(name));
  }

  const goBack = () => {
    navigate(-1);
  }


  return (
    <>
      {projectHandling.isFetching ||
      project.isFetching ||
      isFetching ? (
        <Loading />
      ) : (
        <>
          <h1 className="page-header">Edit Project</h1>
          <ProjectForm
            setAlert={(message, type, timer) =>
              dispatch(setAlert(message, type, timer))
            }
            volumeList={volumeList}
            project={project}
            goBack={goBack}
            projectPathSuggestions={projectPathSuggestions.items}
            isFetching={projectPathSuggestions.isFetching}
            saveProject={saveProject}
            projectEntity={projectEntity}
            searchPathSuggestions={searchPathSuggestions}
            errors={projectHandling.errors}
          />
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const {
    projectPathSuggestions,
    projectHandling,
    volumes,
    auth,
    dates,
  } = state;

  const volumeList = volumes.items;
  const indexingDate = dates.activeDate;

  return {
    projectPathSuggestions,
    projectHandling,
    volumeList,
    auth,
    indexingDate,
  };
}

export default connect(mapStateToProps)(EditProject);
