import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WizardModal from "../../components/WizardModal";
import RestoreFromArchiveStep1 from "./SelectArchiwareClientStep";
import SearchSelectionReview from "./SearchSelectionReview";
import ManualSelectionReview from "./ManualSelectionReview";
import { setAlert } from "../../actions/alert";
import { getServers } from "../../api/server";
import {
  restoreArchiwareSelection,
  restoreArchiwareSelectionSearchResult,
} from "../../api/archiware";

export default function RestoreFromArchiveWizard({
  showWizard,
  setShowWizard,
  selectedFiles,
  useSearchResultForActions,
  isDirectory = false,
  search = "",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector((state) => state.dates.activeDate);
  const searchFilters = useSelector((state) => state.search.filters);
  const [allArchiwareClients, setAllArchiwareClients] = useState([]);
  const [allServers, setAllServers] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);
  const [archiwareClients, setArchiwareClients] = useState([]);
  const [isSpecifyLocationChecked, setIsSpecifyLocationChecked] =
    useState(false);

  const emptyUserInputObj = {
    clientId: "",
    credentialsId: "",
    location: "",
  };
  const [userInputObj, setUserInputObj] = useState(emptyUserInputObj);

  const STEP_TITLES = ["Select Archiware P5 Client", "Review"];

  useEffect(() => {
    getAllServers();
  }, []);

  const getAllServers = async () => {
    try {
      const servers = await getServers(dispatch, auth);
      setAllServers(servers);
    } catch (err) {
      console.log(err);
      setAlert(
        "Something went wrong while trying to get servers. Please try again.",
        "danger",
        9000
      );
    }
  };

  const setLocation = (value) => {
    setUserInputObj((prev) => ({
      ...prev,
      location: value,
    }));
  };

  const setClient = (value) => {
    if (value.constructor === Array) {
      setUserInputObj((prev) => ({
        ...prev,
        clientId: value[0].clientName,
        credentialsId: value[0].credentialsId,
      }));
    } else {
      var objClient = value.split(",", 2);
      const thisArchiwareClient = archiwareClients.find((client) => {
        if (
          client.clientName === objClient[0] &&
          client.server.id === parseInt(objClient[1])
        )
          return client;
      });
      setUserInputObj((prev) => ({
        ...prev,
        clientId: thisArchiwareClient ? thisArchiwareClient.clientName : "N/A",
        credentialsId: thisArchiwareClient
          ? thisArchiwareClient.credentialsId
          : "N/A",
      }));
    }
  };
  const steps = [
    <RestoreFromArchiveStep1
      userInputObj={userInputObj}
      setClient={setClient}
      location={userInputObj.location}
      setLocation={setLocation}
      isSpecifyLocationChecked={isSpecifyLocationChecked}
      setIsSpecifyLocationChecked={setIsSpecifyLocationChecked}
      setDisableForwardActionButton={setDisableForwardActionButton}
      archiwareClients={archiwareClients}
      setArchiwareClients={setArchiwareClients}
      selectedFiles={selectedFiles}
      fileTypesToOnlyInclude={["Archives", "Archiware"]}
      isDirectory={isDirectory}
      allServers={allServers}
      allArchiwareClients={allArchiwareClients}
      setAllArchiwareClients={setAllArchiwareClients}
      restoring
    />,
    useSearchResultForActions ? (
      <SearchSelectionReview
        selectedFiles={selectedFiles}
        userInputObj={userInputObj}
        useSearchResultForActions={useSearchResultForActions}
        isDirectory={isDirectory}
        searchText={search}
        setDisableForwardActionButton={setDisableForwardActionButton}
        restoring
      />
    ) : (
      <ManualSelectionReview
        userInputObj={userInputObj}
        isDirectory={isDirectory}
        setDisableForwardActionButton={setDisableForwardActionButton}
        selectedFiles={selectedFiles}
        restoring
      />
    ),
  ];
  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setUserInputObj(emptyUserInputObj);
    setArchiwareClients([]);
    setShowWizard(false);
    setStepIndex(0);
    setDisableForwardActionButton(false);
    setIsSpecifyLocationChecked(false);
  };

  const resolvedPromiseActions = (res) => {
    dispatch(
      setAlert(
        <>
          A job for archiving the selection is now running. You can monitor the
          job by clicking
          <a
            className="text-primary"
            onClick={() => navigate("/settings/jobs")}
          >
            {" "}
            here
          </a>
        </>,
        "success",
        9000
      )
    );

    onWizardExit();
  };

  const rejectedPromiseActions = (err) => {
    console.log(err);
    dispatch(
      setAlert(
        "Something went wrong while trying to start the archive job. Please try again.",
        "danger",
        9000
      )
    );
    onWizardExit();
  };

  const restoreSelection = async () => {
    if (useSearchResultForActions) {
      restoreArchiwareSelectionSearchResult(
        dispatch,
        auth,
        userInputObj.clientId,
        userInputObj.credentialsId,
        userInputObj.location,
        indexingDate,
        isDirectory,
        search,
        searchFilters
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    } else {
      restoreArchiwareSelection(
        dispatch,
        auth,
        userInputObj.clientId,
        userInputObj.credentialsId,
        userInputObj.location,
        selectedFiles
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Restore":
        setDisableForwardActionButton(true);
        restoreSelection();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (stepIndex === STEPS_LENGTH - 1) {
      return "Restore";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      title="Restore with Archiware P5"
      subTitle={STEP_TITLES[stepIndex]}
      onWizardExit={onWizardExit}
      stepsLength={STEPS_LENGTH}
      stepIndex={stepIndex + 1}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}
