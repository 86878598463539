import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Form, FormGroup, Label, FormFeedback, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function CreateGroupForm({
    groupObjForWizard,
    setGroupObjForWizard,
    setDisableConfirmAction,
    existingGroupsAndGroupsBeforeCreation,
    isUpdateForm = false
}) {
    const volumeList = useSelector(({ volumeSettings }) => volumeSettings.items);
    const projectList = useSelector(({ projectList }) => projectList.items);
    const [privilegeListDivHeight, setPrivilegeListDivHeight] = useState(0);

    const cleanPrivilegeObj = {
        action: "ALLOW",
        resourceType: "PROJECT",
        resourceId: projectList.length ? projectList[0].id : ""
    }
    const [privilegeObj, setPrivilegeObj] = useState(cleanPrivilegeObj);
    const [privilegeArray, setPrivilegeArray] = useState(isUpdateForm ? groupObjForWizard.privileges : []);
    const [action, setAction] = useState("ALLOW");
    const [resourceType, setResourceType] = useState("PROJECT");
    const [resourceId, setResourceId] = useState(projectList.length ? projectList[0].id : "");
    const [disableAddPrivilegeButton, setDisableAddPrivilegeButton] = useState(true);
    const [groupErrors, setGroupErrors] = useState({ nameAlreadyExist: "" });
    const { current: autoFillWhenUpdateForm } = useRef(isUpdateForm ? groupObjForWizard.name : "");

    const handleGroupErrors = () => {
        const groupName = groupObjForWizard.name.trim();
        if (groupName.length > 255 || groupName.length < 1) {
            setDisableConfirmAction(true);
            setGroupErrors(prev => ({ ...prev, nameAlreadyExist: "Name size must be between 1 and 255 characters" }));
        } else if (existingGroupsAndGroupsBeforeCreation.some(g => (g.name === groupName && g.name !== autoFillWhenUpdateForm))) {
            setGroupErrors(prev => ({ ...prev, nameAlreadyExist: "This name already exist" }));
            setDisableConfirmAction(true);
        } else {
            setGroupErrors(prev => ({ ...prev, nameAlreadyExist: "" }));
            setDisableConfirmAction(false);
        }
    }

    const projectOptions = projectList.length ? projectList.map(project => {
        return (
            <option key={project.id} value={project.id}>
                {project.name}
            </option>
        );
    }) : <option value="">No projects available</option>;

    const volumeOptions = volumeList.length ? volumeList.map(volume => {
        return (
            <option key={volume.id} value={volume.id}>
                {volume.name}
            </option>
        );
    }) : <option value="">No volumes available</option>;

    // handling if a privilege can be added or not
    useEffect(() => {
        if (
            (resourceType === "PROJECT" && !projectOptions.length) ||
            (resourceType === "VOLUME" && !volumeOptions.length)
        ) {
            setDisableAddPrivilegeButton(true);
        } else if (privilegeArray.some(privilege => privilege.resourceId === resourceId && privilege.resourceType === resourceType)) {
            setDisableAddPrivilegeButton(true);
        } else {
            setDisableAddPrivilegeButton(false);
        };
    }, [privilegeArray, projectOptions, volumeOptions, resourceId, resourceType]);

    const getResourceName = privilege => {
        if (-1 === privilege.resourceId) {
            return "All";
        }
        return privilege.resourceType === "PROJECT" ?
            projectList.find(proj => proj.id === privilege.resourceId).name :
            volumeList.find(vol => vol.id === privilege.resourceId).name;
    };

    return (
        <Form>
            <FormGroup>
                <Label for="name">Group Name</Label>
                <Input
                    onChange={event => { setGroupObjForWizard({ ...groupObjForWizard, name: event.target.value }) }}
                    value={groupObjForWizard.name}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Ex. Project B all access. (Must be unique)"
                    onBlur={handleGroupErrors}
                    invalid={!!groupErrors["nameAlreadyExist"]}
                />
                <FormFeedback>{groupErrors["nameAlreadyExist"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="description">Description</Label>
                <Input
                    onChange={event => { setGroupObjForWizard({ ...groupObjForWizard, description: event.target.value }) }}
                    value={groupObjForWizard.description}
                    type="text"
                    name="description"
                    id="description"
                    placeholder=" Ex. This group is used for…"
                />
            </FormGroup>

            <div style={{ borderTop: "1px solid lightgrey" }} className="my-3 pt-2">
                Add a privilege to the group <FontAwesomeIcon id="privilegeInfoWizard" icon={faQuestionCircle} /><br />
                By default, all projects and volumes are allowed
                <UncontrolledTooltip placement="auto" autohide={false} target={`privilegeInfoWizard`}>
                    <span>Select DENY to restrict access to a resource specifically</span>
                    <br />
                    <br />
                    <span>DENY has precedence over ALLOW</span>
                    <br />
                    <br />
                    <span>Select ALLOW to restrict everything but the allowed resources</span>
                    <br />
                    <br />
                    <span>ALLOW privileges can be combined together</span>
                </UncontrolledTooltip>
            </div>
            <div className="mb-4">
                <div className="d-flex justify-content-between">
                    <FormGroup>
                        <Label for="action">Action</Label>
                        <Input
                            type="select"
                            name="action"
                            value={action}
                            onChange={e => {
                                const inputValue = e.target.value;
                                setAction(inputValue);
                                setPrivilegeObj(prev => ({
                                    ...prev,
                                    action: inputValue
                                }));
                            }}
                        >
                            <option value="ALLOW">ALLOW</option>
                            <option value="DENY">DENY</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="resource">Resource</Label>
                        <Input
                            type="select"
                            name="resource"
                            value={resourceType}
                            onChange={e => {
                                const inputValue = e.target.value;
                                setResourceType(inputValue);
                                setPrivilegeObj(prev => ({
                                    ...prev,
                                    resourceType: inputValue,
                                    resourceId: inputValue === "PROJECT" && projectList.length ? projectList[0].id
                                        : inputValue === "VOLUME" && volumeList.length ? volumeList[0].id
                                            : ""
                                }));
                                setResourceId(
                                    inputValue === "PROJECT" && projectList.length ? projectList[0].id
                                        : inputValue === "VOLUME" && volumeList.length ? volumeList[0].id
                                            : ""
                                );
                            }}
                        >
                            <option value="PROJECT">PROJECT</option>
                            <option value="VOLUME">VOLUME</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="resourceName">Name</Label>
                        <Input
                            type="select"
                            name="resourceName"
                            value={resourceId}
                            onChange={e => {
                                const inputValue = e.target.value;
                                setResourceId(Number(inputValue));
                                setPrivilegeObj(prev => ({
                                    ...prev,
                                    resourceId: Number(inputValue)
                                }));
                            }}
                        >
                            {"PROJECT" === resourceType ? projectOptions : volumeOptions}
                            {"DENY" === action && <option value="-1">All</option>}
                        </Input>
                    </FormGroup>
                </div>

                <div ref={thisRef => {
                    if (thisRef) setPrivilegeListDivHeight(thisRef.clientHeight);
                }} style={{
                    backgroundColor: "#f5f5f5",
                    maxHeight: "250px",
                    padding: "5px 0",
                    overflowY: privilegeListDivHeight >= 250 ? "scroll" : "initial",
                    borderRadius: "5px"
                }}
                >
                    {
                        privilegeArray.length ?
                            privilegeArray.map((privilege, index, thisArray) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center mx-2 pb-2 pt-1 mt-2"
                                    style={{ borderBottom: index === thisArray.length - 1 ? "" : "1px solid #d9d9d9" }}
                                >
                                    <Button className="me-1" style={{ marginRight: "12px" }} color="danger" size="sm" onClick={(e) => {
                                        e.preventDefault();
                                        const privilegeArrayCopy = privilegeArray.slice();
                                        privilegeArrayCopy.splice(index, 1);
                                        setPrivilegeArray(privilegeArrayCopy);
                                        setGroupObjForWizard(prev => ({
                                            ...prev,
                                            privileges: privilegeArrayCopy
                                        }));
                                    }}>
                                        X
                                    </Button>
                                    <span className="px-2 border-end border-dark">{privilege.action}</span>
                                    <span className="px-2 border-end border-dark">{privilege.resourceType}</span>
                                    <span className="px-2">{getResourceName(privilege)}</span>
                                </div>
                            )) :
                            null
                    }

                </div>

                <Button className="mt-3" disabled={disableAddPrivilegeButton} color="success" onClick={() => {
                    setPrivilegeArray(prev => [...prev, privilegeObj]);
                    setGroupObjForWizard(prev => ({ ...prev, privileges: [...prev.privileges, privilegeObj] }));
                }}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
        </Form>
    );
}

export default CreateGroupForm;