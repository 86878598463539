import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import TableActions from "../../components/TableActions";
import ArchiwareVolumeMappingForm from "./ArchiwareVolumeMappingForm";
import ArchiwareGlobalVolumeMapping from "./ArchiwareGlobalVolumeMapping";
import Loading from "../../components/Loading";
import { getArchiwareVolumeMapping } from "../../api/archiware";

ArchiwareVolumeMappingList.propTypes = {
  volumeMappings: PropTypes.array,
  total: PropTypes.number,
  createVolumeMapping: PropTypes.func,
  updateVolumeMapping: PropTypes.func,
  deleteVolumeMapping: PropTypes.func,
  fetchArchiwareVolumeMappingsListPage: PropTypes.func,
  errors: PropTypes.object,
  formValid: PropTypes.bool,
  isFetching: PropTypes.bool,
};

function ArchiwareVolumeMappingList(props) {
  const {
    volumes,
    volumeMappings,
    total,
    page,
    size,
    sort,
    direction,
    createVolumeMapping,
    updateVolumeMapping,
    deleteVolumeMapping,
    fetchArchiwareVolumeMappingsListPage,
    errors,
    formValid,
    isFetching,
  } = props;

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [id, setId] = useState("");
  const [onPremisesStorage, setOnPremisesStorage] = useState("");
  const [dataIntellPath, setDataIntellPath] = useState("");
  const [archiwareP5Path, setArchiwareP5Path] = useState("");
  const [showCreateVolumeMappingForm, setShowCreateVolumeMappingForm] =
    useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [showUpdateVolumeMappingForm, setShowUpdateVolumeMappingForm] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [allArchiwareVolumeMappings, setAllArchiwareVolumeMappings] = useState(
    []
  );

  const numberOfPages = Math.ceil(total / size);
  const volumeMappingTitles = [
    { name: "Id", sort: "id" },
    { name: "On-Premises Storage" },
    { name: "DataIntell Path" },
    { name: "Archiware P5 Path" },
    { name: "Action" },
  ];

  useEffect(() => {
    if (formValid && !isFetching) {
      handleCloseModal();
    }
  }, [formValid, isFetching]);

  useEffect(() => {
    getArchiwareVolumeMapping(dispatch, auth, 1, 500)
      .then((res) => setAllArchiwareVolumeMappings(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching storage path configurations for archiware",
            "danger"
          )
        )
      );
  }, [total]);

  const handleCloseModal = () => {
    setShowCreateVolumeMappingForm(false);
    setShowUpdateVolumeMappingForm(false);
    setShowDeleteConfirmation(false);
    setShowAllFields(false);
    setId("");
    setOnPremisesStorage("");
    setDataIntellPath("");
    setArchiwareP5Path("");
  };

  const handleChange = (name, value) => {
    if (name === "onPremisesStorage") {
      setOnPremisesStorage(value);
      value !== "" ? setShowAllFields(true) : setShowAllFields(false);
    } else if (name === "dataIntellPath") {
      setDataIntellPath(value);
    } else if (name === "archiwareP5Path") {
      setArchiwareP5Path(value);
    }
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === sort) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    fetchArchiwareVolumeMappingsListPage(page, newSort, newDirection, size);
  };

  const listFormated = volumeMappings.map((volumeMapping) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      volumeMapping.id,
      volumes.find((volume) => volume.id === volumeMapping.volumeId)?.name,
      volumeMapping.dataintellPath,
      volumeMapping.archiwarePath,
      <>
        <Button
          onClick={() => {
            setShowUpdateVolumeMappingForm(true);
            setId(volumeMapping.id);
            setOnPremisesStorage(volumeMapping.volumeId);
            setDataIntellPath(volumeMapping.dataintellPath);
            setArchiwareP5Path(volumeMapping.archiwarePath);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(volumeMapping.id);
            setShowDeleteConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateVolumeMappingForm(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h5>On-Premises Storage Path Configurations</h5>
      <p>
        To let Archiware P5 knows where the files are located when launching an
        archive or a restore job, DataIntell will provide the full path of the
        file based on this configuration. DataIntell will follow this order to
        set a storage path for a specific storage based on matched value:
      </p>
      <ol>
        <li>Individual storage path configuration</li>
        <li>Global storage path configuration</li>
        <li>Path configured under the On-Premises storages section</li>
      </ol>

      <ArchiwareGlobalVolumeMapping />
      <h6>Individual Storage Path Configurations</h6>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={page}
            numberOfPages={numberOfPages}
            numberOfElements={size}
            setNumberOfElements={(numberOfElements) =>
              fetchArchiwareVolumeMappingsListPage(
                1,
                sort,
                direction,
                numberOfElements
              )
            }
            selectPage={(newPage) =>
              fetchArchiwareVolumeMappingsListPage(
                newPage,
                sort,
                direction,
                size
              )
            }
          />
          <TableResult
            title={"On-Premises Storage Path Configurations"}
            data={listFormated}
            titles={volumeMappingTitles}
            sort={sort}
            direction={direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      )}
      <ConfirmModal
        showConfirmation={showCreateVolumeMappingForm}
        title="Add Individual Storage Path Configuration"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createVolumeMapping({
            volumeId: onPremisesStorage,
            dataintellPath: dataIntellPath,
            archiwarePath: archiwareP5Path,
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <ArchiwareVolumeMappingForm
          volumeMappings={allArchiwareVolumeMappings}
          volumes={volumes}
          onPremisesStorage={onPremisesStorage}
          dataIntellPath={dataIntellPath}
          archiwareP5Path={archiwareP5Path}
          showAllFields={showAllFields}
          isUpdate={false}
          handleChange={handleChange}
          errors={errors}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateVolumeMappingForm}
        title="Update Individual Storage Path Configuration"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateVolumeMapping(id, {
            volumeId: onPremisesStorage,
            dataintellPath: dataIntellPath,
            archiwarePath: archiwareP5Path,
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <ArchiwareVolumeMappingForm
          volumes={volumes}
          onPremisesStorage={onPremisesStorage}
          dataIntellPath={dataIntellPath}
          archiwareP5Path={archiwareP5Path}
          handleChange={handleChange}
          errors={errors}
          isUpdate={true}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteVolumeMapping(id);
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        Are you sure you want to delete the individual storage path
        configuration with id {id}?
      </ConfirmModal>
    </>
  );
}

export default ArchiwareVolumeMappingList;
