import { Badge, Col, FormGroup, Input, Label, Row } from "reactstrap";
import ParameterList from "./parameters/ParameterList";
import { REPORT_DESCRIPTIONS } from "./reportDescription";

export default function ReportReviewStep(props) {
  const {
    reportTemplateObj,
    isLaunchedAfterCreation,
    setIsLaunchedAfterCreation,
    isUpdate,
    searchQueryNodes,
    tags,
  } = props;

  const getReviewWithParameters = () => {
    return (
      <Row>
        <Col sm={6}>
          <h6>Parameters</h6>
          <div style={{ maxHeight: "260px", overflowY: "auto" }}>
            <ParameterList
              references={reportTemplateObj.parameters.references}
              comparison={reportTemplateObj.parameters.comparison}
              reportType={reportTemplateObj.type}
            />
          </div>
          <hr />
          <h6>Tags</h6>
          {tags.length === 0 ? (
            <span>None</span>
          ) : (
            <div style={{ maxHeight: "90px", overflowY: "auto" }}>
              {tags.map(tag => <Badge className="me-1">{tag}</Badge>)}
            </div>
          )}
        </Col>
        <Col sm={6}>
          <h6>Search Queries</h6>
          {searchQueryNodes ? (
            <div
              style={{
                maxHeight: "400px",
                overflow: "auto",
                background: "#f2f2f2",
                borderRadius: "10px",
                marginBottom: "10px",
                display: "grid",
              }}
            >
              <pre className="mt-2 ms-2">
                {JSON.stringify(searchQueryNodes, null, 2)}
              </pre>
            </div>
          ) : (
            "None"
          )}
        </Col>
      </Row>
    );
  };

  const getReviewWithoutParameters = () => {
    return (
      <Row>
        <Col sm={6}>
          <h6>Search Queries</h6>
          {searchQueryNodes ? (
            <div
              style={{
                maxHeight: "400px",
                overflow: "auto",
                background: "#f2f2f2",
                borderRadius: "10px",
                marginBottom: "10px",
                display: "grid",
              }}
            >
              <pre className="mt-2 ms-2">
                {JSON.stringify(searchQueryNodes, null, 2)}
              </pre>
            </div>
          ) : (
            "None"
          )}
        </Col>
        <Col sm={6}>
          <h6>Tags</h6>
          {tags.length === 0 ? (
            <span>None</span>
          ) : (
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {tags.map(tag => <Badge className="me-1">{tag}</Badge>)}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      {REPORT_DESCRIPTIONS[reportTemplateObj.type]}
      <div className="mt-2">
        Flagged Files Retention (Days):{" "}
        <strong>{reportTemplateObj.retention}</strong>
      </div>

      <hr />
      {(reportTemplateObj.type === "DUPLICATED" ||
        reportTemplateObj.type === "UNIQUE") &&
        getReviewWithParameters()}
      {reportTemplateObj.type === "SEARCH_QUERY" &&
        getReviewWithoutParameters()}
      <hr />
      <FormGroup check className="mt-3">
        <Label check>
          <Input
            type="checkbox"
            checked={isLaunchedAfterCreation}
            onChange={(e) => setIsLaunchedAfterCreation(e.target.checked)}
          />
          Launch the report after the {isUpdate ? "update" : "creation"}.
        </Label>
      </FormGroup>
    </>
  );
}
