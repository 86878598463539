import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Canvas,
  Font,
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Link,
} from "@react-pdf/renderer";
import ConfirmModal from "../../components/ConfirmModal";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {
  DATE_FORMAT_OPTIONS,
  DATE_FORMAT,
} from "../../constants/dateFormatting";
import { getMonthlyCostFormatted } from "../../utils/pricePerVolume";
import Aileron from "../../font/Aileron-Light.otf";
import AileronBold from "../../font/Aileron-Bold.otf";
import { setAlert } from "../../actions/alert";
import { formatSize } from "../../components/FormatSize";
import CloudSodaLogoSVG from "../../components/soda/CloudSodaLogoSVG";

Font.register({ family: "Aileron", src: Aileron });
Font.register({ family: "AileronBold", src: AileronBold });

const styles = {
  footer: {
    position: "absolute",
    bottom: 0,
    left: 10,
    right: 10,
    padding: 10,
    borderTop: "0.25px solid black",
    display: "flex",
    flexDirection: "row",
  },
  logoBlack: {
    width: 40,
    height: 10,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  footerText: {
    fontFamily: "Aileron",
    fontSize: 7,
    color: "black",
  },
  cardWithChart: {
    flex: 1,
    backgroundColor: "#f2f2f2",
    padding: 16,
    borderRadius: 2,
  },
  card: {
    backgroundColor: "#f2f2f2",
    padding: 16,
    borderRadius: 2,
    marginBottom: 5,
  },
  copyrightText: {
    fontFamily: "Aileron",
    fontSize: 8,
    color: "gray",
    flex: 1,
    textAlign: "center",
  },
  pick: {
    position: "absolute",
    bottom: 2,
    left: 8,
    height: 5,
    width: 5,
    borderRadius: 10,
  },
  dashedline: {
    position: "absolute",
    bottom: 4,
    height: 1,
    width: 20,
    borderBottomWidth: 1,
    borderBottomStyle: "dashed",
  },
  title: {
    fontFamily: "AileronBold",
    fontSize: 15,
    marginBottom: 15,
  },
  subTitle: {
    fontFamily: "AileronBold",
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 8,
  },
  field: {
    fontFamily: "Aileron",
    margin: 5,
    fontSize: 8,
  },

  legend: {
    fontFamily: "Aileron",
    fontSize: 8,
    marginRight: 8,
    marginLeft: 8,
  },
  maxmin: {
    fontFamily: "Aileron",
    fontSize: 8,
    marginTop: 5,
    marginRight: 5,
    marginLeft: 5,
  },
  deltaInfo: {
    fontFamily: "Aileron",
    marginRight: 5,
    fontSize: 8,
    textAlign: "right",
    marginBottom: 10,
  },
};

export default function VolumePDF({
  volumeName,
  latestDetailedVolume,
  latestVolumeSettings,
  duplicateSize,
  unusedSixMonthsSize,
  unusedOneYearSize,
  timelineRef,
  sizeChartRef,
  minSize,
  maxSize,
  chartUnit,
  chartFromDate,
  chartToDate,
  chartInterval,
  deltaInfo,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const getCost = (sizeInBytes, currencySymbol) => {
    return getMonthlyCostFormatted(
      sizeInBytes,
      latestVolumeSettings.storagePrice.price,
      currencySymbol
    );
  };

  const logoColor = "#4f92db";
  const PDFPage = () => (
    <Document>
      <Page size="A4" style={{ padding: 15 }}>
        <View style={styles.flexRow}>
          <Text
            style={{
              fontSize: 20,
              marginRight: "auto",
              marginBottom: 10,
              fontFamily: "AileronBold",
            }}
          >
            Storage Report
          </Text>

          <CloudSodaLogoSVG width={80} color={logoColor} />
        </View>

        <View
          style={{ borderBottom: "0.25px solid black", marginBottom: 15 }}
        ></View>

        <Text style={styles.title}>{volumeName}</Text>

        {sizeChartRef !== null && sizeChartRef.current !== null ? (
          <>
            <View style={styles.flexRow}>
              <div
                style={{
                  ...styles.cardWithChart,
                  marginRight: 5,
                  marginBottom: 5,
                }}
              >
                <Text style={styles.subTitle}>Storage Details</Text>
                <Text style={styles.field}>
                  Total Capacity:{" "}
                  {formatSize(latestDetailedVolume.total, baseSize)}
                </Text>
                <Text style={styles.field}>
                  Available Space:{" "}
                  {latestDetailedVolume.free
                    ? formatSize(latestDetailedVolume.free, baseSize)
                    : "N/A"}
                </Text>
                <Text style={styles.field}>
                  Used Space: {formatSize(latestDetailedVolume.used, baseSize)}
                </Text>
                <Text style={styles.field}>
                  Total Size Of Files:{" "}
                  {formatSize(latestDetailedVolume.sizeOfFiles, baseSize)}
                </Text>
                {latestDetailedVolume.numberOfFiles !== undefined &&
                  latestDetailedVolume.numberOfFiles !== null &&
                  latestDetailedVolume.numberOfFolders !== undefined &&
                  latestDetailedVolume.numberOfFolders !== null && (
                    <>
                      <Text style={styles.field}>
                        Number Of Files:{" "}
                        {latestDetailedVolume.numberOfFiles.toLocaleString(
                          "en-US"
                        )}
                      </Text>
                      <Text style={styles.field}>
                        Number Of Folders:{" "}
                        {latestDetailedVolume.numberOfFolders.toLocaleString(
                          "en-US"
                        )}
                      </Text>
                    </>
                  )}
                <Text style={styles.field}>
                  Last Indexing Date:
                  {` ${new Date(
                    latestDetailedVolume.indexingDate
                  ).toLocaleDateString("en-CA", DATE_FORMAT_OPTIONS)}`}
                </Text>
              </div>

              <div
                style={{
                  ...styles.cardWithChart,
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                <Text style={styles.subTitle}>Size</Text>
                <Image
                  style={{
                    marginTop: 0,
                    width: 220,
                    height: 220,
                    alignSelf: "center",
                  }}
                  src={sizeChartRef.current.toBase64Image()}
                />
              </div>
            </View>
            <View
              style={{
                ...styles.flexRow,
                marginTop: 5,
              }}
            >
              <div
                style={{
                  ...styles.cardWithChart,
                  marginRight: 5,
                  marginBottom: 10,
                }}
              >
                <Text style={styles.subTitle}>Monthly Expenses</Text>
                <Text style={styles.field}>
                  Monthly Cost:
                  {` ${getCost(
                    latestDetailedVolume.used,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
                <Text style={styles.field}>
                  Duplicate Cost:
                  {` ${getCost(
                    duplicateSize,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
                <Text style={styles.field}>
                  Unused Cost:
                  {` ${getCost(
                    unusedSixMonthsSize,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
              </div>
              <div
                style={{
                  ...styles.cardWithChart,
                  marginLeft: 5,
                  marginBottom: 10,
                }}
              >
                <Text style={styles.subTitle}>Insights</Text>
                <Text style={styles.field}>
                  Duplicate Data: {formatSize(duplicateSize, baseSize)}
                </Text>
                <Text style={styles.field}>
                  Cold Data - 6 Months:{" "}
                  {formatSize(unusedSixMonthsSize, baseSize)}
                </Text>
                <Text style={styles.field}>
                  Cold Data - 1 Year: {formatSize(unusedOneYearSize, baseSize)}
                </Text>
              </div>
            </View>
          </>
        ) : (
          <View style={styles.flexRow}>
            <div style={{ ...styles.card, flex: 1 }}>
              <Text style={styles.subTitle}>Storage Details</Text>
              <Text style={styles.field}>
                Total Capacity:{" "}
                {formatSize(latestDetailedVolume.total, baseSize)}
              </Text>
              <Text style={styles.field}>
                Total Size Of Files:{" "}
                {formatSize(latestDetailedVolume.sizeOfFiles, baseSize)}
              </Text>

              {latestDetailedVolume.numberOfFiles !== undefined &&
                latestDetailedVolume.numberOfFolders !== undefined && (
                  <>
                    <Text style={styles.field}>
                      Number Of Files:{" "}
                      {latestDetailedVolume.numberOfFiles.toLocaleString(
                        "en-US"
                      )}
                    </Text>
                    <Text style={styles.field}>
                      Number Of Folders:{" "}
                      {latestDetailedVolume.numberOfFolders.toLocaleString(
                        "en-US"
                      )}
                    </Text>
                  </>
                )}
              <Text style={styles.field}>
                Last Indexing Date:
                {` ${new Date(
                  latestDetailedVolume.indexingDate
                ).toLocaleDateString("en-CA", DATE_FORMAT_OPTIONS)}`}
              </Text>
            </div>

            <View
              style={{
                flex: 1,
                marginLeft: 5,
              }}
            >
              <div style={styles.card}>
                <Text style={styles.subTitle}>Monthly Expenses</Text>
                <Text style={styles.field}>
                  Monthly Cost:
                  {` ${getCost(
                    latestDetailedVolume.used,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
                <Text style={styles.field}>
                  Duplicate Cost:
                  {` ${getCost(
                    duplicateSize,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
                <Text style={styles.field}>
                  Unused Cost:
                  {` ${getCost(
                    unusedSixMonthsSize,
                    latestVolumeSettings.storagePrice.currencySymbol
                  )}`}
                </Text>
              </div>
              <div style={styles.card}>
                <Text style={styles.subTitle}>Insights</Text>
                <Text style={styles.field}>
                  Duplicate Data: {formatSize(duplicateSize, baseSize)}
                </Text>
              </div>
            </View>
          </View>
        )}

        <div
          style={{
            width: "100%",
            padding: 16,
            backgroundColor: "#f2f2f2",
            marginTop: 5,
          }}
        >
          <Text style={styles.subTitle}>
            {chartInterval !== "custom"
              ? `Used Capacity In The Last ${chartInterval} Days`
              : `Used Capacity Between  ${new Date(
                  chartFromDate
                ).toLocaleDateString("en-CA", DATE_FORMAT)} And ${new Date(
                  chartToDate
                ).toLocaleDateString("en-CA", DATE_FORMAT)}`}
          </Text>
          <Text style={styles.deltaInfo}>{deltaInfo}</Text>

          <div
            style={{
              ...styles.flexRow,
              alignSelf: "center",
              marginTop: 5,
            }}
          >
            <div
              style={{
                flexDirection: "row",
              }}
            >
              <Canvas
                style={{
                  position: "absolute",
                  bottom: 2,
                  left: -4,
                  height: 5,
                  width: 10,
                  backgroundColor: "rgba(201, 33, 33, 0.5)",
                  borderColor: "rgb(201, 33, 33)",
                }}
              ></Canvas>
              <Text style={styles.legend}>Last 10% Available</Text>
            </div>

            <div
              style={{
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  marginRight: 15,
                }}
              >
                <div>
                  <View
                    style={{
                      ...styles.dashedline,
                      borderBottomColor: "rgb(128,128,128)",
                    }}
                  ></View>
                </div>

                <Canvas
                  style={{
                    ...styles.pick,
                    backgroundColor: "#007BFF",
                  }}
                ></Canvas>
              </div>
              <Text style={styles.legend}>Minimum Used Capacity</Text>
            </div>

            <div
              style={{
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  marginRight: 15,
                }}
              >
                <div>
                  <View
                    style={{
                      ...styles.dashedline,
                      borderBottomColor: "black",
                    }}
                  ></View>
                </div>

                <Canvas
                  style={{
                    ...styles.pick,
                    backgroundColor: "#FF0000",
                  }}
                ></Canvas>
              </div>
              <Text style={styles.legend}>Maximum Used Capacity</Text>
            </div>
          </div>

          <Image src={timelineRef.current.toBase64Image()} />
          <div
            style={{
              ...styles.flexRow,
              alignSelf: "center",
              marginTop: 5,
            }}
          >
            <Text style={styles.maxmin}>
              Minimum Used Capacity: {minSize}
              {` ${chartUnit}`}
            </Text>

            <Text style={styles.maxmin}>
              Maximum Used Capacity: {maxSize}
              {` ${chartUnit}`}
            </Text>
          </div>
        </div>

        <View style={styles.footer}>
          <CloudSodaLogoSVG width={40} color={logoColor} />
          <Text style={styles.copyrightText}>
            © {new Date().getFullYear()} CloudSoda. All rights reserved.
          </Text>

          <Text style={styles.footerText}>
            <Link src="https://www.cloudsoda.io">cloudsoda.io</Link>
          </Text>
        </View>
      </Page>
    </Document>
  );

  const DownloadLink = () => (
    <PDFDownloadLink
      document={<PDFPage />}
      fileName={`Volume-${volumeName}-${new Date().toLocaleDateString(
        "en-CA",
        DATE_FORMAT
      )}.pdf`}
    >
      {({ loading, error }) => {
        if (error !== null) {
          setShowModal(false);
          dispatch(setAlert(error.message, "danger"));
        }
        return loading ? (
          <Button disabled>Loading document...</Button>
        ) : (
          <Button color="primary">Download</Button>
        );
      }}
    </PDFDownloadLink>
  );

  return (
    <>
      <Button
        disabled={latestDetailedVolume ? false : true}
        id="volumePDF"
        className="ms-auto"
        size="sm"
        color="danger"
        outline
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faFilePdf} />
      </Button>
      <UncontrolledTooltip placement="auto" target="volumePDF">
        Preview PDF
      </UncontrolledTooltip>
      <ConfirmModal
        title="Preview"
        customButton={<DownloadLink />}
        closeConfirmation={() => setShowModal(false)}
        showConfirmation={showModal}
        size="xl"
      >
        <PDFViewer
          style={{
            width: "100%",
            height: "600px",
          }}
        >
          <PDFPage />
        </PDFViewer>
      </ConfirmModal>
    </>
  );
}
