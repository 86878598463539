import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Font,
  PDFViewer,
  BlobProvider,
  Document,
  Page,
  View,
  Text,
  Image,
  Link,
} from "@react-pdf/renderer";
import ConfirmModal from "../../components/ConfirmModal";
import { Button, Spinner, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { DATE_FORMAT } from "../../constants/dateFormatting";
import Aileron from "../../font/Aileron-Light.otf";
import AileronBold from "../../font/Aileron-Bold.otf";
import { setAlert } from "../../actions/alert";
import { formatSize } from "../../components/FormatSize";
import {
  handleVolumeAndPaths,
  handleVolumeNames,
} from "../../utils/handleVolumeAndPath";
import CloudSodaLogoSVG from "../../components/soda/CloudSodaLogoSVG";

Font.register({ family: "Aileron", src: Aileron });
Font.register({ family: "AileronBold", src: AileronBold });

const styles = {
  footer: {
    position: "absolute",
    bottom: 0,
    left: 10,
    right: 10,
    padding: 10,
    borderTop: "0.25px solid black",
    display: "flex",
    flexDirection: "row",
  },
  logoBlack: {
    width: 40,
    height: 10,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  footerText: {
    fontFamily: "Aileron",
    fontSize: 7,
    color: "black",
  },
  legendText: {
    fontFamily: "Aileron",
    fontSize: 6,
    color: "black",
  },
  cardWithoutChart: {
    flex: 1,
    backgroundColor: "#f2f2f2",
    padding: 16,
  },
  copyrightText: {
    fontFamily: "Aileron",
    fontSize: 8,
    color: "gray",
    flex: 1,
    textAlign: "center",
  },
  dashedline: {
    position: "absolute",
    bottom: 4,
    height: 1,
    width: 20,
    borderBottomWidth: 1,
    borderBottomStyle: "dashed",
  },
  title: {
    fontFamily: "AileronBold",
    fontSize: 15,
    marginBottom: 15,
  },
  card: {
    width: "100%",
    height: 350,
    padding: 16,
    backgroundColor: "#f2f2f2",
    marginTop: 5,
    marginBottom: 5,
  },
  subTitle: {
    fontFamily: "AileronBold",
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 8,
  },
  field: {
    fontFamily: "Aileron",
    margin: 5,
    fontSize: 8,
  },

  description: {
    fontFamily: "Aileron",
    margin: 4,
    fontSize: 8,
    textAlign: "center",
  },
  bold: {
    fontFamily: "AileronBold",
  },
  page: { padding: "35px 15px 35px 15px" },
  firstPage: { padding: "15px" },
  unitDescription: {
    fontFamily: "Aileron",
    margin: 5,
    fontSize: 10,
  },
  bulletText: {
    fontFamily: "Aileron",
    marginLeft: 10,
    fontSize: 8,
  },
};

export default function SearchPDF({
  foldersIsFetching,
  filesIsFetching,
  sizeShownDataCreation,
  sizeShownDataLastAccess,
  sizeShownDataLastModified,
  sizeShownDataExtension,
  sizeShownDataVolumes,
  sizeShownDataOwners,
  sizeShownDataGroups,
  subtitleStorageType,
  sizeItemsExtension,
  sizeItemsVolumes,
  sizeItemsOwners,
  sizeItemsGroups,
  sizeItemsDuplicate,
  timeUnitLastAccess,
  timeUnitCreation,
  timeUnitLastModified,
  timeUnitStorageType,
  getStatisticsAndCharts = () => { },
  subtitleCreation,
  subtitleLastAccess,
  subtitleLastModified,
  subtitleExtension,
  subtitleVolumes,
  subtitleOwners,
  subtitleGroups,
  subtitleDuplicate,
  showPDFPreview = false,
  storageTypeRef,
  duplicateRef,
  creationRef,
  groupsRef,
  ownersRef,
  extensionRef,
  volumesRef,
  lastModifiedRef,
  lastAccessRef,
  searchFilters,
  sizeOfFiles,
  numberOfFiles,
  numberOfFolders,
  search,
  volumeList,
}) {
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(showPDFPreview);
  const [isLoading, setIsLoading] = useState(true);
  const logoColor = "#4f92db";

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  function getUnitName(unit) {
    const units = {
      B: "byte",
      kB: "kilobyte",
      MB: "megabyte",
      GB: "gigabyte",
      TB: "terabyte",
      PB: "petabyte",
      EB: "exabyte",
      ZB: "zettabyte",
      YB: "yottabyte",
      KiB: "kibibyte",
      MiB: "mebibyte",
      GiB: "gibibyte",
      TiB: "tebibyte",
      PiB: "pebibyte",
      EiB: "exbibyte",
      ZiB: "zebibyte",
      YiB: "yobibyte",
    };

    return units[unit] || "";
  }
  const getRatio = (totalSize, partialSize) => {
    return (((totalSize - partialSize) / totalSize) * 100).toFixed(2);
  };
  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `Search-Report-${new Date().toLocaleDateString(
      "en-CA",
      DATE_FORMAT
    )}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const PDFPage = () => (
    <Document>
      <Page size="A4" style={styles.firstPage}>
        <Header />

        <View style={{ ...styles.flexRow, height: 350 }}>
          <SearchFilters />
          <Statistics />
        </View>

        <Card
          title="Creation Date"
          subtitle={subtitleCreation}
          refGraph={creationRef}
          timeUnit={timeUnitCreation}
          others={getRatio(sizeOfFiles, sizeShownDataCreation)}
        />

        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Card
          title="Last Access Date"
          subtitle={subtitleLastAccess}
          refGraph={lastAccessRef}
          timeUnit={timeUnitLastAccess}
          others={getRatio(sizeOfFiles, sizeShownDataLastAccess)}
        />
        <Card
          title="Last Modified Date"
          subtitle={subtitleLastModified}
          refGraph={lastModifiedRef}
          timeUnit={timeUnitLastModified}
          others={getRatio(sizeOfFiles, sizeShownDataLastModified)}
        />
        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Card
          title="Extensions"
          subtitle={subtitleExtension}
          refGraph={extensionRef}
          sizeItems={sizeItemsExtension}
          others={getRatio(sizeOfFiles, sizeShownDataExtension)}
        />
        <Card
          title="Volumes"
          subtitle={subtitleVolumes}
          refGraph={volumesRef}
          sizeItems={sizeItemsVolumes}
          others={getRatio(sizeOfFiles, sizeShownDataVolumes)}
        />
        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Card
          title="Owners"
          subtitle={subtitleOwners}
          refGraph={ownersRef}
          sizeItems={sizeItemsOwners}
          others={getRatio(sizeOfFiles, sizeShownDataOwners)}
        />
        <Card
          title="Groups"
          subtitle={subtitleGroups}
          refGraph={groupsRef}
          sizeItems={sizeItemsGroups}
          others={getRatio(sizeOfFiles, sizeShownDataGroups)}
        />
        <Footer />
      </Page>

      <Page size="A4" style={styles.page}>
        <Card
          title="Duplicate Data"
          subtitle={subtitleDuplicate}
          refGraph={duplicateRef}
          sizeItems={sizeItemsDuplicate}
        />
        <Card
          title="Storage Types"
          subtitle={subtitleStorageType}
          refGraph={storageTypeRef}
          timeUnit={timeUnitStorageType}
        />
        <Footer />
      </Page>
    </Document>
  );

  const Header = () => (
    <>
      <View style={styles.flexRow}>
        <Text
          style={{
            fontSize: 20,
            marginRight: "auto",
            marginBottom: 10,
            fontFamily: "AileronBold",
          }}
        >
          Search Report
        </Text>

        <CloudSodaLogoSVG width={80} color={logoColor} />
      </View>

      <View
        style={{ borderBottom: "0.25px solid black", marginBottom: 15 }}
      ></View>
    </>
  );
  const Footer = () => (
    <View style={styles.footer}>
      <CloudSodaLogoSVG width={40} color={logoColor} />

      <Text style={styles.copyrightText}>
        © {new Date().getFullYear()} CloudSoda. All rights reserved.
      </Text>

      <Text style={styles.footerText}>
        <Link src="https://www.cloudsoda.io">cloudsoda.io</Link>
      </Text>
      <Text
        style={{ ...styles.footerText, marginLeft: "4px" }}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </View>
  );

  const Card = ({
    title,
    subtitle,
    refGraph,
    timeUnit = "",
    sizeItems = "",
    others = null,
  }) => (
    <div style={styles.card}>
      <Text style={styles.subTitle}>{title}</Text>
      <Text style={styles.unitDescription}>
        {title === "Storage Types" ? "Storage cost " : "Size of files "}
        {`(${subtitle})`}
      </Text>

      {refGraph !== null && refGraph.current !== null && (
        <Image
          style={{ alignSelf: "center" }}
          src={refGraph.current.toBase64Image()}
        />
      )}

      {sizeOfFiles != null && others !== null && (
        <Text style={{ ...styles.description, paddingTop: 10 }}>
          {" "}
          Others: {others} %
        </Text>
      )}

      <Description
        title={title}
        subtitle={subtitle}
        timeUnit={timeUnit}
        sizeItems={sizeItems}
      />
    </div>
  );

  const isAllDataLoaded = () => {
    return (
      !foldersIsFetching &&
      !filesIsFetching &&
      storageTypeRef !== null &&
      duplicateRef !== null &&
      creationRef !== null &&
      groupsRef !== null &&
      ownersRef !== null &&
      extensionRef !== null &&
      volumesRef !== null &&
      lastModifiedRef !== null &&
      lastAccessRef !== null &&
      storageTypeRef.current !== null &&
      duplicateRef.current !== null &&
      creationRef.current !== null &&
      groupsRef.current !== null &&
      ownersRef.current !== null &&
      extensionRef.current !== null &&
      volumesRef.current !== null &&
      lastModifiedRef.current !== null &&
      lastAccessRef.current !== null
    );
  };

  const Description = ({ title, subtitle, timeUnit = "", sizeItems = "" }) => {
    let displayText;

    switch (title) {
      case "Creation Date":
      case "Last Access Date":
      case "Last Modified Date":
        displayText = (
          <>
            This chart displays the total size in{" "}
            {subtitle && (
              <Text style={styles.bold}>
                {`${getUnitName(subtitle)}(${subtitle})`}
              </Text>
            )}{" "}
            and the number of files grouped by their{" "}
            {title && (
              <Text style={styles.bold}>
                {title.replace("Date", "").toLowerCase()}
              </Text>
            )}{" "}
            {timeUnit && (
              <Text style={styles.bold}>{timeUnit.toLowerCase()}</Text>
            )}
            .
          </>
        );
        break;
      case "Extensions":
      case "Volumes":
      case "Owners":
      case "Groups":
      case "Duplicate Data":
        displayText = (
          <>
            This chart displays the total size and the number of files of the{" "}
            {sizeItems && <Text style={styles.bold}>{sizeItems}</Text>} most
            used{" "}
            {title && <Text style={styles.bold}>{title.toLowerCase()}</Text>} in{" "}
            {subtitle && (
              <Text style={styles.bold}>
                {`${getUnitName(subtitle)}(${subtitle})`}
              </Text>
            )}
            .
          </>
        );
        break;
      default:
        displayText = (
          <>
            This chart displays the total cost and the number of files per{" "}
            {timeUnit && (
              <Text style={styles.bold}>
                {timeUnit.replace("LY", " ").toLowerCase()}
              </Text>
            )}
            grouped by <Text style={styles.bold}>storage type</Text>.
          </>
        );
        break;
    }

    return <Text style={styles.description}>{displayText}</Text>;
  };

  const SearchFilters = () => (
    <div
      style={{
        ...styles.cardWithoutChart,
        marginRight: 5,
        marginBottom: 5,
      }}
    >
      <Text style={styles.subTitle}>Search Query</Text>

      {search !== "" && <Text style={styles.field}>Search: {search}</Text>}
      {searchFilters.creationBefore !== "" && (
        <Text style={styles.field}>
          Creation Date Before: {searchFilters.creationBefore}
        </Text>
      )}
      {searchFilters.creationAfter !== "" && (
        <Text style={styles.field}>
          Creation Date After: {searchFilters.creationAfter}
        </Text>
      )}
      {searchFilters.lastAccessBefore !== "" && (
        <Text style={styles.field}>
          Last Access Date Before: {searchFilters.lastAccessBefore}
        </Text>
      )}

      {searchFilters.lastAccessAfter !== "" && (
        <Text style={styles.field}>
          Last Access Date After: {searchFilters.lastAccessAfter}
        </Text>
      )}
      {searchFilters.lastModifiedBefore !== "" && (
        <Text style={styles.field}>
          Last Modified Date Before: {searchFilters.lastModifiedBefore}
        </Text>
      )}
      {searchFilters.lastModifiedAfter !== "" && (
        <Text style={styles.field}>
          Last Modified Date After: {searchFilters.lastModifiedAfter}
        </Text>
      )}
      {searchFilters.minimumSize !== "" && (
        <Text style={styles.field}>
          Minimum Size: {searchFilters.minimumSize}{" "}
          {searchFilters.minimumSizeUnit}
        </Text>
      )}
      {searchFilters.maximumSize !== "" && (
        <Text style={styles.field}>
          Maximum Size: {searchFilters.maximumSize}{" "}
          {searchFilters.maximumSizeUnit}
        </Text>
      )}

      {searchFilters.tags && searchFilters.tags.length > 0 && (
        <Text style={styles.field}>Tags: {searchFilters.tags.join(", ")}</Text>
      )}

      {searchFilters.name !== "" && (
        <Text style={styles.field}>Name: {searchFilters.name}</Text>
      )}

      {searchFilters.minimumNumberOfFiles !== "" && (
        <Text style={styles.field}>
          Minimum Number of Files: {searchFilters.minimumNumberOfFiles}
        </Text>
      )}

      {searchFilters.maximumNumberOfFiles !== "" && (
        <Text style={styles.field}>
          Maximum Number of Files: {searchFilters.maximumNumberOfFiles}
        </Text>
      )}

      {searchFilters.minimumNumberOfFolders !== "" && (
        <Text style={styles.field}>
          Minimum Number of Folders: {searchFilters.minimumNumberOfFolders}
        </Text>
      )}

      {searchFilters.maximumNumberOfFolders !== "" && (
        <Text style={styles.field}>
          Maximum Number of Folders: {searchFilters.maximumNumberOfFolders}
        </Text>
      )}

      {searchFilters.volumeIds !== "" && (
        <>
          <Text style={styles.field}>
            Data Sources:{" "}
            {handleVolumeNames(searchFilters.volumeIds, volumeList)}
          </Text>
        </>
      )}

      {searchFilters.owner !== "" && (
        <Text style={styles.field}>Owners:{searchFilters.owner}</Text>
      )}
      {searchFilters.group !== "" && (
        <Text style={styles.field}>Groups: {searchFilters.group}</Text>
      )}

      {searchFilters.extension !== "" && (
        <Text style={styles.field}>Extension: {searchFilters.extension}</Text>
      )}

      {searchFilters.onlyDuplicates !== "" && (
        <Text style={styles.field}>
          {searchFilters.onlyDuplicates === "true"
            ? "Show Only Duplicate"
            : "Show Only Unique"}
        </Text>
      )}

      {searchFilters.showOnlyDeletedFiles !== "" && (
        <Text style={styles.field}>
          {searchFilters.showOnlyDeletedFiles === "true"
            ? "Show only deleted, moved and renamed files"
            : "Show all files"}
        </Text>
      )}

      {searchFilters.ignorePatterns &&
        !searchFilters.ignorePatterns.every((pattern) => pattern === "") && (
          <Text style={styles.field}>
            Ignore Patterns:{" "}
            {searchFilters.ignorePatterns
              .filter((pattern) => pattern !== "")
              .join(", ")}
          </Text>
        )}

      {searchFilters.volumesAndPaths &&
        !searchFilters.volumesAndPaths.every(
          (volumeAndPath) => volumeAndPath === ""
        ) && (
          <Text style={styles.field}>
            Volumes And Paths:{" "}
            {handleVolumeAndPaths(
              searchFilters.volumesAndPaths,
              volumeList
            ).join(", ")}
          </Text>
        )}
    </div>
  );

  const Statistics = () => (
    <div
      style={{
        ...styles.cardWithoutChart,
        marginLeft: 5,
        marginBottom: 5,
      }}
    >
      <Text style={styles.subTitle}>Statistics</Text>
      <Text style={styles.field}>
        Number of Files: {numberOfFiles.toLocaleString("en-US")}
      </Text>
      <Text style={styles.field}>
        Size of Files: {formatSize(sizeOfFiles, baseSize)}
      </Text>
      <Text style={styles.field}>
        Number of Folders: {numberOfFolders.toLocaleString("en-US")}
      </Text>
    </div>
  );

  return (
    <>
      <Button
        disabled={showModal}
        id="searchPDF"
        className="ms-auto"
        color="danger"
        outline
        onClick={() => {
          getStatisticsAndCharts();
          setShowModal(true);
        }}
      >
        {showModal ? (
          <Spinner size="sm" />
        ) : (
          <FontAwesomeIcon icon={faFilePdf} />
        )}
      </Button>
      <UncontrolledTooltip placement="auto" target="searchPDF">
        Preview PDF
      </UncontrolledTooltip>
      <ConfirmModal
        title="Preview"
        customButton={
          showModal && (
            <>
              {isLoading || !isAllDataLoaded() ? (
                <Button disabled color="primary">
                  Loading document...
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    if (buttonRef.current) {
                      buttonRef.current.onClick();
                    }
                  }}
                >
                  Download
                </Button>
              )}
            </>
          )
        }
        closeConfirmation={() => {
          setShowModal(false);
        }}
        showConfirmation={showModal}
        size="xl"
      >
        {showModal && isAllDataLoaded() && (
          <>
            <BlobProvider document={<PDFPage />}>
              {({ blob, url, loading, error }) => {
                if (error !== null) {
                  setShowModal(false);

                  dispatch(setAlert(error.message, "danger"));
                }
                if (blob != null) {
                  setIsLoading(false);
                } else {
                  setIsLoading(true);
                }
                return (
                  <div>
                    <PDFViewer
                      style={{
                        width: "100%",
                        height: "600px",
                        backgroundImage: "url('/icons/loading.gif')",
                        backgroundSize: "10%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      src={url}
                    >
                      {/* prevent null document */}
                      <Document />
                    </PDFViewer>

                    <Button
                      style={{ padding: 0, border: 0 }}
                      color="white"
                      ref={buttonRef}
                      onClick={() => downloadFile(url)}
                    ></Button>
                  </div>
                );
              }}
            </BlobProvider>
          </>
        )}
      </ConfirmModal>
    </>
  );
}
