import {
    SET_FILTERS,
} from '../actions';

function filters(state = { onlyDuplicate: false }, action) {
    switch (action.type) {
        case SET_FILTERS:
            return Object.assign({}, state, {
                onlyDuplicate: action.filters.onlyDuplicate
            })
        default:
            return state
    }
}

export default filters;