import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import ScanButton from "./ScanButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import ArchiwareForm from "./ArchiwareForm";
import TableActions from "../../components/TableActions";
import { scanArchiwares } from "../../api/scan";
import { getVolumeSettings } from "../../api/volume";
import PerformanceModeWarning from "./PerformanceModeWarning";
import Loading from "../../components/Loading";
import { getAll, getArchiwareP5Credentials } from "../../api/archiware";
import { setAlert } from "../../actions/alert";

ArchiwareList.propTypes = {
  archiwares: PropTypes.array,
  total: PropTypes.number,
  storagePriceList: PropTypes.array,
  serverList: PropTypes.array,
  updateArchiware: PropTypes.func,
  deleteArchiware: PropTypes.func,
  fetchArchiwaresListPage: PropTypes.func,
  isFetching: PropTypes.bool,
  scan: PropTypes.func,
  scanAll: PropTypes.func,
  errors: PropTypes.object,
  formValid: PropTypes.bool,
};

function ArchiwareList(props) {
  const {
    archiwares,
    total,
    page,
    size,
    sort,
    direction,
    totalCredentials,
    storagePriceList,
    serverList,
    updateArchiware,
    deleteArchiware,
    fetchArchiwaresListPage,
    isFetching,
    errors,
    formValid,
    openChosenWizardFromSection,
  } = props;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [indexId, setIndexId] = useState("");
  const [storagePriceId, setStoragePriceId] = useState("");
  const [serverId, setServerId] = useState("");
  const [archiwareCredentialsId, setArchiwareCredentialsId] = useState("");
  const [showUpdateArchiwareForm, setShowUpdateArchiwareForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allArchiwares, setAllArchiwares] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const [archiwareIndexes, setArchiwareIndexes] = useState([]);

  const numberOfPages = Math.ceil(total / size);

  const archiwareTitles = [
    { name: "Id", sort: "id" },
    { name: "Name" },
    { name: "Path", sort: "path" },
    { name: "Archiware Index", sort: "indexId" },
    { name: "Server", sort: "volumeSettingEntity.server.name" },
    {
      name: "Storage Type",
      sort: "volumeSettingEntity.storagePrice.description",
    },
    { name: "Action" },
  ];
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (formValid && !isFetching) {
      handleCloseModal();
    }
  }, [formValid, isFetching]);

  useEffect(() => {
    getVolumeSettings(dispatch, auth, 1, sort, direction, 500, ["Archiware"])
      .then((res) => setAllArchiwares(res.elements))
      .catch((err) => console.error(err));
  }, [sort, direction, total]);

  useEffect(() => {
    getAll(dispatch, auth, 1, 500)
      .then((res) => setArchiwareIndexes(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the Archiware P5 Indexes",
            "danger"
          )
        )
      );
  }, [total]);
  useEffect(() => {
    getArchiwareP5Credentials(dispatch, auth, 1, 500)
      .then((res) => setCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the archiware credentials",
            "danger"
          )
        )
      );
  }, [totalCredentials]);

  const handleCloseModal = () => {
    setShowUpdateArchiwareForm(false);
    setShowDeleteConfirmation(false);
    setId("");
    setIsProcessing(false);
    setName("");
    setPath("");
    setIndexId("");
    setStoragePriceId("");
    setServerId("");
    setIsSaveButtonEnabled(true);
  };

  const handleChange = (name, value) => {
    if (name === "name") {
      setName(value);
    } else if (name === "path") {
      setPath(value);
    } else if (name === "indexId") {
      setIndexId(value);
    } else if (name === "archiwareP5Credential") {
      setArchiwareCredentialsId(value);
    } else if (name === "storagePrice") {
      setStoragePriceId(value);
    } else if (name === "server") {
      setServerId(value);
    }
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === sort) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    fetchArchiwaresListPage(page, newSort, newDirection, size);
  };

  const deleteArchiwareVolume = async (deleteId) => {
    setIsProcessing(true);
    await deleteArchiware(deleteId);
    setShowDeleteConfirmation(false);
    setIsProcessing(false);
  };

  const handleScanVolumeIds = (volumeIds) => {
    return archiwareIndexes
      .filter((element) => volumeIds.includes(element.volumeSettingId))
      .map((element) => element.id);
  };

  const listFormated = archiwares.map((archiware) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      archiware.id,
      archiware.name,
      archiware.path,
      archiware.indexId,
      archiware.serverId
        ? serverList.find((server) => server.id === archiware.serverId)?.name
        : "N/A",
      archiware.storagePrice.description,
      <>
        <Button
          onClick={() => {
            setShowUpdateArchiwareForm(true);
            setId(archiware.id);
            setName(archiware.name);
            setPath(archiware.path);
            setIndexId(archiware.indexId);
            setStoragePriceId(archiware.storagePrice.id);
            setServerId(archiware.serverId);
            setArchiwareCredentialsId(archiware.archiwareP5Credential.id);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setShowDeleteConfirmation(true);
            setId(archiware.id);
            setName(archiware.name);
          }}
          color="danger"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <ScanButton
          title="Scan"
          volumeIds={[archiware.volumeSettingId]}
          volumes={allArchiwares}
          scan={(volumeIds, cloneIndex, deltaScan) =>
            scanArchiwares(
              dispatch,
              auth,
              handleScanVolumeIds(volumeIds),
              cloneIndex,
              deltaScan
            )
          }
          hasCloneIndex
          hasDeltaScan
        >
          <PerformanceModeWarning />
          <p>
            Scanning an Archiware P5 can take between a couple minutes and
            several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      "",
      <>
        <Button
          onClick={() => openChosenWizardFromSection("archiware")}
          color="success"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <ScanButton
          title="Scan All"
          volumeIds={allArchiwares.map((archiware) => archiware.id)}
          volumes={allArchiwares}
          scan={(volumeIds, cloneIndex, deltaScan) =>
            scanArchiwares(
              dispatch,
              auth,
              handleScanVolumeIds(volumeIds),
              cloneIndex,
              deltaScan
            )
          }
          hasCloneIndex
          hasDeltaScan
        >
          <PerformanceModeWarning />
          <p>
            Scanning all Archiware P5 can take between a couple minutes and
            several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      </>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h5>Indexes</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={page}
            numberOfPages={numberOfPages}
            numberOfElements={size}
            setNumberOfElements={(numberOfElements) => {
              fetchArchiwaresListPage(1, sort, direction, numberOfElements);
            }}
            selectPage={(newPage) => {
              fetchArchiwaresListPage(newPage, sort, direction, size);
            }}
          />
          <TableResult
            title={"Archiwares"}
            data={listFormated}
            titles={archiwareTitles}
            sort={sort}
            direction={direction}
            changeOrder={handleChangeOrder}
          />
        </div>
      )}

      <ConfirmModal
        showConfirmation={showUpdateArchiwareForm}
        title="Update Archiware P5 Index"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateArchiware(id, {
            name: name,
            path: path,
            indexId: indexId,
            storagePrice: storagePriceId === "" ? null : { id: storagePriceId },
            serverId: serverId === "" ? 1 : serverId,
            archiwareP5Credential:
              archiwareCredentialsId === ""
                ? null
                : { id: archiwareCredentialsId },
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={!isSaveButtonEnabled}
      >
        <ArchiwareForm
          id={id}
          name={name}
          path={path}
          indexId={indexId}
          archiwareCredentialsId={archiwareCredentialsId}
          credentials={credentials}
          storagePriceList={storagePriceList}
          storagePriceId={storagePriceId}
          serverList={serverList}
          serverId={serverId}
          handleChange={handleChange}
          errors={errors}
          setIsSaveButtonEnabled={setIsSaveButtonEnabled}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        isProcessing={isProcessing}
        confirmAction={() => {
          deleteArchiwareVolume(id);
        }}
        closeConfirmation={handleCloseModal}
      >
        <p>Are you sure you want to delete Archiware P5 {name}?</p>
        <p>
          This process will delete the configuration and the indexed data for
          the last index. It could take several minutes.
        </p>
      </ConfirmModal>
    </>
  );
}

export default ArchiwareList;
