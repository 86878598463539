import React, { Component } from "react";
import { Form, Button, FormGroup, Label, Input, Col, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InfoTooltip from "../../components/InfoTooltip";
import MultiInput from "./MultiInput";
import TagField from "./TagField";
import SearchMultiSelect from "./SearchMultiSelect";
import { getSymbolFromBase } from "../../components/FormatSize";
import { connect } from "react-redux";

class AdvancedSearchFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startingPath: "",
      lastAccessAfter: "",
      lastAccessBefore: "",
      creationBefore: "",
      creationAfter: "",
      owner: "",
      extension: "",
      minimumSize: "",
      maximumSize: "",
      minimumSizeUnit: "B",
      maximumSizeUnit: "B",
      volumeIds: [],
      lastModifiedAfter: "",
      lastModifiedBefore: "",
      group: "",
      onlyDuplicates: false,
      showOnlyDeletedFiles: "",
      ignorePatterns: [""],
      tags: [],
      reports: [],
      name: "",
      minimumNumberOfFiles: "",
      maximumNumberOfFiles: "",
      minimumNumberOfFolders: "",
      maximumNumberOfFolders: "",
      selectVolumes: false,
      selectArchives: false,
      selectBuckets: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters !== this.props.filters) {
      for (var name in nextProps.filters) {
        this.setState({
          [name]: nextProps.filters[name],
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleDateChange(date, name) {
    this.setState({
      [name]:
        date === "" || date === null
          ? ""
          : moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
    });
  }

  applyFilters(event) {
    event.preventDefault();
    this.props.applyFilters(this.state);
  }

  render() {
    const {
      startingPath,
      lastAccessAfter,
      lastAccessBefore,
      owner,
      minimumSize,
      maximumSize,
      minimumSizeUnit,
      maximumSizeUnit,
      extension,
      lastModifiedAfter,
      lastModifiedBefore,
      creationAfter,
      creationBefore,
      group,
      onlyDuplicates,
      showOnlyDeletedFiles,
      ignorePatterns,
      name,
      minimumNumberOfFiles,
      maximumNumberOfFiles,
      minimumNumberOfFolders,
      maximumNumberOfFolders
    } = this.state;

    return (
      <Form onSubmit={this.applyFilters}>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <SearchMultiSelect
                setVolumeIds={(volumeIds) =>
                  this.setState({ volumeIds: volumeIds })
                }
                selectedDataSources={this.props.selectedDataSources}
                setSelectedDataSources={this.props.setSelectedDataSources}
                checkboxStatus={this.props.checkboxStatus}
                setCheckboxStatus={this.props.setCheckboxStatus}
              />
            </FormGroup>
            <FormGroup>
              <Label>Starting Path</Label>
              <Input
                id="startingPath"
                type="text"
                placeholder="/Documents"
                name="startingPath"
                value={startingPath}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>
                    Name{" "}
                    <InfoTooltip
                      id="nameTooltip"
                      text={
                        <>
                          Name of the files.
                          <br />
                          * wildcard : Matches zero or more characters.
                          <br />
                          ? wildcard : Matches one character.
                          <br />
                          Example : file* will find all the files that start with file.
                        </>
                      }
                    />
                  </Label>
                  <Input
                    id="name"
                    type="text"
                    placeholder="fileName.txt"
                    name="name"
                    value={name}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>
                    Extensions{" "}
                    <InfoTooltip
                      id="extensionsTooltip"
                      text="Only for files. To filter with multiple extensions, add a comma between extensions Ex: mp4, mov"
                    />
                  </Label>
                  <Input
                    id="extension"
                    type="text"
                    label="Extension"
                    placeholder="mp4"
                    name="extension"
                    value={extension}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Creation Date After</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      type="text"
                      selected={
                        creationAfter === ""
                          ? null
                          : moment(creationAfter).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "creationAfter")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Creation Date Before</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      selected={
                        creationBefore === ""
                          ? null
                          : moment(creationBefore).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "creationBefore")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Last Access Date After</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      type="text"
                      selected={
                        lastAccessAfter === ""
                          ? null
                          : moment(lastAccessAfter).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "lastAccessAfter")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Last Access Date Before</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      selected={
                        lastAccessBefore === ""
                          ? null
                          : moment(lastAccessBefore).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "lastAccessBefore")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Last Modified Date After</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      selected={
                        lastModifiedAfter === ""
                          ? null
                          : moment(lastModifiedAfter).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "lastModifiedAfter")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Last Modified Date Before</Label>
                  <span
                    className="form-control"
                    style={{ border: "none", padding: 0 }}
                  >
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="form-control"
                      selected={
                        lastModifiedBefore === ""
                          ? null
                          : moment(lastModifiedBefore).toDate()
                      }
                      onChange={(value) =>
                        this.handleDateChange(value, "lastModifiedBefore")
                      }
                      dateFormat="yyyy-MM-dd"
                      isClearable={true}
                    />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Tags</Label>
              <TagField
                handleChange={(list) => this.setState({ tags: list })}
              />
            </FormGroup>
          </Col>

          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Owner</Label>
                  <Input
                    id="owner"
                    type="text"
                    placeholder="root"
                    name="owner"
                    value={owner}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Group</Label>
                  <Input
                    id="group"
                    type="text"
                    placeholder="well"
                    name="group"
                    value={group}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Minimum Size</Label>
                  <Input
                    id="minimumSize"
                    type="text"
                    label="minimumSize"
                    placeholder=""
                    name="minimumSize"
                    value={minimumSize}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Unit</Label>
                  <Input
                    type="select"
                    name="minimumSizeUnit"
                    id="minimumSizeUnit"
                    value={minimumSizeUnit === "kB" ? "KB" : minimumSizeUnit}
                    onChange={this.handleInputChange}
                  >
                    <option value="B">Bytes</option>
                    <option>
                      {getSymbolFromBase("KB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("MB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("GB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("TB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("PB", this.props.baseSize)}
                    </option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Maximum Size</Label>
                  <Input
                    id="maximumSize"
                    type="text"
                    label="maximumSize"
                    placeholder=""
                    name="maximumSize"
                    value={maximumSize}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Unit</Label>
                  <Input
                    type="select"
                    name="maximumSizeUnit"
                    id="maximumSizeUnit"
                    value={maximumSizeUnit === "kB" ? "KB" : maximumSizeUnit}
                    onChange={this.handleInputChange}
                  >
                    <option value="B">Bytes</option>
                    <option>
                      {getSymbolFromBase("KB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("MB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("GB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("TB", this.props.baseSize)}
                    </option>
                    <option>
                      {getSymbolFromBase("PB", this.props.baseSize)}
                    </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Minimum Number of Files</Label>
                  <Input
                    id="minimumNumberOfFiles"
                    type="text"
                    label="minimumNumberOfFiles"
                    placeholder=""
                    name="minimumNumberOfFiles"
                    value={minimumNumberOfFiles}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Maximum Number of Files</Label>
                  <Input
                    id="maximumNumberOfFiles"
                    type="text"
                    label="maximumNumberOfFiles"
                    placeholder=""
                    name="maximumNumberOfFiles"
                    value={maximumNumberOfFiles}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Minimum Number of Folders</Label>
                  <Input
                    id="minimumNumberOfFolders"
                    type="text"
                    label="minimumNumberOfFolders"
                    placeholder=""
                    name="minimumNumberOfFolders"
                    value={minimumNumberOfFolders}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Maximum Number of Folders</Label>
                  <Input
                    id="maximumNumberOfFolders"
                    type="text"
                    label="maximumNumberOfFolders"
                    placeholder=""
                    name="maximumNumberOfFolders"
                    value={maximumNumberOfFolders}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Duplicated Status</Label>
                  <Input
                    type="select"
                    name="onlyDuplicates"
                    id="onlyDuplicates"
                    value={onlyDuplicates}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Show all</option>
                    <option value="true">Show only duplicate</option>
                    <option value="false">Show only unique</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Deleted Status</Label>
                  <Input
                    type="select"
                    name="showOnlyDeletedFiles"
                    id="showOnlyDeletedFiles"
                    value={showOnlyDeletedFiles}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Show only active</option>
                    <option value="true">
                      Show only deleted, moved and renamed
                    </option>
                    <option value="false">Show all</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>
                Ignore Patterns{" "}
                <InfoTooltip
                  id="ignorePatternTooltip"
                  text={
                    <>
                      Text ignored in name.
                      <br />
                      * wildcard : Matches zero or more characters.
                      <br />
                      ? wildcard : Matches one character.
                      <br />
                      Example : *.txt will ignore all files ending with .txt.
                    </>
                  }
                />
              </Label>
              <MultiInput
                placeholder={".*"}
                value={ignorePatterns}
                handleChange={(list) => this.setState({ ignorePatterns: list })}
              />
            </FormGroup>
            <FormGroup>
              <Label>Reports</Label>
              <TagField
                handleChange={(list) => this.setState({ reports: list })}
                isReport
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex">
          <Button className="ms-auto" type="submit">
            Apply filters
          </Button>
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { userSettings } = state;
  const baseSize = userSettings.baseValue;

  return { baseSize };
}

export default connect(mapStateToProps)(AdvancedSearchFilters);
