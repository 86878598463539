import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setAlert } from "../../actions/alert";
import { Spinner } from "reactstrap";
import { searchFiles, getSizeOfSearchedFiles } from "../../api/file";
import { searchDirectories } from "../../api/directory";
import SourceReview from "./SourceReview";
import ArchiveDestinationReview from "./ArchiveDestinationReview";
import RestoreDestinationReview from "./RestoreDestinationReview";

export default function SearchSelectionReview({
  userInputObj,
  parseFileDeletionMethod,
  isDirectory,
  setDisableForwardActionButton,
  restoring = false,
  searchText = "",
  setDeltaScanAfter,
}) {
  const dispatch = useDispatch();
  const volumes = useSelector(({ volumes }) => volumes.items);
  const search = useSelector(({ search }) => search);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  const [isFetching, setIsFetching] = useState(false);
  const [validTotalFileSize, setValidTotalFileSize] = useState(0);
  const [numberOfValidFileOrFolders, setNumberOfValidFileOrFolders] =
    useState(0);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [totalNumberOfFilesOrFolders, setTotalNumberOfFilesOrFolders] =
    useState(0);
  const auth = useSelector(({ auth }) => auth);

  const fileType = restoring
    ? ["Archives", "Archiware"]
    : ["crawl.files", "NetworkVolume", "DellPowerScale"];
  const volumesIdsFromSearchFilter = search.filters.volumeIds.length
    ? search.filters.volumeIds
    : null;

  const getListOfValidVolumeIds = (selectedVolumes) => {
    if (selectedVolumes) {
      return volumesIdsFromSearchFilter.filter((volumeId) =>
        volumes.some(
          (volume) =>
            parseInt(volumeId) === volume.id && fileType.includes(volume.type)
        )
      );
    }

    return volumes
      .filter((volume) => fileType.includes(volume.type))
      .map((volume) => volume.id);
  };

  const calculateReviewValues = async () => {
    const validVolumeIds = getListOfValidVolumeIds(search.filters.volumeIds);

    if (!validVolumeIds.length) {
      setDisableForwardActionButton(true);
      setNumberOfValidFileOrFolders(0);
      setValidTotalFileSize(0);
      return;
    }

    const searchFilters = Object.assign({}, search.filters);
    searchFilters.volumeIds = validVolumeIds;

    try {
      setIsFetching(true);
      const searchSizeOnlyByFileType = await getSizeOfSearchedFiles(
        dispatch,
        auth,
        indexingDate,
        searchText,
        searchFilters
      );
      let totalSearchSize = 0;
      let numberOfValidElement = 0;
      let totalNumberOfFilesOrFolders = 0;
      if (isDirectory) {
        const folderSearchResult = await searchDirectories(
          dispatch,
          auth,
          indexingDate,
          searchText,
          0,
          0,
          searchFilters,
          "size",
          "desc"
        );
        numberOfValidElement = folderSearchResult.total;

        totalSearchSize = await getSizeOfSearchedFiles(
          dispatch,
          auth,
          indexingDate,
          searchText,
          search.filters
        );
        totalNumberOfFilesOrFolders = search.folders.total;
      } else {
        const fileSearchResult = await searchFiles(
          dispatch,
          auth,
          indexingDate,
          searchText,
          0,
          0,
          searchFilters,
          "size",
          "desc"
        );
        numberOfValidElement = fileSearchResult.total;

        totalSearchSize = search.searchSize;
        totalNumberOfFilesOrFolders = search.total;
      }

      setNumberOfValidFileOrFolders(numberOfValidElement);
      setValidTotalFileSize(searchSizeOnlyByFileType);
      setTotalFileSize(totalSearchSize);
      setTotalNumberOfFilesOrFolders(totalNumberOfFilesOrFolders);
      if (numberOfValidElement > 0) {
        setDisableForwardActionButton(false);
      }
    } catch (e) {
      dispatch(setAlert(e.message, "danger", 7000));
    }

    setIsFetching(false);
  };

  useEffect(() => {
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, []);

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-2">
          <SourceReview
            fileOrFolder={isDirectory ? "folder" : "file"}
            numberOfValidFileOrFolders={numberOfValidFileOrFolders}
            validTotalFileSize={validTotalFileSize}
            totalFileSize={totalFileSize}
            action={fileType.includes("crawl.files") ? "archive" : "restore"}
            fileType={
              fileType.includes("Archives") ? "Archiware P5" : "On-Premises"
            }
            totalNumberOfFilesOrFolders={totalNumberOfFilesOrFolders}
            addP5Icon={restoring}
          />

          {restoring ? (
            <RestoreDestinationReview location={userInputObj.location} />
          ) : (
            <ArchiveDestinationReview
              indexName={userInputObj.indexName}
              planName={userInputObj.planName}
              parseFileDeletionMethod={parseFileDeletionMethod}
              location={userInputObj.location}
              deltaScanAfter={userInputObj.deltaScanAfter}
              setDeltaScanAfter={setDeltaScanAfter}
            />
          )}
        </div>
      )}
    </>
  );
}
