import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../ducks/search";

export const uploadFiles = async (
  dispatch, 
  auth, 
  dataIntellPaths, 
  collectionId, 
  storageId, 
  storageType
) => {
  const url = `/v1/iconik/jobs`;

  const body = {
    dataIntellPaths: dataIntellPaths,
    collectionId: collectionId,
    storageId: storageId,
    storageType: storageType
  };

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
};

export const uploadFilesFromSearchResult = async (
  dispatch,
  auth,
  indexingDate,
  search,
  isDirectory,
  filters = {},
  collectionId,
  storageId, 
  storageType
) => {
  const url = "/v1/iconik/jobs/search";
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    indexingDAte,
    search,
    isDirectory
  };

  const body = {
    dataIntellPaths: [],
    collectionId: collectionId,
    storageId: storageId,
    storageType: storageType
  };

  return await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    { ...queryParams, ...searchParams },
    "POST",
    JSON.stringify(body)
  );
};

export const getCollections = async (dispatch, auth) => {
  const url = "/v1/iconik/collections";
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken
  );
};

export const getStorages = async (dispatch, auth) => {
  const url = "/v1/iconik/storages";
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken
  );
};
