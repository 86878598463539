import React from "react";
import { Card, CardHeader, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import FormatSize from "../../components/FormatSize";

export default function SingleStatistic(props) {
  const {
    title,
    subtitle,
    link,
    size,
    isFetching
  } = props;

  return (
    <>
      {isFetching ? <Loading /> :
        <Card style={{ marginBottom: "1rem" }}>
          <CardHeader>
            <CardTitle tag="h5">{title}</CardTitle>
            <CardSubtitle tag="h6" className="mb-2 text-muted">{subtitle}</CardSubtitle>
          </CardHeader>
          <CardBody style={{ fontSize: "1.5rem" }}>
            <strong>{size && <Link className="text-decoration-none" to={link}><FormatSize>{size}</FormatSize></Link>}</strong>
          </CardBody>
        </Card>
      }
    </>
  );
}
