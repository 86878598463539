export const SET_ALERT = "SET_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";

export function setAlert(message, type, timer = 8000) {
  return {
    type: SET_ALERT,
    message,
    alertType: type,
    timer,
  };
}

export function dismissAlert() {
  return {
    type: DISMISS_ALERT
  };
}
