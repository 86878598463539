import { useState, useEffect } from "react";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CronHelper from "../../components/CronHelper";
import PropTypes from "prop-types";
import BucketPricingWarning from "./BucketPricingWarning";
import HasBundle from "../../validation/HasBundle";
import { ARCHIWARE_BUNDLE } from "../../constants/bundles";
import FolderTree from "../../components/folderTree/FolderTree";

const SCAN_ALL_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanAllJob";
const SCAN_SERVER_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanServerJob";
const SCAN_VOLUMES_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanVolumesJob";
const SCAN_ALL_BUCKETS_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanAllBucketsJob";
const SCAN_BUCKETS_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanBucketsJob";
const SCAN_ALL_DELLPOWERSCALES_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanAllDellPowerScalesJob";
const SCAN_DELLPOWERSCALES_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanDellPowerScalesJob";
const SCAN_ALL_ARCHIWARES_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanAllArchiwaresJob";
const SCAN_ARCHIWARES_JOB_CLASS_NAME =
  "io.dataintell.visionapi.schedule.job.ScanArchiwaresJob";

ScheduleForm.propTypes = {
  name: PropTypes.string,
  cron: PropTypes.string,
  job: PropTypes.string,
  serverId: PropTypes.number,
  volumesId: PropTypes.array,
  bucketsId: PropTypes.array,
  archiwareIds: PropTypes.array,
  cloneIndex: PropTypes.bool,
  servers: PropTypes.array,
  volumes: PropTypes.array,
  buckets: PropTypes.array,
  archiwares: PropTypes.array,
  handleChange: PropTypes.func,
  isUpdateScheduleForm: PropTypes.bool,
};

function ScheduleForm(props) {
  const {
    name,
    cron,
    active,
    job,
    serverId,
    volumesId,
    bucketsId,
    dellPowerScaleIds,
    archiwareIds,
    cloneIndex,
    deltaScan,
    servers,
    volumes,
    buckets,
    dellPowerScaleStorages,
    archiwares,
    handleChange,
    isUpdateScheduleForm,
    setVolumeIds,
    setBucketIds,
    setDellPowerScaleIds,
    setArchiwareIds,
    setDisableRightButton,
  } = props;

  const [selectedVolumes, setSelectedVolumes] = useState([]);
  const [selectedBuckets, setSelectedBuckets] = useState([]);
  const [selectedDellPowerScales, setSelectedDellPowerScales] = useState([]);
  const [selectedArchiwares, setSelectedArchiwares] = useState([]);
  const [isActiveTooltipOpen, setIsActiveTooltipOpen] = useState(false);
  const [isCloneIndexTooltipOpen, setIsCloneIndexTooltipOpen] = useState(false);
  const [isDeltaScanTooltipOpen, setIsDeltaScanTooltipOpen] = useState(false);

  useEffect(() => {
    setSelectedVolumes(
      volumesId.map((id) => {
        return {
          volumeSetting: { id: id },
          path: "",
        };
      })
    );

    setSelectedBuckets(
      bucketsId.map((id) => {
        return {
          volumeSetting: { id: id },
          path: "",
        };
      })
    );
    setSelectedDellPowerScales(
      dellPowerScaleIds.map((id) => {
        return {
          volumeSetting: { id: id },
          path: "",
        };
      })
    );

    setSelectedArchiwares(
      archiwareIds.map((id) => {
        return {
          volumeSetting: { id: id },
          path: "",
        };
      })
    );
  }, [volumesId, bucketsId, archiwareIds, dellPowerScaleIds]);

  useEffect(() => {
    setDisableRightButton(handleDisableButton(job));
  }, [
    volumesId,
    bucketsId,
    archiwareIds,
    dellPowerScaleIds,
    name,
    cron,
    job,
    serverId,
  ]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    handleChange(name, value);
  };

  const updateSelectedVolumes = (volume) => {
    const updatedSelectedItems = [...selectedVolumes];

    const itemIndex = updatedSelectedItems.findIndex(
      (item) => item.volumeSetting.id === volume.volumeId
    );

    if (itemIndex !== -1) {
      updatedSelectedItems.splice(itemIndex, 1);
    } else {
      updatedSelectedItems.push({
        volumeSetting: { id: volume.volumeId },
        path: "/",
      });
    }

    setSelectedVolumes(updatedSelectedItems);
    setVolumeIds(
      updatedSelectedItems.map(
        (updatedSelectedItem) => updatedSelectedItem.volumeSetting.id
      )
    );
  };

  const updateSelectedBuckets = (volume) => {
    const updatedSelectedItems = [...selectedBuckets];

    const itemIndex = updatedSelectedItems.findIndex(
      (item) => item.volumeSetting.id === volume.volumeId
    );

    if (itemIndex !== -1) {
      updatedSelectedItems.splice(itemIndex, 1);
    } else {
      updatedSelectedItems.push({
        volumeSetting: { id: volume.volumeId },
        path: "/",
      });
    }

    setSelectedBuckets(updatedSelectedItems);
    setBucketIds(
      updatedSelectedItems.map(
        (updatedSelectedItem) => updatedSelectedItem.volumeSetting.id
      )
    );
  };
  const updateSelectedDellPowerScales = (volume) => {
    const updatedSelectedItems = [...selectedDellPowerScales];

    const itemIndex = updatedSelectedItems.findIndex(
      (item) => item.volumeSetting.id === volume.volumeId
    );

    if (itemIndex !== -1) {
      updatedSelectedItems.splice(itemIndex, 1);
    } else {
      updatedSelectedItems.push({
        volumeSetting: { id: volume.volumeId },
        path: "/",
      });
    }

    setSelectedDellPowerScales(updatedSelectedItems);
    setDellPowerScaleIds(
      updatedSelectedItems.map(
        (updatedSelectedItem) => updatedSelectedItem.volumeSetting.id
      )
    );
  };
  const updateSelectedArchiwares = (volume) => {
    const updatedSelectedItems = [...selectedArchiwares];

    const itemIndex = updatedSelectedItems.findIndex(
      (item) => item.volumeSetting.id === volume.volumeId
    );

    if (itemIndex !== -1) {
      updatedSelectedItems.splice(itemIndex, 1);
    } else {
      updatedSelectedItems.push({
        volumeSetting: { id: volume.volumeId },
        path: "/",
      });
    }

    setSelectedArchiwares(updatedSelectedItems);
    setArchiwareIds(
      updatedSelectedItems.map(
        (updatedSelectedItem) => updatedSelectedItem.volumeSetting.id
      )
    );
  };

  const handleDisableButton = (job) => {
    const commonCondition = name.length === 0 || cron.length === 0;

    switch (job) {
      case SCAN_VOLUMES_JOB_CLASS_NAME:
        return commonCondition || volumesId.length === 0;
      case SCAN_BUCKETS_JOB_CLASS_NAME:
        return commonCondition || bucketsId.length === 0;
      case SCAN_ARCHIWARES_JOB_CLASS_NAME:
        return commonCondition || archiwareIds.length === 0;
      case SCAN_DELLPOWERSCALES_JOB_CLASS_NAME:
        return commonCondition || dellPowerScaleIds.length === 0;
      case SCAN_SERVER_JOB_CLASS_NAME:
        return commonCondition || serverId === 0;
      default:
        return commonCondition || job.length === 0;
    }
  };

  return (
    <>
      <Form>
        <FormGroup row>
          <Label for="name" sm={3}>
            Name
          </Label>
          <Col sm={9}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Ex: Scan 1"
              value={name}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="cron" sm={3}>
            Cron <CronHelper />
          </Label>
          <Col sm={9}>
            <Input
              id="cron"
              name="cron"
              type="text"
              placeholder="Ex: 0 0 */4 * * ?"
              value={cron}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="active" sm={3}>
            Active
            <FontAwesomeIcon
              id="activeIcon"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="left"
              isOpen={isActiveTooltipOpen}
              target="activeIcon"
              toggle={() => setIsActiveTooltipOpen(!isActiveTooltipOpen)}
            >
              Whether or not the current schedule will trigger for the given
              cron.
            </Tooltip>
          </Label>
          <Col sm={9} style={{ marginTop: 5 }}>
            <FormGroup switch>
              <Input
                type="switch"
                id="active"
                name="active"
                checked={active}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="job" sm={3}>
            Job
          </Label>
          <Col sm={9}>
            <Input
              id="job"
              name="job"
              type="select"
              value={job}
              onChange={handleInputChange}
              disabled={isUpdateScheduleForm}
            >
              <option value="">Select job</option>
              <option value={SCAN_ALL_JOB_CLASS_NAME}>
                Scan all On-Premise Storages
              </option>
              <option value={SCAN_SERVER_JOB_CLASS_NAME}>
                Scan On-Premise Storages by servers
              </option>
              <option value={SCAN_VOLUMES_JOB_CLASS_NAME}>
                Scan On-Premise Storages
              </option>
              <option value={SCAN_ALL_BUCKETS_JOB_CLASS_NAME}>
                Scan all Cloud Storages
              </option>
              <option value={SCAN_BUCKETS_JOB_CLASS_NAME}>
                Scan Cloud Storages
              </option>

              <option value={SCAN_ALL_DELLPOWERSCALES_JOB_CLASS_NAME}>
                Scan all Dell PowerScale Storages
              </option>
              <option value={SCAN_DELLPOWERSCALES_JOB_CLASS_NAME}>
                Scan Dell PowerScale Storages
              </option>
              <HasBundle requiredBundle={ARCHIWARE_BUNDLE} hasOverlay={false}>
                <>
                  <option value={SCAN_ALL_ARCHIWARES_JOB_CLASS_NAME}>
                    Scan all Archiware P5 Indexes
                  </option>
                  <option value={SCAN_ARCHIWARES_JOB_CLASS_NAME}>
                    Scan Archiware P5 Indexes
                  </option>
                </>
              </HasBundle>
            </Input>
          </Col>
        </FormGroup>
        {job === SCAN_ALL_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <p>
                This type of job will launch a scan of all the On-Premises
                Storages.
              </p>
            </Col>
          </Row>
        )}
        {job === SCAN_SERVER_JOB_CLASS_NAME && (
          <FormGroup row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <FormGroup>
                <Input
                  name="serverId"
                  type="select"
                  value={serverId}
                  onChange={handleInputChange}
                >
                  <option value={0}>Select server</option>
                  {servers.map((server) => {
                    return (
                      <option key={server.id} value={server.id}>
                        {server.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <p>
                This type of job will launch a scan of all the On-Premises
                Storages on the selected server.
              </p>
            </Col>
          </FormGroup>
        )}
        {job === SCAN_VOLUMES_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <FormGroup
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <FolderTree
                  selectedFolders={selectedVolumes}
                  volumes={volumes}
                  volumeSelector={true}
                  hasFiles={false}
                  hasRowBackground={false}
                  isDefaultOpen={false}
                  hasActions={false}
                  showOnlyFirstColumn={true}
                  hasHeader={false}
                  onCheckboxClick={(e) => {
                    updateSelectedVolumes(e);
                  }}
                  onNameClickFolder={() => { }}
                  isOpenable={false}
                  maxHeight="206px"
                ></FolderTree>
              </FormGroup>
              <p>
                This type of job will launch a scan of the selected On-Premises
                Storages.
              </p>
            </Col>
          </Row>
        )}
        {job === SCAN_BUCKETS_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <FormGroup
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <FolderTree
                  selectedFolders={selectedBuckets}
                  volumes={buckets}
                  volumeSelector={true}
                  hasFiles={false}
                  hasRowBackground={false}
                  isDefaultOpen={false}
                  hasActions={false}
                  showOnlyFirstColumn={true}
                  hasHeader={false}
                  onCheckboxClick={(e) => {
                    updateSelectedBuckets(e);
                  }}
                  onNameClickFolder={() => { }}
                  isOpenable={false}
                  maxHeight="206px"
                ></FolderTree>
              </FormGroup>

              <p>
                This type of job will launch a scan of the selected Cloud
                Storages.
              </p>
              <BucketPricingWarning />
            </Col>
          </Row>
        )}
        {job === SCAN_ALL_BUCKETS_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <p>
                This type of job will launch a scan of all the Cloud Storages.
              </p>
              <BucketPricingWarning />
            </Col>
          </Row>
        )}
        {job === SCAN_DELLPOWERSCALES_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <FormGroup
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <FolderTree
                  selectedFolders={selectedDellPowerScales}
                  volumes={dellPowerScaleStorages}
                  volumeSelector={true}
                  hasFiles={false}
                  hasRowBackground={false}
                  isDefaultOpen={false}
                  hasActions={false}
                  showOnlyFirstColumn={true}
                  hasHeader={false}
                  onCheckboxClick={(e) => {
                    updateSelectedDellPowerScales(e);
                  }}
                  onNameClickFolder={() => {}}
                  isOpenable={false}
                  maxHeight="206px"
                ></FolderTree>
              </FormGroup>
              <p>
                This type of job will launch a scan of the selected Dell
                PowerScale Storages.
              </p>
            </Col>
          </Row>
        )}
        {job === SCAN_ALL_DELLPOWERSCALES_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <p>
                This type of job will launch a scan of all Dell PowerScale
                Storages.
              </p>
            </Col>
          </Row>
        )}
        {job === SCAN_ARCHIWARES_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <FormGroup
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              >
                <FolderTree
                  selectedFolders={selectedArchiwares}
                  volumes={archiwares}
                  volumeSelector={true}
                  hasFiles={false}
                  hasRowBackground={false}
                  isDefaultOpen={false}
                  hasActions={false}
                  showOnlyFirstColumn={true}
                  hasHeader={false}
                  onCheckboxClick={(e) => {
                    updateSelectedArchiwares(e);
                  }}
                  onNameClickFolder={() => { }}
                  isOpenable={false}
                  maxHeight="206px"
                ></FolderTree>
              </FormGroup>
              <p>
                This type of job will launch a scan of the selected Archiware P5
                Indexes.
              </p>
            </Col>
          </Row>
        )}
        {job === SCAN_ALL_ARCHIWARES_JOB_CLASS_NAME && (
          <Row>
            <Col sm={3}></Col>
            <Col sm={9}>
              <p>
                This type of job will launch a scan of all the Archiware P5
                Indexes.
              </p>
            </Col>
          </Row>
        )}
      </Form>
      <div className="mt-3" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
        <div>
          <span className="me-2">
            Clone Index
            <FontAwesomeIcon
              id="cloneIndex"
              className="ms-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="left"
              isOpen={isCloneIndexTooltipOpen}
              target="cloneIndex"
              toggle={() =>
                setIsCloneIndexTooltipOpen(!isCloneIndexTooltipOpen)
              }
            >
              Whether or not to clone the index of the last scanned day. Ignored
              if there is already one for the day.
            </Tooltip>
          </span>
          <Input
            className="me-3"
            name="cloneIndex"
            type="checkbox"
            disabled={
              deltaScan &&
              (job === SCAN_ALL_ARCHIWARES_JOB_CLASS_NAME ||
                job === SCAN_ARCHIWARES_JOB_CLASS_NAME ||
                job === SCAN_DELLPOWERSCALES_JOB_CLASS_NAME ||
                job === SCAN_ALL_DELLPOWERSCALES_JOB_CLASS_NAME)
            }
            checked={cloneIndex}
            onChange={handleInputChange}
          />
        </div>
        {(job === SCAN_ALL_ARCHIWARES_JOB_CLASS_NAME ||
          job === SCAN_ARCHIWARES_JOB_CLASS_NAME ||
          job === SCAN_DELLPOWERSCALES_JOB_CLASS_NAME ||
          job === SCAN_ALL_DELLPOWERSCALES_JOB_CLASS_NAME) && (
          <div>
            <span className="me-2">
              Delta Scan
              <FontAwesomeIcon
                id="deltaScan"
                className="ms-1"
                icon={faQuestionCircle}
              />
              <Tooltip
                placement="left"
                isOpen={isDeltaScanTooltipOpen}
                target="deltaScan"
                toggle={() =>
                  setIsDeltaScanTooltipOpen(!isDeltaScanTooltipOpen)
                }
              >
                This is a partial scan instead of a full scan. It is a faster
                approach that only update the files that have changed since
                the previous scan.
              </Tooltip>
            </span>
            <Input
              className="me-3"
              name="deltaScan"
              type="checkbox"
              checked={deltaScan}
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ScheduleForm;
