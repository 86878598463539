import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroup,
  Row,
  Col,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
} from "reactstrap";
import xbytes from "xbytes";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import DropdownButton from "../../components/DropdownButton";
import FolderModal from "../FolderModal";
import FormatSize, {
  formatSize,
  getSymbolFromBase,
} from "../../components/FormatSize";
import { connect } from "react-redux";

class ProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paths: [],
      addPath: "",
      name: "",
      description: "",
      allocatedSize: "",
      search: "",
      unit: getSymbolFromBase("TB", this.props.baseSize),
      isSearchDirectoryOpen: false,
      isAddFolderManuallyOpen: false,
      isFileManagementOpen: false,
    };
    this.addPath = this.addPath.bind(this);
    this.addManualPath = this.addManualPath.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePathChange = this.handlePathChange.bind(this);
    this.addProject = this.addProject.bind(this);
    this.searchPathSuggestions = this.searchPathSuggestions.bind(this);
    this.initializeProject = this.initializeProject.bind(this);
  }

  componentDidMount() {
    this.initializeProject();
  }

  initializeProject() {
    if (!this.props.project) {
      this.setState({
        name: "",
        description: "",
        allocatedSize: "",
        unit: getSymbolFromBase("TB", this.props.baseSize),
        paths: [],
      });
      return;
    }
    const { allocatedSize, name, description } = this.props.project;

    const filesizeObject = formatSize(
      allocatedSize,
      this.props.baseSize,
      null,
      "object"
    );
    if (filesizeObject.exponent > 4) {
      filesizeObject.unit = getSymbolFromBase("TB", this.props.baseSize);
    }
    if (this.props.projectEntity) {
      const projectItems = this.props.projectEntity.projectShares.flatMap(
        (share) => share.projectItems
      );

      this.setState({
        paths: projectItems.map((p) => {
          return {
            path: p.path,
            volumeSetting: {
              id: p.volumeId,
              name: p.volumeName,
            },
            size: p.sizeOfFiles,
          };
        }),
      });
    }
    console.log(filesizeObject);
    this.setState({ name: name || "" });
    this.setState(
      { unit: filesizeObject.unit } ||
      getSymbolFromBase("TB", this.props.baseSize)
    );
    this.setState({
      allocatedSize:
        formatSize(
          allocatedSize,
          this.props.baseSize,
          filesizeObject.exponent,
          "object"
        ).value || "",
    });
    this.setState({ description: description || "" });
  }

  addProject() {
    this.props.saveProject(
      this.state.name,
      this.state.description,
      this.state.paths,
      xbytes.parseSize(`${this.state.allocatedSize} ${this.state.unit}`)
    );
  }

  addPath(path) {
    let paths = this.state.paths;
    const { volume } = path;
    const formatedPath = {
      path: path.path,
      volumeSetting: volume,
      size: path.size,
    };
    paths.push(formatedPath);
    this.setState({ paths });
  }

  addManualPath(event) {
    event.preventDefault();

    let paths = this.state.paths;
    const path = event.target.addPath.value;
    const select = event.target.volumeSelect;
    const formatedPath = {
      path: path,
      volumeSetting: {
        id: select.value,
        name: select.options[select.selectedIndex].text,
        size: 0,
      },
    };
    paths.push(formatedPath);
    this.setState({ paths });
    event.target.value = "";
  }

  removePath(index, event) {
    event && event.preventDefault();
    let paths = this.state.paths;
    paths.splice(index, 1);
    this.setState({ paths });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handlePathChange(event, index) {
    const value = event.target.value;
    let paths = this.state.paths;
    paths[index] = value;
    this.setState({ paths });
  }

  searchPathSuggestions(event) {
    this.handleInputChange(event);
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    const target = event.target;
    this._timeout = setTimeout(() => {
      const value = target.type === "checkbox" ? target.checked : target.value;
      if (value && value !== "") {
        this.props.searchPathSuggestions(value);
      }
    }, 1000);
  }

  render() {
    const {
      name,
      description,
      addPath,
      allocatedSize,
      search,
      unit,
      paths,
      isFileManagementOpen,
    } = this.state;

    const { projectPathSuggestions, volumeList, isFetching, errors } =
      this.props;

    const AddFolderComponent = (folder) => {
      const isVolume = folder.path === "/";
      const folderPath = isVolume ? "" : folder.path;
      const folderVolumeId = folder.volumeId;

      if (
        paths.filter(
          (path) =>
            path.path === folderPath && path.volumeSetting.id === folderVolumeId
        ).length > 0
      ) {
        this.state.paths.map((path, index) => {
          if (
            path.path === folderPath &&
            path.volumeSetting.id === folderVolumeId
          )
            this.removePath(index);
        });
      } else {
        this.addPath({
          path: folderPath,
          volume: {
            id: folderVolumeId,
            name: folder.volumeName,
          },
          size: folder.size,
        });
      }
    };

    let pathSize = 0;

    const pathList =
      this.state.paths.length === 0 ? (
        <div className="m-2">No tracked folders</div>
      ) : (
        this.state.paths.map((path, index) => {
          pathSize += path.size || 0;
          return (
            <div className="m-2" key={index}>
              <Button
                size="sm"
                type="submit"
                color="danger"
                className="me-2"
                onClick={(event) => this.removePath(index, event)}
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
              {path.volumeSetting.name}
              {path.path}{" "}
              {path.size ? <>({<FormatSize>{path.size}</FormatSize>})</> : ""}
            </div>
          );
        })
      );

    let pathListSuggestions = "";

    const suggestions = this.props.projectPathSuggestions
      .filter((suggestion) => {
        return (
          this.state.paths.findIndex(
            (path) =>
              path.path === suggestion.path &&
              path.volumeSetting.id === suggestion.volume.id
          ) < 0
        );
      })
      .map((path, index) => {
        return (
          <div className="mb-1 ms-2" key={index}>
            <Button
              size="sm"
              color="success"
              className="me-1"
              onClick={() => this.addPath(path)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            {path.volume.name}
            {path.path} (<FormatSize>{path.size}</FormatSize>)
          </div>
        );
      });

    if (projectPathSuggestions.length > 0) {
      pathListSuggestions = <FormGroup>{suggestions}</FormGroup>;
    }

    const optionsVolume = volumeList.map((volume) => {
      return (
        <option key={volume.id} value={volume.id}>
          {volume.name}
        </option>
      );
    });

    const formInstance = (
      <Form>
        <FormGroup>
          <Label>Name</Label>{" "}
          <Input
            onChange={this.handleInputChange}
            name="name"
            value={name}
            required
            type="text"
            placeholder="Project name"
            invalid={errors["name"] !== undefined}
          />
          <FormFeedback>{errors["name"]}</FormFeedback>
        </FormGroup>{" "}
        <FormGroup>
          <Label>Description</Label>{" "}
          <Input
            type="text"
            onChange={this.handleInputChange}
            name="description"
            value={description}
            placeholder="A description for the project"
            invalid={errors["description"] !== undefined}
          />
          <FormFeedback>{errors["description"]}</FormFeedback>
        </FormGroup>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label>Allocated Size</Label>
              <Input
                id="allocatedSize"
                type="text"
                label="allocatedSize"
                name="allocatedSize"
                value={allocatedSize}
                onChange={this.handleInputChange}
                placeholder="A soft size limit for the project, for example 200"
                required
                invalid={errors["allocatedSize"] !== undefined}
              />
              <FormFeedback>{errors["allocatedSize"]}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>Unit</Label>
              <Input
                type="select"
                name="unit"
                id="unit"
                value={unit === "kB" ? "KB" : unit}
                onChange={this.handleInputChange}
              >
                <option value="B">Bytes</option>
                <option>{getSymbolFromBase("KB", this.props.baseSize)}</option>
                <option>{getSymbolFromBase("MB", this.props.baseSize)}</option>
                <option>{getSymbolFromBase("GB", this.props.baseSize)}</option>
                <option>{getSymbolFromBase("TB", this.props.baseSize)}</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>
            Tracked Folders (Actual Size <FormatSize>{pathSize}</FormatSize>)
          </Label>
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: 5,
              backgroundColor: "white",
            }}
          >
            {pathList}
          </div>
        </FormGroup>
        <DropdownButton dropdownTitle="Add folders from" buttonColor="success">
          <DropdownItem
            onClick={() => this.setState({ isFileManagementOpen: true })}
          >
            File Management
          </DropdownItem>
          <DropdownItem
            onClick={() => this.setState({ isSearchDirectoryOpen: true })}
          >
            A search
          </DropdownItem>
          <DropdownItem
            onClick={() => this.setState({ isAddFolderManuallyOpen: true })}
          >
            A path
          </DropdownItem>
        </DropdownButton>
      </Form>
    );

    return (
      <>
        {formInstance}
        <Button
          className="float-end mt-3"
          color="primary"
          onClick={() => this.addProject()}
        >
          Save project
        </Button>
        <Button
          outline
          style={{ marginRight: 10 }}
          className="float-end mt-3"
          color="secondary"
          onClick={() => this.props.goBack()}
        >
          Cancel
        </Button>

        <FolderModal
          selectedFolders={paths}
          onNameClickFolder={AddFolderComponent}
          onCheckboxClick={AddFolderComponent}
          modalTitle={"Add Folder"}
          isOpen={isFileManagementOpen}
          setIsOpen={() => this.setState({ isFileManagementOpen: false })}
        />

        <Modal
          size="lg"
          isOpen={this.state.isSearchDirectoryOpen}
          toggle={() => this.setState({ isSearchDirectoryOpen: false })}
        >
          <ModalHeader>Search for Folder</ModalHeader>
          <ModalBody>
            <>
              <FormGroup>
                <Input
                  onChange={this.searchPathSuggestions}
                  name="search"
                  value={search}
                  type="text"
                  placeholder="Directory name"
                />
              </FormGroup>
              {isFetching === true ? (
                <Loading />
              ) : (
                <span>{pathListSuggestions}</span>
              )}
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.setState({ isSearchDirectoryOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.isAddFolderManuallyOpen}
          toggle={() => this.setState({ isAddFolderManuallyOpen: false })}
        >
          <ModalHeader>Add Folder Manually</ModalHeader>
          <ModalBody>
            <fieldset>
              <form onSubmit={(event) => this.addManualPath(event, false)}>
                <FormGroup>
                  <Label>Volume</Label>
                  <Input name="volumeSelect" type="select" placeholder="select">
                    {optionsVolume}
                  </Input>
                </FormGroup>
                <InputGroup>
                  <Button type="submit" color="success">
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>

                  <Input
                    onChange={this.handleInputChange}
                    name="addPath"
                    value={addPath}
                    type="text"
                    placeholder="/path/of/the/directory"
                  />
                </InputGroup>
              </form>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.setState({ isAddFolderManuallyOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userSettings } = state;
  const baseSize = userSettings.baseValue;

  return { baseSize };
}

export default connect(mapStateToProps)(ProjectForm);
