import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "reactstrap";

export default function DisplayToggle(props) {
  const {
    activeButton,
    setActiveButton,
    icons = [],
    buttonColor = "secondary",
  } = props;

  return (
    <ButtonGroup>
      {icons.map((icon, index) => {
        return (
          <Button
            key={index}
            color={buttonColor}
            outline
            onClick={() => setActiveButton(index)}
            active={activeButton === index}
          >
            <FontAwesomeIcon icon={icon} size="sm" />
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
