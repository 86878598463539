import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  FormGroup,
  Input,
} from "reactstrap";

import { getSearchRequestParamsV2 } from "../../ducks/search";
import Loading from "../../components/Loading";
import StackedBarsChart from "../../components/StackedBarsChart";
import { getMonthlyCost } from "../../utils/pricePerVolume";
import PDFChartButton from "../../components/PDFChartButton";
import { CHART_COLORS } from "../../constants/chartColors";
import { formatSize } from "../../components/FormatSize";

export function StorageTypeChart({
  title,
  addFilters,
  lastAnalyticsSearch,
  filters,
  search,
  setRef = () => { },
  setSubtitle = () => { },
  setTimeUnit = () => { },
}) {
  const volumeSettings = useSelector(
    ({ volumeSettings }) => volumeSettings.items
  );
  const storagePrices = useSelector(({ storagePrices }) => storagePrices.items);
  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const [isFetching, setIsFetching] = useState(false);
  const [volumes, setVolumes] = useState([]);

  const labels = storagePrices.map((storage) => storage.description);
  const [dateIntervalType, setDateIntervalType] = useState("YEARLY");
  const totalCostByVolumeType = labels.map(() => 0);

  const chartRef = useRef();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsFetching(true);
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName: "volumeName",
      size: 50,
      indexingDate: activeDate,
    };

    const fetchedVolumes = await fetchWithAuthV2(dispatch, url, accessToken, {
      ...queryParams,
      ...searchParams,
    });
    setVolumes(fetchedVolumes);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [lastAnalyticsSearch]);

  useEffect(() => {
    setRef(chartRef);
    setSubtitle(storagePrices[0].currencySymbol);
  }, [chartRef]);

  useEffect(() => {
    setTimeUnit(dateIntervalType);
  }, [dateIntervalType]);

  function getExistingVolumes(volume) {
    return volumeSettings.find((volumeSetting) => {
      let volumeSettingName = volumeSetting.name;
      if (volumeSetting.type === "AWS-S3") {
        volumeSettingName = volumeSettingName.replace("S3-BUCKET-", "");
      }
      return volumeSettingName === volume.field;
    });
  }

  const mapDataSet = volumes
    .map((volume, index) => {
      const costArray = labels.map(() => 0);
      const volumeSettingExist = getExistingVolumes(volume);
      if (!!volumeSettingExist) {
        const volumeStorageObj = volumeSettingExist.storagePrice;
        let volumePrice = getMonthlyCost(volume.size, volumeStorageObj.price);
        const storageTypeIndexPosition = labels.indexOf(
          volumeStorageObj.description
        );

        switch (dateIntervalType) {
          case "YEARLY":
            volumePrice *= 12;
            break;

          case "QUARTERLY":
            volumePrice *= 3;
            break;

          default:
            break;
        }

        costArray[storageTypeIndexPosition] = volumePrice.toFixed(2);
        totalCostByVolumeType[storageTypeIndexPosition] += volumePrice;
        return {
          backgroundColor: CHART_COLORS[index],
          data: costArray.filter((value) => value !== 0),
          label: volume.field,
        };
      } else {
        return null;
      }
    })
    .filter((v) => v);

  const mapVolumeTypeDataForMeans = () => {
    const toCalculateMeansByVolumeType = labels.map(() => []);
    volumes.forEach((volume) => {
      const volumeSettingExist = getExistingVolumes(volume);
      if (!!volumeSettingExist) {
        const volumeStorageObj = volumeSettingExist.storagePrice;
        let volumePrice = getMonthlyCost(volume.size, volumeStorageObj.price);
        const storageTypeIndexPosition = labels.indexOf(
          volumeStorageObj.description
        );

        switch (dateIntervalType) {
          case "YEARLY":
            volumePrice *= 12;
            break;

          case "QUARTERLY":
            volumePrice *= 3;
            break;

          default:
            break;
        }

        toCalculateMeansByVolumeType[storageTypeIndexPosition].push(
          parseFloat(volumePrice.toFixed(2))
        );
      }
    });
    return toCalculateMeansByVolumeType;
  };

  const mapSizeAndFilesAmount = () => {
    return volumes.map((volume) => {
      const filesizeObject = formatSize(volume.size, baseSize, null, "object");
      return {
        size: formatSize(volume.size, baseSize),
        numberOfFiles: volume.count,
      };
    });
  };

  const mapVolumeTitles = () => {
    return volumes.map((volume) => volume.field);
  };

  const intervalPicker = () => {
    return (
      <FormGroup>
        <Input
          type="select"
          placeholder="select"
          value={dateIntervalType}
          onChange={(event) => {
            const target = event.target;
            const value = target.value;
            setDateIntervalType(value);
          }}
          bsSize="sm"
        >
          <option value="MONTHLY">Monthly</option>
          <option value="QUARTERLY">Quarterly</option>
          <option value="YEARLY">Yearly</option>
        </Input>
      </FormGroup>
    );
  };

  const filteredLabels = labels.filter(
    (label, index) => totalCostByVolumeType[index] !== 0
  );
  const filteredTotalByLabel = totalCostByVolumeType.filter(
    (value) => value !== 0
  );

  const handleOnBarClick = (field) => {
    if (!!field) {
      const names = ["volumeName"];
      const values = [field];
      addFilters(names, values);
    }
  };

  return (
    <Card style={{ marginBottom: 10 }}>
      <CardHeader>
        <CardTitle tag="h5">
          <span style={{ fontWeight: "bold" }}>{title}</span>
          <span style={{ float: "right", marginLeft: 10 }}>
            <PDFChartButton
              chartRef={chartRef}
              title={title}
              subTitle={
                storagePrices[0] ? storagePrices[0].currencySymbol : "N/A"
              }
              timeUnit={dateIntervalType}
            />
          </span>
          <span className={"float-end"}>{intervalPicker()}</span>
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Storage cost (
          {storagePrices[0] ? storagePrices[0].currencySymbol : "N/A"})
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            <StackedBarsChart
              data={mapDataSet}
              dataCount={mapSizeAndFilesAmount()}
              labels={filteredLabels}
              chartRef={chartRef}
              volumeTitles={mapVolumeTitles()}
              totalByLabel={filteredTotalByLabel}
              toCalculateMeansByVolumeType={mapVolumeTypeDataForMeans()}
              onClick={(value) => handleOnBarClick(value)}
              storagePrices={storagePrices}
            />
            <div
              style={{ position: "absolute", left: "-9999px", top: "-9999px" }}
            >
              <StackedBarsChart
                data={mapDataSet}
                height={350}
                width={600}
                dataCount={mapSizeAndFilesAmount()}
                labels={filteredLabels}
                volumeTitles={mapVolumeTitles()}
                totalByLabel={filteredTotalByLabel}
                chartRef={chartRef}
                toCalculateMeansByVolumeType={mapVolumeTypeDataForMeans()}
                onClick={(value) => handleOnBarClick(value)}
                storagePrices={storagePrices}
                isCustomLabel={true}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
}
