import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

export default function FolderAnalyticsButton(props) {
  const {
    volumeId,
    path
  } = props;

  const navigate = useNavigate();

  const getFolderAnalytics = () => {
    navigate(`/folders?volumeId=${volumeId}&path=${path}`);
  };

  return (
    <>
      <FontAwesomeIcon
        id="goToFolderAnalytics"
        icon={faChartLine}
        onClick={(e) => {
          e.stopPropagation();
          getFolderAnalytics();
        }}
      />
      <UncontrolledTooltip target="goToFolderAnalytics" placement="auto">
        See analytics of folder
      </UncontrolledTooltip>
    </>
  );
}