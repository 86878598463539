import React, { Component } from "react";
import { Badge, Tooltip } from "reactstrap";

class InfoTooltip extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <span>
        <Badge id={this.props.id} color="secondary">
          Info
        </Badge>
        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target={this.props.id}
          toggle={this.toggle}
          children={this.props.text}
        />
      </span>
    );
  }
}

export default InfoTooltip;
