import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../ducks/search";

export const createStorageManagerConfig = async (
  dispatch,
  auth,
  credentials
) => {
  const url = `/v1/storageManager/configs`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(credentials)
  );
};

export const getAllStorageManagerConfigsPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/storageManager/configs?page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};

export const updateStorageManagerConfig = async (
  dispatch,
  auth,
  id,
  credentials
) => {
  const url = `/v1/storageManager/configs/${id}`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(credentials)
  );
};

export const deleteStorageManagerConfig = async (dispatch, auth, id) => {
  const url = `/v1/storageManager/configs/${id}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "DELETE", null);
};

export const createStorageManagerMapping = async (dispatch, auth, mapping) => {
  const url = `/v1/storageManager/volumeMappings`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(mapping)
  );
};

export const getStorageManagerVolumeMappings = async (
  dispatch,
  auth,
  page = 1,
  sort = "id",
  direction = "asc",
  size = 200
) => {
  const url = `/v1/storageManager/volumeMappings?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};

export const updateStorageManagerMapping = async (
  dispatch,
  auth,
  id,
  mapping
) => {
  const url = `/v1/storageManager/volumeMappings/${id}`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(mapping)
  );
};

export const deleteStorageManagerMapping = async (dispatch, auth, id) => {
  const url = `/v1/storageManager/volumeMappings/${id}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "DELETE", null);
};

export const archiveListOfFiles = async (dispatch, auth, dataIntellPaths) => {
  const url = `/v1/storageManager/archiveselections`;
  const body = { dataIntellPaths: dataIntellPaths };

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
};

export const archiveSearchResult = async (
  dispatch,
  auth,
  indexingDate,
  search,
  isDirectory,
  filters = {}
) => {
  const url = "/v1/storageManager/archiveselections/search";
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    indexingDate,
    search,
    isDirectory
  };

  return await fetchWithAuthV2(dispatch, url, auth.accessToken, { ...queryParams, ...searchParams }, "POST", null);
};

export const restoreListOfFiles = async (
  dispatch,
  auth,
  dataIntellPaths,
  relocate
) => {
  const url = `/v1/storageManager/restoreselections`;
  const body = { dataIntellPaths: dataIntellPaths, relocate };

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
};

export const restoreSearchResult = async (
  dispatch,
  auth,
  indexingDate,
  search,
  isDirectory,
  filters = {},
  relocate
) => {
  const url = "/v1/storageManager/restoreselections/search";
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    indexingDate,
    search,
    isDirectory
  };

  const body = {
    dataIntellPaths: [],
    relocate: relocate,
  };
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    { ...queryParams, ...searchParams },
    "POST",
    JSON.stringify(body)
  );
};

export const getFileById = async (dispatch, auth, id, indexingDate) => {
  const url = `/v1/storageManager/files/${id}?indexingDate=${indexingDate}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};
