import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  moveData,
  moveDataFromSearchResult,
  getSodaVolumeMappings,
} from "../../api/soda";
import WizardModal from "../WizardModal";
import SelectSodaActionStep from "./SelectSodaActionStep";
import TargetSodaActionStep from "./TargetSodaActionStep";
import { setAlert } from "../../actions/alert";
import SodaSearchReview from "./SodaSearchReview";
import SodaManualReview from "./SodaManualReview";
import { Spinner } from "reactstrap";

const emptySodaActionObj = {
  action: "",
  jobAlias: "",
  target: {
    volume: "",
    volumeName: "",
    path: "",
    conflictHandling: "OVERWRITE",
  },
};

export default function SodaActionWizard({
  showWizard,
  setShowWizard,
  selectedFiles,
  useSearchResultForActions,
  search,
  isDirectory,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const volumes = useSelector(({ volumes }) => volumes.items);
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector((state) => state.dates.activeDate);
  const searchFilters = useSelector((state) => state.search.filters);

  const [stepIndex, setStepIndex] = useState(0);
  const [sodaActionObj, setSodaActionObj] = useState(emptySodaActionObj);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [sodaVolumeMappings, setSodaVolumeMappings] = useState([]);

  useEffect(() => {
    getSodaVolumeMappings(dispatch, auth).then((res) =>
      setSodaVolumeMappings(res.elements)
    );
  }, []);

  const hasDirectories = () => {
    if (isDirectory) {
      return true;
    }

    return selectedFiles.find((file) => file.isDirectory);
  };

  const STEP_TITLES = ["Select Transfer Action", "Configure Target", "Review"];

  const steps = [
    <SelectSodaActionStep
      sodaActionObj={sodaActionObj}
      setStepIndex={setStepIndex}
      stepIndex={stepIndex}
      setSodaActionObj={setSodaActionObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
    />,
    <TargetSodaActionStep
      sodaActionObj={sodaActionObj}
      setSodaActionObj={setSodaActionObj}
      volumes={volumes}
      disableForwardActionButton={disableForwardActionButton}
      setDisableForwardActionButton={setDisableForwardActionButton}
    />,
    useSearchResultForActions ? (
      <SodaSearchReview
        sodaActionObj={sodaActionObj}
        setDisableForwardActionButton={setDisableForwardActionButton}
        searchText={search}
        sodaVolumeMappings={sodaVolumeMappings}
        isDirectory={isDirectory}
      />
    ) : (
      <SodaManualReview
        sodaActionObj={sodaActionObj}
        selectedFolders={selectedFiles}
        setDisableForwardActionButton={setDisableForwardActionButton}
        sodaVolumeMappings={sodaVolumeMappings}
        isDirectory={isDirectory}
      />
    ),
  ];

  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setSodaActionObj(emptySodaActionObj);
    setStepIndex(0);
    setShowWizard(false);
    setIsFetching(false);
    setDisableForwardActionButton(false);
  };

  const launchTransfer = async () => {
    setIsFetching(true);
    setDisableForwardActionButton(true);

    let targetName = "";

    if (sodaActionObj.target.path !== "/") {
      const splitArr = sodaActionObj.target.path.split("/");
      targetName = splitArr[splitArr.length - 1];
    }

    const body = {
      name: sodaActionObj.jobAlias,
      transferType: sodaActionObj.action.toUpperCase(),
      conflictHandling: sodaActionObj.target.conflictHandling,
      target: {
        name: targetName,
        path: sodaActionObj.target.path,
        volumeId: sodaActionObj.target.volume,
        isDirectory: true,
      },
      sources: selectedFiles.map((source) => ({
        name: source.name,
        path: source.path,
        volumeId: source.volumeId,
        isDirectory: source.isDirectory,
      })),
    };

    const resolvedPromiseActions = (res) => {
      dispatch(
        setAlert(
          <>
            A job for the data transfer is now running. You can monitor the job
            by clicking
            <a
              className="text-primary"
              onClick={() => navigate("/settings/jobs")}
            >
              {" "}
              here
            </a>
          </>,
          "success",
          9000
        )
      );
      onWizardExit();
    };

    const rejectedPromiseActions = (err) => {
      console.log(err);
      dispatch(
        setAlert(
          "Something went wrong while trying to start the data transfer. Please try again.",
          "danger",
          9000
        )
      );
      onWizardExit();
    };

    if (useSearchResultForActions) {
      moveDataFromSearchResult(
        dispatch,
        auth,
        body,
        indexingDate,
        search,
        isDirectory,
        searchFilters
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    } else {
      moveData(dispatch, auth, body)
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Start":
        launchTransfer();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (stepIndex === STEPS_LENGTH - 1) {
      return "Start";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      title="Transfer with CloudSoda"
      subTitle={STEP_TITLES[stepIndex]}
      onWizardExit={onWizardExit}
      stepsLength={STEPS_LENGTH}
      stepIndex={stepIndex + 1}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={isFetching ? <Spinner /> : handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
      showFooter={stepIndex > 0}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}
