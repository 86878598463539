import moment from "moment";
import { Row, Col, Badge } from 'reactstrap';
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import FormatSize from "../../components/FormatSize";
import DateLabelWithTooltip from "../../components/DateLabelWithTooltip";

const formatDate = (value) => moment(value).format("lll");

function FolderContent(props) {
  const {
    indexingDate,
    size,
    lastAccess,
    lastModified,
    creationDate,
    group,
    owner,
    inode,
    numberOfFiles,
    numberOfFolders
  } = props.folder;

  return (
    <Row>
      <Col lg={4}>
        <Row>
          <Col lg={6} xs={6}>
            <h6>Size</h6>
            <h6>Number of folders</h6>
            <h6>Number of files</h6>
          </Col>
          <Col lg={5} xs={6}>
            <h6><Badge color="secondary"><FormatSize>{size}</FormatSize></Badge></h6>
            <h6><Badge color="secondary">{numberOfFolders.toLocaleString("en-US")}</Badge></h6>
            <h6><Badge color="secondary">{numberOfFiles.toLocaleString("en-US")}</Badge></h6>
          </Col>
        </Row>
      </Col>
      <Col lg={5}>
        <Row>
          <Col lg={6} xs={6}>
            <h6>Creation date</h6>
            <h6><DateLabelWithTooltip dateLabel={"lastModified"} /></h6>
            <h6><DateLabelWithTooltip dateLabel={"lastAccess"} /></h6>
            <h6>Indexing date</h6>
          </Col>
          <Col lg={5} xs={6}>
            <h6><Badge color="secondary">{returnNAOnNull(creationDate, formatDate)}</Badge></h6>
            <h6><Badge color="secondary">{returnNAOnNull(lastModified, formatDate)}</Badge></h6>
            <h6><Badge color="secondary">{returnNAOnNull(lastAccess, formatDate)}</Badge></h6>
            <h6><Badge color="secondary">{returnNAOnNull(indexingDate, formatDate)}</Badge></h6>
          </Col>
        </Row>
      </Col>
      <Col lg={3}>
        <Row>
          <Col lg={4} xs={6}>
            <h6>Group</h6>
            <h6>Owner</h6>
            <h6>Inode</h6>
          </Col>
          <Col lg={4} xs={6}>
            <h6><Badge color="secondary">{returnNAOnNull(group)}</Badge></h6>
            <h6><Badge color="secondary">{returnNAOnNull(owner)}</Badge></h6>
            <h6><Badge color="secondary">{returnNAOnNull(inode)}</Badge></h6>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default FolderContent;
