import React, { useEffect } from "react";
import { Input, Label } from "reactstrap";

function ServerSelectionConfiguration({
  dataObj,
  setDataObj,
  servers,
  serverName,
  setServerName,
  dataSource,
}) {
  useEffect(() => {
    if (dataObj.server === null) {
      setDataObj((prev) => ({
        ...prev,
        server: servers[0].id,
        serverName: servers[0].name,
      }));
    }
  }, [servers, setDataObj]);

  return (
    <div>
      <Label>The server that will scan the {dataSource}</Label>
      <Input
        type="select"
        name="serverId"
        value={serverName}
        onChange={(e) => {
          const inputValue = e.target.value;
          const selectedServer = servers.find(
            (server) => server.name === inputValue
          );
          setDataObj((prev) => ({
            ...prev,
            server: selectedServer.id,
            serverName: selectedServer.name,
          }));
          setServerName(inputValue);
        }}
      >
        {servers.map((server, index) => (
          <option key={index} value={server.name}>
            {server.name}
          </option>
        ))}
      </Input>
    </div>
  );
}

export default ServerSelectionConfiguration;
