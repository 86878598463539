import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function SizeValidation({ validTotalFileSize, action }) {
  const TEN_TB_IN_BYTES = 10995116277760;

  return (
    <>
      {validTotalFileSize >= TEN_TB_IN_BYTES && (
        <div className="d-flex align-items-center mb-2">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ marginRight: "15px", transform: "scale(1.3)" }}
          />
          <span>
            You are about to {action} more than 10 TB
            of data. It could take a considerable amount of time to complete the
            process.
          </span>
        </div>
      )}
    </>
  );
}
