import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import InfoTooltip from "./InfoTooltip";
import { useNavigate } from "react-router-dom";
import { getDateInThePastByMonths } from "../utils/getPastDate";

function getPercentageColor(sizePercentage) {
  if (sizePercentage > 70) { return "#FF0000" }
  else if (sizePercentage > 50) { return "#FF5F1F" }
  else if (sizePercentage > 30) { return "#FFC107" }
  else return "#000";
}

export default function StorageCostCard(props) {
  const {
    selectedCostInterval,
    setSelectedCostInterval,
    baseURL,
    totalCost,
    currencySymbol,
    duplicateCost,
    duplicatePercentage,
    coldData3MonthsCost,
    coldData3MonthsPercentage,
    coldData6MonthsCost,
    coldData6MonthsPercentage,
    coldData1YearCost,
    coldData1YearPercentage,
    margin
  } = props;

  const navigate = useNavigate();

  return (
    <Card className={margin}>
      <CardHeader tag="h5">
        <span>
          Storage Cost{" "}
          <InfoTooltip
            id="storageCostTooltip"
            text="Storage cost is calculated by multiplying the cost per GB of storage by the actual storage used. Price of storage varies by storage type."
          />
        </span>
        <Form className="float-end">
          <Input
            type="select"
            value={selectedCostInterval}
            onChange={(e) => {
              setSelectedCostInterval(e.target.value);
            }}
            bsSize="sm"
          >
            <option>Daily</option>
            <option>Weekly</option>
            <option>Monthly</option>
            <option>Quaterly</option>
            <option>Yearly</option>
          </Input>
        </Form>
      </CardHeader>
      <CardBody>
        <Row>
          <Col 
            sm={4} 
            style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <h5>{selectedCostInterval} Cost</h5>
            <h4 
              style={{ 
                display: "flex", 
                alignItems: "center", 
                height: "50%",
                color: "#007bff", 
                cursor: "pointer",
                fontWeight: "bold",
                textAlign: "center"
              }}
              onClick={() => navigate(baseURL)}
            >
              {totalCost} {currencySymbol}
            </h4>
          </Col>
          <Col sm={8} style={{ borderLeft: "1px solid black" }}>
            <h5>Potential Savings</h5>
            <Row>
              <Col sm={6} style={{ paddingRight: "5px" }}>
                <span>Duplicate Cost</span>
              </Col>
              <Col sm={2} style={{ textAlign: "end", paddingLeft: "5px", paddingRight: "5px" }}>
                <span style={{ color: getPercentageColor(duplicatePercentage) }}>{duplicatePercentage} %</span>
              </Col>
              <Col sm={4} style={{ textAlign: "end", paddingLeft: "5px" }}>
                <strong
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => navigate(baseURL + "&onlyDuplicates=true")}
                >
                  {duplicateCost} {currencySymbol}
                </strong>
              </Col>
              <Col sm={6} style={{ paddingRight: "5px" }}>
                <span>Cold Data - 3 Months</span>
              </Col>
              <Col sm={2} style={{ textAlign: "end", paddingLeft: "5px", paddingRight: "5px" }}>
                <span style={{ color: getPercentageColor(coldData3MonthsPercentage) }}>{coldData3MonthsPercentage} %</span>
              </Col>
              <Col sm={4} style={{ textAlign: "end", paddingLeft: "5px" }}>
                <strong
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => navigate(baseURL + `&lastAccessBefore=${getDateInThePastByMonths(3)}`)}
                >
                  {coldData3MonthsCost} {currencySymbol}
                </strong>
              </Col>
              <Col sm={6} style={{ paddingRight: "5px" }}>
                <span>Cold Data - 6 Months</span>
              </Col>
              <Col sm={2} style={{ textAlign: "end", paddingLeft: "5px", paddingRight: "5px" }}>
                <span style={{ color: getPercentageColor(coldData6MonthsPercentage) }}>{coldData6MonthsPercentage} %</span>
              </Col>
              <Col sm={4} style={{ textAlign: "end", paddingLeft: "5px" }}>
                <strong
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => navigate(baseURL + `&lastAccessBefore=${getDateInThePastByMonths(6)}`)}
                >
                  {coldData6MonthsCost} {currencySymbol}
                </strong>
              </Col>
              <Col sm={6} style={{ paddingRight: "5px" }}>
                <span>Cold Data - 1 Year</span>
              </Col>
              <Col sm={2} style={{ textAlign: "end", paddingLeft: "5px", paddingRight: "5px" }}>
                <span style={{ color: getPercentageColor(coldData1YearPercentage) }}>{coldData1YearPercentage} %</span>
              </Col>
              <Col sm={4} style={{ textAlign: "end", paddingLeft: "5px" }}>
                <strong
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => navigate(baseURL + `&lastAccessBefore=${getDateInThePastByMonths(12)}`)}
                >
                  {coldData1YearCost} {currencySymbol}
                </strong>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}