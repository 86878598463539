import { useState, useEffect } from "react";
import { Col, FormGroup, Row, Tooltip } from "reactstrap";
import NodeActions from "./NodeActions";
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import { getMonthlyCostByVolume } from "../../utils/pricePerVolume";
import moment from "moment";
import Checkbox from "./Checkbox";
import { useRef } from "react";
import { ROW_COLOR, getChildNodeFromTreeNodes } from "./FolderTree";
import { getTreeNodeChildrenStyle } from "./FolderTree";
import { useSelector } from "react-redux";
import DeltaChart from "./DeltaChart";
import ListFileTags from "../tags/ListFileTags";
import FormatSize from "../FormatSize";

export default function TreeNode(props) {
  const {
    file,
    hasCheckboxes,
    hasRowBackground = true,
    showOnlyFirstColumn = false,
    hasActions,
    valueToMarge = 1,
    treeNodes,
    setTreeNodes,
    positionInTree,
    isTagFileOpen,
    setIsTagFileOpen,
    isFound = false,
    isSelectAllVisibleTriggered,
    setIsSelectAllVisibleTriggered,
    isSelectNoneTriggered,
    setIsSelectNoneTriggered,
    setShownFileDetails,
    showLastColumn,
    isOdd = true,
    dynamicColumnTitle,
    checkboxRef,
    onCheckboxClick,
    children,
  } = props;

  const [isActionBarOpen, setIsActionBarOpen] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);

  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings);

  const cbRef = useRef();
  const nameTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(nameTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isTagFileOpen) {
      setIsActionBarOpen(false);
    }
  }, [isTagFileOpen]);

  const getRowColor = () => {
    if (isFound) {
      return ROW_COLOR.isFound;
    } else if (isRowHovered) {
      return ROW_COLOR.onHover;
    } else if (isOdd) {
      return ROW_COLOR.isOdd;
    } else {
      return ROW_COLOR.isEven;
    }
  };

  const getDynamicColumnValue = () => {
    if (
      dynamicColumnTitle === "creationDate" ||
      dynamicColumnTitle === "lastAccess" ||
      dynamicColumnTitle === "lastModified" ||
      dynamicColumnTitle === "lastAccessFolder" ||
      dynamicColumnTitle === "lastModifiedFolder" ||
      dynamicColumnTitle === "indexingDate"
    ) {
      return returnNAOnNull(file[dynamicColumnTitle], (value) =>
        moment(value).format("lll")
      );
    } else if (dynamicColumnTitle === "monthlyCost") {
      return getMonthlyCostByVolume(
        volumeSettings.items,
        file.volumeId,
        file.size
      );
    } else if (dynamicColumnTitle === "deltaChartColumn") {
      const parentNode = getChildNodeFromTreeNodes(treeNodes, [0]);
      return (
        <DeltaChart
          deltaFile={file}
          parentSize={
            parentNode.file.size !== -1
              ? parentNode.file.size
              : Math.abs(parentNode.file.delta)
          }
        />
      );
    } else if (
      dynamicColumnTitle === "numberOfFiles" ||
      dynamicColumnTitle === "numberOfFolders"
    ) {
      return returnNAOnNull(file[dynamicColumnTitle]).toLocaleString("en-US");
    } else if (dynamicColumnTitle === "customTag") {
      return (
        <div>
          <ListFileTags tags={file[dynamicColumnTitle] ?? []} />
        </div>
      );
    } else {
      return returnNAOnNull(file[dynamicColumnTitle]);
    }
  };

  const handleMouseEnterForName = () => {
    if (!isTagFileOpen) {
      setIsActionBarOpen(true);
      nameTimeoutRef.current = setTimeout(() => {
        setIsNameTooltipOpen(true);
      }, 1000);
    }
  };

  const handleMouseLeaveForName = () => {
    if (!isTagFileOpen) {
      setIsActionBarOpen(false);
      clearTimeout(nameTimeoutRef.current);
      setIsNameTooltipOpen(false);
    }
  };

  return (
    <Row
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      style={{
        backgroundColor: hasRowBackground && getRowColor(),
      }}
    >
      <Col sm={showOnlyFirstColumn ? 12 : showLastColumn ? 6 : 9}>
        <FormGroup check style={{ marginLeft: `${valueToMarge}rem` }}>
          {hasCheckboxes && (
            <Checkbox
              treeNodes={treeNodes}
              setTreeNodes={setTreeNodes}
              positionInTree={positionInTree}
              isSelectAllVisibleTriggered={isSelectAllVisibleTriggered}
              setIsSelectAllVisibleTriggered={setIsSelectAllVisibleTriggered}
              isSelectNoneTriggered={isSelectNoneTriggered}
              onCheckboxClick={onCheckboxClick}
              setIsSelectNoneTriggered={setIsSelectNoneTriggered}
              checkboxRef={checkboxRef ?? cbRef}
            />
          )}
          <div
            onMouseEnter={handleMouseEnterForName}
            onMouseLeave={handleMouseLeaveForName}
            className="ms-1 d-flex"
          >
            {children}
            {hasActions && isActionBarOpen && (
              <span className="ms-2" style={{ flexShrink: 0 }}>
                <NodeActions
                  treeNodes={treeNodes}
                  setTreeNodes={setTreeNodes}
                  positionInTree={positionInTree}
                  isTagFileOpen={isTagFileOpen}
                  setIsTagFileOpen={setIsTagFileOpen}
                  setShownFileDetails={setShownFileDetails}
                />
              </span>
            )}
            <Tooltip
              placement="top-start"
              isOpen={isNameTooltipOpen}
              target={"id" + file.fileId}
            >
              {file.name === "" ? file.volumeName : file.name}
            </Tooltip>
          </div>
        </FormGroup>
      </Col>

      {!showOnlyFirstColumn && (
        <>
          <Col
            sm={showLastColumn ? 2 : 3}
            style={getTreeNodeChildrenStyle(file)}
          >
            {file.size === -1 ? "—" : <FormatSize>{file.size}</FormatSize>}
          </Col>
          {showLastColumn && (
            <Col sm={4} style={getTreeNodeChildrenStyle(file)}>
              {getDynamicColumnValue()}
            </Col>
          )}
        </>
      )}
    </Row>
  );
}
