import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { setAlert } from "../../actions/alert";
import {
  createNetworkVolumeCredentials,
  deleteNetworkVolumeCredential,
  getAllNetworkVolumeCredentialsPaginated,
  updateNetworkVolumeCredential,
} from "../../api/networkVolume";
import ConfirmModal from "../../components/ConfirmModal";
import TableActions from "../../components/TableActions";
import TableResult from "../../components/TableResult";
import OnPremisesCredentialForm from "./OnPremisesCredentialForm";
import Loading from "../../components/Loading";

export default function OnPremisesCredentialList(props) {
  const { dispatch } = props;

  const [onPremisesCredentials, setOnPremisesCredentials] = useState([]);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsDataSourceOnPremCreds")
      ? Number(
          window.localStorage.getItem("numberOfElementsDataSourceOnPremCreds")
        )
      : 10,
    page: 1,
  });
  const [total, setTotal] = useState();
  const [showCreateCredentialsModal, setShowCreateCredentialsModal] =
    useState(false);
  const [showUpdateCredentialsModal, setShowUpdateCredentialsModal] =
    useState(false);
  const [
    showDeleteCredentialsConfirmation,
    setShowDeleteCredentialsConfirmation,
  ] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState("");
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    fetchCredentials(
      pagination.page,
      pagination.size,
      pagination.sort,
      pagination.direction
    );
  }, [pagination.page, pagination.size, pagination.sort, pagination.direction]);

  const numberOfPages = Math.ceil(total / pagination.size);

  const onPremisesCredentialsTitle = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Action" },
  ];

  const fetchCredentials = async (page, size, sort, direction) => {
    setIsFetching(true);

    try {
      const result = await getAllNetworkVolumeCredentialsPaginated(
        dispatch,
        auth,
        page,
        size,
        sort,
        direction
      );
      setOnPremisesCredentials(result.elements);
      setTotal(result.total);
    } catch (err) {
      const message = err.message || "Unable to fetch On-Premises credentials";
      dispatch(setAlert(message, "danger"));
    }

    setIsFetching(false);
  };

  const createCredentials = async (credentials) => {
    try {
      await createNetworkVolumeCredentials(dispatch, auth, credentials);
      setPagination((prev) => ({ ...prev, page: 1 }));
      await fetchCredentials(
        1,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert("On-Premises credentials was created successfully", "success")
      );
    } catch (err) {
      const message = err.message || "Unable to create On-Premises credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const updateCredentials = async (id, credentials) => {
    try {
      await updateNetworkVolumeCredential(dispatch, auth, id, credentials);
      setPagination((prev) => ({ ...prev, page: 1 }));
      await fetchCredentials(
        1,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert("On-Premises credentials was updated successfully", "success")
      );
    } catch (err) {
      const message = err.message || "Unable to update On-Premises credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const deleteCredentials = async (id) => {
    try {
      await deleteNetworkVolumeCredential(dispatch, auth, id);
      setPagination((prev) => ({ ...prev, page: 1 }));
      await fetchCredentials(
        1,
        pagination.size,
        pagination.sort,
        pagination.direction
      );
      dispatch(
        setAlert("On-Premises credentials was deleted successfully", "success")
      );
    } catch (err) {
      const message = err.message || "Unable to delete On-Premises credentials";
      dispatch(setAlert(message, "danger"));
    }
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }

    setPagination((prev) => ({
      ...prev,
      sort: newSort,
      direction: newDirection,
    }));
  };

  const listFormated = onPremisesCredentials.map((cred) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };

    lineObject.columnData = [
      cred.id,
      cred.name,
      <>
        <Button
          onClick={() => {
            setId(cred.id);
            setName(cred.name);
            setDomain(cred.domain);
            setUsername(cred.username);
            setShowUpdateCredentialsModal(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setId(cred.id);
            setName(cred.name);
            setShowDeleteCredentialsConfirmation(true);
          }}
          color="danger"
          style={{ marginLeft: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateCredentialsModal(true);
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  const handleCloseModal = () => {
    setShowCreateCredentialsModal(false);
    setShowUpdateCredentialsModal(false);
    setShowDeleteCredentialsConfirmation(false);
    setId(null);
    setName("");
    setUsername("");
    setPassword("");
    setDomain("");
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem(
      "numberOfElementsDataSourceOnPremCreds",
      numberOfElements
    );
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  return (
    <>
      <h5>Credentials</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(page) =>
              setPagination((prev) => ({ ...prev, page: page }))
            }
          />
          <TableResult
            title="Credentials"
            data={listFormated}
            titles={onPremisesCredentialsTitle}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={handleChangeOrder}
          />
        </div>
      )}
      <ConfirmModal
        showConfirmation={showCreateCredentialsModal}
        title="Add On-Premises Credentials"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createCredentials({
            name: name,
            username: username,
            password: password,
            domain: domain,
          });
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableCreateButton}
      >
        <OnPremisesCredentialForm
          name={name}
          setName={setName}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          domain={domain}
          setDomain={setDomain}
          setDisableCreateButton={setDisableCreateButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUpdateCredentialsModal}
        title="Update On-Premises Credentials"
        buttonColor="primary"
        buttonText="Update"
        confirmAction={() => {
          updateCredentials(id, {
            name: name,
            username: username,
            password: password,
            domain: domain,
          });
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableCreateButton}
      >
        <OnPremisesCredentialForm
          name={name}
          setName={setName}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          domain={domain}
          setDomain={setDomain}
          setDisableCreateButton={setDisableCreateButton}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showDeleteCredentialsConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteCredentials(id);
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        Are you sure you want to delete credential {name}?
      </ConfirmModal>
    </>
  );
}
