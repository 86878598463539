import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";

class RectangleChart extends Component {
  render() {
    const { data, labels, colors, links } = this.props;
    const sum = data.reduce((a, b) => a + b, 0);
    let startPosition = 0;
    let rectangles = [];
    let tooltips = [];

    data.forEach((value, index) => {
      const id = `tooltip${Math.random()
        .toString(36)
        .substr(2, 9)}`;
      const width = Math.abs((value / sum) * 100);
      rectangles.push(
        <rect
          key={`tooltip${index}`}
          id={id}
          onClick={() => links !== undefined && this.props.handleClick(links[index])}
          x={startPosition + "%"}
          style={{ fill: colors[index], cursor: links !== undefined && "pointer" }}
          width={width + "%"}
          height="10"
        />
      );
      if (labels !== undefined) {
        tooltips.push(
          <UncontrolledTooltip key={id} placement="top" target={id}>
            {labels[index]}
          </UncontrolledTooltip>
        );
      }
      startPosition += width;
    });

    return (
      <>
        <svg
          className="animation"
          width="100%"
          height="10"
          style={{ transition: "width 2s" }}
        >
          {rectangles}
        </svg>
        {labels !== undefined && tooltips}
      </>
    );
  }
}

export default RectangleChart;
