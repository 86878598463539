export function getMonthlyCostByVolume(volumeSettings, volumeId, size) {
  const volumeSetting = volumeSettings.find(v => v.id === volumeId);
  if (volumeSetting === undefined || volumeSetting.storagePrice === null) {
    return "N/A";
  }
  const storagePrice = volumeSetting.storagePrice;
  const price = storagePrice.price;
  const currencySymbol = storagePrice.currencySymbol;
  return getMonthlyCostFormatted(size, price, currencySymbol);
}

export function getCostFormatted(sizeInBytes, pricePerGbPerYear, currencySymbol, dividedBy) {
  const monthlyCost = getCost(sizeInBytes, pricePerGbPerYear, dividedBy);
  return `${monthlyCost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySymbol}`;
}

export function getMonthlyCostFormatted(sizeInBytes, pricePerGbPerYear, currencySymbol) {
  return getCostFormatted(sizeInBytes, pricePerGbPerYear, currencySymbol, 12);
}

export function getCost(sizeInBytes, pricePerGbPerYear, dividedBy) {
  if (sizeInBytes === undefined) { console.error("Error : sizeInBytes is", sizeInBytes); }
  if (pricePerGbPerYear === undefined) { console.error("Error : pricePerGbPerYear is", pricePerGbPerYear); }

  const verifiedSizeInBytes = sizeInBytes ? sizeInBytes : 0;
  const verifiedPricePerGbPerYear = pricePerGbPerYear ? pricePerGbPerYear : 0;

  const sizeInGb = verifiedSizeInBytes / (1024 * 1024 * 1024);
  return ((sizeInGb * verifiedPricePerGbPerYear) / dividedBy);
}

export function getMonthlyCost(sizeInBytes, pricePerGbPerYear) {
  return getCost(sizeInBytes, pricePerGbPerYear, 12);
}