import React from "react";
import { Input, Form, FormGroup, Label, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";

ResetPasswordForm.propTypes = {
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  resetPasswordErrors: PropTypes.object,
  handleChange: PropTypes.func
}

function ResetPasswordForm(props) {
  const { newPassword, confirmPassword, resetPasswordErrors, handleChange } = props;

  return (
    <Form>
        <FormGroup>
          <Label for="newPassword">New password</Label>
          <Input
            onChange={event => handleChange(event.target.name, event.target.value)}
            value={newPassword}
            type="password"
            name="newPassword"
            id="newPassword"
            invalid={resetPasswordErrors["newPassword"] !== undefined}
          />
          <FormFeedback>{resetPasswordErrors["newPassword"]}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="confirmPassword">Confirm password</Label>
          <Input
            onChange={event => handleChange(event.target.name, event.target.value)}
            value={confirmPassword}
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            invalid={resetPasswordErrors["confirmPassword"] !== undefined}
          />
          <FormFeedback>{resetPasswordErrors["confirmPassword"]}</FormFeedback>
        </FormGroup>
      </Form>
  );
}

export default ResetPasswordForm;
