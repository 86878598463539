import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialOptionStyle = {
    cursor: "pointer",
    padding: "15px",
    border: "0.5px white solid"
}

export default function SourceSelectionItem({
    itemIcon,
    iconSize,
    onClickFunction,
    title,
    description,
    marginRight = "25px"
}) {
    const [itemOptionStyle, setItemOptionStyle] = useState(initialOptionStyle);

    const itemOptionOnHover = () => {
        setItemOptionStyle({
            cursor: "pointer",
            border: "0.5px lightgrey solid",
            boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            padding: "15px",
        });
    }

    const itemOptionOnHoverExit = () => {
        setItemOptionStyle(initialOptionStyle);
    }

    return (
        <>
            <div
                className="d-flex"
                style={itemOptionStyle}
                onClick={() => {
                    onClickFunction();
                }}
                onMouseEnter={itemOptionOnHover}
                onMouseLeave={itemOptionOnHoverExit}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        size={iconSize}
                        icon={itemIcon}
                        style={{ marginRight: marginRight }}
                    />
                </div>
                <div>
                    <h4>{title}</h4>
                    <div>{description}</div>
                </div>
            </div>
        </>
    );
}