import { fetchWithAuth } from "../actions/requestHelper";

export const getAllNotifications = async (dispatch, auth) => {
    const url = "/v1/notifications";
    return await fetchWithAuth(dispatch, url, auth.accessToken);
}

export const getNotificableJobTypes = async (dispatch, auth) => {
    const url = "/v1/jobs/notificable";
    return await fetchWithAuth(dispatch, url, auth.accessToken);
}

export const createJobNotification = async (dispatch, accessToken, httpMethod, body) => {
    const url = "/v1/notifications";
    await fetchWithAuth(dispatch, url, accessToken, httpMethod, body);
}

export const editJobNotification = async (dispatch, accessToken, httpMethod, notificationId, body) => {
    const url = `/v1/notifications/${notificationId}`;
    await fetchWithAuth(dispatch, url, accessToken, httpMethod, body);
}

export const deleteJobNotification = async (dispatch, accessToken, notificationId, httpMethod) => {
    const url = `/v1/notifications/${notificationId}`;
    await fetchWithAuth(dispatch, url, accessToken, httpMethod);
}