import SodaConfiguration from "./SodaConfiguration";
import SodaVolumeMappingList from "./SodaVolumeMappingList";
import { fetchVolumes } from "../../../actions/index";
import { fetchVolumeSettings } from "../../../ducks/volumeSettings";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { fetchConfigurations } from "../../../ducks/configurations";

function SodaIntegration(props) {
  const { volumeSettings, dispatch, activeDate } = props;

  const configurations = useSelector((state) => {
    return {
      isFetching: state.configurations.isFetching,
      url: state.configurations.sodaURL,
      deploymentId: state.configurations.sodaDeploymentId,
      clientId: state.configurations.sodaClientId,
      numberOfThreads: state.configurations.sodaNumberOfThreads,
    };
  });

  useEffect(() => {
    dispatch(fetchVolumeSettings());
    dispatch(fetchConfigurations());
  }, [dispatch]);

  useEffect(() => {
    if (activeDate) dispatch(fetchVolumes());
  }, [activeDate]);

  const isConfigurationSet = () => {
    return (
      configurations.url.value.length > 0 &&
      configurations.deploymentId.value.length > 0 &&
      configurations.clientId.value.length > 0 &&
      configurations.numberOfThreads.value.length > 0
    );
  };

  return (
    <>
      <SodaConfiguration
        isConfigurationSet={isConfigurationSet()}
        configurations={configurations}
      />
      <br />
      {!configurations.isFetching &&
        <SodaVolumeMappingList
          volumeSettings={volumeSettings.items.filter(
            (volumeSetting) => "Archiware" !== volumeSetting.type
          )}
          isConfigurationSet={isConfigurationSet()}
        />
      }
    </>
  );
}

function mapStateToProps(state) {
  const { volumeSettings, dates } = state;
  const activeDate = dates.activeDate;
  return {
    volumeSettings,
    activeDate,
  };
}
export default connect(mapStateToProps)(SodaIntegration);
