import { useEffect, useState } from "react";
import EntraIDConfiguration from "./EntraIDConfiguration";
import OktaConfiguration from "./OktaConfiguration";

import {
  Button,
  Col,
  Input,
  Form,
  FormGroup,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import UpdateLoginConfigurationModal from "./UpdateLoginConfigurationModal";
import { useDispatch } from "react-redux";
import { signOut } from "../../ducks/login";
import { dismissAlert } from "../../actions/alert";
import { useMsal } from "@azure/msal-react";

export default function LoginConfiguration(props) {
  const {
    isDefaultActive,
    isEntraIdActive,
    entraIdClientId,
    entraIdTenantId,
    entraIdRedirectUri,
    isOktaActive,
    oktaClientId,
    oktaDomain,
    oktaRedirectUri,
    oktaApiToken,
    updateConfigurations,
  } = props;

  const [isDefaultActiveObj, setIsDefaultActiveObj] = useState(isDefaultActive);
  const [isEntraIdActiveObj, setIsEntraIdActiveObj] = useState(isEntraIdActive);
  const [entraIdClientIdObj, setEntraIdClientIdObj] = useState(entraIdClientId);
  const [entraIdTenantIdObj, setEntraIdTenantIdObj] = useState(entraIdTenantId);
  const [entraIdRedirectUriObj, setEntraIdRedirectUriObj] =
    useState(entraIdRedirectUri);
  const [isOktaActiveObj, setIsOktaActiveObj] = useState(isOktaActive);
  const [oktaClientIdObj, setOktaClientIdObj] = useState(oktaClientId);
  const [oktaDomainObj, setOktaDomainObj] = useState(oktaDomain);
  const [oktaRedirectUriObj, setOktaRedirectUriObj] = useState(oktaRedirectUri);
  const [oktaApiTokenObj, setOktaApiTokenObj] =
    useState(oktaApiToken);
  const [isUpdateLoginConfigurationOpen, setIsUpdateLoginConfigurationOpen] =
    useState(false);

  const dispatch = useDispatch();

  const { instance } = useMsal();

  useEffect(() => {
    setIsDefaultActiveObj(isDefaultActive);
    setIsEntraIdActiveObj(isEntraIdActive);
    setEntraIdClientIdObj(entraIdClientId);
    setEntraIdTenantIdObj(entraIdTenantId);
    setEntraIdRedirectUriObj(entraIdRedirectUri);
    setIsOktaActiveObj(isOktaActive);
    setOktaClientIdObj(oktaClientId);
    setOktaDomainObj(oktaDomain);
    setOktaRedirectUriObj(oktaRedirectUri);
  }, [
    isDefaultActive,
    isEntraIdActive,
    entraIdClientId,
    entraIdTenantId,
    entraIdRedirectUri,
    isOktaActive,
    oktaClientId,
    oktaDomain,
    oktaRedirectUri,
  ]);

  const handleUpdateConfigurationsOnFailure = (isEntraIdValid, isOktaValid) => {
    let isDefaultActiveValue = { ...isDefaultActiveObj };
    let isEntraIdActiveValue = { ...isEntraIdActiveObj };
    let isOktaActiveValue = { ...isOktaActiveObj };

    if (!isOktaValid) {
      isOktaActiveValue = { ...isOktaActiveObj, value: "false" };
      setIsOktaActiveObj(isOktaActiveValue);
    }

    if (!isEntraIdValid) {
      isEntraIdActiveValue = { ...isEntraIdActiveObj, value: "false" };
      setIsEntraIdActiveObj(isEntraIdActiveValue);
    }

    if (!isOktaValid || !isEntraIdValid) {
      isDefaultActiveValue = { ...isDefaultActiveObj, value: "true" };
      setIsDefaultActiveObj(isDefaultActiveValue);
    }

    if (
      isOktaActiveValue.value === "true" ||
      isEntraIdActiveValue.value === "true"
    ) {
      handleUpdateConfigurations([
        isDefaultActiveValue,
        isEntraIdActiveValue,
        entraIdClientIdObj,
        entraIdTenantIdObj,
        entraIdRedirectUriObj,
        isOktaActiveValue,
        oktaClientIdObj,
        oktaDomainObj,
        oktaRedirectUriObj,
        oktaApiTokenObj,
      ]);
    } else {
      updateConfigurations(
        [
          isDefaultActiveValue,
          isEntraIdActiveValue,
          entraIdClientIdObj,
          entraIdTenantIdObj,
          entraIdRedirectUriObj,
          isOktaActiveValue,
          oktaClientIdObj,
          oktaDomainObj,
          oktaRedirectUriObj,
          oktaApiTokenObj,
        ],
        null,
        null,
        false
      );
    }
  };

  const handleUpdateConfigurations = (value) => {
    updateConfigurations(value, {
      message: (
        <div>
          Login configurations updated successfully. To access the application
          with the latest login method, please sign out and log back in.
          <span
            style={{
              marginLeft: "5px",
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              if (instance && instance["browserStorage"]) {
                instance["browserStorage"].clear();
              }
              dispatch(signOut());
              dispatch(dismissAlert());
            }}
          >
            Sign out
          </span>
        </div>
      ),
      type: "warning",
      timer: 13000,
    });
    setIsUpdateLoginConfigurationOpen(false);
  };

  return (
    <>
      <Form>
        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="enableDefaultLogin" sm={2}>
            Use Default DataIntell Login
            <FontAwesomeIcon
              id="defaultLoginDescription"
              icon={faQuestionCircle}
              className="ms-1"
            />
            <UncontrolledTooltip
              placement="auto"
              target="defaultLoginDescription"
            >
              Enable this option to retain DataIntell's built-in login system.
            </UncontrolledTooltip>
          </Label>
          <Col sm={4}>
            <FormGroup switch>
              <Input
                type="switch"
                id="enableDefaultLogin"
                name="enableDefaultLogin"
                onChange={(e) =>
                  setIsDefaultActiveObj((prev) => ({
                    ...prev,
                    value: e.target.checked ? "true" : "false",
                  }))
                }
                checked={isDefaultActiveObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>
        <EntraIDConfiguration
          isEntraIdActive={isEntraIdActiveObj}
          setIsEntraIdActive={setIsEntraIdActiveObj}
          entraIdClientId={entraIdClientIdObj}
          setEntraIdClientId={setEntraIdClientIdObj}
          entraIdTenantId={entraIdTenantIdObj}
          setEntraIdTenantId={setEntraIdTenantIdObj}
          entraIdRedirectUri={entraIdRedirectUriObj}
          setEntraIdRedirectUri={setEntraIdRedirectUriObj}
        />
        <OktaConfiguration
          isOktaActive={isOktaActiveObj}
          setIsOktaActive={setIsOktaActiveObj}
          oktaClientId={oktaClientIdObj}
          setOktaClientId={setOktaClientIdObj}
          oktaDomain={oktaDomainObj}
          setOktaDomain={setOktaDomainObj}
          oktaRedirectUri={oktaRedirectUriObj}
          setOktaRedirectUri={setOktaRedirectUriObj}
          oktaApiToken={oktaApiTokenObj}
          setOktaApiToken={setOktaApiTokenObj}
        />
        <FormGroup>
          <Button
            onClick={() => setIsUpdateLoginConfigurationOpen(true)}
            outline
            color="primary"
            disabled={
              isDefaultActiveObj.value === "false" &&
              isEntraIdActiveObj.value === "false" &&
              isOktaActiveObj.value === "false"
            }
          >
            Update configurations
          </Button>
        </FormGroup>
      </Form>
      <UpdateLoginConfigurationModal
        isOpen={isUpdateLoginConfigurationOpen}
        setIsOpen={setIsUpdateLoginConfigurationOpen}
        isDefaultActive={isDefaultActiveObj.value === "true"}
        isEntraIdActive={isEntraIdActiveObj.value === "true"}
        entraIdClientId={entraIdClientIdObj.value}
        entraIdRedirectUri={entraIdRedirectUriObj.value}
        entraIdTenantId={entraIdTenantIdObj.value}
        isOktaActive={isOktaActiveObj.value === "true"}
        oktaClientId={oktaClientIdObj.value}
        oktaDomain={oktaDomainObj.value}
        oktaRedirectUri={oktaRedirectUriObj.value}
        oktaApiToken={oktaApiTokenObj.value}
        updateConfigurationsOnFailure={(isEntraIdValid, isOktaValid) =>
          handleUpdateConfigurationsOnFailure(isEntraIdValid, isOktaValid)
        }
        updateConfigurations={() =>
          handleUpdateConfigurations([
            isDefaultActiveObj,
            isEntraIdActiveObj,
            entraIdClientIdObj,
            entraIdTenantIdObj,
            entraIdRedirectUriObj,
            isOktaActiveObj,
            oktaClientIdObj,
            oktaDomainObj,
            oktaRedirectUriObj,
            oktaApiTokenObj,
          ])
        }
      />
    </>
  );
}
