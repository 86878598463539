import { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { REPORT_DESCRIPTIONS } from "./reportDescription";
import { formatName } from "../utils/formatString";
import { getAllReportTemplateTypes } from "../api/report";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../actions/alert";

export default function ConfigureReportTemplateStep(props) {
  const {
    reportTemplateObj,
    setReportTemplateObj,
    setDisableForwardActionButton,
    isUpdate = false,
  } = props;

  const [reportType, setReportType] = useState([]);

  const auth = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllReportTemplateTypes(dispatch, auth)
      .then((res) => setReportType(res))
      .catch((err) => {
        console.error(err);
        dispatch(
          setAlert("Unable to get the list of report types.", "danger", 8000)
        );
      });
  }, []);

  useEffect(() => {
    setDisableForwardActionButton(
      reportTemplateObj.name.length === 0 ||
        reportTemplateObj.type.length === 0 ||
        reportTemplateObj.retention.length === 0
    );
  }, [
    reportTemplateObj.name,
    reportTemplateObj.type,
    reportTemplateObj.retention,
  ]);

  return (
    <>
      <FormGroup row>
        <Label for="name" sm={3}>
          Name
        </Label>
        <Col sm={9}>
          <Input
            id="name"
            name="name"
            type="text"
            disabled={isUpdate}
            value={reportTemplateObj.name}
            onChange={(e) => {
              setReportTemplateObj((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="type" sm={3}>
          Type
        </Label>
        <Col sm={9}>
          <Input
            id="type"
            name="type"
            type="select"
            value={reportTemplateObj.type}
            onChange={(e) => {
              setReportTemplateObj((prev) => ({
                ...prev,
                type: e.target.value,
              }));
            }}
          >
            <option value="">Select Report Type</option>
            {reportType.map((type, index) => {
              return (
                <option value={type} key={`${type}-${index}`}>
                  {formatName(type)}
                </option>
              );
            })}
          </Input>
        </Col>
      </FormGroup>
      {REPORT_DESCRIPTIONS[reportTemplateObj.type]}
      <hr />
      <FormGroup row>
        <Label for="retention" sm={3}>
          Flagged Files Retention (Days)
        </Label>
        <Col sm={9}>
          <Input
            id="retention"
            name="retention"
            type="number"
            min="1"
            value={reportTemplateObj.retention}
            onChange={(e) => {
              setReportTemplateObj((prev) => ({
                ...prev,
                retention: e.target.value,
              }));
            }}
          />
        </Col>
      </FormGroup>
      Set the retention policy, in days, for files flagged in reports. The
      latest version of flagged files is always preserved, while older versions
      are subject to the selected retention policy. A summary of all past
      reports is kept indefinitely.
    </>
  );
}
