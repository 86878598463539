import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";

// === Actions ===
// READ
export const REQUEST_DETAILED_VOLUMES = "REQUEST_DETAILED_VOLUMES";
export const RECEIVE_DETAILED_VOLUMES = "RECEIVE_DETAILED_VOLUMES";

function requestDetailedVolumes() {
  return {
    type: REQUEST_DETAILED_VOLUMES,
  };
}

function receiveDetailedVolumes(volumes) {
  return {
    type: RECEIVE_DETAILED_VOLUMES,
    volumes,
  };
}

// === Reducers ===
export default function detailedVolumes(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_DETAILED_VOLUMES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_DETAILED_VOLUMES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.volumes.elements.map((element) => ({
          ...element,
          name:
            element.type === "AWS-S3"
              ? element.name.replace("S3-BUCKET-", "")
              : element.name,
        })),
        page: action.volumes.page,
        total: action.volumes.total,
        size: action.volumes.size,
        sort: action.volumes.sort,
        direction: action.volumes.direction.toLowerCase(),
      });
    default:
      return state;
  }
}

// === Side Effects ===
export function fetchDetailedVolumes(
  volumeId,
  from,
  to,
  page = 1,
  sort = "indexingDate",
  direction = "desc",
  size = 1000
) {
  return async (dispatch, getState) => {
    dispatch(requestDetailedVolumes());
    const url = `/v1/detailedVolumes/${volumeId}?page=${page}&sort=${sort}&direction=${direction}&size=${size}&from=${from}&to=${to}`;
    try {
      const resp = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      return dispatch(receiveDetailedVolumes(resp));
    } catch (e) {
      return dispatch(setAlert(e.message, "danger"));
    }
  };
}
