import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Button,
  UncontrolledTooltip,
  InputGroup,
} from "reactstrap";
import PropTypes from "prop-types";
import URLInput from "./URLInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

CredentialForm.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  bucketAccessKey: PropTypes.string,
  bucketPrivateKey: PropTypes.string,
  bucketUrl: PropTypes.string,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
};

const CREDENTIALS_KEYS_NAME = {
  S3: { accessKey: "Access key ID", privateKey: "Secret access key" },
  ORACLE_OBJECT_STORAGE: { accessKey: "Access key", privateKey: "Secret key" },
  BACKBLAZE_B2: { accessKey: "keyID", privateKey: "applicationKey" },
  WASABI: { accessKey: "Access key", privateKey: "Secret access key" },
  AZURE: { accessKey: "Connection String" },
  DROPBOX: { accessKey: "Code" },
  OTHER: {
    accessKey: "Access key ID",
    privateKey: "Secret access key",
    url: "URL",
  },
};

function CredentialForm(props) {
  const {
    type,
    name,
    bucketAccessKey,
    bucketPrivateKey,
    bucketUrl,
    handleChange,
    errors,
    preSelectInputFromCredentialsCreation = null,
    setDisableCreateButton = null,
  } = props;

  const existingBuckets = useSelector(
    ({ bucketCredentials }) => bucketCredentials.items
  );

  const [localErrors, setLocalErrors] = useState({
    type: "",
    name: "",
    accessKey: "",
    privateKey: "",
    url: "",
  });

  const handleLocalErrors = (name, value) => {
    switch (name) {
      case "type":
        if (value === "") {
          setLocalErrors((prev) => ({
            ...prev,
            type: "Type can't be empty",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            type: "",
          }));
        }
        break;

      case "name":
        if (value === "" || value.length > 255) {
          setLocalErrors((prev) => ({
            ...prev,
            name: "Name must be between 1 and 255 characters",
          }));
        } else if (
          !!existingBuckets.find((credential) => credential.name === value)
        ) {
          setLocalErrors((prev) => ({
            ...prev,
            name: "Name must be unique",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            name: "",
          }));
        }
        break;

      case "accessKey":
        if (value === "" || value.length > 10000) {
          setLocalErrors((prev) => ({
            ...prev,
            accessKey: `${CREDENTIALS_KEYS_NAME[type].accessKey} must be between 1 and 10000 characters`,
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            accessKey: "",
          }));
        }
        break;

      case "privateKey":
        if (value === "" || value.length > 255) {
          setLocalErrors((prev) => ({
            ...prev,
            privateKey: `${CREDENTIALS_KEYS_NAME[type].privateKey} must be between 1 and 255 characters`,
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            privateKey: "",
          }));
        }
        break;

      case "url":
        if (value === "" || value.length > 255) {
          setLocalErrors((prev) => ({
            ...prev,
            url: "URL must be between 1 and 255 characters",
          }));
        } else {
          setLocalErrors((prev) => ({
            ...prev,
            url: "",
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (typeof preSelectInputFromCredentialsCreation === "function")
      preSelectInputFromCredentialsCreation(name, value);

    handleLocalErrors(name, value);
    handleChange(name, value);
  };

  // first time inputs validation check on mount
  useEffect(() => {
    if (setDisableCreateButton !== null) {
      const credentialsExist = existingBuckets.find(
        (credential) => credential.name === name
      );
      if (
        !!credentialsExist ||
        type.length === 0 ||
        name.length === 0 ||
        bucketAccessKey.length === 0 ||
        (type !== "AZURE" &&
          type !== "DROPBOX" &&
          bucketPrivateKey.length === 0) ||
        (type === "OTHER" && bucketUrl.length === 0)
      ) {
        setDisableCreateButton(true);
      } else {
        setDisableCreateButton(false);
      }
    }
  }, [
    setDisableCreateButton,
    type,
    name,
    bucketAccessKey,
    bucketPrivateKey,
    existingBuckets,
    bucketUrl,
  ]);

  return (
    <Form>
      <FormGroup row>
        <Label for="type" sm={4}>
          Type
        </Label>
        <Col sm={8}>
          <Input
            id="type"
            name="type"
            type="select"
            value={type}
            onChange={handleInputChange}
            invalid={Boolean(localErrors["type"].length)}
          >
            <option value="S3">AWS S3</option>
            <option value="ORACLE_OBJECT_STORAGE">Oracle Object Storage</option>
            <option value="BACKBLAZE_B2">BackBlaze B2</option>
            <option value="WASABI">Wasabi</option>
            <option value="AZURE">Azure</option>
            <option value="DROPBOX">Dropbox</option>
            <option value="OTHER">Other</option>
          </Input>
          <FormFeedback>{localErrors["type"]}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="name" sm={4}>
          Name
        </Label>
        <Col sm={8}>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Ex: DataIntell credentials"
            value={name}
            onChange={handleInputChange}
            invalid={
              errors["name"] !== undefined ||
              Boolean(localErrors["name"].length)
            }
          />
          <FormFeedback>{errors["name"] || localErrors["name"]}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="accessKey" sm={4}>
          {CREDENTIALS_KEYS_NAME[type].accessKey}
          {type === "DROPBOX" && (
            <FontAwesomeIcon
              id="accessKeyLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
          )}
        </Label>

        <Col sm={8}>
          <InputGroup>
            <Input
              id="bucketAccessKey"
              name="accessKey"
              type="text"
              value={bucketAccessKey}
              onChange={handleInputChange}
              invalid={
                errors["accessKey"] !== undefined ||
                Boolean(localErrors["accessKey"].length)
              }
            />
            {type === "DROPBOX" && (
              <>
                <Button
                  style={{ borderRadius: "0 5px 5px 0px" }}
                  onClick={() =>
                    window.open(
                      "https://www.dropbox.com/oauth2/authorize?client_id=o9v6cvb2falbl2m&token_access_type=offline&response_type=code",
                      "_blank"
                    )
                  }
                >
                  Get Code
                </Button>
                <UncontrolledTooltip placement="right" target="accessKeyLabel">
                  Copy the code from Dropbox
                </UncontrolledTooltip>
              </>
            )}
            <FormFeedback>
              {errors["accessKey"] || localErrors["accessKey"]}
            </FormFeedback>
          </InputGroup>
        </Col>
      </FormGroup>
      {type !== "AZURE" && type !== "DROPBOX" && (
        <FormGroup row>
          <Label for="privateKey" sm={4}>
            {CREDENTIALS_KEYS_NAME[type].privateKey}
          </Label>
          <Col sm={8}>
            <Input
              id="bucketPrivateKey"
              name="privateKey"
              type="password"
              value={bucketPrivateKey}
              onChange={handleInputChange}
              invalid={
                errors["privateKey"] !== undefined ||
                Boolean(localErrors["privateKey"].length)
              }
            />
            <FormFeedback>
              {errors["privateKey"] || localErrors["privateKey"]}
            </FormFeedback>
          </Col>
        </FormGroup>
      )}
      {type === "OTHER" && (
        <FormGroup row>
          <Label for="url" sm={4}>
            {CREDENTIALS_KEYS_NAME[type].url}
          </Label>
          <Col sm={8}>
            <URLInput
              urlConfiguration={bucketUrl}
              updateURL={(updatedUrl) => handleChange("url", updatedUrl)}
              placeholder="s3.reg.amazonaws.com"
            />
          </Col>
        </FormGroup>
      )}
    </Form>
  );
}

export default CredentialForm;
