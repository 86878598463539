import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal";
import BucketForm from "./BucketForm";

function UpdateBucketFormModal(props) {
  const {
    id,
    save,
    closeModal,
    buckets,
    credentials,
    storagePriceList,
    showUpdateBucketForm,

    errors,
  } = props;

  const [editId, setEditId] = useState("");
  const [name, setName] = useState("");
  const [namespace, setNamespace] = useState("");
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState("");
  const [type, setType] = useState("S3");
  const [awsCredentialsId, setAwsCredentialsId] = useState("");
  const [storagePriceId, setStoragePriceId] = useState("");
  const [path, setPath] = useState("");
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const volumeSettings = useSelector(
    ({ volumeSettings }) => volumeSettings.items
  );
  useEffect(() => {
    if (id) {
      editBucket(id);
    }
  }, [id]);

  const handleCloseModal = () => {
    closeModal();
    setEditId("");
    setName("");
    setNamespace("");
    setDescription("");
    setPath("");
    setRegion("");
    setType("S3");
    setAwsCredentialsId("");
    setStoragePriceId("");
    setIsSaveButtonEnabled(true);
  };

  const handleGetName = (name, type) => {
    if (type === "ORACLE_OBJECT_STORAGE") {
      return name.split(".")[1];
    }
    return name;
  };

  const handleGetNamespace = (name, type) => {
    if (type === "ORACLE_OBJECT_STORAGE") {
      return name.split(".")[0];
    }
    return "";
  };

  const handleChange = (name, value) => {
    if (name === "name") {
      setName(value);
    } else if (name === "namespace") {
      setNamespace(value);
    } else if (name === "description") {
      setDescription(value);
      if (type === "DROPBOX") {
        setName(value);
      }
    } else if (name === "region") {
      setRegion(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "awsCredentialsId") {
      setAwsCredentialsId(value);
    } else if (name === "storagePrice") {
      setStoragePriceId(value);
    } else if (name === "path") {
      setPath(value);
    }
  };

  const editBucket = (id) => {
    const bucketToUpdate = buckets.find((bucket) => bucket.id === id);
    setEditId(bucketToUpdate.id);
    setName(handleGetName(bucketToUpdate.name, bucketToUpdate.type));
    setPath(
      volumeSettings.find(
        (volume) => volume.id === bucketToUpdate.volumeSettingId
      )?.path
    );
    setNamespace(handleGetNamespace(bucketToUpdate.name, bucketToUpdate.type));
    setDescription(bucketToUpdate.description);
    setRegion(bucketToUpdate.region);
    setType(bucketToUpdate.type);
    setAwsCredentialsId(bucketToUpdate.awsCredentialsId);
    setStoragePriceId(bucketToUpdate.storagePrice.id);
  };

  return (
    <>
      <ConfirmModal
        showConfirmation={showUpdateBucketForm}
        title="Update Cloud Storage"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          save(id, {
            name:
              type === "ORACLE_OBJECT_STORAGE" ? namespace + "." + name : name,
            description: description,
            region: region,
            path: path,
            type: type,
            awsCredentialsId: awsCredentialsId,
            storagePrice: storagePriceId === "" ? null : { id: storagePriceId },
          });
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={!isSaveButtonEnabled}
      >
        <BucketForm
          id={id}
          name={name}
          namespace={namespace}
          description={description}
          region={region}
          type={type}
          path={path}
          awsCredentialsId={awsCredentialsId}
          credentials={credentials}
          storagePriceList={storagePriceList}
          storagePriceId={storagePriceId}
          handleChange={handleChange}
          errors={errors}
          setIsSaveButtonEnabled={setIsSaveButtonEnabled}
        />
      </ConfirmModal>
    </>
  );
}

export default UpdateBucketFormModal;
