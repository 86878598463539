import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  FormGroup,
  Input,
} from "reactstrap";
import moment from "moment";
import PDFChartButton from "../../components/PDFChartButton";

import { fetchWithAuthV2 } from "../../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import HistogramChart from "../../components/HistogramChart";
import Loading from "../../components/Loading";
import { CHART_COLORS } from "../../constants/chartColors";
import { formatSize } from "../../components/FormatSize";

function CreationDateChart({
  filters,
  search,
  addFilters,
  field,
  title,
  setRef = () => { },
  setSubtitle = () => { },
  setTimeUnit = () => { },
  setSizeShownData = () => { },
  sizeOfFiles,
  lastAnalyticsSearch,
}) {
  const chartRef = useRef();

  const dispatch = useDispatch();

  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const [dates, setDates] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [dateIntervalType, setDateIntervalType] = useState("YEAR");

  const SIZE_OF_CHART = 13;
  const filterBefore = `${field}Before`;
  const filterAfter = `${field}After`;
  const dateField = field === "creation" ? "creationDate" : field;

  const fetchData = async (intervalType) => {
    setIsFetching(true);
    const url = "/files/search/dates";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      dateField,
      size: SIZE_OF_CHART,
      dateIntervalType: intervalType,
      indexingDate: activeDate,
    };

    const dates = await fetchWithAuthV2(dispatch, url, accessToken, {
      ...queryParams,
      ...searchParams,
    });

    setDates(dates);
    setIsFetching(false);
  };

  const changePeriod = (fromDate, toDate) => {
    const names = [filterAfter, filterBefore];
    const values = [fromDate, toDate];
    addFilters(names, values);
  };

  useEffect(() => {
    let dateBefore = moment();
    let dateAfter = moment();
    if (filters[filterBefore]) {
      dateBefore = moment(filters[filterBefore]);
    }
    if (filters[filterAfter]) {
      dateAfter = moment(filters[filterAfter]);
    }
    const diff = dateBefore.diff(dateAfter, "days");
    if (diff > 400 || diff === 0) {
      setDateIntervalType("YEAR");
      fetchData("YEAR");
    } else if (diff > 91) {
      setDateIntervalType("MONTH");
      fetchData("MONTH");
    } else if (diff > 13) {
      setDateIntervalType("WEEK");
      fetchData("WEEK");
    } else {
      setDateIntervalType("DAY");
      fetchData("DAY");
    }
  }, [lastAnalyticsSearch]);

  useEffect(() => {
    if (chartRef !== null) {
      setRef(chartRef);
    }
  }, [chartRef]);
  // filesizeObject will change when dates change
  useEffect(() => {
    setSubtitle(filesizeObject.unit);
    setSizeShownData(dates.reduce((a, b) => a + b.size, 0));
  }, [dates]);
  useEffect(() => {
    setTimeUnit(dateIntervalType);
  }, [dateIntervalType]);
  const disableNextButton = () => {
    if (dates.length === 0) {
      return true;
    }
    const lastDate = moment(dates[dates.length - 1].date);
    const today = moment();
    const lastDateIsToday = today.isSame(
      lastDate,
      dateIntervalType.toLocaleLowerCase()
    );
    const lastDateIsAfter = moment(lastDate).isAfter(moment());

    return lastDateIsToday || lastDateIsAfter;
  };

  const handleNextButton = () => {
    let from = dates[dates.length - 1].date;
    let to = moment(from)
      .add(SIZE_OF_CHART, dateIntervalType.toLocaleLowerCase() + "s")
      .format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    if (moment(to).isAfter(today)) {
      to = today;
      from = moment(today)
        .subtract(SIZE_OF_CHART, dateIntervalType.toLocaleLowerCase() + "s")
        .format("YYYY-MM-DD");
    }
    changePeriod(from, to);
  };

  const handlePreviousButton = () => {
    const from = moment(dates[0].date)
      .subtract(SIZE_OF_CHART, dateIntervalType.toLocaleLowerCase() + "s")
      .format("YYYY-MM-DD");
    changePeriod(from, dates[0].date);
  };

  const handleChartClick = (index) => {
    if (null != index && dateIntervalType !== "DAY") {
      const from = moment(dates[index].date)
        .startOf(dateIntervalType.toLowerCase())
        .format("YYYY-MM-DD");
      const to = moment(dates[index].date)
        .endOf(dateIntervalType.toLowerCase())
        .format("YYYY-MM-DD");
      changePeriod(from, to);
    }
  };

  const labels = [];
  const dataCount = [];
  const dataSize = [];
  const dataSizeValues = [];
  let sum = 0;
  dates.forEach((date) => (sum += date.size));

  const filesizeObject = formatSize(
    dates.length > 0 ? sum / dates.length : 0,
    baseSize,
    null,
    "object"
  );
  dates.forEach((element) => {
    if (dateIntervalType === "DAY") {
      labels.push(moment(element.date, "YYYY-MM-DD").format("LL"));
    } else if (dateIntervalType === "WEEK") {
      labels.push(moment(element.date, "YYYY-MM-DD").format("LL"));
    } else if (dateIntervalType === "MONTH") {
      labels.push(moment(element.date, "YYYY-MM-DD").format("MMM YYYY"));
    } else {
      labels.push(moment(element.date, "YYYY-MM-DD").format("YYYY"));
    }
    dataCount.push(element.count);
    dataSize.push(
      formatSize(element.size, baseSize, filesizeObject.exponent, "object")
        .value
    );
    dataSizeValues.push(element.size);
  });

  const cardContent = () => {
    if (isFetching === true) {
      return <Loading />;
    } else {
      return (
        <>
          <div>
            <Button
              disabled={dates.length === 0}
              onClick={handlePreviousButton}
              size="sm"
              outline
            >
              <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
            </Button>
            <Button
              disabled={disableNextButton()}
              size="sm"
              outline
              className={"float-end"}
              onClick={handleNextButton}
            >
              <FontAwesomeIcon icon={faAngleRight} size={"lg"} />
            </Button>
            <HistogramChart
              unit={filesizeObject.unit}
              onClick={(index) => handleChartClick(index)}
              data={dataSize}
              dataCount={dataCount}
              labels={labels}
              showRatio
              sizeOfFiles={sizeOfFiles}
              dataSizeValues={dataSizeValues}
              title={`Size (${filesizeObject.unit})`}
              color={CHART_COLORS[0]}
            />
          </div>
          <div
            style={{ position: "absolute", left: "-9999px", top: "-9999px" }}
          >
            <HistogramChart
              height={300}
              width={800}
              unit={filesizeObject.unit}
              histogramRef={chartRef}
              onClick={(index) => handleChartClick(index)}
              data={dataSize}
              dataCount={dataCount}
              labels={labels}
              isCustomLabel
              sizeOfFiles={sizeOfFiles}
              dataSizeValues={dataSizeValues}
              showRatio
              title={`Size (${filesizeObject.unit})`}
              color={CHART_COLORS[0]}
            />
          </div>
        </>
      );
    }
  };

  const intervalPicker = () => {
    return (
      <FormGroup>
        <Input
          type="select"
          placeholder="select"
          value={dateIntervalType}
          onChange={(event) => {
            const target = event.target;
            const value = target.value;
            setDateIntervalType(value);
            fetchData(value);
          }}
          bsSize="sm"
        >
          <option value="YEAR">Year</option>
          <option value="MONTH">Month</option>
          <option value="WEEK">Week</option>
          <option value="DAY">Day</option>
        </Input>
      </FormGroup>
    );
  };

  return (
    <Card style={{ marginBottom: 10 }}>
      <CardHeader>
        <CardTitle tag="h5">
          <span style={{ fontWeight: "bold" }}>{title}</span>
          <div className="float-end" style={{ paddingLeft: 10 }}>
            <PDFChartButton
              chartRef={chartRef}
              title={title}
              subTitle={filesizeObject.unit}
              timeUnit={dateIntervalType}
              sizeOfFiles={sizeOfFiles}
              sizeShownData={dates.reduce((a, b) => a + b.size, 0)}
            />
          </div>
          <span className={"float-end"}>{intervalPicker()}</span>
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Size of files ({filesizeObject.unit})
        </CardSubtitle>
      </CardHeader>
      <CardBody>{cardContent()}</CardBody>
    </Card>
  );
}

export default CreationDateChart;
