import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { setAlert } from "../actions/alert";
import path from "path";

export default function CopyPath(props) {
  const { file } = props;

  const dispatch = useDispatch();

  const volumeList = useSelector(({ volumes }) => volumes.items);
  const buckets = useSelector(({ buckets }) => buckets.items);

  function copyPath() {
    const volume = volumeList.find((element) => element.id === file.volumeId);
    let pathToCopy = path.join("/", volume.path ?? "", file.path);

    if (volume && volume.type === "AWS-S3") {
      const bucket = buckets.find(bucket => bucket.volumeSettingId === volume.id);
      pathToCopy = path.join("/", bucket.name, file.path);
    }
    
    if (!file.isDirectory) {
      pathToCopy = path.join(pathToCopy, file.name);
    }
    const input = document.createElement("textarea");
    input.value = pathToCopy;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    dispatch(setAlert("File path copied to clipboard", "info", 2500));
  }

  return (
    <>
      <FontAwesomeIcon
        id="copyPath"
        icon={faClipboard}
        onClick={(e) => {
          e.stopPropagation();
          copyPath();
        }}
      />
      <UncontrolledTooltip target="copyPath" placement="auto">
        Copy path
      </UncontrolledTooltip>
    </>
  );
}
