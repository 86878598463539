import React from 'react';
import moment from "moment";
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import { Card, CardHeader, CardBody } from 'reactstrap';
import FormatSize from '../../components/FormatSize';

export function getColor(sizePercentage) {
  if (sizePercentage >= 100) { return "#FF0000" }
  else if (sizePercentage >= 80) { return "#FF5F1F" }
  else return "#000";
}

function ProjectDetails(props) {
  const {
    size,
    sizePercentage,
    allocatedSize,
    numberOfFiles,
    lastAccessDate,
    lastModifiedDate,
    description
  } = props;

  const formatDate = value => moment(value).format("lll");

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader tag="h5">
        Details
      </CardHeader>
      <CardBody>
        <div style={{ "display": "grid", "columnGap": "20px", "gridTemplateColumns": "140px auto" }}>
          {description &&
            <>
              <div>Description:</div>
              <div><strong>{description}</strong></div>
            </>
          }
          <div>Used Space:</div>
          <div><strong style={{ color: getColor(sizePercentage) }}>{sizePercentage}{" %"}</strong></div>
          <div>Size:</div>
          <div><strong><FormatSize>{size}</FormatSize></strong></div>
          <div>Allocated Space:</div>
          <div><strong><FormatSize>{allocatedSize}</FormatSize></strong></div>
          <div>Number of Files:</div>
          <div><strong>{numberOfFiles}</strong></div>
          <div>Last Access Date:</div>
          <div><strong>{returnNAOnNull(lastAccessDate, formatDate)}</strong></div>
          <div>Last Modified Date:</div>
          <div><strong>{returnNAOnNull(lastModifiedDate, formatDate)}</strong></div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ProjectDetails;
