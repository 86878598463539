import { useEffect } from "react";
import { connect } from "react-redux";
import AppInfo from "./components/AppInfo";
import SubSection from "./components/SubSection";
import { downloadLogs } from "../actions";
import { fetchAppInfo } from "../ducks/appInfo";
import { updateLicense } from "../ducks/license";
import {
  fetchConfigurations,
  updateConfigurations,
  testEmailConfigurations,
} from "../ducks/configurations";
import PropTypes from "prop-types";
import EmailConfiguration from "./components/EmailConfiguration";
import GeneralConfiguration from "./components/GeneralConfiguration";
import LoginConfiguration from "./components/LoginConfiguration";

General.propTypes = {
  dispatch: PropTypes.func,
  configurations: PropTypes.object,
  app: PropTypes.object,
  license: PropTypes.object,
};

function General(props) {
  const { dispatch, configurations, app, license } = props;

  useEffect(() => {
    dispatch(fetchConfigurations());
    dispatch(fetchAppInfo());
  }, [dispatch]);

  return (
    <>
      <h1 className="page-header">General Settings</h1>
      <SubSection
        title={"Application Information"}
        subTitle={"Display API and Client version, application logs."}
        isOpen={true}
      >
        <AppInfo
          downloadLogsFun={() => dispatch(downloadLogs())}
          version={app.version}
          licenseExpirationDate={app.expirationDate}
          licenseIsValid={app.isValid}
          license={license}
          updateLicenseFunc={(license) => dispatch(updateLicense(license))}
        />
      </SubSection>
      <hr />
      <SubSection
        title={"General Configurations"}
        subTitle={"Configure data retention"}
        isOpen={false}
      >
        <GeneralConfiguration
          minimumSize={configurations.minimumSize}
          retentionSnapshot={configurations.snapshotNumberOfDays}
          retentionDeletedFiles={configurations.deletedFilesNumberOfDays}
          retentionFirstDay={configurations.snapshotKeepFirstDayOfMonth}
          retentionJobs={configurations.jobRetention}
          queuedLengthJobs={configurations.jobQueuedLength}
          urlConfiguration={configurations.appURL}
          isHealthCheckActive={configurations.isHealthCheckActive}
          isPerformanceModeActive={configurations.isPerformanceModeActive}
          isAlwaysUpdateActive={configurations.isAlwaysUpdateActive}
          isAdvancedScanActive={configurations.isAdvancedScanActive}
          folderDepth={configurations.folderDepth}
          scanDellPowerscaleNumberOfThreads={configurations.scanDellPowerscaleNumberOfThreads}
          numberOfFiles={configurations.numberOfFiles}
          isBetaActive={configurations.isBetaActive}
          updateConfigurations={(value) =>
            dispatch(updateConfigurations(value))
          }
        />
      </SubSection>
      <hr />
      <SubSection
        title={"Login Configurations"}
        subTitle={
          "Customize user access to DataIntell. Enable and manage third-party Single Sign-On (SSO) options."
        }
        isOpen={false}
      >
        <LoginConfiguration
          isDefaultActive={configurations.isAppLoginActive}
          isEntraIdActive={configurations.isEntraIdLoginActive}
          entraIdClientId={configurations.entraIdClientId}
          entraIdTenantId={configurations.entraIdTenantId}
          entraIdRedirectUri={configurations.entraIdRedirectId}
          isOktaActive={configurations.isOktaLoginActive}
          oktaClientId={configurations.oktaClientId}
          oktaDomain={configurations.oktaDomain}
          oktaRedirectUri={configurations.oktaRedirectUri}
          oktaApiToken={configurations.oktaApiToken}
          updateConfigurations={(
            value,
            successMessage,
            errorMessage,
            withAlert
          ) =>
            dispatch(
              updateConfigurations(
                value,
                successMessage,
                errorMessage,
                withAlert
              )
            )
          }
        />
      </SubSection>
      <hr />
      <SubSection
        title={"Email Configurations"}
        subTitle={"Settings to configure email notifications"}
        isOpen={false}
      >
        <EmailConfiguration
          hostname={configurations.mailHostname}
          username={configurations.mailUsername}
          from={configurations.mailFrom}
          port={configurations.mailPort}
          tlsEnable={configurations.mailTlsEnable}
          tlsRequired={configurations.mailTlsRequired}
          sslEnable={configurations.mailSslEnable}
          smtpAuth={configurations.mailSMTPAuth}
          urlConfiguration={configurations.appURL.value}
          testEmailConfigurations={() => {
            dispatch(testEmailConfigurations());
          }}
          updateConfigurations={(value, successMessage, errorMessage) =>
            dispatch(updateConfigurations(value, successMessage, errorMessage))
          }
        />
      </SubSection>
    </>
  );
}

function mapStateToProps(state) {
  const { configurations, app, license } = state;
  return { configurations, app, license };
}

export default connect(mapStateToProps)(General);
