import { useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Col,
  InputGroup,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

IgnorePathsInput.propTypes = {
  ignoredPaths: PropTypes.array,
  handleChange: PropTypes.func,
};

export default function IgnorePathsInput(props) {
  const {
    ignoredPaths,
    handleChange,
    volumeType = "On-Premises",
    labelGrid = 4,
    inputGrid = 8,
  } = props;

  const [inputIgnoredPath, setInputIgnoredPath] = useState("");

  const addIgnoredPath = () => {
    if (
      inputIgnoredPath.length > 0 &&
      !ignoredPaths.some((path) => path === inputIgnoredPath)
    ) {
      handleChange("ignoredPaths", [...ignoredPaths, inputIgnoredPath]);
      setInputIgnoredPath("");
    }
  };

  return (
    <FormGroup row>
      <Col sm={labelGrid}>
        <Label for="ignoredPaths">Ignored Paths</Label>
        <FontAwesomeIcon
          id="ignoredPathsLabel"
          className="ms-1"
          icon={faQuestionCircle}
        />
        <UncontrolledTooltip placement="auto" target="ignoredPathsLabel">
          Paths that are ignored while scanning the {volumeType} storage. They
          should be relative to the {volumeType} path above.
        </UncontrolledTooltip>
      </Col>
      <Col sm={inputGrid}>
        <InputGroup className="mb-1">
          <Input
            type="text"
            value={inputIgnoredPath}
            placeholder="Ex: /ignored/path"
            onChange={(e) => setInputIgnoredPath(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addIgnoredPath();
              }
            }}
          />
          <Button color="success" onClick={() => addIgnoredPath()}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </InputGroup>
      </Col>
      <Col sm={labelGrid} />
      <Col sm={inputGrid} className="mt-2">
        <div style={{ maxHeight: "150px", overflowY: "auto" }}>
          {ignoredPaths.map((ignoredPath) => {
            return (
              <Badge
                color="primary"
                style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }}
                key={ignoredPath}
              >
                {ignoredPath}
                <Badge
                  onClick={() =>
                    handleChange(
                      "ignoredPaths",
                      ignoredPaths.filter((path) => ignoredPath != path)
                    )
                  }
                  color="light"
                  className="text-dark"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                >
                  X
                </Badge>
              </Badge>
            );
          })}
        </div>
      </Col>
    </FormGroup>
  );
}
