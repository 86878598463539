import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";

class Tabs extends Component {
  render() {
    const { titles, children, activeTab, toggleTab } = this.props;

    const tabs = titles.map((title, index) => {
      const tabNumber = index.toString();
      return (
        <NavItem key={"tab-" + index}>
          <NavLink
            className={classnames({
              active: activeTab === tabNumber
            })}
            onClick={() => {
              toggleTab(tabNumber);
            }}
            style={{ cursor: "pointer" }}
          >
            <h6 style={{ "marginBottom": 0 }}>{title}</h6>
          </NavLink>
        </NavItem>
      );
    });

    const tabPanes = children.map((child, index) => {
      const tabNumber = index.toString();
      return (
        <TabPane key={"tabPane-" + index} tabId={tabNumber}>
          <Row>
            <Col style={{ marginTop: 10 }} sm="12">
              {child}
            </Col>
          </Row>
        </TabPane>
      );
    });

    return (
      <div>
        <Nav tabs>{tabs}</Nav>
        <TabContent activeTab={activeTab}>{tabPanes}</TabContent>
      </div>
    );
  }
}

export default Tabs;
