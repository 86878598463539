import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_BUCKET_CREDENTIAL = "REQUEST_CREATE_BUCKET_CREDENTIAL";
const RECEIVE_CREATE_BUCKET_CREDENTIAL = "RECEIVE_CREATE_BUCKET_CREDENTIAL";
// READ
const REQUEST_BUCKET_CREDENTIAL_LIST = "REQUEST_BUCKET_CREDENTIAL_LIST";
const RECEIVE_BUCKET_CREDENTIAL_LIST = "RECEIVE_BUCKET_CREDENTIAL_LIST";
// UPDATE
const REQUEST_UPDATE_BUCKET_CREDENTIAL = "REQUEST_UPDATE_BUCKET_CREDENTIAL";
const RECEIVE_UPDATE_BUCKET_CREDENTIAL = "RECEIVE_UPDATE_BUCKET_CREDENTIAL";
// DELETE
const REQUEST_DELETE_BUCKET_CREDENTIAL = "REQUEST_DELETE_BUCKET_CREDENTIAL";
const RECEIVE_DELETE_BUCKET_CREDENTIAL = "RECEIVE_DELETE_BUCKET_CREDENTIAL";
// ERRORS
const RECEIVE_INVALID_BUCKET_CREDENTIAL = "RECEIVE_INVALID_BUCKET_CREDENTIAL";

// === Reducers ===
export default function bucketCredentials(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: true,
  },
  action
) {
  switch (action.type) {
    case REQUEST_BUCKET_CREDENTIAL_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_BUCKET_CREDENTIAL_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.bucketCredentials.elements,
        page: action.bucketCredentials.page,
        total: action.bucketCredentials.total,
        size: action.bucketCredentials.size,
        sort: action.bucketCredentials.sort,
        direction: action.bucketCredentials.direction,
        errors: {},
        formValid: true,
      });
    case RECEIVE_INVALID_BUCKET_CREDENTIAL:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false,
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestBucketCredentialList() {
  return {
    type: REQUEST_BUCKET_CREDENTIAL_LIST,
  };
}

function receiveBucketCredentialList(bucketCredentials) {
  return {
    type: RECEIVE_BUCKET_CREDENTIAL_LIST,
    bucketCredentials,
  };
}

function receiveInvalidBucketCredential(errors) {
  return {
    type: RECEIVE_INVALID_BUCKET_CREDENTIAL,
    errors,
  };
}

function requestCreateBucketCredential() {
  return {
    type: REQUEST_CREATE_BUCKET_CREDENTIAL,
  };
}

function receiveCreateBucketCredential(createdBucketCredential) {
  return {
    type: RECEIVE_CREATE_BUCKET_CREDENTIAL,
    bucketCredential: createdBucketCredential,
  };
}

function requestUpdateBucketCredential() {
  return {
    type: REQUEST_UPDATE_BUCKET_CREDENTIAL,
  };
}

function receiveUpdateBucketCredential(updatedBucketCredential) {
  return {
    type: RECEIVE_UPDATE_BUCKET_CREDENTIAL,
    bucketCredential: updatedBucketCredential,
  };
}

function requestDeleteBucketCredential(id) {
  return {
    type: REQUEST_DELETE_BUCKET_CREDENTIAL,
    id,
  };
}

function receiveDeleteBucketCredential(id) {
  return {
    type: RECEIVE_DELETE_BUCKET_CREDENTIAL,
    id,
  };
}

// === Side Effects ===
export function fetchBucketCredentialList(
  page = 1,
  sort = "name",
  direction = "asc",
  size = window.localStorage.getItem("numberOfElementsDataSourceBucketsCreds")
    ? Number(
        window.localStorage.getItem("numberOfElementsDataSourceBucketsCreds")
      )
    : 10
) {
  return async (dispatch, getState) => {
    window.localStorage.setItem("numberOfElementsDataSourceBucketsCreds", size);
    dispatch(requestBucketCredentialList());
    const url = `/v1/bucket-credentials?page=${page}&sort=${sort},${direction}&size=${size}`;
    try {
      const json = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      dispatch(receiveBucketCredentialList(json));
    } catch (err) {
      const message =
        err.statusText || `Unable to get the list of bucket credentials`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createBucketCredential(bucketCredential) {
  return async (dispatch, getState) => {
    dispatch(requestCreateBucketCredential());
    try {
      const newBucketCredential = await fetchWithAuth(
        dispatch,
        "/v1/bucket-credentials",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(bucketCredential)
      );
      dispatch(receiveCreateBucketCredential(newBucketCredential));
      dispatch(
        fetchBucketCredentialList(
          1,
          getState().bucketCredentials.sort,
          getState().bucketCredentials.direction,
          getState().bucketCredentials.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidBucketCredential(err));
      else {
        const message = err.message || "Unable to create bucket credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}
export function createDropboxCredential(bucketCredential) {
  return async (dispatch, getState) => {
    dispatch(requestCreateBucketCredential());
    try {
      const newBucketCredential = await fetchWithAuth(
        dispatch,
        "/v1/dropbox-credentials",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(bucketCredential)
      );
      dispatch(receiveCreateBucketCredential(newBucketCredential));
      dispatch(
        fetchBucketCredentialList(
          1,
          getState().bucketCredentials.sort,
          getState().bucketCredentials.direction,
          getState().bucketCredentials.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidBucketCredential(err));
      else {
        const message = err.message || "Unable to create Dropbox credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateBucketCredential(id, bucketCredential) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateBucketCredential());
    try {
      const updatedBucketCredential = await fetchWithAuth(
        dispatch,
        `/v1/bucket-credentials/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(bucketCredential)
      );
      dispatch(receiveUpdateBucketCredential(updatedBucketCredential));
      dispatch(
        fetchBucketCredentialList(
          1,
          getState().bucketCredentials.sort,
          getState().bucketCredentials.direction,
          getState().bucketCredentials.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidBucketCredential(err));
      else {
        const message = err.message || "Unable to update bucket credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateDropboxCredential(id, bucketCredential) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateBucketCredential());
    try {
      const updatedBucketCredential = await fetchWithAuth(
        dispatch,
        `/v1/dropbox-credentials/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(bucketCredential)
      );
      dispatch(receiveUpdateBucketCredential(updatedBucketCredential));
      dispatch(
        fetchBucketCredentialList(
          1,
          getState().bucketCredentials.sort,
          getState().bucketCredentials.direction,
          getState().bucketCredentials.size
        )
      );
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidBucketCredential(err));
      else {
        const message = err.message || "Unable to update Dropbox credential";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function deleteBucketCredential(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteBucketCredential(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/bucket-credentials/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(
        fetchBucketCredentialList(
          1,
          getState().bucketCredentials.sort,
          getState().bucketCredentials.direction,
          getState().bucketCredentials.size
        )
      );
      dispatch(receiveDeleteBucketCredential(id));
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}
