import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import UserForm from "./UserForm";
import moment from "moment";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faKey } from "@fortawesome/free-solid-svg-icons";
import ResetPasswordForm from "./ResetPasswordForm";
import PropTypes from "prop-types";
import UserWizard from "./UserWizard";
import { fetchRoles } from "../../ducks/role";
import RoleList from "./RoleList";
import TableActions from "../../components/TableActions";
import Loading from "../../components/Loading";

UserList.propTypes = {
  userList: PropTypes.array,
  groupList: PropTypes.array,
  total: PropTypes.number,
  currentUserId: PropTypes.number,
  formValid: PropTypes.bool,
  errors: PropTypes.object,
  resetPasswordValid: PropTypes.bool,
  resetPasswordErrors: PropTypes.object,
  deleteUser: PropTypes.func,
  updateUserList: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  resetPassword: PropTypes.func,
  isFetching: PropTypes.bool,
};

function UserList(props) {
  const {
    userList,
    groupList,
    total,
    currentUserId,
    formValid,
    errors,
    resetPasswordValid,
    resetPasswordErrors,
    deleteUser,
    updateUserList,
    createUser,
    updateUser,
    resetPassword,
    isCreateForm,
    setIsCreateForm,
    showWizard,
    setShowWizard,
    isFetching,
  } = props;

  const dispatch = useDispatch();
  const GROUP = "GROUP";
  const SYSTEM = "SYSTEM";

  const { current: cleanUserFormObj } = useRef({
    name: "",
    username: "",
    email: "",
    password: "",
  });

  const [userFormObj, setUserFormObj] = useState(cleanUserFormObj);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorsObj, setErrorsObj] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [groups, setGroups] = useState([]);
  const [availableGroups, setAvailableGroups] = useState(
    groupList.filter((group) => GROUP === group.type)
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [pagination, setPagination] = useState({
    sort: "name",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsUsers")
      ? Number(window.localStorage.getItem("numberOfElementsUsers"))
      : 50,
    page: 1,
  });

  const handleAddGroup = (group) => {
    if (group.name === "ROLE_ADMIN") {
      setGroups([group]);
    } else {
      setGroups([...groups, group]);
    }
  };

  const handleRemoveGroup = (group) =>
    setGroups(groups.filter((g) => g.id !== group.id));

  useEffect(() => {
    updateUserList(
      pagination.page,
      pagination.sort,
      pagination.direction,
      pagination.size
    );
  }, [pagination.page, pagination.sort, pagination.direction, pagination.size]);

  // to avoid display bugs
  useEffect(() => {
    setAvailableGroups(groupList.filter((group) => GROUP === group.type));
  }, [groupList]);

  useEffect(() => {
    setSelectedGroups(groups.filter((group) => GROUP === group.type));
    setAvailableGroups((currGroups) =>
      currGroups.filter(
        (group) => !groups.some((userGroup) => userGroup.id === group.id)
      )
    );
  }, [groups]);

  useEffect(() => {
    if (formValid) {
      setShowUserForm(false);
      setUserId("");
      setGroups([]);
      setUserFormObj(cleanUserFormObj);
    }
    if (resetPasswordValid) {
      setShowResetPasswordForm(false);
      setUserId("");
      setNewPassword("");
      setConfirmPassword("");
    }
  }, [formValid, resetPasswordValid, cleanUserFormObj]);

  useEffect(() => {
    setErrorsObj(errors);
  }, [errors]);

  const changeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }

    setPagination((prev) => ({
      ...prev,
      sort: newSort,
      direction: newDirection,
    }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsUsers", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const handleChange = (name, value) => {
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const saveUser = () => {
    if (isCreateForm) {
      const createUserResult = createUser(
        userFormObj.name,
        userFormObj.username,
        userFormObj.email,
        userFormObj.password,
        groups
      );
      dispatch(fetchRoles);
      return createUserResult;
    } else {
      updateUser(
        userId,
        userFormObj.name,
        userFormObj.username,
        userFormObj.email,
        groups
      );
    }
  };

  const getUserType = (type) => {
    switch (type) {
      case "ENTRAID":
        return "Microsoft Entra ID";
      case "OKTA":
        return "Okta";
      default:
        return "DataIntell";
    }
  };

  const numberOfPages = Math.ceil(total / pagination.size);

  let listFormated = userList.map((user) => {
    const roles = user.roles.filter((role) => role.type === SYSTEM);
    const groups = user.roles.filter((role) => role.type === GROUP);

    let lineObject = {
      columnData: [
        user.id,
        user.name,
        user.username,
        user.email,
        getUserType(user.type),
        moment(user.lastLoginAt).format("lll"),
        roles.length ? <RoleList roleList={roles} color={"primary"} /> : "",
        groups.length ? <RoleList roleList={groups} /> : "",

        <>
          {user.type === "DATAINTELL" && (
            <>
              <Button
                id="updateUserInformationUserList"
                color="secondary"
                onClick={() => {
                  setShowUserForm(true);
                  setIsCreateForm(false);
                  setUserId(user.id);
                  setGroups(user.roles);
                  setUserFormObj((prev) => ({
                    ...prev,
                    username: user.username,
                    name: user.name,
                    email: user.email,
                  }));
                }}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                id="resetPasswordUserList"
                color="warning"
                onClick={() => {
                  setShowResetPasswordForm(true);
                  setUserId(user.id);
                }}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faKey} />
              </Button>
              <UncontrolledTooltip
                placement="auto"
                target="resetPasswordUserList"
              >
                Reset password
              </UncontrolledTooltip>
              <UncontrolledTooltip
                placement="auto"
                target="updateUserInformationUserList"
              >
                Edit user
              </UncontrolledTooltip>
            </>
          )}
          {user.id !== currentUserId && (
            <>
              <Button
                id="deleteUserUserList"
                onClick={() => {
                  setDeleteId(user.id);
                  setModalMessage(
                    `Are you sure you want to delete user ${user.name}? ${
                      user.type !== "DATAINTELL"
                        ? "Please note that it will only reset the DataIntell account for the user."
                        : ""
                    }`
                  );
                  setShowConfirmation(true);
                }}
                color="danger"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <UncontrolledTooltip placement="auto" target="deleteUserUserList">
                Delete user
              </UncontrolledTooltip>
            </>
          )}
        </>,
      ],
      style: {},
      options: {},
    };

    return lineObject;
  });

  listFormated.push({
    columnData: ["", "", "", "", "", "", "", ""],
    style: {},
    options: {},
  });

  const fileTitles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "name" },
    { name: "Username", sort: "username" },
    { name: "Email", sort: "email" },
    { name: "Type", sort: "type" },
    { name: "Last Login Date", sort: "lastLoginAt" },
    { name: "Roles", sort: "roles" },
    { name: "Groups", sort: "groups" },
    { name: "Action" },
  ];

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(page) =>
              setPagination((prev) => ({ ...prev, page: page }))
            }
          />
          <TableResult
            title={"Files"}
            data={listFormated}
            titles={fileTitles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={changeOrder}
          />
        </div>
      )}

      <ConfirmModal
        showConfirmation={showConfirmation}
        title="Delete Confirmation"
        closeConfirmation={() => {
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
        }}
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
          deleteUser(deleteId);
        }}
      >
        {modalMessage}
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showUserForm}
        title="User"
        closeConfirmation={() => {
          setShowUserForm(false);
          setUserId("");
          setGroups([]);
          setUserFormObj(cleanUserFormObj);
        }}
        buttonColor="primary"
        buttonText={isCreateForm ? "Create" : "Save"}
        confirmAction={saveUser}
      >
        <UserForm
          userGroups={groups}
          groupList={groupList}
          errors={errorsObj}
          isCreateForm={isCreateForm}
          handleAddGroup={(group) => setGroups([...groups, group])}
          handleRemoveGroup={handleRemoveGroup}
          userFormObj={userFormObj}
          setUserFormObj={setUserFormObj}
        />
      </ConfirmModal>
      <ConfirmModal
        showConfirmation={showResetPasswordForm}
        title="Reset Password"
        closeConfirmation={() => {
          setShowResetPasswordForm(false);
          setUserId("");
          setNewPassword("");
          setConfirmPassword("");
        }}
        buttonColor="primary"
        buttonText="Reset password"
        confirmAction={() =>
          resetPassword(userId, newPassword, confirmPassword)
        }
      >
        <ResetPasswordForm
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          handleChange={handleChange}
          resetPasswordErrors={resetPasswordErrors}
        />
      </ConfirmModal>
      <UserWizard
        isVisible={showWizard}
        closeWizardFunction={() => {
          setShowWizard(false);
          setUserId("");
          setGroups([]);
          setUserFormObj(cleanUserFormObj);
          setAvailableGroups(groupList.filter((group) => GROUP === group.type));
          setErrorsObj({});
        }}
        saveUser={saveUser}
        userFormObj={userFormObj}
        groups={groups}
        groupList={groupList}
        errorsObj={errorsObj}
        isCreateForm={isCreateForm}
        handleAddGroup={handleAddGroup}
        handleRemoveGroup={handleRemoveGroup}
        setUserFormObj={setUserFormObj}
        availableGroups={availableGroups}
        setAvailableGroups={setAvailableGroups}
        selectedGroups={selectedGroups}
        createUser={createUser}
        isFormValid={isFormValid}
        setIsFormValid={setIsFormValid}
      />
    </>
  );
}

export default UserList;
