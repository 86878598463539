import { Alert } from "reactstrap";
import ConfirmModal from "./ConfirmModal";
import Loading from "./Loading";
import { useState } from "react";
import { get } from "../api/job";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const MODAL_STATE = { 
  INITIAL: "INITIAL", 
  LOADING: "LOADING", 
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  TIMEOUT: "TIMEOUT" 
};

export default function OperationModal(props) {
  const {
    isOpen,
    title,
    closeFunc,
    initialStateChildren,
    loadingStateText,
    successStateChildren,
    failureStateChildren,
    timeoutStateChildren,
    launchJobFunc,
    retryAmountCap = 20
  } = props;

  const [modalState, setModalState] = useState(MODAL_STATE.INITIAL);
  const [jobMessage, setJobMessage] = useState("");

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  const location = useLocation();

  const getResult = (retryAmount = 0, jobId) => {
    if (!isOpen) {
      return;
    }

    if (retryAmount >= retryAmountCap) {
      setModalState(MODAL_STATE.TIMEOUT);
      return;
    }

    setTimeout(async () => {
      get(dispatch, auth, jobId)
        .then(res => {
          if (res.status === "SUCCESS") {
            setModalState(MODAL_STATE.SUCCESS);
          } else if (res.status === "WARNING") {
            setJobMessage(res.result?.message);
            setModalState(MODAL_STATE.SUCCESS);
          } else if (res.status === "FAILURE") {
            setJobMessage(res.result?.errorMessage);
            setModalState(MODAL_STATE.FAILURE);
          } else {
            // If job is running, we want to try again later
            getResult(retryAmount + 1, jobId);
          }
        })
        .catch(err => {
          // If the API is down, it means that we probably had it restart, so we try again
          console.log("Unreachable API, will retry in 15 seconds.");
          getResult(retryAmount + 1, jobId);
        });
    }, 15000);
  };

  const getChildren = () => {
    switch (modalState) {
      case MODAL_STATE.INITIAL:
        return initialStateChildren;
      case MODAL_STATE.LOADING:
        return (
          <>
            <Alert color="info">{loadingStateText}</Alert>
            <Loading />
          </>
        );
      case MODAL_STATE.SUCCESS:
        return (
          <>
            <p>{successStateChildren}</p>
            {jobMessage.length > 0 && <Alert color="warning">{jobMessage}</Alert>}
          </>
        );
      case MODAL_STATE.FAILURE:
        return (
          <>
            <p>{failureStateChildren}</p>
            <Alert color="danger">{jobMessage}</Alert>
          </>
        );
      case MODAL_STATE.TIMEOUT:
        return timeoutStateChildren;
      default:
        return null;
    }
  };

  return (
    <ConfirmModal
      showConfirmation={isOpen}
      title={title}
      closeConfirmation={() => {
        closeFunc();
        setModalState(MODAL_STATE.INITIAL);
        setJobMessage("");

        if (modalState === MODAL_STATE.SUCCESS) {
          window.location.reload();
        }
      }}
      buttonColor="primary"
      buttonText={modalState === MODAL_STATE.INITIAL ? "Confirm" : "Retry"}
      cancelText={modalState === MODAL_STATE.INITIAL ? "Cancel" : "Close"}
      customButton={(modalState === MODAL_STATE.LOADING || modalState === MODAL_STATE.SUCCESS) ? <></> : null}
      confirmAction={async () => {
        const result = await launchJobFunc();

        if (result.id) {
          setModalState(MODAL_STATE.LOADING);
          getResult(0, result.id);
        } else {
          setModalState(MODAL_STATE.FAILURE);
        }
      }}
    >
      {getChildren()}
    </ConfirmModal>
  );
}