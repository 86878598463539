import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RestoreArchiveButton({
    onClickFunction
}) {
    const [isIconHovered, setIsIconHovered] = useState(false);

    return (
        <>
            <FontAwesomeIcon
                id="restoreArchivedFiles"
                icon={faBoxOpen}
                size="lg"
                style={{ cursor: "pointer" }}
                className="ms-1"
                color={isIconHovered ? "#21252991" : "#212529"}
                onMouseEnter={() => setIsIconHovered(true)}
                onMouseLeave={() => setIsIconHovered(false)}
                onClick={onClickFunction}
            />
            <UncontrolledTooltip target={"restoreArchivedFiles"} placement="auto">
                Restore the selected files from Archiware P5
            </UncontrolledTooltip>
        </>
    )
}