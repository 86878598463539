import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SourceReview from "./SourceReview";
import ArchiveDestinationReview from "./ArchiveDestinationReview";
import RestoreDestinationReview from "./RestoreDestinationReview";

export default function ManualSelectionReview({
  userInputObj,
  parseFileDeletionMethod,
  selectedFiles,
  isDirectory,
  setDisableForwardActionButton,
  restoring = false,
  setDeltaScanAfter,
}) {
  const volumes = useSelector(({ volumes }) => volumes.items);

  const [validTotalFileSize, setValidTotalFileSize] = useState(0);
  const [numberOfValidFileOrFolders, setNumberOfValidFileOrFolders] =
    useState(0);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [totalNumberOfFilesOrFolders, setTotalNumberOfFilesOrFolders] =
    useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const fileTypes = restoring
    ? ["Archives", "Archiware"]
    : ["crawl.files", "NetworkVolume", "DellPowerScale"];

  const calculateReviewValues = () => {
    let validVolumes = {};
    volumes.forEach((volume) => {
      if (fileTypes.includes(volume.type)) {
        validVolumes[volume.id] = volume.name;
      }
    });

    let validFiles = 0;
    let validTotalFileSize = 0;
    let activeVolume = {};

    selectedFiles.forEach((file) => {
      if (validVolumes[file.volumeId]) {
        validFiles++;
        validTotalFileSize += file.size;
        activeVolume[file.volumeId] = file.volumeName;
      }
    });

    // Number of files
    setTotalNumberOfFilesOrFolders(selectedFiles.length);
    // Number of valid folders
    setNumberOfValidFileOrFolders(validFiles);
    // Size of valid files
    setValidTotalFileSize(validTotalFileSize);
    // Total Size
    setTotalFileSize(selectedFiles.reduce((a, b) => a + b.size, 0));
    setActiveVolumes(Object.values(activeVolume));

    if (validFiles > 0) {
      setDisableForwardActionButton(false);
    }
  };

  useEffect(() => {
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, []);

  return (
    <>
      <div className="mx-2">
        <SourceReview
          fileOrFolder={isDirectory ? "folder" : "file"}
          numberOfValidFileOrFolders={numberOfValidFileOrFolders}
          validTotalFileSize={validTotalFileSize}
          totalFileSize={totalFileSize}
          action={restoring ? "restore" : "archive"}
          fileType={restoring ? "Archiware P5" : "On-Premises"}
          totalNumberOfFilesOrFolders={totalNumberOfFilesOrFolders}
          addP5Icon={restoring}
          activeVolumes={activeVolumes}
        />

        {restoring ? (
          <RestoreDestinationReview location={userInputObj.location} />
        ) : (
          <ArchiveDestinationReview
            indexName={userInputObj.indexName}
            planName={userInputObj.planName}
            parseFileDeletionMethod={parseFileDeletionMethod}
            location={userInputObj.location}
            deltaScanAfter={userInputObj.deltaScanAfter}
            setDeltaScanAfter={setDeltaScanAfter}
          />
        )}
      </div>
    </>
  );
}
