import { useState, useEffect } from "react";
import { Input, Form, FormGroup } from 'reactstrap';

export default function TagCheckbox(props) {
  const {
    file,
    selectedFiles,
    setSelectedFiles,
    selectAllDisable
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(selectedFiles.some(f => f.fileId === file.fileId));
  }, [file, selectedFiles]);

  return (
    <Form>
      <FormGroup check>
        <Input
          disabled={selectAllDisable}
          checked={selectAllDisable ? true : isChecked}
          type="checkbox"
          onClick={e => e.stopPropagation()}
          onChange={e => {
            const checked = e.target.checked;
            const foundFile = selectedFiles.find(f => f.fileId === file.fileId);
            if (foundFile && !checked) {
              setSelectedFiles(selectedFiles.filter(f => f.fileId !== foundFile.fileId));
            } else if (!foundFile && checked) {
              setSelectedFiles([...selectedFiles, file]);
            }
          }}
        />
      </FormGroup>
    </Form>
  );
}