import { REQUEST_DATES, RECEIVE_DATES, SET_DATE } from '../actions';

function dates(state = {items:[], isFetching:false }, action) {
    switch (action.type) {
        case REQUEST_DATES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_DATES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt,
                activeDate: action.dates[0],
                items: action.dates
            })
        case SET_DATE:
            return Object.assign({}, state, {
                activeDate: action.date
            })
        default:
            return state
    }
}

export default dates;