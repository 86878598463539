import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchDetailedVolumes } from "../ducks/detailedVolumes";
import VolumeChartWithDates from "./components/VolumeChartWithDates";
import { DAY_IN_MILLISECONDS } from "../constants/dateFormatting";
import { fetchWithAuth } from "../actions/requestHelper";
import VolumeDetailCard from "./components/VolumeDetailCard";
import VolumeSizeCard from "./components/VolumeSizeCard";
import VolumePricingCard from "./components/VolumePricingCard";
import VolumeInsightCard from "./components/VolumeInsightCard";
import VolumePDF from "./components/VolumePDF";
import VolumeIcon from "../components/VolumeIcon";
import moment from "moment";
import VolumeAggregationChart from "./components/VolumeAggregationChart";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import VolumeDateChart from "./components/VolumeDateChart";
import VolumeSizeChart from "./components/VolumeSizeChart";
import { useParams } from "react-router-dom";

const CARD_POSITIONS = {
  default: "default",
  fileManagement: "fileManagement",
};

function Volumes(props) {
  const {
    match,
    activeDate,
    dispatch,
    volumes,
    isFetching,
    volumeSettings,
    auth,
    cardPosition = CARD_POSITIONS.default,
    titleSize = 1,
  } = props;

  const [latestVolumeSettings, setLatestVolumeSettings] = useState(null);
  const [duplicateSize, setDuplicateSize] = useState(0);
  const [unusedThreeMonthsSize, setUnusedThreeMonthsSize] = useState(0);
  const [unusedSixMonthsSize, setUnusedSixMonthsSize] = useState(0);
  const [unusedSixMonthsDate, setUnusedSixMonthsDate] = useState(null);
  const [unusedOneYearSize, setUnusedOneYearSize] = useState(0);
  const [unusedOneYearDate, setUnusedOneYearDate] = useState(null);
  const [timelineRef, setTimelineRef] = useState();
  const [sizeChartRef, setSizeChartRef] = useState(null);
  const [maxSize, setMaxSize] = useState(0);
  const [minSize, setMinSize] = useState(0);
  const [chartFromDate, setChartFromDate] = useState();
  const [chartToDate, setChartToDate] = useState();
  const [chartInterval, setChartInterval] = useState("30");
  const [chartUnit, setChartUnit] = useState("");
  const [deltaInfo, setDeltaInfo] = useState("");
  const [selectedCostInterval, setSelectedCostInterval] = useState(
    window.localStorage.getItem("storageCostIntervalVolume") ?? "Monthly"
  );

  const params = match ?? useParams();

  const volume = volumeSettings.items.find((vol) => vol.id == params.id);

  useEffect(() => {
    dispatch(
      fetchDetailedVolumes(
        params.id,
        moment(Date.parse(activeDate) - DAY_IN_MILLISECONDS * 29).format(
          "YYYY-MM-DD"
        ),
        activeDate
      )
    );
    setLatestVolumeSettings(
      volumeSettings.items.filter((obj) => obj.id.toString() === params.id)[0]
    );
  }, [params.id, volumeSettings, activeDate, dispatch]);

  // initial setup for monthly cost values
  useEffect(() => {
    let unmounted = false;

    (async function fetchVolumeData() {
      try {
        const duplicateRes = await fetchWithAuth(
          dispatch,
          `/files/search/sizes?search=&indexingDate=${activeDate}&onlyDuplicates=true&volumeId=${params.id}`,
          auth.accessToken
        );

        let threeMonthInThePast = new Date();
        threeMonthInThePast.setMonth(threeMonthInThePast.getMonth() - 3);
        threeMonthInThePast = moment(threeMonthInThePast).format("YYYY-MM-DD");
        let sixMonthInThePast = new Date();
        sixMonthInThePast.setMonth(sixMonthInThePast.getMonth() - 6);
        sixMonthInThePast = moment(sixMonthInThePast).format("YYYY-MM-DD");
        let oneYearInThePast = new Date();
        oneYearInThePast.setMonth(oneYearInThePast.getMonth() - 12);
        oneYearInThePast = moment(oneYearInThePast).format("YYYY-MM-DD");

        const unusedThreeMonthRes = await fetchWithAuth(
          dispatch,
          `/files/search/sizes?search=&indexingDate=${activeDate}&lastAccessBefore=${threeMonthInThePast}&volumeId=${params.id}`,
          auth.accessToken
        );

        const unusedSixMonthRes = await fetchWithAuth(
          dispatch,
          `/files/search/sizes?search=&indexingDate=${activeDate}&lastAccessBefore=${sixMonthInThePast}&volumeId=${params.id}`,
          auth.accessToken
        );

        const unusedOneYearRes = await fetchWithAuth(
          dispatch,
          `/files/search/sizes?search=&indexingDate=${activeDate}&lastAccessBefore=${oneYearInThePast}&volumeId=${params.id}`,
          auth.accessToken
        );

        if (!unmounted) {
          setDuplicateSize(duplicateRes);
          setUnusedThreeMonthsSize(unusedThreeMonthRes);
          setUnusedSixMonthsSize(unusedSixMonthRes);
          setUnusedSixMonthsDate(sixMonthInThePast);
          setUnusedOneYearSize(unusedOneYearRes);
          setUnusedOneYearDate(oneYearInThePast);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [activeDate, params.id, auth.accessToken, dispatch]);

  const getCardsPositionedByValue = () => {
    const isCloudOrArchive = ["Archives", "Archiware", "AWS-S3"].includes(
      volumes[0].type
    );
    if (cardPosition === CARD_POSITIONS.fileManagement) {
      return (
        <div className="row">
          <div className="col-lg-12 col-xl-6" style={{ paddingBottom: 10 }}>
            <VolumeDetailCard latestDetailedVolume={volumes[0]} />
          </div>
          {!isCloudOrArchive && (
            <div className="col-lg-12 col-xl-6">
              <VolumeSizeCard
                latestDetailedVolume={volumes[0]}
                setSizeChartRef={setSizeChartRef}
              />
            </div>
          )}
          <div className="col-lg-12 col-xl-6">
            <VolumePricingCard
              volumeId={params.id}
              showLoading={volumeSettings.isFetching}
              latestVolumeSettings={latestVolumeSettings}
              latestDetailedVolume={volumes[0]}
              duplicateSize={duplicateSize}
              unusedThreeMonthsSize={unusedThreeMonthsSize}
              unusedSixMonthsSize={unusedSixMonthsSize}
              unusedOneYearSize={unusedOneYearSize}
              selectedCostInterval={selectedCostInterval}
              setSelectedCostInterval={setSelectedCostInterval}
              margin="mb-sm-3 mb-xl-0"
            />
            {["Archives", "Archiware", "AWS-S3"].includes(volumes[0].type) && (
              <VolumeInsightCard
                volumeId={params.id}
                unusedSixMonthsDate={unusedSixMonthsDate}
                unusedSixMonthsSize={unusedSixMonthsSize}
                duplicateSize={duplicateSize}
                unusedOneYearSize={unusedOneYearSize}
                unusedOneYearDate={unusedOneYearDate}
                volumeType={volumes[0].type}
              />
            )}
          </div>
          {!isCloudOrArchive && (
            <div className="col-lg-12 col-xl-6">
              <VolumeInsightCard
                volumeId={params.id}
                unusedSixMonthsDate={unusedSixMonthsDate}
                unusedSixMonthsSize={unusedSixMonthsSize}
                duplicateSize={duplicateSize}
                unusedOneYearSize={unusedOneYearSize}
                unusedOneYearDate={unusedOneYearDate}
                volumeType={volumes[0].type}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div
            className={
              isCloudOrArchive ? "col-md-12  col-lg-6" : "col-md-12  col-lg-4"
            }
            style={{ paddingBottom: 10 }}
          >
            <VolumeDetailCard latestDetailedVolume={volumes[0]} />
          </div>
          {!isCloudOrArchive && (
            <div className="col-md-12 col-lg-4">
              <VolumeSizeCard
                latestDetailedVolume={volumes[0]}
                setSizeChartRef={setSizeChartRef}
              />
            </div>
          )}
          <div
            className={
              isCloudOrArchive ? "col-md-12  col-lg-6" : "col-md-12  col-lg-4"
            }
          >
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <VolumePricingCard
                  volumeId={params.id}
                  showLoading={volumeSettings.isFetching}
                  latestVolumeSettings={latestVolumeSettings}
                  latestDetailedVolume={volumes[0]}
                  duplicateSize={duplicateSize}
                  unusedThreeMonthsSize={unusedThreeMonthsSize}
                  unusedSixMonthsSize={unusedSixMonthsSize}
                  unusedOneYearSize={unusedOneYearSize}
                  selectedCostInterval={selectedCostInterval}
                  setSelectedCostInterval={setSelectedCostInterval}
                  margin="mb-sm-2 mb-md-0 mb-lg-3"
                />
              </div>
              <div className="col-md-6 col-lg-12">
                <VolumeInsightCard
                  volumeId={params.id}
                  unusedSixMonthsDate={unusedSixMonthsDate}
                  unusedSixMonthsSize={unusedSixMonthsSize}
                  duplicateSize={duplicateSize}
                  unusedOneYearSize={unusedOneYearSize}
                  unusedOneYearDate={unusedOneYearDate}
                  volumeType={volumes[0].type}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const getTitle = () => {
    const title = (
      <>
        <VolumeIcon volumeType={volume?.type} size="sm" />
        <span className="ms-1">{volume?.name}</span>
      </>
    );

    switch (titleSize) {
      case 1:
        return <h1>{title}</h1>;
      case 2:
        return <h2>{title}</h2>;
      case 3:
        return <h3>{title}</h3>;
      case 4:
        return <h4>{title}</h4>;
      case 5:
        return <h5>{title}</h5>;
      case 6:
        return <h6>{title}</h6>;
      default:
        return <h1>{title}</h1>;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {getTitle()}
        <div>
          <VolumePDF
            volumeName={volume?.name}
            latestDetailedVolume={volumes[0]}
            latestVolumeSettings={latestVolumeSettings}
            duplicateSize={duplicateSize}
            unusedSixMonthsSize={unusedSixMonthsSize}
            unusedOneYearSize={unusedOneYearSize}
            timelineRef={timelineRef}
            sizeChartRef={sizeChartRef}
            minSize={minSize}
            maxSize={maxSize}
            chartUnit={chartUnit}
            chartFromDate={chartFromDate}
            chartToDate={chartToDate}
            chartInterval={chartInterval}
            deltaInfo={deltaInfo}
          />
        </div>
      </div>

      {!isFetching && volumes.length > 0 && getCardsPositionedByValue()}

      <VolumeChartWithDates
        volumeId={params.id}
        indexingDate={activeDate}
        title="Used Capacity"
        setTimelineRef={setTimelineRef}
        setMaxSize={setMaxSize}
        setMinSize={setMinSize}
        setChartFromDate={setChartFromDate}
        setChartToDate={setChartToDate}
        setChartInterval={setChartInterval}
        setChartUnit={setChartUnit}
        setDeltaInfo={setDeltaInfo}
      />

      {!isFetching && volumes.length > 0 && volumes[0].statistics && (
        <>
          <hr />
          <h4>
            <FontAwesomeIcon icon={faChartSimple} />
            <span className="ms-2">Statistics</span>
          </h4>
          <Row>
            <Col sm={12}>
              <VolumeSizeChart latestDetailedVolume={volumes[0]} />
              <VolumeDateChart
                latestDetailedVolume={volumes[0]}
                before="lastModifiedBefore"
                after="lastModifiedAfter"
                name="MODIFIED_DATE"
                title="Last Modified"
              />
              {volumes[0].type === "crawl.files" && (
                <VolumeDateChart
                  latestDetailedVolume={volumes[0]}
                  before="lastAccessBefore"
                  after="lastAccessAfter"
                  name="ACCESS_DATE"
                  title="Last Access"
                />
              )}
              {volumes[0].type !== "AWS-S3" && (
                <VolumeDateChart
                  latestDetailedVolume={volumes[0]}
                  before="creationBefore"
                  after="creationAfter"
                  name="CREATION_DATE"
                  title="Creation"
                />
              )}
              {volumes[0].type === "Archiware" && (
                <VolumeDateChart
                  latestDetailedVolume={volumes[0]}
                  before="backupBefore"
                  after="backupAfter"
                  name="BACKUP_DATE"
                  title="Backup"
                />
              )}
            </Col>
            <Col sm={6}>
              <VolumeAggregationChart
                latestDetailedVolume={volumes[0]}
                empty="No extension"
                fieldName="extension"
                name="EXTENSION"
                title="Extensions"
                componentId="volumeExtensionChartId"
              />
            </Col>
            {volumes[0].type === "AWS-S3" && (
              <Col sm={6}>
                <VolumeAggregationChart
                  latestDetailedVolume={volumes[0]}
                  empty="No storage class"
                  fieldName="storageClass"
                  name="STORAGE_CLASS"
                  title="Storage Classes"
                  componentId="volumeStorageClassId"
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { dates, detailedVolumes, volumeSettings, search, auth } = state;
  const activeDate = dates.activeDate;
  const isFetching = detailedVolumes.isFetching;
  const volumes = detailedVolumes.items;
  return { activeDate, volumes, isFetching, volumeSettings, search, auth };
}

export default connect(mapStateToProps)(Volumes);
