import {
    NEW_ERROR,
} from '../actions';

function error(state = {}, action) {
    switch (action.type) {
        case NEW_ERROR:
            return Object.assign({}, state, {
                message: action.message
            })
        default:
            return state
    }
}

export default error;