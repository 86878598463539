import React, { useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import PropTypes from "prop-types";
import HistogramChart from "../../components/HistogramChart";
import PDFChartButton from "../../components/PDFChartButton";
import { formatSize } from "../../components/FormatSize";
import { useSelector } from "react-redux";

Storage.propTypes = {
  storageList: PropTypes.array,
  handlePathChange: PropTypes.func,
};

function Storage(props) {
  const { title, subTitle, storageList, handlePathChange } = props;
  const chartRef = useRef();

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const filesizeObject = formatSize(
    storageList.length > 0
      ? storageList.reduce((a, b) => a + b.used, 0) / storageList.length
      : 0,
    baseSize,
    null,
    "object"
  );
  const color = "#5F9EA0";

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <CardHeader>
        <CardTitle tag="h5">
          <span>{title}</span>
          <div className="float-end">
            <PDFChartButton
              chartRef={chartRef}
              title={title}
              subTitle={filesizeObject.unit}
            />
          </div>
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          {subTitle}
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <HistogramChart
          unit={filesizeObject.unit}
          histogramRef={chartRef}
          data={storageList.map(
            (storage) =>
              formatSize(
                storage.used,
                baseSize,
                filesizeObject.exponent,
                "object"
              ).value
          )}
          title={`Size (${filesizeObject.unit})`}
          labels={storageList.map((storage) => storage.name)}
          color={color}
          horizontal
          maxBarThickness={50}
          onClick={(index) => {
            if (null !== index) {
              handlePathChange(
                "/filemanagement?path=/&volumeId=" + storageList[index].id
              );
            }
          }}
          storageLegend
        />
      </CardBody>
    </Card>
  );
}

export default Storage;
