import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getArchiwareVolumeMappingByVolumeId,
  createArchiwareVolumeMapping,
  updateArchiwareVolumeMapping,
  deleteArchiwareVolumeMapping,
} from "../../api/archiware";
import { setAlert } from "../../actions/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import ArchiwareGlobalVolumeMappingModal from "./ArchiwareGlobalVolumeMappingModal";
import ConfirmModal from "../../components/ConfirmModal";

export default function ArchiwareGlobalVolumeMapping() {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [volumeId, setVolumeId] = useState(-1);
  const [dataintellPath, setDataintellPath] = useState("");
  const [archiwarePath, setArchiwarePath] = useState("");

  useEffect(() => {
    getGlobalArchiwareVolumeMapping();
  }, []);

  const getGlobalArchiwareVolumeMapping = async () => {
    try {
      const globalVolumeMapping = await getArchiwareVolumeMappingByVolumeId(
        dispatch,
        auth,
        -1
      );

      setDataintellPath(globalVolumeMapping.dataintellPath);
      setArchiwarePath(globalVolumeMapping.archiwarePath);
      setId(globalVolumeMapping.id);
      setVolumeId(-1);
    } catch (err) {
      setDataintellPath("");
      setArchiwarePath("");
      setId(null);
      setVolumeId(-1);
    }
  };

  const createGlobalVolumeMapping = async (volumeMapping) => {
    try {
      await createArchiwareVolumeMapping(dispatch, auth, volumeMapping);
      await getGlobalArchiwareVolumeMapping();
      dispatch(
        setAlert(
          "Global storage path configuration for Archiware P5 was created successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to create global storage path configuration for Archiware P5";
      dispatch(setAlert(message, "danger"));
    }
  };

  const updateGlobalVolumeMapping = async (volumeMapping) => {
    try {
      await updateArchiwareVolumeMapping(dispatch, auth, id, volumeMapping);
      await getGlobalArchiwareVolumeMapping();
      dispatch(
        setAlert(
          "Global storage path configuration for Archiware P5 was updated successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to update global storage path configuration for Archiware P5";
      dispatch(setAlert(message, "danger"));
    }
  };

  const deleteGlobalVolumeMapping = async () => {
    try {
      await deleteArchiwareVolumeMapping(dispatch, auth, id);
      setDataintellPath("");
      setArchiwarePath("");
      setId("");
      setVolumeId(-1);
      dispatch(
        setAlert(
          "Global storage path configuration for Archiware P5 was deleted successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message ||
        "Unable to delete the global storage path configuration for Archiware P5";
      dispatch(setAlert(message, "danger"));
    }
  };

  return (
    <div style={{ maxWidth: "800px" }}>
      <Card style={{ marginBottom: "1rem" }}>
        <CardHeader>
          <CardTitle tag="h6">
            <span>Global Storage Path Configuration</span>
            <div className="float-end">
              {id ? (
                <>
                  <Button
                    className="me-2"
                    onClick={() => setIsUpdateModalOpen(true)}
                    id="updateGlobalVolumeMappingButton"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>

                  <UncontrolledTooltip target="updateGlobalVolumeMappingButton">
                    Update the Archiware P5 global storage path configuration
                  </UncontrolledTooltip>

                  <Button
                    onClick={() => setIsDeleteModalOpen(true)}
                    color="danger"
                    id="deleteGlobalVolumeMappingButton"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <UncontrolledTooltip target="deleteGlobalVolumeMappingButton">
                    Delete the Archiware P5 global storage path configuration
                  </UncontrolledTooltip>
                </>
              ) : (
                <>
                  <Button
                    className="me-2"
                    onClick={() => setIsUpdateModalOpen(true)}
                    id="createGlobalVolumeMappingButton"
                    color="success"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>

                  <UncontrolledTooltip target="createGlobalVolumeMappingButton">
                    Create the Archiware P5 global storage path configuration
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </CardTitle>
          <CardSubtitle tag="p" className="mb-2 text-muted">
            Set your global storage path configuration for Archiware P5.
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          {id ? (
            <>
              <p>
                <strong>DataIntell Path:</strong> {dataintellPath}
              </p>
              <p>
                <strong>Archiware P5 Path:</strong> {archiwarePath}
              </p>
            </>
          ) : (
            <p>
              DataIntell will use the same path configured under the On-Premises
              storages section.
            </p>
          )}
        </CardBody>
      </Card>

      <ArchiwareGlobalVolumeMappingModal
        id={id}
        action={id ? "Update" : "Add"}
        volumeId={volumeId}
        dataintellPath={dataintellPath}
        archiwarePath={archiwarePath}
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        configurationsFunc={(value) => {
          id
            ? updateGlobalVolumeMapping(value)
            : createGlobalVolumeMapping(value);
        }}
      />

      <ConfirmModal
        showConfirmation={isDeleteModalOpen}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteGlobalVolumeMapping();
          setIsDeleteModalOpen(false);
        }}
        closeConfirmation={() => setIsDeleteModalOpen(false)}
      >
        Are you sure you want to delete the global storage path configuration
        for Archiware P5?
      </ConfirmModal>
    </div>
  );
}
