import { Col, FormGroup, Input, Label } from "reactstrap";
import DocumentationButton from "../../components/DocumentationButton";

export default function OktaConfiguration(props) {
  const {
    isOktaActive,
    setIsOktaActive,
    oktaClientId,
    setOktaClientId,
    oktaDomain,
    setOktaDomain,
    oktaRedirectUri,
    setOktaRedirectUri,
    oktaApiToken,
    setOktaApiToken,
  } = props;

  return (
    <>
      <h5>
        Okta
        <DocumentationButton
          id="oktaDocumentationButton"
          url="https://support.cloudsoda.io/hc/en-us/articles/27835836532891-Single-Sign-On-SSO-Okta"
        />
      </h5>

      <FormGroup row style={{ display: "flex", alignItems: "center" }}>
        <Label for="enableOktaLogin" sm={2}>
          Enable Okta Login
        </Label>
        <Col sm={4}>
          <FormGroup switch>
            <Input
              type="switch"
              id="enableOktaLogin"
              name="enableOktaLogin"
              onChange={(e) =>
                setIsOktaActive((prev) => ({
                  ...prev,
                  value: e.target.checked ? "true" : "false",
                }))
              }
              checked={isOktaActive.value === "true"}
            />
          </FormGroup>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="oktaClientId" sm={2}>
          Client ID
        </Label>
        <Col sm={4}>
          <Input
            id="oktaClientId"
            name="oktaClientId"
            value={oktaClientId.value}
            onChange={(e) =>
              setOktaClientId((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={isOktaActive.value === "false"}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="oktaDomain" sm={2}>
          Domain
        </Label>
        <Col sm={4}>
          <Input
            id="oktaDomain"
            name="oktaDomain"
            value={oktaDomain.value}
            onChange={(e) =>
              setOktaDomain((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={isOktaActive.value === "false"}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="oktaRedirectUri" sm={2}>
          Redirect URI
        </Label>
        <Col sm={4}>
          <Input
            id="oktaRedirectUri"
            name="oktaRedirectUri"
            value={oktaRedirectUri.value}
            onChange={(e) =>
              setOktaRedirectUri((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={isOktaActive.value === "false"}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="oktaApiToken" sm={2}>
          API Token
        </Label>
        <Col sm={4}>
          <Input
            id="oktaApiToken"
            name="oktaApiToken"
            value={oktaApiToken.value}
            onChange={(e) =>
              setOktaApiToken((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
            disabled={isOktaActive.value === "false"}
          />
        </Col>
      </FormGroup>
    </>
  );
}
