import React from "react";
import RectangleChart from "../../components/RectangleChart";
import moment from "moment";
import PropTypes from "prop-types";
import FormatSize from "../../components/FormatSize";

VolumeFolder.propTypes = {
  item: PropTypes.object,
  handleDetailClick: PropTypes.func,
  indexingDate: PropTypes.string
};

function VolumeFolder(props) {
  const { item, handleDetailClick, indexingDate } = props;

  const duplicateSize = item.statistics.DUPLICATE && item.statistics.DUPLICATE.duplicate ? item.statistics.DUPLICATE.duplicate.sizeOfFiles : 0;
  const duplicateNumberOfFiles = item.statistics.DUPLICATE && item.statistics.DUPLICATE.duplicate ? item.statistics.DUPLICATE.duplicate.numberOfFiles : 0;
  const lastAccessOneYearSize = item.statistics.ACCESS_DATE && item.statistics.ACCESS_DATE["1 year"] ? item.statistics.ACCESS_DATE["1 year"].sizeOfFiles : 0;
  const lastAccessSixMonthsSize = item.statistics.ACCESS_DATE && item.statistics.ACCESS_DATE["6 month"] ? item.statistics.ACCESS_DATE["6 month"].sizeOfFiles : 0;
  const lastAccessThreeMonthsSize = item.statistics.ACCESS_DATE && item.statistics.ACCESS_DATE["3 month"] ? item.statistics.ACCESS_DATE["3 month"].sizeOfFiles : 0;
  const lastAccessOneMonthSize = item.statistics.ACCESS_DATE && item.statistics.ACCESS_DATE["1 month"] ? item.statistics.ACCESS_DATE["1 month"].sizeOfFiles : 0;
  const lastAccessOneWeekSize = item.statistics.ACCESS_DATE && item.statistics.ACCESS_DATE["1 week"] ? item.statistics.ACCESS_DATE["1 week"].sizeOfFiles : 0;

  // Setting up values for duplicate data RectangleChart
  const labelsDuplicate = [
    <>Duplicate data: <FormatSize>{duplicateSize}</FormatSize></>,
    <>Unique data: <FormatSize>{item.sizeOfFiles - duplicateSize}</FormatSize></>
  ];
  const dataDuplicate = [
    duplicateSize,
    item.sizeOfFiles - duplicateSize
  ];
  const colorDuplicate = ["rgba(255,243,37,1)", "#00B23C"];
  const linksDuplicate = [
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&onlyDuplicates=true`,
    `/search?volumeId=${item.volumeId}&startingPath=${item.path}`
  ];

  // Setting up values for last access data RectangleChart
  const lastAccess6Months = lastAccessSixMonthsSize - lastAccessOneYearSize;
  const lastAccess3months = lastAccessThreeMonthsSize - lastAccessSixMonthsSize;
  const lastAccess1month = lastAccessOneMonthSize - lastAccessThreeMonthsSize;
  const lastAccess1week = lastAccessOneWeekSize - lastAccessOneMonthSize;
  const other = item.sizeOfFiles - lastAccessOneWeekSize;

  const labelsLastAccess = [
    <>Last access {">"} 1 year: <FormatSize>{lastAccessOneYearSize}</FormatSize></>,
    <>Last access between 6 months and 1 year: <FormatSize>{lastAccess6Months}</FormatSize></>,
    <>Last access between 3 months and 6 months: <FormatSize>{lastAccess3months}</FormatSize></>,
    <>Last access between 1 month and 3 months: <FormatSize>{lastAccess1month}</FormatSize></>,
    <>Last access between 1 week and 1 month: <FormatSize>{lastAccess1week}</FormatSize></>,
    <>Last access {"<"} 1 week: <FormatSize>{other}</FormatSize></>
  ];
  const dataLastAccess = [
    lastAccessOneYearSize,
    lastAccess6Months,
    lastAccess3months,
    lastAccess1month,
    lastAccess1week,
    other
  ];
  const colorLastAccess = [
    "#C91D1E",
    "#D37039",
    "#D3963E",
    "#FAE556",
    "#7FB227",
    "#00B23C"
  ];
  const linksLastAccess = [
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessBefore=${moment(indexingDate)
      .subtract(1, "years")
      .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessBefore=${moment(indexingDate)
      .subtract(6, "months")
      .format("YYYY-MM-DD")}&lastAccessAfter=${moment(indexingDate)
        .subtract(1, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessBefore=${moment(indexingDate)
      .subtract(3, "months")
      .format("YYYY-MM-DD")}&lastAccessAfter=${moment(indexingDate)
        .subtract(6, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessBefore=${moment(indexingDate)
      .subtract(1, "months")
      .format("YYYY-MM-DD")}&lastAccessAfter=${moment(indexingDate)
        .subtract(3, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessBefore=${moment(indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}&lastAccessAfter=${moment(indexingDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${item.volumeId}&startingPath=${
    item.path
    }&lastAccessAfter=${moment(indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}`
  ];

  return (
    <>
      <h5 className="mb-0">Duplicate data</h5>
      <div className="mb-2">
        <RectangleChart
          links={linksDuplicate}
          handleClick={handleDetailClick}
          labels={labelsDuplicate}
          data={dataDuplicate}
          colors={colorDuplicate}
        />
      </div>

      <h5 className="mb-0">Last access data</h5>
      <div className="mb-2">
        <RectangleChart
          links={linksLastAccess}
          handleClick={handleDetailClick}
          labels={labelsLastAccess}
          data={dataLastAccess}
          colors={colorLastAccess}
        />
      </div>

      <div className="row">
        <div className="col-sm-6">
          <h6>
            Size: <strong><FormatSize>{item.sizeOfFiles}</FormatSize></strong>
          </h6>
          <h6>
            Last access date:{" "}
            <strong>{moment(item.lastAccessDate).format("lll")}</strong>
          </h6>
          <h6>
            Number of files: <strong>{item.numberOfFiles}</strong>
          </h6>
          <h6>
            Duplicate data:{" "}
            <strong><FormatSize>{duplicateSize}</FormatSize></strong>
          </h6>
          <h6>
            Number of duplicate files:{" "}
            <strong>{duplicateNumberOfFiles}</strong>
          </h6>
        </div>
        <div className="col-sm-6">
          <h6>
            Last access date {">"} 1 Year:{" "}
            <strong>
              <FormatSize>{lastAccessOneYearSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last access date {">"} 6 Months:{" "}
            <strong>
              <FormatSize>{lastAccessSixMonthsSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last access date {">"} 3 Months:{" "}
            <strong>
              <FormatSize>{lastAccessThreeMonthsSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last access date {">"} 1 Month:{" "}
            <strong>
              <FormatSize>{lastAccessOneMonthSize}</FormatSize>
            </strong>
          </h6>
          <h6>
            Last access date {">"} 1 Week:{" "}
            <strong>
              <FormatSize>{lastAccessOneWeekSize}</FormatSize>
            </strong>
          </h6>
        </div>
      </div>
    </>
  );
}

export default VolumeFolder;
