import { PublicClientApplication } from "@azure/msal-browser";
import {
  faCheckCircle,
  faSpinner,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OktaAuth from "@okta/okta-auth-js";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { isOktaAPITokenValid } from "../../api/configuration";
import { useDispatch, useSelector } from "react-redux";

function LoginStatus(props) {
  const { isActive, isTesting, isValid, children } = props;

  return (
    <>
      <span style={{ color: `#212529${isActive ? "" : "55"}` }}>
        {children}
      </span>
      {isTesting ? (
        <FontAwesomeIcon className="ms-1 fa-spin" icon={faSpinner} />
      ) : (
        isValid !== null && (
          <FontAwesomeIcon
            className="ms-1"
            icon={isValid ? faCheckCircle : faXmarkCircle}
            color={`${isValid ? "#00FF00" : "#FF0000"}${isActive ? "" : "55"}`}
          />
        )
      )}
    </>
  );
}

export default function UpdateLoginConfigurationModal(props) {
  const {
    isOpen,
    setIsOpen,
    isDefaultActive,
    isEntraIdActive,
    entraIdClientId,
    entraIdTenantId,
    entraIdRedirectUri,
    isOktaActive,
    oktaClientId,
    oktaDomain,
    oktaRedirectUri,
    oktaApiToken,
    updateConfigurations,
    updateConfigurationsOnFailure,
  } = props;

  const [testDefaultLogin, setTestDefaultLogin] = useState({
    isTesting: false,
    isValid: null,
    errorCause: null,
  });
  const [testEntraIdLogin, setTestEntraIdLogin] = useState({
    isTesting: false,
    isValid: null,
    errorCause: null,
  });
  const [testOktaLogin, setTestOktaLogin] = useState({
    isTesting: false,
    isValid: null,
    errorCause: null,
  });
  const [isDefaultActiveOnLoad, setIsDefaultActiveOnLoad] =
    useState(isDefaultActive);
  const [isEntraIdActiveOnLoad, setIsEntraIdActiveOnLoad] =
    useState(isEntraIdActive);
  const [isOktaActiveOnLoad, setIsOktaActiveOnLoad] = useState(isOktaActive);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (isOpen) {
      setIsDefaultActiveOnLoad(isDefaultActive);
      setIsEntraIdActiveOnLoad(isEntraIdActive);
      setIsOktaActiveOnLoad(isOktaActive);
      testLogins();
    } else {
      setTestDefaultLogin({
        isTesting: false,
        isValid: null,
        errorCause: null,
      });
      setTestEntraIdLogin({
        isTesting: false,
        isValid: null,
        errorCause: null,
      });
      setTestOktaLogin({
        isTesting: false,
        isValid: null,
        errorCause: null,
      });
    }
  }, [isOpen]);

  const updateValidConfigurations = (updatedStates) => {
    if (
      (!isDefaultActive || (isDefaultActive && updatedStates.isDefaultValid)) &&
      (!isEntraIdActive || (isEntraIdActive && updatedStates.isEntraValid)) &&
      (!isOktaActive || (isOktaActive && updatedStates.isOktaValid))
    ) {
      updateConfigurations();
    } else {
      updateConfigurationsOnFailure(
        updatedStates.isEntraValid,
        updatedStates.isOktaValid
      );
    }
  };

  const testLogins = async () => {
    const updatedStates = {};
    testDefaultLoginFunc().then((res) => {
      updatedStates.isDefaultValid = res;
      testEntraIdLoginFunc().then((res) => {
        updatedStates.isEntraValid = res;
        testOktaLoginFunc().then((res) => {
          updatedStates.isOktaValid = res;
          updateValidConfigurations(updatedStates);
        });
      });
    });
  };

  const testDefaultLoginFunc = async () => {
    if (!isDefaultActive) return;
    setTestDefaultLogin((prev) => ({ ...prev, isTesting: true }));
    return new Promise((resolve) =>
      setTimeout(() => {
        setTestDefaultLogin({ isTesting: false, isValid: true });
        resolve(true);
      }, 1000)
    );
  };

  const testEntraIdLoginFunc = async () => {
    if (!isEntraIdActive) {
      return;
    }
    setTestEntraIdLogin((prev) => ({ ...prev, isTesting: true }));
    return new Promise((resolve) => handleEntraIdPopupLogin(resolve));
  };

  const testOktaLoginFunc = async () => {
    if (!isOktaActive) {
      return;
    }
    setTestOktaLogin((prev) => ({ ...prev, isTesting: true }));
    return new Promise((resolve) => handleOktaPopupLogin(resolve));
  };

  const getMsalConfig = () => {
    return {
      auth: {
        clientId: entraIdClientId,
        authority: `https://login.microsoftonline.com/${entraIdTenantId}`,
        redirectUri: entraIdRedirectUri,
      },
    };
  };

  const handleEntraIdPopupLogin = (resolve) => {
    const instance = new PublicClientApplication(getMsalConfig());

    instance
      .initialize()
      .then((res) =>
        instance
          .loginPopup({})
          .then((res) => {
            setTestEntraIdLogin({ isTesting: false, isValid: true });
            resolve(true);
          })
          .catch((err) => {
            setTestEntraIdLogin({
              isTesting: false,
              isValid: false,
              errorCause: err.message.includes("popup_window_error")
                ? "POPUP"
                : "DEFAULT",
            });
            resolve(false);
          })
      )
      .catch((err) => {
        setTestEntraIdLogin({
          isTesting: false,
          isValid: false,
          errorCause: "DEFAULT",
        });
        resolve(false);
      });
  };

  const handleOktaPopupLogin = (resolve) => {
    const oktaAuth = new OktaAuth({
      issuer: `https://${oktaDomain}/oauth2/default`,
      clientId: oktaClientId,
      redirectUri: oktaRedirectUri
    });

    isOktaAPITokenValid(dispatch, auth, oktaDomain, oktaApiToken)
      .then(res => {
        if (res) {
          oktaAuth.token.getWithPopup({ prompt: "login" })
            .then(() => {
              setTestOktaLogin({ isTesting: false, isValid: true });
              resolve(true);
            })
            .catch(err => {
              console.error(err);
              setTestOktaLogin({
                isTesting: false,
                isValid: false,
                errorCause: "CLIENTID",
              });
              resolve(false);
            });
        } else {
          setTestOktaLogin({
            isTesting: false,
            isValid: false,
            errorCause: "APITOKEN",
          });
          resolve(false);
        }
      });
  };

  const getEntraIDErrorMessage = () => {
    if (testEntraIdLogin.errorCause === "POPUP") {
      return (
        <>
          <hr />
          <h6>Unable to test the Microsoft Entra ID configurations</h6>
          <p>
            The popup used to validate the configurations for Microsoft Entra ID
            has been blocked by your browser. Authorize popup with your browser
            and try again. For now, we have reverted to the default DataIntell
            login system to ensure uninterrupted access.
          </p>
        </>
      );
    } else {
      return (
        <>
          <hr />
          <h6>Unable to connect to Microsoft Entra ID</h6>
          <p>
            We're having trouble connecting to Microsoft Entra ID. For now, we
            have reverted to the default DataIntell login system to ensure
            uninterrupted access. Please check your Microsoft Entra ID
            configuration settings.
          </p>
        </>
      );
    }
  };

  const getOktaErrorMessage = () => {
    if (testOktaLogin.errorCause === "APITOKEN") {
      return (
        <>
          <hr />
          <h6>Unable to connect to Okta</h6>
          <p>
            We're having trouble connecting to Okta with either your API Token or Domain.
            For now, we have reverted to the default DataIntell login system
            to ensure uninterrupted access.Please check your Okta configuration
            settings.
          </p >
        </>
      );
    } else {
      return (
        <>
          <hr />
          <h6>Unable to connect to Okta</h6>
          <p>
            We're having trouble connecting to Okta. It might be an issue with either your Client ID, Redirect URI
            or that your browser is blocking the popup. For now, we have reverted to the default DataIntell login
            system to ensure uninterrupted access. Please check your Okta configuration settings and make sure
            that your browser is allowing popups.
          </p>
        </>
      );
    }
  };

  return (
    <Modal size="md" toggle={() => setIsOpen(false)} isOpen={isOpen}>
      <ModalHeader toggle={() => setIsOpen(false)}>
        Updating Login Configuration
      </ModalHeader>
      <ModalBody>
        <LoginStatus
          isActive={isDefaultActiveOnLoad}
          isTesting={testDefaultLogin.isTesting}
          isValid={testDefaultLogin.isValid}
        >
          Default DataIntell Login
        </LoginStatus>
        <br />
        <LoginStatus
          isActive={isEntraIdActiveOnLoad}
          isTesting={testEntraIdLogin.isTesting}
          isValid={testEntraIdLogin.isValid}
        >
          Microsoft Entra ID Login
        </LoginStatus>
        <br />
        <LoginStatus
          isActive={isOktaActiveOnLoad}
          isTesting={testOktaLogin.isTesting}
          isValid={testOktaLogin.isValid}
        >
          Okta Login
        </LoginStatus>
        {testEntraIdLogin.isValid === false && getEntraIDErrorMessage()}
        {testOktaLogin.isValid === false && getOktaErrorMessage()}
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
