import { filesize } from "filesize";
import { useSelector } from "react-redux";

export const getSymbolFromBase = (symbol, base) => {
  if (symbol === "B") return symbol;
  return (base === "2" || base === 2) ? symbol.charAt(0) + "iB" : symbol;
};

export const convertSymbolToExponent = (symbol) => {
  switch (symbol) {
    case "B":
      return 0;
    case "KB":
    case "KiB":
    case "kB":
    case "kiB":
      return 1;
    case "MB":
    case "MiB":
      return 2;
    case "GB":
    case "GiB":
      return 3;
    case "TB":
    case "TiB":
      return 4;
    case "PB":
    case "PiB":
      return 5;
    case "EB":
    case "EiB":
      return 6;
    default:
      return -1;
  }
};

export const formatSize = (sizeInBytes, base, exponent, output) => {
  const value = base ? Number(base) :  Number(window.localStorage.getItem("sizeBase") ?? "2");

  return filesize(
    sizeInBytes, 
    { 
      base: value, 
      standard: value === 2 ? "iec" : "si",
      exponent: exponent ?? -1,
      output: output ?? "string"
    }
  );
};

export default function FormatSize(props) {
  const { children } = props;

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const isUnitKB = formatSize(children, baseSize, null, "object").unit === "kB";

  return (
    <span>{isUnitKB ? formatSize(children, baseSize).toUpperCase() : formatSize(children, baseSize)}</span>
  );
}