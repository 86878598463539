import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

export default function DocumentationButton(props) {
  const {
    id,
    url
  } = props;

  return (
    <>
      <a
        id={id}
        className="btn btn-outline-info btn-sm ms-2"
        href={url}
        target="_blank"
      >
        <FontAwesomeIcon icon={faBook} />
      </a>
      <UncontrolledTooltip target={id} placement="auto">
        Open documentation
      </UncontrolledTooltip>
    </>
  );
}