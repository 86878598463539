import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function PerformanceModeWarning(props) {
  const { configurations } = props;
  const navigate = useNavigate();
  return (
    <>
      {configurations.isPerformanceModeActive.value === "true" && (
        <div className="mb-3">
          <span style={{ color: "#eda003" }}>Warning: </span>
          <strong>
            Performance mode is active. Files will not be indexed while
            scanning.{" "}
          </strong>

          <a className="text-primary" onClick={() => navigate("/settings")}>
            Deactivate performance mode.
          </a>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { configurations } = state;
  return {
    configurations,
  };
}

export default connect(mapStateToProps)(PerformanceModeWarning);
