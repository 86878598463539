import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import ChartCard from "./ChartCard";

export default function ExtensionChart(props) {
  const {
    filters,
    search,
    addFilters,
    lastAnalyticsSearch,
    setRef = () => {},
    setSubtitle = () => {},
    setSizeItems = () => {},
    setSizeShownData = () => {},
    sizeOfFiles,
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [extensions, setExtensions] = useState([]);

  const dispatch = useDispatch();

  const accessToken = useSelector(({ auth }) => auth.accessToken);
  const activeDate = useSelector(({ dates }) => dates.activeDate);

  const fetchData = async () => {
    setIsFetching(true);
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName: "extension",
      size: 50,
      indexingDate: activeDate,
    };

    const extensions = await fetchWithAuthV2(dispatch, url, accessToken, {
      ...queryParams,
      ...searchParams,
    });
    const sortedBySizeExtensions = extensions.sort((a, b) => b.size - a.size);
    setExtensions(sortedBySizeExtensions);
    setIsFetching(false);
  };

  useEffect(() => {
    fetchData();
  }, [lastAnalyticsSearch]);

  const addExtensionFilter = (index) => {
    if (null !== index) {
      const fieldValue = extensions[index].field;
      if ("" === fieldValue) {
        addFilters(["ignorePatterns"], [[...filters.ignorePatterns, "*.*"]]);
        return;
      }
      const name = ["extension"];
      const value = [extensions[index].field];
      addFilters(name, value);
    }
  };

  return (
    <ChartCard
      setRef={setRef}
      setSubtitle={setSubtitle}
      setSizeItems={setSizeItems}
      setSizeShownData={setSizeShownData}
      title="Extensions"
      elements={extensions}
      sizeOfFiles={sizeOfFiles}
      emptyStringValue="No extension"
      addFilterFunc={addExtensionFilter}
      isFetching={isFetching}
      componentId="extensionChartId"
    />
  );
}
