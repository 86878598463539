/**
 * Function that takes in a string and validate whether it is a positive integer or not.
 * Use this function if you want to validate that a string is a true integer.
 * It fixes the issue where Number.isInteger() returns true when there is an integer followed by random strings 
 * and also the parseInt() function that remove the decimals, but does not allow validation.
 * 
 * @param {String} stringIntegerToValidate The string to validate if it is an integer
 */
export const validatePositiveStringInteger = (stringIntegerToValidate) => {
  const onlyNumbersRegex = /^[0-9]*$/;
  const foundIntegerInString = stringIntegerToValidate.match(onlyNumbersRegex);
  return foundIntegerInString && foundIntegerInString[0] !== "";
}

// This function will do the same as the one above with the exception that it will also validate that it is not 0
export const validateTruePositiveStringInteger = (stringIntegerToValidate) => {
  if (stringIntegerToValidate.length === 1 && stringIntegerToValidate[0] === "0") {
    return false;
  }

  return validatePositiveStringInteger(stringIntegerToValidate);
}