import { useState, useEffect } from "react";
import moment from "moment";
import TableResult from "../components/TableResult";
import TableTitleTooltip from "./TableTitleTooltip";
import { getMonthlyCostByVolume } from "../utils/pricePerVolume";
import TableFileName from "./TableFileName";
import TagCheckbox from "./tags/TagCheckbox";
import TagMultipleFilesButton from "./tags/TagMultipleFilesButton";
import TagMultipleFilesPopover from "./tags/TagMultipleFilesPopover";
import FileIcon from "./FileIcon";
import TableActions from "./TableActions";
import CheckboxButton from "./CheckboxButton";
import Loading from "./Loading";
import { returnNAOnNull } from "../utils/returnNAOnNull";
import ArchiveSelectionButton from "./ArchiveSelectionButton";
import ArchiveSelectionWizard from "./archiwareArchiveRestore/ArchiveSelectionWizard";
import HasBundle from "../validation/HasBundle";
import {
  ARCHIWARE_BUNDLE,
  ICONIK_BUNDLE,
  SODA_BUNDLE,
  STORAGE_MANAGER_BUNDLE,
} from "../constants/bundles";
import RestoreArchiveButton from "./RestoreArchiveButton";
import RestoreFromArchiveWizard from "./archiwareArchiveRestore/RestoreFromArchiveWizard";
import {
  ROLE_ARCHIWARE_ARCHIVE,
  ROLE_ARCHIWARE_RESTORE,
  ROLE_SODA_TRANSFER,
  ROLE_STORAGE_MANAGER_ARCHIVE,
  ROLE_STORAGE_MANAGER_RESTORE,
  ROLE_ICONIK_UPLOAD,
} from "../constants/roles";
import Authorization from "../validation/Authorization";
import SodaActionButton from "./SodaActionButton";
import SodaActionWizard from "./soda/SodaActionWizard";
import StorageManagerActionButton from "./storageManager/StorageManagerActionButton";
import StorageManagerActionWizard from "./storageManager/StorageManagerActionWizard";
import FormatSize from "./FormatSize";
import IconikActionButton from "./iconik/IconikActionButton";
import IconikActionWizard from "./iconik/IconikActionWizard";
import { smartTrim } from "../utils/formatString";
import { useSelector } from "react-redux";

export default function FileList(props) {
  const {
    files,
    page,
    total,
    size,
    changeOrder,
    sort,
    direction,
    searchSize,
    handleClick,
    selectPage,
    volumeSettings,
    updateFile,
    updateTags,
    setNumberOfElements,
    boldElements = {},
    isRedBackground = true,
    search = "",
    isLoading,
    isInModal = false
  } = props;

  const [targetTableRowIndex, setTargetTableRowIndex] = useState(null);
  const [isTagFileOpen, setIsTagFileOpen] = useState(false);
  const [isApplyTagMultipleFilesOpen, setIsApplyTagMultipleFilesOpen] =
    useState(false);
  const [isRemoveTagMultipleFilesOpen, setIsRemoveTagMultipleFilesOpen] =
    useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [useSearchResultForActions, setUseSearchResultForActions] =
    useState(false);
  const [isCurrentPageSelected, setIsCurrentPageSelected] = useState(false);
  const [showArchiveSelectionWizard, setShowArchiveSelectionWizard] =
    useState(false);
  const [showRestoreFromArchiveWizard, setShowRestoreFromArchiveWizard] =
    useState(false);
  const [showSodaActionWizard, setShowSodaActionWizard] = useState(false);
  const [showStorageManagerActionWizard, setShowStorageManagerActionWizard] =
    useState(false);
  const [showIconikActionWizard, setShowIconikActionWizard] = useState(false);

  useEffect(() => {
    if (files && files.length > 0) {
      const isCurrentPageFilesAllSelected = files.every((file) =>
        selectedFiles.some(
          (selectedFile) => file.fileId === selectedFile.fileId
        )
      );
      setIsCurrentPageSelected(isCurrentPageFilesAllSelected);
    }
  }, [files, selectedFiles]);

  useEffect(() => {
    refreshSelectedFiles();
  }, []);

  useEffect(() => {
    setUseSearchResultForActions(false);
  }, [total]);

  const isPathCondensed = useSelector(
    ({ userSettings }) => userSettings.isPathCondensed
  );

  const refreshSelectedFiles = () => {
    const refreshedSelectedFiles = [];
    selectedFiles.forEach((selectedFile) => {
      const refreshedFile = files.find(
        (file) => file.fileId === selectedFile.fileId
      );
      refreshedSelectedFiles.push(refreshedFile);
    });
    setSelectedFiles(refreshedSelectedFiles);
  };

  const formatDate = (value) => moment(value).format("lll");

  const formatFontWeight = (value) => (boldElements[value] ? "bold" : "normal");

  const updateSelectedFile = (file) => {
    const updatedSelectedFiles = selectedFiles.map((f) =>
      f.fileId === file.fileId ? file : f
    );
    setSelectedFiles(updatedSelectedFiles);
  };

  const listFormated =
    files &&
    files.map((file, index) => {
      const lastAccess = returnNAOnNull(file.lastAccess, formatDate);
      const lastModified = returnNAOnNull(file.lastModified, formatDate);
      const creationDate = returnNAOnNull(file.creationDate, formatDate);
      const indexingDate = returnNAOnNull(file.indexingDate, formatDate);

      const lineObject = {
        columnData: [
          <>
            <span style={{ whiteSpace: "nowrap" }}>
              <FileIcon extension={file.extension} />
              <TableFileName
                updateFile={updateFile}
                updateTags={updateTags}
                file={file}
                updateSelectedFile={(file) => updateSelectedFile(file)}
                showActions={updateFile && index === targetTableRowIndex}
                isTagFileOpen={isTagFileOpen}
                setIsTagFileOpen={setIsTagFileOpen}
                isFileNameBold={boldElements["name"]}
              />
            </span>
          </>,
          <span style={{ fontWeight: formatFontWeight("path") }}>
            {smartTrim(
              file.path,
              isPathCondensed === "true"
                ? Math.floor(window.innerWidth / 70)
                : 0
            )}
          </span>,
          <span style={{ fontWeight: formatFontWeight("volumeName") }}>
            {file.volumeName}
          </span>,
          <span style={{ fontWeight: formatFontWeight("size") }}>
            <FormatSize>{file.size}</FormatSize>
          </span>,
          <span style={{ fontWeight: formatFontWeight("owner") }}>
            {file.owner}
          </span>,
          <span style={{ fontWeight: formatFontWeight("group") }}>
            {file.group}
          </span>,
          <span style={{ fontWeight: formatFontWeight("creationDate") }}>
            {creationDate}
          </span>,
          <span style={{ fontWeight: formatFontWeight("lastAccess") }}>
            {lastAccess}
          </span>,
          <span style={{ fontWeight: formatFontWeight("lastModified") }}>
            {lastModified}
          </span>,
          <span style={{ fontWeight: formatFontWeight("indexingDate") }}>
            {indexingDate}
          </span>,
          getMonthlyCostByVolume(
            volumeSettings.items,
            file.volumeId,
            file.size
          ),
          file.dupe ? "Yes" : "No",
        ],
        style: {},
        options: {
          actionColumn: true,
        },
      };

      if (updateFile) {
        lineObject.columnData.unshift(
          <TagCheckbox
            file={file}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            selectAllDisable={useSearchResultForActions}
          />
        );
      }

      if (handleClick) {
        lineObject.options.linkData = file;
      }

      if (isRedBackground && file.deleted) {
        lineObject.style.backgroundColor = "#f17a7a";
      }
      return lineObject;
    });

  const isDuplicateTitleName = (
    <>
      Is Duplicate{" "}
      <TableTitleTooltip target="fileDupeInfo">
        Duplicate files On-Premises Storage are files with the same name, size
        and last modified date. Duplicate files on cloud storage are files with
        the same eTag. The eTag is created by hashing the file with MD5.
      </TableTitleTooltip>
    </>
  );

  const fileTitles = [
    { name: "File Name", sort: "name" },
    { name: "Path", sort: "path" },
    { name: "Volume Name", sort: "volumeName" },
    { name: "Size", sort: "size" },
    { name: "Owner", sort: "owner" },
    { name: "Group", sort: "group" },
    { name: "Creation Date", sort: "creationDate" },
    {
      name: "Last Access Date",
      sort: "lastAccess",
    },
    {
      name: "Last Modified Date",
      sort: "lastModified",
    },
    { name: "Indexing Date", sort: "indexingDate" },
    { name: "Monthly Cost" },
    { name: isDuplicateTitleName },
  ];

  if (updateFile) {
    fileTitles.unshift({
      name: "",
    });
  }

  const selectAllFilesFromCurrentPage = () => {
    const filesToAdd = files.filter(
      (file) =>
        !selectedFiles.some(
          (selectedFile) => selectedFile.fileId === file.fileId
        )
    );
    return [...selectedFiles, ...filesToAdd];
  };

  const getTableActions = () => {
    return (
      <>
        <CheckboxButton
          handleSelectNone={() => {
            setSelectedFiles([]);
            setUseSearchResultForActions(false);
          }}
          handleSelectCurrentPage={() => {
            setSelectedFiles(selectAllFilesFromCurrentPage());
            setUseSearchResultForActions(false);
          }}
          handleSelectAll={() => {
            setSelectedFiles([]);
            setUseSearchResultForActions(true);
          }}
          isSelectAllAvailable={!!searchSize}
          isCurrentPageSelected={isCurrentPageSelected}
          isSelectAllSelected={useSearchResultForActions}
        />
        {updateFile &&
          (useSearchResultForActions || selectedFiles.length > 0) && (
            <>
              <div className="ms-2">
                <TagMultipleFilesButton
                  id="applyTagMultipleFiles"
                  isMultipleTagsOpen={isApplyTagMultipleFilesOpen}
                  deleteTag={false}
                />
                <TagMultipleFilesPopover
                  target="applyTagMultipleFiles"
                  isTagMultipleFilesOpen={isApplyTagMultipleFilesOpen}
                  setIsTagMultipleFilesOpen={setIsApplyTagMultipleFilesOpen}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  updateFile={updateFile}
                  updateTags={(tags) => updateTags(tags)}
                  useSearchResult={useSearchResultForActions}
                  search={search}
                  isDirectory={false}
                  deleteTag={false}
                />
              </div>
              <div className="ms-2">
                <TagMultipleFilesButton
                  id="removeTagMultipleFiles"
                  isMultipleTagsOpen={isRemoveTagMultipleFilesOpen}
                  deleteTag
                />
                <TagMultipleFilesPopover
                  target="removeTagMultipleFiles"
                  isTagMultipleFilesOpen={isRemoveTagMultipleFilesOpen}
                  setIsTagMultipleFilesOpen={setIsRemoveTagMultipleFilesOpen}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  updateFile={updateFile}
                  updateTags={(tags) => updateTags(tags)}
                  useSearchResult={useSearchResultForActions}
                  search={search}
                  isDirectory={false}
                  deleteTag
                />
              </div>
              <HasBundle requiredBundle={ARCHIWARE_BUNDLE} hasOverlay={false}>
                <Authorization
                  requiredAuthorizations={[ROLE_ARCHIWARE_ARCHIVE]}
                >
                  <div className="ms-2">
                    <ArchiveSelectionButton
                      onClickFunction={() =>
                        setShowArchiveSelectionWizard(true)
                      }
                    />
                    <ArchiveSelectionWizard
                      showWizard={showArchiveSelectionWizard}
                      setShowWizard={setShowArchiveSelectionWizard}
                      selectedFiles={selectedFiles}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      total={total}
                    />
                  </div>
                </Authorization>
                <Authorization
                  requiredAuthorizations={[ROLE_ARCHIWARE_RESTORE]}
                >
                  <div className="ms-2">
                    <RestoreArchiveButton
                      onClickFunction={() =>
                        setShowRestoreFromArchiveWizard(true)
                      }
                    />
                    <RestoreFromArchiveWizard
                      showWizard={showRestoreFromArchiveWizard}
                      setShowWizard={setShowRestoreFromArchiveWizard}
                      selectedFiles={selectedFiles}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      total={total}
                    />
                  </div>
                </Authorization>
              </HasBundle>
              <HasBundle requiredBundle={SODA_BUNDLE} hasOverlay={false}>
                <Authorization requiredAuthorizations={[ROLE_SODA_TRANSFER]}>
                  <div className="ms-2">
                    <div style={{ marginLeft: "4px" }}>
                      <SodaActionButton
                        onClickFunction={() => setShowSodaActionWizard(true)}
                      />
                    </div>
                    <SodaActionWizard
                      showWizard={showSodaActionWizard}
                      setShowWizard={setShowSodaActionWizard}
                      selectedFiles={selectedFiles}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      isDirectory={false}
                    />
                  </div>
                </Authorization>
              </HasBundle>
              <HasBundle
                requiredBundle={STORAGE_MANAGER_BUNDLE}
                hasOverlay={false}
              >
                <Authorization
                  requiredAuthorizations={[
                    ROLE_STORAGE_MANAGER_ARCHIVE,
                    ROLE_STORAGE_MANAGER_RESTORE,
                  ]}
                >
                  <div className="ms-2">
                    <div style={{ marginLeft: "4px" }}>
                      <StorageManagerActionButton
                        onClickFunction={() =>
                          setShowStorageManagerActionWizard(true)
                        }
                      />
                    </div>
                    <StorageManagerActionWizard
                      showWizard={showStorageManagerActionWizard}
                      setShowWizard={setShowStorageManagerActionWizard}
                      selectedFiles={selectedFiles}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      isDirectory={false}
                    />
                  </div>
                </Authorization>
              </HasBundle>
              <HasBundle requiredBundle={ICONIK_BUNDLE} hasOverlay={false}>
                <Authorization requiredAuthorizations={[ROLE_ICONIK_UPLOAD]}>
                  <div className="ms-2">
                    <div style={{ marginLeft: "4px" }}>
                      <IconikActionButton
                        onClickFunction={() => {
                          setShowIconikActionWizard(true);
                        }}
                      />
                    </div>
                    <IconikActionWizard
                      showWizard={showIconikActionWizard}
                      setShowWizard={setShowIconikActionWizard}
                      selectedFiles={selectedFiles}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      isDirectory={false}
                    />
                  </div>
                </Authorization>
              </HasBundle>
            </>
          )}
      </>
    );
  };

  return (
    <>
      {isLoading || !files ? (
        <Loading />
      ) : files && files.length > 0 ? (
        <div>
          <TableActions
            page={page}
            numberOfPages={Math.ceil(total / size)}
            selectPage={selectPage}
            isFile
            showBadge
            total={total}
            searchSize={searchSize}
            selectedFiles={selectedFiles}
            useSearchResultForActions={useSearchResultForActions}
            isInModal={isInModal}
            numberOfElements={size}
            setNumberOfElements={setNumberOfElements}
          >
            {getTableActions()}
          </TableActions>
          <TableResult
            handleClick={handleClick}
            title={"Files"}
            data={listFormated}
            titles={fileTitles}
            sort={sort}
            direction={direction}
            changeOrder={changeOrder}
            setTargetTableRowIndex={(index) => {
              if (!isTagFileOpen) {
                setTargetTableRowIndex(index);
              }
            }}
            hasSizeBaseButton
            hasCondenseButton
          />
        </div>
      ) : (
        <div>No files found</div>
      )}
    </>
  );
}
