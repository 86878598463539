import { Badge, Col, FormGroup, Input, Label } from "reactstrap";
import FolderTree from "../../components/folderTree/FolderTree";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { getAllDataSourceTypes } from "../../api/report";
import { setAlert } from "../../actions/alert";
import { formatName } from "../../utils/formatString";
import { getVolumeIcon } from "../../utils/getVolumeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";

function ComparedValues(props) {
  const { values, setReportTemplateObj, setSelectedFolders, selectedFolders } =
    props;
  const volumes = useSelector(({ volumes }) => volumes.items);

  const removeDatasource = (value, isReference) => {
    const updatedSelectedFolders = [...selectedFolders];
    const folderIndex = updatedSelectedFolders.findIndex(
      (selectedFolder) =>
        selectedFolder.type === value.type &&
        (selectedFolder.volumeId === value.id ||
          selectedFolder.volumeSetting?.id === value.id) &&
        (selectedFolder.path === value.path ||
          (selectedFolder.path === "" && value.path === "/"))
    );

    if (folderIndex !== -1) {
      updatedSelectedFolders.splice(folderIndex, 1);
      setSelectedFolders(updatedSelectedFolders);
    }

    setReportTemplateObj((prev) => ({
      ...prev,
      parameters: {
        ...prev.parameters,
        references: isReference
          ? prev.parameters.references.filter(
              (f) =>
                f.type !== value.type ||
                f.id !== value.id ||
                f.path !== value.path
            )
          : prev.parameters.references,
        comparison: isReference
          ? prev.parameters.comparison
          : prev.parameters.comparison.filter(
              (f) =>
                f.type !== value.type ||
                f.id !== value.id ||
                f.path !== value.path
            ),
      },
    }));
  };

  const getBadge = (value, isReference) => {
    const volumeType = volumes.find((volume) => volume.id === value.id)?.type;

    return (
      <Badge
        key={`${value.volumeName}-${value.path}`}
        className="me-1"
        color={value.volumeName ? "secondary" : "danger"}
      >
        <FontAwesomeIcon
          className="me-1"
          icon={value.type === "VOLUME" ? getVolumeIcon(volumeType) : faSitemap}
        />
        {value.volumeName} {value.type === "VOLUME" && `: ${value.path}`}
        {!value.volumeName && (
          <Badge
            style={{ cursor: "pointer", marginLeft: 10 }}
            className="text-dark"
            color="light"
            onClick={() => {
              removeDatasource(value, isReference);
            }}
          >
            X
          </Badge>
        )}
      </Badge>
    );
  };

  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#f5f5f5",
        borderRadius: "5px",
        marginBottom: "10px",
        maxHeight: "250px",
        overflowY: "auto",
      }}
    >
      <span>List of selected references data:</span>
      <br />
      {values.references
        ? values.references.map((value) => getBadge(value, true))
        : "None"}
      <br />
      <br />
      <span>List of selected comparison data:</span>
      <br />
      {values.comparison
        ? values.comparison.map((value) => getBadge(value, false))
        : "None"}
    </div>
  );
}

export default function DataSource(props) {
  const {
    isReferences,
    reportTemplateObj,
    setReportTemplateObj,
    setDisableForwardActionButton,
  } = props;

  const [dataSourceType, setDataSourceType] = useState("");
  const [dataSourceTypeList, setDataSourceTypeList] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [isSettingUp, setIsSettingUp] = useState(false);

  const volumes = useSelector(({ volumes }) => volumes.items);
  const projects = useSelector(({ projectList }) => projectList.items);
  const auth = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isReferences) {
      setSelectedFolders(
        reportTemplateObj.parameters.references.map((ref) => ({
          volumeSetting: { id: ref.id },
          path: ref.path === "/" ? "" : ref.path,
          type: ref.type,
        }))
      );
    } else {
      setSelectedFolders(
        reportTemplateObj.parameters.comparison.map((ref) => ({
          volumeSetting: { id: ref.id },
          path: ref.path === "/" ? "" : ref.path,
          type: ref.type,
        }))
      );
    }

    getAllDataSourceTypes(dispatch, auth)
      .then((res) => setDataSourceTypeList(res))
      .catch((err) => {
        console.error(err);
        dispatch(
          setAlert(
            "Unable to get the list of data source types.",
            "danger",
            8000
          )
        );
      });

    // To make sure that the selectedFolders are prepared before rendering the FolderTree component
    setTimeout(() => setIsSettingUp(false), 100);
  }, []);

  useEffect(() => {
    setDisableForwardActionButton(selectedFolders.length === 0);
  }, [selectedFolders.length]);

  const selectFolderFunc = (folder, isVolume) => {
    const updatedSelectedFolders = [...selectedFolders];

    const folderIndex = updatedSelectedFolders.findIndex(
      (selectedFolder) =>
        selectedFolder.type === (isVolume ? "VOLUME" : "PROJECT") &&
        (selectedFolder.volumeId === folder.volumeId ||
          selectedFolder.volumeSetting?.id === folder.volumeId) &&
        (selectedFolder.path === folder.path ||
          (selectedFolder.path === "" && folder.path === "/"))
    );

    if (folderIndex !== -1) {
      updatedSelectedFolders.splice(folderIndex, 1);
      setReportTemplateObj((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          references: isReferences
            ? prev.parameters.references.filter(
                (f) =>
                  f.type !== (isVolume ? "VOLUME" : "PROJECT") ||
                  f.id !== folder.volumeId ||
                  f.path !== folder.path
              )
            : prev.parameters.references,
          comparison: isReferences
            ? prev.parameters.comparison
            : prev.parameters.comparison.filter(
                (f) =>
                  f.type !== (isVolume ? "VOLUME" : "PROJECT") ||
                  f.id !== folder.volumeId ||
                  f.path !== folder.path
              ),
        },
      }));
    } else {
      updatedSelectedFolders.push({
        volumeSetting: { id: folder.volumeId },
        path: folder.path === "/" ? "" : folder.path,
        type: isVolume ? "VOLUME" : "PROJECT",
      });

      setReportTemplateObj((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          references: isReferences
            ? [
                ...prev.parameters.references,
                {
                  id: folder.volumeId,
                  type: isVolume ? "VOLUME" : "PROJECT",
                  path: folder.path,
                  volumeName: folder.volumeName,
                },
              ]
            : prev.parameters.references,
          comparison: isReferences
            ? prev.parameters.comparison
            : [
                ...prev.parameters.comparison,
                {
                  id: folder.volumeId,
                  type: isVolume ? "VOLUME" : "PROJECT",
                  path: folder.path,
                  volumeName: folder.volumeName,
                },
              ],
        },
      }));
    }
    setSelectedFolders(updatedSelectedFolders);
  };

  return (
    <>
      <ComparedValues
        setReportTemplateObj={setReportTemplateObj}
        setSelectedFolders={setSelectedFolders}
        selectedFolders={selectedFolders}
        values={reportTemplateObj.parameters}
      />
      <FormGroup row>
        <Label for="dataSource" sm={3}>
          Data Source
        </Label>
        <Col sm={9}>
          <Input
            id="dataSource"
            name="dataSource"
            type="select"
            value={dataSourceType}
            onChange={(e) => setDataSourceType(e.target.value)}
          >
            <option value="">Select Data Source Type</option>
            {dataSourceTypeList.map((dataSource, index) => {
              return (
                <option value={dataSource} key={`${dataSource}-${index}`}>
                  {dataSource === "VOLUME" ? "Storage" : formatName(dataSource)}
                </option>
              );
            })}
          </Input>
        </Col>
      </FormGroup>
      {isSettingUp ? (
        <Loading />
      ) : (
        <>
          {dataSourceType === "VOLUME" && (
            <FolderTree
              selectedFolders={selectedFolders.filter(
                (folder) => folder.type === "VOLUME"
              )}
              volumes={volumes.filter((volume) => volume.sizeOfFiles !== null)}
              volumeSelector
              hasFiles={false}
              hasRowBackground={false}
              isDefaultOpen={false}
              hasActions={false}
              onCheckboxClick={(e) => selectFolderFunc(e, true)}
              isSortable={false}
              defaultSort="name"
              defaultDirection="asc"
              hideLastColumn
            />
          )}
          {dataSourceType === "PROJECT" && (
            <FolderTree
              selectedFolders={selectedFolders.filter(
                (folder) => folder.type === "PROJECT"
              )}
              volumes={projects.map((project) => ({
                ...project,
                description: project.name,
                volumeType: "PROJECT",
              }))}
              volumeSelector
              isProject
              hasFiles={false}
              hasRowBackground={false}
              isDefaultOpen={false}
              hasActions={false}
              showOnlyFirstColumn
              hasHeader={false}
              onNameClickFolder={() => {}}
              isOpenable={false}
              onCheckboxClick={(e) => selectFolderFunc(e, false)}
            />
          )}
        </>
      )}
    </>
  );
}
