import {
  fetchWithAuth,
  downloadFileWithAuth,
  fetchWithAuthV2,
} from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import xbytes from "xbytes";
import { SET_DATE } from "../actions";
import { REPORT_HASH } from "../reports/Report";

// === Actions ===
const REQUEST_SEARCH_FILES = "REQUEST_SEARCH_FILES";
const RECEIVE_SEARCH_FILES = "RECEIVE_SEARCH_FILES";

const REQUEST_SEARCH_FOLDERS = "REQUEST_SEARCH_FOLDERS";
const RECEIVE_SEARCH_FOLDERS = "RECEIVE_SEARCH_FOLDERS";

const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
const CLEAR_SEARCH_FILTER_VALUES = "CLEAR_SEARCH_FILTER_VALUES";
const SET_SEARCH_FILTER_VALUE = "SET_SEARCH_FILTER_VALUE";

const REQUEST_FIELD_STATS_BASED_ON_SEARCH =
  "REQUEST_FIELD_STATS_BASED_ON_SEARCH";
const RECEIVE_FIELD_STATS_BASED_ON_SEARCH =
  "RECEIVE_FIELD_STATS_BASED_ON_SEARCH";

const REQUEST_SEARCH_SIZE = "REQUEST_SEARCH_SIZE";
const RECEIVE_SEARCH_SIZE = "RECEIVE_SEARCH_SIZE";

const REQUEST_CSV_FILE = "REQUEST_CSV_FILE";
const RECEIVE_CSV_FILE = "RECEIVE_CSV_FILE";
const INVALID_CSV_FILE = "INVALID_CSV_FILE";

export const REQUEST_UPDATE_SEARCH_FILE = "REQUEST_UPDATE_SEARCH_FILE";
export const RECEIVE_UPDATE_SEARCH_FILE = "RECEIVE_UPDATE_SEARCH_FILE";

export const REQUEST_UPDATE_SEARCH_FOLDER = "REQUEST_UPDATE_SEARCH_FOLDER";
export const RECEIVE_UPDATE_SEARCH_FOLDER = "RECEIVE_UPDATE_SEARCH_FOLDER";

// === Reducers ===
function mapStatisticsForDuplicates(statistics) {
  statistics.forEach((statistic) => {
    if (statistic.field === "1") {
      statistic.field = "Duplicate data";
    } else {
      statistic.field = "Unique data";
    }
  });

  return statistics;
}

const getUpdatedList = (list, file) => {
  return list.map((f) => (f.fileId === file.fileId ? file : f));
};

export default function search(
  state = {
    isFetching: false,
    didInvalidate: false,

    searchSize: 0,
    filters: {
      startingPath: "",
      lastAccessAfter: "",
      lastAccessBefore: "",
      owner: "",
      extension: "",
      volumeId: "",
      lastModifiedAfter: "",
      lastModifiedBefore: "",
      group: "",
      onlyDuplicates: "",
      showOnlyDeletedFiles: "",
      name: "",
    },
    creationDate: {
      isFetching: false,
      items: [],
    },
    lastAccess: {
      isFetching: false,
      items: [],
    },
    lastModified: {
      isFetching: false,
      items: [],
    },
    extension: {
      isFetching: false,
      items: [],
    },
    volumeName: {
      isFetching: false,
      items: [],
    },
    group: {
      isFetching: false,
      items: [],
    },
    owner: {
      isFetching: false,
      items: [],
    },
    isDupe: {
      isFetching: false,
      items: [],
    },
    csvFile: {
      isFetching: false,
    },
    csvFolder: {
      isFetching: false,
    },
    sort: "size",
    direction: "desc",
    folders: {
      items: [],
      sort: "size",
      direction: "desc",
      page: 1,
      total: 0,
      size: 0,
      isFetching: false,
    },
    prevFilters: null,
  },
  action
) {
  switch (action.type) {
    case REQUEST_SEARCH_FILES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_SEARCH_FILES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.files.elements,
        lastUpdated: action.receivedAt,
        page: action.files.page,
        total: action.files.total,
        size: action.files.size,
        sort: action.files.sort,
        direction: action.files.direction,
      });
    case REQUEST_SEARCH_FOLDERS:
      return Object.assign({}, state, {
        folders: {
          ...state.folders,
          isFetching: true,
        },
      });
    case RECEIVE_SEARCH_FOLDERS:
      return Object.assign({}, state, {
        folders: {
          ...state.folders,
          isFetching: false,
          items: action.folders.elements,
          page: action.folders.page,
          total: action.folders.total,
          size: action.folders.size,
          sort: action.folders.sort,
          direction: action.folders.direction,
        },
      });
    case SET_SEARCH_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.filters),
      });
    case CLEAR_SEARCH_FILTER_VALUES:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          tags: [],
          reports: [],
          maximumSize: "",
          maximumSizeUnit: "B",
          minimumSize: "",
          minimumSizeUnit: "B",
          creationAfter: "",
          creationBefore: "",
          extension: "",
          startingPath: "",
          lastAccessAfter: "",
          lastAccessBefore: "",
          owner: "",
          extension: "",
          volumeId: "",
          volumeIds: "",
          lastModifiedAfter: "",
          lastModifiedBefore: "",
          group: "",
          onlyDuplicates: "",
          showOnlyDeletedFiles: "",
          ignorePatterns: [""],
          volumesAndPaths: [""],
          name: "",
          minimumNumberOfFiles: "",
          maximumNumberOfFiles: "",
          minimumNumberOfFolders: "",
          maximumNumberOfFolders: "",
        }),
      });
    case SET_SEARCH_FILTER_VALUE:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          [action.name]: action.value,
        }),
      });
    case REQUEST_FIELD_STATS_BASED_ON_SEARCH:
      return Object.assign({}, state, {
        [action.fieldName]: {
          isFetching: true,
          items: [],
        },
      });
    case RECEIVE_SEARCH_SIZE:
      return Object.assign({}, state, {
        searchSize: action.size,
      });
    case RECEIVE_FIELD_STATS_BASED_ON_SEARCH:
      if (action.fieldName === "isDupe") {
        return Object.assign({}, state, {
          [action.fieldName]: {
            isFetching: false,
            items: mapStatisticsForDuplicates(action.statistics),
          },
        });
      } else {
        return Object.assign({}, state, {
          [action.fieldName]: {
            isFetching: false,
            items: action.statistics,
          },
        });
      }
    case REQUEST_CSV_FILE:
      return Object.assign({}, state, {
        [action.csvType]: {
          isFetching: true,
        },
      });
    case RECEIVE_CSV_FILE:
    case INVALID_CSV_FILE:
      return Object.assign({}, state, {
        [action.csvType]: {
          isFetching: false,
        },
      });
    case REQUEST_UPDATE_SEARCH_FILE:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_UPDATE_SEARCH_FILE:
      return Object.assign({}, state, {
        isFetching: false,
        items: getUpdatedList(state.items, action.file),
      });
    case REQUEST_UPDATE_SEARCH_FOLDER:
      return Object.assign({}, state, {
        folders: { ...state.folders, isFetching: true },
      });
    case RECEIVE_UPDATE_SEARCH_FOLDER:
      return Object.assign({}, state, {
        folders: {
          ...state.folders,
          isFetching: false,
          items: getUpdatedList(state.folders.items, action.folder),
        },
      });
    case SET_DATE:
      return Object.assign({}, state, {
        prevFilters: { ...state.filters },
      });
    default:
      return Object.assign({}, state, {
        prevFilters: null,
      });
  }
}

// === Action Creators ===
export function setSearchFilters(filters) {
  return {
    type: SET_SEARCH_FILTERS,
    filters,
  };
}

export function clearSearchFilterValues() {
  return {
    type: CLEAR_SEARCH_FILTER_VALUES,
  };
}

export function setSearchFilterValue(name, value) {
  return {
    type: SET_SEARCH_FILTER_VALUE,
    name,
    value,
  };
}

function requestSearchSize() {
  return {
    type: REQUEST_SEARCH_SIZE,
  };
}

function receiveSearchSize(size) {
  return {
    type: RECEIVE_SEARCH_SIZE,
    size,
  };
}

function requestSearchFiles(search) {
  return {
    type: REQUEST_SEARCH_FILES,
    search,
  };
}

function receiveSearchFiles(search, json) {
  return {
    type: RECEIVE_SEARCH_FILES,
    files: json,
    search,
  };
}

function requestSearchFolders(search) {
  return {
    type: REQUEST_SEARCH_FOLDERS,
    search,
  };
}

function receiveSearchFolders(search, json) {
  return {
    type: RECEIVE_SEARCH_FOLDERS,
    folders: json,
    search,
  };
}

function requestFieldStatsBasedOnSearch(fieldName) {
  return {
    type: REQUEST_FIELD_STATS_BASED_ON_SEARCH,
    fieldName,
  };
}

function receiveFieldStatsBasedOnSearch(fieldName, statistics) {
  return {
    type: RECEIVE_FIELD_STATS_BASED_ON_SEARCH,
    fieldName,
    statistics,
  };
}

function requestCsvFile(csvType) {
  return {
    type: REQUEST_CSV_FILE,
    csvType,
  };
}

function receiveCsvFile(csvType) {
  return {
    type: RECEIVE_CSV_FILE,
    csvType,
  };
}

function invalidCsvFile(csvType) {
  return {
    type: INVALID_CSV_FILE,
    csvType,
  };
}

function requestUpdateSearchFile() {
  return {
    type: REQUEST_UPDATE_SEARCH_FILE,
  };
}

function receiveUpdateSearchFile(json) {
  return {
    type: RECEIVE_UPDATE_SEARCH_FILE,
    file: json,
  };
}

function requestUpdateSearchFolder() {
  return {
    type: REQUEST_UPDATE_SEARCH_FOLDER,
  };
}

function receiveUpdateSearchFolder(json) {
  return {
    type: RECEIVE_UPDATE_SEARCH_FOLDER,
    folder: json,
  };
}

// === Side Effects ===
export function searchFiles(
  search,
  page = 1,
  size = 25,
  filters = {},
  sort = "size",
  direction = "desc"
) {
  return (dispatch, getState) => {
    dispatch(requestSearchFiles(search));
    const url = "/files/search";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      page,
      size,
      sort: `${sort},${direction}`,
      indexingDate: getState().dates.activeDate,
    };

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, {
      ...queryParams,
      ...searchParams,
    })
      .then((json) => {
        dispatch(receiveSearchFiles(search, json));
      })
      .catch((err) => {
        let message = err.statusText || "Unable to search the files";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function searchFolders(
  search,
  page = 1,
  size = 25,
  filters = {},
  sort = "size",
  direction = "desc"
) {
  return (dispatch, getState) => {
    dispatch(requestSearchFolders(search));
    const url = "/directories/search";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      page,
      size,
      sort: `${sort},${direction}`,
      indexingDate: getState().dates.activeDate,
    };

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, {
      ...queryParams,
      ...searchParams,
    })
      .then((json) => {
        dispatch(receiveSearchFolders(search, json));
      })
      .catch((err) => {
        let message = err.statusText || "Unable to search the folders";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function searchSize(search, filters = {}) {
  return (dispatch, getState) => {
    dispatch(requestSearchSize());
    const url = "/files/search/sizes";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      indexingDate: getState().dates.activeDate,
    };

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, {
      ...queryParams,
      ...searchParams,
    })
      .then((json) => {
        dispatch(receiveSearchSize(json));
      })
      .catch((err) => {
        let message = err.statusText || "Unable to get the size of the search";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function getFieldStatsBasedOnSearch(
  search,
  fieldName = "volumeName",
  filters = {}
) {
  return (dispatch, getState) => {
    dispatch(requestFieldStatsBasedOnSearch(fieldName));
    const url = "/files/search/fields";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
      search,
      fieldName,
      indexingDate: getState().dates.activeDate,
    };

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, {
      ...queryParams,
      ...searchParams,
    })
      .then((json) => {
        dispatch(receiveFieldStatsBasedOnSearch(fieldName, json));
      })
      .catch((err) => {
        let message =
          err.statusText || `Unable to get the ${fieldName} statistics`;
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function downloadCsv(
  search,
  page = 1,
  size = 25,
  filters = {},
  sort = "size",
  direction = "desc",
  numberOfFiles
) {
  return (dispatch, getState) => {
    dispatch(requestCsvFile("csvFile"));
    dispatch(getRequestCsvFileAlertMessage(numberOfFiles));
    const params = getSearchRequestParams(filters, false);
    const url = `/files/search/csv?search=${search}&page=${page}&size=${size}&sort=${sort},${direction}&indexingDate=${
      getState().dates.activeDate
    }${params}`;
    const d = new Date();
    const today = d.toLocaleDateString("en-CA");
    return downloadFileWithAuth(
      dispatch,
      url,
      `searchFileResult-${today}.csv`,
      getState().auth.accessToken
    )
      .then((json) => {
        dispatch(receiveCsvFile("csvFile"));
        dispatch(setAlert("CSV file downloaded successfully", "success"));
      })
      .catch((err) => {
        dispatch(invalidCsvFile("csvFile"));
        const message = err.message || "Unable to download csv file";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function downloadCsvForFolder(
  search,
  page = 1,
  size = 25,
  filters = {},
  sort = "size",
  direction = "desc",
  numberOfFiles
) {
  return (dispatch, getState) => {
    dispatch(requestCsvFile("csvFolder"));
    dispatch(getRequestCsvFileAlertMessage(numberOfFiles));
    const params = getSearchRequestParams(filters, false);
    const url = `/files/search/folders/csv?search=${search}&page=${page}&size=${size}&sort=${sort},${direction}&indexingDate=${
      getState().dates.activeDate
    }${params}`;
    const d = new Date();
    const today = d.toLocaleDateString("en-CA");
    return downloadFileWithAuth(
      dispatch,
      url,
      `searchFolderResult-${today}.csv`,
      getState().auth.accessToken
    )
      .then((json) => {
        dispatch(receiveCsvFile("csvFolder"));
        dispatch(setAlert("CSV file downloaded successfully", "success"));
      })
      .catch((err) => {
        dispatch(invalidCsvFile("csvFolder"));
        const message = err.message || "Unable to download csv file";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function updateSearchFile(id, file) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateSearchFile());
    const url = `/files/${id}?indexingDate=${getState().dates.activeDate}`;
    try {
      await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(file)
      );
      dispatch(receiveUpdateSearchFile(file));
    } catch (err) {
      let message = "Unable to update file";

      if (err.message) {
        if (err.message.includes("index write")) {
          message =
            "Unable to update a file from a previous snapshot as it is read only";
        } else {
          message = err.message;
        }
      }

      dispatch(setAlert(message, "danger"));
    }
  };
}

export function updateSearchFolder(id, folder) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateSearchFolder());
    const url = `/files/${id}?indexingDate=${getState().dates.activeDate}`;
    try {
      await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(folder)
      );
      dispatch(receiveUpdateSearchFolder(folder));
    } catch (err) {
      let message = "Unable to update folder";

      if (err.message) {
        if (err.message.includes("index write")) {
          message =
            "Unable to update a folder from a previous snapshot as it is read only";
        } else {
          message = err.message;
        }
      }

      dispatch(setAlert(message, "danger"));
    }
  };
}

function getRequestCsvFileAlertMessage(numberOfFiles) {
  if (numberOfFiles > 1000000) {
    return setAlert(
      "Only the first 1,000,000 items will be in the file. The request was received from the server, It could take a couple of minutes to get the file.",
      "warning",
      5000
    );
  }
  return setAlert(
    "The request was received from the server, It could take a couple of minutes to get the file.",
    "success",
    5000
  );
}

/**
 * @deprecated since version 1.11.0
 */
export function getSearchRequestParams(filters, isShare) {
  let params = "";

  if (filters.onlyDuplicates) {
    params += `&onlyDuplicates=${filters.onlyDuplicates}`;
  }
  if (filters.volumeIds) {
    filters.volumeIds.forEach((volumeId) => {
      params += `&volumeId=${volumeId}`;
    });
  }
  if (filters.startingPath) {
    params += `&startingPath=${filters.startingPath}`;
  }
  if (filters.owner) {
    params += `&owner=${filters.owner}`;
  }
  if (filters.group) {
    params += `&group=${filters.group}`;
  }
  if (filters.lastAccessAfter) {
    params += `&lastAccessAfter=${filters.lastAccessAfter}`;
  }
  if (filters.lastAccessBefore) {
    params += `&lastAccessBefore=${filters.lastAccessBefore}`;
  }
  if (filters.creationAfter) {
    params += `&creationAfter=${filters.creationAfter}`;
  }
  if (filters.creationBefore) {
    params += `&creationBefore=${filters.creationBefore}`;
  }
  if (filters.lastModifiedAfter) {
    params += `&lastModifiedAfter=${filters.lastModifiedAfter}`;
  }
  if (filters.lastModifiedBefore) {
    params += `&lastModifiedBefore=${filters.lastModifiedBefore}`;
  }
  if (filters.minimumSize) {
    const minimumByteSize = xbytes.parseSize(
      `${filters.minimumSize} ${filters.minimumSizeUnit}`
    );
    params += `&minimumSize=${minimumByteSize}`;
  }
  if (filters.maximumSize) {
    const maximumByteSize = xbytes.parseSize(
      `${filters.maximumSize} ${filters.maximumSizeUnit}`
    );
    params += `&maximumSize=${maximumByteSize}`;
  }
  if (filters.extension) {
    const splittedExtensions = filters.extension.split(",");
    splittedExtensions.forEach((extension) => {
      params += `&extension=${extension.replace(/\./g, "").trim()}`;
    });
  }
  if (filters.showOnlyDeletedFiles) {
    params += `&showOnlyDeletedFiles=${filters.showOnlyDeletedFiles}`;
  }
  if (filters.volumesAndPaths) {
    filters.volumesAndPaths.forEach((volumeAndPath) => {
      params += `&volumeAndPath=${volumeAndPath}`;
    });
  }
  if (filters.ignorePatterns) {
    filters.ignorePatterns.forEach((ignorePattern) => {
      params += `&ignorePattern=${ignorePattern}`;
    });
  }
  if (filters.tags) {
    filters.tags.forEach((tag) => {
      params += `&tag=${tag}`;
    });
  }
  if (filters.reports) {
    filters.reports.forEach((report) => {
      const formattedReport = REPORT_HASH + "-" + report;
      params += isShare ? `&report=${report}` : `&tag=${formattedReport}`;
    });
  }
  if (filters.name) {
    params += `&name=${filters.name}`;
  }
  if (filters.minimumNumberOfFiles) {
    params += `&minimumNumberOfFiles=${filters.minimumNumberOfFiles}`;
  }
  if (filters.maximumNumberOfFiles) {
    params += `&maximumNumberOfFiles=${filters.maximumNumberOfFiles}`;
  }
  if (filters.minimumNumberOfFolders) {
    params += `&minimumNumberOfFolders=${filters.minimumNumberOfFolders}`;
  }
  if (filters.maximumNumberOfFolders) {
    params += `&maximumNumberOfFolders=${filters.maximumNumberOfFolders}`;
  }
  return params;
}

export function getSearchRequestParamsV2(filters) {
  const params = {};
  const filterTag = [];

  if (filters.onlyDuplicates) {
    params.onlyDuplicates = filters.onlyDuplicates;
  }
  if (filters.name) {
    params.name = filters.name;
  }
  if (filters.volumeIds) {
    params.volumeId = filters.volumeIds;
  }
  if (filters.startingPath) {
    params.startingPath = filters.startingPath;
  }
  if (filters.owner) {
    params.owner = filters.owner;
  }
  if (filters.group) {
    params.group = filters.group;
  }
  if (filters.lastAccessAfter) {
    params.lastAccessAfter = filters.lastAccessAfter;
  }
  if (filters.lastAccessBefore) {
    params.lastAccessBefore = filters.lastAccessBefore;
  }
  if (filters.creationAfter) {
    params.creationAfter = filters.creationAfter;
  }
  if (filters.creationBefore) {
    params.creationBefore = filters.creationBefore;
  }
  if (filters.lastModifiedAfter) {
    params.lastModifiedAfter = filters.lastModifiedAfter;
  }
  if (filters.lastModifiedBefore) {
    params.lastModifiedBefore = filters.lastModifiedBefore;
  }
  if (filters.minimumSize) {
    const minimumByteSize = xbytes.parseSize(
      `${filters.minimumSize} ${filters.minimumSizeUnit}`
    );
    params.minimumSize = minimumByteSize;
  }
  if (filters.maximumSize) {
    const maximumByteSize = xbytes.parseSize(
      `${filters.maximumSize} ${filters.maximumSizeUnit}`
    );
    params.maximumSize = maximumByteSize;
  }
  if (filters.extension) {
    const splittedExtensions = filters.extension.split(",");
    params.extension = splittedExtensions.map((extension) =>
      extension.replace(/\./g, "").trim()
    );
  }
  if (filters.showOnlyDeletedFiles) {
    params.showOnlyDeletedFiles = filters.showOnlyDeletedFiles;
  }
  if (filters.volumesAndPaths) {
    params.volumeAndPath = filters.volumesAndPaths;
  }
  if (filters.ignorePatterns) {
    params.ignorePattern = filters.ignorePatterns;
  }
  if (filters.tags) {
    filters.tags.forEach((tag) => {
      filterTag.push(tag);
    });
  }
  if (filters.reports) {
    filters.reports.forEach((report) => {
      const formattedReport = REPORT_HASH + "-" + report;
      filterTag.push(formattedReport);
    });
  }
  if (filters.minimumNumberOfFiles) {
    params.minimumNumberOfFiles = filters.minimumNumberOfFiles;
  }
  if (filters.maximumNumberOfFiles) {
    params.maximumNumberOfFiles = filters.maximumNumberOfFiles;
  }
  if (filters.minimumNumberOfFolders) {
    params.minimumNumberOfFolders = filters.minimumNumberOfFolders;
  }
  if (filters.maximumNumberOfFolders) {
    params.maximumNumberOfFolders = filters.maximumNumberOfFolders;
  }
  
  if (filterTag.length > 0) {
    params.tag = filterTag;
  }
  
  return params;
}
