import React, { useEffect, useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import Authorization from "../validation/Authorization";
import TableResult from "../components/TableResult";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { ROLE_PROJECT_MANAGER } from "../constants/roles";
import InputFilter from "../components/InputFilter";
import TableActions from "../components/TableActions";
import { updateFileManagementFolderItems } from "../ducks/fileManagement";
import FormatSize from "../components/FormatSize";
import { getProjects } from "../api/project";
import { useSelector } from "react-redux";
import { setAlert } from "../actions/alert";
import { useNavigate } from "react-router-dom";

export default function Projects(props) {
  const { dispatch, isFetchingProjectCreation } = props;

  const [filter, setFilter] = useState("");
  const [projects, setProjects] = useState({
    elements: [],
    page: 1,
    total: 0,
    size: 25,
    sort: "name",
    direction: "asc",
  });
  const [isFetching, setIsFetching] = useState(true);
  const [size, setSize] = useState(25);

  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  const navigate = useNavigate();

  useEffect(() => {
    const numberOfElementsProject = window.localStorage.getItem("numberOfElementsProject") ? Number(window.localStorage.getItem("numberOfElementsProject")) : 25;
    setSize(numberOfElementsProject);

    dispatch(updateFileManagementFolderItems());
    fetchProjects(
      projects.page,
      numberOfElementsProject,
      window.localStorage.getItem("projectSort") ?? "name",
      window.localStorage.getItem("projectDirection") ?? "asc",
      filter
    );
  }, [dispatch, projects.page]);

  const fetchProjects = (page, size, sort, direction, filters) => {
    setIsFetching(true);
    getProjects(
      dispatch,
      auth,
      indexingDate,
      page,
      size,
      sort,
      direction,
      filters
    )
      .then((res) => {
        setProjects(res);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(setAlert("Error while fetching the list of projects", "danger"));
        setIsFetching(false);
      });
  };

  const changeFilter = (filter) => {
    setFilter(filter);
    fetchProjects(
      projects.page,
      size,
      projects.sort,
      projects.direction,
      filter
    );
  };

  const changeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === projects.sort) {
      newDirection = projects.direction === "asc" ? "desc" : "asc";
    }
    window.localStorage.setItem("projectSort", newSort);
    window.localStorage.setItem("projectDirection", newDirection);

    fetchProjects(
      projects.page,
      size,
      newSort,
      newDirection,
      filter
    );
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsProject", numberOfElements);
    setSize(numberOfElements);

    fetchProjects(
      1,
      numberOfElements,
      projects.sort,
      projects.direction,
      filter
    );
  };

  const projectsListFormated = projects.elements.map((project) => {
    let lineObject = {
      columnData: [
        <FontAwesomeIcon icon={faSitemap} />,
        project.name,
        project.description,
        `${Math.round((project.sizeOfFiles / project.allocatedSize) * 100)} %`,
        <FormatSize>{project.sizeOfFiles}</FormatSize>,
        <FormatSize>{project.allocatedSize}</FormatSize>,
        moment(project.lastModifiedDate).format("lll"),
      ],
      style: {},
      options: {
        linkData: project.id,
      },
    };

    return lineObject;
  });

  const projectListTitles = [
    { name: "" },
    { name: "Project Name", sort: "name" },
    { name: "Description", sort: "description" },
    { name: "Used Space" },
    { name: "Size", sort: "sizeOfFiles" },
    { name: "Allocated Space", sort: "allocatedSize" },
    { name: "Last Modified Date", sort: "lastModifiedDate" },
  ];

  return (
    <>
      <div className="d-flex">
        <h1 className="page-header">Projects</h1>
        <div className="ms-auto">
          <InputFilter
            placeholder="Filter by name or description"
            action={changeFilter}
          />
        </div>
        <div className="ms-2">
          <Authorization requiredAuthorizations={[ROLE_PROJECT_MANAGER]}>
            <Button
              id="createProject"
              color="success"
              onClick={() => navigate("/projects/new")}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <UncontrolledTooltip placement="auto" target="createProject">
              Create new project
            </UncontrolledTooltip>
          </Authorization>
        </div>
      </div>
      {isFetchingProjectCreation || isFetching ? (
        <Loading />
      ) : projectsListFormated.length === 0 ? (
        <h5 className="pt-2">
          There is no project with this name or description
        </h5>
      ) : (
        <div>
          <TableActions
            page={projects.page}
            numberOfPages={Math.ceil(projects.total / size)}
            selectPage={(page) => setProjects((prev) => ({ ...prev, page: page }))}
            numberOfElements={size}
            setNumberOfElements={handleChangeNumberOfElements}
          />
          <TableResult
            handleClick={(projectId) => navigate("/project/" + projectId)}
            changeOrder={changeOrder}
            sort={projects.sort}
            title={"Projects"}
            direction={projects.direction}
            data={projectsListFormated}
            titles={projectListTitles}
          />
        </div>
      )}
    </>
  );
}
