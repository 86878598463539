import { Fragment } from "react";
import { Col } from "reactstrap";
import FormatSize from "../components/FormatSize";
import { Link } from "react-router-dom";

export const getStatistics = (statisticsMap) => {
  var listOfStatisticsOver0 = [];

  for (const [key, value] of Object.entries(statisticsMap)) {
    if (value.size > 0) {
      listOfStatisticsOver0.push(
        <Fragment key={key.replace(/\s+/g, '')}>
          <div>{key}:</div>
          <div>
            <Link className="text-decoration-none" to={value.link}>
              <strong><FormatSize>{value.size}</FormatSize></strong>
            </Link>
          </div>
        </Fragment>
      );
    }
  }

  var firstHalfOfStatisticsOver0 = listOfStatisticsOver0.splice(0, Math.floor((listOfStatisticsOver0.length + 1) / 2));

  return (
    <>
      <Col
        sm={6}
        style={{
          display: "grid",
          columnGap: "20px",
          gridTemplateColumns: "230px auto",
        }}
      >
        {firstHalfOfStatisticsOver0}
      </Col>
      <Col
        sm={6}
        style={{
          display: "grid",
          columnGap: "20px",
          gridTemplateColumns: "230px auto",
        }}
      >
       {listOfStatisticsOver0} 
      </Col>
    </>
  );
};