import React, { Component } from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import Loading from "../../components/Loading";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody
} from "reactstrap";

class Timeline extends Component {
  constructor(props) {
    super(props);

    this.state = { value: 0, previous: 0 };
  }

  componentDidMount() {
    this.setState({ value: this.props.dates.length - 1 });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dates !== prevProps.dates) {
      this.setState({ value: this.props.dates.length - 1 });
      this.props.getData(this.props.dates.length - 1);
    }
  }

  render() {
    const { dates, getData, content, fetching } = this.props;

    let horizontalTimeline;
    if (fetching) {
      horizontalTimeline = <Loading />;
    } else if (dates.length > 0) {
      horizontalTimeline = (
        <span>
          <div
            style={{
              width: "100%",
              height: "100px",
              margin: "0 auto"
            }}
          >
            <HorizontalTimeline
              index={this.state.value}
              styles={{
                background: "white",
                foreground: "rgb(76, 129, 182)",
                outline: "#dfdfdf"
              }}
              minEventPadding={5}
              maxEventPadding={50}
              labelWidth={50}
              indexClick={index => {
                getData(index);
                this.setState({ value: index, previous: this.state.value });
              }}
              values={dates}
              isOpenEnding={false}
            />
          </div>
          <div>{content}</div>
        </span>
      );
    }
    return (
      <Card style={{ marginBottom: 15 }}>
        <CardHeader>
          <CardTitle tag="h5">Timeline</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">Details about the folder at certain points in time</CardSubtitle>
        </CardHeader>
        <CardBody>
          {horizontalTimeline}
        </CardBody>
      </Card>
    );
  }
}

export default Timeline;
