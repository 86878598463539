import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Input, Label, Form, FormGroup, FormFeedback } from "reactstrap";
import { setAlert } from "../../../actions/alert";
import { getAllNetworkVolumeCredentialsPaginated } from "../../../api/networkVolume";
import OnPremisesCredentialForm from "../../components/OnPremisesCredentialForm";
import { SCAN_TYPE } from "./ScanType";
import { formatName } from "../../../utils/formatString";

function VolumeStep2({
  volumeObj,
  setVolumeObj,
  credentialsObj,
  setCredentialsObj,
  showCreateCredentials,
  setShowCreateCredentials,
  setDisableForwardActionButton,
}) {
  const [errorObj, setErrorObj] = useState({
    name: false,
    path: false,
    host: false,
    shareName: false,
  });
  const [onPremisesCredentials, setOnPremisesCredentials] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getAllNetworkVolumeCredentialsPaginated(dispatch, auth)
      .then((res) => setOnPremisesCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the On-Premises credentials",
            "danger"
          )
        )
      );
  }, [showCreateCredentials]);

  useEffect(() => {
    if (
      volumeObj.name.length === 0 ||
      volumeObj.path.length === 0 ||
      (volumeObj.mountType !== "Local" &&
        (volumeObj.host.length === 0 || volumeObj.shareName.length === 0))
    ) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  }, [
    setDisableForwardActionButton,
    volumeObj.name,
    volumeObj.path,
    volumeObj.mountType,
    volumeObj.host,
    volumeObj.shareName,
  ]);

  const handleCredentialsChange = (e) => {
    const value = e.target.value;

    if (value === "add new credentials") {
      setShowCreateCredentials(true);
      return;
    }

    setVolumeObj((prev) => ({ ...prev, credentialsId: value }));
  };

  return (
    <>
      {showCreateCredentials ? (
        <OnPremisesCredentialForm
          name={credentialsObj.name}
          setName={(value) =>
            setCredentialsObj((prev) => ({ ...prev, name: value }))
          }
          username={credentialsObj.username}
          setUsername={(value) =>
            setCredentialsObj((prev) => ({ ...prev, username: value }))
          }
          domain={credentialsObj.domain}
          setDomain={(value) =>
            setCredentialsObj((prev) => ({ ...prev, domain: value }))
          }
          options={credentialsObj.options}
          setOptions={(value) =>
            setCredentialsObj((prev) => ({ ...prev, options: value }))
          }
          password={credentialsObj.password}
          setPassword={(value) =>
            setCredentialsObj((prev) => ({ ...prev, password: value }))
          }
          setDisableCreateButton={setDisableForwardActionButton}
        />
      ) : (
        <Form>
          <FormGroup>
            <Label>Mount Type</Label>
            <Input
              type="select"
              value={volumeObj.mountType}
              onChange={(e) =>
                setVolumeObj((prev) => ({ ...prev, mountType: e.target.value }))
              }
            >
              <option>Local</option>
              <option>SMB</option>
              <option>NFS</option>
            </Input>
          </FormGroup>
          {volumeObj.mountType !== "Local" && (
            <>
              <FormGroup>
                <Label>Host</Label>
                <Input
                  type="text"
                  value={volumeObj.host}
                  placeholder="Ex: 127.0.0.1"
                  onChange={(e) => {
                    const value = e.target.value;
                    setVolumeObj((prev) => ({ ...prev, host: value }));
                    setErrorObj((prev) => ({
                      ...prev,
                      host: value.length === 0,
                    }));
                  }}
                  invalid={errorObj.host}
                />
                <FormFeedback>Host can't be empty</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Share Name</Label>
                <Input
                  type="text"
                  value={volumeObj.shareName}
                  placeholder="Ex: NASW"
                  onChange={(e) => {
                    const value = e.target.value;
                    setVolumeObj((prev) => ({ ...prev, shareName: value }));
                    setErrorObj((prev) => ({
                      ...prev,
                      shareName: value.length === 0,
                    }));
                  }}
                  invalid={errorObj.shareName}
                />
                <FormFeedback>Share Name can't be empty</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Credentials</Label>
                <Input
                  type="select"
                  value={volumeObj.credentialsId}
                  onChange={(e) => handleCredentialsChange(e)}
                >
                  <option value="">Guest</option>
                  {onPremisesCredentials.map((cred) => (
                    <option value={cred.id}>{cred.name}</option>
                  ))}
                  <option value="add new credentials">
                    Add new credentials
                  </option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Options (optional)</Label>
                <Input
                  type="text"
                  value={volumeObj.options}
                  placeholder="Ex: option1=value1,option2=value2"
                  onChange={(e) => {
                    const value = e.target.value;
                    setVolumeObj((prev) => ({ ...prev, options: value }));
                    setErrorObj((prev) => ({
                      ...prev,
                      options: value.length > 10000,
                    }));
                  }}
                  invalid={errorObj.options}
                />
                <FormFeedback>
                  Options must be between 0 and 10000 characters
                </FormFeedback>
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label>
              The name of the On-Premises Storage that will be used in
              DataIntell
            </Label>
            <Input
              type="text"
              value={volumeObj.name}
              placeholder="Ex: SAN"
              onChange={(e) => {
                const value = e.target.value;
                setVolumeObj((prev) => ({
                  ...prev,
                  name: value,
                }));
                setErrorObj((prev) => ({ ...prev, name: value.length === 0 }));
              }}
              invalid={errorObj.name}
            />
            <FormFeedback>Name can't be empty</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              The path of the On-Premises Storage on the server that will be
              scanned
            </Label>
            <Input
              type="text"
              value={volumeObj.path}
              placeholder="Ex: /mnt/SAN"
              onChange={(e) => {
                const value = e.target.value;
                setVolumeObj((prev) => ({
                  ...prev,
                  path: value,
                }));
                setErrorObj((prev) => ({ ...prev, path: value.length === 0 }));
              }}
              invalid={errorObj.path}
            />
            <FormFeedback>Path can't be empty</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              The type of scan that will be used
            </Label>
            <Input
              type="select"
              value={volumeObj.scanType}
              onChange={(e) => {
                setVolumeObj((prev) => ({
                  ...prev,
                  scanType: e.target.value,
                }));
              }}
            >
              {SCAN_TYPE.map((type, index) => <option key={type + "-" + index} value={type}>{formatName(type)}</option>)}
            </Input>
          </FormGroup>
        </Form>
      )}
    </>
  );
}

export default VolumeStep2;
