export const CHART_COLORS = [
    "#5F9EA0",
    "#FF7F50",
    "#6495ED",
    "#29C471",
    "#2F79BA",
    "#D99134",
    "#F3C595",
    "#D42A4F",
    "#07B379",
    "#8540BD",
    "#1114BA",
    "#F0BD7F",
    "#B82727",
    "#A1650B",
    "#0000FF",
    "#8A2BE2",
    "#A52A2A",
    "#DEB887",
    "#DC143C",
    "#00FFFF",
    "#4FDBB6",
    "#008B8B",
    "#B8860B",
    "#BF4141",
    "#059C05"
];