import React, { useState, useEffect } from "react";
import { Button, Input, Form, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import ConfirmModal from "../../components/ConfirmModal";

const SIDE_BARS_STYLE = { 
  border: "1px solid rgba(0,0,0,0.1)", 
  width: "35%", 
  display: "inline-block", 
  verticalAlign: "middle", 
  marginRight: 30,
  marginLeft: 30
};

UpdateLicense.propTypes = {
  license: PropTypes.object,
  updateLicenseFunc: PropTypes.func
};

function UpdateLicense(props) {
  const { license, updateLicenseFunc } = props;

  const [isUpdateLicenseFormOpen, setIsUpdateLicenseFormOpen] = useState(false);
  const [updatedLicense, setUpdatedLicense] = useState("");
  const [isLicenseFieldBlurredOnce, setIsLicenseFieldBlurredOnce] = useState(false);

  useEffect(() => {
    if (!license.isFetching && license.isValid) {
      setUpdatedLicense("");
      setIsUpdateLicenseFormOpen(false);
      setIsLicenseFieldBlurredOnce(false);
    }
  }, [license]);

  const uploadFileFunction = (e) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      setUpdatedLicense(evt.target.result);
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <>
      <Button 
        outline
        color="primary"
        onClick={() => setIsUpdateLicenseFormOpen(!isUpdateLicenseFormOpen)}
      >
        Update license
      </Button>
      <ConfirmModal
        showConfirmation={isUpdateLicenseFormOpen}
        title="Update license"
        buttonColor="primary"
        buttonText="Update"
        confirmAction={() => updateLicenseFunc(updatedLicense ? updatedLicense : "INVALID")}
        closeConfirmation={() => {
          setUpdatedLicense("");
          setIsUpdateLicenseFormOpen(false);
          setIsLicenseFieldBlurredOnce(false);
        }}
      >
        <h6>Upload license file</h6>
        <input
          type="file"
          name="licenseFile"
          id="licenseFileId"
          accept=".txt"
          onChange={e => uploadFileFunction(e)}
        />
        <span className="my-3" style={{ width: "100%", textAlign: "center", display: "inline-block" }}>
          <span style={SIDE_BARS_STYLE} />OR<span style={SIDE_BARS_STYLE} />
        </span>
        <h6>Paste license content</h6>
        <Form>
          <Input
            type="textarea"
            name="license"
            style={{ height: 300 }}
            value={updatedLicense}
            onChange={e => setUpdatedLicense(e.target.value)}
            placeholder="Enter your provided license here"
            onBlur={() => setIsLicenseFieldBlurredOnce(true)}
            invalid={isLicenseFieldBlurredOnce && !updatedLicense}
          />
          <FormFeedback>License must not be empty</FormFeedback>
        </Form>
      </ConfirmModal>
    </>
  );
}

export default UpdateLicense;
