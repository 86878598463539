import React, {useState } from "react";
import { Collapse, Card, CardBody } from "reactstrap";

export default function CollapseText(props) {
  const { text, hiddenText } = props;
  const isBlank = hiddenText.length <= 1;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isBlank &&
        <div>
          <span
            style={{ cursor: "pointer", color: "#337ab7" }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {text}
          </span>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>{hiddenText}</CardBody>
            </Card>
          </Collapse>
        </div>
      }
    </>
  );
}
