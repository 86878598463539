import React from "react";
import { Badge } from "reactstrap";

const LABEL_FOR_ROLE = require("../../constants/labelForRole.json");

function RoleList({ roleList, color = "secondary" }) {
  return (
    <div style={{ maxWidth: "350px" }}>
      {roleList.map((group, index) => (
        <Badge key={index} color={color} style={{ marginRight: "5px" }}>
          {LABEL_FOR_ROLE[group.name] ?? group.name}
        </Badge>
      ))}
    </div>
  );
}

export default RoleList;
