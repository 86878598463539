import React, { useState, forwardRef, useRef, useEffect } from "react";
import LineChart from "../../components/LineChart";
import Loading from "../../components/Loading";
import {
  Input,
  FormGroup,
  Form,
  Row,
  Label,
  Card,
  CardHeader,
  CardBody,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
  CALENDAR_ICON_STYLE,
  DAY_IN_MILLISECONDS,
  DATE_FORMAT,
} from "../../constants/dateFormatting";
import PDFChartButton from "../../components/PDFChartButton";
import moment from "moment";
import { getVolumeById } from "../../api/volume";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { formatSize } from "../../components/FormatSize";
import { getDeltaInfo } from "../../utils/getDeltaInfo";
import TimelineLegend from "../../components/TimelineLegend";

export function VolumeChartWithDates(props) {
  const {
    volumeId,
    indexingDate,
    title,
    setTimelineRef,
    setMinSize,
    setMaxSize,
    setChartFromDate,
    setChartToDate,
    setChartInterval,
    setChartUnit,
    setDeltaInfo,
  } = props;

  const [interval, setInterval] = useState("30");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showCustomTimePeriod, setShowCustomTimePeriod] = useState(false);
  const [volumes, setVolumes] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const chartRef = useRef();

  useEffect(() => {
    setTimelineRef(chartRef);
    getSizesByDate(
      moment(Date.parse(indexingDate) - DAY_IN_MILLISECONDS * 29).format(
        "YYYY-MM-DD"
      ),
      indexingDate
    );
  }, []);

  useEffect(() => {
    if (!isFetching) {
      setMinSize(sizes.length === 0 ? 0 : Math.min(...sizes));
      setMaxSize(sizes.length === 0 ? 0 : Math.max(...sizes));
      setChartUnit(filesizeObject.unit);
    }
    setDeltaInfo(getDeltaInfo(sizes, labels, filesizeObject.unit));
  }, [isFetching]);

  useEffect(() => {
    setChartFromDate(fromDate);
    setChartToDate(toDate);
  }, [interval, toDate, fromDate]);

  const CustomDatePicker = forwardRef(({ onClick, value }, ref) => {
    return (
      <div ref={ref} style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={faCalendar} style={CALENDAR_ICON_STYLE} />
        <Input
          onClick={onClick}
          value={value}
          onChange={() => {}}
          style={{ paddingLeft: "2rem", width: "130px" }}
          bsSize="sm"
        />
      </div>
    );
  });

  const getSizesByDate = (from, to) => {
    setIsFetching(true);
    getVolumeById(dispatch, auth, volumeId, from, to)
      .then((res) => {
        setVolumes(res.elements);
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(setAlert("Error while fetching volume details", "danger"));
        setIsFetching(false);
      });
  };

  const getHistory = (fromDate, toDate) => {
    getSizesByDate(
      moment(fromDate + DAY_IN_MILLISECONDS).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
  };

  const sizes = [];
  const labels = [];

  const sum = volumes.reduce((acc, volume) => acc + volume.used, 0);
  const filesizeObject = formatSize(
    volumes.length > 0 ? sum / volumes.length : 0,
    baseSize,
    null,
    "object"
  );
  const getTotal = () => {
    if (volumes[0] && volumes[0].free) {
      return volumes[0].total;
    }
    return 0;
  };

  volumes.forEach((volume) => {
    labels.unshift(volume.indexingDate);
    sizes.unshift(
      formatSize(volume.used, baseSize, filesizeObject.exponent, "object").value
    );
  });

  return (
    <>
      <Card className="my-3">
        <CardHeader>
          <span style={{ fontSize: "1.25rem" }}>{title}</span>
          <div className="float-end" style={{ paddingLeft: 10 }}>
            <Form>
              <Row className="row-cols-lg-auto g-3 align-items-center">
                <Col>
                  <Input
                    type="select"
                    value={interval}
                    onChange={(event) => {
                      if ("custom" === event.target.value) {
                        setFromDate(
                          Date.parse(indexingDate) -
                            DAY_IN_MILLISECONDS * (Number(interval) - 1)
                        );
                        setToDate(
                          Date.parse(indexingDate) + DAY_IN_MILLISECONDS
                        );
                        setShowCustomTimePeriod(true);
                      } else {
                        setShowCustomTimePeriod(false);
                        getSizesByDate(
                          moment(
                            Date.parse(indexingDate) -
                              DAY_IN_MILLISECONDS *
                                (Number(event.target.value) - 1)
                          ).format("YYYY-MM-DD"),
                          moment(indexingDate).format("YYYY-MM-DD")
                        );
                      }
                      setInterval(event.target.value);
                      setChartInterval(event.target.value);
                    }}
                    bsSize="sm"
                  >
                    <option value="7">Last 7 days</option>
                    <option value="30">Last 30 days</option>
                    <option value="90">Last 90 days</option>
                    <option value="custom">Custom</option>
                  </Input>
                </Col>
                {showCustomTimePeriod && (
                  <>
                    <Col className="ms-2">
                      <Label className="me-1" for="fromDate">
                        From
                      </Label>
                      <DatePicker
                        id="fromDate"
                        className="form-control"
                        type="text"
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDate(Date.parse(date));
                          getHistory(Date.parse(date), toDate);
                        }}
                        maxDate={toDate}
                        dateFormat={DATE_FORMAT}
                        customInput={<CustomDatePicker />}
                        popperPlacement="auto-start"
                      />
                    </Col>
                    <Col className="ms-2">
                      <Label className="me-1" for="toDate">
                        To
                      </Label>
                      <DatePicker
                        id="toDate"
                        className="form-control"
                        type="text"
                        selected={toDate}
                        onChange={(date) => {
                          setToDate(Date.parse(date));
                          getHistory(fromDate, Date.parse(date));
                        }}
                        minDate={fromDate}
                        maxDate={Date.parse(indexingDate) + DAY_IN_MILLISECONDS}
                        dateFormat={DATE_FORMAT}
                        customInput={<CustomDatePicker />}
                        popperPlacement="auto-start"
                      />
                    </Col>
                  </>
                )}
                <div className="ms-1">
                  <PDFChartButton
                    chartRef={chartRef}
                    title={title}
                    subTitle={filesizeObject.unit}
                    deltaInfo={getDeltaInfo(sizes, labels, filesizeObject.unit)}
                  />
                </div>
              </Row>
            </Form>
          </div>
        </CardHeader>
        <CardBody>
          {volumes.length === 0 && (
            <span className="me-3">
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              There is no data for the selected time frame. Make sure that the
              scan is properly configured or try another time frame.
            </span>
          )}
          {isFetching ? (
            <Loading />
          ) : (
            <>
              <div style={{ textAlign: "right" }}>
                {getDeltaInfo(sizes, labels, filesizeObject.unit)}
              </div>

              <LineChart
                data={sizes}
                title={`Size (${filesizeObject.unit})`}
                unit={filesizeObject.unit}
                labels={labels}
                height={100}
                showMinMax
                maxCapacity={Number(
                  formatSize(
                    getTotal() ?? 0,
                    baseSize,
                    filesizeObject.exponent,
                    "object"
                  ).value
                )}
                hideLabels
              />
              <TimelineLegend
                customLabel="Last 10% Available "
                minValue={sizes.length === 0 ? 0 : Math.min(...sizes)}
                maxValue={sizes.length === 0 ? 0 : Math.max(...sizes)}
                unit={filesizeObject.unit}
              ></TimelineLegend>
            </>
          )}

          <div
            style={{ position: "absolute", left: "-9999px", top: "-9999px" }}
          >
            <LineChart
              lineChartRef={chartRef}
              data={sizes}
              title={`Size (${filesizeObject.unit})`}
              unit={filesizeObject.unit}
              labels={labels}
              showMinMax
              height={350}
              width={1100}
              maxCapacity={Number(
                formatSize(
                  getTotal() ?? 0,
                  baseSize,
                  filesizeObject.exponent,
                  "object"
                ).value
              )}
              hideLabels
              showBadges={false}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default VolumeChartWithDates;
