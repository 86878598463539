import { FormGroup, Input, Label } from "reactstrap";

export default function ArchiveDestinationReview({
    planName,
    indexName,
    parseFileDeletionMethod,
    location,
    deltaScanAfter,
    setDeltaScanAfter
}) {

    return (
        <>
            <hr />
            <div className="d-flex">
                <div>
                    <h3>Destination</h3>
                    <ul style={{ listStyle: "none", lineHeight: "2", paddingLeft: "0", marginBottom: "0" }}>
                        <li>Archive Index: <strong>{indexName}</strong></li>
                        <li>Archive Plan: <strong>{planName}</strong></li>
                        <li>Location: <strong>{location.length ? location : "Same path as the current file system"}</strong></li>
                        <li>File deletion method after archive: <strong>{parseFileDeletionMethod()}</strong></li>
                    </ul>
                    <FormGroup check className="mt-3">
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={deltaScanAfter}
                                onChange={e => setDeltaScanAfter(e.target.checked)}
                            />
                            Scan the Archiware P5 indexes after the archive job
                        </Label>
                    </FormGroup>
                </div>
                <div className="ms-auto align-self-center">
                    <img src="/icons/P5.png" alt="Archiware P5 logo" className="img-fluid" width="60px" height="60px" />
                </div>
            </div>
        </>
    );
}
