import React from "react";

function BucketReviewPage({ bucketObj }) {
  return (
    <ul style={{ listStyle: "none", lineHeight: "2", paddingLeft: "0" }}>
      <li>
        Name: &nbsp; <strong>{bucketObj.description}</strong>
      </li>

      {bucketObj.type === "DROPBOX" ? (
        <li>
          Path: &nbsp; <strong>{bucketObj.path}</strong>
        </li>
      ) : (
        <li>
          Bucket Name: &nbsp; <strong>{bucketObj.name}</strong>
        </li>
      )}

      {bucketObj.type === "ORACLE_OBJECT_STORAGE" ? (
        <li>
          Namespace: &nbsp; <strong>{bucketObj.namespace}</strong>
        </li>
      ) : null}
      {bucketObj.type !== "DROPBOX" &&
        bucketObj.type !== "AZURE" &&
        bucketObj.type !== "OTHER" && (
          <li>
            Region: &nbsp; <strong>{bucketObj.region}</strong>
          </li>
        )}
      <li>
        Storage Type: &nbsp; <strong>{bucketObj.storageName}</strong>
      </li>
    </ul>
  );
}

export default BucketReviewPage;
