import React, { useEffect, useState } from "react";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { fetchWithAuth } from "../../../actions/requestHelper";
import { useDispatch, useSelector } from "react-redux";
import GeneralError from "../../../components/GeneralError";

function ArchiwareStep2({
  archiwareObj,
  setArchiwareObj,
  setDisableForwardActionButton,
  localArchiwareObjForValidation,
}) {
  const [errorObj, setErrorObj] = useState({
    index: "",
    path: "",
    name: "",
  });
  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [archiwareErr, setArchiwareErr] = useState("");
  const [timer, setTimer] = useState(10);
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNameExist, setIsNameExist] = useState(false);
  const existingVolumeSettings = useSelector(
    ({ volumeSettings }) => volumeSettings.items
  );

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (timer <= 0) {
      setShowWarning(true);
      return;
    }
    const intervalId = setInterval(() => {
      setTimer((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (indexes.length === 0) {
      setArchiwareErr("");
      fetchWithAuth(
        dispatch,
        `/v1/archiwares/credentials/${archiwareObj.credentialsId}/indexes?serverId=${archiwareObj.server}`,
        auth.accessToken,
        "GET"
      )
        .then((res) => {
          setIndexes(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setArchiwareErr(err.debugMessage);
          setIsLoading(false);
        });
    }
  }, [auth.accessToken, dispatch, archiwareObj, indexes.length]);

  useEffect(() => {
    if (archiwareErr !== "") {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  }, [archiwareErr, setDisableForwardActionButton]);

  useEffect(() => {
    if (
      archiwareObj.index.length === 0 ||
      archiwareObj.path.length === 0 ||
      archiwareObj.name.length === 0 ||
      isNameExist
    ) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  }, [setDisableForwardActionButton, archiwareObj]);

  const handleError = (inputType) => {
    switch (inputType) {
      case "index":
        if (localArchiwareObjForValidation.index.length === 0) {
          setErrorObj((prev) => ({
            ...prev,
            index: "Index can't be empty",
          }));
        } else {
          setErrorObj((prev) => ({
            ...prev,
            index: "",
          }));
        }
        break;

      case "path":
        if (localArchiwareObjForValidation.path.length === 0) {
          setErrorObj((prev) => ({
            ...prev,
            path: "Path can't be empty",
          }));
        } else {
          setErrorObj((prev) => ({
            ...prev,
            path: "",
          }));
        }
        break;

      case "name":
        if (localArchiwareObjForValidation.name.length === 0) {
          setErrorObj((prev) => ({
            ...prev,
            name: "Name can't be empty",
          }));
        } else {
          const nameExist = existingVolumeSettings.find(
            (volume) => volume.name === localArchiwareObjForValidation.name
          );
          if (nameExist) {
            setErrorObj((prev) => ({
              ...prev,
              name: `ArchiwareP5Index name should be unique,${localArchiwareObjForValidation.name} already exists`,
            }));
            setIsNameExist(true);
          } else {
            setIsNameExist(false);
            setErrorObj((prev) => ({
              ...prev,
              name: "",
            }));
          }
        }

        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="d-flex justify-content-center align-items-center p-3">
            <Spinner />
          </div>
          <div hidden={!showWarning}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="me-1"
              color="#ffc107"
            />
            The request is taking longer than usual, it might be an issue with
            the Archiware P5 URL.
          </div>
        </>
      ) : archiwareErr !== "" ? (
        <GeneralError
          customErrorMessage="Something went wrong, please check the inputs in the previous step to see if they're correct"
          apiErrorMessage={archiwareErr}
          iconSize={"2x"}
        />
      ) : (
        <div>
          <Form>
            <FormGroup>
              <Label>Archive Index </Label>
              <Input
                id="index"
                name="index"
                type="select"
                value={archiwareObj.index}
                onChange={(e) => {
                  const archiwareIndexInput = e.target.value;
                  localArchiwareObjForValidation.index = archiwareIndexInput;
                  handleError("index");
                  setArchiwareObj((prev) => ({
                    ...prev,
                    index: archiwareIndexInput,
                  }));
                }}
                onBlur={() => handleError("index")}
                invalid={errorObj["index"].length > 1}
              >
                <option value="">Select index</option>
                {indexes.map((elem, index) => (
                  <option key={index} value={elem}>
                    {elem}
                  </option>
                ))}
              </Input>
              <FormFeedback>{errorObj["index"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>Path in the Archive Index</Label>
              <Input
                id="path"
                name="path"
                placeholder="Ex: /mnt/SAN"
                type="text"
                value={archiwareObj.path}
                onChange={(e) => {
                  const archiwarePathInput = e.target.value;
                  localArchiwareObjForValidation.path = archiwarePathInput;
                  handleError("path");
                  setArchiwareObj((prev) => ({
                    ...prev,
                    path: archiwarePathInput,
                  }));
                }}
                onBlur={() => handleError("path")}
                invalid={errorObj["path"].length > 1}
              />
              <FormFeedback>{errorObj["path"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>
                DataIntell Name
                <FontAwesomeIcon
                  id="nameLabel"
                  className="ms-sm-1"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  placement="right"
                  isOpen={isNameTooltipOpen}
                  target="nameLabel"
                  toggle={() => setIsNameTooltipOpen(!isNameTooltipOpen)}
                >
                  The name of the volume that will be used in DataIntell
                </Tooltip>
              </Label>

              <Input
                id="name"
                name="name"
                placeholder="Ex: name"
                type="text"
                value={archiwareObj.name}
                onChange={(e) => {
                  const archiwareNameInput = e.target.value;
                  localArchiwareObjForValidation.name = archiwareNameInput;
                  handleError("name");
                  setArchiwareObj((prev) => ({
                    ...prev,
                    name: archiwareNameInput,
                  }));
                }}
                onBlur={() => handleError("name")}
                invalid={errorObj["name"].length > 1}
              />
              <FormFeedback>{errorObj["name"]}</FormFeedback>
            </FormGroup>
          </Form>
        </div>
      )}
    </>
  );
}

export default ArchiwareStep2;
