import { fetchWithAuth, downloadFileWithAuth } from "./requestHelper";
import { setAlert } from "./alert";

export const NEW_ERROR = "NEW_ERROR";

export const REQUEST_VOLUMES = "REQUEST_VOLUMES";
export const RECEIVE_VOLUMES = "RECEIVE_VOLUMES";

export const REQUEST_ALL_VOLUMES = "REQUEST_ALL_VOLUMES";
export const RECEIVE_ALL_VOLUMES = "RECEIVE_ALL_VOLUMES";

export const REQUEST_SCAN_EVERYTHING = "REQUEST_SCAN_EVERYTHING";
export const RECEIVE_SCAN_EVERYTHING = "RECEIVE_SCAN_EVERYTHING";

export const REQUEST_LAST_ACCESS = "REQUEST_LAST_ACCESS";
export const RECEIVE_LAST_ACCESS = "RECEIVE_LAST_ACCESS";
export const INVALIDATE_DATE = "INVALIDATE_DATE";
export const SELECT_DATES = "SELECT_DATES";

export const SET_FILTERS = "SET_FILTERS";

export const REQUEST_DATES = "REQUEST_DATES";
export const RECEIVE_DATES = "RECEIVE_DATES";
export const SET_DATE = "SET_DATE";

export const REQUEST_DUPLICATE_FILES = "REQUEST_DUPLICATE_FILES";
export const RECEIVE_DUPLICATE_FILES = "RECEIVE_DUPLICATE_FILES";

export const REQUEST_PATHS_STATISTICS = "REQUEST_PATHS_STATISTICS";
export const RECEIVE_PATHS_STATISTICS = "RECEIVE_PATHS_STATISTICS";

export const REQUEST_BIGGEST_VOLUME_VARIATIONS =
  "REQUEST_BIGGEST_VOLUME_VARIATIONS";
export const RECEIVE_BIGGEST_VOLUME_VARIATIONS =
  "RECEIVE_BIGGEST_VOLUME_VARIATIONS";
export const SET_VOLUME_VARIATION_DURATION = "SET_VOLUME_VARIATION_DURATION";

function newError(message) {
  return {
    type: NEW_ERROR,
    message,
  };
}

export function selectDates(dates) {
  return {
    type: SELECT_DATES,
    dates,
  };
}

function requestVolumes() {
  return {
    type: REQUEST_VOLUMES,
  };
}

function receiveVolumes(volumes, sort, direction) {
  return {
    type: RECEIVE_VOLUMES,
    volumes,
    sort,
    direction,
  };
}

export function fetchVolumes(
  page = 1,
  sort = "name",
  direction = "asc",
  size = 1000
) {
  return (dispatch, getState) => {
    dispatch(requestVolumes());
    const url = `/v1/volumes?&page=${page}&sort=${sort}&direction=${direction}&size=${size}&indexingDate=${
      getState().dates.activeDate
    }`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then((json) => {
        dispatch(receiveVolumes(json, sort, direction));
      })
      .catch((e) => {
        dispatch(setAlert(e, "danger"));
      });
  };
}

function requestLastAccess(date) {
  return {
    type: REQUEST_LAST_ACCESS,
    date,
  };
}

function receiveLastAccess(date, size) {
  return {
    type: RECEIVE_LAST_ACCESS,
    date,
    size,
    receivedAt: Date.now(),
  };
}

export function fetchLastAccess(date) {
  return (dispatch, getState) => {
    dispatch(requestLastAccess(date));
    const url = `/files/search/sizes?search=&lastAccessBefore=${date}&indexingDate=${
      getState().dates.activeDate
    }`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (size) => {
        dispatch(receiveLastAccess(date, size));
      }
    ).catch(err => console.error(err));
  };
}

function shouldFetchLastAccess(state, date) {
  const lastAccess = state.lastAccessByDate[date];
  if (!lastAccess) {
    return true;
  } else if (lastAccess.isFetching) {
    return false;
  } else {
    return lastAccess.didInvalidate;
  }
}

export function fetchLastAccessIfNeeded(date) {
  return (dispatch, getState) => {
    if (shouldFetchLastAccess(getState(), date)) {
      return dispatch(fetchLastAccess(date));
    } else {
      return Promise.resolve();
    }
  };
}

function requestDuplicateFiles() {
  return {
    type: REQUEST_DUPLICATE_FILES,
  };
}

function receiveDuplicateFiles(statistics) {
  return {
    type: RECEIVE_DUPLICATE_FILES,
    statistics,
    receivedAt: Date.now(),
  };
}

export function fetchDuplicateFiles() {
  return (dispatch, getState) => {
    dispatch(requestDuplicateFiles());
    const url = `/statistics/duplicates?indexingDate=${
      getState().dates.activeDate
    }`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(receiveDuplicateFiles(json));
      }
    ).catch(err => console.error(err));
  };
}

export function setFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}

/****************************************
Menu content
*****************************************/

function requestDates() {
  return {
    type: REQUEST_DATES,
  };
}

function receiveDates(dates) {
  return {
    type: RECEIVE_DATES,
    dates,
    receivedAt: Date.now(),
  };
}

export function setDate(date) {
  return {
    type: SET_DATE,
    date,
  };
}

export function fetchDates() {
  return (dispatch, getState) => {
    dispatch(requestDates());
    const url = `/administration/dates`;

    fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then((response) => {
        if (response.status >= 400) {
          throw response;
        }
        return response;
      })
      .then((result) => {
        dispatch(receiveDates(result));
      })
      .catch((err) => {
        dispatch(newError(err));
      });
  };
}

function shouldFetchDates(state) {
  const { dates } = state;

  if (dates.items.length === 0) {
    return true;
  } else if (dates.isFetching) {
    return false;
  } else {
    return dates.didInvalidate;
  }
}

export function fetchDatesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchDates(getState())) {
      return dispatch(fetchDates());
    } else {
      return Promise.resolve();
    }
  };
}

function requestPathsStatistics(paths, isDuplicate, lastAccessDate) {
  return {
    type: REQUEST_PATHS_STATISTICS,
    paths,
    isDuplicate,
    lastAccessDate,
  };
}

function receivePathsStatistics(
  paths,
  isDuplicate,
  lastAccessDate,
  statistics
) {
  return {
    type: RECEIVE_PATHS_STATISTICS,
    paths,
    isDuplicate,
    lastAccessDate,
    statistics,
  };
}

export function fetchPathsStatistics(paths, isDuplicate, lastAccessDate) {
  return (dispatch, getState) => {
    dispatch(requestPathsStatistics(paths, isDuplicate, lastAccessDate));
    let queryParams = `?indexingDate=${getState().dates.activeDate}`;
    queryParams = lastAccessDate
      ? `${queryParams}&lastAccess=${lastAccessDate}`
      : queryParams;
    queryParams = isDuplicate
      ? `${queryParams}&onlyDuplicate=${isDuplicate}`
      : queryParams;
    paths.map((path) => (queryParams += `&path=${encodeURIComponent(path)}`));
    const url = `/statistics/paths${queryParams}`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(
          receivePathsStatistics(paths, isDuplicate, lastAccessDate, json)
        );
      }
    ).catch(err => console.error(err));
  };
}

/**********************************************
 * Dashboard
 **********************************************/

function requestAllVolumes() {
  return {
    type: REQUEST_ALL_VOLUMES,
  };
}

function receiveAllVolumes(volumes) {
  return {
    type: RECEIVE_ALL_VOLUMES,
    volumes,
  };
}

export function fetchAllVolumes() {
  return (dispatch, getState) => {
    dispatch(requestAllVolumes());
    const url = `/v1/volumes?&page=1&sort=name&direction=asc&size=200&indexingDate=${
      getState().dates.activeDate
    }`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(receiveAllVolumes(json.elements));
      }
    ).catch(err => console.error(err));
  };
}

function requestBiggestVolumeVariations() {
  return {
    type: REQUEST_BIGGEST_VOLUME_VARIATIONS,
  };
}

function receiveBiggestVolumeVariations(variations) {
  return {
    type: RECEIVE_BIGGEST_VOLUME_VARIATIONS,
    variations,
  };
}

export function fetchBiggestVolumeVariations() {
  return (dispatch, getState) => {
    dispatch(requestBiggestVolumeVariations());
    const url = `/detailedVolumes/biggestVariations?duration=${
      getState().volumeVariations.duration
    }&indexingDate=${getState().dates.activeDate}&size=100`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(receiveBiggestVolumeVariations(json));
      }
    ).catch(err => console.error(err));
  };
}

export function setVolumeVariationDuration(duration) {
  return {
    type: SET_VOLUME_VARIATION_DURATION,
    duration,
  };
}

function requestScanEverything() {
  return {
    type: REQUEST_SCAN_EVERYTHING,
  };
}

function receiveScanEverything() {
  return {
    type: RECEIVE_SCAN_EVERYTHING,
  };
}

export function scanEverything(cloneIndex = true) {
  return (dispatch, getState) => {
    dispatch(requestScanEverything());
    const url = `/scans?cloneIndex=${cloneIndex}`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken, "POST")
      .then(() => {
        dispatch(receiveScanEverything());
      })
      .catch((err) => dispatch(setAlert(err.message, "danger")));
  };
}

export function downloadLogs() {
  return (dispatch, getState) => {
    const url = "/administration/logs";
    return downloadFileWithAuth(
      dispatch,
      url,
      "logs.zip",
      getState().auth.accessToken
    )
      .then((json) => {})
      .catch((err) => {
        console.error(err);
        let message = err.statusText || "Unable to download log files";
        dispatch(setAlert(message, "danger"));
      });
  };
}
