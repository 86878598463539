import React, { Component } from "react";
import RectangleChart from "../../components/RectangleChart";
import {
  Card,
  CardHeader,
  CardBody,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import Loading from "../../components/Loading";

import { Input, FormGroup } from "reactstrap";
import FormatSize from "../../components/FormatSize";

class VolumeVariations extends Component {
  render() {
    const {
      title,
      subtitle,
      variations,
      handleSelectChange,
      handlePathChange,
      duration,
      isFetching,
    } = this.props;
    const colorList = {
      used: "#4C81B6",
      free: "rgba(1, 1, 1, 0.2)",
      positiveVariation: "#375C82",
      negativeVariation: "#8BA6C1",
    };

    const cardBody = () => {
      if (isFetching === true) {
        return <Loading />;
      } else {
        return variations.map((variation, index) => {
          let data = [variation.object1Used, variation.object1Free];
          let colors = [colorList.used, colorList.free];
          let labels = [
            <>
              Used space (<FormatSize>{variation.object1Used}</FormatSize>)
            </>,
            <>
              Available space (<FormatSize>{variation.object1Free}</FormatSize>)
            </>,
          ];
          let textColor = "black";

          if (variation.variation > 0) {
            data = [
              variation.object2Used,
              variation.variation,
              variation.object1Free,
            ];
            colors = [
              colorList.used,
              colorList.positiveVariation,
              colorList.free,
            ];
            labels = [
              <>
                Used space {variation.numberOfDays} days ago (
                <FormatSize>{variation.object2Used}</FormatSize>)
              </>,
              <>
                Data added (<FormatSize>{variation.variation}</FormatSize>)
              </>,
              <>
                Available space (
                <FormatSize>{variation.object1Free}</FormatSize>)
              </>,
            ];
            textColor = colorList.red;
          } else if (variation.variation < 0) {
            data = [
              variation.object1Used,
              Math.abs(variation.variation),
              variation.object2Free,
            ];
            colors = [
              colorList.used,
              colorList.negativeVariation,
              colorList.free,
            ];
            labels = [
              <>
                Used space (<FormatSize>{variation.object1Used}</FormatSize>)
              </>,
              <>
                Data removed (
                <FormatSize>{Math.abs(variation.variation)}</FormatSize>)
              </>,
              <>
                Available space {variation.numberOfDays} days ago (
                <FormatSize>{variation.object2Free}</FormatSize>)
              </>,
            ];
            textColor = colorList.green;
          }
          return (
            <span
              key={`${variation.name}-${index}`}
              onClick={() => {
                handlePathChange(variation.links[0]);
              }}
              style={{ cursor: "pointer" }}
            >
              <h6 className="mb-0">{variation.name}</h6>
              <div className="mb-1">
                <RectangleChart
                  links={variation.links}
                  handleClick={() => {}}
                  labels={labels}
                  data={data}
                  colors={colors}
                />
              </div>

              <p>
                <strong style={{ color: textColor }}>
                  <FormatSize>{Math.abs(variation.variation)}</FormatSize>
                </strong>{" "}
                was {variation.variation > 0 ? "added" : "removed"} in the last{" "}
                <strong>
                  {variation.numberOfDays}{" "}
                  {variation.numberOfDays > 1 ? "days" : "day"}
                </strong>
              </p>
            </span>
          );
        });
      }
    };

    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">
            {title}
            <FormGroup className="float-end">
              <Input
                type="select"
                //style={{ width: "33%" }}
                placeholder="select"
                value={duration}
                onChange={handleSelectChange}
                bsSize="sm"
              >
                <option value="1">One day</option>
                <option value="3">Three days</option>
                <option value="7">One week</option>
                <option value="30">One month</option>
                <option value="90">Three months</option>
                <option value="365">One year</option>
              </Input>
            </FormGroup>
          </CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            {subtitle}
          </CardSubtitle>
        </CardHeader>
        <CardBody style={{ textAlign: "left", height: 500, overflowY: "auto" }}>
          {cardBody()}
        </CardBody>
      </Card>
    );
  }
}

export default VolumeVariations;
