import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../ducks/search";

export const getDirectories = async (
  dispatch,
  auth,
  indexingDate,
  path,
  volumeId,
  sort = "size",
  direction = "desc",
  page = 1,
  size = 500,
  showHiddenDirectories = false
) => {
  const url = "/directories";

  const queryParams = {
    path,
    showHiddenDirectories,
    page,
    size,
    sort: `${sort},${direction}`,
    indexingDate,
  };

  if (volumeId) {
    queryParams.volumeId = volumeId;
  }

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const searchDirectories = async (
  dispatch,
  auth,
  indexingDate,
  search = "",
  page = 1,
  size = 25,
  filters = {},
  sort = "size",
  direction = "desc"
) => {
  const url = "/directories/search";
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    search,
    page,
    size,
    sort: `${sort},${direction}`,
    indexingDate,
  };

  const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, {
    ...queryParams,
    ...searchParams,
  });

  return result;
};

export const getSameDirectoryInformationAcrossTime = async (
  dispatch,
  auth,
  volumeId,
  indexingDate,
  path = "/",
  page = 1,
  size = 10,
  sort = "indexingDate",
  direction = "desc"
) => {
  const url = "/directories/details";
  const queryParams = {
    path,
    volumeId,
    page,
    size,
    sort: `${sort},${direction}`,
    indexingDate,
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const getFoldersByHash = async (
  dispatch,
  auth,
  indexingDate,
  hash,
  page = 1,
  size = 5,
  sort = "name",
  direction = "asc"
) => {
  const url = `/directories/hash/${hash}?page=${page}&size=${size}&sort=${sort},${direction}&indexingDate=${indexingDate}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const getDeltaFolders = async (
  dispatch,
  auth,
  indexingDate,
  previousIndexingDate,
  path,
  volumeId,
  page = 1,
  size = 500,
  sort = "size",
  direction = "desc",
  showHiddenDirectories = false
) => {
  const url = `/v1/directories/snapshots/${previousIndexingDate}`;
  const queryParams = {
    path,
    volumeId,
    showHiddenDirectories,
    page,
    size,
    sort,
    direction,
    indexingDate,
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const getSameDirectoryInformationAcrossTimeV1 = async (
  dispatch,
  auth,
  volumeId,
  path = "/",
  from,
  to
) => {
  const url = "/v1/directories/details";
  const queryParams = {
    path,
    volumeId,
    from,
    to,
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const getDirectoriesByPath = async (
  dispatch,
  auth,
  indexingDate,
  volumeIds,
  path = "/",
  page = 1,
  size = 500,
  sort = "size",
  direction = "desc"
) => {
  const url = "/v1/directories/paths";
  const queryParams = {
    path,
    volumeIds,
    page,
    size,
    sort,
    direction,
    indexingDate,
  };

  return await fetchWithAuthV2(dispatch, url, auth.accessToken, queryParams);
};
