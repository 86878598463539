import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

export default function OverlayBundle(props) {
  const { children, message } = props;
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          backgroundColor: "gray"
        }}
      >
        <div
          style={{
            color: "white",
            display: "tableCell",
            position: "absolute",
            top: "50%",
            zIndex: 2,
            transform: "translate(-50%,-50%)",
            left: "50%",
          }}
        >
          <a
            href="mailto:support@dataintell.io"
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
          >
            <div style={{ display: "flex" }}>
              <h5 style={{ margin: "0" }}>{message}</h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faLock}
                  style={{
                    display: "inline-block",
                    marginLeft: "8px",
                  }}
                />
              </div>
            </div>
          </a>
        </div>
        <div style={{ opacity: "0.3", pointerEvents: "none" }}>{children}</div>
      </div>
    </>
  );
}
