import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { formatSize } from "./FormatSize";

ChartJS.register(CategoryScale, BarElement, Tooltip, Legend, ChartDataLabels);

const LEGEND = { display: false };

const STORAGE_LEGEND = {
  position: "bottom",
  onClick: () => {},
};

export default function HistogramChart(props) {
  const {
    unit,
    histogramRef,
    data,
    dataCount,
    title,
    labels,
    color,
    hoverColor,
    width,
    height,
    horizontal,
    maxBarThickness,
    onClick = () => {},
    storageLegend,
    isCustomLabel = false,
    dataSizeValues = [],
    showRatio = false,
    sizeOfFiles = 0,
  } = props;

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const chartRef = histogramRef ?? useRef();
  const [index, setIndex] = useState(null);

  useEffect(() => {
    onClick(index);
  }, [index]);

  const paddingForOrientation = horizontal ? { right: 45 } : { top: 32 };
  const barChartData = {
    labels: labels.map((label, index) => {
      const percentage = ((dataSizeValues[index] / sizeOfFiles) * 100).toFixed(
        2
      );
      return showRatio ? [label, `${percentage}%`] : label;
    }),
    datasets: [
      {
        label: title,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        hoverBackgroundColor: hoverColor,
        hoverBorderColor: hoverColor,
        data: data,
        maxBarThickness: maxBarThickness,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
  };
  const tooltip = {
    callbacks: {
      label: function (context) {
        if (typeof dataCount !== "undefined") {
          return [
            "Size: " + data[context.dataIndex] + " " + unit,
            "Number of files: " +
              dataCount[context.dataIndex].toLocaleString("en-US"),
          ];
        } else {
          return ["Size: " + data[context.dataIndex] + " " + unit];
        }
      },
    },
  };
  const option = {
    onHover: (event, chartElement) => {
      if (onClick.toString() != (() => {}).toString()) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      }
    },
    indexAxis: horizontal ? "y" : "x",
    plugins: {
      legend: storageLegend ? STORAGE_LEGEND : LEGEND,
      tooltip,
      datalabels: {
        backgroundColor: function (context) {
          if (isCustomLabel) {
            return;
          }
          return context.dataset.backgroundColor;
        },
        borderRadius: 18,
        textAlign: "center",
        color: function (context) {
          return isCustomLabel ? "black" : "white";
        },
        padding: 6,
        font: {
          size: isCustomLabel ? 7 : 10,
        },
        formatter: function (value, context) {
          if (isCustomLabel) {
            return (
              data[context.dataIndex] +
              " " +
              unit +
              " \n" +
              dataCount[context.dataIndex].toLocaleString("en-US")
            );
          } else {
            return Math.round(value);
          }
        },
        listeners: {
          click: function (context) {
            setIndex(context.dataIndex);
          },
        },
      },
    },
    layout: {
      padding: paddingForOrientation,
    },
  };

  const elementIndex = (e) =>
    getElementAtEvent(chartRef.current, e).length
      ? getElementAtEvent(chartRef.current, e)[0].index
      : null;

  return (
    <Bar
      ref={chartRef}
      width={width}
      height={height}
      data={barChartData}
      options={option}
      onClick={(e) => onClick(elementIndex(e))}
    />
  );
}
