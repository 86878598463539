import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup
} from "reactstrap";
import { fetchWithAuthV2 } from "../../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../../ducks/search";
import { setAlert } from "../../actions/alert";

function EditFileTags(props) {
  const {
    fileTags,
    updateFile,
    updateTags,
    closeFunc,
    useSearchResult,
    search,
    isDirectory,
    deleteTag,
    setSelectedTagsParent = () => {},
    maxHeight = "14rem",
    isSave = false,
    isInCard = false
  } = props;

  const [selectedTags, setSelectedTags] = useState(fileTags ?? []);
  const [availableTags, setAvailableTags] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const tags = useSelector(state => state.tags);
  const searchFilters = useSelector(state => state.search.filters);
  const indexingDate = useSelector(state => state.dates.activeDate);
  const accessToken = useSelector(state => state.auth.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    (function () {
      setTimeout(() => setIsFocused(true), 100);
    }());
  }, []);

  useEffect(() => {
    if (!tags.isFetching) {
      setAvailableTags(tags.items);
    }
  }, [tags]);

  useEffect(() => {
    setSelectedTagsParent([ ...selectedTags ]);
  }, [selectedTags.length]);

  const applyTagsToSearchResults = async () => {
    const searchParams = getSearchRequestParamsV2(searchFilters);

    const queryParams = {
      search,
      indexingDate,
      isDirectory,
      deleteTag,
      tagToApply: selectedTags
    };

    const res = await fetchWithAuthV2(
      dispatch,
      "/v1/files/search/tags",
      accessToken,
      { ...queryParams, ...searchParams },
      "POST"
    ).then(res => {
      dispatch(setAlert(
        <>
          The job to {deleteTag ? "remove" : "apply"} the tags {deleteTag ? "from" : "to"} the search results is now running.
          It could take a couple of minutes to complete the job.
        </>,
        "success"
      ));
    }).catch(err => {
      dispatch(setAlert(err.message, "danger"));
    });
  };

  // Adds "enter" key functionnality to tag search & toggles tag selection if the tag name exists
  const toggleInputtedTag = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      if (!deleteTag && "" !== inputTag && !availableTags.some(t => t === inputTag)) {
        setAvailableTags([...availableTags, inputTag]);
        setSelectedTags([...selectedTags, inputTag]);
      } else if ("" !== inputTag && availableTags.some(t => t === inputTag)) {
        const inputtedIndex = selectedTags.indexOf(inputTag);
        if (inputtedIndex !== -1) {
          selectedTags.splice(inputtedIndex, 1);
          setSelectedTags(selectedTags);
        } else {
          setSelectedTags([...selectedTags, inputTag]);
        }
      }
      setInputTag("");
    }
  }

  const filterOrAddInputGroup = () => {
    const inputTextPlaceholder = "Filter " + (deleteTag ? "" : "or add new ") + "tag...";
    return (
      <InputGroup className="mb-1" size="sm">
        {
          isFocused &&
          <Input
            type="text"
            value={inputTag}
            placeholder={inputTextPlaceholder}
            onChange={e => setInputTag(e.target.value)}
            autoFocus
            onKeyDown={toggleInputtedTag}
          />
        }
        {
          !deleteTag &&
          <Button
            color="success"
            onClick={() => {
              if ("" !== inputTag && !availableTags.some(t => t === inputTag)) {
                setAvailableTags([...availableTags, inputTag]);
                setSelectedTags([...selectedTags, inputTag]);
              }
              setInputTag("");
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        }
      </InputGroup>
    );
  };

  const displayTags = (tag) => {
    return (
      <FormGroup key={tag} check>
        <Label check>
          <Input
            type="checkbox"
            onChange={() => {
              undefined === selectedTags.find(t => t === tag) ?
                setSelectedTags([...selectedTags, tag]) :
                setSelectedTags(selectedTags.filter(t => t !== tag));
            }}
            checked={selectedTags.some(t => t === tag)}
          />
          {tag}
        </Label>
      </FormGroup>
    )
  }

  const getConfirmActionButtonText = () => {
    if (isSave) {
      return "Save";
    } else if (deleteTag) {
      return "Remove";
    }
    return "Add";
  };

  const confirmButton = () => {
    const buttonColor = deleteTag ? "danger" : "primary";
    const buttonText = getConfirmActionButtonText();
    const alertText = (deleteTag ? "Removing" : "Applying") + " the tags to the selected " + (isDirectory ? "folders" : "files");
    return (
      <Button
        className="ms-1"
        color={buttonColor}
        size="sm"
        onClick={() => {
          if (useSearchResult) {
            applyTagsToSearchResults();
          } else {
            dispatch(setAlert(alertText, "success", 2500));
            updateFile(selectedTags);
          }
          updateTags(availableTags);
          closeFunc();
        }}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <div style={isInCard ? { height: "100%", paddingBottom: "50px" } : {}}>
      <Form onSubmit={e => { e.preventDefault(); }} style={{ maxHeight: `${isInCard ? "100%": maxHeight}`, overflowY: "auto" }}>
        {filterOrAddInputGroup()}
        <FormGroup>
          {
            selectedTags.length ? selectedTags.filter(tag => tag.toLowerCase().includes(inputTag.toLowerCase())).sort().map(tag => {
              return displayTags(tag);
            }) : null
          }
          {
            availableTags.length ? availableTags.filter(tag => tag.toLowerCase().includes(inputTag.toLowerCase())).sort().map(tag => {
              if (selectedTags.some(t => t === tag)) return null;
              return displayTags(tag);
            }) : null
          }
        </FormGroup>
      </Form>
      {closeFunc &&
        <div style={isInCard ? { height: "50px" } : {}}>
          <div className="border-bottom pt-1" />
          <div className="d-flex pt-2">
            <Button
              className="ms-auto"
              color="secondary"
              size="sm"
              onClick={() => closeFunc()}
            >
              Cancel
            </Button>
            {confirmButton()}
          </div>
        </div>
      }
    </div>
  );
}

export default EditFileTags;
