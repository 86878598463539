import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";

export default function FileDetailsTrigger(props) {
  const { setShownFileDetails, file } = props;

  return (
    <>
      <FontAwesomeIcon
        id="shownFileDetails"
        icon={faInfoCircle}
        onClick={() => setShownFileDetails(file)}
      />
      <UncontrolledTooltip target="shownFileDetails" placement="auto">
        Show {file.isDirectory ? "folder" : "file"} details
      </UncontrolledTooltip>
    </>
  );
}