import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import UpdateIconikConfigurationModal from "./UpdateIconikConfigurationModal";
import ChangeIconikSecretModal from "./ChangeIconikSecretModal";

export default function IconikConfiguration() {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isChangeSecretModalOpen, setIsChangeSecretModalOpen] = useState(false);

  const configurations = useSelector(({ configurations }) => configurations);

  return (
    <div style={{ maxWidth: "800px" }}>
      <Card style={{ marginBottom: "1rem" }}>
        <CardHeader>
          <CardTitle tag="h6">
            <span>Configurations</span>
            <div className="float-end">
              <Button
                className="me-2"
                onClick={() => setIsChangeSecretModalOpen(true)}
                color="warning"
                id="changeIconikSecretButton"
              >
                <FontAwesomeIcon icon={faKey} />
              </Button>
              <UncontrolledTooltip target="changeIconikSecretButton">
                Change the Iconik application token
              </UncontrolledTooltip>
              <Button
                onClick={() => setIsUpdateModalOpen(true)}
                id="updateIconikConfigurationButton"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <UncontrolledTooltip target="updateIconikConfigurationButton">
                Update the Iconik configurations
              </UncontrolledTooltip>
            </div>
          </CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            Set your basic configuration for Iconik.
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          {configurations.iconikAppId.value.length > 0 ? (
            <>
              <p>
                <strong>Application ID:</strong> {configurations.iconikAppId.value}
              </p>
              <span>
                <strong>Application Token:</strong> •••••
              </span>
            </>
          ) : (
            <span>Iconik integration is not configured properly.</span>
          )}
        </CardBody>
      </Card>
      <UpdateIconikConfigurationModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
      />
      <ChangeIconikSecretModal
        isOpen={isChangeSecretModalOpen}
        setIsOpen={setIsChangeSecretModalOpen}
      />
    </div>
  );
}