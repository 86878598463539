import { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { selectDates, fetchLastAccess } from "../actions";
import { Row, Col } from "reactstrap";

import CrawlDuration from "./sections/CrawDuration";
import VolumeSize from "./sections/VolumeSize";
import VolumeVariations from "./sections/VolumeVariations";
import ProjectVariations from "./sections/ProjectVariations";
import Storage from "./sections/Storage";
import SingleStatistic from "./sections/SingleStatistic";
import HasBundle from "../validation/HasBundle";
import { ARCHIWARE_BUNDLE } from "../constants/bundles";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {
  const {
    lastAccess,
    activeDate,
    volumeList,
    storageList,
    isFetchingVolumes,
    dispatch,
    dates
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    setDates();
  }, []);

  useEffect(() => {
    if (dates.length === 0) {
      setDates();
    } else {
      dispatch(fetchLastAccess(dates[0]));
      dispatch(fetchLastAccess(dates[1]));
      dispatch(fetchLastAccess(dates[2]));
    }
  }, [dispatch, dates]);

  const setDates = () => {
    var threeMonths = new Date();
    threeMonths.setMonth(threeMonths.getMonth() - 3);

    var sixMonth = new Date();
    sixMonth.setMonth(sixMonth.getMonth() - 6);

    var nineMonths = new Date();
    nineMonths.setMonth(nineMonths.getMonth() - 9);

    var dateList = [
      moment(threeMonths).format("YYYY-MM-DD"),
      moment(sixMonth).format("YYYY-MM-DD"),
      moment(nineMonths).format("YYYY-MM-DD"),
    ];
    dispatch(selectDates(dateList));
  };

  const handlePathChange = (path) => {
    navigate(path);
  };

  const getStatistics = (activeDate, lastAccess) => {
    return (
      <>
        <SingleStatistic
          title={"Cold Data - 3 Months"}
          subtitle={"Files not accessed during the last 3 months"}
          link={`/search?lastAccessBefore=${moment(activeDate)
            .subtract(3, "months")
            .format("YYYY-MM-DD")}`}
          size={lastAccess[0].size}
          isFetching={lastAccess[0].isFetching}
        />
        <SingleStatistic
          title={"Cold Data - 6 Months"}
          subtitle={"Files not accessed during the last 6 months"}
          link={`/search?lastAccessBefore=${moment(activeDate)
            .subtract(6, "months")
            .format("YYYY-MM-DD")}`}
          size={lastAccess[1].size}
          isFetching={lastAccess[1].isFetching}
        />
        <SingleStatistic
          title={"Cold Data - 9 Months"}
          subtitle={"Files not accessed during the last 9 months"}
          link={`/search?lastAccessBefore=${moment(activeDate)
            .subtract(9, "months")
            .format("YYYY-MM-DD")}`}
          size={lastAccess[2].size}
          isFetching={lastAccess[2].isFetching}
        />
      </>
    );
  };

  const archiveList = storageList.filter(
    (storage) => storage.type === "Archives" || storage.type === "Archiware" 
  );

  const cloudList = storageList.filter(
    (storage) => storage.type === "AWS-S3"
  );

  return (
    <>
      <h1 className="page-header">Dashboard</h1>

      <Row className="mb-3">
        <Col sm={4}>
          <VolumeVariations handlePathChange={handlePathChange} />
        </Col>
        <Col sm={4}>
          <ProjectVariations handlePathChange={handlePathChange} />
        </Col>
        <Col sm={4}>
          {getStatistics(activeDate, lastAccess)}
        </Col>
      </Row>
      <VolumeSize
        handlePathChange={handlePathChange}
        volumes={volumeList}
        isFetching={isFetchingVolumes}
      />
      <Row>
        {cloudList.length > 0 && (
          <Col md="12" lg="8">
            <Storage
              title="Cloud Storage Sizes"
              subTitle="Used space for all Cloud storage"
              storageList={cloudList}
              handlePathChange={handlePathChange}
            />
          </Col>
        )}
        <Col md="12" lg="4">
          <CrawlDuration />
        </Col>

        {archiveList.length > 0 ? (
          <HasBundle requiredBundle={ARCHIWARE_BUNDLE} hasOverlay={false}>
            <Col md="12" lg="8">
              <Storage
                title="Archiware P5 Index Sizes"
                subTitle="Used space for all Archiware P5 indexes"
                storageList={archiveList}
                handlePathChange={handlePathChange}
              />
            </Col>
          </HasBundle>
        ) : null}
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  const { dashboard, dateStatistics, dates } = state;

  const dashboardDates = dashboard.dates;
  const volumeList = dashboard.volumeList;
  const storageList = dashboard.bucketList;
  const isFetchingVolumes = dashboard.isFetching;
  const lastAccess = [
    dateStatistics.lastAccessByDate[dashboardDates[0]] || {},
    dateStatistics.lastAccessByDate[dashboardDates[1]] || {},
    dateStatistics.lastAccessByDate[dashboardDates[2]] || {},
  ];
  const { activeDate } = dates;

  return {
    lastAccess,
    dates: dashboardDates,
    activeDate,
    volumeList,
    storageList,
    isFetchingVolumes,
  };
}

export default connect(mapStateToProps)(Dashboard);
