import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../ducks/search";

export const moveData = async (
  dispatch,
  auth,
  sodaTransfer
) => {
  const url = `/v1/soda/jobs`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(sodaTransfer)
  );
};

export const moveDataFromSearchResult = async (
  dispatch,
  auth,
  sodaTransfer,
  indexingDate,
  search,
  isDirectory,
  filters = {}
) => {
  const url = "/v1/soda/jobs/search";
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    search,
    indexingDate,
    isDirectory
  };
  
  return await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    { ...queryParams, ...searchParams },
    "POST",
    JSON.stringify(sodaTransfer)
  );
};

export const getSodaVolumeMappings = async (
  dispatch,
  auth,
  page = 1,
  sort = "id",
  direction = "asc",
  size = 200
) => {
  const url = `/v1/soda/volumeMappings?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};

export const createSodaVolumeMapping = async (
  dispatch,
  auth,
  sodaVolumeMapping
) => {
  return await fetchWithAuth(
    dispatch,
    "/v1/soda/volumeMappings",
    auth.accessToken,
    "POST",
    JSON.stringify(sodaVolumeMapping)
  );
};

export const updateSodaVolumeMapping = async (
  dispatch,
  auth,
  id,
  sodaVolumeMapping
) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/soda/volumeMappings/${id}`,
    auth.accessToken,
    "PUT",
    JSON.stringify(sodaVolumeMapping)
  );
};

export const deleteSodaVolumeMapping = async (dispatch, auth, id) => {
  await fetchWithAuth(
    dispatch,
    `/v1/soda/volumeMappings/${id}`,
    auth.accessToken,
    "DELETE"
  );
};

export const getSodaStorages = async (dispatch, auth, size = 500) => {
  const url = `/v1/soda/storages?size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};
