import React, { Component } from "react";
import { connect } from "react-redux";

import queryString from "query-string";

class Error extends Component {
  render() {
    // const { activePath } = this.props;

    var parsed = queryString.parse(this.props.location.search);

    return (
      <>
        <h1 className="page-header">Error</h1>

        <h2>{parsed.message}</h2>
        <h2>Please contact Ordigraphe</h2>
        <h2>
          <a
            href={`mailto:support@ordigraphe.com?Subject=${parsed.message}`}
            target="_top"
          >
            support@ordigraphe.com
          </a>
        </h2>
      </>
    );
  }
}

function mapStateToProps(state) {
  // const { fileManagement, activeIndex } = state
  // const activePath = fileManagement.activePath;
  // const index = activeIndex.index;

  return {};
}

export default connect(mapStateToProps)(Error);
