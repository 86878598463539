import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import FormatSize from "../../components/FormatSize";

const VolumeInsightCard = ({
  volumeId,
  unusedSixMonthsDate,
  unusedSixMonthsSize,
  duplicateSize,
  unusedOneYearDate,
  unusedOneYearSize,
  volumeType,
}) => {
  const navigate = useNavigate();

  const COLD_DATA_LABEL = "Cold Data";

  return (
    <Card className="mb-3" style={{ height: "100%" }}>
      <CardHeader tag="h5">Insights</CardHeader>
      <CardBody>
        <div
          style={{
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "140px auto",
          }}
        >
          <div>Duplicate Data:</div>
          <div>
            <strong>
              <span
                style={{ color: "#007bff", cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `/search?volumeId=${volumeId}&onlyDuplicates=true`
                  )
                }
              >
                <FormatSize>{duplicateSize}</FormatSize>
              </span>
            </strong>
          </div>
          {!["Archives", "Archiware", "AWS-S3"].includes(volumeType) && (
            <>
              <div>{COLD_DATA_LABEL} - 6 Months:</div>
              <div>
                <strong>
                  <span
                    style={{ color: "#007bff", cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/search?lastAccessBefore=${unusedSixMonthsDate}&volumeId=${volumeId}`
                      )
                    }
                  >
                    <FormatSize>{unusedSixMonthsSize}</FormatSize>
                  </span>
                </strong>
              </div>
              <div>{COLD_DATA_LABEL} - 1 Year:</div>
              <div>
                <strong>
                  <span
                    style={{ color: "#007bff", cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/search?lastAccessBefore=${unusedOneYearDate}&volumeId=${volumeId}`
                      )
                    }
                  >
                    <FormatSize>{unusedOneYearSize}</FormatSize>
                  </span>
                </strong>
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default VolumeInsightCard;
