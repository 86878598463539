import { useEffect, useState } from "react";

import { Provider } from "react-redux";
import { store } from "../configureStore";

import { receiveAccessToken } from "../ducks/login";
import AppRouter from "./AppRouter";
import { getLoginConfigurations } from "../api/auth";
import { receiveLoginConfigurations } from "../ducks/configurations";
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { setAlert } from "../actions/alert";
import { Security } from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";

function RenderedComponentWithLogin({ msalInstance, oktaLoginConfigurations }) {
  const baseComponent = (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );

  const hasEntraId = !!msalInstance;
  const hasOkta = !!oktaLoginConfigurations;

  const oktaAuth = hasOkta && new OktaAuth({
    issuer: `https://${oktaLoginConfigurations.domain}/oauth2/default`,
    clientId: oktaLoginConfigurations.clientId,
    redirectUri: oktaLoginConfigurations.redirectUri,
    postLogoutRedirectUri: oktaLoginConfigurations.redirectUri
  });
  
  if (!hasEntraId && !hasOkta) {
    return baseComponent;
  } else if (hasEntraId && !hasOkta) {
    return (
      <MsalProvider instance={msalInstance}>
        {baseComponent}
      </MsalProvider>
    );
  } else if (!hasEntraId && hasOkta) {
    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={() => {}}
      >
        {baseComponent}
      </Security>
    );
  } else {
    return (
      <MsalProvider instance={msalInstance}>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={() => {}}
        >
          {baseComponent}
        </Security>
      </MsalProvider>
    );
  }
}

function Root() {
  const [msalInstance, setMsalInstance] = useState(null);
  const [oktaLoginConfigurations, setOktaLoginConfigurations] = useState(null);
  const [validatingSSO, setValidatingSSO] = useState(true);

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      store.dispatch(receiveAccessToken(accessToken));
    }

    getLoginConfigurations()
      .then((res) => {
        res.json().then((json) => {
          store.dispatch(receiveLoginConfigurations(json));

          if (json.entraIdLoginConfigurations.loginActive) {
            getMsalInstance(json)
              .then(res => {
                if (json.oktaLoginConfigurations.loginActive) {
                  setOktaLoginConfigurations(json.oktaLoginConfigurations);
                }

                setValidatingSSO(false);
              })
              .catch(err => {
                console.error(err);
                store.dispatch(setAlert("Error while getting login session for Microsoft Entra ID.", "danger", 8000));

                if (json.oktaLoginConfigurations.loginActive) {
                  setOktaLoginConfigurations(json.oktaLoginConfigurations);
                }
                
                setValidatingSSO(false);
              });
          } else if (json.oktaLoginConfigurations.loginActive) {
            setOktaLoginConfigurations(json.oktaLoginConfigurations);
            setValidatingSSO(false);
          } else {
            setValidatingSSO(false);
          }
        });
      })
      .catch((err) => {
        console.error("Error while getting login configurations, using default behaviour");
        setValidatingSSO(false);
      });
  }, []);

  const getMsalInstance = async (json) => {
    const msalConfig = {
      auth: {
        clientId: json.entraIdLoginConfigurations.clientId,
        authority: `https://login.microsoftonline.com/${json.entraIdLoginConfigurations.tenantId}`,
        redirectUri: json.entraIdLoginConfigurations.redirectUri
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
      }    
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    msalInstance.initialize().then(() => {
      // Default to using the first account if no account is active on page load
      if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }
    
      msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      });

      setMsalInstance(msalInstance);
    });
  };

  return (
    <>
      {validatingSSO ? null : 
        <RenderedComponentWithLogin
          msalInstance={msalInstance}
          oktaLoginConfigurations={oktaLoginConfigurations}
        />
      }
    </>
  );
}

export default Root;