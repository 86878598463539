import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";

const DATE_LABELS_AND_DESCRIPTIONS = {
  lastModified: {
    label: "Last Modified Date (Recursive)",
    description:
      "This represents the most recent modification date of any file within the folder, including those in its subfolders.",
  },
  lastAccess: {
    label: "Last Access Date (Recursive)",
    description:
      "This represents the most recent access date of any file within the folder, including those in its subfolders.",
  },
  lastModifiedFolder: {
    label: "Last Modified Date (Folder)",
    description:
      "This refers to the last time the folder itself was modified, as provided by the file system.",
  },
  lastAccessFolder: {
    label: "Last Access Date (Folder)",
    description:
      "This refers to the last time the folder itself was accessed, as provided by the file system.",
  },
};

export default function DateLabelWithTooltip(props) {
  const { dateLabel } = props;

  return (
    <>
      <span>
        {DATE_LABELS_AND_DESCRIPTIONS[dateLabel].label}
        <FontAwesomeIcon
          id={`label-${dateLabel}`}
          icon={faQuestionCircle}
          className="mx-1"
        />
      </span>
      <UncontrolledTooltip target={`label-${dateLabel}`} placement="auto">
        {DATE_LABELS_AND_DESCRIPTIONS[dateLabel].description}
      </UncontrolledTooltip>
    </>
  );
}
