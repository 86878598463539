import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";

// === Actions ===
// READ
export const REQUEST_TAGS = "REQUEST_TAGS";
export const RECEIVE_TAGS = "RECEIVE_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";

// === Reducers ===
export default function tags(state = { items: [], isFetching: false }, action) {
  switch (action.type) {
    case REQUEST_TAGS:
      return Object.assign({}, state, {
        isFetching: true,
        items: []
      });
    case RECEIVE_TAGS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.tags
      });
    case UPDATE_TAGS:
      return Object.assign({}, state, {
        items: action.tags
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestTags() {
  return {
    type: REQUEST_TAGS
  };
}

function receiveTags(tags) {
  return {
    type: RECEIVE_TAGS,
    tags
  };
}

export function updateTags(tags) {
  return {
    type: UPDATE_TAGS,
    tags
  };
}

// === Side Effects ===
export function fetchTags(size = 1000) {
  return async (dispatch, getState) => {
    dispatch(requestTags());
    try {
      const url = `/v1/files/tags?size=${size}&indexingDate=${getState().dates.activeDate}`;
      const tags = await fetchWithAuth(dispatch, url, getState().auth.accessToken);
      dispatch(receiveTags(tags));
    } catch (err) {
      const message = err.message || "Unable to fetch tags";
      dispatch(setAlert(message, "danger"));
    }
  };
}
