import React from "react";
import { connect } from "react-redux";
import { signOut } from "../ducks/login";
import { setDate } from "../actions";
import Header from "../components/Header";
import { ACTIVE_THEME } from "../components/Theme";
import { useLocation, useNavigate } from "react-router-dom";

function HeaderContainer(props) {
  const {
    name,
    dateList,
    activeDate,
    dispatch
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Header
      activePath={location.pathname}
      name={name}
      searchFiles={search => navigate(`/search?search=${search}`)}
      signOut={() => dispatch(signOut())}
      dates={dateList}
      handleChange={event => dispatch(setDate(event.target.value))}
      activeDate={activeDate}
      backgroundColor={ACTIVE_THEME.color}
      logo={ACTIVE_THEME.logo}
    />
  );
}

function mapStateToProps(state) {
  const { dates, error, auth } = state;
  const { name } = auth;
  const dateList = dates.items;
  const activeDate = dates.activeDate;
  return { name, dateList, error, activeDate };
}

export default connect(mapStateToProps)(HeaderContainer);
