import { FormGroup, Input, Label } from "reactstrap";
import SubSection from "../../settings/components/SubSection";
import { useDispatch, useSelector } from "react-redux";
import { setIsNameCondensed, setIsPathCondensed, setSizeBaseValue } from "../../ducks/userSettings";
import { useEffect, useState } from "react";

export default function UserSettings() {
  const [isShowHiddenFolders, setIsShowHiddenFolders] = useState(false);
  
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const isNameCondensed = useSelector(({ userSettings }) => userSettings.isNameCondensed);
  const isPathCondensed = useSelector(({ userSettings }) => userSettings.isPathCondensed);
  const dispatch = useDispatch();

  useEffect(() => {
    const showHiddenFolders = window.localStorage.getItem("showHiddenFoldersFileManagement");
    if (showHiddenFolders) {
      setIsShowHiddenFolders(showHiddenFolders === "true");
    }
  }, []);

  return (
    <SubSection
      title="User Settings"
      subTitle={"Note that right now, these settings are registered locally, so one user can change the settings for everyone else. This will change in a future update."}
      isOpen={true}
    >
      <hr />
      <h5>Unit of Measurement</h5>
      <p>Binary or decimal representation for the values like size.</p>
      <FormGroup check>
        <Label check >
          <Input
            type="radio"
            name="measurement"
            checked={baseSize === "2"}
            onChange={() => dispatch(setSizeBaseValue("2"))}
          />
          Binary
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name="measurement"
            checked={baseSize === "10"}
            onChange={() => dispatch(setSizeBaseValue("10"))}
          />
          Decimal
        </Label>
      </FormGroup>
      <hr />
      <h5>Toggle Hidden Directories Visibility</h5>
      <p>This will either show or hide hidden directories in the File Management section.</p>
      <FormGroup switch className="mx-0">
        <Input
          type="switch"
          id="showHiddenDirectories"
          name="showHiddenDirectories"
          onChange={() => {
            window.localStorage.setItem("showHiddenFoldersFileManagement", !isShowHiddenFolders);
            setIsShowHiddenFolders(prev => !prev);
          }}
          checked={isShowHiddenFolders}
        />
        <Label for="showHiddenDirectories">
          Show Hidden Directories
        </Label>
      </FormGroup>
      <hr />
      <h5>File and Folder Name Display</h5>
      <p>Choose how to display file and folder names in various tables.</p>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name="isNameCondensed"
            checked={isNameCondensed === "false"}
            onChange={() => dispatch(setIsNameCondensed("false"))}
          />
          Full View
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name="isNameCondensed"
            checked={isNameCondensed === "true"}
            onChange={() => dispatch(setIsNameCondensed("true"))}
          />
          Condensed View
        </Label>
      </FormGroup>
      <hr />
      <h5>File and Folder Path Display</h5>
      <p>Choose how to display file and folder paths in various tables.</p>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name="isPathCondensed"
            checked={isPathCondensed === "false"}
            onChange={() => dispatch(setIsPathCondensed("false"))}
          />
          Full View
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name="isPathCondensed"
            checked={isPathCondensed === "true"}
            onChange={() => dispatch(setIsPathCondensed("true"))}
          />
          Condensed View
        </Label>
      </FormGroup>
      <hr />
    </SubSection>
  );
}