import { faBan, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "reactstrap";

export default function JobStatusColorScheme(props) {
  const { status, action, isRelaunchable = false, relaunch } = props;

  const handleBadgeAction = (icon, iconColor, badgeColor, actionButton) => {
    return (
      <Badge
        color={badgeColor}
        style={{
          padding: 0,
          paddingLeft: "3px",
        }}
      >
        <span className="d-flex align-items-center">
          {status} |
          <Button
            className="d-flex align-items-center"
            style={{
              padding: "3px",
              borderBottomLeftRadius: "0rem",
              borderTopLeftRadius: "0rem",
            }}
            color={badgeColor}
            onClick={actionButton}
          >
            <FontAwesomeIcon
              color={iconColor}
              icon={icon}
              style={{ fontSize: "60%" }}
            ></FontAwesomeIcon>
          </Button>
        </span>
      </Badge>
    );
  };

  const handleStatusColor = () => {
    switch (status) {
      case "NEW":
      case "AVAILABLE":
        // Default color is "secondary"
        return <Badge>{status}</Badge>;

      case "WAITING":
        return handleBadgeAction(faBan, "red", "warning", action);
      case "WARNING":
        return isRelaunchable ? (
          handleBadgeAction(faRefresh, "black", "warning", relaunch)
        ) : (
          <Badge color="warning">{status}</Badge>
        );

      case "RUNNING":
        return <Badge color="primary">{status}</Badge>;

      case "SUCCESS":
        return <Badge color="success">{status}</Badge>;

      case "SCANNER_ERROR":
      case "FAILURE":
      case "CANCELLED":
        return isRelaunchable ? (
          handleBadgeAction(faRefresh, "white", "danger", relaunch)
        ) : (
          <Badge color="danger">{status}</Badge>
        );

      default:
        return <Badge>{status}</Badge>;
    }
  };

  return handleStatusColor();
}
