import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SodaActionReview from "./SodaActionReview";

export default function SodaManualReview(props) {
  const {
    sodaActionObj,
    selectedFolders,
    setDisableForwardActionButton,
    sodaVolumeMappings,
    isDirectory
  } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [totalNumberOfFolders, setTotalNumberOfFolders] = useState(0);
  const [validNumberOfFolders, setValidNumberOfFolders] = useState(0);
  const [totalSizeOfFolders, setTotalSizeOfFolders] = useState(0);
  const [validSizeOfFolders, setValidSizeOfFolders] = useState(0);
  const [activeVolumes, setActiveVolumes] = useState([]);

  const volumes = useSelector(({ volumes }) => volumes.items);

  useEffect(() => {
    setIsFetching(true);
    setDisableForwardActionButton(true);
    calculateReviewValues();
  }, [sodaVolumeMappings]);

  const calculateReviewValues = () => {
    let validVolumes = {};
    sodaVolumeMappings.forEach((volume) => {
      const foundVolume = volumes.find((v) => v.id === volume.volumeId);

      if (foundVolume) {
        validVolumes[volume.volumeId] = foundVolume.name;
      }
    });

    let currentValidNumberOfFolders = 0;
    let currentValidSizeOfFolders = 0;
    let activeVolume = {};

    selectedFolders.forEach((folder) => {
      if (validVolumes[folder.volumeId]) {
        currentValidNumberOfFolders++;
        currentValidSizeOfFolders += folder.size;
        activeVolume[folder.volumeId] = folder.volumeName;
      }
    });

    setTotalNumberOfFolders(selectedFolders.length);
    setValidNumberOfFolders(currentValidNumberOfFolders);
    setTotalSizeOfFolders(selectedFolders.reduce((a, b) => a + b.size, 0));
    setValidSizeOfFolders(currentValidSizeOfFolders);
    setActiveVolumes(Object.values(activeVolume));

    if (currentValidNumberOfFolders > 0) {
      setDisableForwardActionButton(false);
    }

    setIsFetching(false);
  };

  return (
    <SodaActionReview
      sodaActionObj={sodaActionObj}
      volumes={volumes}
      isFetching={isFetching}
      activeVolumes={activeVolumes}
      totalNumberOfFolders={totalNumberOfFolders}
      validNumberOfFolders={validNumberOfFolders}
      totalSizeOfFolders={totalSizeOfFolders}
      validSizeOfFolders={validSizeOfFolders}
      isDirectory={isDirectory}
    />
  );
}
