import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

ArchiwareForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  path: PropTypes.string,
  indexId: PropTypes.string,
  storagePriceList: PropTypes.array,
  serverList: PropTypes.array,
  serverId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  setIsSaveButtonEnabled: PropTypes.func
};

function ArchiwareForm(props) {
  const {
    id,
    name,
    credentials,
    archiwareCredentialsId,
    path,
    indexId,
    storagePriceList,
    storagePriceId,
    serverList,
    serverId,
    handleChange,
    errors,
    setIsSaveButtonEnabled
  } = props;

  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);
  const [
    isArchiwareCredentialsTooltipOpen,
    setIsArchiwareCredentialsTooltipOpen,
  ] = useState(false);
  const [fieldError, setFieldError] = useState({
    server: "",
    archiwareP5Credential: "",
    indexId: "",
    path: "",
    name: "",
    storagePrice: ""
  });

  useEffect(() => {
    if (fieldError.server === "" && fieldError.archiwareP5Credential === "" && fieldError.indexId === "" &&
      fieldError.path === "" && fieldError.name === "" && fieldError.storagePrice === "") {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [fieldError.server, fieldError.archiwareP5Credential, fieldError.indexId,
  fieldError.path, fieldError.name, fieldError.storagePrice]);

  const existingArchiwares = useSelector(({ archiwares }) => archiwares.items);

  const handleFieldError = (field, value) => {
    let fieldName = "";
    let reason = "";

    if (value === "") {
      reason = " can't be empty";
    }

    switch (field) {
      case "server":
        fieldName = "Server";
        break;
      case "archiwareP5Credential":
        fieldName = "Credentials";
        break;
      case "indexId":
        fieldName = "IndexId";
        break;
      case "path":
        fieldName = "Path";
        break;
      case "name":
        fieldName = "DataIntell Name";
        const nameExists = existingArchiwares.some(archiware => archiware.id !== id && archiware.name === value);
        if (nameExists) reason = " must be unique";
        break;
      case "storagePrice":
        fieldName = "Storage Type";
        break;
      default:
        break;
    }

    setFieldError(prev => ({ ...prev, [field]: reason === "" ? "" : fieldName + reason }));
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    handleChange(name, value);
    handleFieldError(name, value);
  };

  return (
    <Form>
      <FormGroup row>
        <Label for="server" sm={4}>
          Server
        </Label>
        <Col sm={8}>
          <Input
            name="server"
            type="select"
            value={serverId}
            onChange={handleInputChange}
            invalid={errors["server"] !== undefined || fieldError.server !== ""}
          >
            <option value={""}>Select server</option>
            {serverList.map((server) => {
              return (
                <option key={server.id} value={server.id}>
                  {server.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{errors["server"] || fieldError.server}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="archiwareCredentialsId" sm={4}>
          Credentials
          <FontAwesomeIcon
            id="archiwareCredentialsLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <Tooltip
            placement="right"
            isOpen={isArchiwareCredentialsTooltipOpen}
            target="archiwareCredentialsLabel"
            toggle={() =>
              setIsArchiwareCredentialsTooltipOpen(
                !isArchiwareCredentialsTooltipOpen
              )
            }
          >
            Credentials are needed for authentication. You need to set up
            credentials before creation.
          </Tooltip>
        </Label>
        <Col sm={8}>
          <Input
            name="archiwareP5Credential"
            type="select"
            value={archiwareCredentialsId}
            onChange={handleInputChange}
            invalid={errors["archiwareP5Credential"] !== undefined || fieldError.archiwareP5Credential !== ""}
          >
            <option value={""}>Select credential</option>
            {credentials.map((credential) => {
              return (
                <option key={credential.id} value={credential.id}>
                  {credential.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{errors["archiwareP5Credential"] || fieldError.archiwareP5Credential}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="indexId" sm={4}>
          Archiware Index
        </Label>
        <Col sm={8}>
          <Input
            id="indexId"
            name="indexId"
            type="text"
            placeholder="Ex: archiwareP5-indexId"
            value={indexId}
            onChange={handleInputChange}
            invalid={errors["indexId"] !== undefined || fieldError.indexId !== ""}
          />
          <FormFeedback>{errors["indexId"] || fieldError.indexId}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="path" sm={4}>
          Path
        </Label>
        <Col sm={8}>
          <Input
            id="path"
            name="path"
            type="text"
            placeholder="Ex: /path/ "
            value={path}
            onChange={handleInputChange}
            invalid={errors["path"] !== undefined || fieldError.path !== ""}
          />
          <FormFeedback>{errors["path"] || fieldError.path}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="name" sm={4}>
          DataIntell Name
          <FontAwesomeIcon
            id="nameLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <Tooltip
            placement="right"
            isOpen={isNameTooltipOpen}
            target="nameLabel"
            toggle={() => setIsNameTooltipOpen(!isNameTooltipOpen)}
          >
            User readable name for the Archiware P5
          </Tooltip>
        </Label>
        <Col sm={8}>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Ex: name"
            value={name}
            onChange={handleInputChange}
            invalid={errors["name"] !== undefined || fieldError.name !== ""}
          />
          <FormFeedback>{errors["name"] || fieldError.name}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="storagePrice" sm={4}>
          Storage Type
        </Label>
        <Col sm={8}>
          <Input
            name="storagePrice"
            type="select"
            value={storagePriceId}
            onChange={handleInputChange}
            invalid={errors["storagePrice"] !== undefined || fieldError.storagePrice !== ""}
          >
            <option value={""}>Select storage type</option>
            {storagePriceList.map((storagePrice) => {
              return (
                <option key={storagePrice.id} value={storagePrice.id}>
                  {storagePrice.description}
                </option>
              );
            })}
          </Input>
          <FormFeedback>{errors["storagePrice"] || fieldError.storagePrice}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
}

export default ArchiwareForm;
