export default function SodaLogo({
  isIconHovered = false,
  height = 20,
  color = "#4f92db",
  colorAlt = "#5daaff",
}) {
  return (
    <svg
      id="sodaLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449 568.24"
      xmlSpace="preserve"
      height={height}
    >
      <path
        d="M121.61 47.19 143 57.24c26.3 10.24 36.02 5.44 51.6-.05l46.37-22.42c13.51-7.19 13.25-18.84-2.58-24.48l-13.12-5.74c-19.99-6.87-33.53-5.7-54.87 1.74L125 24.24c-13.13 5.56-14.25 18.04-3.39 22.95zm65.73-34.13c5.43-1.97 12.06-3.85 17.9-1.67l19.25 7.94c5.56 1.98 6.63 6.16 1.16 8.09-5.48 1.93-13.07 2.8-18.64.82l-19.51-8.09c-5.57-1.98-5.62-5.1-.16-7.09zm-49.59 17.5 19.22-7.84c4.38-1.55 11.54-1.51 15.99.07l23.18 9.61c4.45 1.59 4.51 4.12.13 5.67l-18.22 7.84c-4.38 1.55-11.54 1.51-15.99-.07l-24.18-9.61c-4.46-1.58-4.51-4.12-.13-5.67zm300.83 336.97c-36.77 36.87-118.88 62.54-214.28 62.54-95.08 0-176.96-25.5-213.91-62.17C6.79 364.33 0 366.48 0 371.22v97.88c0 20.24 10.49 39.56 28.98 52.03 42.13 28.41 113.88 47.11 195.32 47.11 81.88 0 153.97-18.91 196-47.58 18.31-12.49 28.7-31.7 28.7-51.83v-98.01c0-4.75-6.83-6.89-10.42-3.29zM422.5 482.28c0 6.25-5.11 13.57-11.36 16.29-6.25 2.71-11.36-.18-11.36-6.43v-58.67c0-6.25 5.11-13.57 11.36-16.29 6.25-2.71 11.36.18 11.36 6.43v58.67zm16.08-457.46C401.81 61.69 319.7 87.36 224.3 87.36c-95.08 0-176.96-25.5-213.91-62.17C6.79 21.61 0 23.77 0 28.51v97.88c0 20.24 10.49 39.56 28.98 52.02 42.13 28.41 113.88 47.11 195.32 47.11 81.88 0 153.97-18.91 196-47.58 18.31-12.49 28.7-31.7 28.7-51.83v-98c0-4.76-6.83-6.9-10.42-3.29zM422.5 139.56c0 6.25-5.11 13.57-11.36 16.29-6.25 2.71-11.36-.18-11.36-6.43V90.75c0-6.25 5.11-13.58 11.36-16.29 6.25-2.71 11.36.18 11.36 6.43v58.67zm16.08 53.84c-36.77 36.87-118.88 62.54-214.28 62.54-95.08 0-176.96-25.5-213.91-62.17C6.79 190.2 0 192.35 0 197.09v97.88c0 20.24 10.49 39.56 28.98 52.03 42.13 28.41 113.88 47.11 195.32 47.11 81.88 0 153.97-18.91 196-47.58 18.31-12.49 28.7-31.7 28.7-51.83v-98.01c0-4.75-6.83-6.89-10.42-3.29zM422.5 308.15c0 6.25-5.11 13.57-11.36 16.29-6.25 2.71-11.36-.18-11.36-6.43v-58.67c0-6.25 5.11-13.57 11.36-16.29 6.25-2.71 11.36.18 11.36 6.43v58.67z"
        fill={isIconHovered ? color : colorAlt}
      />
    </svg>
  );
}
