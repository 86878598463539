import React, { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDatesIfNeeded } from "../actions";
import { fetchVolumeSettingsIfNeeded } from "../ducks/volumeSettings";
import { Alert } from "reactstrap";
import Header from "./Header";
import PropTypes from "prop-types";
import { getAllCrawlDates } from "../api/administration";
import { useMsal } from "@azure/msal-react";
import Authorization from "../validation/Authorization";
import { getDataIntellVersion } from "../api/wordPress";
import semver from "semver";
import { getAppInfo } from "../api/auth";
import { fetchBucketList } from "../ducks/bucket";
import { OktaContext, useOktaAuth } from "@okta/okta-react";

Interface.propTypes = {
  connected: PropTypes.bool,
  dispatch: PropTypes.func,
  app: PropTypes.object,
  children: PropTypes.node,
};

function Interface(props) {
  const { auth, connected, dispatch, app, children, dates } = props;

  const [hasNewIndex, setHasNewIndex] = useState(false);
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const { instance } = useMsal();
  
  const oktaContext = useContext(OktaContext);
  const { oktaAuth } = oktaContext ? useOktaAuth() : { oktaAuth: null };

  const type = useSelector(({ auth }) => auth.type);

  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (connected) {
      dispatch(fetchVolumeSettingsIfNeeded());
      dispatch(fetchDatesIfNeeded());
      dispatch(fetchBucketList());
    } else if (type === "OKTA") {
      oktaAuth.signOut();
    } else {
      navigate("/login");
      if (type === "ENTRAID") {
        instance.logout();
      } else {
        window.location.reload();
      }
    }
  }, [connected, dispatch]);

  useEffect(() => {
    showHasNewIndex();

    const interval = setInterval(async () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currentTimeAsStringInTimezone = Date.now().toLocaleString("en-CA", { timeZone: timezone });
      const formattedCurrentTime = new Date(Number(currentTimeAsStringInTimezone.replaceAll(",", "")));

      if (formattedCurrentTime.getHours() === 5) {
        window.location.reload();
      } else {
        showHasNewIndex();
        showIsNewVersionAvailable();
      }
    }, 3600000);

    return () => clearInterval(interval);
  }, []);

  const showHasNewIndex = async () => {
    getAllCrawlDates(dispatch, auth)
      .then(res => {
        if (res.length > 0 && dates.items.length > 0 && !dates.items.includes(res[0])) {
          setHasNewIndex(true);
        }
      })
      .catch(err => console.error("Error while getting the indexing dates", err));
  };

  const showIsNewVersionAvailable = async () => {
    getAppInfo()
      .then(json => json.json()
        .then(appInfo =>
          getDataIntellVersion()
            .then(res => {
              if (typeof res === "string") {
                setIsNewVersionAvailable(semver.gt(res, appInfo.build.version));
              } else {
                console.error("Error while getting the latest version of DataIntell from the web.");
                setIsNewVersionAvailable(false);
              }
            })
            .catch(err => {
              console.error("Error while getting the latest version of DataIntell from the web.", err);
              setIsNewVersionAvailable(false);
            })
        ).catch(err => {
          console.error("Error while getting the version of DataIntell from the application.", err);
          setIsNewVersionAvailable(false);
        })
      ).catch(err => {
        console.error("Error while getting the version of DataIntell from the application.", err);
        setIsNewVersionAvailable(false);
      });
  };

  const showChildren = () => {
    const url = location.pathname;
    if (
      url.startsWith("/settings") ||
      url.startsWith("/profile") ||
      (!dates.isFetching && dates.activeDate)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Header />
      <main role="main">
        <div style={{ marginTop: 10 }} className="container-fluid pt-3">
          {!app.isValid && (
            <Alert color="danger">
              The license is expired or invalid, you can update it{" "}
              <a
                className="text-primary"
                onClick={() => navigate("/settings")}
              >
                here
              </a>
              .
            </Alert>
          )}
          {!dates.isFetching && dates.items.length === 0 && (
            <Alert color="warning">
              There is no data indexed. You need to do a scan{" "}
              <a
                className="text-primary"
                onClick={() => navigate("/settings/data")}
              >
                here
              </a>
              .
            </Alert>
          )}
          {hasNewIndex && (
            <Alert color="warning">
              There is a new index available. Would you like to refresh the page?{" "}
              <a
                className="text-primary"
                onClick={() => window.location.reload()}
              >
                Refresh
              </a>
            </Alert>
          )}
          {isNewVersionAvailable && (
            <Authorization>
              <Alert color="warning">
                There is a new version of the application available. Update now in the{" "}
                <a
                  className="text-primary"
                  onClick={() => navigate("/settings")}
                >
                  General Settings
                </a>
                {" "}section.
              </Alert>
            </Authorization>
          )}
          {showChildren() && <span key={dates.activeDate}>{children}</span>}
        </div>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  const { auth, app, dates } = state;
  const { connected } = auth;
  return { auth, connected, app, dates };
}

export default connect(mapStateToProps)(Interface);
