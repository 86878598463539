import { fetchWithAuth } from "../actions/requestHelper";

export const createReportTemplate = async (
  dispatch,
  auth,
  reportTemplate
) => {
  const url = `/v1/reports/templates`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(reportTemplate)
  );
};

export const getReportTemplate = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/reports/templates/${id}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getAllReportTemplatesPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/reports/templates?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const updateReportTemplate = async (
  dispatch,
  auth,
  id,
  reportTemplate
) => {
  const url = `/v1/reports/templates/${id}`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(reportTemplate)
  );
};

export const deleteReportTemplate = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/reports/templates/${id}`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "DELETE"
  );
};

export const getAllReportTemplateTypes = async (
  dispatch,
  auth
) => {
  const url = "/v1/reports/templates/types";

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getAllDataSourceTypes = async (
  dispatch,
  auth
) => {
  const url = "/v1/reports/templates/dataSources/types";

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const generateReport = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/reports/templates/${id}/jobs`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST"
  );
}

export const getReportJobs = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/reports/${id}/jobs`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};