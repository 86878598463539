import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableResult from "../../components/TableResult";
import { Button } from "reactstrap";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import ScanButton from "./ScanButton";
import { scanBuckets } from "../../api/scan";
import BucketPricingWarning from "./BucketPricingWarning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import TableActions from "../../components/TableActions";
import PerformanceModeWarning from "./PerformanceModeWarning";
import Loading from "../../components/Loading";
import { getAllCredentials } from "../../api/bucket";
import { getAll } from "../../api/bucket";
import {
  fetchBucketList,
  updateBucket,
  deleteBucket,
} from "../../ducks/bucket";
import UpdateBucketFormModal from "./UpdateBucketFormModal";
import DeleteBucketFormModal from "./DeleteBucketFormModal";
BucketList.propTypes = {
  buckets: PropTypes.array,
  total: PropTypes.number,
  credentials: PropTypes.array,
  storagePriceList: PropTypes.array,
  fetchBucketsListPage: PropTypes.func,
  errors: PropTypes.object,
  formValid: PropTypes.bool,
  isFetching: PropTypes.bool,
  scan: PropTypes.func,
  scanAll: PropTypes.func,
};

function BucketList(props) {
  const {
    buckets,
    total,
    totalBucketCredentials,
    page,
    sort,
    direction,
    size,
    storagePriceList,
    errors,
    isFetching,
    openChosenWizardFromSection,
  } = props;

  const [id, setId] = useState("");
  const [credentials, setCredentials] = useState();

  const [showUpdateBucketForm, setShowUpdateBucketForm] = useState(false);
  const [showDeleteBucketForm, setShowDeleteBucketForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allBuckets, setAllBuckets] = useState([]);

  const numberOfPages = Math.ceil(total / size);
  const bucketTitles = [
    { name: "Id", sort: "id" },
    { name: "Name", sort: "description" },
    { name: "Bucket Name", sort: "name" },
    { name: "Region", sort: "region" },
    { name: "Type", sort: "type" },
    { name: "Storage Type" },
    { name: "Action" },
  ];
  useEffect(() => {
    getAllCredentials(dispatch, auth, 1, 500)
      .then((res) => setCredentials(res.elements))
      .catch((err) =>
        dispatch(
          setAlert(
            "Something went wrong while fetching the bucket credentials",
            "danger"
          )
        )
      );
  }, [totalBucketCredentials]);

  useEffect(() => {
    getAll(dispatch, auth, 1, 500)
      .then((res) => setAllBuckets(res.elements))
      .catch((err) =>
        dispatch(
          setAlert("Something went wrong while fetching the buckets", "danger")
        )
      );
  }, [total]);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  const getUpdatedBuckets = (
    updatedPage = page,
    updatedSort = sort,
    updatedDirection = direction,
    updatedSize = size
  ) => {
    dispatch(
      fetchBucketList(updatedPage, updatedSort, updatedDirection, updatedSize)
    );
  };

  const saveUpdatedBucket = (id, bucket) => {
    dispatch(updateBucket(id, bucket));
    setShowUpdateBucketForm(false);
  };

  const deleteBucketById = async (deleteId) => {
    setIsProcessing(true);
    await dispatch(deleteBucket(deleteId));
    setShowDeleteBucketForm(false);
    setIsProcessing(false);
  };

  const handleGetName = (name, type) => {
    if (type === "ORACLE_OBJECT_STORAGE") {
      return name.split(".")[1];
    }
    return name;
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === sort) {
      newDirection = direction === "asc" ? "desc" : "asc";
    }
    getUpdatedBuckets(page, newSort, newDirection, size);
  };

  const listFormated = buckets.map((bucket) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    lineObject.columnData = [
      bucket.id,
      bucket.description,
      handleGetName(bucket.name, bucket.type),
      bucket.region.length > 0 ? bucket.region : "N/A",
      bucket.type,
      bucket.storagePrice.description,
      <>
        <Button
          onClick={() => {
            setShowUpdateBucketForm(true);
            setId(bucket.id);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            setShowDeleteBucketForm(true);
            setId(bucket.id);
          }}
          color="danger"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <ScanButton
          title="Scan"
          hasCloneIndex
          volumeIds={[bucket.id]}
          volumes={allBuckets}
          scan={(volumeIds, cloneIndex) =>
            scanBuckets(dispatch, auth, volumeIds, cloneIndex)
          }
        >
          <PerformanceModeWarning />
          <p>
            Scanning a bucket can take between a couple minutes and several
            hours, depending on the number of files. Results should start
            showing in the search section after 5 minutes.
          </p>
          <BucketPricingWarning />
        </ScanButton>
      </>,
    ];

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      "",
      "",
      <>
        <Button
          onClick={() => openChosenWizardFromSection("bucket")}
          color="success"
          style={{ marginRight: 10 }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <ScanButton
          title="Scan All"
          volumeIds={allBuckets.map((bucket) => bucket.id)}
          hasCloneIndex
          volumes={allBuckets}
          scan={(volumeIds, cloneIndex) =>
            scanBuckets(dispatch, auth, volumeIds, cloneIndex)
          }
        >
          <PerformanceModeWarning />
          <p>
            Scanning all buckets can take between a couple minutes and several
            hours, depending on the number of files. Results should start
            showing in the search section after 5 minutes.
          </p>
          <BucketPricingWarning />
        </ScanButton>
      </>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h5>Buckets</h5>
      {isFetching ? (
        <Loading />
      ) : (
        <div>
          <TableActions
            page={page}
            numberOfPages={numberOfPages}
            numberOfElements={size}
            setNumberOfElements={(numberOfElements) =>
              getUpdatedBuckets(1, sort, direction, numberOfElements)
            }
            selectPage={(newPage) =>
              getUpdatedBuckets(newPage, sort, direction, size)
            }
          />
          <TableResult
            title={"Buckets"}
            data={listFormated}
            titles={bucketTitles}
            sort={sort}
            direction={direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      )}

      <UpdateBucketFormModal
        buckets={buckets}
        credentials={credentials}
        showUpdateBucketForm={showUpdateBucketForm}
        save={saveUpdatedBucket}
        closeModal={() => {
          setShowUpdateBucketForm(false);
          setId("");
        }}
        id={id}
        storagePriceList={storagePriceList}
        errors={errors}
      />

      <DeleteBucketFormModal
        showDeleteBucketForm={showDeleteBucketForm}
        isProcessing={isProcessing}
        deleteBucket={deleteBucketById}
        closeModal={() => setShowDeleteBucketForm(false)}
        id={id}
        buckets={buckets}
      />
    </>
  );
}

export default BucketList;
