import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Spinner } from "reactstrap";
import GeneralError from "../GeneralError";
import { getArchiwareP5ArchivePlans } from "../../api/archiware";
import Select from "react-select";
import SelectLocation from "./SelectLocation";

export default function SelectArchiwarePlanStep({
  userInputObj,
  setDisableForwardActionButton,
  archiwarePlans,
  setArchiwarePlans,
  setPlan,
  parseFileDeletionMethod,
  selectedFiles,
  fileTypesToOnlyInclude,
  isDirectory,
  allServers,
  location,
  setLocation,
  isSpecifyLocationChecked,
  setIsSpecifyLocationChecked,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  const [isFetching, setIsFetching] = useState(
    archiwarePlans.length ? false : true
  );
  const [errorObj, setErrorObj] = useState({
    error: false,
    apiErrorMessage: null,
  });
  const [serverOptions, setServerOptions] = useState([]);
  const [
    hasAnythingElseThanFileTypeToInclude,
    setHasAnythingElseThanFileTypeToInclude,
  ] = useState(false);

  const volumes = useSelector(({ volumes }) => volumes.items);

  useEffect(() => {
    setDisableForwardActionButton(false);
    checkIfSelectionIncludesOtherThanOnPremises();

    if (archiwarePlans.length === 0) {
      setDisableForwardActionButton(true);
      getArchivePlans();
    }
  }, []);

  useEffect(() => {
    getServerOptions();
  }, [archiwarePlans]);

  const fileOrFolder = isDirectory ? "folders" : "files";

  const checkIfSelectionIncludesOtherThanOnPremises = () => {
    const volumeIdsHashMap = {};

    volumes
      .filter((volume) => fileTypesToOnlyInclude.includes(volume.type))
      .forEach((volume) => {
        volumeIdsHashMap[volume.id] = volume.id;
      });

    setHasAnythingElseThanFileTypeToInclude(
      selectedFiles.some((file) => !volumeIdsHashMap[file.volumeId])
    );
  };

  const getArchivePlans = async () => {
    try {
      const archiwarePlans = await getArchiwareP5ArchivePlans(dispatch, auth);
      if (archiwarePlans.length) {
        setArchiwarePlans(archiwarePlans);
        setPlan(archiwarePlans);
        setDisableForwardActionButton(false);
      }
      setIsFetching(false);
    } catch (err) {
      setArchiwarePlans([]);
      setErrorObj({
        error: true,
        apiErrorMessage: err.message,
      });
      setIsFetching(false);
    }
  };
  const getServerOptions = () => {
    if (archiwarePlans.length !== 0) {
      const options = [];
      const archiwarePlanIdAndServerMap = {};

      allServers.forEach((server) => {
        if (!archiwarePlanIdAndServerMap[server.name]) {
          archiwarePlanIdAndServerMap[server.name] = { group: [] };
        }
      });

      archiwarePlans.forEach((archiwarePlan) => {
        if (archiwarePlanIdAndServerMap[archiwarePlan.server.name]) {
          archiwarePlanIdAndServerMap[archiwarePlan.server.name].group.push({
            value: `${archiwarePlan.planId},${archiwarePlan.server.id}`,
            label: archiwarePlan.planName,
          });
        }
      });

      Object.keys(archiwarePlanIdAndServerMap).forEach((serverName) => {
        options.push({
          label: serverName,
          options: archiwarePlanIdAndServerMap[serverName].group,
        });
      });
      setServerOptions(options);
    }
  };

  const formatGroupLabel = (groupedOption) => {
    return (
      <>
        Server : <span>{groupedOption.label}</span>
      </>
    );
  };

  return (
    <>
      {hasAnythingElseThanFileTypeToInclude && (
        <div className="mb-3">
          <span style={{ color: "#eda003" }}>Warning:</span>
          <strong>
            {` You have non On-Premises ${fileOrFolder} in your ${fileOrFolder} selection. Only On-Premises ${fileOrFolder} will be archived.`}
          </strong>
        </div>
      )}
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : errorObj.error ? (
        <GeneralError
          customErrorMessage="There was an error while fetching the data"
          apiErrorMessage={errorObj.apiErrorMessage}
        />
      ) : (
        <>
          <Label>Select an Archiware P5 archive plan</Label>
          {archiwarePlans.length ? (
            <Select
              value={[
                {
                  value: `${userInputObj.planId},${userInputObj.serverId}`,
                  label: userInputObj.planName,
                },
              ]}
              options={serverOptions}
              formatGroupLabel={formatGroupLabel}
              onChange={(e) => {
                setPlan(e.value);
              }}
            />
          ) : (
            <GeneralError
              customErrorMessage={
                "It seems you have no plans on Archiware. Please verify and try again."
              }
            />
          )}

          <div>
            <ul
              style={{
                listStyle: "none",
                lineHeight: "2",
                paddingLeft: "0",
                margin: "10px 0 0 0",
              }}
            >
              <li>
                Storage destination:{" "}
                <strong>{userInputObj.storageDestination}</strong>
              </li>
              <li>
                {isDirectory ? "Folder" : "File"} deletion method after archive:{" "}
                <strong>{parseFileDeletionMethod()}</strong>
              </li>
            </ul>
          </div>
          <SelectLocation
            isSpecifyLocationChecked={isSpecifyLocationChecked}
            onCheck={(isChecked) => {
              if (!isChecked) {
                setLocation("");
              }
              setIsSpecifyLocationChecked(isChecked);
            }}
            volumes={volumes}
            volumeTypes={["Archives", "Archiware"]}
            location={location}
            setLocation={setLocation}
          />
        </>
      )}
    </>
  );
}
