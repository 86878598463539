import { fetchWithAuth } from "../actions/requestHelper";

export const getAllCredentials = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/bucket-credentials?page=${page}&sort=${sort},${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getAll = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/buckets?page=${page}&size=${size}&sort=${sort},${direction}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};
