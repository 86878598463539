import React, { useEffect, useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";

function DeleteBucketFormModal(props) {
  const {
    showDeleteBucketForm,
    closeModal,
    id,
    buckets,
    deleteBucket,
    isProcessing,
  } = props;

  const [deleteId, setDeleteId] = useState();

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (id) {
      setDeleteBucket(id);
    }
  }, [id]);

  const handleCloseModal = () => {
    closeModal();
    setDeleteId(null);
    setDescription("");
  };

  const setDeleteBucket = (id) => {
    const bucketToDelete = buckets.find((bucket) => bucket.id === id);
    setDeleteId(bucketToDelete.id);
    setDescription(bucketToDelete.description);
  };

  return (
    <>
      <ConfirmModal
        showConfirmation={showDeleteBucketForm}
        title="Delete Confirmation"
        buttonColor="danger"
        isProcessing={isProcessing}
        closeConfirmation={() => handleCloseModal()}
        buttonText="Delete"
        confirmAction={() => {
          deleteBucket(deleteId);
        }}
      >
        <p>Are you sure you want to delete bucket {description}?</p>
        <p>
          This process will delete the configuration and the indexed data for
          the last index. It could take several minutes.
        </p>
      </ConfirmModal>
    </>
  );
}

export default DeleteBucketFormModal;
