import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputGroup, Input, Button } from "reactstrap";

Component.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.array,
  handleChange: PropTypes.func
}

export default function MultiInput(props) {
  const {
    placeholder,
    value,
    handleChange
  } = props;

  function handleMultiInputChange(event, currentInputIndex) {
    const updatedInput = value.map((item, itemIndex) => {
      return itemIndex === currentInputIndex ? event.target.value : item
    })

    handleChange(updatedInput);
  }

  return (
    <>
      {value.map((input, index) => {
        return (
          <InputGroup key={index}>
            <Input
              type="text"
              placeholder={placeholder}
              value={value[index]}
              onChange={e => handleMultiInputChange(e, index)}
            />
            {index === 0 &&
              <Button
                outline
                color="secondary"
                onClick={() => handleChange(["", ...value])}
              >
                Add another
              </Button>
            }
            {index > 0 &&
              <Button
                outline
                color="danger"
                onClick={() => handleChange(value.filter((item, itemIndex) => index !== itemIndex))}
              >
                X
              </Button>
            }
          </InputGroup>
        )
      })}
    </>
  );
}
