import { Col, FormGroup, Input, Label } from "reactstrap";
import DocumentationButton from "../../components/DocumentationButton";

export default function EntraIDConfiguration(props) {
  const {
    isEntraIdActive,
    setIsEntraIdActive,
    entraIdClientId,
    setEntraIdClientId,
    entraIdTenantId,
    setEntraIdTenantId,
    entraIdRedirectUri,
    setEntraIdRedirectUri,
  } = props;

  return (
    <>
      <h5>
        Microsoft Entra ID
        <DocumentationButton
          id="entraIDDocumentationButton"
          url="https://support.cloudsoda.io/hc/en-us/articles/27835527280539-Single-Sign-On-SSO-Microsoft-Entra-ID"
        />
      </h5>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label
            for="enableEntraLogin"
            sm={2}
          >
            Enable Microsoft Entra ID Login
          </Label>
          <Col sm={4}>
            <FormGroup switch>
              <Input
                type="switch"
                id="enableEntraLogin"
                name="enableEntraLogin"
                onChange={(e) => setIsEntraIdActive((prev) => ({ ...prev, value: e.target.checked ? "true" : "false" }))}
                checked={isEntraIdActive.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

      <FormGroup row>
        <Label for="entraClientId" sm={2}>
          Client ID
        </Label>
        <Col sm={4}>
          <Input
            id="entraClientId"
            name="entraClientId"
            value={entraIdClientId.value}
            onChange={(e) =>
              setEntraIdClientId((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={isEntraIdActive.value === "false"}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="entraTenantId" sm={2}>
          Tenant ID
        </Label>
        <Col sm={4}>
          <Input
            id="entraTenantId"
            name="entraTenantId"
            value={entraIdTenantId.value}
            onChange={(e) =>
              setEntraIdTenantId((prev) => ({ ...prev, value: e.target.value }))
            }
            disabled={isEntraIdActive.value === "false"}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="entraRedirectUri" sm={2}>
          Redirect URI
        </Label>
        <Col sm={4}>
          <Input
            id="entraRedirectUri"
            name="entraRedirectUri"
            value={entraIdRedirectUri.value}
            onChange={(e) =>
              setEntraIdRedirectUri((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
            disabled={isEntraIdActive.value === "false"}
          />
        </Col>
      </FormGroup>
    </>
  );
}
