import { useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { useEffect } from "react";

export default function SearchMultiSelect({
  setVolumeIds,
  selectedDataSources,
  setSelectedDataSources,
  checkboxStatus,
  setCheckboxStatus,
  hasLabel = true,
}) {
  const volumes = useSelector(({ volumes }) => volumes.items);
  const volumeOptions = volumes
    .filter(
      (dataSource) =>
        dataSource.type === "crawl.files" ||
        dataSource.type === "NetworkVolume" ||
        dataSource.type === "DellPowerScale"
    )
    .map((volume) => ({
      value: volume.id,
      label: volume.name,
      dataType: "volume",
    }));
  const bucketOptions = volumes
    .filter((dataSource) => dataSource.type === "AWS-S3")
    .map((bucket) => ({
      value: bucket.id,
      label: bucket.name,
      dataType: "bucket",
    }));
  const archiveOptions = volumes
    .filter(
      (dataSource) =>
        dataSource.type === "Archives" || dataSource.type === "Archiware"
    )
    .map((archive) => ({
      value: archive.id,
      label: archive.name,
      dataType: "archive",
    }));
  const options = volumeOptions.concat(bucketOptions).concat(archiveOptions);

  useEffect(() => {
    const selectedVolumeJSON = JSON.stringify(
      selectedDataSources
        .filter((data) => data.dataType === "volume")
        .sort((a, b) => a.value - b.value)
    );
    const volumeOptionsJSON = JSON.stringify(
      volumeOptions.sort((a, b) => a.value - b.value)
    );

    const selectedBucketJSON = JSON.stringify(
      selectedDataSources
        .filter((data) => data.dataType === "bucket")
        .sort((a, b) => a.value - b.value)
    );
    const bucketOptionsJSON = JSON.stringify(
      bucketOptions.sort((a, b) => a.value - b.value)
    );

    const selectedArchiveJSON = JSON.stringify(
      selectedDataSources
        .filter((data) => data.dataType === "archive")
        .sort((a, b) => a.value - b.value)
    );
    const archiveOptionsJSON = JSON.stringify(
      archiveOptions.sort((a, b) => a.value - b.value)
    );

    setCheckboxStatus({
      volume:
        selectedVolumeJSON === volumeOptionsJSON && selectedVolumeJSON !== "[]",
      bucket:
        selectedBucketJSON === bucketOptionsJSON && selectedBucketJSON !== "[]",
      archive:
        selectedArchiveJSON === archiveOptionsJSON &&
        selectedArchiveJSON !== "[]",
    });
  }, [selectedDataSources]);

  const handleMultiSelectChange = (options) => {
    setVolumeIds(options.map((option) => option.value));
    setSelectedDataSources(options);
  };

  const handleDataSourceCheckboxFilters = (checkboxType) => {
    const alreadySelectedMap = {};
    selectedDataSources.forEach((dataSource) => {
      alreadySelectedMap[dataSource.label] = true;
    });
    let checkForDuplicates = {
      volume: volumeOptions,
      bucket: bucketOptions,
      archive: archiveOptions,
    }[checkboxType];

    // The checkbox state is not yet updated, so we return if we are unchecking the checkbox
    if (checkboxStatus[checkboxType]) {
      return;
    }

    switch (checkboxType) {
      case "volume":
        checkForDuplicates = checkForDuplicates.filter(
          (volume) => !alreadySelectedMap[volume.label]
        );
        break;

      case "bucket":
        checkForDuplicates = checkForDuplicates.filter(
          (bucket) => !alreadySelectedMap[bucket.label]
        );
        break;

      case "archive":
        checkForDuplicates = checkForDuplicates.filter(
          (archive) => !alreadySelectedMap[archive.label]
        );
        break;

      default:
        break;
    }

    const toAvoidDuplicatedOptions = [
      ...selectedDataSources,
      ...checkForDuplicates,
    ];

    setSelectedDataSources(toAvoidDuplicatedOptions);
    setVolumeIds(
      toAvoidDuplicatedOptions.map((dataSource) => dataSource.value)
    );

    const prevCheckboxState = checkboxStatus;
    prevCheckboxState[checkboxType] = !prevCheckboxState[checkboxType];
    setCheckboxStatus(prevCheckboxState);
  };

  return (
    <div>
      {hasLabel && <Label>Data Sources</Label>}
      <Select
        options={options}
        onChange={handleMultiSelectChange}
        name="dataSourceIds"
        isMulti
        className="basic-multi-select mb-2"
        classNamePrefix="select"
        value={selectedDataSources}
        styles={{
          multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: data.invalid
              ? "#dc354588"
              : styles.backgroundColor,
          }),
        }}
      />

      {volumeOptions.length ? (
        <FormGroup check inline>
          <Label check>
            <Input
              type="checkbox"
              checked={checkboxStatus.volume}
              onChange={() => {
                handleDataSourceCheckboxFilters("volume");
              }}
              disabled={volumeOptions.length ? false : true}
            />
            Select all On-Premises storage
          </Label>
        </FormGroup>
      ) : null}
      {bucketOptions.length ? (
        <FormGroup check inline>
          <Label check>
            <Input
              type="checkbox"
              checked={checkboxStatus.bucket}
              onChange={() => handleDataSourceCheckboxFilters("bucket")}
              disabled={bucketOptions.length ? false : true}
            />
            Select all Cloud storage
          </Label>
        </FormGroup>
      ) : null}
      {archiveOptions.length ? (
        <FormGroup check inline>
          <Label check>
            <Input
              type="checkbox"
              checked={checkboxStatus.archive}
              onChange={() => handleDataSourceCheckboxFilters("archive")}
            />
            Select all Archiware P5 indexes
          </Label>
        </FormGroup>
      ) : null}
    </div>
  );
}
