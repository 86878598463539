import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";

import { getSearchRequestParamsV2 } from "../ducks/search";

export const getArchiwareP5ArchivePlans = async (dispatch, auth) => {
  const url = `/v1/archiwares/plans`;

  return fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getArchiwareP5Credentials = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/archiwares/credentials?page=${page}&sort=${sort},${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getAll = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/archiwares?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getArchiwareClients = async (dispatch, auth) => {
  const url = `/v1/archiwares/clients`;

  return fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getArchiwareVolumeMappingByVolumeId = async (
  dispatch,
  auth,
  volumeId
) => {
  const url = `/v1/archiwares/volumeMapping/volumes/${volumeId}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getArchiwareVolumeMapping = async (
  dispatch,
  auth,
  page = 1,
  size = 10,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/archiwares/volumeMapping?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const createArchiwareVolumeMapping = async (
  dispatch,
  auth,
  archiwareVolumeMapping
) => {
  const url = `/v1/archiwares/volumeMapping`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(archiwareVolumeMapping)
  );
};

export const updateArchiwareVolumeMapping = async (
  dispatch,
  auth,
  id,
  archiwareVolumeMapping
) => {
  const url = `/v1/archiwares/volumeMapping/${id}`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(archiwareVolumeMapping)
  );
};

export const deleteArchiwareVolumeMapping = async (dispatch, auth, id) => {
  const url = `/v1/archiwares/volumeMapping/${id}`;

  await fetchWithAuth(dispatch, url, auth.accessToken, "DELETE");
};

export const archiveArchiwareFiles = async (
  dispatch,
  auth,
  planId,
  clientId,
  serverId,
  credentialsId,
  indexroot,
  dataIntellPaths,
  deltaScanAfter
) => {
  const url = `/v1/archiwares/plans/${planId}/archiveselections?clientId=${clientId}&serverId=${serverId}&credentialsId=${credentialsId}`;
  const body = {
    dataIntellPaths: dataIntellPaths,
    meta: [],
    indexroot: indexroot,
    deltaScanAfter: deltaScanAfter,
  };

  const result = await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
};

export const archiveArchiwareSearchResult = async (
  dispatch,
  auth,
  planId,
  clientId,
  serverId,
  credentialsId,
  indexroot,
  indexingDate,
  isDirectory,
  search,
  filters = {},
  deltaScanAfter
) => {
  const url = `/v1/archiwares/plans/${planId}/archiveselections/search`;
  const searchParams = getSearchRequestParamsV2(filters);

  const queryParams = {
    search,
    clientId,
    serverId,
    credentialsId,
    indexingDate,
    isDirectory
  };
  
  const body = {
    dataIntellPaths: null,
    meta: [],
    indexroot: indexroot,
    deltaScanAfter: deltaScanAfter,
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    { ...queryParams, ...searchParams },
    "POST",
    JSON.stringify(body)
  );
};

export const restoreArchiwareSelection = async (
  dispatch,
  auth,
  clientId,
  credentialsId,
  relocate,
  dataIntellPaths
) => {
  const url = `/v1/archiwares/restoreselections?clientId=${clientId}&credentialsId=${credentialsId}`;
  const body = {
    dataIntellPaths: dataIntellPaths,
    relocate: relocate,
  };

  const result = await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
};

export const restoreArchiwareSelectionSearchResult = async (
  dispatch,
  auth,
  clientId,
  credentialsId,
  relocate,
  indexingDate,
  isDirectory,
  search,
  filters = {}
) => {
  const url = "/v1/archiwares/restoreselections/search";
  const searchParams = getSearchRequestParamsV2(filters);
  
  const queryParams = {
    search,
    clientId,
    credentialsId,
    indexingDate,
    isDirectory
  };
  
  const body = {
    dataIntellPaths: [],
    relocate: relocate,
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    { ...queryParams, ...searchParams },
    "POST",
    JSON.stringify(body)
  );
};
