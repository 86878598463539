import { fetchWithAuthV2 } from "../actions/requestHelper";

export const createDellPowerScaleStorage = async (
  dispatch,
  auth,
  dellPowerScaleStorage
) => {
  const url = "/v1/powerScales";

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "POST",
    JSON.stringify(dellPowerScaleStorage)
  );

  return result;
};

export const getDellPowerScaleStorage = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/powerScales/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken
  );

  return result;
};

export const getAllDellPowerScaleStoragesPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleStorages") ? Number(window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleStorages")) : 10,
  sort = "id",
  direction = "asc"
) => {
  const url = "/v1/powerScales";

  const queryParams = {
    page,
    size,
    sort,
    direction
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const deleteDellPowerScaleStorage = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/powerScales/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "DELETE"
  );

  return result;
};

export const updateDellPowerScaleStorage = async (
  dispatch,
  auth,
  id,
  dellPowerScaleStorage
) => {
  const url = `/v1/powerScales/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "PUT",
    JSON.stringify(dellPowerScaleStorage)
  );

  return result;
};

export const createDellPowerScaleCredentials = async (
  dispatch,
  auth,
  dellPowerScaleCredentials
) => {
  const url = "/v1/powerScales/credentials";

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "POST",
    JSON.stringify(dellPowerScaleCredentials)
  );

  return result;
};

export const getDellPowerScaleCredentials = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/powerScales/credentials/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken
  );

  return result;
};

export const getAllDellPowerScaleCredentialsPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleCredentials") ? Number(window.localStorage.getItem("numberOfElementsDataSourceDellPowerScaleCredentials")) : 10,
  sort = "id",
  direction = "asc"
) => {
  const url = "/v1/powerScales/credentials";

  const queryParams = {
    page,
    size,
    sort,
    direction
  };

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    queryParams
  );

  return result;
};

export const deleteDellPowerScaleCredentials = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/powerScales/credentials/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "DELETE"
  );

  return result;
};

export const updateDellPowerScaleCredentials = async (
  dispatch,
  auth,
  id,
  dellPowerScaleCredentials
) => {
  const url = `/v1/powerScales/credentials/${id}`;

  const result = await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "PUT",
    JSON.stringify(dellPowerScaleCredentials)
  );

  return result;
};