import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { getDateInThePastByMonths } from "../../utils/getPastDate";
import { buildSearchUrl } from "../../search/searchBuilder";
import FormatSize from "../../components/FormatSize";

Insights.propTypes = {
  projectShares: PropTypes.array,
  detailedProjectPaths: PropTypes.array,
};

function Insights(props) {
  const { projectShares, detailedProjectPaths } = props;

  const baseURL = buildSearchUrl(detailedProjectPaths);

  const getInsight = (size) => {
    return (
      <FormatSize>
        {projectShares
          .map((share) => {
            if (size === "duplicate") {
              return share.statistics.DUPLICATE && share.statistics.DUPLICATE.duplicate
                ? share.statistics.DUPLICATE.duplicate.sizeOfFiles
                : 0;
            } else if (size === "sixMonths") {
              return share.statistics.ACCESS_DATE && share.statistics.ACCESS_DATE["6 month"]
                ? share.statistics.ACCESS_DATE["6 month"].sizeOfFiles
                : 0;
            } else if (size === "oneYear") {
              return share.statistics.ACCESS_DATE && share.statistics.ACCESS_DATE["1 year"]
                ? share.statistics.ACCESS_DATE["1 year"].sizeOfFiles
                : 0;
            }
            return 0;
          })
          .reduce((a, b) => a + b, 0)}
      </FormatSize>
    );
  };

  return (
    <Card className="mb-3" style={{ height: "100%" }}>
      <CardHeader tag="h5">Insights</CardHeader>
      <CardBody>
        <div
          style={{
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "140px auto",
          }}
        >
          <div>Duplicate Data:</div>
          <div>
            <Link className="text-decoration-none" to={baseURL + "&onlyDuplicates=true"}>
              <strong>{getInsight("duplicate")}</strong>
            </Link>
          </div>
          <div>Cold Data - 6 Months:</div>
          <div>
            <Link
              className="text-decoration-none"
              to={baseURL + `&lastAccessBefore=${getDateInThePastByMonths(6)}`}
            >
              <strong>{getInsight("sixMonths")}</strong>
            </Link>
          </div>
          <div>Cold Data - 1 Year:</div>
          <div>
            <Link
              className="text-decoration-none"
              to={baseURL + `&lastAccessBefore=${getDateInThePastByMonths(12)}`}
            >
              <strong>{getInsight("oneYear")}</strong>
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Insights;
