import { Spinner } from "reactstrap";
import SourceReview from "../archiwareArchiveRestore/SourceReview";
import SelectLocation from "./SelectLocation";

export default function ReviewStep(props) {
  const {
    storageManagerActionObj,
    isFetching,
    activeVolumes,
    totalNumberOfFiles,
    validNumberOfFiles,
    totalSizeOfFiles,
    validSizeOfFiles,
    isDirectory,
    numberOfValidFiles,
    relocate,
    setRelocate,
  } = props;

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-2">
          <SourceReview
            fileOrFolder={isDirectory ? "folder" : "file"}
            action={storageManagerActionObj.action}
            fileType="mapped Storage Manager"
            activeVolumes={activeVolumes}
            totalNumberOfFilesOrFolders={totalNumberOfFiles}
            numberOfValidFileOrFolders={validNumberOfFiles}
            totalFileSize={totalSizeOfFiles}
            validTotalFileSize={validSizeOfFiles}
          >
            <li>
              Action:{" "}
              <strong>
                {storageManagerActionObj.action[0].toUpperCase() +
                  storageManagerActionObj.action.slice(1)}
              </strong>
            </li>
          </SourceReview>
          {numberOfValidFiles > 0 &&
            storageManagerActionObj.action === "restore" && (
              <SelectLocation
                relocate={relocate}
                numberOfValidFiles={numberOfValidFiles}
                setRelocate={setRelocate}
              />
            )}
        </div>
      )}
    </>
  );
}
