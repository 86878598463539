import { useState } from "react";
import CreatableSelect from 'react-select/creatable';

export default function SelectWithCustomValue(props) {
  const {
    values,
    selectedValue,
    setSelectedValue
  } = props;

  const [selectedOption, setSelectedOption] = useState({
    label: selectedValue,
    value: selectedValue
  });

  const options = values.map(value => {
    return {
      label: value,
      value: value
    };
  })

  return (
    <CreatableSelect
      isClearable
      options={options}
      value={selectedOption}
      formatCreateLabel={(value) => <span>{value}</span>}
      onCreateOption={(value) => {
        const newOption = {
          label: value,
          value: value
        };
        setSelectedOption(newOption);
        setSelectedValue(value);
      }}
      onChange={(value) => {
        setSelectedOption(value);
        setSelectedValue(value?.value)
      }}
    />
  );
}