import { useEffect, useRef, useState } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { DATE_FORMAT_OPTIONS } from "../constants/dateFormatting";
import moment from "moment";
import TimelineLegend from "./TimelineLegend";
import { getDeltaInfo } from "../utils/getDeltaInfo";

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin,
  Filler
);

export default function MultiLineChart(props) {
  const {
    lineChartRef,
    unit = "GB",
    labels,
    height,
    width,
    onClick = () => {},
    showMinMax,
    maxCapacity = 0,
    hideLabels = false,
    showBadges = true,
    datasets = [{ title: "Total", data: [], colors: "#333333" }],
    setDeltaInfo,
  } = props;

  const [min, setMin] = useState(Math.min(...datasets[0].data));
  const [max, setMax] = useState(Math.max(...datasets[0].data));
  const [isShowAllLabel, setIsShowAllLabel] = useState(true);

  const chartRef = lineChartRef ?? useRef();

  const totalDataset = datasets[0];
  const minValue =
    totalDataset.data.length === 0 ? 0 : Math.min(...totalDataset.data);
  const maxValue =
    totalDataset.data.length === 0 ? 0 : Math.max(...totalDataset.data);
  const color = "#333333";
  const minColor = "#007BFF";
  const maxColor = "#FF0000";
  const allDataValuesEqual = totalDataset.data.every(
    (val) => val === totalDataset.data[0]
  );

  const pointColorValues = totalDataset.data.map((value) =>
    minValue === Number(value)
      ? minColor
      : maxValue === Number(value)
      ? maxColor
      : color
  );
  const pointRadiusValues = totalDataset.data.map((value) =>
    minValue === Number(value) || maxValue === Number(value) ? 5 : 0
  );

  useEffect(() => {
    setDeltaInfo(getDeltaInfo(datasets[0].data, labels, unit));
  }, [datasets]);

  const getDatasets = () => {
    const result = [];

    datasets.forEach((dataset, index) => {
      result.push({
        label: dataset.title,
        lineTension: 0.1,
        backgroundColor: dataset.colors + "22",
        borderColor: dataset.colors,
        borderCapStyle: "butt",
        borderDash: [],
        borderWidth: index === 0 ? 5 : 3,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: index === 0 ? pointColorValues : dataset.colors,
        pointBackgroundColor: index === 0 ? pointColorValues : dataset.colors,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor:
          index === 0 ? pointColorValues : dataset.colors,
        pointHoverBorderColor: index === 0 ? pointColorValues : dataset.colors,
        pointHoverBorderWidth: 2,
        pointRadius: index === 0 ? pointRadiusValues : 0,
        pointHitRadius: 5,
        data: dataset.data,
        hidden: index !== 0,
        fill: index === 0,
      });
    });

    result.push({
      label: isShowAllLabel ? "Show All" : "Hide All",
    });

    return result;
  };

  const lineChartData = {
    labels: labels,
    datasets: getDatasets(),
  };

  const setPosition = (context) => {
    const totalValue = context.dataset.data
      .map((value) => parseInt(value))
      .reduce((prev, curr) => prev + curr);
    if (context.dataset.data.length > 0) {
      const averageValue = totalValue / context.dataset.data.length;
      return context.dataset.data[context.dataIndex] > averageValue
        ? "start"
        : "end";
    } else {
      return "end";
    }
  };

  const minLine = () => {
    let display = false;

    if (showMinMax && !allDataValuesEqual) {
      const contentValue = showBadges ? `Min: ${minValue} ${unit}` : "";
      return {
        display: (context) => {
          if (context.type === "chart") {
            display = !context.chart.legend.legendItems[0].hidden;
          }
          return display;
        },
        type: "line",
        borderColor: "rgb(128,128,128)",
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          backgroundColor: "rgba(0,0,0,0.6)",
          borderRadius: 10,
          borderWidth: 2,
          borderColor: "rgb(128,128,128)",
          content: contentValue,
          display: true,
          rotation: "auto",
        },
        scaleID: "y",
        value: minValue,
      };
    }
  };

  const maxLine = () => {
    if (showMinMax && !allDataValuesEqual && max / maxValue >= 0.8) {
      const contentValue = showBadges ? `Max: ${maxValue} ${unit}` : "";
      return {
        type: "line",
        borderColor: "black",
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          backgroundColor: "rgba(0,0,0,0.8)",
          borderRadius: 10,
          borderWidth: 2,
          content: contentValue,
          display: true,
          rotation: "auto",
        },
        scaleID: "y",
        value: maxValue,
      };
    }
  };

  const redBar = () => {
    if (maxCapacity > 0 && max / maxCapacity >= 0.7) {
      return {
        type: "box",
        backgroundColor: "rgba(201, 33, 33, 0.5)",
        borderColor: "rgb(201, 33, 33)",
        borderWidth: 1,
        xMax: labels[labels.length - 1],
        xMin: labels[0],
        xScaleID: "x",
        yMin: maxCapacity,
        yScaleID: "y",
      };
    }
  };

  const options = {
    responsive: true,
    onHover: (event, chartElement) => {
      if (onClick.toString() != (() => {}).toString()) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      }
    },
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 12,
          generateLabels: function (chart) {
            let newLabels =
              defaults.plugins.legend.labels.generateLabels(chart);
            for (var key in newLabels) {
              newLabels[key].fillStyle =
                newLabels[key].strokeStyle +
                (newLabels[key].hidden ? "22" : "");
            }
            return newLabels;
          },
        },
        display: !hideLabels,
        onHover: function (e) {
          e.native.target.style.cursor = "pointer";
        },
        onLeave: function (e) {
          e.native.target.style.cursor = "default";
        },
        onClick: function (e, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;

          if (index === ci.legend.legendItems.length - 1) {
            for (var i = 0; i < ci.legend.legendItems.length; i++) {
              if (isShowAllLabel) {
                ci.show(i);
              } else if (i !== ci.legend.legendItems.length - 1) {
                ci.hide(i);
              }
            }

            if (isShowAllLabel) {
              setMin(Math.min(...ci.getDatasetMeta(0)._dataset.data));
              setMax(Math.max(...ci.getDatasetMeta(0)._dataset.data));
            } else {
              setMin(0);
              setMax(0);
            }

            setIsShowAllLabel((prev) => !prev);
            return;
          }

          const newMax = Math.max(...ci.getDatasetMeta(index)._dataset.data);
          const newMin = Math.min(...ci.getDatasetMeta(index)._dataset.data);

          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;

            setIsShowAllLabel(true);

            if (max === newMax) {
              let newerMax = 0;
              ci.getSortedVisibleDatasetMetas().forEach(
                (datasetMeta, index) => {
                  let newestMax = Math.max(...datasetMeta._dataset.data);
                  if (index === 0 || newestMax > newerMax) {
                    newerMax = newestMax;
                  }
                }
              );

              setMax(newerMax);
            }

            if (min === newMin) {
              let newerMin = 0;
              ci.getSortedVisibleDatasetMetas().forEach(
                (datasetMeta, index) => {
                  let newestMin = Math.min(...datasetMeta._dataset.data);
                  if (index === 0 || newestMin < newerMin) {
                    newerMin = newestMin;
                  }
                }
              );

              setMin(newerMin);
            }
          } else {
            ci.show(index);
            legendItem.hidden = false;

            if (ci.getVisibleDatasetCount() === ci.legend.legendItems.length) {
              setIsShowAllLabel(false);
            }

            setMax((prev) => (prev >= newMax ? prev : newMax));
            setMin((prev) => (prev < newMin ? prev : newMin));
          }
        },
      },
      annotation: {
        annotations: {
          minLine,
          maxLine,
          redBar,
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            if (datasets.length > 0) {
              return tooltipItem[0].dataset.label;
            } else {
              return new Date(tooltipItem[0].label).toLocaleDateString(
                "en-CA",
                DATE_FORMAT_OPTIONS
              );
            }
          },
          label: (tooltipItem) => {
            if (datasets.length > 0) {
              return [
                "Size: " + tooltipItem.raw + ` ${unit}`,
                new Date(tooltipItem.label).toLocaleDateString(
                  "en-CA",
                  DATE_FORMAT_OPTIONS
                ),
              ];
            } else {
              return ["Size: " + tooltipItem.raw + ` ${unit}`];
            }
          },
        },
      },
      datalabels: {
        align: (context) => setPosition(context),
        anchor: (context) => setPosition(context),
        backgroundColor: function (context) {
          return context.dataset.borderColor;
        },
        borderRadius: 18,
        color: "white",
        padding: 3,
        display: function (context) {
          if (context.dataset.data.length < 20) {
            return true;
          } else {
            const currentIndex = context.dataIndex;
            if (context.dataset.data.length < 30) {
              return currentIndex % 2 === 0 ? true : false;
            }
            if (context.dataset.data.length < 60) {
              return currentIndex % 3 === 0 ? true : false;
            }
            if (context.dataset.data.length < 80) {
              return currentIndex % 4 === 0 ? true : false;
            }
            if (context.dataset.data.length < 100) {
              return currentIndex % 5 === 0 ? true : false;
            }
            if (context.dataset.data.length < 120) {
              return currentIndex % 6 === 0 ? true : false;
            }
          }
          return false;
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            // This is a fix for the ChartJS issue with values getting converted to NaN.
            const fixedValue = value != NaN ? value : "";
            const label = this.getLabelForValue(fixedValue);
            return moment(label).format("YYYY-MM-DD");
          },
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            // This is a fix for the ChartJS issue with values getting converted to NaN.
            const fixedValue = value != NaN ? value : "";
            return this.getLabelForValue(Math.trunc(fixedValue));
          },
        },
      },
    },
    layout: {
      padding: { top: 32 },
    },
  };

  const elementIndex = (e) =>
    getElementAtEvent(chartRef.current, e).length
      ? getElementAtEvent(chartRef.current, e)[0].index
      : null;

  return (
    <>
      <div style={{ textAlign: "right" }}>
        {getDeltaInfo(datasets[0].data, labels, unit)}
      </div>
      <div>
        <Line
          ref={chartRef}
          height={height}
          width={width}
          data={lineChartData}
          options={options}
          onClick={(e) => onClick(elementIndex(e))}
        />
      </div>
      <TimelineLegend minValue={minValue} maxValue={maxValue} unit={unit} />
    </>
  );
}
