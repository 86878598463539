import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import EditFileTags from "./EditFileTags";
import ListFileTags from "./ListFileTags";

function TagFilePanel(props) {
  const { file, updateFile, updateTags } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [fileTags, setFileTags] = useState(file.customTag ?? []);

  return (
    <Card style={{ marginBottom: 10, height: "100%", maxHeight: "260px" }}>
      <CardHeader>
        <CardTitle tag="h6" style={{ "marginBottom": 0 }} className="d-flex align-items-center">
          <span>Tags</span>
          {isOpen ?
            <Button className="ms-auto" size="sm" onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faXmark} size="sm" />
            </Button> :
            <Button className="ms-auto" color={isOpen ? "danger" : "primary"} size="sm" onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon icon={faEdit} size="sm" />
            </Button>
          }
        </CardTitle>
      </CardHeader>
      <CardBody style={{ paddingBottom: `${isOpen ? 0 : ""}` }}>
        {isOpen ?
          <EditFileTags
            fileTags={fileTags}
            updateFile={tags => {
              updateFile(tags);
              setFileTags(tags);
            }}
            updateTags={updateTags}
            closeFunc={() => setIsOpen(false)}
            isInCard
          /> :
          <div style={{ maxHeight: "100%", maxWidth: "22rem", overflowY: "auto" }}>
            <ListFileTags tags={fileTags} />
          </div>
        }
      </CardBody>
    </Card>
  );
}

export default TagFilePanel;
