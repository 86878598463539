import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import FolderTree from "../../components/folderTree/FolderTree";
import DatePickerForPreviousDate from "./DatePickerForPreviousDate";

export default function CompareDatesFolderTree(props) {
  const { volumes, path } = props;

  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">
          Folder Tree Variation
          <div className="float-end">
            <DatePickerForPreviousDate
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Variation of the current indexing date to a previous indexing date
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        <FolderTree
          volumes={volumes}
          path={path}
          previousIndexingDate={selectedDate}
          hasCheckboxes={false}
          hasActions={false}
          hasDeltaChartColumn
        />
      </CardBody>
    </Card>
  );
}
