import { useEffect, useState } from "react";
import ConfirmModal from "../../../components/ConfirmModal";
import {
  createDellPowerScaleStorage,
  getAllDellPowerScaleCredentialsPaginated,
  getDellPowerScaleStorage,
  updateDellPowerScaleStorage,
} from "../../../api/dellPowerScale";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import IgnorePathsInput from "../../components/IgnorePathsInput";

export default function DellPowerScaleStorageForm(props) {
  const { isOpen, handleConfirmationFunction, handleClose, id } = props;

  const [host, setHost] = useState("");
  const [dataintellName, setDataintellName] = useState("");
  const [path, setPath] = useState("/ifs");
  const [credentialsId, setCredentialsId] = useState("");
  const [storagePriceId, setStoragePriceId] = useState("");
  const [scanType, setScanType] = useState("VOLUME_MULTITHREAD");
  const [serverId, setServerId] = useState("");
  const [volumeIgnoreRules, setVolumeIgnoreRules] = useState([]);
  const [fullScanSnapshotExpiresDays, setFullScanSnapshotExpiresDays] =
    useState(7);
  const [deltaScanSnapshotExpiresDays, setDeltaScanSnapshotExpiresDays] =
    useState(3);
  const [credentials, setCredentials] = useState([]);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const servers = useSelector(({ servers }) => servers.items);
  const storagePrices = useSelector(({ storagePrices }) => storagePrices.items);

  useEffect(() => {
    if (isUpdate) {
      getDellPowerScaleStorage(dispatch, auth, id)
        .then((res) => {
          setHost(res.host);
          setDataintellName(res.dataintellName);
          setPath(res.path);
          setScanType(res.scanType);
          setCredentialsId(res.dellPowerScaleCredentials.id);
          setStoragePriceId(res.storagePrice.id);
          setServerId(res.serverId);
          setVolumeIgnoreRules(res.volumeIgnoreRules);
          setFullScanSnapshotExpiresDays(res.fullScanSnapshotExpiresDays);
          setDeltaScanSnapshotExpiresDays(res.deltaScanSnapshotExpiresDays);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert("Unable to get Dell PowerScale storage", "danger", 8000)
          );
        });
    }

    getAllDellPowerScaleCredentialsPaginated(dispatch, auth, 1, 500)
      .then((res) => setCredentials(res.elements))
      .catch((err) => {
        console.error(err);
        dispatch(
          setAlert("Unable to get Dell PowerScale credentials", "danger", 8000)
        );
      });
  }, [id, isOpen]);

  useEffect(() => {
    if (
      host.length === 0 ||
      host.length > 255 ||
      dataintellName.length === 0 ||
      dataintellName.length > 255 ||
      path.length === 0 ||
      path.length > 255 ||
      deltaScanSnapshotExpiresDays === "" ||
      fullScanSnapshotExpiresDays === "" ||
      credentialsId === "" ||
      storagePriceId === "" ||
      serverId === ""
    ) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, [
    host,
    dataintellName,
    path,
    credentialsId,
    storagePriceId,
    serverId,
    fullScanSnapshotExpiresDays,
    deltaScanSnapshotExpiresDays,
  ]);

  const isUpdate = id !== null && id !== undefined;

  const handleConfirmation = () => {
    const dellPowerScaleStorage = {
      host,
      dataintellName,
      path,
      scanType,
      dellPowerScaleCredentials: {
        id: credentialsId,
      },
      storagePrice: {
        id: storagePriceId,
      },
      serverId,
      volumeIgnoreRules,
      fullScanSnapshotExpiresDays,
      deltaScanSnapshotExpiresDays,
    };

    if (isUpdate) {
      updateDellPowerScaleStorage(dispatch, auth, id, dellPowerScaleStorage)
        .then(() => {
          handleConfirmationFunction();
          onClose();
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert("Unable to update Dell PowerScale storage", "danger", 8000)
          );
        });
    } else {
      createDellPowerScaleStorage(dispatch, auth, dellPowerScaleStorage)
        .then(() => {
          handleConfirmationFunction();
          onClose();
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert("Unable to create Dell PowerScale storage", "danger", 8000)
          );
        });
    }
  };

  const onClose = () => {
    setHost("");
    setDataintellName("");
    setPath("/ifs");
    setCredentialsId("");
    setStoragePriceId("");
    setServerId("");
    setScanType("VOLUME_MULTITHREAD");
    setVolumeIgnoreRules([]);
    setFullScanSnapshotExpiresDays(7);
    setDeltaScanSnapshotExpiresDays(3);
    handleClose();
  };

  return (
    <ConfirmModal
      size="xl"
      showConfirmation={isOpen}
      title={`${isUpdate ? "Update" : "Create"} Dell PowerScale Storage`}
      buttonColor="primary"
      buttonText={`${isUpdate ? "Update" : "Create"}`}
      confirmAction={() => handleConfirmation()}
      closeConfirmation={() => onClose()}
      disableRightButton={isConfirmButtonDisabled}
    >
      <Form>
        <FormGroup row>
          <Label for="name" sm={3}>
            Name
            <FontAwesomeIcon
              id="nameLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <UncontrolledTooltip placement="auto" target="nameLabel">
              User readable name for the Dell PowerScale storage
            </UncontrolledTooltip>
          </Label>
          <Col sm={9}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Ex: name"
              value={dataintellName}
              onChange={(e) => {
                const value = e.target.value;
                setDataintellName(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="host" sm={3}>
            Host
          </Label>
          <Col sm={9}>
            <Input
              id="host"
              name="host"
              type="text"
              placeholder="Ex: https://127.0.0.1:8080"
              value={host}
              onChange={(e) => {
                const value = e.target.value;
                setHost(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="path" sm={3}>
            Path
          </Label>
          <Col sm={9}>
            <Input
              id="path"
              name="path"
              type="text"
              placeholder="Ex: /ifs"
              value={path}
              onChange={(e) => {
                const value = e.target.value;
                setPath(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="credentials" sm={3}>
            Credentials
          </Label>
          <Col sm={9}>
            <Input
              id="credentials"
              name="credentials"
              type="select"
              value={credentialsId}
              onChange={(e) => {
                const value = e.target.value;
                setCredentialsId(value);
              }}
            >
              <option value={""}>Select credential</option>
              {credentials.map((credential) => (
                <option value={credential.id}>{credential.name}</option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="storagePrice" sm={3}>
            Storage Price
          </Label>
          <Col sm={9}>
            <Input
              id="storagePrice"
              name="storagePrice"
              type="select"
              value={storagePriceId}
              onChange={(e) => {
                const value = e.target.value;
                setStoragePriceId(value);
              }}
            >
              <option value={""}>Select storage price</option>
              {storagePrices.map((storagePrice) => (
                <option value={storagePrice.id}>
                  {storagePrice.description}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="server" sm={3}>
            Server
          </Label>
          <Col sm={9}>
            <Input
              id="server"
              name="server"
              type="select"
              value={serverId}
              onChange={(e) => {
                const value = e.target.value;
                setServerId(value);
              }}
            >
              <option value={""}>Select server</option>
              {servers.map((server) => (
                <option value={server.id}>{server.name}</option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        {isUpdate &&
          <IgnorePathsInput
            ignoredPaths={volumeIgnoreRules}
            handleChange={(name, value) => setVolumeIgnoreRules(value)}
            volumeType="Dell PowerScale"
            labelGrid={3}
            inputGrid={9}
          />
        }
        <hr />
        <div className="mb-4">
          <h6>PowerScale Snapshot Retention (Days)</h6>
        </div>
        <FormGroup row>
          <Label for="fullScanSnapshotExpiresDays" sm={3}>
            Full Scan
          </Label>
          <Col sm={9}>
            <Input
              id="fullScanSnapshotExpiresDays"
              name="fullScanSnapshotExpiresDays"
              type="number"
              min="0"
              value={fullScanSnapshotExpiresDays}
              onChange={(e) => {
                const value = e.target.value;
                setFullScanSnapshotExpiresDays(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="deltaScanSnapshotExpiresDays" sm={3}>
            Delta Scan
          </Label>
          <Col sm={9}>
            <Input
              id="deltaScanSnapshotExpiresDays"
              name="deltaScanSnapshotExpiresDays"
              type="number"
              min="1"
              value={deltaScanSnapshotExpiresDays}
              onChange={(e) => {
                const value = e.target.value;
                setDeltaScanSnapshotExpiresDays(value);
              }}
            />
          </Col>
        </FormGroup>
        <p style={{ textAlign: "justify" }}>
          CloudSoda leverages PowerScale's snapshot capabilities to enhance
          storage scanning efficiency. Snapshots are used during scans to track
          changes between them. The scanning engine will delete these snapshots
          after completing the scans. These values are used as a failsafe to
          delete the snapshots automatically if the engine was unable to
          complete its process.
        </p>
      </Form>
    </ConfirmModal>
  );
}
