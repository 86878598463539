import { useEffect } from "react";
import { Input } from "reactstrap";

export default function Checkbox(props) {
  const {
    treeNodes,
    setTreeNodes,
    positionInTree,
    isSelectAllVisibleTriggered,
    setIsSelectAllVisibleTriggered,
    isSelectNoneTriggered,
    setIsSelectNoneTriggered,
    checkboxRef,
    onCheckboxClick,
  } = props;

  useEffect(() => {
    if (isSelectAllVisibleTriggered) {
      setTreeNodesStructure(true);
      setIsSelectAllVisibleTriggered(false);
    } else if (isSelectNoneTriggered) {
      setTreeNodesStructure(false);
      setIsSelectNoneTriggered(false);
    }
  }, [isSelectAllVisibleTriggered, isSelectNoneTriggered]);

  const setCheckboxRefIndeterminateValue = (isIndeterminate) => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate;
    }
  };

  const getChildNodeFromTreeNode = (
    children = treeNodes,
    index = 0
  ) => {
    for (var i = 0; i < children.length; i++) {
      const child = children[i];
      if (i === positionInTree[index]) {
        if (index === positionInTree.length - 1) {
          return child;
        } else {
          return getChildNodeFromTreeNode(child.children, index + 1);
        }
      }
    }
  };
  const getIsChecked = () => {
    const nodeToGetValueFrom = getChildNodeFromTreeNode();

    if (nodeToGetValueFrom.isChecked === null) {
      setCheckboxRefIndeterminateValue(true);
      return false;
    }

    setCheckboxRefIndeterminateValue(false);
    return nodeToGetValueFrom.isChecked;
  };

  const setCheckedValueForChildren = (isChecked, currentChildren, index = 0) => {
    const updatedChildren = currentChildren.map((child, i) => {
      if (i === positionInTree[index]) {
        if (index === positionInTree.length - 1) {
          child.isChecked = isChecked;
        } else {
          child.children = setCheckedValueForChildren(
            isChecked,
            child.children,
            index + 1
          );
        }
      }

      return child;
    });

    return updatedChildren;
  };

  const setTreeNodesStructure = (isChecked) => {
    setTreeNodes(prev => setCheckedValueForChildren(isChecked, prev));
  };

  return (
    <Input
      type="checkbox"
      innerRef={checkboxRef}
      checked={getIsChecked()}
      onChange={(e) => {
        onCheckboxClick(getChildNodeFromTreeNode().file);
        setTreeNodesStructure(e.target.checked);
      }}
    />
  );
}
