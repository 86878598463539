import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";
import FileDetails from "./FileDetails";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";

function FileDetailsModal(props) {
  const {
    file,
    updateFile,
    updateTags,
    volumeType,
    close,
    showModal,
    isInSearchSection
  } = props;

  return (
    <>
      {file.path && (
        <Modal size="lg" toggle={close} isOpen={showModal}>
          <ModalHeader toggle={close}>File Details</ModalHeader>
          <ModalBody>
            <FileDetails
              file={file}
              updateFile={updateFile}
              updateTags={updateTags}
              volumeType={volumeType}
            />
          </ModalBody>
          <ModalFooter>
            {isInSearchSection &&
              <>
                <Link
                  to={`/filemanagement?path=${file.path}&volumeId=${file.volumeId}&fileName=${file.name}`}
                >
                  <Button
                    id="goToFilemanagement"
                    color="info"
                  >
                    <FontAwesomeIcon icon={faServer} />
                  </Button>
                </Link>
                <UncontrolledTooltip placement="auto" target="goToFilemanagement">
                  Show file in File Management
                </UncontrolledTooltip>
              </>
            }
            <Button onClick={close} className="ms-auto">
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default FileDetailsModal;
