import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardModal from "../../components/WizardModal";
import UserForm from "./UserForm";
import GroupManagement from "./GroupManagement";
import UserWizardReviewPage from "./UserWizardReviewPage";
import { fetchWithAuth } from "../../actions/requestHelper";
import { fetchRoles } from "../../ducks/role";
import { generateRandomNumber } from "../../utils/generateRandomValue";

function UserWizard({
  closeWizardFunction,
  isVisible,
  saveUser,
  userFormObj,
  setUserFormObj,
  groups,
  groupList,
  errorsObj,
  isCreateForm,
  handleAddGroup,
  handleRemoveGroup,
  availableGroups,
  setAvailableGroups,
  selectedGroups,
  createUser,
  isFormValid,
  setIsFormValid,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [
    existingGroupsAndGroupsBeforeCreation,
    setExistingGroupsAndGroupsBeforeCreation,
  ] = useState(
    useSelector(({ roles }) =>
      roles.items.filter((group) => group.type === "GROUP")
    )
  );

  const TITLE_ARRAY = ["Create User", "Add Group", "Review"];
  const DEFAULT_IS_BLURRED_ONCE_STATE = {
    name: false,
    username: false,
    email: false,
    password: false,
  };

  const [stepIndex, setStepIndex] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [groupsCreatedWithWizard, setGroupsCreatedWithWizard] = useState([]);
  const [
    hasAlreadyCreatedGroupsFromWizard,
    setHasAlreadyCreatedGroupsFromWizard,
  ] = useState(false);
  const [isUserFormInputBlurredOnce, setIsUserFormInputBlurredOnce] = useState(
    DEFAULT_IS_BLURRED_ONCE_STATE
  );
  const [showCreateGroupInWizard, setShowCreateGroupInWizard] = useState(false);

  const emptyGroup = {
    name: "",
    description: "",
    type: "GROUP",
    privileges: [],
  };
  const [groupObjForWizard, setGroupObjForWizard] = useState(emptyGroup);
  const [disableAddGroup, setDisableAddGroup] = useState(true);

  const addGroupForStep2 = (group) => {
    handleAddGroup(group);
    setGroupsCreatedWithWizard((prev) => [...prev, group]);
    setExistingGroupsAndGroupsBeforeCreation((prev) => [...prev, group]);
    setGroupObjForWizard(emptyGroup);
  };

  const handleGroupSelectInput = (inputValue) => {
    if (inputValue === "create group") {
      setShowCreateGroupInWizard(true);
      setHasAlreadyCreatedGroupsFromWizard(false);
    } else {
      handleAddGroup(availableGroups[inputValue]);
      setAvailableGroups(
        availableGroups.filter((group) => group !== inputValue)
      );
    }
  };

  const removeGroup = (group) => {
    if (!groupsCreatedWithWizard.some((g) => g.name === group.name)) {
      setAvailableGroups([...availableGroups, group]);
    }
    handleRemoveGroup(group);
    setGroupsCreatedWithWizard((prev) =>
      prev.filter((g) => g.name !== group.name)
    );
  };

  const userWizardSteps = [
    <UserForm
      userGroups={groups}
      groupList={groupList}
      errors={errorsObj}
      isCreateForm={isCreateForm}
      handleAddGroup={handleAddGroup}
      handleRemoveGroup={handleRemoveGroup}
      userFormObj={userFormObj}
      setUserFormObj={setUserFormObj}
      setIsFormValid={setIsFormValid}
      isBlurredOnce={isUserFormInputBlurredOnce}
      setIsBlurredOnce={setIsUserFormInputBlurredOnce}
    />,
    <GroupManagement
      availableGroups={availableGroups}
      selectedGroups={selectedGroups}
      showCreateGroupInWizard={showCreateGroupInWizard}
      existingGroupsAndGroupsBeforeCreation={
        existingGroupsAndGroupsBeforeCreation
      }
      groupObjForWizard={groupObjForWizard}
      setGroupObjForWizard={setGroupObjForWizard}
      setDisableAddGroup={setDisableAddGroup}
      handleGroupSelectInput={handleGroupSelectInput}
      removeGroup={removeGroup}
    />,
    <UserWizardReviewPage userFormObj={userFormObj} groups={groups} />,
  ];
  const STEPS_LENGTH = userWizardSteps.length;

  const closeWizard = () => {
    closeWizardFunction();
    setStepIndex(0);
    setIsProcessing(false);
    setIsUserFormInputBlurredOnce(DEFAULT_IS_BLURRED_ONCE_STATE);
    setExistingGroupsAndGroupsBeforeCreation([]);
    setShowCreateGroupInWizard(false);
    setGroupObjForWizard(emptyGroup);
  };

  const createGroupWithPrivilegeFromWizard = async (group) => {
    // Giving a copy to avoid display issues in UserWizardReviewPage
    const groupCopy = JSON.parse(JSON.stringify(group));
    delete groupCopy.id;
    delete groupCopy.type;

    const newGroup = await fetchWithAuth(
      dispatch,
      "/v1/roles",
      auth.accessToken,
      "POST",
      JSON.stringify(groupCopy)
    );
    return newGroup;
  };

  const handleGroupsCreatedWithWizard = async () => {
    // to avoid recreating groups if there's an error during the wizard saving process
    if (!hasAlreadyCreatedGroupsFromWizard) {
      const groupsToCreate = await Promise.all(
        groupsCreatedWithWizard.map(async (group) => {
          return await createGroupWithPrivilegeFromWizard(group);
        })
      );
      const joinExistingGroupsWithWizardGroups = groupsToCreate
        .concat(groups)
        .filter((group) => group.id < 10_000);
      const createUserResult = createUser(
        userFormObj.name,
        userFormObj.username,
        userFormObj.email,
        userFormObj.password,
        joinExistingGroupsWithWizardGroups
      );
      dispatch(fetchRoles());
      return createUserResult;
    } else {
      const createUserResult = createUser(
        userFormObj.name,
        userFormObj.username,
        userFormObj.email,
        userFormObj.password,
        groups
      );
      dispatch(fetchRoles());
      return createUserResult;
    }
  };

  const handleSave = async () => {
    setIsProcessing(true);
    const res = groupsCreatedWithWizard.length
      ? await handleGroupsCreatedWithWizard()
      : await saveUser();
    let subErrors = undefined,
      message = undefined;

    if (!!res) {
      subErrors = res.subErrors;
      message = res.message;
    }

    if (
      (subErrors && subErrors.length) ||
      (message && message.includes("should be unique"))
    ) {
      setIsProcessing(false);
      setStepIndex(0);
    } else {
      setIsProcessing(false);
      closeWizard();
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        closeWizard();
        break;

      case "Cancel group creation":
        setGroupObjForWizard(emptyGroup);
        setShowCreateGroupInWizard(false);
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Save":
        handleSave();
        break;

      case "Add group":
        const tempId = generateRandomNumber(50_000, 10_000);
        const customGroup = { ...groupObjForWizard, id: tempId };

        addGroupForStep2(customGroup);
        setShowCreateGroupInWizard(false);
        setDisableAddGroup(true);
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (showCreateGroupInWizard) {
      return "Cancel group creation";
    } else if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (showCreateGroupInWizard) {
      return "Add group";
    } else if (stepIndex === STEPS_LENGTH - 1) {
      return "Save";
    } else {
      return "Next";
    }
  };

  const handleDisableNextStep = () => {
    if (showCreateGroupInWizard) {
      return disableAddGroup;
    } else {
      return !isFormValid;
    }
  };

  return (
    <WizardModal
      stepIndex={stepIndex + 1}
      stepsLength={STEPS_LENGTH}
      showWizard={isVisible}
      title="User"
      subTitle={TITLE_ARRAY[stepIndex]}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={handleDisableNextStep()}
      isProcessing={isProcessing}
      onWizardExit={closeWizard}
    >
      {userWizardSteps[stepIndex]}
    </WizardModal>
  );
}

export default UserWizard;
