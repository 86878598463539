import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";

// === Actions ===
const REQUEST_CONFIGURATIONS = "REQUEST_CONFIGURATIONS";
const RECEIVE_CONFIGURATIONS = "RECEIVE_CONFIGURATIONS";
const RECEIVE_LOGIN_CONFIGURATIONS = "RECEIVE_LOGIN_CONFIGURATIONS";

// === Reducers ===
const DEFAULT_STATE_VALUE = {
  name: "",
  description: "",
  value: "",
  isHashed: false,
};

export default function configurations(
  state = {
    minimumSize: DEFAULT_STATE_VALUE,
    snapshotNumberOfDays: DEFAULT_STATE_VALUE,
    deletedFilesNumberOfDays: DEFAULT_STATE_VALUE,
    snapshotKeepFirstDayOfMonth: DEFAULT_STATE_VALUE,
    appURL: DEFAULT_STATE_VALUE,
    jobRetention: DEFAULT_STATE_VALUE,
    jobQueuedLength: DEFAULT_STATE_VALUE,
    isHealthCheckActive: DEFAULT_STATE_VALUE,
    isPerformanceModeActive: DEFAULT_STATE_VALUE,
    isAlwaysUpdateActive: DEFAULT_STATE_VALUE,
    isAdvancedScanActive: DEFAULT_STATE_VALUE,
    folderDepth: DEFAULT_STATE_VALUE,
    numberOfFiles: DEFAULT_STATE_VALUE,
    isBetaActive: DEFAULT_STATE_VALUE,
    mailSMTPAuth: DEFAULT_STATE_VALUE,
    mailHostname: DEFAULT_STATE_VALUE,
    mailUsername: DEFAULT_STATE_VALUE,
    mailFrom: DEFAULT_STATE_VALUE,
    mailPort: DEFAULT_STATE_VALUE,
    mailTlsEnable: DEFAULT_STATE_VALUE,
    mailSslEnable: DEFAULT_STATE_VALUE,
    mailTlsRequired: DEFAULT_STATE_VALUE,
    sodaURL: DEFAULT_STATE_VALUE,
    sodaDeploymentId: DEFAULT_STATE_VALUE,
    sodaClientId: DEFAULT_STATE_VALUE,
    sodaNumberOfThreads: DEFAULT_STATE_VALUE,
    isAppLoginActive: DEFAULT_STATE_VALUE,
    isEntraIdLoginActive: DEFAULT_STATE_VALUE,
    entraIdClientId: DEFAULT_STATE_VALUE,
    entraIdTenantId: DEFAULT_STATE_VALUE,
    entraIdRedirectId: DEFAULT_STATE_VALUE,
    isOktaLoginActive: DEFAULT_STATE_VALUE,
    oktaClientId: DEFAULT_STATE_VALUE,
    oktaDomain: DEFAULT_STATE_VALUE,
    oktaRedirectUri: DEFAULT_STATE_VALUE,
    oktaApiToken: DEFAULT_STATE_VALUE,
    iconikAppId: DEFAULT_STATE_VALUE,
    iconikAuthToken: DEFAULT_STATE_VALUE,
    scanDellPowerscaleNumberOfThreads: DEFAULT_STATE_VALUE,
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_CONFIGURATIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CONFIGURATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        minimumSize: findConfigurationByName(
          action.configurations,
          "scan.tagDuplicate.minimumSize"
        ),
        snapshotNumberOfDays: findConfigurationByName(
          action.configurations,
          "snapshot.numberOfDays"
        ),
        snapshotKeepFirstDayOfMonth: findConfigurationByName(
          action.configurations,
          "snapshot.keepFirstDayOfMonth"
        ),
        deletedFilesNumberOfDays: findConfigurationByName(
          action.configurations,
          "app.deletedFiles.numberOfDays"
        ),
        appURL: findConfigurationByName(action.configurations, "app.url"),
        jobRetention: findConfigurationByName(
          action.configurations,
          "job.retention"
        ),
        jobQueuedLength: findConfigurationByName(
          action.configurations,
          "job.queuedLength"
        ),
        isHealthCheckActive: findConfigurationByName(
          action.configurations,
          "app.scanner.isHealthCheckActive"
        ),
        isPerformanceModeActive: findConfigurationByName(
          action.configurations,
          "app.performanceMode"
        ),
        isAlwaysUpdateActive: findConfigurationByName(
          action.configurations,
          "scan.firstscan.alwaysupdate"
        ),
        isAdvancedScanActive: findConfigurationByName(
          action.configurations,
          "scan.advanced.isAdvancedScan"
        ),
        folderDepth: findConfigurationByName(
          action.configurations,
          "scan.advanced.folderDepth"
        ),
        numberOfFiles: findConfigurationByName(
          action.configurations,
          "scan.advanced.numberOfFiles"
        ),
        isBetaActive: findConfigurationByName(
          action.configurations,
          "app.isBetaActive"
        ),
        mailSMTPAuth: findConfigurationByName(
          action.configurations,
          "spring.mail.properties.mail.smtp.auth"
        ),
        mailUsername: findConfigurationByName(
          action.configurations,
          "spring.mail.username"
        ),
        mailFrom: findConfigurationByName(
          action.configurations,
          "spring.mail.from"
        ),
        mailHostname: findConfigurationByName(
          action.configurations,
          "spring.mail.host"
        ),
        mailPort: findConfigurationByName(
          action.configurations,
          "spring.mail.port"
        ),
        mailTlsEnable: findConfigurationByName(
          action.configurations,
          "spring.mail.properties.mail.smtp.starttls.enable"
        ),
        mailTlsRequired: findConfigurationByName(
          action.configurations,
          "spring.mail.properties.mail.smtp.starttls.required"
        ),
        mailSslEnable: findConfigurationByName(
          action.configurations,
          "spring.mail.properties.mail.smtp.ssl.enable"
        ),
        sodaURL: findConfigurationByName(action.configurations, "soda.url"),
        sodaDeploymentId: findConfigurationByName(
          action.configurations,
          "soda.deploymentId"
        ),
        sodaClientId: findConfigurationByName(
          action.configurations,
          "soda.clientId"
        ),
        sodaNumberOfThreads: findConfigurationByName(
          action.configurations,
          "soda.numberOfThreads"
        ),
        isAppLoginActive: findConfigurationByName(
          action.configurations,
          "app.login.active"
        ),
        isEntraIdLoginActive: findConfigurationByName(
          action.configurations,
          "sso.entraid.active"
        ),
        entraIdClientId: findConfigurationByName(
          action.configurations,
          "sso.entraid.clientid"
        ),
        entraIdTenantId: findConfigurationByName(
          action.configurations,
          "sso.entraid.tenantid"
        ),
        entraIdRedirectId: findConfigurationByName(
          action.configurations,
          "sso.entraid.redirecturi"
        ),
        isOktaLoginActive: findConfigurationByName(
          action.configurations,
          "sso.okta.active"
        ),
        oktaClientId: findConfigurationByName(
          action.configurations,
          "sso.okta.clientid"
        ),
        oktaDomain: findConfigurationByName(
          action.configurations,
          "sso.okta.domain"
        ),
        oktaRedirectUri: findConfigurationByName(
          action.configurations,
          "sso.okta.redirecturi"
        ),
        oktaApiToken: findConfigurationByName(
          action.configurations,
          "sso.okta.apitoken"
        ),
        iconikAppId: findConfigurationByName(
          action.configurations,
          "iconik.appid"
        ),
        iconikAuthToken: findConfigurationByName(
          action.configurations,
          "iconik.authtoken"
        ),
        scanDellPowerscaleNumberOfThreads: findConfigurationByName(
          action.configurations,
          "scan.dellPowerscale.numberOfThreads"
        )
      });
    case RECEIVE_LOGIN_CONFIGURATIONS:
      return Object.assign({}, state, {
        isAppLoginActive: {
          name: "app.login.active",
          value: action.loginConfigurations.defaultLoginActive
            ? "true"
            : "false",
        },
        isEntraIdLoginActive: {
          name: "sso.entraid.active",
          value: action.loginConfigurations.entraIdLoginConfigurations
            .loginActive
            ? "true"
            : "false",
        },
        entraIdClientId: {
          name: "sso.entraid.clientid",
          value: action.loginConfigurations.entraIdLoginConfigurations.clientId,
        },
        entraIdTenantId: {
          name: "sso.entraid.tenantid",
          value: action.loginConfigurations.entraIdLoginConfigurations.tenantId,
        },
        entraIdRedirectId: {
          name: "sso.entraid.redirecturi",
          value:
            action.loginConfigurations.entraIdLoginConfigurations.redirectUri,
        },
        isOktaLoginActive: {
          name: "sso.okta.active",
          value: action.loginConfigurations.oktaLoginConfigurations.loginActive
            ? "true"
            : "false",
        },
        oktaClientId: {
          name: "sso.okta.clientid",
          value: action.loginConfigurations.oktaLoginConfigurations.clientId,
        },
        oktaDomain: {
          name: "sso.okta.domain",
          value: action.loginConfigurations.oktaLoginConfigurations.domain,
        },
        oktaRedirectUri: {
          name: "sso.okta.redirecturi",
          value: action.loginConfigurations.oktaLoginConfigurations.redirectUri,
        },
        oktaApiToken: {
          name: "sso.okta.apitoken",
          value:
            action.loginConfigurations.oktaLoginConfigurations.apiToken,
        },
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestConfigurations() {
  return {
    type: REQUEST_CONFIGURATIONS,
  };
}

function receiveConfigurations(configurations) {
  return {
    type: RECEIVE_CONFIGURATIONS,
    configurations,
  };
}

export function receiveLoginConfigurations(loginConfigurations) {
  return {
    type: RECEIVE_LOGIN_CONFIGURATIONS,
    loginConfigurations,
  };
}

// === Side Effects ===
export function fetchConfigurations() {
  return (dispatch, getState) => {
    dispatch(requestConfigurations());
    const url = `/configurations`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then((json) => {
        dispatch(receiveConfigurations(json));
      })
      .catch((err) => console.error(err));
  };
}

export function updateConfigurations(
  body,
  successMessage = {
    message: `The configurations were updated.`,
    type: "success",
    timer: 8000,
  },
  errorMessage = `Unable to update the configurations`,
  withAlert = true
) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      dispatch,
      `/configurations`,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then((response) => {
        dispatch(fetchConfigurations());
        withAlert &&
          dispatch(
            setAlert(
              successMessage.message,
              successMessage.type,
              successMessage.timer
            )
          );
      })
      .catch((error) => {
        withAlert &&
          dispatch(setAlert(`${errorMessage}: ${error.title}`, "danger"));
      });
  };
}

export function updateEmailPassword(body) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      dispatch,
      `/configurations`,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then((response) => {
        dispatch(fetchConfigurations());
        dispatch(setAlert(`The configurations were updated.`, "success"));
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Unable to update the configurations: ${error.title}`,
            "danger"
          )
        );
      });
  };
}

export function testEmailConfigurations() {
  return (dispatch, getState) => {
    return fetchWithAuth(
      dispatch,
      `/v1/emails/tests`,
      getState().auth.accessToken,
      "POST"
    )
      .then((response) => {
        dispatch(fetchConfigurations());
        dispatch(
          setAlert(
            `The email configurations were tested successfully.`,
            "success"
          )
        );
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Unable to test the email configurations: ${error.message}`,
            "danger"
          )
        );
      });
  };
}

function findConfigurationByName(configurations, name) {
  return (
    configurations.find((config) => config.name === name) ?? DEFAULT_STATE_VALUE
  );
}
