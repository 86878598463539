import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ARCHIWARE_BUNDLE,
  ICONIK_BUNDLE,
  SODA_BUNDLE,
  STORAGE_MANAGER_BUNDLE,
} from "../../constants/bundles";
import {
  ROLE_ARCHIWARE_ARCHIVE,
  ROLE_ARCHIWARE_RESTORE,
  ROLE_SODA_TRANSFER,
  ROLE_STORAGE_MANAGER_ARCHIVE,
  ROLE_STORAGE_MANAGER_RESTORE,
  ROLE_ICONIK_UPLOAD,
} from "../../constants/roles";
import { updateFileManagementFile } from "../../ducks/fileManagement";
import { updateTags } from "../../ducks/tag";
import Authorization from "../../validation/Authorization";
import HasBundle from "../../validation/HasBundle";
import ArchiveSelectionButton from "../ArchiveSelectionButton";
import ArchiveSelectionWizard from "../archiwareArchiveRestore/ArchiveSelectionWizard";
import RestoreFromArchiveWizard from "../archiwareArchiveRestore/RestoreFromArchiveWizard";
import CheckboxButton from "../CheckboxButton";
import RestoreArchiveButton from "../RestoreArchiveButton";
import SodaActionWizard from "../soda/SodaActionWizard";
import SodaActionButton from "../SodaActionButton";
import StorageManagerActionButton from "../storageManager/StorageManagerActionButton";
import StorageManagerActionWizard from "../storageManager/StorageManagerActionWizard";
import TableActions from "../TableActions";
import TagMultipleFilesButton from "../tags/TagMultipleFilesButton";
import TagMultipleFilesPopover from "../tags/TagMultipleFilesPopover";
import IconikActionButton from "../iconik/IconikActionButton";
import IconikActionWizard from "../iconik/IconikActionWizard";

export default function FolderTreeActions(props) {
  const {
    treeNodes,
    setTreeNodes,
    setIsSelectAllVisibleTriggered,
    setIsSelectNoneTriggered,
  } = props;

  const dispatch = useDispatch();

  const [isAnyNodeChecked, setIsAnyNodeChecked] = useState(false);
  const [isApplyTagMultipleFilesOpen, setIsApplyTagMultipleFilesOpen] =
    useState(false);
  const [isRemoveTagMultipleFilesOpen, setIsRemoveTagMultipleFilesOpen] =
    useState(false);
  const [showArchiveSelectionWizard, setShowArchiveSelectionWizard] =
    useState(false);
  const [showRestoreFromArchiveWizard, setShowRestoreFromArchiveWizard] =
    useState(false);
  const [showSodaActionWizard, setShowSodaActionWizard] = useState(false);
  const [showIconikActionWizard, setShowIconikActionWizard] = useState(false);
  const [showStorageManagerActionWizard, setShowStorageManagerActionWizard] =
    useState(false);

  useEffect(() => {
    setIsAnyNodeChecked(findAnyCheckedNode(treeNodes));
  }, [treeNodes]);

  const findAnyCheckedNode = (children) => {
    for (var child of children) {
      if (child.isChecked) {
        return true;
      }

      if (child.children.length > 0) {
        if (findAnyCheckedNode(child.children)) {
          return true;
        }
      }
    }

    return false;
  };

  const getSelectedFiles = (
    children,
    currentPositionInTree = [],
    selectedFiles = []
  ) => {
    children.forEach((child, index) => {
      const childPositionInTree = [...currentPositionInTree, index];
      if (child.isChecked) {
        const file = child.file;
        selectedFiles.push({ file: file, positionInTree: childPositionInTree });
      }

      if (child.children.length > 0) {
        getSelectedFiles(child.children, childPositionInTree, selectedFiles);
      }
    });

    return selectedFiles;
  };

  const getListOfFilesAndFolders = (children) => {
    const selectedFiles = getSelectedFiles(children);

    return {
      files: selectedFiles.map((f) => f.file),
      positionInTree: selectedFiles.map((f) => f.positionInTree),
    };
  };

  const setUpdatedFileForChildren = (
    files,
    positionsInTree,
    currentChild,
    index = 0,
    nextFileToUpdateIndex = 0
  ) => {
    const updatedChildren = currentChild.children.map((child, i) => {
      if (
        positionsInTree.length > nextFileToUpdateIndex &&
        i === positionsInTree[nextFileToUpdateIndex][index]
      ) {
        if (index === positionsInTree[nextFileToUpdateIndex].length - 1) {
          child.file = files[nextFileToUpdateIndex];
          nextFileToUpdateIndex++;

          if (positionsInTree.length === nextFileToUpdateIndex) {
            return child;
          }
        }

        child.children = setUpdatedFileForChildren(
          files,
          positionsInTree,
          child,
          index + 1,
          nextFileToUpdateIndex
        );
      }

      return child;
    });

    return updatedChildren;
  };

  const updateSelectedFiles = (files, positionsInTree) => {
    setTreeNodes((prev) =>
      setUpdatedFileForChildren(files, positionsInTree, { children: prev })
    );
  };

  const selectedFilesWithPositionInTree = getListOfFilesAndFolders(treeNodes);

  return (
    <TableActions
      selectedFiles={selectedFilesWithPositionInTree.files}
      showPagination={false}
      marginXTableAction="-15px"
      marginRightSelectionBadge="5px"
    >
      <>
        <CheckboxButton
          handleSelectNone={() => setIsSelectNoneTriggered(true)}
          handleSelectCurrentPage={() => setIsSelectAllVisibleTriggered(true)}
          handleSelectAll={() => { }}
          isSelectAllAvailable={false}
          isCurrentPageSelected={isAnyNodeChecked}
          isSelectAllSelected={false}
          selectCurrentText="Select all visible"
        />
        {isAnyNodeChecked && (
          <>
            <div className="ms-2">
              <TagMultipleFilesButton
                id="applyTagMultipleFiles"
                isMultipleTagsOpen={isApplyTagMultipleFilesOpen}
                deleteTag={false}
              />
              <TagMultipleFilesPopover
                target="applyTagMultipleFiles"
                isTagMultipleFilesOpen={isApplyTagMultipleFilesOpen}
                setIsTagMultipleFilesOpen={setIsApplyTagMultipleFilesOpen}
                selectedFiles={selectedFilesWithPositionInTree.files}
                setSelectedFiles={(files) =>
                  updateSelectedFiles(
                    files,
                    selectedFilesWithPositionInTree.positionInTree
                  )
                }
                updateFile={(id, file) =>
                  dispatch(updateFileManagementFile(id, file))
                }
                updateTags={(tags) => dispatch(updateTags(tags))}
                useSearchResult={false}
                search=""
                isDirectory={false}
                deleteTag={false}
              />
            </div>
            <div className="ms-2">
              <TagMultipleFilesButton
                id="removeTagMultipleFiles"
                isMultipleTagsOpen={isRemoveTagMultipleFilesOpen}
                deleteTag
              />
              <TagMultipleFilesPopover
                target="removeTagMultipleFiles"
                isTagMultipleFilesOpen={isRemoveTagMultipleFilesOpen}
                setIsTagMultipleFilesOpen={setIsRemoveTagMultipleFilesOpen}
                selectedFiles={selectedFilesWithPositionInTree.files}
                setSelectedFiles={(files) =>
                  updateSelectedFiles(
                    files,
                    selectedFilesWithPositionInTree.positionInTree
                  )
                }
                updateFile={(id, file) =>
                  dispatch(updateFileManagementFile(id, file))
                }
                updateTags={(tags) => dispatch(updateTags(tags))}
                useSearchResult={false}
                search=""
                isDirectory={false}
                deleteTag
              />
            </div>
            <HasBundle requiredBundle={ARCHIWARE_BUNDLE} hasOverlay={false}>
              <Authorization requiredAuthorizations={[ROLE_ARCHIWARE_ARCHIVE]}>
                <div className="ms-2">
                  <ArchiveSelectionButton
                    onClickFunction={() => setShowArchiveSelectionWizard(true)}
                  />
                  <ArchiveSelectionWizard
                    showWizard={showArchiveSelectionWizard}
                    setShowWizard={setShowArchiveSelectionWizard}
                    selectedFiles={selectedFilesWithPositionInTree.files}
                    useSearchResultForActions={false}
                  />
                </div>
              </Authorization>
              <Authorization requiredAuthorizations={[ROLE_ARCHIWARE_RESTORE]}>
                <div className="ms-2">
                  <RestoreArchiveButton
                    onClickFunction={() =>
                      setShowRestoreFromArchiveWizard(true)
                    }
                  />
                  <RestoreFromArchiveWizard
                    showWizard={showRestoreFromArchiveWizard}
                    setShowWizard={setShowRestoreFromArchiveWizard}
                    selectedFiles={selectedFilesWithPositionInTree.files}
                    useSearchResultForActions={false}
                  />
                </div>
              </Authorization>
            </HasBundle>
            <HasBundle requiredBundle={SODA_BUNDLE} hasOverlay={false}>
              <Authorization requiredAuthorizations={[ROLE_SODA_TRANSFER]}>
                <div className="ms-2">
                  <div style={{ marginLeft: "4px" }}>
                    <SodaActionButton
                      onClickFunction={() => setShowSodaActionWizard(true)}
                    />
                  </div>
                  <SodaActionWizard
                    showWizard={showSodaActionWizard}
                    setShowWizard={setShowSodaActionWizard}
                    selectedFiles={selectedFilesWithPositionInTree.files}
                    useSearchResultForActions={false}
                    search=""
                    isDirectory={false}
                  />
                </div>
              </Authorization>
            </HasBundle>
            <HasBundle
              requiredBundle={STORAGE_MANAGER_BUNDLE}
              hasOverlay={false}
            >
              <Authorization
                requiredAuthorizations={[
                  ROLE_STORAGE_MANAGER_ARCHIVE,
                  ROLE_STORAGE_MANAGER_RESTORE,
                ]}
              >
                <div className="ms-2">
                  <div style={{ marginLeft: "4px" }}>
                    <StorageManagerActionButton
                      onClickFunction={() =>
                        setShowStorageManagerActionWizard(true)
                      }
                    />
                  </div>
                  <StorageManagerActionWizard
                    showWizard={showStorageManagerActionWizard}
                    setShowWizard={setShowStorageManagerActionWizard}
                    selectedFiles={selectedFilesWithPositionInTree.files}
                    useSearchResultForActions={false}
                    search=""
                  />
                </div>
              </Authorization>
            </HasBundle>
            <HasBundle requiredBundle={ICONIK_BUNDLE} hasOverlay={false}>
              <Authorization requiredAuthorizations={[ROLE_ICONIK_UPLOAD]}>
                <div className="ms-2">
                  <div style={{ marginLeft: "4px" }}>
                    <IconikActionButton
                      onClickFunction={() => {
                        setShowIconikActionWizard(true);
                      }}
                    />
                  </div>
                  <IconikActionWizard
                    showWizard={showIconikActionWizard}
                    setShowWizard={setShowIconikActionWizard}
                    selectedFiles={selectedFilesWithPositionInTree.files}
                    useSearchResultForActions={false}
                    search={search}
                    isDirectory={false}
                  />
                </div>
              </Authorization>
            </HasBundle>
          </>
        )}
      </>
    </TableActions>
  );
}
