export function generatePassword() {
  return Math.random().toString(36).slice(-8);
}

// Random number between max and min, where min is 0 by default
export function generateRandomNumber(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateUniqueId() {
  return '_' + Math.random().toString(36).slice(2, 11);
}