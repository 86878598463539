import { useEffect, useState } from "react";
import SearchFilter from "./SearchFilter";
import SearchQueryNode from "./SearchQueryNode";
import { generateUniqueId } from "../../utils/generateRandomValue";

const SIDE_BARS_STYLE = { 
  border: "1px solid rgba(0,0,0,0.1)", 
  width: "35%", 
  display: "inline-block", 
  verticalAlign: "middle", 
  marginRight: 30,
  marginLeft: 30
};

const BASE_SEARCH_QUERIES = {
  name: { value: "" },
  excludeFilter: false
};

function parseSearchQuery(query) {
  const operators = {
      '&&': 'AND',
      '||': 'OR'
  };

  function parseCondition(condition) {
    let trimmedCondition = condition.trim().split(" ");
    if (trimmedCondition[1] === "not") {
      if (trimmedCondition[2] === "equals") {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: trimmedCondition[3],
          excludeFilter: true
        });
      } else if (trimmedCondition[2] === "values") {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: {[trimmedCondition[2]]: trimmedCondition[3].split(",")},
          excludeFilter: true
        });
      } else {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: {[trimmedCondition[2]]: trimmedCondition[3]},
          excludeFilter: true
        });
      }
    } else {
      if (trimmedCondition[1] === "equals") {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: trimmedCondition[3],
          excludeFilter: false
        });
      } else if (trimmedCondition[1] === "values") {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: {[trimmedCondition[1]]: trimmedCondition[2].split(",")},
          excludeFilter: true
        });
      } else {
        return ({
          id: generateUniqueId(),
          [trimmedCondition[0]]: {[trimmedCondition[1]]: trimmedCondition[2]},
          excludeFilter: false
        });
      }
    }
  }

  function parseQuery(query) {
      let depth = 0;
      let startIndex = 0;
      let queries = [];
      let currentOperator = '||';

      for (let i = 0; i < query.length; i++) {
          if (query[i] === '(') {
              if (depth === 0) {
                  startIndex = i + 1;
              }
              depth++;
          } else if (query[i] === ')') {
              depth--;
              if (depth === 0) {
                  queries.push(parseQuery(query.substring(startIndex, i)));
              }
          } else if (depth === 0) {
              if (query[i] === '&' && query[i + 1] === '&') {
                  currentOperator = '&&';
                  queries.push(query.substring(startIndex, i).trim());
                  startIndex = i + 2;
                  i++;
              } else if (query[i] === '|' && query[i + 1] === '|') {
                  currentOperator = '||';
                  queries.push(query.substring(startIndex, i).trim());
                  startIndex = i + 2;
                  i++;
              }
          }
      }

      // The leftover
      if (startIndex < query.length) {
          queries.push(query.substring(startIndex).trim());
      }

      const searchQueryNodes = [];
      const groupedQueries = [];
      
      queries.forEach(q => {
        if (typeof q === "string") {
          if (!q.endsWith(')')) {
            groupedQueries.push(parseCondition(q));
          }
        } else {
          searchQueryNodes.push(q);
        }
      });

      if (groupedQueries.length > 0) {
        searchQueryNodes.push({ 
          searchOperator: operators[currentOperator] || 'OR',
          searchQueries: groupedQueries 
        });
      }

      if (searchQueryNodes.length === 1 && searchQueryNodes[0].searchQueries) {
        return searchQueryNodes[0];
      }

      return {
          searchOperator: operators[currentOperator] || 'OR',
          searchQueryNodes
      };
  }

  const result = parseQuery(query);

  if (!!result.searchQueryNodes && result.searchQueryNodes.length > 1 ||
    !!result.searchQueries && result.searchQueries.length > 1) {
    return { searchOperator: "AND", searchQueryNodes: [ result ] };
  } else {
    return result;
  }
}

export default function SearchQueryNodes(props) {
  const { 
    initialSearchQueryNodes,
    setParentSearchQueryNodes = () => {},
    setSearchQueryNodesString = () => {}
  } = props;

  const [searchQueryNodes, setSearchQueryNodes] = useState({ searchOperator: "AND", searchQueries: [ { ...BASE_SEARCH_QUERIES, id: generateUniqueId(), } ] });
  const [searchQueryNodesStringValue, setSearchQueryNodesStringValue] = useState("");

  useEffect(() => {
    if (initialSearchQueryNodes) {
      if (!!initialSearchQueryNodes.searchQueryNodes && initialSearchQueryNodes.searchQueryNodes.length > 1 ||
        !!initialSearchQueryNodes.searchQueries && initialSearchQueryNodes.searchQueries.length > 1) {
        setSearchQueryNodes({ searchOperator: "AND", searchQueryNodes: [ initialSearchQueryNodes ] });
      } else {
        setSearchQueryNodes(initialSearchQueryNodes);
      }
    }
  }, []);

  useEffect(() => {
    setParentSearchQueryNodes(searchQueryNodes);

    const searchQueryNodesStringResult = getSearchQueryNodesString(searchQueryNodes);
    setSearchQueryNodesStringValue(searchQueryNodesStringResult);
    setSearchQueryNodesString(searchQueryNodesStringResult);
  }, [searchQueryNodes]);

  const getSearchQueryNodesString = (searchQueryNodesToUse) => {
    let string = "";

    if (!!searchQueryNodesToUse.searchQueryNodes) {
      if (searchQueryNodesToUse.searchQueryNodes.length > 1) {
        string += "(";
      }

      searchQueryNodesToUse.searchQueryNodes.forEach((searchQuery, index) => {
        string += getSearchQueryNodesString(searchQuery);

        if (index < searchQueryNodesToUse.searchQueryNodes.length - 1) {
          string += ` ${searchQueryNodesToUse.searchOperator === "OR" ? "||" : "&&"} `;
        }
      });

      if (searchQueryNodesToUse.searchQueryNodes.length > 1) {
        string += ")";
      }
    } else if (!!searchQueryNodesToUse.searchQueries) {
      string += "(";

      searchQueryNodesToUse.searchQueries.forEach((searchQuery, index) => {
        Object.entries(searchQuery).forEach(([key, value]) => {
          string += (key !== "excludeFilter" && key !== "id") ? 
            `${key} ${searchQuery.excludeFilter ? "not " : ""}${typeof value === "boolean" ? "equals" : Object.keys(value)[0]} ${typeof value === "boolean" ? value : Object.values(value)[0]}` : 
            ""
        });

        if (index < searchQueryNodesToUse.searchQueries.length - 1) {
          string += ` ${searchQueryNodesToUse.searchOperator === "OR" ? "||" : "&&"} `;
        }
      });

      string += ")";
    }

    return string;
  };

  const setUpdatedRootSearchQueryNode = (searchOperator, isNode) => {
    if (isNode) {
      setSearchQueryNodes(prev => ({ searchOperator: "AND", searchQueryNodes: [ { searchOperator: searchOperator, searchQueryNodes: [ ...prev.searchQueryNodes, { searchOperator: "AND", searchQueries: [ { ...BASE_SEARCH_QUERIES, id: generateUniqueId(), } ] } ] } ] }));
    } else {
      setSearchQueryNodes(prev => ({ searchOperator: "AND", searchQueryNodes: [ { searchOperator: searchOperator, searchQueries: [ ...prev.searchQueries, { ...BASE_SEARCH_QUERIES, id: generateUniqueId() }] } ] }));
    }
  };

  const setUpdatedSearchQueryNode = (position, searchOperator) => {
    setSearchQueryNodes(prev => updateSearchQueryNode(prev, position, searchOperator));
  };

  const setUpdatedSearchFilter = (position, valueIndex, value) => {
    setSearchQueryNodes(prev => updateSearchFilter(prev, position, valueIndex, value));
  };

  const setRemovedSearchQueryNode = (position) => {
    setSearchQueryNodes(prev => removeSearchQueryNode(prev, position));
  };

  const setRemovedSearchFilter = (position, valueIndex) => {
    setSearchQueryNodes(prev => removeSearchFilter(prev, position, valueIndex));
  };

  const removeSearchFilter = (currentNode, position, valueIndex, index = 0) => {
    if (position[index].isNode) {
      const updatedNode = currentNode.searchQueryNodes.map((node, i) => {
        if (i === position[index + 1].value) {
          node = removeSearchFilter(
            node,
            position,
            valueIndex,
            index + 1
          );
        }
  
        return node;
      });
  
      currentNode = { ...currentNode, searchQueryNodes: updatedNode };
      return currentNode;
    } else {
      const searchQueriesFromCurrentNode = [ ...currentNode.searchQueries ];
      searchQueriesFromCurrentNode.splice(valueIndex, 1);

      return { ...currentNode, searchQueries: searchQueriesFromCurrentNode };
    }
  };

  const removeSearchQueryNode = (currentNode, position, index = 0) => {
    // We remove the top node, which means that we reset to the default state
    if (position.length === 2) {
      return { searchOperator: "AND", searchQueries: [ { ...BASE_SEARCH_QUERIES, id: generateUniqueId(), } ] };
    }

    if (position[index].isNode) {
      // We are at the position of the parent that will get its child node removed
      if (index === position.length - 2) {
        const searchQueryNodesFromCurrentNode = [ ...currentNode.searchQueryNodes ];
        searchQueryNodesFromCurrentNode.splice(position[index + 1].value, 1);
        
        // If, after deletion, the parent has no child nodes left, delete it
        return searchQueryNodesFromCurrentNode.length === 0 ? null : {
          ...currentNode,
          searchQueryNodes: searchQueryNodesFromCurrentNode
        };
      }

      const updatedNode = currentNode.searchQueryNodes.map((node, i) => {
        if (i === position[index + 1].value) {
          node = removeSearchQueryNode(
            node,
            position,
            index + 1
          );
        }
  
        return node;
      });

      // We remove child nodes that have been deleted from the updated node
      const filteredUpdatedNode = updatedNode.filter(node => node !== null);
     
      // If the updated node does not contain any child nodes after the deletion, delete it
      currentNode = filteredUpdatedNode.length === 0 ? null : { ...currentNode, searchQueryNodes: filteredUpdatedNode };
      
      return currentNode;
    }

    return { ...currentNode };
  };

  const updateSearchFilter = (currentNode, position, valueIndex, value, index = 0) => {
    if (position[index].isNode) {
      const updatedNode = currentNode.searchQueryNodes.map((node, i) => {
        if (i === position[index + 1].value) {
          node = updateSearchFilter(
            node,
            position,
            valueIndex,
            value,
            index + 1
          );
        }
  
        return node;
      });
  
      currentNode = { ...currentNode, searchQueryNodes: updatedNode };
      return currentNode;
    } else {
      const updatedNode = currentNode.searchQueries.map((node, i) => i === valueIndex ? value : node);
      return { ...currentNode, searchQueries: updatedNode };
    }
  };

  const updateSearchQueryNode = (currentNode, position, searchOperator, index = 0) => {
    if (position[index].isNode) {
      if (index === position.length - 1) {
        return {
          ...currentNode,
          searchQueryNodes: [ 
            ...currentNode.searchQueryNodes, 
            { searchQueries: [{ ...BASE_SEARCH_QUERIES, id: generateUniqueId() }] }
          ],
          searchOperator: searchOperator
        };
      }

      const updatedNode = currentNode.searchQueryNodes.map((node, i) => {
        if (i === position[index + 1].value) {
          node = updateSearchQueryNode(
            node,
            position,
            searchOperator,
            index + 1
          );
        }
  
        return node;
      });
  
      currentNode = { ...currentNode, searchQueryNodes: updatedNode };
      return currentNode;
    }

    return {
      ...currentNode,
      searchOperator: searchOperator,
      searchQueries: [ 
        ...currentNode.searchQueries, 
        { ...BASE_SEARCH_QUERIES, id: generateUniqueId() }
      ]
    };
  };

  const getSearchQueryNodes = (searchQueryNodesToUse, position, depth = 0) => {
    // This happens when we delete the top node, so we reset to the default state
    if (searchQueryNodesToUse === null) {
      setSearchQueryNodes({ searchOperator: "AND", searchQueries: [ { ...BASE_SEARCH_QUERIES, id: generateUniqueId(), } ] });
      searchQueryNodesToUse = { searchOperator: "AND", searchQueries: [ { ...BASE_SEARCH_QUERIES, id: generateUniqueId(), } ] };
    }

    if (!!searchQueryNodesToUse.searchQueryNodes) {
      const queries = [];
      
      searchQueryNodesToUse.searchQueryNodes.forEach((searchQuery, index) => {
        queries.push(
          <>
            {getSearchQueryNodes(searchQuery, [ ...position, { value: index, isNode: !!searchQuery.searchQueryNodes} ], depth + 1)}
            {index < searchQueryNodesToUse.searchQueryNodes.length - 1 &&
              <span className="my-3" style={{ width: "100%", textAlign: "center", display: "inline-block" }}>
                <span style={SIDE_BARS_STYLE} />{searchQueryNodesToUse.searchOperator}<span style={SIDE_BARS_STYLE} />
              </span>
            }
          </>
        );  
      });
      
      return (
        <SearchQueryNode
          key={`node-${position.map(pos => pos.value).join('-')}`}
          isSearchQueries={false}
          operator={searchQueryNodesToUse.searchOperator}
          numberOfFilters={queries.length}
          position={position}
          setUpdatedSearchQueryNode={(searchOperator) => setUpdatedSearchQueryNode(position, searchOperator)}
          setUpdatedRootSearchQueryNode={(searchOperator, isNode) => setUpdatedRootSearchQueryNode(searchOperator, isNode)}
          removeSearchQueryNode={() => setRemovedSearchQueryNode(position)}
        >
          {queries}
        </SearchQueryNode>
      );
    } else if (!!searchQueryNodesToUse.searchQueries) {
      const filters = [];

      searchQueryNodesToUse.searchQueries.forEach((searchQuery, index) => {
        Object.entries(searchQuery).forEach(([key, value]) => {
          if (key !== "excludeFilter" && key !== "id" && value !== null) {
            let filter;
            let input;

            for (const k in value) {
              if (value[k] !== null) {
                filter = k;
                input = value[k];
              }
            }

            filters.push(
              <SearchFilter
                key={searchQuery.id}
                searchQueryId={searchQuery.id}
                operator={(index < searchQueryNodesToUse.searchQueries.length - 1) ? searchQueryNodesToUse.searchOperator : null} 
                keyInput={key} 
                filter={typeof value === "boolean" ? null : filter}
                input={typeof value === "boolean" ? value : input}
                isExcluded={searchQuery.excludeFilter}
                setUpdatedSearchQueryNodes={(value) => setUpdatedSearchFilter(position, index, value)}
                removeSearchFilter={searchQueryNodesToUse.searchQueries.length > 1 ? () => setRemovedSearchFilter(position, index) : null}
              />
            );
          }
        });
      });

      return (
        <SearchQueryNode
          key={`filters-${position.map(pos => pos.value).join('-')}`}
          isSearchQueries
          operator={searchQueryNodesToUse.searchOperator}
          numberOfFilters={filters.length}
          position={position}
          setUpdatedSearchQueryNode={(searchOperator) => setUpdatedSearchQueryNode(position, searchOperator)}
          setUpdatedRootSearchQueryNode={(searchOperator, isNode) => setUpdatedRootSearchQueryNode(searchOperator, isNode)}
          removeSearchQueryNode={() => setRemovedSearchQueryNode(position)}
        >
          {filters}
        </SearchQueryNode>
      );
    }
    
    return <></>;
  };

  return (
    <>
      <p>String format: {searchQueryNodesStringValue}</p>
      {getSearchQueryNodes(searchQueryNodes, [{ value: 0, isNode: searchQueryNodes && !!searchQueryNodes.searchQueryNodes }])}
    </>
  );
}