import { useState } from "react";
import { Input, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

export default function BrowserURLclipper({ url }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyLink = (link) => {
    const input = document.createElement("textarea");
    input.value = link;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  return (
    <div>
      <Input
        style={{ paddingRight: "55px" }}
        id="urlClipper"
        name="urlClipper"
        value={url}
        readOnly
      />
      <div className="d-flex">
        <FontAwesomeIcon
          id="searchModalClipboard"
          icon={faClipboard}
          color="primary"
          style={{
            position: "relative",
            color: "#6c757d",
            marginLeft: "auto",
            bottom: "26px",
            right: "22px",
            cursor: "pointer",
            transform: "scale(1.5)",
          }}
          onClick={() => {
            setShowTooltip(true);
            copyLink(url);
            setTimeout(() => {
              setShowTooltip(false);
            }, 2500);
          }}
        />
      </div>
      <Tooltip
        placement="top"
        target="searchModalClipboard"
        isOpen={showTooltip}
      >
        Copied!
      </Tooltip>
    </div>
  );
}
