import React from "react";
import { getCost } from "../../utils/pricePerVolume";
import Loading from "../../components/Loading";
import { STORAGE_COST_INTERVALS } from "../../constants/storageCostInterval";
import StorageCostCard from "../../components/StorageCostCard";

const VolumePricingCard = ({
  volumeId,
  showLoading,
  latestVolumeSettings,
  latestDetailedVolume,
  duplicateSize,
  unusedThreeMonthsSize,
  unusedSixMonthsSize,
  unusedOneYearSize,
  selectedCostInterval,
  setSelectedCostInterval,
  margin
}) => {
  const baseURL = `/search?volumeId=${volumeId}`;

  const getCostForStorage = (size) => {
    if (size === "cost") {
      return getCost(
        latestDetailedVolume.used,
        latestVolumeSettings.storagePrice.price,
        STORAGE_COST_INTERVALS[selectedCostInterval]
      ).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (size === "duplicate") {
      return getCost(
        duplicateSize,
        latestVolumeSettings.storagePrice.price,
        STORAGE_COST_INTERVALS[selectedCostInterval]
      ).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (size === "cold_three_months") {
      return getCost(
        unusedThreeMonthsSize,
        latestVolumeSettings.storagePrice.price,
        STORAGE_COST_INTERVALS[selectedCostInterval]
      ).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (size === "cold_six_months") {
      return getCost(
        unusedSixMonthsSize,
        latestVolumeSettings.storagePrice.price,
        STORAGE_COST_INTERVALS[selectedCostInterval]
      ).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (size === "cold_one_year") {
      return getCost(
        unusedOneYearSize,
        latestVolumeSettings.storagePrice.price,
        STORAGE_COST_INTERVALS[selectedCostInterval]
      ).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  };

  const getSizeForStorage = (size) => {
    if (size === "duplicate") {
      return Math.round((duplicateSize / latestDetailedVolume.used) * 100);
    } else if (size === "cold_three_months") {
      return Math.round((unusedThreeMonthsSize / latestDetailedVolume.used) * 100);
    } else if (size === "cold_six_months") {
      return Math.round((unusedSixMonthsSize / latestDetailedVolume.used) * 100);
    } else if (size === "cold_one_year") {
      return Math.round((unusedOneYearSize / latestDetailedVolume.used) * 100);
    }
  };

  return (
    <>
      {showLoading || !latestVolumeSettings ? (
        <Loading />
      ) : (
        <StorageCostCard
          selectedCostInterval={selectedCostInterval}
          setSelectedCostInterval={(costInterval) => {
            window.localStorage.setItem("storageCostIntervalVolume", costInterval);
            setSelectedCostInterval(costInterval);
          }}
          baseURL={baseURL}
          totalCost={getCostForStorage("cost")}
          currencySymbol={latestVolumeSettings.storagePrice.currencySymbol}
          duplicateCost={getCostForStorage("duplicate")}
          duplicatePercentage={getSizeForStorage("duplicate")}
          coldData3MonthsCost={getCostForStorage("cold_three_months")}
          coldData3MonthsPercentage={getSizeForStorage("cold_three_months")}
          coldData6MonthsCost={getCostForStorage("cold_six_months")}
          coldData6MonthsPercentage={getSizeForStorage("cold_six_months")}
          coldData1YearCost={getCostForStorage("cold_one_year")}
          coldData1YearPercentage={getSizeForStorage("cold_one_year")}
          margin={margin}
        />
      )}
    </>
  );
};

export default VolumePricingCard;
