import {
  REQUEST_BIGGEST_VOLUME_VARIATIONS,
  RECEIVE_BIGGEST_VOLUME_VARIATIONS,
  SET_VOLUME_VARIATION_DURATION,
} from "../actions";

const initialState = {
  variations: [],
  isFetching: false,
  duration: 30,
};

function volumeVariations(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BIGGEST_VOLUME_VARIATIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_BIGGEST_VOLUME_VARIATIONS:
      return Object.assign({}, state, {
        variations: action.variations.map((variation) => ({
          ...variation,
          object1: {
            ...variation.object1,
            name:
              variation.object1.type === "AWS-S3"
                ? variation.object1.name.replace("S3-BUCKET-", "")
                : variation.object1.name,
          },
          object2: {
            ...variation.object2,
            name:
              variation.object2.type === "AWS-S3"
                ? variation.object2.name.replace("S3-BUCKET-", "")
                : variation.object2.name,
          },
        })),
        isFetching: false,
      });
    case SET_VOLUME_VARIATION_DURATION:
      return Object.assign({}, state, {
        duration: action.duration,
      });
    default:
      return state;
  }
}

export default volumeVariations;
