import { useState, useEffect } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";

export default function UpdateSodaConfigurationModal(props) {
  const {
    url,
    deploymentId,
    clientId,
    numberOfThreads,
    isOpen,
    setIsOpen,
    updateConfigurationsFunc,
  } = props;

  const [updatedURL, setUpdatedURL] = useState(url.value);
  const [updatedDeploymentId, setUpdatedDeploymentId] = useState(
    deploymentId.value
  );
  const [updatedClientId, setUpdatedClientId] = useState(clientId.value);
  const [updatedNumberOfThreads, setUpdatedNumberOfThreads] = useState(
    numberOfThreads.value
  );

  useEffect(() => {
    if (isOpen) {
      setUpdatedURL(url.value);
      setUpdatedDeploymentId(deploymentId.value);
      setUpdatedClientId(clientId.value);
      setUpdatedNumberOfThreads(numberOfThreads.value);
    }
  }, [isOpen]);

  return (
    <ConfirmModal
      confirmAction={() => {
        updateConfigurationsFunc([
          { ...url, value: updatedURL },
          { ...deploymentId, value: updatedDeploymentId },
          { ...clientId, value: updatedClientId },
          { ...numberOfThreads, value: updatedNumberOfThreads },
        ]);
        setIsOpen(false);
      }}
      showConfirmation={isOpen}
      title="Update Configurations"
      closeConfirmation={() => setIsOpen(false)}
      buttonColor="primary"
      buttonText="Save"
      disableRightButton={updatedNumberOfThreads < 1}
    >
      <Form>
        <FormGroup row>
          <Label sm={4}>URL</Label>
          <Col sm={8}>
            <Input
              value={updatedURL}
              onChange={(e) => setUpdatedURL(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Deployment Id</Label>
          <Col sm={8}>
            <Input
              value={updatedDeploymentId}
              onChange={(e) => setUpdatedDeploymentId(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Client Id</Label>
          <Col sm={8}>
            <Input
              value={updatedClientId}
              onChange={(e) => setUpdatedClientId(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Number of Threads</Label>
          <Col sm={8}>
            <Input
              value={updatedNumberOfThreads}
              onChange={(e) => setUpdatedNumberOfThreads(e.target.value)}
              type="number"
              min={1}
              invalid={updatedNumberOfThreads < 1}
            />
            <FormFeedback>
              Number of threads should not be lower than 1
            </FormFeedback>
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}
