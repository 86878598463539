import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

export default function OnPremisesCredentialForm(props) {
  const {
    name,
    setName,
    username,
    setUsername,
    password,
    setPassword,
    domain,
    setDomain,
    setDisableCreateButton,
  } = props;

  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isDomainInvalid, setIsDomainInvalid] = useState(false);

  useEffect(() => {
    if (name.length === 0 || username.length === 0 || password.length === 0) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [name, username, password]);

  return (
    <Form>
      <FormGroup row>
        <Label for="name" sm={4}>
          Name
          <FontAwesomeIcon
            id="nameLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <UncontrolledTooltip placement="auto" target="nameLabel">
            User readable name for the On-Premises credentials
          </UncontrolledTooltip>
        </Label>
        <Col sm={8}>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Ex: name"
            value={name}
            onChange={(e) => {
              const value = e.target.value;
              setName(value);
              setIsNameInvalid(value.length === 0);
            }}
            invalid={isNameInvalid}
          />
          <FormFeedback>Name must be between 1 and 255 characters</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="username" sm={4}>
          Username
        </Label>
        <Col sm={8}>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="Ex: username"
            value={username}
            onChange={(e) => {
              const value = e.target.value;
              setUsername(value);
              setIsUsernameInvalid(value.length === 0);
            }}
            invalid={isUsernameInvalid}
          />
          <FormFeedback>
            Username must be between 1 and 255 characters
          </FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="password" sm={4}>
          Password
        </Label>
        <Col sm={8}>
          <Input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => {
              const value = e.target.value;
              setPassword(value);
              setIsPasswordInvalid(value.length === 0);
            }}
            invalid={isPasswordInvalid}
          />
          <FormFeedback>
            Password must be between 1 and 255 characters
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="domain" sm={4}>
          Domain (optional)
        </Label>
        <Col sm={8}>
          <Input
            id="domain"
            name="domain"
            type="text"
            placeholder="Ex: cloudsoda"
            value={domain}
            onChange={(e) => {
              const value = e.target.value;
              setDomain(value);
              setIsDomainInvalid(value.length > 255);
            }}
            invalid={isDomainInvalid}
          />
          <FormFeedback>
            Domain must be between 0 and 255 characters
          </FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
}
