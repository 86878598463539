import React, { useState } from "react";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function UserWizardReviewPage({ userFormObj, groups }) {
    const SYSTEM = "SYSTEM";
    const GROUP = "GROUP";
    const [showReviewPassword, setShowReviewPassword] = useState(false);

    return (
        <div className="d-flex flex-column">
            <div className="mb-2">
                <span>Name:</span> <span className="ms-2"><strong>{userFormObj.name}</strong></span>
            </div>
            <div className="mb-2">
                <span>Username:</span> <span className="ms-2"><strong>{userFormObj.username}</strong></span>
            </div>
            <div className="mb-2">
                <span>Email:</span> <span className="ms-2"><strong>{userFormObj.email}</strong></span>
            </div>
            <div className="d-flex mb-2">
                <span className="me-2">Roles:</span>
                <div>
                    {
                        groups.filter(gr => gr.type === SYSTEM).length ?
                            groups.filter(gr => gr.type === SYSTEM).map((gr, i) => {
                                return (
                                    <Badge key={`${gr.name}-create-user-review-role-${i}`} style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }} color="primary">
                                        {gr.name}
                                    </Badge>
                                )
                            }) :
                            <span><strong>No role</strong></span>
                    }
                </div>
            </div>
            <div className="flex mb-2">
                Password: <input style={{ fontWeight: "bold" }} className="ms-2 border-0" type={showReviewPassword ? "text" : "password"} readOnly={true} value={userFormObj.password} />
                <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    className="ms-3"
                    icon={showReviewPassword ? faEye : faEyeSlash}
                    onClick={() => setShowReviewPassword(!showReviewPassword)}
                />
            </div>
            <div className="d-flex">
                <span className="me-2">Groups:</span>
                <div>
                    {
                        groups.filter(gr => gr.type === GROUP).length ?
                            groups.filter(gr => gr.type === GROUP).map((gr, i) => {
                                return (
                                    <Badge key={`${gr.name}-create-user-review-group-${i}`} style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }} color="primary">
                                        {gr.name}
                                    </Badge>
                                )
                            }) :
                            <span><strong>No group</strong></span>
                    }
                </div>
            </div>
        </div>
    );
}

export default UserWizardReviewPage;