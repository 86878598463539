import { faFileExcel, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { getReportForProject } from "../../api/project";
import { setAlert } from "../../actions/alert";

const REPORT_TYPE = {
  OVERVIEW: "OVERVIEW",
  FOLDERLIST: "FOLDERLIST",
};

export default function ReportModal(props) {
  const { id, projectName, isOpen, onClose } = props;

  const [isLoading, setIsLoading] = useState({
    OVERVIEW: false,
    FOLDERLIST: false,
  });

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  const getReportForProjectIdAndType = (id, type) => {
    setIsLoading((prev) => ({ ...prev, [type]: true }));
    getReportForProject(dispatch, auth, id, projectName, indexingDate, type)
      .then((res) => {
        setIsLoading((prev) => ({ ...prev, [type]: false }));
      })
      .catch((err) => {
        dispatch(setAlert(err.message, "danger"));
        setIsLoading((prev) => ({ ...prev, [type]: false }));
      });
  };

  return (
    <Modal toggle={onClose} isOpen={isOpen}>
      <ModalHeader toggle={onClose}>Download Excel Report</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <span className="pe-3">
            <h6>Project overview report</h6>
            Excel report with monthly cost and storage usage grouped by storage.
          </span>
          <Button
            className="ms-auto"
            onClick={() =>
              getReportForProjectIdAndType(id, REPORT_TYPE.OVERVIEW)
            }
            isDisabled={isLoading.OVERVIEW}
            color="success"
          >
            <FontAwesomeIcon
              icon={isLoading.OVERVIEW ? faSpinner : faFileExcel}
              className={`${isLoading.OVERVIEW ? "fa-spin" : ""}`}
            />
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-1">
          <span className="pe-3">
            <h6>Project folder list report</h6>
            Excel report with a list of all configured folders for the project.
            Generating it might take some time.
          </span>
          <Button
            className="ms-auto"
            onClick={() =>
              getReportForProjectIdAndType(id, REPORT_TYPE.FOLDERLIST)
            }
            isDisabled={isLoading.FOLDERLIST}
            color="success"
          >
            <FontAwesomeIcon
              icon={isLoading.FOLDERLIST ? faSpinner : faFileExcel}
              className={`${isLoading.FOLDERLIST ? "fa-spin" : ""}`}
            />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
