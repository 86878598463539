import { store } from "../configureStore";
import OverlayBundle from "./OverlayBundle";

/**
 * Function that returns true if the required bundle is in the bundles list of the user. Returns false otherwise.
 *
 * @param {String} requiredBundle The bundle that is required for the content.
 */
export function hasBundle(requiredBundle) {
  const bundles = store.getState().auth.bundles;
  return bundles ? bundles.includes(requiredBundle) : false;
}

/**
 * Component that allows the children to be shown depending on if the user has the required bundle or not.
 *
 * @param props requiredBundles is the required bundle that allows the user to access the children.
 * @param hasOverlay If hasOverlay is true and user does not have the required bundle, add overlay with the corresponding message on children.
 * If hasOverlay is false, nothing is shown.
 */
export default function HasBundle(props) {
  const { requiredBundle, children, message = "", hasOverlay } = props;
  return (
    <>
      {hasBundle(requiredBundle) ? (
        children
      ) : hasOverlay ? (
        <OverlayBundle message={message}>{children}</OverlayBundle>
      ) : null}
    </>
  );
}
