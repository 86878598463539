import React from "react";
import moment from "moment";

import { Card, CardTitle, CardText, CardBody } from "reactstrap";
import PropTypes from "prop-types";

DateCard.propTypes = {
  dateType: PropTypes.string,
  numberOfDifferentDates: PropTypes.number,
  oldestDate: PropTypes.string
};

export function DateCard(props) {
  const {
    dateTitle,
    dateText,
    numberOfDifferentDates,
    oldestDate
  } = props;

  return (
    <>
      {oldestDate &&
        <Card>
          <CardBody>
            <CardTitle>{dateTitle}</CardTitle>
            <CardText>
              There is{" "}
              <strong>{numberOfDifferentDates}</strong>{" "}
              differents {dateText} for this folder.
            </CardText>
            <CardText>
              The oldest date is{" "}
              <strong>{moment(oldestDate).format("lll")}</strong>
            </CardText>
          </CardBody>
        </Card>
      }
    </>
  );
}

export default DateCard;
