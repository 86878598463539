import React from "react";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import EditFileTags from "./EditFileTags";

export default function TagMultipleFilesPopover(props) {
  const {
    target,
    isTagMultipleFilesOpen,
    setIsTagMultipleFilesOpen,
    selectedFiles,
    setSelectedFiles = () => {},
    updateFile,
    updateTags,
    useSearchResult,
    search,
    isDirectory,
    deleteTag = true
  } = props;

  return (
    <Popover
      target={target}
      placement="right"
      trigger="legacy"
      isOpen={isTagMultipleFilesOpen}
      toggle={() => setIsTagMultipleFilesOpen(!isTagMultipleFilesOpen)}
    >
      <PopoverHeader>{deleteTag ? "Remove" : "Add"} tags</PopoverHeader>
      <PopoverBody>
        <EditFileTags
          updateFile={tags => {
            const selectedFilesCopy = [ ...selectedFiles ];

            selectedFiles.forEach((file, index) => {
              const tagsToAdd = file.customTag ? [...file.customTag, ...tags.filter(tag => !file.customTag.includes(tag))] : tags;
              const tagsToRemove = file.customTag ? file.customTag.filter(fileTag => !tags.includes(fileTag)) : [];

              const updatedFile = { ...file, customTag: (deleteTag ? tagsToRemove : tagsToAdd) };
              updateFile(file.fileId, updatedFile);

              selectedFilesCopy[index] = updatedFile;
            });
            
            setSelectedFiles(selectedFilesCopy);
          }}
          updateTags={tags => updateTags(tags)}
          closeFunc={() => setIsTagMultipleFilesOpen(false)}
          useSearchResult={useSearchResult}
          search={search}
          isDirectory={isDirectory}
          deleteTag={deleteTag}
        />
      </PopoverBody>
    </Popover>
  );
}