import { useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "./Tabs";
import RelatedFilesList from "./RelatedFilesList";

export default function RelatedFilesForArchive(props) {
  const { file } = props;

  const [activeTab, setActiveTab] = useState("0");

  const volumes = useSelector(({ volumes }) => volumes.items);

  const onPremVolumeIds = volumes
    .filter(
      (volume) =>
        "crawl.files" === volume.type ||
        "NetworkVolume" === volume.type ||
        "DellPowerScale" === volume.type
    )
    .map((v) => v.id);
  const onPremDuplicateBody = JSON.stringify([
    {
      volumeId: { values: onPremVolumeIds },
      name: { value: file.name },
      lastModifiedDate: { after: file.lastModified },
      size: { min: file.size - 1024, max: file.size + 1024 },
    },
  ]);

  const cloudVolumeIds = volumes
    .filter((volume) => "AWS-S3" === volume.type)
    .map((v) => v.id);
  const cloudDuplicateBody = JSON.stringify([
    {
      volumeId: { values: cloudVolumeIds },
      name: { value: file.name },
      size: { min: file.size - 1024, max: file.size + 1024 },
    },
  ]);

  const archiveVolumeIds = volumes
    .filter(
      (volume) => "Archives" === volume.type || "Archiware" === volume.type
    )
    .map((v) => v.id);
  const archiveDuplicateBody = JSON.stringify([
    {
      volumeId: { values: archiveVolumeIds },
      identificationHash: { value: file.identificationHash },
      isDeleted: false,
    },
    {
      fileId: { value: file.fileId },
      excludeFilter: true,
    },
  ]);

  const archiveDeletedBody = JSON.stringify([
    {
      volumeId: { values: archiveVolumeIds },
      identificationHash: { value: file.identificationHash },
      isDeleted: true,
    },
  ]);

  return (
    <Tabs
      toggleTab={(tab) => setActiveTab(tab)}
      activeTab={activeTab}
      titles={["Archive", "Cloud", "On-Premises", "Removed"]}
    >
      <RelatedFilesList
        searchBody={archiveDuplicateBody}
        description="Archive files are files with the same name, same last modified date and with a similar size."
        boldElements={{ name: true, size: true, lastModified: true }}
        emptyListString="No archive files found."
        tabPosition="0"
        currentActiveTab={activeTab}
      />

      <RelatedFilesList
        searchBody={cloudDuplicateBody}
        description={
          <>
            Related Cloud files are files with the same name with a similar
            size. Files shown in red are no longer in their original location.
          </>
        }
        boldElements={{ name: true, size: true }}
        emptyListString="No cloud files found."
        tabPosition="1"
        currentActiveTab={activeTab}
        isRedBackground
      />
      <RelatedFilesList
        searchBody={onPremDuplicateBody}
        description={
          <>
            Related On-Premises files are with the same name, same last modified
            date and with a similar size. Files shown in red are no longer in
            their original location.
          </>
        }
        boldElements={{ name: true, size: true, lastModified: true }}
        emptyListString="No On-Premises files found."
        tabPosition="2"
        currentActiveTab={activeTab}
        isRedBackground
      />

      <RelatedFilesList
        searchBody={archiveDeletedBody}
        description="Archive files with the same name, same size, same last modified date and that is no longer in its original location."
        boldElements={{
          name: true,
          size: true,
          lastModified: true,
          indexingDate: true,
        }}
        emptyListString="No deleted files found."
        tabPosition="3"
        currentActiveTab={activeTab}
      />
    </Tabs>
  );
}
