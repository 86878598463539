import { useState } from "react";
import StorageManagerCredentialsList from "./StorageManagerCredentialsList";
import StorageManagerMappingList from "./StorageManagerMappingList";

export default function StorageManagerIntegration() {
  const [credentials, setCredentials] = useState([]);

  return (
    <>
      <StorageManagerCredentialsList
        credentials={credentials}
        setCredentials={setCredentials}
      />
      <StorageManagerMappingList credentials={credentials} />
    </>
  );
}