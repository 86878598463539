import { useEffect, useState } from "react";
import xbytes from "xbytes";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
} from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validatePositiveStringInteger, validateTruePositiveStringInteger } from "../../utils/validateNumber";
import URLInput from "./URLInput";
import {
  convertSymbolToExponent,
  formatSize,
  getSymbolFromBase,
} from "../../components/FormatSize";
import { useDispatch, useSelector } from "react-redux";
import { deleteIndex, getAllCrawlDates } from "../../api/administration";
import { setAlert } from "../../actions/alert";
import { fetchDates } from "../../actions/index";
import ConfirmModal from "../../components/ConfirmModal";

function GeneralConfiguration(props) {
  const {
    minimumSize,
    retentionSnapshot,
    retentionDeletedFiles,
    retentionJobs,
    queuedLengthJobs,
    retentionFirstDay,
    urlConfiguration,
    updateConfigurations,
    isHealthCheckActive,
    isPerformanceModeActive,
    isAlwaysUpdateActive,
    isAdvancedScanActive,
    folderDepth,
    scanDellPowerscaleNumberOfThreads,
    numberOfFiles,
    isBetaActive
  } = props;

  const [url, setUrl] = useState(urlConfiguration.value);
  const [unit, setUnit] = useState();
  const [retentionSnapshotObj, setRetentionSnapshotObj] =
    useState(retentionSnapshot);
  const [retentionDeletedFilesObj, setRetentionDeletedFilesObj] = useState(
    retentionDeletedFiles
  );
  const [retentionJobsObj, setRetentionJobsObj] = useState(retentionJobs);
  const [queuedLengthJobsObj, setQueuedLengthJobsObj] =
    useState(queuedLengthJobs);
  const [minimumSizeObj, setMinimumSizeObj] = useState(minimumSize);
  const [retentionFirstDayObj, setRetentionFirstDayObj] =
    useState(retentionFirstDay);
  const [isHealthCheckActiveObj, setIsHealthCheckActiveObj] =
    useState(isHealthCheckActive);
  const [isPerformanceModeActiveObj, setIsPerformanceModeActiveObj] = useState(
    isPerformanceModeActive
  );
  const [isAlwaysUpdateActiveObj, setIsAlwaysUpdateActiveObj] =
    useState(isAlwaysUpdateActive);
  const [isAdvancedScanActiveObj, setIsAdvancedScanActiveObj] =
    useState(isAdvancedScanActive);
  const [folderDepthObj, setFolderDepthObj] = useState(folderDepth);
  const [scanDellPowerscaleNumberOfThreadsObj, setScanDellPowerscaleNumberOfThreadsObj] =
    useState(scanDellPowerscaleNumberOfThreads);
  const [numberOfFilesObj, setNumberOfFilesObj] = useState(numberOfFiles);
  const [urlConfigurationObj, setUrlConfigurationObj] =
    useState(urlConfiguration);
  const [isBetaActiveObj, setIsBetaActiveObj] = useState(isBetaActive);

  const [invalidRetentionSnapshot, setInvalidRetentionSnapshot] =
    useState(false);
  const [invalidRetentionDeletedFiles, setInvalidRetentionDeletedFiles] =
    useState(false);
  const [invalidNumberOfFiles, setInvalidNumberOfFiles] = useState(false);
  const [invalidFolderDepth, setInvalidFolderDepth] = useState(false);
  const [invalidScanDellPowerscaleNumberOfThreads, setInvalidScanDellPowerscaleNumberOfThreads] =
    useState(false);
  const [invalidRetentionJobs, setInvalidRetentionJobs] = useState(false);
  const [invalidQueuedLengthJobs, setInvalidQueuedLengthJobs] = useState(false);
  const [invalidMinimumSize, setInvalidMinimumSize] = useState(false);
  const [invalidUrlConfiguration, setInvalidUrlConfiguration] = useState(false);
  const [indexingDates, setIndexingDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [isDeleteIndexModalOpen, setIsDeleteIndexModalOpen] = useState(false);

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const dates = useSelector(({ dates }) => dates.items);

  useEffect(() => {
    const convertedSize = formatSize(
      parseInt(minimumSize.value) ? parseInt(minimumSize.value) : 0,
      baseSize,
      null,
      "object"
    );

    setMinimumSizeObj({ ...minimumSize, value: convertedSize.value });
    setUnit(convertedSize.unit);
  }, [minimumSize.value]);

  useEffect(() => {
    getAllCrawlDates(dispatch, auth)
      .then((res) => {
        setIndexingDates(res);
        setSelectedDate(res[res.length - 1]);
      })
      .catch((err) =>
        dispatch(setAlert("Unable to get the list of snapshots", "danger"))
      );
  }, [dates]);

  useEffect(() => {
    retentionSnapshot.value === ""
      ? setInvalidRetentionSnapshot(true)
      : setInvalidRetentionSnapshot(false);
    retentionDeletedFiles.value === ""
      ? setInvalidRetentionDeletedFiles(true)
      : setInvalidRetentionDeletedFiles(false);
    retentionJobs.value === ""
      ? setInvalidRetentionJobs(true)
      : setInvalidRetentionJobs(false);
    queuedLengthJobs.value === ""
      ? setInvalidQueuedLengthJobs(true)
      : setInvalidQueuedLengthJobs(false);
    numberOfFiles.value === ""
      ? setInvalidNumberOfFiles(true)
      : setInvalidNumberOfFiles(false);
    folderDepth.value === ""
      ? setInvalidFolderDepth(true)
      : setInvalidFolderDepth(false);
    scanDellPowerscaleNumberOfThreads.value === ""
      ? setInvalidScanDellPowerscaleNumberOfThreads(true)
      : setInvalidScanDellPowerscaleNumberOfThreads(false);
    url === "" || url === "http://" || url === "https://"
      ? setInvalidUrlConfiguration(true)
      : setInvalidUrlConfiguration(false);

    setRetentionSnapshotObj(retentionSnapshot);
    setRetentionDeletedFilesObj(retentionDeletedFiles);
    setRetentionJobsObj(retentionJobs);
    setQueuedLengthJobsObj(queuedLengthJobs);
    setUrlConfigurationObj({ ...urlConfiguration, value: url });
    setRetentionFirstDayObj({
      ...retentionFirstDay,
      value: retentionFirstDay.value === "true" ? "true" : "false",
    });
    setIsHealthCheckActiveObj({
      ...isHealthCheckActive,
      value: isHealthCheckActive.value === "true" ? "true" : "false",
    });
    setIsPerformanceModeActiveObj({
      ...isPerformanceModeActive,
      value: isPerformanceModeActive.value === "true" ? "true" : "false",
    });

    setIsAlwaysUpdateActiveObj({
      ...isAlwaysUpdateActive,
      value: isAlwaysUpdateActive.value === "true" ? "true" : "false",
    });

    setIsAdvancedScanActiveObj({
      ...isAdvancedScanActive,
      value: isAdvancedScanActive.value === "true" ? "true" : "false",
    });
    setFolderDepthObj(folderDepth);
    setScanDellPowerscaleNumberOfThreadsObj(scanDellPowerscaleNumberOfThreads);
    setNumberOfFilesObj(numberOfFiles);
    setIsBetaActiveObj({
      ...isBetaActive,
      value: isBetaActive.value === "true" ? "true" : "false",
    });
  }, [
    retentionSnapshot,
    retentionDeletedFiles,
    retentionJobs,
    queuedLengthJobs,
    retentionFirstDay,
    isHealthCheckActive,
    isPerformanceModeActive,
    isAlwaysUpdateActive,
    isAdvancedScanActive,
    folderDepth,
    scanDellPowerscaleNumberOfThreads,
    numberOfFiles,
    urlConfiguration,
    url,
    isBetaActive
  ]);

  const input = () => {
    return (
      <>
        <FormGroup row>
          <Label for="URLinput" sm={2}>
            URL of the DataIntell Application
          </Label>
          <Col sm={6}>
            <URLInput
              urlConfiguration={urlConfiguration.value}
              updateURL={setUrl}
              checkMark
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="minimumSize" sm={2}>
            Minimum Size of Files That Will Be Tagged as Duplicate
          </Label>

          <Col sm={4}>
            <Input
              id="minimumSize"
              name="minimumSize"
              type="number"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setMinimumSizeObj({
                  ...minimumSizeObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidMinimumSize(!isValueValid);
              }}
              value={minimumSizeObj.value}
              invalid={invalidMinimumSize}
              valid={!invalidMinimumSize}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
          <Col sm={2}>
            <Input
              type="select"
              name="unit"
              id="unit"
              value={unit === "kB" ? "KB" : unit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            >
              <option value="B">Bytes</option>
              <option>{getSymbolFromBase("KB", baseSize)}</option>
              <option>{getSymbolFromBase("MB", baseSize)}</option>
              <option>{getSymbolFromBase("GB", baseSize)}</option>
              <option>{getSymbolFromBase("TB", baseSize)}</option>
              <option>{getSymbolFromBase("PB", baseSize)}</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="queuedLengthJobs" sm={2}>
            Maximum Number of Jobs of a Type That Can Be Set as Waiting
          </Label>

          <Col sm={6}>
            <Input
              id="queuedLengthJobs"
              name="queuedLengthJobs"
              type="number"
              min="0"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setQueuedLengthJobsObj({
                  ...queuedLengthJobsObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidQueuedLengthJobs(!isValueValid);
              }}
              value={queuedLengthJobsObj.value}
              invalid={invalidQueuedLengthJobs}
              valid={!invalidQueuedLengthJobs}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="retentionJobs" sm={2}>
            Number of Days to Keep Jobs
          </Label>

          <Col sm={6}>
            <Input
              id="retentionJobs"
              name="retentionJobs"
              type="number"
              min="1"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setRetentionJobsObj({
                  ...retentionJobsObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidRetentionJobs(!isValueValid);
              }}
              value={retentionJobsObj.value}
              invalid={invalidRetentionJobs}
              valid={!invalidRetentionJobs}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="retentionSnapshot" sm={2}>
            Number of Days to Keep Snapshot
          </Label>

          <Col sm={6}>
            <Input
              id="retentionSnapshot"
              name="retentionSnapshot"
              type="number"
              min="1"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setRetentionSnapshotObj({
                  ...retentionSnapshotObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidRetentionSnapshot(!isValueValid);
              }}
              value={retentionSnapshotObj.value}
              invalid={invalidRetentionSnapshot}
              valid={!invalidRetentionSnapshot}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="retentionDeletedFiles" sm={2}>
            Number of Days to Keep Deleted Files
          </Label>

          <Col sm={6}>
            <Input
              id="retentionDeletedFiles"
              name="retentionDeletedFiles"
              type="number"
              min="1"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setRetentionDeletedFilesObj({
                  ...retentionDeletedFilesObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidRetentionDeletedFiles(!isValueValid);
              }}
              value={retentionDeletedFilesObj.value}
              invalid={invalidRetentionDeletedFiles}
              valid={!invalidRetentionDeletedFiles}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="numberOfFiles" sm={2}>
            Number of Files for Advanced Scan
          </Label>

          <Col sm={6}>
            <Input
              id="numberOfFiles"
              name="numberOfFiles"
              type="number"
              min="0"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setNumberOfFilesObj({
                  ...numberOfFilesObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidNumberOfFiles(!isValueValid);
              }}
              value={numberOfFilesObj.value}
              invalid={invalidNumberOfFiles}
              valid={!invalidNumberOfFiles}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="folderDepth" sm={2}>
            Folder Depth for Advanced Scan
          </Label>

          <Col sm={6}>
            <Input
              id="folderDepth"
              name="folderDepth"
              type="number"
              min="0"
              onChange={(e) => {
                const isValueValid = validatePositiveStringInteger(
                  e.target.value
                );
                setFolderDepthObj({
                  ...folderDepthObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidFolderDepth(!isValueValid);
              }}
              value={folderDepthObj.value}
              invalid={invalidFolderDepth}
              valid={!invalidFolderDepth}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="scanDellPowerscaleNumberOfThreads" sm={2}>
            Number of Threads for Dell Powerscale Scan
          </Label>

          <Col sm={6}>
            <Input
              id="scanDellPowerscaleNumberOfThreads"
              name="scanDellPowerscaleNumberOfThreads"
              type="number"
              min="1"
              onChange={(e) => {
                const isValueValid = validateTruePositiveStringInteger(
                  e.target.value
                );
                setScanDellPowerscaleNumberOfThreadsObj({
                  ...scanDellPowerscaleNumberOfThreadsObj,
                  value: isValueValid ? e.target.value : "",
                });
                setInvalidScanDellPowerscaleNumberOfThreads(!isValueValid);
              }}
              value={scanDellPowerscaleNumberOfThreadsObj.value}
              invalid={invalidScanDellPowerscaleNumberOfThreads}
              valid={!invalidScanDellPowerscaleNumberOfThreads}
            />
            <FormFeedback style={{ position: "relative" }}>
              <span>Value must be a positive number</span>
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="deleteIndex" sm={2}>
            Delete a Snapshot
          </Label>
          <Col sm={2}>
            <Input
              onChange={(e) => setSelectedDate(e.target.value)}
              type="select"
              value={selectedDate}
            >
              {indexingDates.reverse().map((indexingDate, index) => {
                return (
                  <option key={index + "-" + indexingDate} value={indexingDate}>
                    {indexingDate}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Col sm={2}>
            <Button
              disabled={!selectedDate}
              color="danger"
              outline
              onClick={() => setIsDeleteIndexModalOpen(true)}
            >
              Delete
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="alwaysUpdate" sm={2}>
            Always Update the Files on First Scan of the Day
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="alwaysUpdate"
                name="alwaysUpdate"
                onChange={(e) => {
                  setIsAlwaysUpdateActiveObj({
                    ...isAlwaysUpdateActive,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={isAlwaysUpdateActiveObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="advancedScan" sm={2}>
            Use Advanced Scan
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="advancedScan"
                name="advancedScan"
                onChange={(e) => {
                  setIsAdvancedScanActiveObj({
                    ...isAdvancedScanActive,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={isAdvancedScanActiveObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="retentionFirstDay" sm={2}>
            Keep First Day of Each Month
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="retentionFirstDay"
                name="retentionFirstDay"
                onChange={(e) => {
                  setRetentionFirstDayObj({
                    ...retentionFirstDay,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={retentionFirstDayObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="scannerHealthCheck" sm={2}>
            Activate the Health Check to the Servers
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="scannerHealthCheck"
                name="scannerHealthCheck"
                onChange={(e) => {
                  setIsHealthCheckActiveObj({
                    ...isHealthCheckActive,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={isHealthCheckActiveObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="performanceMode" sm={2}>
            Activate Performance Mode
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="performanceMode"
                name="performanceMode"
                onChange={(e) => {
                  setIsPerformanceModeActiveObj({
                    ...isPerformanceModeActive,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={isPerformanceModeActiveObj.value === "true"}
              />
            </FormGroup>
            {isPerformanceModeActiveObj.value == "true" && (
              <div>
                <FontAwesomeIcon
                  className="me-1"
                  color={"#eda003"}
                  icon={faExclamationTriangle}
                />
                The performance mode stop indexation on Elasticsearch. This is
                for debugging purposes only.
              </div>
            )}
          </Col>
        </FormGroup>

        <FormGroup row style={{ display: "flex", alignItems: "center" }}>
          <Label for="isBetaActive" sm={2}>
            Turn On Beta Features
          </Label>
          <Col sm={6}>
            <FormGroup switch>
              <Input
                type="switch"
                id="isBetaActive"
                name="isBetaActive"
                onChange={(e) => {
                  setIsBetaActiveObj({
                    ...isBetaActive,
                    value: e.target.checked ? "true" : "false",
                  });
                }}
                checked={isBetaActiveObj.value === "true"}
              />
            </FormGroup>
          </Col>
        </FormGroup>
      </>
    );
  };

  return (
    <>
      <Form>
        {input()}
        <FormGroup>
          <Button
            disabled={
              invalidMinimumSize ||
              invalidRetentionSnapshot ||
              invalidRetentionDeletedFiles ||
              invalidRetentionJobs ||
              invalidQueuedLengthJobs ||
              invalidNumberOfFiles ||
              invalidFolderDepth ||
              invalidUrlConfiguration
            }
            onClick={() => {
              updateConfigurations([
                {
                  id: minimumSizeObj.id,
                  name: minimumSizeObj.name,
                  description: minimumSizeObj.description,
                  value: parseInt(
                    unit === "B"
                      ? minimumSizeObj.value
                      : xbytes.parseSize(`${minimumSizeObj.value} ${unit}`)
                  ),
                },
                numberOfFilesObj,
                folderDepthObj,
                scanDellPowerscaleNumberOfThreadsObj,
                retentionSnapshotObj,
                retentionDeletedFilesObj,
                retentionJobsObj,
                queuedLengthJobsObj,
                retentionFirstDayObj,
                urlConfigurationObj,
                isHealthCheckActiveObj,
                isPerformanceModeActiveObj,
                isAlwaysUpdateActiveObj,
                isAdvancedScanActiveObj,
                isBetaActiveObj,
              ]);
            }}
            outline
            color="primary"
          >
            Update configurations
          </Button>
        </FormGroup>
      </Form>
      <ConfirmModal
        showConfirmation={isDeleteIndexModalOpen}
        title="Delete Snapshot"
        buttonColor="danger"
        buttonText="Delete"
        closeConfirmation={() => setIsDeleteIndexModalOpen(false)}
        confirmAction={() => {
          deleteIndex(dispatch, auth, selectedDate)
            .then(() => {
              dispatch(fetchDates());
              setIsDeleteIndexModalOpen(false);
              dispatch(
                setAlert(
                  "Snapshot deleted successfully, you will need to refresh the page to see the changes",
                  "success"
                )
              );
            })
            .catch((err) => {
              setIsDeleteIndexModalOpen(false);
              dispatch(
                setAlert(
                  `Error while trying to delete the snapshot for the date [${selectedDate}]`,
                  "danger"
                )
              );
            });
        }}
      >
        Are you sure you want to delete the snapshot for {selectedDate}? This
        will remove the files and folders for this snapshot.
      </ConfirmModal>
    </>
  );
}

export default GeneralConfiguration;
