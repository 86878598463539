import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import CircleChart from "../../components/CircleChart";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { formatSize } from "../../components/FormatSize";

export default function VolumeSize(props) {
  const { isFetching, volumes, handlePathChange } = props;

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const volumeSizes = () => {
    return volumes.map((volume) => {
      const filesizeObject = formatSize(volume.total, baseSize, null, "object");
      const usedSpace = Math.round((volume.used / volume.total) * 100);
      const title = `${volume.name} (${usedSpace} %)`;
      const data = [
        formatSize(volume.used, baseSize, filesizeObject.exponent, "object")
          .value,
        formatSize(volume.free, baseSize, filesizeObject.exponent, "object")
          .value,
      ];
      const labels = [
        `Used space (${filesizeObject.unit})`,
        `Available space (${filesizeObject.unit})`,
      ];
      let colors = ["#5F9EA0", "rgba(1,1,1,0.2)"];

      if (usedSpace >= 90) {
        colors = ["#C91D1E", "rgba(0,0,0,0.2)"];
      } else if (usedSpace >= 80) {
        colors = ["#FAE556", "rgba(0,0,0,0.2)"];
      }
      return (
        <div
          key={volume.id}
          style={{ height: 200, marginBottom: "3.5rem", cursor: "pointer" }}
          onClick={() =>
            handlePathChange("/filemanagement?path=/&volumeId=" + volume.id)
          }
        >
          <h6 style={{ marginBottom: 0 }} className="text-center">
            <strong>{title}</strong>
          </h6>
          <CircleChart
            data={data}
            labels={labels}
            title={title}
            color={colors}
            hoverColor={"rgba(75,192,192,0.7)"}
            maintainAspectRatio={false}
            unit={filesizeObject.unit}
          />
          <hr style={{ marginRight: "10px" }} />
        </div>
      );
    });
  };

  const cardContent = () => {
    if (isFetching === true) {
      return <Loading />;
    } else {
      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>{volumeSizes()}</div>
      );
    }
  };

  return volumes.length > 0 ? (
    <Card
      style={{
        marginBottom: 12,
      }}
    >
      <CardHeader>
        <CardTitle tag="h5">On-Premises Storage Sizes</CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Used space for all On-Premises storage
        </CardSubtitle>
      </CardHeader>

      <CardBody style={{ maxHeight: 520, overflowY: "auto" }}>
        {cardContent()}
      </CardBody>
    </Card>
  ) : null;
}
