import { faArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { ROLE_STORAGE_MANAGER_ARCHIVE, ROLE_STORAGE_MANAGER_RESTORE } from "../../constants/roles";
import Authorization from "../../validation/Authorization";
import SourceSelectionItem from "../SourceSelectionItem";

export default function SelectActionStep(props) {
  const {
    storageManagerActionObj,
    setStorageManagerActionObj,
    setStepIndex,
    setDisableForwardActionButton
  } = props;
  
  useEffect(() => {
    if (!storageManagerActionObj.action) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  }, []);

  return (
    <>
      <Authorization requiredAuthorizations={[ROLE_STORAGE_MANAGER_ARCHIVE]} >
        <SourceSelectionItem
          itemIcon={faArchive}
          iconSize={"3x"}
          onClickFunction={() => {
            setDisableForwardActionButton(false);
            setStepIndex(prev => ++prev);
            setStorageManagerActionObj((prev) => ({ ...prev, action: "archive" }));
          }}
          title={"Archive"}
          description={
            "Archive the selected items"
          }
          marginRight="35px"
        />
      </Authorization>
      <Authorization requiredAuthorizations={[ROLE_STORAGE_MANAGER_RESTORE]} >
        <SourceSelectionItem
          itemIcon={faBoxOpen}
          iconSize={"3x"}
          onClickFunction={() => {
            setDisableForwardActionButton(false);
            setStepIndex(prev => ++prev);
            setStorageManagerActionObj((prev) => ({ ...prev, action: "restore" }));
          }}
          title={"Restore"}
          description={
            "Restore the selected items"
          }
        />
      </Authorization>
    </>
  );
}