import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileIcon from "../components/FileIcon";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import FormatSize from "../components/FormatSize";
import TableResult from "../components/TableResult";

export default function DataTableLite(props) {
  const {
    data,
    isFile
  } = props;

  const formattedData = data.map(element => {
    const lineObject = {
      columnData: [
        <span style={{ whiteSpace: "nowrap" }}>
          {isFile ?
            <FileIcon extension={element.extension} /> :
            <FontAwesomeIcon
              className="me-2"
              color="#007bff"
              icon={faFolder}
            />
          }
          {element.name}
        </span>,
        <span>{element.path}</span>,
        <span>{element.volumeName}</span>,
        <span><FormatSize>{element.size}</FormatSize></span>
      ],
      style: {},
      options: {}
    };

    return lineObject;
  });

  const titles = [
    { name: `${isFile ? "File" : "Folder"} Name` },
    { name: "Path" },
    { name: "Volume Name" },
    { name: "Size" },
  ];

  return (
    <>
      {data.length > 0 ?
        <>
          <TableResult
            data={formattedData}
            titles={titles}
            changeOrder={() => { }}
          />
        </> :
        <div>No {isFile ? "file" : "folder"} found</div>
      }
    </>
  );
}