import { REQUEST_VOLUMES, RECEIVE_VOLUMES } from "../actions";

function volumes(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_VOLUMES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_VOLUMES:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.volumes.elements.map((element) => ({
          ...element,
          name:
            element.type === "AWS-S3"
              ? element.name.replace("S3-BUCKET-", "")
              : element.name,
        })),
        page: action.volumes.page,
        total: action.volumes.total,
        size: action.volumes.size,
        sort: action.volumes.sort,
        direction: action.volumes.direction,
      });
    default:
      return state;
  }
}

export default volumes;
