import React, { useState, useEffect } from "react";
import { Input, Row, Col, FormFeedback } from "reactstrap";

function URLInput(props) {
  const {
    urlConfiguration,
    updateURL,
    checkMark,
    placeholder = "Ex: localhost:9000"
  } = props;
  const [baseUrl, setBaseUrl] = useState("");
  const [protocol, setProtocol] = useState("");
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (urlConfiguration) {
      setBaseUrl(urlConfiguration.replace(/^https?:\/\//, ""));
      setProtocol(
        urlConfiguration.startsWith("https://") ? "https://" : "http://"
      );
    }
  }, [urlConfiguration]);

  useEffect(() => {
    updateURL(protocol + baseUrl);
  }, [baseUrl, protocol]);

  return (
    <Row>
      <Col sm={4}>
        <Input
          type="select"
          name="protocol"
          value={protocol}
          onChange={(e) => setProtocol(e.target.value)}
        >
          <option value="http://">http://</option>
          <option value="https://">https://</option>
        </Input>
      </Col>
      <Col sm={8}>
        <Input
          style={{ marginLeft: "-5px" }}
          id="baseUrl"
          name="baseUrl"
          type="text"
          placeholder={placeholder}
          value={baseUrl}
          onChange={(e) => {
            setBaseUrl(e.target.value);
            setInvalid(e.target.value.length === 0);
          }}
          invalid={invalid}
          valid={!invalid && checkMark}
        />
        <FormFeedback style={{ position: "absolute" }}>
          <span>URL can't be empty</span>
        </FormFeedback>
      </Col>
    </Row>
  );
}

export default URLInput;
