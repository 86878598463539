import React, { useState, useEffect } from "react";
import { Input, Form, FormGroup, Label, Col, FormFeedback, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import URLInput from "./URLInput";

ArchiwareCredentialForm.propTypes = {
    name: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    url: PropTypes.string,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
};



function ArchiwareCredentialForm(props) {
    const {
        name,
        username,
        password,
        url,
        handleChange,
        errors,
        setDisableCreateButton = null
    } = props;
    useEffect(() => {
        if (setDisableCreateButton !== null) {
            if (name.length === 0 ||
                username.length === 0 ||
                password.length === 0 ||
                url === "http://" ||
                url === "https://" ||
                url.length === 0) {
                setDisableCreateButton(true);
            } else {
                setDisableCreateButton(false);
            }
        }
    }, [name, username, url, password, setDisableCreateButton]);

    const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);
    const [localErrors, setLocalErrors] = useState({
        name: "",
        username: "",
        password: "",
    });
    const handleLocalErrors = (name, value) => {
        switch (name) {
            case "name":
                if (value === "" || value.length > 255) {
                    setLocalErrors(prev => ({
                        ...prev,
                        name: "Name must be between 1 and 255 characters"
                    }));
                }
                else {
                    setLocalErrors(prev => ({
                        ...prev,
                        name: ""
                    }));
                }
                break;
            case "username":
                if (value === "" || value.length > 255) {
                    setLocalErrors(prev => ({
                        ...prev,
                        username: "Username must be between 1 and 255 characters"
                    }));
                }
                else {
                    setLocalErrors(prev => ({
                        ...prev,
                        username: ""
                    }));
                }
                break;
            case "password":
                if (value === "" || value.length > 255) {
                    setLocalErrors(prev => ({
                        ...prev,
                        password: "Password must be between 1 and 255 characters"
                    }));
                }
                else {
                    setLocalErrors(prev => ({
                        ...prev,
                        password: ""
                    }));
                }
                break;
            default:
                break;
        }
    }


    const handleInputChange = event => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        handleChange(name, value);
        handleLocalErrors(name, value);
    };

    const updateURL = (url) => {
        handleChange("url", url);
    }
    return (
        <Form>
            <FormGroup row>
                <Label for="name" sm={3}>Name
                    <FontAwesomeIcon
                        id="nameLabel"
                        className="ms-sm-1"
                        icon={faQuestionCircle}
                    />
                    <Tooltip
                        placement="right"
                        isOpen={isNameTooltipOpen}
                        target="nameLabel"
                        toggle={() => setIsNameTooltipOpen(!isNameTooltipOpen)}
                    >
                        User readable name for the Archiware P5
                    </Tooltip>
                </Label>
                <Col sm={9}>
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Ex: name"
                        value={name}
                        onChange={handleInputChange}
                        invalid={(errors["name"] !== undefined || Boolean(localErrors["name"].length))}
                    />
                    <FormFeedback>{errors["name"]} {localErrors["name"]}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="username" sm={3}>Username</Label>
                <Col sm={9}>
                    <Input
                        id="username"
                        name="username"
                        type="text"
                        placeholder="Ex: username"
                        value={username}
                        onChange={handleInputChange}
                        invalid={(errors["username"] !== undefined || Boolean(localErrors["username"].length))}
                    />
                    <FormFeedback>{errors["username"]} {localErrors["username"]}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="password" sm={3}>Password</Label>
                <Col sm={9}>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={handleInputChange}
                        invalid={(errors["password"] !== undefined || Boolean(localErrors["password"].length))}
                    />
                    <FormFeedback>{errors["password"]} {localErrors["password"]}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="url" sm={3}>URL</Label>
                <Col sm={9}>
                    <URLInput urlConfiguration={url} updateURL={updateURL} />
                    <FormFeedback>{errors["password"]} {localErrors["password"]}</FormFeedback>
                </Col>
            </FormGroup>
        </Form>
    );
}

export default ArchiwareCredentialForm;