import DataSource from "./parameters/DataSource";

export default function SelectReferenceDataStep(props) {
  const {
    reportTemplateObj,
    setReportTemplateObj,
    setDisableForwardActionButton
  } = props;

  return (
    <>
      {(reportTemplateObj.type === "DUPLICATED" || reportTemplateObj.type === "UNIQUE") &&
        <DataSource
          isReferences
          reportTemplateObj={reportTemplateObj}
          setReportTemplateObj={setReportTemplateObj}
          setDisableForwardActionButton={setDisableForwardActionButton}
        />
      }
    </>
  );
}