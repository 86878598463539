import React, { useState } from "react";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import { Button, FormGroup, Input, FormFeedback } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

function ServerList(props) {
  const {
    servers,
    errors,
    createServerFunc,
    updateServerFunc,
    deleteServerFunc,
  } = props;
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [newName, setNewName] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");

  const fileTitles = [{ name: "Id" }, { name: "Name" }, { name: "Action" }];

  const changeOrder = (newSort) => {
    if (newSort === sort) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSort(newSort);
    }
  };

  const editLine = (id) => {
    const serverToUpdate = servers.find((server) => server.id === id);
    setEditId(id);
    setEditName(serverToUpdate.name);
  };

  const listFormated = servers.map((server) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    if (editId === server.id) {
      lineObject.columnData = [
        server.id,
        <FormGroup>
          <Input
            type="text"
            value={editName}
            placeholder="Ex: Montreal Datacenter"
            onChange={(e) => setEditName(e.target.value)}
            invalid={editName === ""}
          />
          <FormFeedback>Name must not be blank</FormFeedback>
        </FormGroup>,
        <span>
          <Button
            disabled={editName === "" ? true : false}
            onClick={() => {
              updateServerFunc(editId, editName);
              setEditId(null);
            }}
            color="primary"
          >
            Update
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={() => setEditId(null)}>
            Cancel
          </Button>
        </span>,
      ];
    } else {
      lineObject.columnData = [
        server.id,
        server.name,
        <span>
          <Button onClick={() => editLine(server.id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          {server.id !== 1 &&
            <Button
              onClick={() => {
                setDeleteId(server.id);
                setModalMessage(
                  `Are you sure you want to delete server ${server.name}?`
                );
                setShowConfirmation(true);
              }}
              color="danger"
              style={{ marginLeft: 10 }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          }
        </span>,
      ];
    }

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "Add new server",
      <FormGroup>
        <Input
          type="text"
          value={newName}
          placeholder="Ex: Montreal Datacenter"
          onChange={(e) => setNewName(e.target.value)}
          invalid={errors.name !== undefined}
        />
        <FormFeedback>{errors.name}</FormFeedback>
      </FormGroup>,
      <Button
        onClick={() => {
          createServerFunc(newName);
          setNewName("");
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <TableResult
        title={"Servers"}
        data={listFormated}
        titles={fileTitles}
        sort={sort}
        direction={sortDirection}
        changeOrder={changeOrder}
      />
      <ConfirmModal
        showConfirmation={showConfirmation}
        title="Delete Confirmation"
        closeConfirmation={() => {
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
        }}
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteServerFunc(deleteId);
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
        }}
      >
        {modalMessage}
      </ConfirmModal>
    </>
  );
}

export default ServerList;
