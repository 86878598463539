import {
  Font,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import Aileron from "../font/Aileron-Light.otf";
import AileronBold from "../font/Aileron-Bold.otf";
import CloudSodaLogoSVG from "./soda/CloudSodaLogoSVG";

Font.register({ family: "Aileron", src: Aileron });
Font.register({ family: "AileronBold", src: AileronBold });

// Create styles
const styles = StyleSheet.create({
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 10,
    right: 10,
    padding: 10,
    borderTop: "0.25px solid black",
    display: "flex",
    flexDirection: "row",
  },
  logoBlack: {
    width: 40,
    height: 10,
  },
  copyrightText: {
    fontFamily: "Aileron",
    fontSize: 8,
    color: "gray",
    flex: 1,
    textAlign: "center",
  },
  footerText: {
    fontFamily: "Aileron",
    fontSize: 7,
    color: "black",
  },
  bold: {
    fontFamily: "AileronBold",
  },
  description: {
    fontFamily: "Aileron",
    margin: 4,
    fontSize: 8,
    textAlign: "center",
  },
  card: {
    width: "100%",
    height: 350,
    padding: 16,
    backgroundColor: "#f2f2f2",
    marginBottom: 5,
  },
  subTitle: {
    fontFamily: "AileronBold",
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 8,
  },
  unitDescription: {
    fontFamily: "Aileron",
    margin: 5,
    fontSize: 10,
  },

  deltaInfo: {
    fontFamily: "Aileron",
    marginRight: 5,
    fontSize: 8,
    textAlign: "right",
    marginBottom: 10,
  },
  firstPage: { padding: 15 },
});

const logoColor = "#4f92db";

function getUnitName(unit) {
  const units = {
    B: "byte",
    kB: "kilobyte",
    MB: "megabyte",
    GB: "gigabyte",
    TB: "terabyte",
    PB: "petabyte",
    EB: "exabyte",
    ZB: "zettabyte",
    YB: "yottabyte",
    KiB: "kibibyte",
    MiB: "mebibyte",
    GiB: "gibibyte",
    TiB: "tebibyte",
    PiB: "pebibyte",
    EiB: "exbibyte",
    ZiB: "zebibyte",
    YiB: "yobibyte",
  };

  return units[unit] || "";
}

const getRatio = (totalSize, partialSize) => {
  return (((totalSize - partialSize) / totalSize) * 100).toFixed(2);
};

const Header = () => (
  <>
    <View style={styles.flexRow}>
      <Text
        style={{
          fontSize: 20,
          marginRight: "auto",
          marginBottom: 10,
          fontFamily: "AileronBold",
        }}
      >
        Search Report
      </Text>

      <CloudSodaLogoSVG width={80} color={logoColor} />
    </View>

    <View style={{ borderBottom: "0.25px solid black", marginBottom: 15 }} />
  </>
);

const Footer = () => (
  <View style={styles.footer}>
    <CloudSodaLogoSVG width={40} color={logoColor} />
    <Text style={styles.copyrightText}>
      © {new Date().getFullYear()} CloudSoda, All rights reserved.
    </Text>

    <Text style={styles.footerText}>
      <Link src="https://www.cloudsoda.io">cloudsoda.io</Link>
    </Text>
    <Text
      style={{ ...styles.footerText, marginLeft: "4px" }}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
    />
  </View>
);

const Description = ({ title, subtitle, timeUnit = "", sizeItems = "" }) => {
  let displayText;

  switch (title) {
    case "Creation Date":
    case "Last Access Date":
    case "Last Modified Date":
      displayText = (
        <>
          This chart displays the total size in{" "}
          {subtitle && (
            <Text style={styles.bold}>
              {`${getUnitName(subtitle)}(${subtitle})`}
            </Text>
          )}{" "}
          and the number of files grouped by their{" "}
          {title && (
            <Text style={styles.bold}>
              {title.replace("Date", "").toLowerCase()}
            </Text>
          )}{" "}
          {timeUnit && (
            <Text style={styles.bold}>{timeUnit.toLowerCase()}</Text>
          )}
          .
        </>
      );
      break;
    case "Extensions":
    case "Volumes":
    case "Owners":
    case "Groups":
    case "Duplicate Data":
      displayText = (
        <>
          This chart displays the total size and the number of files of the{" "}
          {sizeItems && <Text style={styles.bold}>{sizeItems}</Text>} most used{" "}
          {title && <Text style={styles.bold}>{title.toLowerCase()}</Text>} in{" "}
          {subtitle && (
            <Text style={styles.bold}>
              {`${getUnitName(subtitle)}(${subtitle})`}
            </Text>
          )}
          .
        </>
      );
      break;
    default:
      displayText = (
        <>
          This chart displays the total cost and the number of files per{" "}
          {timeUnit && (
            <Text style={styles.bold}>
              {timeUnit.replace("LY", " ").toLowerCase()}
            </Text>
          )}{" "}
          grouped by <Text style={styles.bold}>storage type</Text>.
        </>
      );
      break;
  }

  return <Text style={styles.description}>{displayText}</Text>;
};

const Card = ({
  title,
  subtitle,
  refGraph,
  deltaInfo = "",
  timeUnit = "",
  sizeItems = "",
  others,
}) => (
  <div style={styles.card}>
    <Text style={styles.subTitle}>{title}</Text>
    <Text style={styles.unitDescription}>
      {title === "Storage Types" ? "Storage cost " : "Size of files "}
      {`(${subtitle})`}
    </Text>

    <Text style={styles.deltaInfo}>{deltaInfo}</Text>

    <Image style={{ alignSelf: "center" }} src={refGraph} />

    {others !== null && (
      <Text style={{ ...styles.description, paddingTop: 10 }}>
        {" "}
        Others: {others} %
      </Text>
    )}

    <Description
      title={title}
      subtitle={subtitle}
      timeUnit={timeUnit}
      sizeItems={sizeItems}
    />
  </div>
);

// Create Document Component
function PDFDocument(props) {
  const {
    data,
    title,
    subTitle,
    deltaInfo = "",
    timeUnit,
    sizeItems,
    sizeOfFiles,
    sizeShownData,
  } = props;
  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4" style={styles.firstPage}>
        <Header />
        <Card
          title={title}
          subtitle={subTitle}
          deltaInfo={deltaInfo}
          refGraph={data}
          timeUnit={timeUnit}
          sizeItems={sizeItems}
          others={sizeOfFiles > 0 ? getRatio(sizeOfFiles, sizeShownData) : null}
        />
        <Footer />
      </Page>
    </Document>
  );
}
export default PDFDocument;
