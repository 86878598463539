import { Badge } from "reactstrap";
import { formatSize } from "./FormatSize";
import { useSelector } from "react-redux";

export default function PageResultBadge(props) {
  const {
    isFile,
    showBadge,
    total,
    searchSize,
    className
  } = props;

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const formattedWord = () => {
    const type = isFile ? "file" : "folder";
    const plural = total > 1 ? "s" : "";
    return type + plural;
  };

  return (
    showBadge ?
      <Badge
        className={className}
        color="secondary"
        pill
      >
        {total.toLocaleString("en-US")} {formattedWord()} found
        {searchSize !== undefined && ` - ${formatSize(searchSize, baseSize)}`}
      </Badge> :
      null
  );
}