import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { getVolumeIcon } from "../utils/getVolumeIcon";

VolumeIcon.propTypes = {
  volumeType: PropTypes.string,
  size: PropTypes.string,
};

export default function VolumeIcon(props) {
  const { volumeType, size } = props;

  return (
    <FontAwesomeIcon
      icon={getVolumeIcon(volumeType)}
      size={size ? size : "lg"}
      style={{ marginRight: 10 }}
    />
  );
}
