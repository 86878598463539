import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import IconikLogo from "./IconikLogo";

export default function IconikActionButton({ onClickFunction }) {
  const [isIconHovered, setIsIconHovered] = useState(false);

  return (
    <>
      <span
        id="iconikActionButton"
        onClick={onClickFunction}
        style={{ cursor: "pointer", width: "20px", height: "20px" }}
        onMouseEnter={() => setIsIconHovered(true)}
        onMouseLeave={() => setIsIconHovered(false)}
      >
        <IconikLogo isIconHovered={isIconHovered} />
      </span>
      <UncontrolledTooltip target="iconikActionButton" placement="auto">
        Perform an Iconik action
      </UncontrolledTooltip>
    </>
  );
}
