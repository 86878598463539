import PropTypes from "prop-types";
import { getCost } from "../../utils/pricePerVolume";
import { STORAGE_COST_INTERVALS } from "../../constants/storageCostInterval";
import { buildSearchUrl } from "../../search/searchBuilder";
import StorageCostCard from "../../components/StorageCostCard";

ProjectStorageCost.propTypes = {
  projectShares: PropTypes.array,
  volumeSettings: PropTypes.object,
  selectedCostInterval: PropTypes.string,
  setSelectedCostInterval: PropTypes.func,
};

function ProjectStorageCost(props) {
  const {
    projectShares,
    detailedProjectPaths,
    volumeSettings,
    selectedCostInterval,
    setSelectedCostInterval,
  } = props;

  const baseURL = buildSearchUrl(detailedProjectPaths);

  const currencySymbol =
    volumeSettings.items[0] && volumeSettings.items[0].storagePrice
      ? volumeSettings.items[0].storagePrice.currencySymbol
      : "$";

  const getCostForStorages = (size) => {
    return projectShares
      .map((share) => {
        const volumeSetting = volumeSettings.items.find(
          (v) => share.volumeId === v.id
        );
        const storagePrice = volumeSetting ? volumeSetting.storagePrice : null;
        const price = storagePrice ? storagePrice.price : 0;
        if (size === "cost") {
          return getCost(
            share.sizeOfFiles,
            price,
            STORAGE_COST_INTERVALS[selectedCostInterval]
          );
        } else if (size === "duplicate") {
          return getCost(
            share.statistics.DUPLICATE && share.statistics.DUPLICATE.duplicate
              ? share.statistics.DUPLICATE.duplicate.sizeOfFiles
              : 0,
            price,
            STORAGE_COST_INTERVALS[selectedCostInterval]
          );
        } else if (size === "cold_three_months") {
          return getCost(
            share.statistics.ACCESS_DATE &&
              share.statistics.ACCESS_DATE["3 month"]
              ? share.statistics.ACCESS_DATE["3 month"].sizeOfFiles
              : 0,
            price,
            STORAGE_COST_INTERVALS[selectedCostInterval]
          );
        } else if (size === "cold_six_months") {
          return getCost(
            share.statistics.ACCESS_DATE &&
              share.statistics.ACCESS_DATE["6 month"]
              ? share.statistics.ACCESS_DATE["6 month"].sizeOfFiles
              : 0,
            price,
            STORAGE_COST_INTERVALS[selectedCostInterval]
          );
        } else if (size === "cold_one_year") {
          return getCost(
            share.statistics.ACCESS_DATE &&
              share.statistics.ACCESS_DATE["1 year"]
              ? share.statistics.ACCESS_DATE["1 year"].sizeOfFiles
              : 0,
            price,
            STORAGE_COST_INTERVALS[selectedCostInterval]
          );
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0)
      .toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
  };

  const getSizeForStorages = (size) => {
    const projectSize = projectShares
      .map((share) => {
        if (size === "duplicate") {
          return share.statistics.DUPLICATE &&
            share.statistics.DUPLICATE.duplicate
            ? share.statistics.DUPLICATE.duplicate.sizeOfFiles
            : 0;
        } else if (size === "cold_three_months") {
          return share.statistics.ACCESS_DATE &&
            share.statistics.ACCESS_DATE["3 month"]
            ? share.statistics.ACCESS_DATE["3 month"].sizeOfFiles
            : 0;
        } else if (size === "cold_six_months") {
          return share.statistics.ACCESS_DATE &&
            share.statistics.ACCESS_DATE["6 month"]
            ? share.statistics.ACCESS_DATE["6 month"].sizeOfFiles
            : 0;
        } else if (size === "cold_one_year") {
          return share.statistics.ACCESS_DATE &&
            share.statistics.ACCESS_DATE["1 year"]
            ? share.statistics.ACCESS_DATE["1 year"].sizeOfFiles
            : 0;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    const totalSize = projectShares
      .map((share) => share.sizeOfFiles)
      .reduce((a, b) => a + b, 0);

    return Math.round((projectSize / totalSize) * 100);
  };

  return (
    <StorageCostCard
      selectedCostInterval={selectedCostInterval}
      setSelectedCostInterval={(costInterval) => {
        window.localStorage.setItem("storageCostIntervalProject", costInterval);
        setSelectedCostInterval(costInterval);
      }}
      baseURL={baseURL}
      totalCost={getCostForStorages("cost")}
      currencySymbol={currencySymbol}
      duplicateCost={getCostForStorages("duplicate")}
      duplicatePercentage={getSizeForStorages("duplicate")}
      coldData3MonthsCost={getCostForStorages("cold_three_months")}
      coldData3MonthsPercentage={getSizeForStorages("cold_three_months")}
      coldData6MonthsCost={getCostForStorages("cold_six_months")}
      coldData6MonthsPercentage={getSizeForStorages("cold_six_months")}
      coldData1YearCost={getCostForStorages("cold_one_year")}
      coldData1YearPercentage={getSizeForStorages("cold_one_year")}
      margin="mb-sm-2 mb-md-0 mb-lg-3"
    />
  );
}

export default ProjectStorageCost;
