import RectangleChart from "../RectangleChart";
import FormatSize from "../FormatSize";

const colorList = {
  used: "#4C81B6",
  free: "rgba(1, 1, 1, 0.2)",
  positiveVariation: "#375C82",
  negativeVariation: "#8BA6C1"
};

export default function DeltaChart(props) {
  const {
    deltaFile,
    parentSize
  } = props;

  const getShownDeltaColor = () => {
    if (deltaFile.delta === 0) {
      return "black";
    } else if (deltaFile.delta > 0) {
      return colorList.positiveVariation;
    }
    return colorList.negativeVariation;
  };

  const getShownDeltaValue = () => {
    if (deltaFile.delta === 0) {
      return "—";
    } else if (deltaFile.delta > 0) {
      return <>+<FormatSize>{deltaFile.delta}</FormatSize></>;
    }
    return <FormatSize>{deltaFile.delta}</FormatSize>;
  };

  const getUsedValue = () => {
    if (deltaFile.size === Math.abs(deltaFile.delta)) {
      return 0;
    } else if (deltaFile.delta >= 0) {
      return deltaFile.size - deltaFile.delta;
    }
    return deltaFile.size >= 0 ? deltaFile.size : 0;
  };

  const labelsForRemove = ["Current Size", "Size Decrease", "Other Folder Sizes"];
  const labelsForAdded = ["Previous Size", "Size Growth", "Other Folder Sizes"];

  const usedSize = getUsedValue();
  const deltaSize = Math.abs(deltaFile.delta);
  const leftSize = parentSize - (getUsedValue() + Math.abs(deltaFile.delta));

  return (
    <div className="d-flex align-items-center" style={{ width: "90%" }}>
      <div style={{ width: "50%" }}>
        <RectangleChart
          data={[usedSize, deltaSize, leftSize]}
          colors={[colorList.used, getShownDeltaColor(), colorList.free]}
          labels={[
            <>{deltaFile.delta > 0 ? labelsForAdded[0] : labelsForRemove[0]} {<FormatSize>{usedSize}</FormatSize>} ({Math.round((usedSize / parentSize) * 100)}%)</>,
            <>{deltaFile.delta > 0 ? labelsForAdded[1] : labelsForRemove[1]} {<FormatSize>{deltaSize}</FormatSize>} ({Math.round((deltaSize / parentSize) * 100)}%)</>,
            <>{deltaFile.delta > 0 ? labelsForAdded[2] : labelsForRemove[2]} {<FormatSize>{leftSize}</FormatSize>} ({Math.round((leftSize / parentSize) * 100)}%)</>
          ]}
        />
      </div>
      <span className="ps-3" style={{ color: getShownDeltaColor() }}>{getShownDeltaValue()}</span>
    </div>
  );
}