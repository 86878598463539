import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

function ProfileInfo(props) {
    const { name, username, email } = props;
    return (
        <div className="row">
            <div className="col-md-4">
                <Card className="mb-3">
                    <CardHeader tag="h5">
                        Information
                    </CardHeader>
                    <CardBody className="p-4">
                        <div className="mb-2">
                            <strong className="me-1">Name:</strong> {name}
                        </div>
                        <div className="mb-2">
                            <strong className="me-1">Username:</strong> {username}
                        </div>
                        <div>
                            <strong className="me-1">Email:</strong> {email}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default ProfileInfo;
