import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import NewFolderTreeWrapperPathPicker from "../folderTree/FolderTreeWrapperPathPicker";
import { getStorageManagerVolumeMappings } from "../../api/storageManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

export default function SelectLocation(props) {
  const { relocate, setRelocate, numberOfValidFiles } = props;
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);
  const [locationVolume, setLocationVolume] = useState({});
  const [storageManagerVolumeMappings, setStorageManagerVolumeMappings] =
    useState();
  const [isSpecifyLocationChecked, setIsSpecifyLocationChecked] =
    useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    fetchStorageManagerVolumeMappings();
    setRelocate(null);
  }, []);

  useEffect(() => {
    if (locationVolume.id) {
      setIsLocationPickerOpen(false);
    }
  }, [locationVolume]);

  const fetchStorageManagerVolumeMappings = async () => {
    try {
      const result = await getStorageManagerVolumeMappings(dispatch, auth);
      setStorageManagerVolumeMappings(result.elements);
    } catch (err) {
      const message =
        err.message ||
        "Unable to fetch Quantum StorNext storage path configurations";
      dispatch(setAlert(message, "danger"));
    }
  };

  return (
    <>
      <FormGroup check className="mt-3">
        <Label check>
          <Input
            type="checkbox"
            checked={isSpecifyLocationChecked}
            onChange={(e) => {
              setIsSpecifyLocationChecked(e.target.checked);
            }}
          />
          Specify the location on the Storage Manager server file system
        </Label>
      </FormGroup>
      {isSpecifyLocationChecked && (
        <FormGroup className="mt-2">
          <Label>Location on the Storage Manager server file system</Label>
          <InputGroup>
            <Input
              disabled
              type="text"
              value={relocate ?? ""}
              onChange={(e) => setRelocate(e.target.value)}
            />
            <Button onClick={() => setIsLocationPickerOpen(true)}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </Button>
          </InputGroup>
        </FormGroup>
      )}
      {storageManagerVolumeMappings && (
        <NewFolderTreeWrapperPathPicker
          showCheckbox={false}
          isOpen={isLocationPickerOpen}
          setIsOpen={setIsLocationPickerOpen}
          volumeIds={storageManagerVolumeMappings.map(
            (volume) => volume.volumeId
          )}
          setCheckedPath={setRelocate}
          setCheckedVolume={setLocationVolume}
          modalTitle="Select location"
        />
      )}
      {numberOfValidFiles >= 100000 && (
        <span style={{ color: "#eda003" }}>
          You are about to relocate more than 100 000 files. It could take a
          considerable amount of time to complete the process.
        </span>
      )}
    </>
  );
}
