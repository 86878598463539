export default function TimelineLegend(props) {
  const { customLabel, minValue, maxValue, unit } = props;

  const redBarStyle = {
    height: 10,
    width: 30,
    backgroundColor: "rgba(201, 33, 33, 0.5)",
    borderColor: "rgb(201, 33, 33)",
  };

  const lineStyle = {
    position: "relative",
    bottom: 4,
    width: 30,
    zIndex: 1,
  };

  const pointStyle = {
    height: 10,
    width: 10,
    left: 20,
    position: "relative",
    borderRadius: 10,
    zIndex: 2,
  };

  return (
    <div style={{ textAlign: "center" }}>
      <canvas style={redBarStyle} />
      <span className="ms-1">
        {customLabel ? customLabel : "Over Maximum Capacity"}
      </span>

      <canvas style={{ ...pointStyle, backgroundColor: "#007BFF" }} />
      <canvas
        style={{ ...lineStyle, borderBottom: "1px dashed rgb(128,128,128)" }}
      />
      <span className="ms-1">
        Minimum Used Capacity ({minValue} {unit})
      </span>

      <canvas style={{ ...pointStyle, backgroundColor: "#FF0000" }} />
      <canvas style={{ ...lineStyle, borderBottom: "1px dashed black" }} />
      <span className="ms-1">
        Maximum Used Capacity ({maxValue} {unit})
      </span>
    </div>
  );
}
