import { 
    REQUEST_PATHS_STATISTICS,
    RECEIVE_PATHS_STATISTICS
} from '../actions';


function paths(state, action, key) {

    let formattedPaths = {};

    action.paths.forEach((path, index) => {
        formattedPaths[path] = Object.assign({}, state[path], {
            [key]: action.statistics[index]
        })
    })

    return formattedPaths;
}

function getKey(action) {
    let key = 'all';
     if (action.isDuplicate) {
        key = 'duplicate'
     } else if (action.lastAccessDate) {
         key = action.lastAccessDate;
     }

     return key;
}

function statisticsByPath(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PATHS_STATISTICS:
            return Object.assign({}, state, paths(state, action, getKey(action)))
        case REQUEST_PATHS_STATISTICS:
        default:
            return state
    }
}

export default statisticsByPath;
