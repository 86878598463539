import { useDispatch, useSelector } from "react-redux";
import { setSizeBaseValue } from "../ducks/userSettings";
import { UncontrolledTooltip } from "reactstrap";
import { useState } from "react";

export default function SizeBaseButton(props) {
  const { marginRight, marginLeft } = props;

  const [isHovered, setIsHovered] = useState(false);

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const dispatch = useDispatch();

  return (
    <>
      <span
        id="sizeBaseButton"
        onClick={e => {
          e.stopPropagation();
          dispatch(setSizeBaseValue(baseSize === "10" ? "2" : "10"));
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          border: "1px solid #6c757d",
          borderRadius: "5px",
          textAlign: "center",
          color: "#6c757d",
          width: "18px",
          display: "inline-block",
          marginRight: marginRight,
          marginLeft: marginLeft,
          fontSize: "10px",
          order: 2
        }}
      >
        {isHovered ? (baseSize === "10" ? "2" : "10") : baseSize}
      </span>
      <UncontrolledTooltip target="sizeBaseButton" placement="auto">
        Change unit measurement to {baseSize === "2" ? "decimal" : "binary"}
      </UncontrolledTooltip>
    </>
  );
}