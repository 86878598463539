import {
  SELECT_DATES,
  REQUEST_ALL_VOLUMES,
  RECEIVE_ALL_VOLUMES,
} from "../actions";

// "crawl.files" is the volume type for OnPrem volumes
const ONPREM_VOLUME_TYPE = "crawl.files";
const NETWORK_VOLUME_TYPE = "NetworkVolume";
const DELLPOWERSCALE_VOLUME_TYPE = "DellPowerScale";

const initialState = {
  dates: [],
  volumesByName: [],
  volumeList: [],
  bucketList: [],
  isFetching: false,
};

function volumesByName(volumes) {
  let volumesByName = {};
  volumes.forEach((element) => {
    if (!volumesByName[element.name]) {
      volumesByName[element.name] = [];
    }
    volumesByName[element.name].push(element);
  });

  for (const volumes in volumesByName) {
    volumesByName[volumes].sort(
      (a, b) => new Date(b.indexingDate) - new Date(a.indexingDate)
    );
  }

  return volumesByName;
}

function mostRecentVolume(volumes) {
  let volumeList = [];
  let ids = {};
  volumes.forEach((element) => {
    if (!ids[element.id]) {
      ids[element.id] = true;
      volumeList.push(element);
    }
  });
  volumeList.sort((a, b) => (a.name > b.name ? 1 : -1));
  return volumeList;
}

function dashboard(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ALL_VOLUMES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ALL_VOLUMES:
      const volumeLists = [];
      const bucketLists = [];
      action.volumes.forEach((volume) => {
        if (
          !volume.type ||
          ONPREM_VOLUME_TYPE === volume.type ||
          NETWORK_VOLUME_TYPE === volume.type ||
          DELLPOWERSCALE_VOLUME_TYPE === volume.type
        ) {
          volumeLists.push(volume);
        } else {
          bucketLists.push({
            ...volume,
            name: volume.name.replace("S3-BUCKET-", ""),
          });
        }
      });
      return Object.assign({}, state, {
        isFetching: false,
        volumesByName: volumesByName(
          action.volumes.map((volume) => ({
            ...volume,
            name:
              volume.type === "AWS-S3"
                ? volume.name.replace("S3-BUCKET-", "")
                : volume.name,
          }))
        ),
        volumeList: mostRecentVolume(volumeLists),
        bucketList: mostRecentVolume(bucketLists),
      });
    case SELECT_DATES:
      return Object.assign({}, state, {
        dates: action.dates,
      });
    default:
      return state;
  }
}

export default dashboard;
