import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare as fasCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare as farCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const CHECKED_STATUS = ["UNCHECK", "CHECKDISABLE", "CHECK"];
const CHECKED_COLORS = ["rgb(143,143,156)", "#007BFF", "rgb(194,194,200)"];

export default function CheckboxButton(props) {
  const {
    handleSelectNone,
    handleSelectCurrentPage,
    handleSelectAll,
    isSelectAllAvailable,
    isCurrentPageSelected,
    isSelectAllSelected,
    selectAllText = "Select all",
    selectCurrentText = "Select current page",
    selectNoneText = "Select none",
  } = props;

  const [checkedStatus, setCheckedStatus] = useState(CHECKED_STATUS[0]);
  const [iconColor, setIconColor] = useState(CHECKED_COLORS[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (isSelectAllSelected) {
      setCheckedStatus(CHECKED_STATUS[2]);
      setIconColor(CHECKED_COLORS[2]);
    }
    else {
      if (isCurrentPageSelected) {
        setCheckedStatus(CHECKED_STATUS[1]);
        setIconColor(CHECKED_COLORS[1]);
      } else {
        setCheckedStatus(CHECKED_STATUS[0]);
        setIconColor(CHECKED_COLORS[0]);
      }
    }
  }, [isCurrentPageSelected, isSelectAllSelected]);

  const getIconFromStatus = () => {
    switch (checkedStatus) {
      case "CHECK":
        return fasCheckSquare;
      case "CHECKDISABLE":
        return farCheckSquare;
      case "UNCHECK":
      default:
        return faSquare;
    }
  };

  return (
    <Dropdown
      toggle={() => setIsDropdownOpen(!isDropdownOpen)}
      size="sm"
      isOpen={isDropdownOpen}
      style={{ cursor: "pointer", "marginLeft": "6px" }}
    >
      <DropdownToggle caret tag="span">
        <FontAwesomeIcon
          icon={getIconFromStatus()}
          className="ms-1"
          color={iconColor}
          size="lg"
        />
      </DropdownToggle>
      <DropdownMenu>
        {isSelectAllAvailable &&
          <DropdownItem onClick={() => {
            setCheckedStatus(CHECKED_STATUS[2]);
            setIconColor(CHECKED_COLORS[2]);
            handleSelectAll();
          }}>
            {selectAllText}
          </DropdownItem>
        }
        <DropdownItem
          disabled={isSelectAllSelected}
          onClick={() => {
            setCheckedStatus(CHECKED_STATUS[1]);
            setIconColor(CHECKED_COLORS[1]);
            handleSelectCurrentPage();
          }}
        >
          {selectCurrentText}
        </DropdownItem>
        <DropdownItem onClick={() => {
          setCheckedStatus(CHECKED_STATUS[0]);
          setIconColor(CHECKED_COLORS[0]);
          handleSelectNone();
        }}>
          {selectNoneText}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}