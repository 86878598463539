import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithAuth } from "../../../actions/requestHelper";
import { Input, Label, Spinner, FormFeedback, Tooltip } from "reactstrap";
import { checkIfTwoObjectsAreEqual } from "../../../utils/checkIfTwoObjectsAreEqual";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import SelectWithCustomValue from "../../../components/SelectWithCustomValue";

function BucketStep2({
  bucketObj,
  setBucketObj,
  setDisableForwardActionButton,
  bucketStep1InputsCopy,
  bucketNameList,
  setBucketNameList,
  bucketNamesError,
  setBucketNamesError,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const existingBuckets = useSelector(({ buckets }) => buckets.items);

  // remove the keys that are not used for fetching a bucket before checking
  // the objects equality to determine if buckets should be re-fetched
  const bucketObjCopy = Object.assign({}, bucketObj);
  delete bucketObjCopy.description;
  delete bucketObjCopy.storageName;
  delete bucketObjCopy.storageType;
  delete bucketObjCopy.name;
  delete bucketObjCopy.path;

  const [isLoading, setIsloading] = useState(
    bucketObj.type === "DROPBOX"
      ? false
      : checkIfTwoObjectsAreEqual(bucketObjCopy, bucketStep1InputsCopy.current)
        ? false
        : true
  );
  const [descriptionError, setDescriptionError] = useState("");
  const [pathError, setPathError] = useState("");
  const [isNameTooltipOpen, setIsNameTooltipOpen] = useState(false);
  const [isPathTooltipOpen, setIsPathTooltipOpen] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!isLoading && bucketObj.description === "") {
      setDisableForwardActionButton(true);
    }

    if (
      !unmounted &&
      isLoading &&
      !checkIfTwoObjectsAreEqual(bucketObjCopy, bucketStep1InputsCopy.current)
    ) {
      setDisableForwardActionButton(true);
      setBucketNamesError((prev) => ({
        ...prev,
        hasError: false,
      }));

      bucketStep1InputsCopy.current.namespace = bucketObj.namespace;
      bucketStep1InputsCopy.current.region = bucketObj.region;
      bucketStep1InputsCopy.current.type = bucketObj.type;
      bucketStep1InputsCopy.current.credentialsId = bucketObj.credentialsId;

      (async function () {
        const path =
          bucketObj.type === "ORACLE_OBJECT_STORAGE"
            ? `/v1/buckets/names?credentialsId=${bucketObj.credentialsId}&region=${bucketObj.region}&namespace=${bucketObj.namespace}`
            : `/v1/buckets/names?credentialsId=${bucketObj.credentialsId}&region=${bucketObj.region}`;

        try {
          const res = await fetchWithAuth(
            dispatch,
            path,
            auth.accessToken,
            "POST"
          );
          const firstIndexOfRes = res.length ? res[0] : "";
          const descriptionExists = existingBuckets.find(
            (bucket) => bucket.description === firstIndexOfRes
          );
          setDescriptionError(
            descriptionExists ? "Description name must be unique" : ""
          );

          if (descriptionExists) {
            setDisableForwardActionButton(true);
          }

          setBucketNameList(res);
          setBucketObj((prev) => ({
            ...prev,
            description: firstIndexOfRes,
            name: firstIndexOfRes,
          }));
          setDisableForwardActionButton(false);
          setIsloading(false);
        } catch (e) {
          setBucketNameList([]);
          setBucketNamesError({
            hasError: true,
            errorMessage: e.message === undefined ? null : e.message,
          });
          setBucketObj((prev) => ({
            ...prev,
            description: "",
            name: "",
          }));
          setIsloading(false);
        }
      })();
    }

    return () => {
      unmounted = true;
    };
  }, [
    dispatch,
    auth,
    bucketObj,
    setBucketObj,
    isLoading,
    setDisableForwardActionButton,
    bucketStep1InputsCopy,
    bucketObjCopy,
    setBucketNameList,
    existingBuckets,
    setBucketNamesError,
  ]);

  useEffect(() => {
    setDisableForwardActionButton(
      !!!(bucketObj.name && bucketObj.description) || descriptionError
    );
  }, [bucketObj.name, bucketObj.description]);

  const setPathErrorMessage = (input) => {
    const pathIsEmpty = input.length === 0;

    setPathError(pathIsEmpty ? "Path can't be empty" : "");

    if (pathIsEmpty) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  };
  const setDescriptionErrorMessage = (input) => {
    const descriptionExists = existingBuckets.find(
      (bucket) => bucket.description === input
    );
    const descriptionIsEmpty = input.length === 0;

    setDescriptionError(
      descriptionIsEmpty
        ? "Description can't be empty"
        : descriptionExists
          ? "Description name must be unique"
          : ""
    );

    if (descriptionExists || descriptionIsEmpty) {
      setDisableForwardActionButton(true);
    } else {
      setDisableForwardActionButton(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-3">
          <Spinner />
        </div>
      ) : (
        <div>
          {bucketObj.type !== "DROPBOX" ? (
            <>
              <Label for="bucketNameOnStorageServer">Bucket Name</Label>
              <SelectWithCustomValue
                values={bucketNameList}
                selectedValue={bucketObj.name}
                setSelectedValue={(value) => {
                  const inputValue = value ?? "";
                  setBucketObj((prev) => ({
                    ...prev,
                    name: inputValue,
                    description: inputValue,
                  }));
                  setDescriptionErrorMessage(inputValue);
                }}
              />
              {bucketNamesError.hasError && (
                <span
                  className="mt-2"
                  style={{ fontSize: "80%", color: "#eda003" }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="me-1"
                  />
                  No buckets were found with the given information, you can
                  still enter the bucket name manually
                </span>
              )}
            </>
          ) : (
            <>
              <Label for="path">
                Path
                <FontAwesomeIcon
                  id="pathLabel"
                  className="ms-sm-1"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  placement="right"
                  isOpen={isPathTooltipOpen}
                  target="pathLabel"
                  toggle={() => setIsPathTooltipOpen(!isPathTooltipOpen)}
                >
                  The Dropbox path from which the scan should start. To scan
                  everything in your Dropbox, enter /.
                </Tooltip>
              </Label>

              <Input
                name="path"
                type="text"
                placeholder="Ex: /Projects"
                value={bucketObj.path}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setPathErrorMessage(e.target.value);
                  setBucketObj((prev) => ({
                    ...prev,
                    path: inputValue,
                  }));
                }}
                invalid={Boolean(pathError.length)}
              />
              <FormFeedback>{pathError}</FormFeedback>
            </>
          )}

          <div className="mt-3">
            <Label for="name">
              Name
              <FontAwesomeIcon
                id="nameLabel"
                className="ms-sm-1"
                icon={faQuestionCircle}
              />
              <Tooltip
                placement="right"
                isOpen={isNameTooltipOpen}
                target="nameLabel"
                toggle={() => setIsNameTooltipOpen(!isNameTooltipOpen)}
              >
                The name of the bucket that will be used in DataIntell
              </Tooltip>
            </Label>

            <Input
              name="name"
              type="text"
              value={bucketObj.description}
              onChange={(e) => {
                const inputValue = e.target.value;
                setDescriptionErrorMessage(e.target.value);
                bucketObj.type !== "DROPBOX"
                  ? setBucketObj((prev) => ({
                    ...prev,
                    description: inputValue,
                  }))
                  : setBucketObj((prev) => ({
                    ...prev,
                    description: inputValue,
                    name: inputValue,
                  }));
              }}
              invalid={Boolean(descriptionError.length)}
            />
            <FormFeedback>{descriptionError}</FormFeedback>
          </div>
        </div>
      )}
    </>
  );
}

export default BucketStep2;
