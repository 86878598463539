import { fetchWithAuth } from "../actions/requestHelper";

export const get = async (dispatch, auth, id) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/jobs/${id}`,
    auth.accessToken,
    "GET"
  );
};

export const getJobJobTypes = async (dispatch, auth) => {
  return await fetchWithAuth(
    dispatch,
    "/v1/jobs/types",
    auth.accessToken,
    "GET"
  );
};

export const update = async (dispatch, auth, job, id) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/jobs/${id}`,
    auth.accessToken,
    "PUT",
    JSON.stringify(job)
  );
};

export const getAllTasksFromJob = async (dispatch, auth, id) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/jobs/${id}/tasks`,
    auth.accessToken
  );
};

export const cancelJobs = async (dispatch, auth) => {
  return await fetchWithAuth(
    dispatch,
    `/v1/jobs/cancel`,
    auth.accessToken,
    "PUT"
  );
};