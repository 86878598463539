import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Interface from "./Interface";

import Dashboard from "../dashboard/Dashboard";
import Search from "../search/Search";
import Error from "./Error";
import Login from "../login/Login";
import Alert from "./Alert";

import FileManagement from "../fileManagement/FileManagement";

//Project Components
import ProjectsContainer from "../projects/ProjectsContainer";
import NewProject from "../projects/NewProject";
import EditProject from "../projects/EditProject";
import Project from "../projects/Project";

//Settings Components
import General from "../settings/General";
import Data from "../settings/Data";
import Users from "../settings/Users";
import Schedules from "../settings/Schedules";
import Jobs from "../settings/Jobs";

import Authorization from "../validation/Authorization";
import Folders from "../folders/Folders";
import Profile from "../profile/Profile";
import Volumes from "../volumes/Volumes";
import Reports from "../reports/Reports";
import Report from "../reports/Report";

class AppRouter extends Component {
  render() {
    return (
      <Router>
        <span>
          <Alert />
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route 
              path="/"
              element={
                <Interface>
                  <Dashboard />
                </Interface>
              }
            />

            <Route 
              path="/filemanagement/*" 
              element={
                <Interface>
                  <FileManagement />
                </Interface>  
              }
            />

            <Route 
              path="/volumes/:id"
              element={
                <Interface>
                  <Volumes />
                </Interface>
              }
            />

            <Route 
              path="/projects"
              element={
                <Interface>
                  <ProjectsContainer />
                </Interface>
              }
            />
              
            <Route 
              path="/projects/new"
              element={
                <Interface>
                  <NewProject />
                </Interface>
              }
            />
             
            <Route 
              path="/project/:id"
              element={
                <Interface>
                  <Project />
                </Interface>
              }
            />
              
            <Route
              path="/reports"
              element={
                <Interface>
                  <Reports />
                </Interface>
              }
            />
              
            <Route 
              path="/report/:id"
              element={
                <Interface>
                  <Report />
                </Interface>
              }
            />

            <Route 
              path="/folders"
              element={
                <Interface>
                  <Folders />
                </Interface>
              }
            />

            <Route
              path="/project/edit/:id"
              element={
                <Interface>
                  <EditProject />
                </Interface>
              }
            />
           
            <Route 
              path="/search/*"
              element={
                <Interface>
                  <Search />
                </Interface>
              }
            />
              
            <Route 
              path="/settings"
              element={
                <Authorization>
                  <Interface>
                    <General />
                  </Interface>
                </Authorization>
              }  
            />
              
            <Route 
              path="/settings/data"
              element={
                <Authorization>
                  <Interface>
                    <Data />
                  </Interface>
                </Authorization>
              }
            />
             
            <Route 
              path="/settings/users"
              element={
                <Authorization>
                  <Interface>
                    <Users />
                  </Interface>
                </Authorization>
              }
            />
              
            <Route 
              path="/settings/schedules"
              element={
                <Authorization>
                  <Interface>
                    <Schedules />
                  </Interface>
                </Authorization>
              }
            />
              
            <Route 
              path="/settings/jobs"
              element={
                <Authorization>
                  <Interface>
                    <Jobs />
                  </Interface>
                </Authorization>
              }
            />
             
            <Route 
              path="/profile"
              element={
                <Interface>
                  <Profile />
                </Interface>
              }
            />
              
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </span>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  const { roles } = auth;

  return {
    roles
  };
}

export default connect(mapStateToProps)(AppRouter);
