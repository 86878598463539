import { fetchWithAuth } from "../actions/requestHelper";

// === Actions ===
// READ
const REQUEST_VOLUME_SETTINGS = "REQUEST_VOLUME_SETTINGS";
const RECEIVE_VOLUME_SETTINGS = "RECEIVE_VOLUME_SETTINGS";

// === Reducers ===
export default function volumeSettings(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST_VOLUME_SETTINGS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_VOLUME_SETTINGS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.volumes.elements.map((element) => ({
          ...element,
          name:
            element.type === "AWS-S3"
              ? element.name.replace("S3-BUCKET-", "")
              : element.name,
        })),
        page: action.volumes.page,
        total: action.volumes.total,
        size: action.volumes.size,
        sort: action.volumes.sort,
        direction: action.volumes.direction.toLowerCase(),
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestVolumeSettings() {
  return {
    type: REQUEST_VOLUME_SETTINGS,
  };
}

function receiveVolumeSettings(volumes) {
  return {
    type: RECEIVE_VOLUME_SETTINGS,
    volumes,
  };
}

// === Side Effects ===
export function fetchVolumeSettings(
  page = 1,
  sort = "name",
  direction = "asc",
  size = 200,
  filterType = []
) {
  return (dispatch, getState) => {
    dispatch(requestVolumeSettings());
    var url = `/volumeSettings?page=${page}&sort=${sort},${direction}&size=${size}`;
    filterType.forEach((filter) => (url += `&filterType=${filter}`));
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(receiveVolumeSettings(json));
      }
    ).catch(err => console.error(err));
  };
}

function shouldFetchVolumeSettings(state) {
  const { volumeSettings } = state;

  if (volumeSettings.items.length === 0) {
    return true;
  } else {
    return false;
  }
}

export function fetchVolumeSettingsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchVolumeSettings(getState())) {
      return dispatch(fetchVolumeSettings());
    } else {
      return Promise.resolve();
    }
  };
}
