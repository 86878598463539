export const REPORT_DESCRIPTIONS = {
  DUPLICATED: (
    <span>
      This report will find all the files and folders from the{" "}
      <strong>reference</strong> list that has a copy on the{" "}
      <strong>comparison</strong> list.
    </span>
  ),
  UNIQUE: (
    <span>
      This report will find all the files and folders from the{" "}
      <strong>reference</strong> list that do not have a copy on the{" "}
      <strong>comparison</strong> list.
    </span>
  ),
  SEARCH_QUERY: (
    <span>
      This report will find all the files and folders that match this{" "}
      <strong>search query</strong>.
    </span>
  ),
};
