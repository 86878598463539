import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {
  AWS_REGIONS,
  ORACLE_OBJECT_STORAGE_REGIONS,
  B2_REGIONS,
  WASABI_REGIONS,
} from "../dataSource/bucket/cloudProvidersRegions";

BucketForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  namespace: PropTypes.string,
  description: PropTypes.string,
  region: PropTypes.string,
  type: PropTypes.string,
  credentials: PropTypes.array,
  storagePriceList: PropTypes.array,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  setIsSaveButtonEnabled: PropTypes.func,
};

function BucketForm(props) {
  const {
    id,
    name,
    path,
    namespace,
    description,
    region,
    type,
    awsCredentialsId,
    credentials,
    storagePriceList,
    storagePriceId,
    handleChange,
    errors,
    setIsSaveButtonEnabled,
  } = props;

  const [isAwsCredentialsTooltipOpen, setIsAwsCredentialsTooltipOpen] =
    useState(false);
  const [isDescriptionTooltipOpen, setIsDescriptionTooltipOpen] =
    useState(false);
  const [isPathTooltipOpen, setIsPathTooltipOpen] = useState(false);
  const [isNamespaceTooltipOpen, setIsNamespaceTooltipOpen] = useState(false);
  const [fieldError, setFieldError] = useState({
    awsCredentialsId: "",
    region: "",
    name: "",
    namespace: "",
    path: "",
    description: "",
    storagePrice: "",
  });

  useEffect(() => {
    if (region === "") {
      setFieldError((prev) => ({ ...prev, region: "Region can't be empty" }));
    } else {
      setFieldError((prev) => ({ ...prev, region: "" }));
    }
  }, [region]);

  useEffect(() => {
    switch (type) {
      case "ORACLE_OBJECT_STORAGE":
        if (
          fieldError.awsCredentialsId === "" &&
          fieldError.region === "" &&
          fieldError.name === "" &&
          fieldError.namespace === "" &&
          fieldError.description === "" &&
          fieldError.storagePrice === ""
        ) {
          setIsSaveButtonEnabled(true);
        } else {
          setIsSaveButtonEnabled(false);
        }
        break;
      case "AZURE":
      case "OTHER":
        if (
          fieldError.awsCredentialsId === "" &&
          fieldError.name === "" &&
          fieldError.description === "" &&
          fieldError.storagePrice === ""
        ) {
          setIsSaveButtonEnabled(true);
        } else {
          setIsSaveButtonEnabled(false);
        }
        break;

      case "DROPBOX":
        if (
          fieldError.awsCredentialsId === "" &&
          fieldError.path === "" &&
          fieldError.description === "" &&
          fieldError.storagePrice === ""
        ) {
          setIsSaveButtonEnabled(true);
        } else {
          setIsSaveButtonEnabled(false);
        }
        break;

      default:
        if (
          fieldError.awsCredentialsId === "" &&
          fieldError.region === "" &&
          fieldError.name === "" &&
          fieldError.description === "" &&
          fieldError.storagePrice === ""
        ) {
          setIsSaveButtonEnabled(true);
        } else {
          setIsSaveButtonEnabled(false);
        }
        break;
    }
  }, [
    fieldError.awsCredentialsId,
    fieldError.region,
    fieldError.name,
    fieldError.path,
    fieldError.namespace,
    fieldError.description,
    fieldError.storagePrice,
    type,
  ]);

  const existingBuckets = useSelector(({ buckets }) => buckets.items);

  const handleFieldError = (field, value) => {
    let fieldName = "";
    let reason = "";

    if (value === "") {
      reason = " can't be empty";
    }

    switch (field) {
      case "awsCredentialsId":
        fieldName = "Credentials";
        break;
      case "region":
        fieldName = "Region";
        break;
      case "name":
        fieldName = "Bucket Name";
        break;
      case "path":
        fieldName = "Path";
        break;
      case "namespace":
        fieldName = "Namespace";
        break;
      case "description":
        fieldName = "DataIntell Name";
        const descriptionExists = existingBuckets.some(
          (bucket) => bucket.id !== id && bucket.description === value
        );
        if (descriptionExists) reason = " must be unique";
        break;
      case "storagePrice":
        fieldName = "Storage Type";
        break;
      default:
        break;
    }

    setFieldError((prev) => ({
      ...prev,
      [field]: reason === "" ? "" : fieldName + reason,
    }));
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    handleChange(name, value);
    handleFieldError(name, value);
  };

  const getRegionListForBucketType = (type) => {
    switch (type) {
      case "S3":
        return AWS_REGIONS;
      case "ORACLE_OBJECT_STORAGE":
        return ORACLE_OBJECT_STORAGE_REGIONS;
      case "BACKBLAZE_B2":
        return B2_REGIONS;
      case "WASABI":
        return WASABI_REGIONS;
      default:
        return [];
    }
  };

  return (
    <Form>
      <FormGroup row>
        <Label for="type" sm={4}>
          Cloud Provider
        </Label>
        <Col sm={8}>
          <Input
            name="type"
            type="select"
            value={type}
            onChange={handleInputChange}
          >
            <option value="S3">AWS S3</option>
            <option value="ORACLE_OBJECT_STORAGE">Oracle Object Storage</option>
            <option value="BACKBLAZE_B2">BackBlaze B2</option>
            <option value="WASABI">Wasabi</option>
            <option value="AZURE">Azure</option>
            <option value="DROPBOX">Dropbox</option>
            <option value="OTHER">Other</option>
          </Input>
        </Col>
      </FormGroup>
      {type === "BACKBLAZE_B2" && (
        <p>
          <strong>Note:</strong> BackBlaze B2 buckets created prior to May 4th,
          2020 are not compatible. If that is the case, create a new bucket.
        </p>
      )}
      <FormGroup row>
        <Label for="awsCredentialsId" sm={4}>
          Credentials
          <FontAwesomeIcon
            id="awsCredentialsLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <Tooltip
            placement="right"
            isOpen={isAwsCredentialsTooltipOpen}
            target="awsCredentialsLabel"
            toggle={() =>
              setIsAwsCredentialsTooltipOpen(!isAwsCredentialsTooltipOpen)
            }
          >
            Credentials are needed for authentication. You need to set up
            credentials before creation.
          </Tooltip>
        </Label>
        <Col sm={8}>
          <Input
            name="awsCredentialsId"
            type="select"
            value={awsCredentialsId}
            onChange={handleInputChange}
            invalid={
              errors["awsCredentialsId"] !== undefined ||
              fieldError.awsCredentialsId !== ""
            }
          >
            <option value={""}>Select credential</option>
            {credentials.map((credential) => {
              return (
                <option key={credential.id} value={credential.id}>
                  {credential.name}
                </option>
              );
            })}
          </Input>
          <FormFeedback>
            {errors["awsCredentialsId"] || fieldError.awsCredentialsId}
          </FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="descriptionLabel" sm={4}>
          Name
          <FontAwesomeIcon
            id="descriptionLabel"
            className="ms-sm-1"
            icon={faQuestionCircle}
          />
          <Tooltip
            placement="right"
            isOpen={isDescriptionTooltipOpen}
            target="descriptionLabel"
            toggle={() =>
              setIsDescriptionTooltipOpen(!isDescriptionTooltipOpen)
            }
          >
            The name of the bucket that will be used in DataIntell
          </Tooltip>
        </Label>
        <Col sm={8}>
          <Input
            id="description"
            name="description"
            type="text"
            placeholder="Ex: Bucket name"
            value={description}
            onChange={handleInputChange}
            invalid={
              errors["description"] !== undefined ||
              fieldError.description !== ""
            }
          />
          <FormFeedback>
            {errors["description"] || fieldError.description}
          </FormFeedback>
        </Col>
      </FormGroup>
      {type === "ORACLE_OBJECT_STORAGE" && (
        <FormGroup row>
          <Label for="namespace" sm={4}>
            Namespace
            <FontAwesomeIcon
              id="namespaceLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isNamespaceTooltipOpen}
              target="namespaceLabel"
              toggle={() => setIsNamespaceTooltipOpen(!isNamespaceTooltipOpen)}
            >
              The namespace is found under the Bucket Information section in
              Oracle Object Storage
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              name="namespace"
              type="text"
              placeholder={"Ex: axaxnpcrorw5"}
              value={namespace}
              onChange={handleInputChange}
              invalid={
                errors["namespace"] !== undefined || fieldError.namespace !== ""
              }
            />
            <FormFeedback>
              {errors["namespace"] || fieldError.namespace}
            </FormFeedback>
          </Col>
        </FormGroup>
      )}
      {type !== "DROPBOX" ? (
        <FormGroup row>
          <Label for="nameLabel" sm={4}>
            Bucket Name
          </Label>
          <Col sm={8}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder={type === "Ex: bucket-name"}
              value={name}
              onChange={handleInputChange}
              invalid={errors["name"] !== undefined || fieldError.name !== ""}
            />
            <FormFeedback>{errors["name"] || fieldError.name}</FormFeedback>
          </Col>
        </FormGroup>
      ) : (
        <FormGroup row>
          <Label for="pathLabel" sm={4}>
            Path
            <FontAwesomeIcon
              id="pathLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isPathTooltipOpen}
              target="pathLabel"
              toggle={() => setIsPathTooltipOpen(!isPathTooltipOpen)}
            >
              The Dropbox path from which the scan should start. To scan
              everything in your Dropbox, enter /.
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="path"
              name="path"
              type="text"
              placeholder="Ex: /Projects"
              value={path}
              onChange={handleInputChange}
              invalid={errors["path"] !== undefined || fieldError.path !== ""}
            />
            <FormFeedback>{errors["path"] || fieldError.path}</FormFeedback>
          </Col>
        </FormGroup>
      )}

      {type !== "OTHER" && type !== "AZURE" && type !== "DROPBOX" && (
        <FormGroup row>
          <Label for="region" sm={4}>
            Region
          </Label>
          <Col sm={8}>
            <Input
              name="region"
              type="select"
              value={region}
              onChange={handleInputChange}
              invalid={
                errors["region"] !== undefined || fieldError.region !== ""
              }
            >
              <option value={""}>Select region</option>
              {getRegionListForBucketType(type).map((reg, index) => {
                return <option key={type + " - " + index}>{reg}</option>;
              })}
            </Input>
            <FormFeedback>{errors["region"] || fieldError.region}</FormFeedback>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Label for="storagePrice" sm={4}>
          Storage Type
        </Label>
        <Col sm={8}>
          <Input
            name="storagePrice"
            type="select"
            value={storagePriceId}
            onChange={handleInputChange}
            invalid={
              errors["storagePrice"] !== undefined ||
              fieldError.storagePrice !== ""
            }
          >
            <option value={""}>Select storage type</option>
            {storagePriceList.map((storagePrice) => {
              return (
                <option key={storagePrice.id} value={storagePrice.id}>
                  {storagePrice.description}
                </option>
              );
            })}
          </Input>
          <FormFeedback>
            {errors["storagePrice"] || fieldError.storagePrice}
          </FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
}

export default BucketForm;
