import React, { Component } from "react";

class Loading extends Component {
  render() {
    return (
      <div
        style={{
          width: 80,
          height: 100,
          margin: "auto",
          marginBottom: 40
        }}
      >
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
        <div className="loadingBlock" />
      </div>
    );
  }
}

export default Loading;
