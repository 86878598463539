import { fetchWithAuth } from "../actions/requestHelper";
import { fetchVolumeSettings } from "./volumeSettings";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Actions ===
// CREATE
const REQUEST_CREATE_BUCKET = "REQUEST_CREATE_BUCKET";
const RECEIVE_CREATE_BUCKET = "RECEIVE_CREATE_BUCKET";
// READ
const REQUEST_BUCKET_LIST = "REQUEST_BUCKET_LIST";
const RECEIVE_BUCKET_LIST = "RECEIVE_BUCKET_LIST";
// UPDATE
const REQUEST_UPDATE_BUCKET = "REQUEST_UPDATE_BUCKET";
const RECEIVE_UPDATE_BUCKET = "RECEIVE_UPDATE_BUCKET";
// DELETE
const REQUEST_DELETE_BUCKET = "REQUEST_DELETE_BUCKET";
const RECEIVE_DELETE_BUCKET = "RECEIVE_DELETE_BUCKET";
// ERRORS
const RECEIVE_INVALID_BUCKET = "RECEIVE_INVALID_BUCKET";

// === Reducers ===
export default function buckets(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: true,
  },
  action
) {
  switch (action.type) {
    case REQUEST_BUCKET_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_BUCKET_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.buckets.elements,
        page: action.buckets.page,
        total: action.buckets.total,
        size: action.buckets.size,
        sort: action.buckets.sort,
        direction: action.buckets.direction,
        errors: {},
        formValid: true,
      });
    case RECEIVE_INVALID_BUCKET:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false,
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestBucketList() {
  return {
    type: REQUEST_BUCKET_LIST,
  };
}

function receiveBucketList(buckets) {
  return {
    type: RECEIVE_BUCKET_LIST,
    buckets,
  };
}

function receiveInvalidBucket(errors) {
  return {
    type: RECEIVE_INVALID_BUCKET,
    errors,
  };
}

function requestCreateBucket() {
  return {
    type: REQUEST_CREATE_BUCKET,
  };
}

function receiveCreateBucket(createdBucket) {
  return {
    type: RECEIVE_CREATE_BUCKET,
    bucket: createdBucket,
  };
}

function requestUpdateBucket() {
  return {
    type: REQUEST_UPDATE_BUCKET,
  };
}

function receiveUpdateBucket(updatedBucket) {
  return {
    type: RECEIVE_UPDATE_BUCKET,
    bucket: updatedBucket,
  };
}

function requestDeleteBucket(id) {
  return {
    type: REQUEST_DELETE_BUCKET,
    id,
  };
}

function receiveDeleteBucket(id) {
  return {
    type: RECEIVE_DELETE_BUCKET,
    id,
  };
}

// === Side Effects ===
export function fetchBucketList(
  page = 1,
  sort = "description",
  direction = "asc",
  size = window.localStorage.getItem("numberOfElementsDataSourceBuckets")
    ? Number(window.localStorage.getItem("numberOfElementsDataSourceBuckets"))
    : 10
) {
  window.localStorage.setItem("numberOfElementsDataSourceBuckets", size);
  return async (dispatch, getState) => {
    dispatch(requestBucketList());
    const url = `/v1/buckets?page=${page}&size=${size}&sort=${sort},${direction}`;
    try {
      const json = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      dispatch(receiveBucketList(json));
    } catch (err) {
      const message = err.statusText || `Unable to get the list of buckets`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function createBucket(bucket) {
  return async (dispatch, getState) => {
    dispatch(requestCreateBucket());
    try {
      const newBucket = await fetchWithAuth(
        dispatch,
        "/v1/buckets",
        getState().auth.accessToken,
        "POST",
        JSON.stringify(bucket)
      );
      dispatch(receiveCreateBucket(newBucket));
      dispatch(
        fetchBucketList(
          1,
          getState().buckets.sort,
          getState().buckets.direction,
          getState().buckets.size
        )
      );
      dispatch(fetchVolumeSettings());
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidBucket(err));
      else {
        const message = err.message || "Unable to create bucket";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function updateBucket(id, bucket) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateBucket());
    try {
      const updatedBucket = await fetchWithAuth(
        dispatch,
        `/v1/buckets/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(bucket)
      );
      dispatch(receiveUpdateBucket(updatedBucket));
      dispatch(
        fetchBucketList(
          1,
          getState().buckets.sort,
          getState().buckets.direction,
          getState().buckets.size
        )
      );
      dispatch(fetchVolumeSettings());
    } catch (err) {
      if (err.message.includes("Bucket updated")) {
        dispatch(
          fetchBucketList(
            1,
            getState().buckets.sort,
            getState().buckets.direction,
            getState().buckets.size
          )
        );
        dispatch(fetchVolumeSettings());
        dispatch(setAlert(err.message, "warning"));
      } else if (err.subErrors) {
        dispatch(receiveInvalidBucket(err));
      } else {
        const message = err.message || "Unable to update bucket";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}

export function deleteBucket(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteBucket(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/buckets/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(
        fetchBucketList(
          1,
          getState().buckets.sort,
          getState().buckets.direction,
          getState().buckets.size
        )
      );
      dispatch(receiveDeleteBucket(id));
      dispatch(fetchVolumeSettings());
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}
