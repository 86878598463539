import FormatSize from "../FormatSize";

export default function FileTypeValidation({
  validTotalFileSize,
  totalFileSize,
  fileType,
  numberOfValidFileOrFolders,
  totalNumberOfFilesOrFolders,
  fileOrFolder = "file",
}) {
  const numberOfInvalidElements =
    totalNumberOfFilesOrFolders - numberOfValidFileOrFolders;
  const parseFileTypeValidity = () => {
    if (numberOfValidFileOrFolders === 0) {
      return (
        <li style={{ color: "#eda003" }}>
          There are no {fileType} {fileOrFolder}s in your selection.
        </li>
      );
    } else if (totalFileSize - validTotalFileSize !== 0) {
      return (
        <li style={{ color: "#eda003" }}>
          {numberOfInvalidElements} {fileOrFolder}
          {numberOfInvalidElements > 1 ? "s" : ""} totaling{" "}
          <FormatSize>{totalFileSize - validTotalFileSize}</FormatSize> will be ignored in the
          operation because {numberOfInvalidElements > 1 ? "they are" : "it is"}{" "}
          not part of a{fileType.charAt(0).match(/[aeiouAEIOU]/i) ? "n" : ""}{" "}
          {fileType} storage.
        </li>
      );
    }
  };

  return parseFileTypeValidity();
}
