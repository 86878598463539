import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Input, Row } from "reactstrap";

export default function DatePickerForPreviousDate(props) {
  const { selectedDate, setSelectedDate } = props;

  const [selectValue, setSelectValue] = useState("");
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  const dates = useSelector(({ dates }) => dates.items);
  const activeDate = useSelector(({ dates }) => dates.activeDate);

  useEffect(() => {
    setAvailableDates(dates.filter(date => activeDate >= date));
  }, [dates, activeDate]);

  useEffect(() => {
    if (availableDates.length > 0) {
      const value = getFirstValueOfList(activeDate);

      setSelectValue(value);
      setSelectedDateFromSelectValue(value);
    }
  }, [availableDates]);

  const isDateWithinAvailableDates = (date) => {
    const indexOfDate = availableDates.indexOf(date.toISOString().split('T')[0]);
    return indexOfDate !== -1;
  };

  const getFirstValueOfList = (currentDate) => {
    const firstDateValue = new Date(currentDate);

    firstDateValue.setDate(firstDateValue.getDate() - 7);
    if (isDateWithinAvailableDates(firstDateValue)) {
      return "7";
    }

    firstDateValue.setDate(firstDateValue.getDate() - 1);
    if (isDateWithinAvailableDates(firstDateValue)) {
      return "8";
    }

    firstDateValue.setDate(firstDateValue.getDate() + 2);
    if (isDateWithinAvailableDates(firstDateValue)) {
      return "6";
    }

    for (var i = 5; i >= 0; i--) {
      firstDateValue.setDate(firstDateValue.getDate() + 1);
      if (isDateWithinAvailableDates(firstDateValue)) {
        return `${i}`;
      }
    }
  };

  const getDayValues = () => {
    const values = [];
    const currentDate = new Date(activeDate);

    values.push(getFirstValueOfList(currentDate));

    const fourteenDaysAgoDate = new Date(currentDate);
    fourteenDaysAgoDate.setDate(fourteenDaysAgoDate.getDate() - 14);
    if (isDateWithinAvailableDates(fourteenDaysAgoDate)) {
      values.push("14");
    }

    const twentyOneDaysAgoDate = new Date(currentDate);
    twentyOneDaysAgoDate.setDate(twentyOneDaysAgoDate.getDate() - 21);
    if (isDateWithinAvailableDates(twentyOneDaysAgoDate)) {
      values.push("21");
    }

    const thirtyDaysAgoDate = new Date(currentDate);
    thirtyDaysAgoDate.setDate(thirtyDaysAgoDate.getDate() - 30);
    if (isDateWithinAvailableDates(thirtyDaysAgoDate)) {
      values.push("30");
    }

    return values.map(value => <option id={`${value}-days`} key={`${value}-days`} value={`${value}`}>{value} days ago</option>);
  };

  const setSelectedDateFromSelectValue = (value) => {
    const dateFromValue = new Date(activeDate);
    dateFromValue.setDate(dateFromValue.getDate() - Number(value));

    setSelectedDate(dateFromValue.toISOString().split('T')[0]);
  };

  return (
    <Row className="row-cols-lg-auto g-3 align-items-center">
      <FormGroup>
        <Input
          name="Previous date"
          type="select"
          value={selectValue}
          onChange={(e) => {
            const value = e.target.value;

            if (value === "custom") {
              setShowCustomDate(true);
            } else {
              setShowCustomDate(false);
              setSelectedDateFromSelectValue(value);
            }

            setSelectValue(value);
          }}
          bsSize="sm"
        >
          {getDayValues()}
          <option value="custom">Custom</option>
        </Input>
      </FormGroup>
      {showCustomDate &&
        <FormGroup>
          <Input
            name="Previous date"
            type="select"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            bsSize="sm"
          >
            {availableDates.map((date, index) => {
              return (
                <option key={index} value={date}>
                  {date}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      }
    </Row>
  );
}