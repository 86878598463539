import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";

// === Actions ===
// READ
export const REQUEST_VOLUMES = "REQUEST_VOLUMES";
export const RECEIVE_VOLUMES = "RECEIVE_VOLUMES";
export const REQUEST_FOLDERS = "REQUEST_FOLDERS";
export const RECEIVE_FOLDERS = "RECEIVE_FOLDERS";
export const REQUEST_FILES = "REQUEST_FILES";
export const RECEIVE_FILES = "RECEIVE_FILES";

// UPDATE
export const RECEIVE_UPDATE_FILE_MANAGEMENT_FILE = "RECEIVE_UPDATE_FILE_MANAGEMENT_FILE";
export const REQUEST_UPDATE_FILE_MANAGEMENT_FILE = "REQUEST_UPDATE_FILE_MANAGEMENT_FILE";
export const RECEIVE_UPDATE_FILE_MANAGEMENT_FOLDER = "RECEIVE_UPDATE_FILE_MANAGEMENT_FOLDER";
export const REQUEST_UPDATE_FILE_MANAGEMENT_FOLDER = "REQUEST_UPDATE_FILE_MANAGEMENT_FOLDER";
export const UPDATE_FOLDER_ITEMS_DIRECTLY = "UPDATE_FOLDER_ITEMS_DIRECTLY";
export const UPDATE_FILE_ITEMS_DIRECTLY = "UPDATE_FILE_ITEMS_DIRECTLY";

// SELECT
export const SELECT_PATH = "SELECT_PATH";
export const INVALIDATE_PATH = "INVALIDATE_PATH";

// === Reducers ===
const getUpdatedList = (list, file) => {
  return list.map(f => f.fileId === file.fileId ? file : f);
};

const initialState = {
  activePath: "/",
  files: {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    direction: "desc",
    sort: "size"
  },
  folders: {
    items: [],
    page: 1,
    total: 0,
    size: 0
  }
};

function files(
  state = {
    isFetching: true,
    didInvalidate: false,
    items: [],
    page: 1,
    sort: "size",
    direction: "desc"
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_PATH:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_FILES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        items: []
      });
    case RECEIVE_FILES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.files.elements,
        lastUpdated: action.receivedAt,
        page: action.files.page,
        total: action.files.total,
        size: action.files.size,
        sort: action.sort,
        direction: action.direction
      });
    case REQUEST_UPDATE_FILE_MANAGEMENT_FILE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_UPDATE_FILE_MANAGEMENT_FILE:
      return Object.assign({}, state, {
        isFetching: false,
        items: getUpdatedList(state.items, action.file)
      });
    default:
      return state;
  }
}

function folders(
  state = {
    isFetching: true,
    didInvalidate: false,
    items: [],
    page: 1,
    sort: "size",
    direction: "desc"
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_PATH:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_FOLDERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        items: []
      });
    case RECEIVE_FOLDERS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.folders.elements,
        lastUpdated: action.receivedAt,
        page: action.folders.page,
        total: action.folders.total,
        size: action.folders.size,
        sort: action.sort,
        direction: action.direction
      });
    case REQUEST_UPDATE_FILE_MANAGEMENT_FOLDER:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_UPDATE_FILE_MANAGEMENT_FOLDER:
      return Object.assign({}, state, {
        isFetching: false,
        items: getUpdatedList(state.items, action.folder)
      });
    default:
      return state;
  }
}

export default function fileManagement(state = initialState, action) {
  switch (action.type) {
    case SELECT_PATH:
      return Object.assign({}, state, {
        activePath: action.path
      });
    case INVALIDATE_PATH:
    case RECEIVE_FILES:
    case REQUEST_FILES:
    case RECEIVE_UPDATE_FILE_MANAGEMENT_FILE:
    case REQUEST_UPDATE_FILE_MANAGEMENT_FILE:
      return Object.assign({}, state, {
        files: files(state.files, action)
      });
    case RECEIVE_FOLDERS:
    case REQUEST_FOLDERS:
    case RECEIVE_UPDATE_FILE_MANAGEMENT_FOLDER:
    case REQUEST_UPDATE_FILE_MANAGEMENT_FOLDER:
      return Object.assign({}, state, {
        folders: folders(state.folders, action)
      });
    case UPDATE_FOLDER_ITEMS_DIRECTLY:
      return Object.assign({}, state, {
        folders: {
          ...state.folders,
          items: action.items
        }
      });
    case UPDATE_FILE_ITEMS_DIRECTLY:
      return Object.assign({}, state, {
        files: {
          ...state.files,
          items: action.items
        }
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestFolders() {
  return {
    type: REQUEST_FOLDERS
  };
}

function receiveFolders(json, sort, direction) {
  return {
    type: RECEIVE_FOLDERS,
    folders: json,
    sort,
    direction,
    receivedAt: Date.now(),
  };
}

function requestFiles() {
  return {
    type: REQUEST_FILES
  };
}

function receiveFiles(json, sort, direction) {
  return {
    type: RECEIVE_FILES,
    files: json,
    sort,
    direction,
    receivedAt: Date.now(),
  };
}

export function selectPath(path) {
  return {
    type: SELECT_PATH,
    path,
  };
}

export function invalidatePath(path) {
  return {
    type: INVALIDATE_PATH,
    path,
  };
}

function requestUpdateFileManagementFile() {
  return {
    type: REQUEST_UPDATE_FILE_MANAGEMENT_FILE
  };
}

function receiveUpdateFileManagementFile(json) {
  return {
    type: RECEIVE_UPDATE_FILE_MANAGEMENT_FILE,
    file: json
  };
}

function requestUpdateFileManagementFolder() {
  return {
    type: REQUEST_UPDATE_FILE_MANAGEMENT_FOLDER
  };
}

function receiveUpdateFileManagementFolder(json) {
  return {
    type: RECEIVE_UPDATE_FILE_MANAGEMENT_FOLDER,
    folder: json
  };
}

export function updateFileManagementFileItems(items = []) {
  return {
    type: UPDATE_FILE_ITEMS_DIRECTLY,
    items
  }
}

export function updateFileManagementFolderItems(items = []) {
  return {
    type: UPDATE_FOLDER_ITEMS_DIRECTLY,
    items
  }
}

// === Side Effects ===
export function fetchFolders(
  path,
  volumeId,
  page = 1,
  sort = "size",
  direction = "desc",
  showHiddenDirectories = true,
  size = 10
) {
  return (dispatch, getState) => {
    dispatch(requestFolders());
    
    const url = "/directories";

    const queryParams = {
      path,
      page,
      sort: `${sort},${direction}`,
      size,
      indexingDate: getState().dates.activeDate,
      showHiddenDirectories 
    };

    if (volumeId) {
      queryParams.volumeId = volumeId
    }

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, queryParams)
      .then((json) => {
        dispatch(receiveFolders(json, sort, direction));
      })
      .catch((e) => {
        dispatch(setAlert(e, "danger"));
      });
  };
}

export function fetchFiles(
  path,
  volumeId,
  page = 1,
  filters = { onlyDuplicate: false, last3Months: false },
  sort = "size",
  direction = "desc",
  size = 10
) {
  return (dispatch, getState) => {
    if (getState().filters.onlyDuplicate) {
      filters.onlyDuplicate = getState().filters.onlyDuplicate;
    }

    const url = "/files/directory";

    const queryParams = {
      directoryPath: path,
      sort: `${sort},${direction}`,
      size,
      page,
      onlyDuplicate: filters.onlyDuplicate,
      onlyArchived: false,
      indexingDate: getState().dates.activeDate
    };

    if (volumeId) {
      queryParams.volumeId = volumeId
    }

    dispatch(requestFiles());

    return fetchWithAuthV2(dispatch, url, getState().auth.accessToken, queryParams).then(
      (json) => {
        dispatch(receiveFiles(json, sort, direction));
      })
      .catch(e => {
        dispatch(setAlert(e, "danger"));
      });
  };
}

export function updateFileManagementFile(id, file) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateFileManagementFile());
    const url = `/files/${id}?indexingDate=${getState().dates.activeDate}`;
    try {
      await fetchWithAuth(dispatch, url, getState().auth.accessToken, "PUT", JSON.stringify(file));
      dispatch(receiveUpdateFileManagementFile(file));
    } catch (err) {
      let message = "Unable to update file";

      if (err.message) {
        if (err.message.includes("index write")) {
          message = "Unable to update a file from a previous snapshot as it is read only";
        } else {
          message = err.message;
        }
      }

      dispatch(setAlert(message, "danger"));
    }
  };
}

export function updateFileManagementFolder(id, folder) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateFileManagementFolder());
    const url = `/files/${id}?indexingDate=${getState().dates.activeDate}`;
    try {
      await fetchWithAuth(dispatch, url, getState().auth.accessToken, "PUT", JSON.stringify(folder));
      dispatch(receiveUpdateFileManagementFolder(folder));
    } catch (err) {
      let message = "Unable to update folder";

      if (err.message) {
        if (err.message.includes("index write")) {
          message = "Unable to update a folder from a previous snapshot as it is read only";
        } else {
          message = err.message;
        }
      }
      dispatch(setAlert(message, "danger"));
    }
  };
}
