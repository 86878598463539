import React, { useState } from "react";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import {
  handleVolumeAndPaths,
  handleVolumeNames,
} from "../../utils/handleVolumeAndPath";

function ActiveFilters(props) {
  const { filters, volumeList, clearFilters, removeFilter } = props;
  const [isVolumeAndPathOpen, setIsVolumeAndPathOpen] = useState(false);

  const styles = { marginLeft: 5, marginBottom: 5, fontSize: "100%" };

  const getMultiLabelComponents = (title, badgeColor, filterName, value) => {
    return value.map((element, index) => {
      return (
        <React.Fragment key={index}>
          {element && (
            <Badge style={styles} color={badgeColor}>
              {title} {element}
              <Badge
                className="text-dark"
                onClick={() => removeFilter(filterName, index)}
                color="light"
                style={{ cursor: "pointer", marginLeft: 10 }}
              >
                X
              </Badge>
            </Badge>
          )}
        </React.Fragment>
      );
    });
  };

  const getLabelGroupComponent = (filterName, value) => {
    return (
      <>
        <Badge style={styles} color="primary">
          Folders
          <Badge
            className="text-dark"
            onClick={() => setIsVolumeAndPathOpen(!isVolumeAndPathOpen)}
            color="light"
            style={{ cursor: "pointer", marginLeft: 10 }}
          >
            {isVolumeAndPathOpen ? "_" : value.filter(Boolean).length}
          </Badge>
          <Badge
            className="text-dark"
            onClick={() => removeFilter(filterName)}
            color="light"
            style={{ cursor: "pointer", marginLeft: 3 }}
          >
            X
          </Badge>
        </Badge>
      </>
    );
  };

  const getLabelComponent = (filterName, value) => {
    return (
      <Badge style={styles} color="primary">
        {value}
        <Badge
          onClick={() => removeFilter(filterName)}
          color="light"
          className="text-dark"
          style={{ cursor: "pointer", marginLeft: 10 }}
        >
          X
        </Badge>
      </Badge>
    );
  };

  const showClearAll = () => {
    if (
      filters.startingPath ||
      filters.volumeId ||
      filters.extension ||
      filters.group ||
      filters.owner ||
      filters.lastAccessAfter ||
      filters.lastAccessBefore ||
      filters.creationAfter ||
      filters.creationBefore ||
      filters.lastModifiedAfter ||
      filters.lastModifiedBefore ||
      filters.onlyDuplicates ||
      filters.minimumSize ||
      filters.maximumSize ||
      filters.volumeIds ||
      filters.showOnlyDeletedFiles ||
      (filters.volumesAndPaths && filters.volumesAndPaths[0]) ||
      (filters.ignorePatterns && filters.ignorePatterns[0]) ||
      (filters.tags && filters.tags.length) ||
      (filters.reports && filters.reports.length) ||
      filters.name ||
      filters.minimumNumberOfFiles ||
      filters.maximumNumberOfFiles ||
      filters.minimumNumberOfFolders ||
      filters.maximumNumberOfFolders 
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="form-inline" style={{ marginBottom: 10, marginTop: 10 }}>
      {filters.startingPath &&
        getLabelComponent(
          "startingPath",
          `Starting with path: ${filters.startingPath}`
        )}

      {filters.extension &&
        getLabelComponent(
          "extension",
          `File with extension: ${filters.extension}`
        )}

      {filters.group &&
        getLabelComponent("group", `File in group: ${filters.group}`)}

      {filters.owner &&
        getLabelComponent("owner", `Owner of the file is: ${filters.owner}`)}

      {filters.lastAccessAfter &&
        getLabelComponent(
          "lastAccessAfter",
          `Last access after: ${filters.lastAccessAfter}`
        )}

      {filters.lastAccessBefore &&
        getLabelComponent(
          "lastAccessBefore",
          `Last access before: ${filters.lastAccessBefore}`
        )}

      {filters.creationAfter &&
        getLabelComponent(
          "creationAfter",
          `Creation date after: ${filters.creationAfter}`
        )}

      {filters.creationBefore &&
        getLabelComponent(
          "creationBefore",
          `Creation date before: ${filters.creationBefore}`
        )}

      {filters.lastModifiedAfter &&
        getLabelComponent(
          "lastModifiedAfter",
          `Last modified after: ${filters.lastModifiedAfter}`
        )}

      {filters.lastModifiedBefore &&
        getLabelComponent(
          "lastModifiedBefore",
          `Last modified before: ${filters.lastModifiedBefore}`
        )}

      {filters.onlyDuplicates !== undefined &&
        filters.onlyDuplicates !== "" &&
        getLabelComponent(
          "onlyDuplicates",
          filters.onlyDuplicates === "true"
            ? "Show only duplicated files"
            : "Show only unique files"
        )}

      {filters.minimumSize &&
        getLabelComponent(
          "minimumSize",
          `Minimum size: ${filters.minimumSize} ${filters.minimumSizeUnit}`
        )}

      {filters.maximumSize &&
        getLabelComponent(
          "maximumSize",
          `Maximum size: ${filters.maximumSize} ${filters.maximumSizeUnit}`
        )}

      {filters.volumeIds !== undefined && filters.volumeIds.length
        ? getLabelComponent(
            "volumeIds",
            `On volume : ${handleVolumeNames(filters.volumeIds, volumeList)}`
          )
        : null}

      {filters.showOnlyDeletedFiles !== undefined &&
        filters.showOnlyDeletedFiles !== "" &&
        getLabelComponent(
          "showOnlyDeletedFiles",
          filters.showOnlyDeletedFiles === "true"
            ? "Show only deleted, moved and renamed files"
            : "Show all files"
        )}

      {filters.volumesAndPaths &&
        filters.volumesAndPaths.some((e) => e.length > 0) &&
        getLabelGroupComponent(
          "volumesAndPaths",
          handleVolumeAndPaths(filters.volumesAndPaths, volumeList)
        )}

      {isVolumeAndPathOpen &&
        getMultiLabelComponents(
          <FontAwesomeIcon icon={faServer} />,
          "secondary",
          "volumesAndPaths",
          handleVolumeAndPaths(filters.volumesAndPaths, volumeList)
        )}

      {filters.ignorePatterns &&
        getMultiLabelComponents(
          "Ignored pattern :",
          "primary",
          "ignorePatterns",
          filters.ignorePatterns
        )}

      {filters.tags &&
        getMultiLabelComponents("Tag :", "primary", "tags", filters.tags)
      }

      {filters.reports &&
        getMultiLabelComponents("Report :", "primary", "reports", filters.reports)
      }
      
      {filters.name &&
        getLabelComponent(
          "name",
          `Name: ${filters.name}`
        )
      }

      {filters.minimumNumberOfFiles &&
        getLabelComponent(
          "minimumNumberOfFiles",
          `Minimum number of files: ${filters.minimumNumberOfFiles}`
        )
      }

      {filters.maximumNumberOfFiles &&
        getLabelComponent(
          "maximumNumberOfFiles",
          `Maximum number of files: ${filters.maximumNumberOfFiles}`
        )
      }

      {filters.minimumNumberOfFolders &&
        getLabelComponent(
          "minimumNumberOfFolders",
          `Minimum number of folders: ${filters.minimumNumberOfFolders}`
        )
      }

      {filters.maximumNumberOfFolders &&
        getLabelComponent(
          "maximumNumberOfFolders",
          `Maximum number of folders: ${filters.maximumNumberOfFolders}`
        )
      }
      
      {showClearAll() && (
        <Badge
          style={{
            ...styles,
            cursor: "pointer",
            outline: " 1px solid red",
            color: "red",
            fontWeight: "normal",
          }}
          onClick={() => clearFilters()}
          color="light"
        >
          Clear all
        </Badge>
      )}
    </div>
  );
}

export default ActiveFilters;
