import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  fetchFiles,
  updateFileManagementFile,
  updateFileManagementFileItems,
  updateFileManagementFolder,
  updateFileManagementFolderItems,
} from "../ducks/fileManagement";
import { fetchVolumes } from "../actions/index";
import { fetchTags, updateTags } from "../ducks/tag";
import FolderTree from "./FolderTree";
import FileList from "../components/FileList";
import FileDetailsModal from "../components/FileDetailsModal";
import { Button, UncontrolledTooltip, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChartLine,
  faFilter,
  faFolderTree,
  faTableList,
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Tabs from "../components/Tabs";
import Breadcrumbs from "../components/Breadcrumbs";
import DisplayToggle from "../components/DisplayToggle";
import FolderTreeNew from "../components/folderTree/FolderTree";
import FileDetails from "../components/FileDetails";
import Volumes from "../volumes/Volumes";
import FolderDetails from "../components/FolderDetails";
import { useLocation, useNavigate } from "react-router-dom";

const FILTER_ICON_STYLE = {
  position: "absolute",
  marginLeft: "0.75rem",
  height: "2.375rem",
  pointerEvents: "none",
  color: "#495057",
  opacity: "0.5",
  width: "1em",
};

function FileManagement(props) {
  const {
    files,
    filters,
    dispatch,
    volumeList,
    volumeSettings,
  } = props;

  const location = useLocation();

  const getPath = () => {
    const queryParams = queryString.parse(location.search);
    return !queryParams.path ? "/" : queryParams.path;
  };

  const getVolumeId = () => {
    const queryParams = queryString.parse(location.search);
    return queryParams.volumeId ?? "";
  };

  const getFileName = () => {
    const queryParams = queryString.parse(location.search);
    return queryParams.fileName;
  };

  const [activeTab, setActiveTab] = useState("0");

  const [showFileDetailsModal, setShowFileDetailsModal] = useState(false);
  const [activeFile, setActiveFile] = useState({});
  const [currentVolumeId, setCurrentVolumeId] = useState(getVolumeId());
  const [currentPath, setCurrentPath] = useState(getPath());

  const [direction, setDirection] = useState(files.direction);
  const [sort, setSort] = useState(files.sort);
  const [page, setPage] = useState(files.page);
  const [filesSize, setFilesSize] = useState(25);
  const [filterVolumesInput, setFilterVolumesInput] = useState("");
  const [filterFilesFoldersInput, setFilterFilesFoldersInput] = useState("");
  const [filteredFiles, setFilteredFiles] = useState(files.items);
  const [activeDisplayButton, setActiveDisplayButton] = useState(0);
  const [shownFileDetails, setShownFileDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [toggleDetailsHovered, setToggleDetailsHovered] = useState(false);
  const [displayValue, setDisplayValue] = useState("block");
  const [opacityValue, setOpacityValue] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const activeDisplayButtonValue = window.localStorage.getItem(
      "activeDisplayButtonFileManagement"
    );
    const showDetailsValue = window.localStorage.getItem("showDetails");
    const numberOfElementsFiles = window.localStorage.getItem("numberOfElementsFileManagementFiles")

    if (activeDisplayButtonValue) {
      setActiveDisplayButton(Number(activeDisplayButtonValue));
    }
    if (showDetailsValue) {
      const isShowDetails = showDetailsValue === "true";

      setShowDetails(isShowDetails);
      setDisplayValue(isShowDetails ? "block" : "hidden");
      setOpacityValue(isShowDetails ? 1 : 0);
    }
    if (numberOfElementsFiles) {
      setFilesSize(Number(numberOfElementsFiles));
    }

    const fileSortValue = window.localStorage.getItem("fileSort");
    const fileDirectionValue = window.localStorage.getItem("fileDirection");

    if (fileSortValue) {
      setSort(fileSortValue);
    }

    if (fileDirectionValue) {
      setDirection(fileDirectionValue);
    }
  }, []);

  useEffect(() => {
    setPage(1);
    setCurrentVolumeId(getVolumeId());
    setCurrentPath(getPath());
  }, [location.search]);

  useEffect(() => {
    const fileName = getFileName();

    if (fileName && files.items && files.items.length > 0) {
      setShownFileDetails(files.items.find(file => file.name === fileName));
    }
  }, [location, files, getFileName]);

  useEffect(() => {
    dispatch(fetchVolumes());
    dispatch(fetchTags());
  }, [dispatch]);

  useEffect(() => {
    if (currentVolumeId !== "") {
      dispatch(
        fetchFiles(
          currentPath,
          currentVolumeId,
          page,
          filters,
          sort,
          direction,
          filesSize
        )
      );
    } else {
      dispatch(updateFileManagementFileItems());
      dispatch(updateFileManagementFolderItems());
    }
  }, [
    dispatch,
    direction,
    page,
    sort,
    filters,
    location.search,
    currentPath,
    currentVolumeId,
  ]);

  useEffect(() => {
    setFilterFilesFoldersInput("");
  }, [currentPath]);

  useEffect(() => {
    if (undefined !== filterFilesFoldersInput) {
      const filteredFilesItems =
        files.items &&
        files.items.filter((file) =>
          file.name
            .toLowerCase()
            .includes(filterFilesFoldersInput.toLowerCase())
        );
      setFilteredFiles(filteredFilesItems);
    }
  }, [filterFilesFoldersInput, files.items]);

  const changePath = (path) => {
    if (document.getElementsByClassName("popover").length === 0) {
      setCurrentPath(path);
      navigate(`/filemanagement?path=${path}&volumeId=${getVolumeId()}`);
    }
  };

  const listVolumes = () => {
    setCurrentVolumeId("");
    setCurrentPath("/");
    navigate(`/filemanagement`);
  };

  const selectVolume = (volumeId) => {
    setCurrentVolumeId(volumeId);
    navigate(`/filemanagement?path=/&volumeId=${volumeId}`);
  };

  const changeFilesOrder = (newSort) => {
    if (newSort === sort) {
      const newDirection = direction === "asc" ? "desc" : "asc";
      setDirection(newDirection);
      window.localStorage.setItem("fileDirection", newDirection);
    }
    setSort(newSort);
    window.localStorage.setItem("fileSort", newSort);
  };

  const getFolderAnalytics = () => {
    navigate(`/folders?volumeId=${getVolumeId()}&path=${getPath()}`);
  };

  const getVolumeAnalytics = (volumeId) => {
    navigate(`/volumes/${volumeId}`);
  };

  const moveToSearch = (path, volumeId) => {
    navigate(`/search?startingPath=${path}&volumeId=${volumeId}`);
  };

  const setActiveDisplayButtonValue = (value) => {
    setActiveDisplayButton(value);
    window.localStorage.setItem("activeDisplayButtonFileManagement", value);
  };

  const setShowDetailsValue = (value) => {
    setShowDetails(value);
    window.localStorage.setItem("showDetails", value);
  };

  const getFileDetails = () => {
    if (shownFileDetails.isDirectory) {
      return (
        <FolderDetails
          folder={shownFileDetails}
          updateFolder={(id, file) =>
            dispatch(updateFileManagementFolder(id, file))
          }
          updateTags={(tags) => dispatch(updateTags(tags))}
          volumeType={
            volumeList.find((volume) => volume.id === shownFileDetails.volumeId)
              ?.type
          }
          isInModal={false}
        />
      );
    }

    return (
      <FileDetails
        file={shownFileDetails}
        updateFile={(id, file) => dispatch(updateFileManagementFile(id, file))}
        updateTags={(tags) => dispatch(updateTags(tags))}
        volumeType={
          volumeList.find((volume) => volume.id === shownFileDetails.volumeId)
            ?.type
        }
        isInModal={false}
      />
    );
  };

  const adjustDisplayValues = (isShown) => {
    const element = document.getElementById("fileDetails");

    if (element === null) {
      setDisplayValue("none");
    } else if (isShown) {
      setTimeout(() => {
        setDisplayValue("block");
      }, 100);
      setTimeout(() => {
        setOpacityValue(1);
      }, 110);
    } else {
      setOpacityValue(0);
      setTimeout(() => {
        setDisplayValue("none");
      }, 500);
    }
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsFileManagementFile", numberOfElements);
    setFilesSize(numberOfElements);
    setPage(1);

    dispatch(
      fetchFiles(
        currentPath,
        currentVolumeId,
        1,
        filters,
        sort,
        direction,
        numberOfElements
      )
    );
  };

  return (
    <>
      <div className="d-flex">
        <h1 className="page-header">File Management</h1>
        <div className="ms-auto">
          {currentVolumeId !== "" ? (
            <>
              <Button
                id="search"
                color="info"
                className="float-end me-1"
                onClick={() => moveToSearch(getPath(), getVolumeId())}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <Button
                id="analytics"
                color="primary"
                className="float-end me-1"
                onClick={getFolderAnalytics}
              >
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
              <div className="float-end me-3">
                <FontAwesomeIcon icon={faFilter} style={FILTER_ICON_STYLE} />
                <Input
                  placeholder="Filter files and folders"
                  value={filterFilesFoldersInput}
                  onChange={(e) => setFilterFilesFoldersInput(e.target.value)}
                  style={{ paddingLeft: "2rem" }}
                  tabIndex="1"
                />
              </div>
              <div className="float-end me-3">
                <DisplayToggle
                  activeButton={activeDisplayButton}
                  setActiveButton={(value) =>
                    setActiveDisplayButtonValue(value)
                  }
                  icons={[faFolderTree, faTableList]}
                />
              </div>
              <UncontrolledTooltip placement="auto" target="analytics">
                See analytics of current folder
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="auto" target="search">
                Show content as Search result
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faFilter} style={FILTER_ICON_STYLE} />
              <Input
                placeholder="Filter Storage"
                value={filterVolumesInput}
                onChange={(e) => setFilterVolumesInput(e.target.value)}
                style={{ paddingLeft: "2rem" }}
                tabIndex="1"
              />
            </>
          )}
        </div>
      </div>

      {currentVolumeId === "" ? (
        <FolderTree
          folderFilter={filterFilesFoldersInput}
          volumeId={currentVolumeId}
          getVolumeAnalytics={getVolumeAnalytics}
          path={currentPath}
          selectVolume={selectVolume}
          changePath={changePath}
          listVolumes={listVolumes}
          moveToSearch={moveToSearch}
          updateFolder={(id, folder) =>
            dispatch(updateFileManagementFolder(id, folder))
          }
          updateTags={(tags) => dispatch(updateTags(tags))}
          volumeFilter={filterVolumesInput}
        />
      ) : (
        <>
          <Breadcrumbs
            handleClick={changePath}
            volume={volumeList.find(
              (volume) => volume.id === parseInt(currentVolumeId)
            )}
            listVolumes={listVolumes}
            path={currentPath}
          />
          {activeDisplayButton === 0 ? (
            <div className="d-flex">
              <div
                style={{
                  width: showDetails ? "42%" : "96%",
                  transition: "0.5s ease-in-out",
                }}
              >
                <FolderTreeNew
                  path={currentPath}
                  volumes={volumeList.some(volume => volume.id === parseInt(currentVolumeId)) ? [volumeList.find(
                    (volume) => volume.id === parseInt(currentVolumeId)
                  )] : []}
                  filterString={filterFilesFoldersInput}
                  onNameDoubleClickFile={(file) => {
                    setShowDetailsValue(true);
                    adjustDisplayValues(true);
                    setShownFileDetails(file);
                  }}
                  onNameDoubleClickFolder={(file) => changePath(file.path)}
                  setShownFileDetails={(file) => {
                    setShowDetailsValue(true);
                    adjustDisplayValues(true);
                    setShownFileDetails(file);
                  }}
                  onMountAction={(file) => {
                    if (!!!getFileName()) {
                      setShownFileDetails(null);
                      if (currentPath !== "/") {
                        setShownFileDetails(file);
                      }
                    }
                  }}
                />
              </div>
              <div
                className="d-flex flex-column"
                style={{ width: "4%", cursor: "pointer" }}
                onClick={() => {
                  const value = !showDetails;
                  setShowDetailsValue(value);
                  adjustDisplayValues(value);
                }}
                onMouseEnter={() => setToggleDetailsHovered(true)}
                onMouseLeave={() => setToggleDetailsHovered(false)}
              >
                <div
                  className="align-self-center mb-2"
                  style={{
                    border: "1px solid #6c757d",
                    borderRadius: "25px",
                    backgroundColor: toggleDetailsHovered && "#6c757d",
                    padding: "2.5px 5px",
                    transition: "0.15s ease-in-out",
                  }}
                >
                  <FontAwesomeIcon
                    icon={showDetails ? faAngleDoubleRight : faAngleDoubleLeft}
                    color={toggleDetailsHovered ? "white" : "#6c757d"}
                    style={{ transition: "0.15s ease-in-out" }}
                  />
                </div>
                <div
                  style={{
                    borderRight: "1px solid rgba(0,0,0,.125)",
                    height: "100%",
                    width: "50%",
                  }}
                />
              </div>
              <div
                id="fileDetails"
                style={{
                  width: showDetails ? "54%" : "0%",
                  opacity: opacityValue,
                  transition: "0.5s ease-in-out",
                  display: displayValue,
                }}
              >
                {shownFileDetails ? (
                  getFileDetails()
                ) : (
                  <Volumes
                    match={{ id: currentVolumeId }}
                    cardPosition="fileManagement"
                    titleSize={3}
                  />
                )}
              </div>
            </div>
          ) : (
            <Tabs
              toggleTab={(tab) => setActiveTab(tab)}
              activeTab={activeTab}
              titles={["Folders", "Files"]}
            >
              <FolderTree
                folderFilter={filterFilesFoldersInput}
                volumeId={currentVolumeId}
                getVolumeAnalytics={getVolumeAnalytics}
                path={currentPath}
                selectVolume={selectVolume}
                changePath={changePath}
                listVolumes={listVolumes}
                moveToSearch={moveToSearch}
                updateFolder={(id, folder) =>
                  dispatch(updateFileManagementFolder(id, folder))
                }
                updateTags={(tags) => dispatch(updateTags(tags))}
                volumeFilter={filterVolumesInput}
                tabPosition="0"
              />
              <FileList
                files={filteredFiles}
                page={files.page}
                total={files.total}
                size={filesSize}
                setNumberOfElements={numberOfElements => handleChangeNumberOfElements(numberOfElements)}
                sort={files.sort}
                direction={files.direction}
                selectPage={(page) => setPage(page)}
                handleClick={(file) => {
                  setShowFileDetailsModal(true);
                  setActiveFile(file);
                }}
                changeOrder={changeFilesOrder}
                volumeSettings={volumeSettings}
                updateFile={(id, file) =>
                  dispatch(updateFileManagementFile(id, file))
                }
                updateTags={(tags) => dispatch(updateTags(tags))}
                isLoading={files.isFetching}
                tabPosition="1"
              />
            </Tabs>
          )}
        </>
      )}

      <FileDetailsModal
        file={activeFile}
        updateFile={(id, file) => dispatch(updateFileManagementFile(id, file))}
        updateTags={(tags) => dispatch(updateTags(tags))}
        volumeType={
          volumeList.find((volume) => volume.id === activeFile.volumeId)?.type
        }
        close={() => setShowFileDetailsModal(false)}
        showModal={showFileDetailsModal}
        isInSearchSection={false}
      />
    </>
  );
}

function mapStateToProps(state) {
  const { fileManagement, volumeSettings, tags, volumes } = state;
  const { files } = fileManagement;
  const volumeList = volumes.items;

  return {
    files,
    volumes,
    volumeList,
    volumeSettings,
    tags,
  };
}

export default connect(mapStateToProps)(FileManagement);
