import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWithAuth } from "../actions/requestHelper";
import Loading from "./Loading";
import FileList from "./FileList";
import FolderList from "./FolderList";
import { update } from "../api/file";
import { updateTags } from "../ducks/tag";

const INITIAL_FILE_LIST_OBJECT = {
  elements: [],
  page: 1,
  total: 0,
  size: 10,
  sort: "name",
  direction: "ASC",
};

/**
 * Child component of Tab, RelatedFilesList is showing the table of the list of files that are related to a file depending on the search result.
 * It is fetching its data only once when selected to keep the API calls to a minimum.
 *
 * @param searchBody Corresponding to the List<SearchFilter> required in the API for a search.
 * @param description: String shown at the top of the table.
 * @param boldElements: An object of elements for the table that will be bold if their value is true.
 * @param emptyListString: A string shown in place of the table if there is no elements.
 * @param tabPosition: The position of this component in the Tab component children.
 * @param currentActiveTab: The current active tab, used to trigger the useEffect.
 * @param isRedBackground: Flag deleted files if true.
 */
export default function RelatedFilesList(props) {
  const {
    searchBody,
    description,
    boldElements,
    emptyListString,
    tabPosition,
    currentActiveTab,
    isRedBackground = false,
    isInModal = true,
    isDirectory = false
  } = props;

  const [fileListWithPagination, setFileListWithPagination] = useState(
    INITIAL_FILE_LIST_OBJECT
  );
  const [isDataFetched, setIsDataFetched] = useState(false);

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);
  const accessToken = auth.accessToken;
  const activeDate = useSelector(({ dates }) => dates.activeDate);
  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings);

  const fetchData = async (
    page = 1,
    sort = "name",
    direction = "ASC",
    size = 10
  ) => {
    setIsDataFetched(false);
    const url = `/v1/search?indexingDate=${activeDate}&page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
    const onPremDuplicate = await fetchWithAuth(
      dispatch,
      url,
      accessToken,
      "POST",
      searchBody
    );
    setFileListWithPagination(onPremDuplicate);
    setIsDataFetched(true);
  };

  useEffect(() => {
    if (tabPosition === currentActiveTab && !isDataFetched) {
      let sort = "name";
      let direction = "ASC"; 
      let size = 10;

      const fileSortValue = window.localStorage.getItem("fileSort");
      const fileDirectionValue = window.localStorage.getItem("fileDirection");
      const folderSortValue = window.localStorage.getItem("folderSort");
      const folderDirectionValue = window.localStorage.getItem("folderDirection");

      const numberOfElementsRelatedFiles = window.localStorage.getItem("numberOfElementsRelatedFiles");

      if (isDirectory) {
        sort = folderSortValue ?? sort;
        direction = folderDirectionValue ? folderDirectionValue.toUpperCase() : direction;
      } else {
        sort = fileSortValue ?? sort;
        direction = fileDirectionValue ? fileDirectionValue.toUpperCase() : direction;
      }
      
      size = numberOfElementsRelatedFiles ? Number(numberOfElementsRelatedFiles) : size;

      fetchData(1, sort, direction, size);
    }
  }, [currentActiveTab]);

  useEffect(() => {
    if (tabPosition === currentActiveTab && isDataFetched) {
      fetchData(
        fileListWithPagination.page,
        fileListWithPagination.sort,
        fileListWithPagination.direction,
        fileListWithPagination.size
      );
    }
  }, [fileListWithPagination.page])

  const changeFilesOrder = (sort) => {
    let direction = "ASC";
    if (sort === fileListWithPagination.sort) {
      direction = fileListWithPagination.direction === "ASC" ? "DESC" : "ASC";
    }

    window.localStorage.setItem(isDirectory ? "folderSort" : "fileSort", sort);
    window.localStorage.setItem(isDirectory ? "folderDirection" : "fileDirection", direction);

    fetchData(fileListWithPagination.page, sort, direction, fileListWithPagination.size);
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsRelatedFiles", numberOfElements);

    fetchData(
      1,
      fileListWithPagination.sort,
      fileListWithPagination.direction,
      numberOfElements
    );
  };

  const getFileOrFolderList = () => {
    if (isDirectory) {
      return (
        <FolderList
          folders={fileListWithPagination.elements}
          page={fileListWithPagination.page}
          total={fileListWithPagination.total}
          size={fileListWithPagination.size}
          sort={fileListWithPagination.sort}
          direction={fileListWithPagination.direction.toLowerCase()}
          selectPage={(page) => setFileListWithPagination((prev) => ({ ...prev, page: page }))}
          changeOrder={(sort) => changeFilesOrder(sort)}
          volumeSettings={volumeSettings}
          updateFolder={(id, folder) => update(dispatch, auth, id, activeDate, folder)}
          updateTags={(tags) => dispatch(updateTags(tags))}
          boldElements={boldElements}
          isRedBackground={isRedBackground}
          isInModal={isInModal}
          setNumberOfElements={handleChangeNumberOfElements}
        />
      );
    }
    return (
      <FileList
        files={fileListWithPagination.elements}
        page={fileListWithPagination.page}
        total={fileListWithPagination.total}
        size={fileListWithPagination.size}
        sort={fileListWithPagination.sort}
        direction={fileListWithPagination.direction.toLowerCase()}
        selectPage={(page) => setFileListWithPagination((prev) => ({ ...prev, page: page }))}
        changeOrder={(sort) => changeFilesOrder(sort)}
        volumeSettings={volumeSettings}
        updateFile={(id, file) => update(dispatch, auth, id, activeDate, file)}
        updateTags={(tags) => dispatch(updateTags(tags))}
        boldElements={boldElements}
        isRedBackground={isRedBackground}
        isInModal={isInModal}
        setNumberOfElements={handleChangeNumberOfElements}
      />
    );
  };

  return (
    <>
      <p>{description}</p>
      {!isDataFetched ?
        <Loading /> :
        (fileListWithPagination.elements.length > 0 ? getFileOrFolderList() : <span>{emptyListString}</span>)
      }
    </>
  );
}
