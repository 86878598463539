import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTasksFromJob } from "../../api/job";
import { getVolumeSettings } from "../../api/volume";
import { scanOnPremiseStorages } from "../../api/scan";
import ScanButton from "./ScanButton";
import PerformanceModeWarning from "./PerformanceModeWarning";

function RelaunchJobModal(props) {
  const { id, closeFunction } = props;

  const [allOnPremises, setAllOnPremises] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsFetching(true);
    getVolumeSettings(dispatch, auth, 1, "name", "asc", 500, [
      "crawl.files",
      "NetworkVolume",
    ])
      .then((res) => setAllOnPremises(res.elements))
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });

    getAllTasksFromJob(dispatch, auth, id)
      .then((res) => {
        setTasks(res);
        setIsFetching(false);
      })
      .catch((err) => {
        console.error(err);
        setIsFetching(false);
      });
  }, []);

  const getVolumesToScan = (tasks) => {
    const volumeSettingIds = allOnPremises.map((onPremise) => onPremise.id);
    return tasks.reduce((failedVolumeIds, task) => {
      if (task.type === "SCAN_VOLUMES") {
        if (task.status === "WARNING" && task.result.failedScanVolumeSettings) {
          const failedScanVolumeIds = task.result.failedScanVolumeSettings
            .filter((volume) => volumeSettingIds.includes(volume.id))
            .map((volume) => volume.id);

          failedVolumeIds.push(...failedScanVolumeIds);
        } else if (
          (task.status === "FAILURE" || task.status === "NEW") &&
          task.payload.volumeSettings
        ) {
          const volumeIds = task.payload.volumeSettings
            .filter((volume) => volumeSettingIds.includes(volume.id))
            .map((volume) => volume.id);

          failedVolumeIds.push(...volumeIds);
        }
      }
      return failedVolumeIds;
    }, []);
  };

  return (
    <>
      {!isFetching && (
        <ScanButton
          closeParent={closeFunction}
          title="Scan"
          isAutoOpen={true}
          volumes={allOnPremises}
          volumeIds={getVolumesToScan(tasks)}
          scan={(volumeIds, cloneIndex) =>
            scanOnPremiseStorages(dispatch, auth, volumeIds, cloneIndex)
          }
          hasCloneIndex
        >
          <PerformanceModeWarning />
          <p>
            Scanning On-Premises Storages can take between a couple minutes and
            several hours, depending on the number of files. Results should
            start showing in the search section after 5 minutes.
          </p>
        </ScanButton>
      )}
    </>
  );
}

export default RelaunchJobModal;
