import { Bar, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, BarElement, Tooltip, Legend, ChartDataLabels);

export default function StackedBarsChart(props) {
  const {
    data,
    dataCount,
    labels,
    height,
    width,
    totalByLabel,
    onClick = () => {},
    chartRef,
    toCalculateMeansByVolumeType,
    storagePrices,
    isCustomLabel = false,
  } = props;

  const barChartData = {
    labels: labels,
    datasets: [
      ...data,
      {
        data: totalByLabel.map(() => 0),
        datalabels: {
          anchor: "end",
          align: "end",
          color: "black",
          formatter: function (value, context) {
            if (totalByLabel[context.dataIndex] === 0) {
              return null;
            }

            return `${totalByLabel[context.dataIndex].toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${storagePrices[0].currencySymbol}`;
          },
          backgroundColor: function (context) {
            if (isCustomLabel) {
              return;
            }
            return "rgba(0, 0, 0, 0.1)";
          },
          borderRadius: 18,
        },
      },
    ],
  };
  const tooltip = {
    callbacks: {
      title: function (context) {
        if (context[0].hasOwnProperty("dataset"))
          return context[0].dataset.label;
      },
      label: function (context) {
        if (typeof dataCount !== "undefined") {
          return [
            "Size: " + dataCount[context.datasetIndex].size,
            "Number of files: " +
              dataCount[context.datasetIndex].numberOfFiles.toLocaleString(
                "en-US"
              ),
            `Total ${context.label} price: ${totalByLabel[
              context.dataIndex
            ].toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${storagePrices[0].currencySymbol}`,
          ];
        }
      },
    },
  };
  const option = {
    onHover: (event, chartElement) => {
      if (onClick.toString() != (() => {}).toString()) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      }
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          filter: function (item, chart) {
            return item.text !== undefined;
          },
        },
      },
      tooltip,
      datalabels: {
        color: "black",
        textAlign: "center",
        padding: 6,
        font: {
          size: isCustomLabel ? 7 : 10,
        },
        display: function (context) {
          if (
            context.dataset.hasOwnProperty("datalabels") &&
            context.dataset.datalabels.align === "end" &&
            context.dataset.datalabels.anchor === "end"
          )
            return true;

          const correctVolumeTypeArray =
            toCalculateMeansByVolumeType[context.dataIndex];
          const correctVolumeTypeArrayTotal = correctVolumeTypeArray.reduce(
            (a, b) => a + b,
            0
          );
          if (correctVolumeTypeArrayTotal !== 0) {
            const volumeTypeMean =
              correctVolumeTypeArrayTotal / correctVolumeTypeArray.length;
            return (
              context.dataset.data[context.dataIndex] >= volumeTypeMean / 1.5
            );
          } else {
            return false;
          }
        },

        formatter: function (value, context) {
          // Customize this part to add text beside the label
          if (isCustomLabel) {
            return (
              dataCount[context.datasetIndex].size +
              "\n" +
              dataCount[context.datasetIndex].numberOfFiles.toLocaleString(
                "en-US"
              ) +
              "\n" +
              value +
              " " +
              storagePrices[0].currencySymbol
            );
          } else {
            return value + " " + storagePrices[0].currencySymbol;
          }
        },
      },
    },
    aspectRatio: 1.05,
    layout: {
      padding: {
        top: 35,
        right: 16,
        bottom: 0,
        left: 8,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const elementIndex = (e) =>
    getElementAtEvent(chartRef.current, e).length
      ? getElementAtEvent(chartRef.current, e)[0].datasetIndex
      : null;

  return (
    <Bar
      ref={chartRef}
      height={height}
      width={width}
      data={barChartData}
      options={option}
      onClick={(e) =>
        onClick(!!data[elementIndex(e)] ? data[elementIndex(e)].label : null)
      }
    />
  );
}
