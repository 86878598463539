import { PDFViewer } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import Loading from "./Loading";

function PDFPreview(props) {
  const {
    data,
    title,
    subTitle,
    deltaInfo = "",
    timeUnit,
    sizeItems,
    sizeOfFiles,
    sizeShownData,
  } = props;
  return (
    <>
      {data ? (
        <PDFViewer
          style={{
            width: "100%", //the pdf viewer will take up all of the width
            height: "600px",
          }}
        >
          <PDFDocument
            data={data}
            title={title}
            subTitle={subTitle}
            deltaInfo={deltaInfo}
            timeUnit={timeUnit}
            sizeItems={sizeItems}
            sizeOfFiles={sizeOfFiles}
            sizeShownData={sizeShownData}
          />
        </PDFViewer>
      ) : (
        <Loading />
      )}
    </>
  );
}
export default PDFPreview;
