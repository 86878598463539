import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchArchiwareCredentialList,
  createArchiwareCredential,
  updateArchiwareCredential,
  deleteArchiwareCredential,
} from "../../ducks/archiwareCredential";
import ArchiwareCredentialList from "./ArchiwareCredentialList";
import {
  fetchArchiwareVolumeMappingList,
  createArchiwareVolumeMapping,
  updateArchiwareVolumeMapping,
  deleteArchiwareVolumeMapping,
} from "../../ducks/archiwareVolumeMapping";
import ArchiwareVolumeMappingList from "./ArchiwareVolumeMappingList";
import {
  fetchArchiwareList,
  updateArchiware,
  deleteArchiware,
} from "../../ducks/archiware";
import PropTypes from "prop-types";
import ArchiwareList from "./ArchiwareList";

Archiwares.propTypes = {
  dispatch: PropTypes.func,
  storagePriceList: PropTypes.array,
  archiwares: PropTypes.object,
  archiwareCredentials: PropTypes.object,
};

function Archiwares(props) {
  const {
    onPremisesStorages,
    dispatch,
    storagePriceList,
    serverList,
    archiwareCredentials,
    archiwares,
    archiwareVolumeMappings,
    openChosenWizardFromSection,
  } = props;

  useEffect(() => {
    dispatch(fetchArchiwareCredentialList());
    dispatch(fetchArchiwareVolumeMappingList());
    dispatch(fetchArchiwareList());
  }, []);

  return (
    <>
      <ArchiwareList
        archiwares={archiwares.items}
        total={archiwares.total}
        storagePriceList={storagePriceList}
        page={archiwares.page}
        size={archiwares.size}
        sort={archiwares.sort}
        direction={archiwares.direction}
        serverList={serverList}
        errors={archiwares.errors}
        totalCredentials={archiwareCredentials.total}
        formValid={archiwares.formValid}
        isFetching={archiwares.isFetching}
        updateArchiware={(id, archiware) =>
          dispatch(updateArchiware(id, archiware))
        }
        deleteArchiware={(id) => dispatch(deleteArchiware(id))}
        fetchArchiwaresListPage={(page, sort, direction, size) =>
          dispatch(fetchArchiwareList(page, sort, direction, size))
        }
        openChosenWizardFromSection={openChosenWizardFromSection}
      />
      <br />
      <ArchiwareCredentialList
        credentials={archiwareCredentials.items}
        total={archiwareCredentials.total}
        page={archiwareCredentials.page}
        size={archiwareCredentials.size}
        sort={archiwareCredentials.sort}
        direction={archiwareCredentials.direction}
        createCredential={(credential) =>
          dispatch(createArchiwareCredential(credential))
        }
        updateCredential={(id, credential) =>
          dispatch(updateArchiwareCredential(id, credential))
        }
        deleteCredential={(id) => dispatch(deleteArchiwareCredential(id))}
        fetchArchiwareCredentialsListPage={(page, sort, direction, size) =>
          dispatch(fetchArchiwareCredentialList(page, sort, direction, size))
        }
        errors={archiwareCredentials.errors}
        formValid={archiwareCredentials.formValid}
        isFetching={archiwareCredentials.isFetching}
      />
      <br />
      <ArchiwareVolumeMappingList
        volumes={onPremisesStorages}
        volumeMappings={archiwareVolumeMappings.items}
        total={archiwareVolumeMappings.total}
        page={archiwareVolumeMappings.page}
        size={archiwareVolumeMappings.size}
        sort={archiwareVolumeMappings.sort}
        direction={archiwareVolumeMappings.direction}
        createVolumeMapping={(volumeMapping) =>
          dispatch(createArchiwareVolumeMapping(volumeMapping))
        }
        updateVolumeMapping={(id, volumeMapping) =>
          dispatch(updateArchiwareVolumeMapping(id, volumeMapping))
        }
        deleteVolumeMapping={(id) => dispatch(deleteArchiwareVolumeMapping(id))}
        fetchArchiwareVolumeMappingsListPage={(page, sort, direction, size) =>
          dispatch(fetchArchiwareVolumeMappingList(page, sort, direction, size))
        }
        errors={archiwareVolumeMappings.errors}
        formValid={archiwareVolumeMappings.formValid}
        isFetching={archiwareVolumeMappings.isFetching}
      />
    </>
  );
}

function mapStateToProps(state) {
  const {
    archiwares,
    archiwareCredentials,
    archiwareVolumeMappings,
    volumeSettings,
  } = state;
  const onPremisesStorages = volumeSettings.items.filter(
    (volume) =>
      volume.type === "crawl.files" ||
      "NetworkVolume" === volume.type ||
      "DellPowerScale" === volume.type
  );
  return {
    archiwares,
    archiwareCredentials,
    archiwareVolumeMappings,
    onPremisesStorages,
  };
}

export default connect(mapStateToProps)(Archiwares);
