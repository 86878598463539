import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import FolderTree from "./FolderTree";

export default function NewFolderTreeWrapperPathPicker(props) {
  const {
    isOpen,
    setIsOpen,
    showCheckbox,
    volumeIds = [],
    setCheckedPath,
    setCheckedVolume,
    modalTitle,
  } = props;

  const volumes = useSelector(({ volumes }) => volumes.items);

  const onNameClick = (e) => {
    if (e.path !== "/") {
      setCheckedPath(e.path);
      setCheckedVolume({
        id: e.volumeId,
        name: e.volumeName,
      });
    } else {
      setCheckedPath("/");
      setCheckedVolume({
        id: e.volumeId,
        name: e.volumeName,
      });
    }
  };

  const getFilteredVolumes = () => {
    if (volumeIds.length === 0) {
      return volumes;
    }
    return volumes.filter((volume) => volumeIds.includes(volume.id));
  };

  return (
    <Modal size="xl" toggle={() => setIsOpen(false)} isOpen={isOpen}>
      <ModalHeader toggle={() => setIsOpen(false)}>{modalTitle}</ModalHeader>

      <ModalBody style={{ maxHeight: "77vh", overflow: "auto" }}>
        <FolderTree
          volumes={getFilteredVolumes()}
          hasFiles={false}
          hasRowBackground={false}
          hasCheckboxes={showCheckbox}
          isDefaultOpen={false}
          hasActions={false}
          onNameClickFolder={onNameClick}
          showOnlyFirstColumn={true}
          hasHeader={false}
        />
        <br />
      </ModalBody>
    </Modal>
  );
}
