import React, { useState } from "react";
import {
  Button,
  Table,
  Popover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";

function CronHelper() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <>
      <Button
        id="helpCrawlSchedulerButton"
        className="py-0 px-1 ms-1"
        type="button"
        outline
      >
        Help!
      </Button>
      <Popover
        isOpen={isPopoverOpen}
        target="helpCrawlSchedulerButton"
        placement="left"
        toggle={() => setIsPopoverOpen(!isPopoverOpen)}
      >

        <PopoverHeader>Cron based configuration</PopoverHeader>
        <PopoverBody>
          <Table striped bordered condensed="true" hover>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Mandatory</th>
                <th>Allowed Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Seconds</td>
                <td>YES</td>
                <td>0-59, *</td>
              </tr>
              <tr>
                <td>Minutes</td>
                <td>YES</td>
                <td>0-59, *</td>
              </tr>
              <tr>
                <td>Hours</td>
                <td>YES</td>
                <td>0-23, *</td>
              </tr>

              <tr>
                <td>Day of month</td>
                <td>YES</td>
                <td>1-31, *, ?</td>
              </tr>
              <tr>
                <td>Month</td>
                <td>YES</td>
                <td>1-12 or JAN-DEC, *</td>
              </tr>
              <tr>
                <td>Day of week</td>
                <td>YES</td>
                <td>1-7 or SUN-SAT, *, ?</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>NO</td>
                <td>empty, 1970-2099</td>
              </tr>
            </tbody>
          </Table>
          <p><strong>Note:</strong> Only one value can be set between day of month <br /> and day of week. The other one has to be ?.</p>
          <span>
            <strong>Examples:</strong><br />
            <ul style={{ marginLeft: "-10px", marginBottom: "4px" }}>
              <li>0 0 12 * * ? : Happens at 12 pm (noon) every day</li>
              <li>0 0 8,16,0 * * ?: Happens at 8 am, 4 pm and 0 am everyday</li>
              <li>0 0 0 ? * MON-FRI: Happens at 12 am (midnight) every weekday</li>
              <li>0 0 */4 ? * *: Happens at every 4 hours every day</li>
              <li>0 0 0 1 JAN-NOV ?: Happens at 12 am (midnight) every first<br />
                day of the month for all months except december</li>
            </ul>
          </span>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default CronHelper;
