import React, { useState, useEffect } from "react";
import TableResult from "../../../components/TableResult";
import ConfirmModal from "../../../components/ConfirmModal";
import { Button, Spinner } from "reactstrap";
import {
  getSodaVolumeMappings,
  getSodaStorages,
  createSodaVolumeMapping,
  updateSodaVolumeMapping,
  deleteSodaVolumeMapping,
} from "../../../api/soda";
import { setAlert } from "../../../../src/actions/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import TableActions from "../../../components/TableActions";
import { useDispatch, useSelector } from "react-redux";

import SodaVolumeMappingForm from "./SodaVolumeMappingForm";
import Loading from "../../../components/Loading";

const NONE = "None";
const APPEND = "Append";
const IGNORE = "Ignore";

function SodaVolumeMappingList(props) {
  const { volumeSettings, isConfigurationSet } = props;

  const [id, setId] = useState("");
  const [dataIntellStorage, setDataIntellStorage] = useState("");
  const [sodaStorage, setSodaStorage] = useState("");
  const [rule, setRule] = useState("None");
  const [value, setValue] = useState("");
  const [showCreateVolumeMappingForm, setShowCreateVolumeMappingForm] =
    useState(false);
  const [showUpdateVolumeMappingForm, setShowUpdateVolumeMappingForm] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [sodaStorages, setSodaStorages] = useState([]);
  const [total, setTotal] = useState();
  const [volumeMappings, setVolumeMappings] = useState([]);
  const [isSodaMappingFetching, setIsSodaMappingFetching] = useState(false);
  const [isSodaStorageFetching, setIsSodaStorageFetching] = useState(false);
  const [disableActionButtons, setDisableActionButtons] = useState(false);
  const auth = useSelector(({ auth }) => auth);
  const [pagination, setPagination] = useState({
    sort: "id",
    direction: "asc",
    size: window.localStorage.getItem("numberOfElementsDataSourceSoda") ? Number(window.localStorage.getItem("numberOfElementsDataSourceSoda")) : 10,
    page: 1
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isConfigurationSet) {
      fetchSodaStorages();
    } else {
      setDisableActionButtons(true);
    }
    fetchSodaVolumeMappingsListPage(
      pagination.page, 
      pagination.sort, 
      pagination.direction,
      pagination.size
    );
  }, [pagination.page, pagination.sort, pagination.direction, pagination.size]);

  const numberOfPages = Math.ceil(total / pagination.size);
  const volumeMappingTitles = [
    { name: "Id", sort: "id" },
    { name: "DataIntell Storage" },
    { name: "CloudSoda Storage" },
    { name: "Rule" },
    { name: "Action" },
  ];

  const createVolumeMapping = async (sodaVolumeMapping) => {
    try {
      await createSodaVolumeMapping(dispatch, auth, sodaVolumeMapping);
      await fetchSodaVolumeMappingsListPage(
        pagination.page, 
        pagination.sort, 
        pagination.direction,
        pagination.size
      );
      dispatch(
        setAlert(
          "Storage path configuration was created successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message || "Unable to create Storage path configuration";
      dispatch(setAlert(message, "danger"));
    }
  };

  const updateVolumeMapping = async (id, sodaVolumeMapping) => {
    try {
      await updateSodaVolumeMapping(dispatch, auth, id, sodaVolumeMapping);
      await fetchSodaVolumeMappingsListPage(
        pagination.page, 
        pagination.sort, 
        pagination.direction,
        pagination.size
      );
      dispatch(
        setAlert(
          "Storage path configuration was updated successfully",
          "success"
        )
      );
    } catch (err) {
      const message =
        err.message || "Unable to update Storage path configuration";
      dispatch(setAlert(message, "danger"));
    }
  };

  const deleteVolumeMapping = async (id) => {
    try {
      await deleteSodaVolumeMapping(dispatch, auth, id);
      await fetchSodaVolumeMappingsListPage(
        pagination.page, 
        pagination.sort, 
        pagination.direction,
        pagination.size
      );
      dispatch(
        setAlert(
          "Storage path configuration was deleted successfully",
          "success"
        )
      );
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };

  const fetchSodaStorages = async () => {
    setIsSodaStorageFetching(true);
    try {
      const result = await getSodaStorages(dispatch, auth);
      setSodaStorages(result);
      setDisableActionButtons(false);
    } catch (err) {
      setDisableActionButtons(true);
      const message = err.message || "Unable to fetch CloudSoda storages";
      dispatch(setAlert(message, "danger"));
    }
    setIsSodaStorageFetching(false);
  };

  const fetchSodaVolumeMappingsListPage = async (
    page,
    sort,
    direction,
    size
  ) => {
    setIsSodaMappingFetching(true);
    try {
      const result = await getSodaVolumeMappings(
        dispatch,
        auth,
        page,
        sort,
        direction,
        size
      );

      setVolumeMappings(result.elements);
      setTotal(result.total);
    } catch (err) {
      const message =
        err.message ||
        "Unable to get the list of volume storage path configurations";
      dispatch(setAlert(message, "danger"));
    }
    setIsSodaMappingFetching(false);
  };

  const handleCloseModal = () => {
    setShowCreateVolumeMappingForm(false);
    setShowUpdateVolumeMappingForm(false);
    setShowDeleteConfirmation(false);
    setId("");
    setDataIntellStorage("");
    setSodaStorage("");
    setValue("");
    setRule("None");
  };

  const handleChange = (name, value) => {
    if (name === "dataIntellStorage") {
      setDataIntellStorage(value);
    } else if (name === "sodaStorage") {
      setSodaStorage(value);
    } else if (name === "rule") {
      setRule(value);
    } else if (name === "value") {
      setValue(value);
    }
  };

  const handleChangeOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === pagination.sort) {
      newDirection = pagination.direction === "asc" ? "desc" : "asc";
    }
    setPagination((prev) => ({ ...prev, sort: newSort, direction: newDirection }));
  };

  const handleChangeNumberOfElements = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsDataSourceSoda", numberOfElements);
    setPagination((prev) => ({ ...prev, page: 1, size: numberOfElements }));
  };

  const handleRule = (pathSoda, pathDataintell) => {
    if (pathSoda !== "") {
      return APPEND;
    }
    if (pathDataintell !== "") {
      return IGNORE;
    }
    return NONE;
  };

  const handleValue = (pathSoda, pathDataintell) => {
    if (handleRule(pathSoda, pathDataintell) === APPEND) {
      return pathSoda;
    }
    if (handleRule(pathSoda, pathDataintell) === IGNORE) {
      return pathDataintell;
    }
    return "";
  };

  const getValidDataIntellPath = () => {
    return rule === IGNORE ? value : "";
  };

  const getValidSodaPath = () => {
    return rule === APPEND ? value : "";
  };

  const handleRuleForPath = (pathSoda, pathDataintell) => {
    if (pathSoda !== "") {
      return `${APPEND}: ${pathSoda}`;
    }
    if (pathDataintell !== "") {
      return `${IGNORE}: ${pathDataintell}`;
    }
    return NONE;
  };

  const getDataintellStorageName = (volumeMapping) => {
    const volume = volumeSettings.find(
      (volumeSetting) => volumeSetting.id === volumeMapping.volumeId
    );

    if (volume && volume.type === "AWS-S3") {
      return volume.name.replace("S3-BUCKET-", "");
    } else if (volume) {
      return volume.name;
    }
    return volumeMapping.volumeId;
  };

  const listFormated = volumeMappings.map((volumeMapping) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };

    if (volumeSettings.length > 0) {
      lineObject.columnData = [
        volumeMapping.id,
        getDataintellStorageName(volumeMapping),
        sodaStorages.find(
          (sodaStorage) => sodaStorage.id === volumeMapping.sodaStorageId
        )?.name ?? volumeMapping.sodaStorageId,
        handleRuleForPath(volumeMapping.sodaPath, volumeMapping.dataintellPath),
        <>
          <Button
            onClick={() => {
              setShowUpdateVolumeMappingForm(true);
              setId(volumeMapping.id);
              setDataIntellStorage(volumeMapping.volumeId);
              setSodaStorage(volumeMapping.sodaStorageId);
              setValue(
                handleValue(
                  volumeMapping.sodaPath,
                  volumeMapping.dataintellPath
                )
              );
              setRule(
                handleRule(volumeMapping.sodaPath, volumeMapping.dataintellPath)
              );
            }}
            disabled={isSodaStorageFetching || disableActionButtons}
          >
            {isSodaStorageFetching ? (
              <Spinner size="sm" />
            ) : (
              <FontAwesomeIcon icon={faEdit} />
            )}
          </Button>
          <Button
            onClick={() => {
              setId(volumeMapping.id);
              setShowDeleteConfirmation(true);
            }}
            color="danger"
            style={{ marginLeft: 10 }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>,
      ];
    }

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "",
      "",
      "",
      "",
      <Button
        onClick={() => {
          setShowCreateVolumeMappingForm(true);
        }}
        disabled={isSodaStorageFetching || disableActionButtons}
        color="success"
      >
        {isSodaStorageFetching ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faPlus} />}
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <h5>Storage Path Configurations</h5>
      <p>
        To let CloudSoda knows where the files are located when launching a
        CloudSoda job, DataIntell will provide the full path of the file based
        on this configuration.
      </p>

      {isSodaMappingFetching ? <Loading /> :
        <div>
          <TableActions
            page={pagination.page}
            numberOfPages={numberOfPages}
            numberOfElements={pagination.size}
            setNumberOfElements={handleChangeNumberOfElements}
            selectPage={(newPage) => setPagination((prev) => ({ ...prev, page: newPage }))}
          />

          <TableResult
            title={"Storage Path Configurations"}
            data={listFormated}
            titles={volumeMappingTitles}
            sort={pagination.sort}
            direction={pagination.direction}
            changeOrder={(newSort) => handleChangeOrder(newSort)}
          />
        </div>
      }

      <ConfirmModal
        showConfirmation={showCreateVolumeMappingForm}
        title="Add Storage Path Configuration"
        buttonColor="primary"
        buttonText="Create"
        confirmAction={() => {
          createVolumeMapping({
            volumeId: dataIntellStorage,
            sodaStorageId: sodaStorage,
            dataintellPath: getValidDataIntellPath(),
            sodaPath: getValidSodaPath(),
          });

          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <SodaVolumeMappingForm
          sodaStorages={sodaStorages}
          volumeSettings={volumeSettings}
          volumeMappings={volumeMappings}
          dataIntellStorage={dataIntellStorage}
          sodaStorage={sodaStorage}
          isUpdate={false}
          rule={rule}
          value={value}
          handleChange={handleChange}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>

      <ConfirmModal
        showConfirmation={showUpdateVolumeMappingForm}
        title="Update Storage Path Configuration"
        buttonColor="primary"
        buttonText="Save"
        confirmAction={() => {
          updateVolumeMapping(id, {
            volumeId: dataIntellStorage,
            dataintellPath: getValidDataIntellPath(),
            sodaPath: getValidSodaPath(),
            sodaStorageId: sodaStorage,
          });
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
        disableRightButton={disableRightButton}
      >
        <SodaVolumeMappingForm
          sodaStorages={sodaStorages}
          volumeSettings={volumeSettings}
          volumeMappings={volumeMappings}
          dataIntellStorage={dataIntellStorage}
          sodaStorage={sodaStorage}
          rule={rule}
          value={value}
          handleChange={handleChange}
          isUpdate={true}
          setDisableCreateButton={setDisableRightButton}
        />
      </ConfirmModal>

      <ConfirmModal
        showConfirmation={showDeleteConfirmation}
        title="Delete Confirmation"
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          deleteVolumeMapping(id);
          handleCloseModal();
        }}
        closeConfirmation={handleCloseModal}
      >
        Are you sure you want to delete the storage path configuration with id{" "}
        {id}?
      </ConfirmModal>
    </>
  );
}

export default SodaVolumeMappingList;
