import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchFolderDetails,
  fetchFolderStatistics,
} from "../ducks/folderDetails";
import { Button, UncontrolledTooltip } from "reactstrap";
import { fetchVolumes, setDate } from "../actions";
import Breadcrumbs from "../components/Breadcrumbs";

import queryString from "query-string";
import Timeline from "./components/Timeline";
import FolderContent from "./components/FolderContent";
import FolderSizeChart from "./components/FolderSizeChart";
import FolderStatistics from "./components/FolderStatistics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CompareDatesFolderTree from "./components/CompareDatesFolderTree";
import Loading from "../components/Loading";
import { useLocation, useParams, useNavigate } from "react-router-dom";

function Folders(props) {
  const { 
    dates,
    fetchingDates, 
    items, 
    statistics,
    dispatch,
    volumes
  } = props;

  const [timelineContent, setTimelineContent] = useState("");

  const navigate = useNavigate();

  const location = useLocation();

  const params = useParams();

  useEffect(() => {
    dispatch(fetchVolumes());
    dispatch(fetchFolderDetails(getVolumeId(), getPath()));
    dispatch(fetchFolderStatistics(getVolumeId(), getPath()));
    getData(0);
  }, [location.search]);

  const getVolumeId = () => {
    const queryParams = queryString.parse(location.search);
    return queryParams.volumeId;
  };

  const getPath = () => {
    const queryParams = queryString.parse(location.search);
    const path = !queryParams.path ? "/" : queryParams.path;
    return path;
  };

  const getFolderItemFromReverseIndex = (reverseIndex) => {
    if (items.length === 0) {
      return;
    }

    const index = items.length - reverseIndex - 1;
    return items[index];
  };

  const getData = (reverseIndex) => {
    const item = getFolderItemFromReverseIndex(reverseIndex);
    
    if (item) {
      const content = (
        <FolderContent
          folder={getFolderItemFromReverseIndex(reverseIndex)}
        />
      );
      setTimelineContent(content);
    }
  };

  const getVolume = () => {
    return volumes.find((element) => parseInt(getVolumeId(), 10) === element.id);
  };

  const changePath = (path) => {
    navigate(`/folders?path=${path}&volumeId=${getVolumeId()}`);
  };

  const directoryName = params.name || "";
  const fullPath = getPath() + "/" + directoryName;
  const volume = getVolume();

  return (
    <>
      <div className="d-flex">
        <h1 className="page-header">Folder analytics</h1>
        <div className="ms-auto">
          <Button
            id="search"
            onClick={() =>
              navigate(
                `/search?volumeId=${getVolumeId()}&startingPath=${getPath()}`
              )
            }
            color="info"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <UncontrolledTooltip placement="auto" target="search">
            Show content as Search result
          </UncontrolledTooltip>
        </div>
      </div>
      {volume ?
        <>
          <Breadcrumbs
            handleClick={changePath}
            volume={volume}
            listVolumes={() => navigate(`/filemanagement`)}
            path={fullPath}
          />
          <CompareDatesFolderTree
            volumes={[volume]}
            path={getPath()}
          />
          <br />
          <FolderSizeChart
            volumeId={volume.id}
            path={getPath()}
          />
          <Timeline
            title={"Detailed timeline"}
            fetching={fetchingDates}
            dates={dates}
            getData={getData}
            content={timelineContent}
          />
          <FolderStatistics statistics={statistics} />
        </> :
        <Loading />
      }
    </>
  );
}

function mapStateToProps(state) {
  const { folderDetails, volumes } = state;

  return {
    dates: folderDetails.dates,
    items: folderDetails.items,
    fetchingDates: folderDetails.isFetching,
    statistics: folderDetails.statistics,
    volumes: volumes.items,
  };
}

export default connect(mapStateToProps)(Folders);
