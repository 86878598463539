import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { getFilesByHash } from "../api/file";
import FileFolderDetailsTable from "./FileFolderDetailsTable";
import FileIcon from "./FileIcon";
import FileList from "./FileList";
import Loading from "./Loading";
import RelatedFilesForArchive from "./RelatedFilesForArchive";
import RelatedFilesForCloud from "./RelatedFilesForCloud";
import RelatedFilesForOnPrem from "./RelatedFilesForOnPrem";
import TagFilePanel from "./tags/TagFilePanel";

export default function FileDetails(props) {
  const { file, updateFile, updateTags, volumeType, isInModal = true } = props;

  const [fileDetails, setFileDetails] = useState({
    elements: [],
    page: 1,
    total: 0,
    size: 10,
    sort: "name",
    direction: "asc",
  });
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const volumeSettings = useSelector(({ volumeSettings }) => volumeSettings);

  useEffect(() => {
    const fileSortValue = window.localStorage.getItem("fileSort");
    const fileDirectionValue = window.localStorage.getItem("fileDirection");

    fetchFilesByHash(
      fileDetails.page,
      fileDetails.size,
      fileSortValue ?? fileDetails.sort,
      fileDirectionValue ?? fileDetails.direction
    );
  }, [file]);

  const fetchFilesByHash = (page, size, sort, direction) => {
    setIsFetching(true);
    getFilesByHash(
      dispatch,
      auth,
      indexingDate,
      file.identificationHash,
      page,
      size,
      sort,
      direction
    )
      .then((res) => {
        setFileDetails(res);
        setTimeout(() => setIsFetching(false), 100);
      })
      .catch((err) => {
        console.error("Exception while getting file details");
        setTimeout(() => setIsFetching(false), 100);
      });
  };

  const changeFilesOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === fileDetails.sort) {
      newDirection = fileDetails.direction === "asc" ? "desc" : "asc";
    }

    window.localStorage.setItem("fileSort", newSort);
    window.localStorage.setItem("fileDirection", newDirection);

    fetchFilesByHash(fileDetails.page, fileDetails.size, newSort, newDirection);
  };

  const getRelatedFilesSection = () => {
    switch (volumeType) {
      case "crawl.files":
      case "NetworkVolume":
      case "DellPowerScale":
        return <RelatedFilesForOnPrem file={file} />;
      case "Archives":
      case "Archiware":
        return <RelatedFilesForArchive file={file} />;
      case "AWS-S3":
        return <RelatedFilesForCloud file={file} />;
      default:
        break;
    }
  };

  const getContent = () => {
    if (isInModal) {
      return (
        <>
          <Row>
            <Col lg={8} md={12} className="mb-3 mb-lg-0">
              <FileFolderDetailsTable dataObj={file} />
            </Col>
            <Col lg={4} md={12}>
              <TagFilePanel
                file={file}
                updateFile={(tags) => {
                  updateFile(file.fileId, { ...file, customTag: tags });
                }}
                updateTags={(tags) => updateTags(tags)}
              />
            </Col>
          </Row>
          <hr className="mx-3 mt-4" />
          <h5>Related Files</h5>
          {getRelatedFilesSection()}
        </>
      );
    }

    return (
      <>
        <h3>
          <FileIcon extension={file.extension} />
          <span className="ms-1">{file.name}</span>
        </h3>
        <Row>
          <Col sm={12} className="mb-3">
            <Card>
              <CardHeader tag="h5">Details</CardHeader>
              <CardBody>
                <Row>
                  <Col lg={7} className="mb-3 mb-lg-0">
                    <FileFolderDetailsTable dataObj={file} />
                  </Col>
                  <Col lg={5}>
                    <TagFilePanel
                      file={file}
                      updateFile={(tags) => {
                        updateFile(file.fileId, { ...file, customTag: tags });
                      }}
                      updateTags={(tags) => updateTags(tags)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardHeader tag="h5">Related Files</CardHeader>
              <CardBody>{getRelatedFilesSection()}</CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return <>{isFetching ? <Loading /> : getContent()}</>;
}
