import {
  REQUEST_LAST_ACCESS,
  RECEIVE_LAST_ACCESS,
  INVALIDATE_DATE
} from "../actions";

function statistic(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: []
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_DATE:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_LAST_ACCESS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_LAST_ACCESS:
      return Object.assign(
        {},
        state,
        Object.assign(
          {},
          {
            isFetching: false,
            didInvalidate: false,
            lastUpdated: action.receivedAt,
            size: action.size
          }
        )
      );
    default:
      return state;
  }
}

function dateStatistics(
  state = { lastAccessByDate: {} },
  action
) {
  switch (action.type) {
    case INVALIDATE_DATE:
    case RECEIVE_LAST_ACCESS:
    case REQUEST_LAST_ACCESS:
      return Object.assign({}, state, {
        lastAccessByDate: Object.assign({}, state.lastAccessByDate, {
          [action.date]: statistic(state[action.date], action)
        })
      });
    default:
      return state;
  }
}

export default dateStatistics;
