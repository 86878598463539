import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip, PopoverHeader, PopoverBody, Popover } from "reactstrap";
import EditFileTags from "./tags/EditFileTags";
import CopyPath from "./CopyPath";
import FolderAnalyticsButton from "./FolderAnalyticsButton";
import { smartTrim } from "../utils/formatString";
import { useSelector } from "react-redux";

export default function TableFileName(props) {
  const {
    updateFile,
    updateTags,
    file,
    updateSelectedFile = () => { },
    showActions,
    isTagFileOpen,
    setIsTagFileOpen,
    isFileNameBold
  } = props;

  const isNameCondensed = useSelector(({ userSettings }) => userSettings.isNameCondensed);

  return (
    <>
      <span style={{ fontWeight: isFileNameBold ? "bold" : "normal" }}>
        {smartTrim(file.name, isNameCondensed === "true" ? Math.floor(window.innerWidth / 93.3) : 0)}
      </span>
      {showActions &&
        <>
          <br />
          <FontAwesomeIcon
            id="tagFile"
            icon={faTag}
            onClick={e => e.stopPropagation()}
          />
          <UncontrolledTooltip target="tagFile" placement="auto">Manage tags</UncontrolledTooltip>
          <span className="ms-1">
            <CopyPath file={file} />
          </span>
          {file.isDirectory &&
            <span className="ms-1">
              <FolderAnalyticsButton
                volumeId={file.volumeId}
                path={file.path}
              />
            </span>
          }
          <Popover
            target="tagFile"
            placement="right"
            trigger="legacy"
            isOpen={isTagFileOpen}
            toggle={() => setIsTagFileOpen(!isTagFileOpen)}
            onClick={e => e.stopPropagation()}
          >
            <PopoverHeader>Save tags</PopoverHeader>
            <PopoverBody>
              <EditFileTags
                fileTags={file.customTag ?? []}
                updateFile={tags => {
                  const updatedFile = { ...file, customTag: tags };
                  updateFile(file.fileId, updatedFile);
                  updateSelectedFile(updatedFile);
                }}
                updateTags={tags => updateTags(tags)}
                closeFunc={() => setIsTagFileOpen(false)}
                isSave
              />
            </PopoverBody>
          </Popover>
        </>
      }
    </>

  );
}
