import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import TableResult from "./TableResult";
import Loading from "./Loading";
import GeneralError from "./GeneralError";
import JobStatusColorScheme from "../settings/components/JobStatusColorScheme";
import TableTitleTooltip from "./TableTitleTooltip";
import JobsDuration from "../settings/components/JobsDuration";
import { getAllTasksFromJob } from "../api/job";
import Tabs from "./Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

export default function JobsModal({
  isOpen,
  setShowCancelJobModal,
  setShowRelaunchJobModal,
  closeFunction,
  selectedJob,
  findServerName,
  setActiveTab,
  activeTab,
}) {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [selectedJobTasks, setSelectedJobTasks] = useState([]);
  const [fetchingTasks, setFetchingTasks] = useState(false);
  const [taskFetchError, setTaskFetchError] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getJobTasks = async (id) => {
    setFetchingTasks(true);
    setTaskFetchError(false);

    try {
      const res = await getAllTasksFromJob(dispatch, auth, id, "GET");
      setSelectedJobTasks(res);
      setSelectedTask(res[selectedIndex]);
      setFetchingTasks(false);
    } catch (e) {
      setTaskFetchError(true);
      setFetchingTasks(false);
      console.error(e.message);
    }
  };

  useEffect(() => {
    if (selectedJob.id) getJobTasks(selectedJob.id);
  }, [selectedJob]);

  const listFormated = selectedJobTasks.map((job, index) => {
    const lineObject = {
      columnData: [],
      style: {
        cursor: "pointer",
        backgroundColor: selectedIndex === index ? "rgb(0, 0, 0, 0.075)" : "",
      },
      options: {
        linkData: {
          job,
          index,
        },
      },
    };

    lineObject.columnData = [
      job.id,
      job.type,
      findServerName(job),
      moment(job.createdAt).format("lll"),
      moment(job.startedAt).format("lll"),
      moment(job.updatedAt).format("lll"),
      <JobsDuration
        startTime={new Date(job.startedAt).getTime()}
        updateTime={new Date(job.updatedAt).getTime()}
        status={job.status}
      />,
      <JobStatusColorScheme
        status={job.status}
        action={() => setShowCancelJobModal(true)}
      />,
    ];

    return lineObject;
  });

  const generateJSONFile = (data) => {
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  };

  const updateTimeFragment = (
    <>
      Update Time{" "}
      <TableTitleTooltip target="jobUpdateTime">
        Last time the status of the job was updated
      </TableTitleTooltip>
    </>
  );

  const fieldTitles = [
    { name: "Id" },
    { name: "Type" },
    { name: "Server Name" },
    { name: "Creation Time" },
    { name: "Start Time" },
    { name: updateTimeFragment },
    { name: "Duration" },
    { name: "Status" },
  ];

  const onCloseModal = () => {
    setSelectedIndex(0);
    closeFunction();
  };

  return (
    <Modal size={"lg"} toggle={onCloseModal} isOpen={isOpen}>
      <ModalHeader toggle={onCloseModal}>
        Job {selectedJob.id} - {selectedJob.type}
      </ModalHeader>

      <ModalBody>
        <Tabs
          toggleTab={(tab) => {
            setActiveTab(tab);
          }}
          activeTab={activeTab}
          titles={["Job", "Tasks"]}
        >
          <div
            style={{ fontSize: "1.1em" }}
            className="d-flex flex-lg-row flex-column mb-3"
          >
            <div style={{ flexBasis: "50%" }}>
              <h5>Job Details</h5>
              <div className="d-flex flex-lg-row flex-column mb-4">
                <div className="d-flex flex-column me-lg-5">
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Id</span>
                    <div>
                      <Badge>{selectedJob.id}</Badge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Type</span>
                    <div>
                      <Badge>{selectedJob.type}</Badge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Status</span>
                    <div>
                      <JobStatusColorScheme
                        status={selectedJob.status}
                        action={() => setShowCancelJobModal(true)}
                        isRelaunchable={selectedJob.type === "SCAN_ON_PREMISES"}
                        relaunch={() => {
                          setShowRelaunchJobModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Server Name</span>
                    <div>
                      <Badge>
                        {selectedJob.serverId
                          ? `${selectedJob.serverId} - ${findServerName(
                            selectedJob
                          )}`
                          : "N/A"}
                      </Badge>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Creation Time</span>
                    <div>
                      <Badge>
                        {moment(selectedJob.createdAt).format("lll")}
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Start Time</span>
                    <div>
                      <Badge>
                        {moment(selectedJob.startedAt).format("lll")}
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Update Time</span>
                    <div>
                      <Badge>
                        {moment(selectedJob.updatedAt).format("lll")}
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className="me-4">Duration</span>
                    <div>
                      <Badge>
                        <JobsDuration
                          startTime={new Date(selectedJob.startedAt).getTime()}
                          updateTime={new Date(selectedJob.updatedAt).getTime()}
                          status={selectedJob.status}
                        />
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ flexBasis: "50%" }}>
              <>
                {selectedJob.result ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h5>Job Result</h5>

                      <div className="ms-2">
                        <FontAwesomeIcon
                          id="copyJobResult"
                          icon={faFileExport}
                          onClick={() => {
                            generateJSONFile(selectedJob.result);
                          }}
                          style={{
                            cursor: "pointer",
                            transform: "scale(1.2)",
                          }}
                        />

                        <UncontrolledTooltip
                          target="copyJobResult"
                          placement="auto"
                        >
                          Export
                        </UncontrolledTooltip>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "240px",
                        overflow: "scroll",
                        background: "#f2f2f2",
                        borderRadius: "10px",
                        marginBottom: "10px",
                        display: "grid",
                      }}
                    >
                      <pre className="mt-2 ms-2">
                        {JSON.stringify(selectedJob.result, null, 2)}
                      </pre>
                    </div>
                  </>
                ) : null}
              </>
            </div>
          </div>

          <>
            <h5>Tasks</h5>

            {taskFetchError ? (
              <GeneralError
                customErrorMessage={
                  "Something went wrong while fetching the tasks"
                }
              />
            ) : !fetchingTasks && selectedJobTasks.length ? (
              <TableResult
                data={listFormated}
                titles={fieldTitles}
                handleClick={(linkedObj) => {
                  setSelectedIndex(linkedObj.index);
                  setSelectedTask(linkedObj.job);
                }}
              />
            ) : (
              <Loading />
            )}

            <div className="d-flex">
              <div style={{ flexBasis: "50%" }}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <h5>
                    Task Details - {selectedTask.type} - {selectedTask.id}
                  </h5>

                  <div className="ms-2">
                    <FontAwesomeIcon
                      id="copyTaskDetails"
                      icon={faFileExport}
                      onClick={() => {
                        generateJSONFile(selectedTask.payload);
                      }}
                      style={{
                        cursor: "pointer",
                        transform: "scale(1.2)",
                      }}
                    />

                    <UncontrolledTooltip
                      target="copyTaskDetails"
                      placement="auto"
                    >
                      Export
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div
                  style={{
                    height: "240px",
                    overflow: "scroll",
                    background: "#f2f2f2",
                    borderRadius: "10px",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <pre className="mt-2 ms-2">
                    {JSON.stringify(selectedTask.payload, null, 2)}
                  </pre>
                </div>
              </div>

              {selectedTask.result ? (
                <>
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h5>Task Result</h5>

                      <div className="ms-2">
                        <FontAwesomeIcon
                          id="copyTaskResult"
                          icon={faFileExport}
                          onClick={() => {
                            generateJSONFile(selectedTask.result);
                          }}
                          style={{
                            cursor: "pointer",
                            transform: "scale(1.2)",
                          }}
                        />

                        <UncontrolledTooltip
                          target="copyTaskResult"
                          placement="auto"
                        >
                          Export
                        </UncontrolledTooltip>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "240px",
                        overflow: "scroll",
                        background: "#f2f2f2",
                        borderRadius: "10px",
                        marginBottom: "10px",
                        display: "grid",
                      }}
                    >
                      <pre className="mt-2 ms-2">
                        {JSON.stringify(selectedTask.result, null, 2)}
                      </pre>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
