import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ProfileInfo from "./components/ProfileInfo";
import {
  createUserSchedule,
  fetchScheduleListByUser,
  deleteScheduleForUser,
  updateUserSchedule,
} from "../ducks/schedule";
import AlertConfigurations from "./components/AlertConfigurations";
import { fetchProjectList } from "../ducks/project";
import { fetchVolumeSettings } from "../ducks/volumeSettings";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faUser,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import ResetPasswordModal from "./components/ResetPasswordModal";
import UpdateProfileInfoModal from "./components/UpdateProfileInfoModal";
import NotificationConfiguration from "./components/jobNotifications/NotificationConfigurations";
import UserSettings from "./components/UserSettings";

function Profile(props) {
  const {
    name,
    username,
    email,
    userId,
    schedules,
    projectList,
    volumeSettings,
    dispatch,
  } = props;

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showUpdateProfileInfoModal, setUpdateProfileInfoModal] =
    useState(false);

  const type = useSelector(({ auth }) => auth.type);

  useEffect(() => {
    dispatch(fetchScheduleListByUser("ALERT", userId));
    dispatch(fetchProjectList(1, "name", "asc", 1000));
    dispatch(fetchVolumeSettings());
  }, [dispatch, userId]);

  const deleteFunc = (id) => dispatch(deleteScheduleForUser(id, userId));
  const createFunc = (alert) => dispatch(createUserSchedule(alert, userId));
  const updateFunc = (id, schedule) =>
    dispatch(updateUserSchedule(id, schedule, userId));

  return (
    <>
      <div className="d-flex">
        <h1>Profile</h1>
        <div className="ms-auto">
          {type === "DATAINTELL" &&
            <>
              <Button
                id="profileChangePassword"
                className="me-2"
                color="warning"
                onClick={() => setShowResetPasswordModal(true)}
              >
                <FontAwesomeIcon icon={faKey} />
              </Button>
              <Button
                id="updateProfileInfo"
                className="me-2"
                color="info"
                onClick={() => setUpdateProfileInfoModal(true)}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
              <UncontrolledTooltip
                placement="auto"
                target="profileChangePassword"
              >
                Reset your password
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="auto" target="updateProfileInfo">
                Update your profile information
              </UncontrolledTooltip>
            </>
          }
          <Button
            id="openEulaLink"
            onClick={() =>
              window.open("https://dataintell.io/licence", "_blank")
            }
          >
            <FontAwesomeIcon icon={faFileContract} />
          </Button>
          <UncontrolledTooltip placement="auto" target="openEulaLink">
            Open End User Licence Agreement in new tab
          </UncontrolledTooltip>
        </div>
      </div>
      <ProfileInfo name={name} username={username} email={email} />
      <UserSettings />
      <br />
      <AlertConfigurations
        userId={userId}
        schedules={schedules.items}
        errors={schedules.errors}
        formValid={schedules.formValid}
        projects={projectList.items}
        volumes={volumeSettings.items.filter(
          (v) =>
            v.type === "crawl.files" ||
            v.type === "NetworkVolume" ||
            v.type === "DellPowerScale"
        )}
        deleteFunc={deleteFunc}
        createFunc={createFunc}
        updateFunc={updateFunc}
      />
      <NotificationConfiguration />
      <ResetPasswordModal
        showResetPasswordModal={showResetPasswordModal}
        closeResetPasswordModal={() => setShowResetPasswordModal(false)}
      />
      <UpdateProfileInfoModal
        showUpdateProfileInfoModal={showUpdateProfileInfoModal}
        closeUpdateProfileInfoModal={() => setUpdateProfileInfoModal(false)}
        name={name}
        username={username}
        email={email}
      />
    </>
  );
}

function mapStateToProps(state) {
  const { schedules, projectList, volumeSettings } = state;
  const { name, username, email, userId } = state.auth;
  return {
    name,
    username,
    email,
    userId,
    schedules,
    projectList,
    volumeSettings,
  };
}

export default connect(mapStateToProps)(Profile);
