import { useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Tooltip } from "reactstrap";
import ConfirmModal from "../../components/ConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function ArchiwareGlobalVolumeMappingModal(props) {
  const {
    id,
    action,
    volumeId,
    dataintellPath,
    archiwarePath,
    isOpen,
    setIsOpen,
    configurationsFunc,
  } = props;

  const [updatedDataintellPath, setUpdatedDataintellPath] =
    useState(dataintellPath);
  const [updatedArchiwarePath, setUpdatedArchiwarePath] =
    useState(archiwarePath);
  const [isDataIntellPathTooltipOpen, setIsDataIntellPathTooltipOpen] =
    useState(false);
  const [isArchiwareP5PathTooltipOpen, setIsArchiwareP5PathTooltipOpen] =
    useState(false);

  useEffect(() => {
    if (isOpen) {
      setUpdatedDataintellPath(dataintellPath);
      setUpdatedArchiwarePath(archiwarePath);
    }
  }, [isOpen]);

  return (
    <ConfirmModal
      confirmAction={() => {
        configurationsFunc({
          id: id,
          volumeId: volumeId,
          dataintellPath: updatedDataintellPath,
          archiwarePath: updatedArchiwarePath,
        });
        setIsOpen(false);
      }}
      showConfirmation={isOpen}
      title={`${action} global storage path configuration`}
      closeConfirmation={() => setIsOpen(false)}
      buttonColor="primary"
      buttonText="Save"
      disableRightButton={
        updatedArchiwarePath === "" || updatedDataintellPath === ""
      }
    >
      <Form>
        <FormGroup row>
          <Label sm={4}>
            DataIntell Path
            <FontAwesomeIcon
              id="dataIntellPathLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isDataIntellPathTooltipOpen}
              target="dataIntellPathLabel"
              toggle={() =>
                setIsDataIntellPathTooltipOpen(!isDataIntellPathTooltipOpen)
              }
            >
              The mount path of the On-Premises storage for DataIntell scanner.
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              placeholder="Ex: /Volumes/NASX"
              value={updatedDataintellPath}
              onChange={(e) => setUpdatedDataintellPath(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>
            Archiware P5 Path
            <FontAwesomeIcon
              id="archiwareP5PathLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <Tooltip
              placement="right"
              isOpen={isArchiwareP5PathTooltipOpen}
              target="archiwareP5PathLabel"
              toggle={() =>
                setIsArchiwareP5PathTooltipOpen(!isArchiwareP5PathTooltipOpen)
              }
            >
              The mount path of the On-Premises storage for Archiware P5 server.
            </Tooltip>
          </Label>
          <Col sm={8}>
            <Input
              placeholder="Ex: /Volumes/NASX"
              value={updatedArchiwarePath}
              onChange={(e) => setUpdatedArchiwarePath(e.target.value)}
            />
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}
