import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchServerList } from "../ducks/server";
import {
  fetchScheduleList,
  createSchedule,
  updateSchedule,
  deleteSchedule,
} from "../ducks/schedule";
import { setAlert } from "../actions/alert";
import ScheduleList from "./components/ScheduleList";
import PropTypes from "prop-types";
import { hasBundle } from "../validation/HasBundle";
import { ARCHIWARE_BUNDLE } from "../constants/bundles";
import { getAll as getAllArchiwareP5Indexes } from "../api/archiware";
import { getAll } from "../api/bucket";
import { getAllDellPowerScaleStoragesPaginated } from "../api/dellPowerScale";

Schedules.propTypes = {
  volumeSettings: PropTypes.object,
  dispatch: PropTypes.func,
  servers: PropTypes.object,
  schedules: PropTypes.object,
  buckets: PropTypes.object,
  archiwares: PropTypes.object,
};
function Schedules(props) {
  const { volumeSettings, dispatch, servers, schedules } = props;
  const auth = useSelector(({ auth }) => auth);
  const [buckets, setBuckets] = useState([]);
  const [dellPowerScaleStorages, setDellPowerScaleStorages] = useState([]);
  const [archiwares, setArchiwares] = useState([]);
  useEffect(() => {
    dispatch(fetchServerList());
    dispatch(fetchScheduleList());
    getAll(dispatch, auth, 1, 500)
      .then((res) => setBuckets(res.elements))
      .catch((err) =>
        dispatch(
          setAlert("Something went wrong while fetching the buckets", "danger")
        )
      );
    if (hasBundle(ARCHIWARE_BUNDLE)) {
      getAllArchiwareP5Indexes(dispatch, auth, 1, 500)
        .then((res) => setArchiwares(res.elements))
        .catch((err) =>
          dispatch(
            setAlert(
              "Something went wrong while fetching the Archiware P5 Indexes",
              "danger"
            )
          )
        );
    }
    getDellPowerScaleStorages();
  }, [dispatch]);

  const getDellPowerScaleStorages = async () => {
    try {
      const res = await getAllDellPowerScaleStoragesPaginated(
        dispatch,
        auth,
        1,
        500
      );
      setDellPowerScaleStorages(res.elements);
    } catch (err) {
      console.error(err);
      dispatch(
        setAlert(
          "Something went wrong while fetching Dell PowerScale Storages",
          "danger",
          8000
        )
      );
    }
  };

  return (
    <>
      <h1 className="page-header">Schedule Settings</h1>
      <p>Manage how and when the jobs occur.</p>
      <ScheduleList
        schedules={schedules.items}
        servers={servers.items}
        volumes={volumeSettings.items.filter(
          (volumeSetting) =>
            volumeSetting.type === "crawl.files" ||
            "NetworkVolume" === volumeSetting.type
        )}
        buckets={buckets}
        dellPowerScaleStorages={dellPowerScaleStorages.map(
          (dellPowerScaleStorage) => ({
            ...dellPowerScaleStorage,
            name: dellPowerScaleStorage.dataintellName,
          })
        )}
        archiwares={archiwares.map((archiware) => ({
          ...archiware,
          type: "Archiware",
        }))}
        createSchedule={(schedule) => dispatch(createSchedule(schedule))}
        updateSchedule={(id, schedule) =>
          dispatch(updateSchedule(id, schedule))
        }
        deleteSchedule={(id) => dispatch(deleteSchedule(id))}
      />
    </>
  );
}

function mapStateToProps(state) {
  const { volumeSettings, servers, schedules } = state;
  return { volumeSettings, servers, schedules };
}

export default connect(mapStateToProps)(Schedules);
