import { useState, useEffect } from "react";
import moment from "moment";
import TableResult from "../components/TableResult";
import { returnNAOnNull } from "../utils/returnNAOnNull";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import TableTitleTooltip from "./TableTitleTooltip";
import { getMonthlyCostByVolume } from "../utils/pricePerVolume";
import TableFileName from "./TableFileName";
import TagCheckbox from "./tags/TagCheckbox";
import TagMultipleFilesButton from "./tags/TagMultipleFilesButton";
import TagMultipleFilesPopover from "./tags/TagMultipleFilesPopover";
import TableActions from "./TableActions";
import CheckboxButton from "./CheckboxButton";
import Loading from "./Loading";
import HasBundle from "../validation/HasBundle";
import { ARCHIWARE_BUNDLE, ICONIK_BUNDLE, SODA_BUNDLE, STORAGE_MANAGER_BUNDLE } from "../constants/bundles";
import ArchiveSelectionButton from "./ArchiveSelectionButton";
import ArchiveSelectionWizard from "./archiwareArchiveRestore/ArchiveSelectionWizard";
import RestoreArchiveButton from "./RestoreArchiveButton";
import RestoreFromArchiveWizard from "./archiwareArchiveRestore/RestoreFromArchiveWizard";
import {
  ROLE_ARCHIWARE_ARCHIVE,
  ROLE_ARCHIWARE_RESTORE,
  ROLE_SODA_TRANSFER,
  ROLE_STORAGE_MANAGER_ARCHIVE,
  ROLE_STORAGE_MANAGER_RESTORE
} from "../constants/roles";
import Authorization from "../validation/Authorization";
import SodaActionButton from "./SodaActionButton";
import SodaActionWizard from "./soda/SodaActionWizard";
import StorageManagerActionButton from "./storageManager/StorageManagerActionButton";
import StorageManagerActionWizard from "./storageManager/StorageManagerActionWizard";
import FormatSize from "./FormatSize";
import DateLabelWithTooltip from "./DateLabelWithTooltip";
import IconikActionButton from "./iconik/IconikActionButton";
import IconikActionWizard from "./iconik/IconikActionWizard";
import { useSelector } from "react-redux";
import { smartTrim } from "../utils/formatString";

export default function FolderList(props) {
  const {
    folders,
    page,
    total,
    size,
    sort,
    direction,
    changeOrder,
    AddFolderComponent,
    showNumberOfFolders,
    volumeSettings,
    showFolderDetails,
    selectPage,
    changePath,
    updateFolder,
    updateTags,
    setNumberOfElements,
    boldElements = {},
    isRedBackground = true,
    search = "",
    isLoading,
    isInModal = false
  } = props;

  const [targetTableRowIndex, setTargetTableRowIndex] = useState(null);
  const [isTagFolderOpen, setIsTagFolderOpen] = useState(false);
  const [isApplyTagMultipleFoldersOpen, setIsApplyTagMultipleFoldersOpen] =
    useState(false);
  const [isRemoveTagMultipleFoldersOpen, setIsRemoveTagMultipleFoldersOpen] =
    useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [useSearchResultForActions, setUseSearchResultForActions] =
    useState(false);
  const [isCurrentPageSelected, setIsCurrentPageSelected] = useState(false);
  const [showArchiveSelectionWizard, setShowArchiveSelectionWizard] =
    useState(false);
  const [showRestoreFromArchiveWizard, setShowRestoreFromArchiveWizard] =
    useState(false);
  const [showSodaActionWizard, setShowSodaActionWizard] = useState(false);
  const [showStorageManagerActionWizard, setShowStorageManagerActionWizard] = useState(false);
  const [showIconikActionWizard, setShowIconikActionWizard] = useState(false);

  useEffect(() => {
    if (folders && folders.length > 0) {
      const isCurrentPageFoldersAllSelected = folders.every((folder) =>
        selectedFolders.some(
          (selectedFolder) => folder.fileId === selectedFolder.fileId
        )
      );
      setIsCurrentPageSelected(isCurrentPageFoldersAllSelected);
    }
  }, [folders, selectedFolders]);

  useEffect(() => {
    refreshSelectedFolders();
  }, []);

  useEffect(() => {
    setUseSearchResultForActions(false);
  }, [total]);

  const isPathCondensed = useSelector(({ userSettings }) => userSettings.isPathCondensed);

  const refreshSelectedFolders = () => {
    const refreshedSelectedFolders = [];
    selectedFolders.forEach((selectedFolder) => {
      const refreshedFolder = folders.find(
        (folder) => folder.fileId === selectedFolder.fileId
      );
      refreshedSelectedFolders.push(refreshedFolder);
    });
    setSelectedFolders(refreshedSelectedFolders);
  };

  const formatDate = (value) => moment(value).format("lll");

  const formatFontWeight = (value) => (boldElements[value] ? "bold" : "normal");

  const updateSelectedFolder = (folder) => {
    const updatedSelectedFolders = selectedFolders.map(f => f.fileId === folder.fileId ? folder : f);
    setSelectedFolders(updatedSelectedFolders);
  };

  const listFormated =
    folders &&
    folders.map((folder, index) => {
      const lastAccess = returnNAOnNull(folder.lastAccess, formatDate);
      const lastModified = returnNAOnNull(folder.lastModified, formatDate);
      const creationDate = returnNAOnNull(folder.creationDate, formatDate);
      const indexingDate = returnNAOnNull(folder.indexingDate, formatDate);
      const lineObject = {
        columnData: [
          <>
            <span style={{ whiteSpace: "nowrap" }}>
              <FontAwesomeIcon
                className="me-2"
                color={"#007bff"}
                icon={faFolder}
              />
              <TableFileName
                updateFile={updateFolder}
                updateTags={updateTags}
                file={folder}
                updateSelectedFile={(folder) => updateSelectedFolder(folder)}
                showActions={updateFolder && index === targetTableRowIndex}
                isTagFileOpen={isTagFolderOpen}
                setIsTagFileOpen={setIsTagFolderOpen}
                isFileNameBold={boldElements["name"]}
              />
            </span>
          </>,
          <span style={{ fontWeight: formatFontWeight("path") }}>
            {smartTrim(folder.path, isPathCondensed === "true" ? Math.floor(window.innerWidth / 70) : 0)}
          </span>,
          <span style={{ fontWeight: formatFontWeight("volumeName") }}>
            {folder.volumeName}
          </span>,
          <span style={{ fontWeight: formatFontWeight("size") }}>
            <FormatSize>{folder.size}</FormatSize>
          </span>,
          <span style={{ fontWeight: formatFontWeight("numberOfFiles") }}>
            {folder.numberOfFiles.toLocaleString("en-US")}
          </span>,
          <span style={{ fontWeight: formatFontWeight("numberOfFolders") }}>
            {folder.numberOfFolders.toLocaleString("en-US")}
          </span>,
          <span style={{ fontWeight: formatFontWeight("owner") }}>
            {folder.owner}
          </span>,
          <span style={{ fontWeight: formatFontWeight("group") }}>
            {folder.group}
          </span>,
          <span style={{ fontWeight: formatFontWeight("creationDate") }}>
            {creationDate}
          </span>,
          <span style={{ fontWeight: formatFontWeight("lastAccess") }}>
            {lastAccess}
          </span>,
          <span style={{ fontWeight: formatFontWeight("lastModified") }}>
            {lastModified}
          </span>,
          <span style={{ fontWeight: formatFontWeight("indexingDate") }}>
            {indexingDate}
          </span>,
          getMonthlyCostByVolume(
            volumeSettings.items,
            folder.volumeId,
            folder.size
          ),
          folder.dupe ? "Yes" : "No",
        ],
        style: {},
        options: {},
      };

      if (updateFolder) {
        lineObject.columnData.unshift(
          <TagCheckbox
            file={folder}
            selectedFiles={selectedFolders}
            setSelectedFiles={setSelectedFolders}
            selectAllDisable={useSearchResultForActions}
          />
        );
      }

      if (AddFolderComponent) {
        lineObject.columnData.unshift(<AddFolderComponent folder={folder} />);
        lineObject.options.actionColumn = true;
      }

      if (changePath) {
        lineObject.options.linkData = folder.path;
      }

      if (showFolderDetails) {
        lineObject.options.linkData = folder;
      }

      if (isRedBackground && folder.deleted) {
        lineObject.style.backgroundColor = "#f17a7a";
      }
      return lineObject;
    });

  const isDuplicateTitleName = (
    <>
      Is Duplicate{" "}
      <TableTitleTooltip target="folderDupeInfo">
        Duplicate folders On-Premises Storage are folders with the same size,
        creation date, number of files and number of folders. Duplicate folders
        on cloud storage are folders with the same duplicate files and with the
        same folder structure.
      </TableTitleTooltip>
    </>
  );

  const folderTitles = [
    { name: "Folder Name", sort: "name" },
    { name: "Path", sort: "path" },
    { name: "Volume Name", sort: "volumeName" },
    { name: "Size", sort: "size" },
    { name: "Number of Files", sort: "numberOfFiles" },
    { name: "Number of Folders", sort: "numberOfFolders" },
    { name: "Owner", sort: "owner" },
    { name: "Group", sort: "group" },
    { name: "Creation Date", sort: "creationDate" },
    { name: <DateLabelWithTooltip dateLabel={"lastAccess"} />, sort: "lastAccess" },
    { name: <DateLabelWithTooltip dateLabel={"lastModified"} />, sort: "lastModified" },
    { name: "Indexing Date", sort: "indexingDate" },
    { name: "Monthly Cost" },
    { name: isDuplicateTitleName },
  ];

  if (updateFolder) {
    folderTitles.unshift({ name: "" });
  }

  if (AddFolderComponent) {
    folderTitles.unshift({ name: "Actions" });
  }

  // Returns showFolderDetails if we are in Search, else returns changePath in FileManagement if the tag folder popover is closed
  const handleClick = () => {
    if (!isTagFolderOpen) {
      if (showFolderDetails) {
        return showFolderDetails;
      }
      if (changePath) {
        return (path) => changePath(path);
      }
    }
  };

  const selectAllFoldersFromCurrentPage = () => {
    const foldersToAdd = folders.filter(
      (folder) =>
        !selectedFolders.some(
          (selectedFolder) => selectedFolder.fileId === folder.fileId
        )
    );
    return [...selectedFolders, ...foldersToAdd];
  };

  const getTableActions = () => {
    if (AddFolderComponent) {
      return null;
    }
    return (
      <>
        <CheckboxButton
          handleSelectNone={() => {
            setSelectedFolders([]);
            setUseSearchResultForActions(false);
          }}
          handleSelectCurrentPage={() => {
            setSelectedFolders(selectAllFoldersFromCurrentPage());
            setUseSearchResultForActions(false);
          }}
          handleSelectAll={() => {
            setSelectedFolders([]);
            setUseSearchResultForActions(true);
          }}
          isSelectAllAvailable={!!showFolderDetails}
          isCurrentPageSelected={isCurrentPageSelected}
          isSelectAllSelected={useSearchResultForActions}
        />
        {updateFolder &&
          (useSearchResultForActions || selectedFolders.length > 0) && (
            <>
              <div className="ms-2">
                <TagMultipleFilesButton
                  id="applyTagMultipleFolders"
                  isMultipleTagsOpen={isApplyTagMultipleFoldersOpen}
                  deleteTag={false}
                />
                <TagMultipleFilesPopover
                  target="applyTagMultipleFolders"
                  isTagMultipleFilesOpen={isApplyTagMultipleFoldersOpen}
                  setIsTagMultipleFilesOpen={setIsApplyTagMultipleFoldersOpen}
                  selectedFiles={selectedFolders}
                  setSelectedFiles={setSelectedFolders}
                  updateFile={updateFolder}
                  updateTags={(tags) => updateTags(tags)}
                  useSearchResult={useSearchResultForActions}
                  search={search}
                  isDirectory
                  deleteTag={false}
                />
              </div>
              <div className="ms-2">
                <TagMultipleFilesButton
                  id="removeTagMultipleFolders"
                  isMultipleTagsOpen={isRemoveTagMultipleFoldersOpen}
                  deleteTag
                />
                <TagMultipleFilesPopover
                  target="removeTagMultipleFolders"
                  isTagMultipleFilesOpen={isRemoveTagMultipleFoldersOpen}
                  setIsTagMultipleFilesOpen={setIsRemoveTagMultipleFoldersOpen}
                  selectedFiles={selectedFolders}
                  setSelectedFiles={setSelectedFolders}
                  updateFile={updateFolder}
                  updateTags={(tags) => updateTags(tags)}
                  useSearchResult={useSearchResultForActions}
                  search={search}
                  isDirectory
                  deleteTag
                />
              </div>
              <HasBundle requiredBundle={ARCHIWARE_BUNDLE} hasOverlay={false}>
                <Authorization requiredAuthorizations={[ROLE_ARCHIWARE_ARCHIVE]}>
                  <div className="ms-2">
                    <ArchiveSelectionButton
                      onClickFunction={() =>
                        setShowArchiveSelectionWizard(true)
                      }
                    />
                    <ArchiveSelectionWizard
                      showWizard={showArchiveSelectionWizard}
                      setShowWizard={setShowArchiveSelectionWizard}
                      selectedFiles={selectedFolders}
                      useSearchResultForActions={useSearchResultForActions}
                      isDirectory={true}
                      total={total}
                      search={search}
                    />
                  </div>
                </Authorization>
                <Authorization requiredAuthorizations={[ROLE_ARCHIWARE_RESTORE]}>
                  <div className="ms-2">
                    <RestoreArchiveButton
                      onClickFunction={() =>
                        setShowRestoreFromArchiveWizard(true)
                      }
                    />
                    <RestoreFromArchiveWizard
                      showWizard={showRestoreFromArchiveWizard}
                      setShowWizard={setShowRestoreFromArchiveWizard}
                      selectedFiles={selectedFolders}
                      useSearchResultForActions={useSearchResultForActions}
                      isDirectory={true}
                      total={total}
                      search={search}
                    />
                  </div>
                </Authorization>
              </HasBundle>
              <HasBundle requiredBundle={SODA_BUNDLE} hasOverlay={false}>
                <Authorization requiredAuthorizations={[ROLE_SODA_TRANSFER]}>
                  <div className="ms-2">
                    <div style={{ marginLeft: "4px" }}>
                      <SodaActionButton
                        onClickFunction={() => setShowSodaActionWizard(true)}
                      />
                    </div>
                    <SodaActionWizard
                      showWizard={showSodaActionWizard}
                      setShowWizard={setShowSodaActionWizard}
                      selectedFiles={selectedFolders}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      isDirectory={true}
                    />
                  </div>
                </Authorization>
              </HasBundle>
              <HasBundle requiredBundle={STORAGE_MANAGER_BUNDLE} hasOverlay={false}>
                <Authorization requiredAuthorizations={[ROLE_STORAGE_MANAGER_ARCHIVE, ROLE_STORAGE_MANAGER_RESTORE]}>
                  <div className="ms-2">
                    <div style={{ marginLeft: "4px" }}>
                      <StorageManagerActionButton
                        onClickFunction={() => setShowStorageManagerActionWizard(true)}
                      />
                    </div>
                    <StorageManagerActionWizard
                      showWizard={showStorageManagerActionWizard}
                      setShowWizard={setShowStorageManagerActionWizard}
                      selectedFiles={selectedFolders}
                      useSearchResultForActions={useSearchResultForActions}
                      search={search}
                      isDirectory
                    />
                  </div>
                </Authorization>
              </HasBundle>
              {/* <HasBundle requiredBundle={ICONIK_BUNDLE} hasOverlay={false}>
              <div className="ms-2">
                <div style={{ marginLeft: "4px" }}>
                  <IconikActionButton
                    onClickFunction={() => {
                      setShowIconikActionWizard(true);
                    }}
                  />
                </div>
                <IconikActionWizard
                  showWizard={showIconikActionWizard}
                  setShowWizard={setShowIconikActionWizard}
                  selectedFiles={selectedFolders}
                  useSearchResultForActions={useSearchResultForActions}
                  search={search}
                  isDirectory={false}
                />
              </div> 
              </HasBundle>
              */}
            </>
          )}
      </>
    );
  };

  return (
    <>
      {isLoading || !folders ? (
        <Loading />
      ) : folders && folders.length > 0 ? (
        <div>
          <TableActions
            page={page}
            numberOfPages={Math.ceil(total / size)}
            selectPage={selectPage}
            showBadge={showNumberOfFolders}
            total={total}
            selectedFiles={selectedFolders}
            isDirectory
            useSearchResultForActions={useSearchResultForActions}
            isInModal={isInModal}
            numberOfElements={size}
            setNumberOfElements={setNumberOfElements}
          >
            {getTableActions()}
          </TableActions>
          <TableResult
            handleClick={handleClick()}
            title="Folders"
            sort={sort}
            direction={direction}
            data={listFormated}
            titles={folderTitles}
            changeOrder={changeOrder}
            setTargetTableRowIndex={(index) => {
              if (!isTagFolderOpen) {
                setTargetTableRowIndex(index);
              }
            }}
            hasSizeBaseButton
            hasCondenseButton
          />
        </div>
      ) : (
        <div>No folders found</div>
      )}
    </>
  );
}
