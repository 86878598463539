import { useEffect, useState } from "react";
import ChartCard from "../../search/components/ChartCard";
import { useNavigate } from "react-router-dom";

export default function VolumeAggregationChart(props) {
  const { latestDetailedVolume, empty, fieldName, name, title, componentId } =
    props;

  const [aggregations, setAggregations] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [sizeOfFiles, setSizeOfFiles] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true);

    if (!!latestDetailedVolume && latestDetailedVolume.statistics) {
      const volumeAggregations = [];

      for (const [key, value] of Object.entries(
        latestDetailedVolume.statistics[name]
      )) {
        volumeAggregations.push({
          field: key,
          count: value.numberOfFiles,
          size: value.sizeOfFiles,
        });
      }

      const sortedVolumeAggregations = volumeAggregations.sort(
        (a, b) => b.size - a.size
      );
      const sumOfAggregationsSize = volumeAggregations.reduce(
        (a, b) => a + b.size,
        0
      );
      const sumOfAggregationsNumberOfFiles = volumeAggregations.reduce(
        (a, b) => a + b.count,
        0
      );

      const other = {
        field: "Other",
        count:
          latestDetailedVolume.numberOfFiles - sumOfAggregationsNumberOfFiles,
        size: latestDetailedVolume.sizeOfFiles - sumOfAggregationsSize,
      };

      if (other.count > 0) {
        sortedVolumeAggregations.push(other);
      }
      setSizeOfFiles(sumOfAggregationsSize);
      setAggregations(sortedVolumeAggregations);
    }

    setIsFetching(false);
  }, []);

  const onChartClick = (index) => {
    if (aggregations[index]) {
      const field = aggregations[index].field;

      if (field === "Other" || field === empty) {
        navigate(`/search?volumeId=${latestDetailedVolume.id}`);
      } else {
        navigate(
          `/search?volumeId=${latestDetailedVolume.id}&${fieldName}=${field}`
        );
      }
    }
  };

  return (
    <ChartCard
      title={title}
      elements={aggregations}
      emptyStringValue={empty}
      addFilterFunc={onChartClick}
      isFetching={isFetching}
      defaultDataSize={25}
      componentId={componentId}
      hasDataSizePicker={false}
      sizeOfFiles={sizeOfFiles}
    />
  );
}
