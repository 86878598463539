import { combineReducers } from "redux";
import dateStatistics from "./statisticsReducer";
import dashboard from "./dashboardReducer";
import dates from "./datesReducer";
import duplicateFilesByIndex from "./duplicateFilesReducer";
import filters from "./filterReducer";
import statisticsByPath from "./pathsStatisticsReducer.js";
import volumes from "./volumeReducer.js";
import volumeVariations from "./biggestVolumeVariationsReducer";
import alert from "./alertReducer";
import error from "./errorReducer";
import alertConfigurationList from "./alertConfigurationsReducer";
// ducks quack quack
import app from "../ducks/appInfo";
import servers from "../ducks/server";
import storagePrices from "../ducks/storagePrice";
import schedules from "../ducks/schedule";
import bucketCredentials from "../ducks/bucketCredential";
import buckets from "../ducks/bucket";
import archiwareCredentials from "../ducks/archiwareCredential";
import archiwareVolumeMappings from "../ducks/archiwareVolumeMapping";
import archiwares from "../ducks/archiware";
import {
  projectById,
  projectList,
  projectPathSuggestions,
  projectHandling,
} from "../ducks/project";
import search from "../ducks/search";
import license from "../ducks/license";
import userList from "../ducks/user";
import roles from "../ducks/role";
import folderDetails from "../ducks/folderDetails";
import volumeSettings from "../ducks/volumeSettings";
import dataVolumeSettings from "../ducks/dataVolumeSettings";
import detailedVolumes from "../ducks/detailedVolumes";
import tags from "../ducks/tag";
import fileManagement from "../ducks/fileManagement";
import configurations from "../ducks/configurations";
import userSettings from "../ducks/userSettings";
import auth from "../ducks/login";

const rootReducer = combineReducers({
  fileManagement,
  dateStatistics,
  dashboard,
  dates,
  duplicateFilesByIndex,
  filters,
  statisticsByPath,
  volumes,
  volumeSettings,
  configurations,
  volumeVariations,
  auth,
  alert,
  error,
  app,
  folderDetails,
  servers,
  alertConfigurationList,
  storagePrices,
  schedules,
  bucketCredentials,
  buckets,
  archiwareCredentials,
  archiwareVolumeMappings,
  archiwares,
  projectById,
  projectList,
  projectPathSuggestions,
  projectHandling,
  search,
  license,
  userList,
  roles,
  dataVolumeSettings,
  detailedVolumes,
  tags,
  userSettings,
});

export default rootReducer;
