import { useRef } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { DATE_FORMAT_OPTIONS } from "../constants/dateFormatting";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

export default function LineChart(props) {
  const {
    lineChartRef,
    data,
    title,
    unit = "GB",
    labels,
    height,
    width,
    onClick = () => {},
    showMinMax,
    maxCapacity = 0,
    hideLabels = false,
    showBadges = true,
  } = props;

  const chartRef = lineChartRef ?? useRef();

  const minValue = data.length === 0 ? 0 : Math.min(...data);
  const maxValue = data.length === 0 ? 0 : Math.max(...data);
  const color = "#333";
  const minColor = "#007BFF";
  const maxColor = "#FF0000";
  const allDataValuesEqual = data.every((val) => val === data[0]);

  const pointColorValues = data.map((value) =>
    minValue === Number(value)
      ? minColor
      : maxValue === Number(value)
      ? maxColor
      : color
  );
  const pointRadiusValues = data.map((value) =>
    minValue === Number(value) || maxValue === Number(value) ? 5 : 0
  );

  const lineChartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        fill: false,
        lineTension: 0.1,
        backgroundColor: color,
        borderColor: color,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: pointColorValues,
        pointBackgroundColor: pointColorValues,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: pointColorValues,
        pointHoverBorderColor: pointColorValues,
        pointHoverBorderWidth: 2,
        pointRadius: pointRadiusValues,
        pointHitRadius: 5,
        data: data,
      },
    ],
  };
  const setPosition = (context) => {
    const totalValue = context.dataset.data
      .map((value) => parseInt(value))
      .reduce((prev, curr) => prev + curr);
    if (context.dataset.data.length > 0) {
      const averageValue = totalValue / context.dataset.data.length;
      return context.dataset.data[context.dataIndex] > averageValue
        ? "start"
        : "end";
    } else {
      return "end";
    }
  };

  const minLine = () => {
    if (showMinMax && !allDataValuesEqual) {
      const contentValue = showBadges ? `Min: ${minValue} ${unit}` : "";
      return {
        type: "line",
        borderColor: "rgb(128,128,128)",
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          backgroundColor: "rgba(0,0,0,0.6)",
          borderRadius: 10,
          borderWidth: 2,
          borderColor: "rgb(128,128,128)",
          content: contentValue,
          display: true,
          rotation: "auto",
        },
        scaleID: "y",
        value: minValue,
      };
    }
  };

  const maxLine = () => {
    if (showMinMax && !allDataValuesEqual) {
      const contentValue = showBadges ? `Max: ${maxValue} ${unit}` : "";
      return {
        type: "line",
        borderColor: "black",
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          backgroundColor: "rgba(0,0,0,0.8)",
          borderRadius: 10,
          borderWidth: 2,
          content: contentValue,
          display: true,
          rotation: "auto",
        },
        scaleID: "y",
        value: maxValue,
      };
    }
  };

  const redBar = () => {
    if (maxCapacity > 0 && maxValue / maxCapacity >= 0.7) {
      return {
        type: "box",
        backgroundColor: "rgba(201, 33, 33, 0.5)",
        borderColor: "rgb(201, 33, 33)",
        borderWidth: 1,
        drawTime: "beforeDatasetsDraw",
        xMax: labels[labels.length - 1],
        xMin: labels[0],
        xScaleID: "x",
        yMin: maxCapacity * 0.9,
        yMax: getMaxValueForY(),
        yScaleID: "y",
      };
    }
  };

  const options = {
    onHover: (event, chartElement) => {
      if (onClick.toString() != (() => {}).toString()) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      }
    },
    plugins: {
      legend: {
        display: !hideLabels,
      },
      annotation: {
        annotations: {
          minLine,
          maxLine,
          redBar,
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            return new Date(tooltipItem[0].label).toLocaleDateString(
              "en-CA",
              DATE_FORMAT_OPTIONS
            );
          },
        },
      },
      datalabels: {
        align: (context) => setPosition(context),
        anchor: (context) => setPosition(context),
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 18,
        color: "white",
        padding: 3,
        display: function (context) {
          if (context.dataset.data.length < 20) {
            return true;
          } else {
            const currentIndex = context.dataIndex;
            if (context.dataset.data.length < 30) {
              return currentIndex % 2 === 0 ? true : false;
            }
            if (context.dataset.data.length < 60) {
              return currentIndex % 3 === 0 ? true : false;
            }
            if (context.dataset.data.length < 80) {
              return currentIndex % 4 === 0 ? true : false;
            }
            if (context.dataset.data.length < 100) {
              return currentIndex % 5 === 0 ? true : false;
            }
            if (context.dataset.data.length < 120) {
              return currentIndex % 6 === 0 ? true : false;
            }
          }
          return false;
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            // This is a fix for the ChartJS issue with values getting converted to NaN.
            const fixedValue = value != NaN ? value : "";
            const label = this.getLabelForValue(fixedValue);
            return moment(label).format("YYYY-MM-DD");
          },
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            // This is a fix for the ChartJS issue with values getting converted to NaN.
            const fixedValue = value != NaN ? value : "";
            return this.getLabelForValue(Math.trunc(fixedValue));
          },
        },
        max: () => getMaxValueForY(),
        min: () => getMinValueForY(),
      },
    },
    layout: {
      padding: { top: 32 },
    },
  };

  const getMaxValueForY = () => {
    let maxLineValue = maxValue + (maxValue - minValue) * 0.1;

    if (allDataValuesEqual) {
      maxLineValue = maxValue + maxValue * 0.1;
    }

    if (maxValue > maxCapacity) {
      return maxLineValue;
    }

    if (maxCapacity !== 0) {
      maxLineValue = maxValue + (maxValue - minValue) * 0.4;
    }

    return maxLineValue > maxCapacity
      ? maxCapacity === 0
        ? maxLineValue
        : maxCapacity
      : maxLineValue;
  };

  const getMinValueForY = () => {
    const deltaMinValue = minValue - (maxValue - minValue) * 0.1;

    if (deltaMinValue < 0) {
      return 0;
    } else if (allDataValuesEqual) {
      return minValue - minValue * 0.1;
    }

    return deltaMinValue;
  };

  const elementIndex = (e) =>
    getElementAtEvent(chartRef.current, e).length
      ? getElementAtEvent(chartRef.current, e)[0].index
      : null;

  return (
    <Line
      ref={chartRef}
      height={height}
      width={width}
      data={lineChartData}
      options={options}
      onClick={(e) => onClick(elementIndex(e))}
    />
  );
}
