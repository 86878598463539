import { useEffect, useRef, useState } from "react";
import moment from "moment";
import LineChart from "../../components/LineChart";
import Loading from "../../components/Loading";
import PDFChartButton from "../../components/PDFChartButton";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import DatePickerForPreviousDate from "./DatePickerForPreviousDate";
import { getSameDirectoryInformationAcrossTimeV1 } from "../../api/directory";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { setDate } from "../../actions";
import { useNavigate } from "react-router";
import { formatSize } from "../../components/FormatSize";

export default function FolderSizeChart(props) {
  const { volumeId, path } = props;

  const [selectedDate, setSelectedDate] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const auth = useSelector(({ auth }) => auth);
  const activeDate = useSelector(({ dates }) => dates.activeDate);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const chartRef = useRef();

  useEffect(() => {
    if (selectedDate !== null) {
      setIsFetching(true);
      getSameDirectoryInformationAcrossTimeV1(
        dispatch,
        auth,
        volumeId,
        path,
        selectedDate,
        activeDate
      )
        .then((res) => {
          setFolders(res);
          setIsFetching(false);
        })
        .catch((e) => {
          dispatch(setAlert("Unable to get the folder through time"));
          setIsFetching(false);
        });
    }
  }, [selectedDate]);

  const getFolderItemFromReverseIndex = (reverseIndex) => {
    if (folders.length === 0) {
      return;
    }
    const index = folders.length - reverseIndex - 1;
    return folders[index];
  };

  const handleSizeChartClick = (index) => {
    if (null != index) {
      const item = getFolderItemFromReverseIndex(index);
      if (item) {
        dispatch(setDate(moment(item.indexingDate).format("YYYY-MM-DD")));
        setTimeout(
          () => navigate(`/search?volumeId=${volumeId}&startingPath=${path}`),
          200
        );
      }
    }
  };
  let filesizeObject = {};
  let sizeChart = <Loading />;
  if (!isFetching) {
    const labels = [];
    const sizes = [];

    const sum = folders.reduce((acc, folder) => acc + folder.size, 0);
    filesizeObject = formatSize(
      folders.length > 0 ? sum / folders.length : 0,
      baseSize,
      null,
      "object"
    );
    const xAxisLabels = [];
    folders.forEach((folder) => {
      const date = moment(folder.indexingDate);
      labels.unshift(date.format("lll"));
      xAxisLabels.unshift(date.format("YYYY-MM-DD"));
      sizes.unshift(
        formatSize(folder.size, baseSize, filesizeObject.exponent, "object")
          .value
      );
    });

    sizeChart = (
      <LineChart
        lineChartRef={chartRef}
        data={sizes}
        title={`Size (${filesizeObject.unit})`}
        unit={filesizeObject.unit}
        labels={labels}
        height={75}
        showMinMax
        onClick={handleSizeChartClick}
      />
    );
  }

  const title = "Size Variation";

  return (
    <Card className="mb-3">
      <CardHeader>
        <CardTitle tag="h5">
          {title}
          <div className="float-end ms-3">
            <PDFChartButton
              chartRef={chartRef}
              title={title}
              subTitle={filesizeObject.unit}
            />
          </div>
          <div className="float-end">
            <DatePickerForPreviousDate
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          Size of the folder over time
        </CardSubtitle>
      </CardHeader>
      <CardBody>{sizeChart}</CardBody>
    </Card>
  );
}
