import { useSelector } from "react-redux";
import { ROLE_ADMIN } from "../constants/roles";

export default function Authorization(props) {
  const { requiredAuthorizations = [], children } = props;
  const roles = useSelector(state => state.auth.roles);
  if (roles.includes(ROLE_ADMIN) || requiredAuthorizations.some(auth => roles.includes(auth))) {
    return children;
  }
  return null;
}
