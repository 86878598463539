import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { formatError } from "../reducers/formatError";

// === Utilities ===
function getFormattedItems(suggestions) {
  let list = [];
  suggestions.elements.forEach((suggestion) => {
    list.push({
      path: suggestion.path,
      volume: {
        id: suggestion.volumeId,
        name: suggestion.volumeName,
      },
      size: suggestion.size,
    });
  });
  return list;
}

// === Actions ===
// CREATE
const REQUEST_CREATE_PROJECT = "REQUEST_CREATE_PROJECT";
const RECEIVE_CREATE_PROJECT = "RECEIVE_CREATE_PROJECT";
// READ
const REQUEST_PROJECT = "REQUEST_PROJECT";
const RECEIVE_PROJECT = "RECEIVE_PROJECT";
const REQUEST_PROJECT_LIST = "REQUEST_PROJECT_LIST";
const RECEIVE_PROJECT_LIST = "RECEIVE_PROJECT_LIST";
const REQUEST_PROJECT_PATH_SUGGESTIONS = "REQUEST_PROJECT_PATH_SUGGESTIONS";
const RECEIVE_PROJECT_PATH_SUGGESTIONS = "RECEIVE_PROJECT_PATH_SUGGESTIONS";
// UPDATE
const REQUEST_UPDATE_PROJECT = "REQUEST_UPDATE_PROJECT";
const RECEIVE_UPDATE_PROJECT = "RECEIVE_UPDATE_PROJECT";
// DELETE
const REQUEST_DELETE_PROJECT = "REQUEST_DELETE_PROJECT";
const RECEIVE_DELETE_PROJECT = "RECEIVE_DELETE_PROJECT";
// ERROR
const RESET_PROJECT_ERRORS = "RESET_PROJECT_ERRORS";
const RECEIVE_INVALID_PROJECT = "RECEIVE_INVALID_PROJECT";

// === Reducers ===
export function projectById(state = {}, action) {
  switch (action.type) {
    case REQUEST_PROJECT:
      return Object.assign({}, state, {
        [action.id]: {
          isFetching: true,
          didInvalidate: false,
        },
      });
    case RECEIVE_PROJECT:
      return Object.assign({}, state, {
        [action.id]: {
          isFetching: false,
          didInvalidate: false,
          ...action.project,
        },
      });
    default:
      return state;
  }
}

export function projectList(state = { items: [], isFetching: false }, action) {
  switch (action.type) {
    case REQUEST_PROJECT_LIST:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_PROJECT_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
        items: action.projectList.elements,
        page: action.projectList.page,
        total: action.projectList.total,
        sort: action.sort,
        direction: action.direction,
        size: action.projectList.size,
      });
    default:
      return state;
  }
}

export function projectHandling(
  state = {
    isFetching: false,
    isValid: true,
    errors: {},
  },
  action
) {
  switch (action.type) {
    case REQUEST_CREATE_PROJECT:
    case REQUEST_UPDATE_PROJECT:
    case REQUEST_DELETE_PROJECT:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CREATE_PROJECT:
      return Object.assign({}, state, {
        id: action.id,
        isFetching: false,
        isValid: true,
        errors: {},
      });
    case RECEIVE_UPDATE_PROJECT:
    case RECEIVE_DELETE_PROJECT:
      return Object.assign({}, state, {
        isFetching: false,
        isValid: true,
        errors: {},
      });
    case RECEIVE_INVALID_PROJECT:
      return Object.assign({}, state, {
        isFetching: false,
        isValid: false,
        errors: action.errors ? formatError(action.errors) : {},
      });
    case RESET_PROJECT_ERRORS:
      return Object.assign({}, state, {
        isValid: true,
        errors: {},
      });
    default:
      return state;
  }
}

export function projectPathSuggestions(
  state = { items: [], isFetching: false },
  action
) {
  switch (action.type) {
    case REQUEST_PROJECT_PATH_SUGGESTIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_PROJECT_PATH_SUGGESTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        lastUpdated: action.receivedAt,
        items: getFormattedItems(action.suggestions),
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestProjectList() {
  return {
    type: REQUEST_PROJECT_LIST,
  };
}

function receiveProjectList(json, sort, direction) {
  return {
    type: RECEIVE_PROJECT_LIST,
    projectList: json,
    receivedAt: Date.now(),
    sort,
    direction,
  };
}

function requestProject(id) {
  return {
    type: REQUEST_PROJECT,
    id,
  };
}

function receiveProject(id, project) {
  return {
    type: RECEIVE_PROJECT,
    project,
    id,
    receivedAt: Date.now(),
  };
}

function requestCreateProject() {
  return {
    type: REQUEST_CREATE_PROJECT,
  };
}

function receiveCreateProject(id) {
  return {
    type: RECEIVE_CREATE_PROJECT,
    id,
  };
}

function requestUpdateProject() {
  return {
    type: REQUEST_UPDATE_PROJECT,
  };
}

function receiveUpdateProject() {
  return {
    type: RECEIVE_UPDATE_PROJECT,
  };
}

function requestDeleteProject() {
  return {
    type: REQUEST_DELETE_PROJECT,
  };
}

function receiveDeleteProject() {
  return {
    type: RECEIVE_DELETE_PROJECT,
  };
}

function receiveInvalidProject(errors) {
  return {
    type: RECEIVE_INVALID_PROJECT,
    errors,
  };
}

export function resetProjectErrors() {
  return {
    type: RESET_PROJECT_ERRORS,
  };
}

function requestProjectPathSuggestions(name) {
  return {
    type: REQUEST_PROJECT_PATH_SUGGESTIONS,
    name,
  };
}

function receiveProjectPathSuggestion(name, json) {
  return {
    type: RECEIVE_PROJECT_PATH_SUGGESTIONS,
    suggestions: json,
    name,
  };
}

// === Side Effects ===
export function fetchProjectList(
  page = 1,
  sort = "name",
  direction = "asc",
  size = 10
) {
  return (dispatch, getState) => {
    dispatch(requestProjectList());
    const url = `/projects?page=${page}&sort=${sort}&direction=${direction}&size=${size}`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then((json) => {
        dispatch(receiveProjectList(json, sort, direction));
      })
      .catch((err) => {
        const message = err.message || "Unable to fetch projects list";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function fetchProject(id) {
  return (dispatch, getState) => {
    dispatch(requestProject(id));
    const url = `/projects/${id}`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken)
      .then((json) => {
        dispatch(receiveProject(id, json));
      })
      .catch((err) => {
        const message = err.message || "Unable to fetch project";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function createProject(name, description, pathList, allocatedSize) {
  const body = {
    name: name,
    description: description,
    paths: pathList,
    allocatedSize,
  };
  return (dispatch, getState) => {
    const url = `/projects?indexingDate=${getState().dates.activeDate}`;
    dispatch(requestCreateProject());
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "POST",
      JSON.stringify(body)
    )
      .then((response) => {
        dispatch(receiveCreateProject(response.id));
        dispatch(
          setAlert(
            "Project created successfully, the full timeline of the project could take minutes to be available.",
            "success",
            6000
          )
        );

        setTimeout(function () {
          dispatch(fetchProjectList());
        }, 1000);
      })
      .catch((err) => {
        if (err.subErrors) dispatch(receiveInvalidProject(err));
        else {
          dispatch(receiveInvalidProject());
          const message = err.message || "Unable to create project";
          dispatch(setAlert(message, "danger"));
        }
      });
  };
}

export function updateProject(id, name, description, pathList, allocatedSize) {
  const body = {
    id: id,
    name: name,
    description: description,
    paths: pathList,
    allocatedSize,
  };
  return (dispatch, getState) => {
    dispatch(requestUpdateProject());
    const url = `/projects?indexingDate=${getState().dates.activeDate}`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then(() => {
        setTimeout(function () {
          dispatch(fetchProjectList());
          dispatch(receiveUpdateProject());
          dispatch(
            setAlert(
              "Project updated successfully, the full timeline of the project could take minutes to be updated.",
              "success",
              6000
            )
          );
        }, 1000);
      })
      .catch((err) => {
        if (err.subErrors) dispatch(receiveInvalidProject(err));
        else {
          dispatch(receiveInvalidProject());
          const message = err.message || "Unable to update project";
          dispatch(setAlert(message, "danger"));
        }
      });
  };
}

export function removeProject(id) {
  return (dispatch, getState) => {
    dispatch(requestDeleteProject());
    const url = `/projects/${id}?indexingDate=${getState().dates.activeDate}`;
    return fetchWithAuth(dispatch, url, getState().auth.accessToken, "DELETE")
      .then(() => {
        setTimeout(function () {
          dispatch(receiveDeleteProject());
          dispatch(setAlert("Project deleted successfully", "success"));
        }, 1000);
      })
      .catch((err) => {
        dispatch(receiveInvalidProject());
        const message = err.message || "Unable to delete project";
        dispatch(setAlert(message, "danger"));
      });
  };
}

export function searchProjetPaths(
  name,
  page = 1,
  sort = "name",
  direction = "DESC",
  size = 1000
) {
  const searchBody = JSON.stringify([
    { name: { startingWith: name }, isDirectory: true, isDeleted: false },
  ]);

  return (dispatch, getState) => {
    dispatch(requestProjectPathSuggestions(name));
    const url = `/v1/search?indexingDate=${
      getState().dates.activeDate
    }&page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "POST",
      searchBody
    ).then((json) => {
      dispatch(receiveProjectPathSuggestion(name, json));
    }).catch(err => console.error(err));
  };
}
