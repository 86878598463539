// returns dd:hh:mm:ss format
export function getFormattedTime(milliseconds) {
    if (isNaN(milliseconds)) return "N/A";

    if (1000 > milliseconds) {
        return `${milliseconds} millisecond${1 < milliseconds ? "s" : ""}`;
    }
    let days, hours, minutes, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    days = Math.floor(hours / 24);
    hours = hours % 24;

    const formattedDays = `${days === 0 ? "" : `${days} day${days > 1 ? "s" : ""}`}`;
    const formattedHours = `${hours === 0 ? "" : `${hours} hour${hours > 1 ? "s" : ""}`}`;
    const formattedMinutes = `${minutes === 0 ? "" : `${minutes} minute${minutes > 1 ? "s" : ""}`}`;
    const formattedSeconds = `${seconds === 0 ? "" : `${seconds} second${seconds > 1 ? "s" : ""}`}`;

    const daysHoursMinutesSecondsFormat = `${formattedDays} ${formattedHours} ${formattedMinutes} ${formattedSeconds}`;

    return daysHoursMinutesSecondsFormat;
}