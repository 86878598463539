import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  faChevronDown,
  faChevronRight,
  faFolderClosed,
  faFolderOpen,
  faSitemap,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDeltaFiles, getFilesOfDirectory } from "../../api/file";
import { getDeltaFolders, getDirectories } from "../../api/directory";
import FileIcon from "../FileIcon";
import TreeNode from "./TreeNode";
import LoadMoreNode from "./LoadMoreNode";
import {
  getFormattedDeltaFiles,
  getChildNodeFromTreeNodes,
  getTreeNodeChildrenStyle,
} from "./FolderTree";
import { getVolumeIcon } from "../../utils/getVolumeIcon";

export default function FolderNode(props) {
  const {
    getCheckedFile,
    folder,
    filterString = "",
    previousIndexingDate,
    isOpenable = true,
    isDefaultOpen = false,
    hasCheckboxes,
    hasRowBackground = true,
    hasFiles,
    hasActions,
    showOnlyFirstColumn = false,
    volumeType,
    isProject,
    onNameClickFile,
    onNameClickFolder,
    onNameDoubleClickFile,
    onNameDoubleClickFolder,
    onCheckboxClick,
    fileFetchSize,
    folderFetchSize,
    valueToMarge = 1,
    treeNodes,
    setTreeNodes,
    positionInTree,
    isTagFileOpen,
    setIsTagFileOpen,
    isFound = false,
    getIsFound,
    isSelectAllVisibleTriggered,
    setIsSelectAllVisibleTriggered,
    isSelectNoneTriggered,
    setIsSelectNoneTriggered,
    sort,
    direction,
    isShowHiddenFolders,
    dynamicColumnTitle,
    isRootFolderNode = false,
    setShownFileDetails,
    showLastColumn,
    isOdd = true,
  } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [currentFileFetchPage, setCurrentFileFetchPage] = useState(1);
  const [currentFolderFetchPage, setCurrentFolderFetchPage] = useState(1);
  const [isFetchingNextPageForFiles, setIsFetchingNextPageForFiles] =
    useState(false);
  const [isFetchingNextPageForFolders, setIsFetchingNextPageForFolders] =
    useState(false);

  const checkboxRef = useRef();
  const isMounted = useRef(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);

  useEffect(() => {
    if (isOpen && !hasFetched) {
      fetchFolderContent();
    }
  }, [isOpen, hasFetched]);

  useEffect(() => {
    if (isMounted.current && isRootFolderNode) {
      fetchFolderContent(getCheckedFile(folder, isRootFolderNode));
    } else {
      isMounted.current = true;
    }
  }, [sort, direction]);

  const getChildFiles = (isDirectory) => {
    const currentNode = getChildNodeFromTreeNodes(treeNodes, positionInTree);
    return currentNode.children
      .filter((child) => isDirectory === child.file.isDirectory)
      .map((fileChild) => fileChild.file);
  };

  const getSort = () => {
    if (sort === "monthlyCost") {
      return "size";
    }

    return sort;
  };

  const fetchFolderContent = (isChecked) => {
    const fetchFunctions = [];
    let resultChildFolders = [];
    let resultChildFiles = [];
    setIsFetching(true);

    let directoryFunction;
    let fileFunction;

    if (dynamicColumnTitle === "deltaChartColumn") {
      directoryFunction = getDeltaFolders(
        dispatch,
        auth,
        indexingDate,
        previousIndexingDate,
        folder.path,
        folder.volumeId,
        1,
        folderFetchSize,
        getSort(),
        direction,
        isShowHiddenFolders
      )
        .then(
          (res) => (resultChildFolders = getFormattedDeltaFiles(res, [folder]))
        )
        .catch((err) => console.error(err));

      fileFunction = getDeltaFiles(
        dispatch,
        auth,
        indexingDate,
        previousIndexingDate,
        folder.path,
        folder.volumeId,
        1,
        fileFetchSize,
        getSort(),
        direction
      )
        .then((res) => (resultChildFiles = getFormattedDeltaFiles(res)))
        .catch((err) => console.error(err));
    } else {
      directoryFunction = getDirectories(
        dispatch,
        auth,
        indexingDate,
        folder.path,
        folder.volumeId,
        getSort(),
        direction,
        1,
        folderFetchSize,
        isShowHiddenFolders
      )
        .then((res) => (resultChildFolders = res.elements))
        .catch((err) => console.error(err));

      if (hasFiles) {
        fileFunction = getFilesOfDirectory(
          dispatch,
          auth,
          indexingDate,
          folder.path,
          folder.volumeId,
          getSort(),
          direction,
          1,
          fileFetchSize
        )
          .then((res) => (resultChildFiles = res.elements))
          .catch((err) => console.error(err));
      }
    }
    fetchFunctions.push(directoryFunction);
    if (hasFiles) {
      fetchFunctions.push(fileFunction);
    }

    Promise.all(fetchFunctions).then(() => {
      setTreeNodes((prev) =>
        getFetchedChildren(prev, [
          ...getFormattedChildren(resultChildFolders),
          ...getFormattedChildren(resultChildFiles),
        ])
      );
      setIsFetching(false);
      setHasFetched(true);
    });
  };

  const fetchNextPageForFolders = (page) => {
    setIsFetchingNextPageForFolders(true);

    if (dynamicColumnTitle === "deltaChartColumn") {
      getDeltaFolders(
        dispatch,
        auth,
        indexingDate,
        previousIndexingDate,
        folder.path,
        folder.volumeId,
        page,
        folderFetchSize,
        getSort(),
        direction,
        isShowHiddenFolders
      )
        .then((res) => {
          const ignoredChildFolders = getChildFiles(true);
          ignoredChildFolders.push(folder);

          const resultChildFolders = getFormattedDeltaFiles(
            res,
            ignoredChildFolders
          );

          setCurrentFolderFetchPage(page);
          setTreeNodes((prev) =>
            getFetchedChildren(
              prev,
              getFormattedChildren([
                ...getChildFiles(true),
                ...resultChildFolders,
                ...getChildFiles(false),
              ])
            )
          );
          setIsFetchingNextPageForFolders(false);
        })
        .catch((err) => console.error(err));
    } else {
      getDirectories(
        dispatch,
        auth,
        indexingDate,
        folder.path,
        folder.volumeId,
        getSort(),
        direction,
        page,
        folderFetchSize,
        isShowHiddenFolders
      )
        .then((res) => {
          const resultChildFolders = res.elements;
          setCurrentFolderFetchPage(page);
          setTreeNodes((prev) =>
            getFetchedChildren(
              prev,
              getFormattedChildren([
                ...getChildFiles(true),
                ...resultChildFolders,
                ...getChildFiles(false),
              ])
            )
          );
          setIsFetchingNextPageForFolders(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const fetchNextPageForFiles = (page) => {
    setIsFetchingNextPageForFiles(true);

    if (dynamicColumnTitle === "deltaChartColumn") {
      getDeltaFiles(
        dispatch,
        auth,
        indexingDate,
        previousIndexingDate,
        folder.path,
        folder.volumeId,
        page,
        fileFetchSize,
        getSort(),
        direction
      )
        .then((res) => {
          const resultChildFiles = getFormattedDeltaFiles(
            res,
            getChildFiles(false)
          );

          setCurrentFileFetchPage(page);
          setTreeNodes((prev) =>
            getFetchedChildren(
              prev,
              getFormattedChildren([
                ...getChildFiles(true),
                ...getChildFiles(false),
                ...resultChildFiles,
              ])
            )
          );
          setIsFetchingNextPageForFiles(false);
        })
        .catch((err) => console.error(err));
    } else {
      getFilesOfDirectory(
        dispatch,
        auth,
        indexingDate,
        folder.path,
        folder.volumeId,
        getSort(),
        direction,
        page,
        fileFetchSize
      )
        .then((res) => {
          const resultChildFiles = res.elements;
          setCurrentFileFetchPage(page);
          setTreeNodes((prev) =>
            getFetchedChildren(
              prev,
              getFormattedChildren([
                ...getChildFiles(true),
                ...getChildFiles(false),
                ...resultChildFiles,
              ])
            )
          );
          setIsFetchingNextPageForFiles(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const getFormattedChildren = (children) => {
    if (sort === "size") {
      children.sort((prev, curr) =>
        direction === "asc" ? prev.size - curr.size : curr.size - prev.size
      );
    }

    return children.map((child) => {
      return {
        file: child,
        isChecked: getCheckedFile(child, false),
        children: [],
      };
    });
  };

  const getFetchedChildren = (children, updatedChildren, index = 0) => {
    return children.map((child, i) => {
      if (i === positionInTree[index]) {
        if (index === positionInTree.length - 1) {
          child.children = updatedChildren;
        } else {
          child.children = getFetchedChildren(
            child.children,
            updatedChildren,
            index + 1
          );
        }
      }
      return child;
    });
  };

  const getIsOddValueForFiles = (childFoldersLength) => {
    return childFoldersLength === folderFetchSize * currentFolderFetchPage
      ? childFoldersLength + 1
      : childFoldersLength;
  };

  const getChildrenNodes = () => {
    const childFolders = getChildFiles(true);
    const childFiles = getChildFiles(false);

    return hasFetched ? (
      <div hidden={!isOpen}>
        {childFolders.map((childFolder, index) => {
          return (
            <FolderNode
              getCheckedFile={getCheckedFile}
              key={childFolder.path + "-" + index}
              folder={childFolder}
              filterString={filterString}
              isOpenable={isOpenable}
              previousIndexingDate={previousIndexingDate}
              showOnlyFirstColumn={showOnlyFirstColumn}
              hasCheckboxes={hasCheckboxes}
              hasRowBackground={hasRowBackground}
              hasFiles={hasFiles}
              hasActions={hasActions}
              onNameClickFile={onNameClickFile}
              onNameClickFolder={onNameClickFolder}
              onNameDoubleClickFile={onNameDoubleClickFile}
              onNameDoubleClickFolder={onNameDoubleClickFolder}
              onCheckboxClick={onCheckboxClick}
              fileFetchSize={fileFetchSize}
              folderFetchSize={folderFetchSize}
              valueToMarge={valueToMarge + 1}
              treeNodes={treeNodes}
              setTreeNodes={setTreeNodes}
              positionInTree={[...positionInTree, index]}
              isTagFileOpen={isTagFileOpen}
              setIsTagFileOpen={setIsTagFileOpen}
              isFound={getIsFound(childFolder.name, childFolder.path)}
              getIsFound={getIsFound}
              isSelectAllVisibleTriggered={
                isOpen && isSelectAllVisibleTriggered
              }
              setIsSelectAllVisibleTriggered={setIsSelectAllVisibleTriggered}
              isSelectNoneTriggered={isSelectNoneTriggered}
              setIsSelectNoneTriggered={setIsSelectNoneTriggered}
              sort={sort}
              direction={direction}
              isShowHiddenFolders={isShowHiddenFolders}
              dynamicColumnTitle={dynamicColumnTitle}
              setShownFileDetails={setShownFileDetails}
              showLastColumn={showLastColumn}
              isOdd={isOdd ? Boolean(index % 2) : Boolean((index + 1) % 2)}
            />
          );
        })}
        <LoadMoreNode
          numberOfChildren={childFolders.length}
          sizeToFetch={folderFetchSize}
          currentPage={currentFolderFetchPage}
          valueToMarge={valueToMarge + 1.8}
          isOdd={
            isOdd
              ? (folderFetchSize * currentFolderFetchPage) % 2
              : (folderFetchSize * currentFolderFetchPage + 1) % 2
          }
          loadMoreFunction={() =>
            fetchNextPageForFolders(currentFolderFetchPage + 1)
          }
          isFetching={isFetchingNextPageForFolders}
        />
        {childFiles.map((childFile, index) => {
          const childFilePositionInTree = [
            ...positionInTree,
            index + childFolders.length,
          ];

          return (
            <TreeNode
              key={childFile.path + "-" + index}
              file={childFile}
              hasCheckboxes={hasCheckboxes}
              hasActions={hasActions}
              hasRowBackground={hasRowBackground}
              showOnlyFirstColumn={showOnlyFirstColumn}
              valueToMarge={valueToMarge + 1}
              treeNodes={treeNodes}
              setTreeNodes={setTreeNodes}
              positionInTree={childFilePositionInTree}
              isTagFileOpen={isTagFileOpen}
              setIsTagFileOpen={setIsTagFileOpen}
              isFound={getIsFound(childFile.name, childFile.path)}
              onCheckboxClick={onCheckboxClick}
              isSelectAllVisibleTriggered={
                isOpen && isSelectAllVisibleTriggered
              }
              setIsSelectAllVisibleTriggered={setIsSelectAllVisibleTriggered}
              isSelectNoneTriggered={isSelectNoneTriggered}
              setIsSelectNoneTriggered={setIsSelectNoneTriggered}
              dynamicColumnTitle={dynamicColumnTitle}
              setShownFileDetails={setShownFileDetails}
              showLastColumn={showLastColumn}
              isOdd={
                isOdd
                  ? Boolean(
                      (index + getIsOddValueForFiles(childFolders.length)) % 2
                    )
                  : Boolean(
                      (index + getIsOddValueForFiles(childFolders.length + 1)) %
                        2
                    )
              }
            >
              <div style={getTreeNodeChildrenStyle(childFile)}>
                <FileIcon extension={childFile.extension} />
                <span
                  onDoubleClick={() =>
                    onNameDoubleClickFile(childFile, childFilePositionInTree)
                  }
                  onClick={() => {
                    if (onNameClickFile !== null) {
                      onNameClickFile(childFile);
                    }
                  }}
                >
                  <span id={"id" + childFile.fileId}>{childFile.name}</span>
                </span>
              </div>
            </TreeNode>
          );
        })}
        <LoadMoreNode
          numberOfChildren={childFiles.length}
          sizeToFetch={fileFetchSize}
          currentPage={currentFileFetchPage}
          valueToMarge={valueToMarge + 1.8}
          isOdd={
            isOdd
              ? (fileFetchSize * currentFileFetchPage +
                  getIsOddValueForFiles(childFolders.length)) %
                2
              : (fileFetchSize * currentFileFetchPage +
                  getIsOddValueForFiles(childFolders.length + 1)) %
                2
          }
          loadMoreFunction={() =>
            fetchNextPageForFiles(currentFileFetchPage + 1)
          }
          isFetching={isFetchingNextPageForFiles}
        />
      </div>
    ) : null;
  };

  const getIconForFolderNode = () => {
    if (isFetching) {
      return faSpinner;
    } else if (isOpen) {
      return faFolderOpen;
    } else if (volumeType && folder.path === "/") {
      return getVolumeIcon(volumeType);
    } else if (isProject) {
      return faSitemap;
    }
    return faFolderClosed;
  };

  const getColorForFolderNodeIcon = (folder) => {
    if (folder.isCreated) {
      return "#375C82";
    } else if (folder.isRemoved) {
      return "#8BA6C1";
    }

    return "#007bff";
  };

  return (
    <>
      <TreeNode
        file={folder}
        hasCheckboxes={hasCheckboxes}
        hasActions={hasActions}
        hasRowBackground={hasRowBackground}
        showOnlyFirstColumn={showOnlyFirstColumn}
        valueToMarge={valueToMarge}
        treeNodes={treeNodes}
        setTreeNodes={setTreeNodes}
        positionInTree={positionInTree}
        isTagFileOpen={isTagFileOpen}
        setIsTagFileOpen={setIsTagFileOpen}
        isFound={isFound}
        onCheckboxClick={onCheckboxClick}
        isSelectAllVisibleTriggered={isSelectAllVisibleTriggered}
        setIsSelectAllVisibleTriggered={setIsSelectAllVisibleTriggered}
        isSelectNoneTriggered={isSelectNoneTriggered}
        setIsSelectNoneTriggered={setIsSelectNoneTriggered}
        setShownFileDetails={setShownFileDetails}
        showLastColumn={showLastColumn}
        isOdd={isOdd}
        dynamicColumnTitle={dynamicColumnTitle}
        checkboxRef={checkboxRef}
      >
        <div style={getTreeNodeChildrenStyle(folder)}>
          {isOpenable && (
            <FontAwesomeIcon
              icon={isOpen ? faChevronDown : faChevronRight}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          <span
            onClick={() => {
              if (onNameClickFolder === null) {
                setIsOpen(!isOpen);
              } else {
                onNameClickFolder(folder);
              }
            }}
            onDoubleClick={() => onNameDoubleClickFolder(folder)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              color={getColorForFolderNodeIcon(folder)}
              icon={getIconForFolderNode()}
              className={`mx-2 ${isFetching ? "fa-spin" : ""}`}
            />

            <span id={"id" + folder.fileId}>
              {folder.name === "" ? folder.volumeName : folder.name}
            </span>
          </span>
        </div>
      </TreeNode>
      {!isFetching && getChildrenNodes()}
    </>
  );
}
