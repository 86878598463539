import { useState } from "react";
import PDFPreview from "./PDFPreview";
import DownloadPDFDocument from "./DownloadPDFDocument";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "./ConfirmModal";

export default function PDFChartButton(props) {
  const {
    chartRef,
    title = "",
    subTitle = "",
    deltaInfo = "",
    timeUnit,
    sizeItems,
    sizeOfFiles,
    sizeShownData,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [base64Image, setBase64Image] = useState();

  return (
    <>
      <Button
        id="pdfChart"
        size="sm"
        color="danger"
        outline
        onClick={() => {
          setBase64Image(chartRef.current.toBase64Image());
          setShowCreateModal(true);
        }}
      >
        <FontAwesomeIcon icon={faFilePdf} />
      </Button>
      <UncontrolledTooltip placement="auto" target="pdfChart">
        Preview PDF
      </UncontrolledTooltip>
      <ConfirmModal
        showConfirmation={showCreateModal}
        title="PDF Preview"
        size="xl"
        customButton={
          <DownloadPDFDocument
            data={base64Image}
            action={() => {
              setShowCreateModal(false);
            }}
            title={title}
            subTitle={subTitle}
            deltaInfo={deltaInfo}
            timeUnit={timeUnit}
            sizeItems={sizeItems}
            sizeOfFiles={sizeOfFiles}
            sizeShownData={sizeShownData}
          />
        }
        closeConfirmation={() => {
          setShowCreateModal(false);
        }}
      >
        <PDFPreview
          data={base64Image}
          title={title}
          subTitle={subTitle}
          deltaInfo={deltaInfo}
          timeUnit={timeUnit}
          sizeItems={sizeItems}
          sizeOfFiles={sizeOfFiles}
          sizeShownData={sizeShownData}
        />
      </ConfirmModal>
    </>
  );
}
