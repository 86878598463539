import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers";

export default function configureStore(preloadedState) {
  let store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk)
  );
  if (process.env.NODE_ENV === "development") {
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      rootReducer,
      preloadedState,
      composeEnhancers(applyMiddleware(thunk))
    );
  }
  return store;
}

export const store = configureStore();