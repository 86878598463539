import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WizardModal from "../../components/WizardModal";
import { useDispatch } from "react-redux";
import { fetchArchiwareList } from "../../ducks/archiware";
import { fetchBucketList } from "../../ducks/bucket";
import ArchiveSelectionStep1 from "./SelectArchiwarePlanStep";
import ArchiveSelectionStep2 from "./SelectArchiwareClientStep";
import SearchSelectionReview from "./SearchSelectionReview";
import ManualSelectionReview from "./ManualSelectionReview";
import { setAlert } from "../../actions/alert";
import { getServers } from "../../api/server";
import {
  archiveArchiwareFiles,
  archiveArchiwareSearchResult,
} from "../../api/archiware";

export default function ArchiveSelectionWizard({
  showWizard,
  setShowWizard,
  selectedFiles,
  useSearchResultForActions,
  isDirectory = false,
  search = "",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const indexingDate = useSelector((state) => state.dates.activeDate);
  const searchFilters = useSelector((state) => state.search.filters);
  const previousPlanId = useRef("");
  const previousServerId = useRef("");
  const [allArchiwareClients, setAllArchiwareClients] = useState([]);
  const [allServers, setAllServers] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const auth = useSelector(({ auth }) => auth);
  const [disableForwardActionButton, setDisableForwardActionButton] =
    useState(false);
  const [archiwarePlans, setArchiwarePlans] = useState([]);
  const [archiwareClients, setArchiwareClients] = useState([]);

  const emptyUserInputObj = {
    indexName: "",
    planId: "",
    planName: "",
    clientId: "",
    serverId: "",
    credentialsId: "",
    location: "",
    deltaScanAfter: false,
  };
  const [userInputObj, setUserInputObj] = useState(emptyUserInputObj);
  const [isSpecifyLocationChecked, setIsSpecifyLocationChecked] =
    useState(false);

  useEffect(() => {
    getAllServers();
    dispatch(fetchBucketList());
    dispatch(fetchArchiwareList());
  }, []);

  const parseFileDeletionMethod = () => {
    if (userInputObj.deleteFiles && userInputObj.deleteAll) {
      return "Delete files and folders";
    } else if (!userInputObj.deleteFiles && !userInputObj.deleteAll) {
      return "Do not delete";
    } else {
      return "Delete files only";
    }
  };

  const getAllServers = async () => {
    try {
      const servers = await getServers(dispatch, auth);
      setAllServers(servers);
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert(
          "Something went wrong while trying to get servers. Please try again.",
          "danger",
          9000
        )
      );
    }
  };
  // The function checks if value is an array for the first time the user is at step 2 of the wizard to set the default selection values
  // Then value will be a planId string for the onChange function at step 2
  const setPlan = (value) => {
    if (value.constructor === Array) {
      setUserInputObj((prev) => ({
        ...prev,
        planId: value[0].planId,
        planName: value[0].planName,
        indexName: value[0].index,
        deleteFiles: value[0].deleteFiles,
        serverId: value[0].server.id,
        credentialsId: value[0].credentialsId,
        deleteAll: value[0].deleteAll,
        storageDestination: value[0].storage,
      }));
    } else {
      var objPlan = value.split(",", 2);
      const thisArchiwarePlans = archiwarePlans.find((plan) => {
        if (
          plan.planId === objPlan[0] &&
          plan.server.id === parseInt(objPlan[1])
        )
          return plan;
      });
      setUserInputObj((prev) => ({
        ...prev,
        planId: thisArchiwarePlans ? thisArchiwarePlans.planId : "N/A",
        planName: thisArchiwarePlans ? thisArchiwarePlans.planName : "N/A",
        deleteFiles: thisArchiwarePlans
          ? thisArchiwarePlans.deleteFiles
          : "N/A",
        deleteAll: thisArchiwarePlans ? thisArchiwarePlans.deleteAll : "N/A",
        storageDestination: thisArchiwarePlans
          ? thisArchiwarePlans.storage
          : "N/A",
        serverId: thisArchiwarePlans ? thisArchiwarePlans.server.id : "N/A",
        credentialsId: thisArchiwarePlans
          ? thisArchiwarePlans.credentialsId
          : "N/A",
        indexName: thisArchiwarePlans ? thisArchiwarePlans.index : "N/A",
      }));
    }
  };
  const setClient = (value) => {
    if (value.constructor === Array) {
      setUserInputObj((prev) => ({
        ...prev,
        clientId: value[0].clientName,
      }));
    } else {
      var objClient = value.split(",", 2);
      setUserInputObj((prev) => ({
        ...prev,
        clientId: objClient[0],
      }));
    }
  };

  const setLocation = (value) => {
    setUserInputObj((prev) => ({
      ...prev,
      location: value,
    }));
  };

  const setDeltaScanAfter = (value) => {
    setUserInputObj((prev) => ({
      ...prev,
      deltaScanAfter: value,
    }));
  };

  const STEP_TITLES = [
    "Select Archive P5 Plan",
    "Select Archive P5 Client",
    "Review",
  ];

  const steps = [
    <ArchiveSelectionStep1
      userInputObj={userInputObj}
      setDisableForwardActionButton={setDisableForwardActionButton}
      archiwarePlans={archiwarePlans}
      setArchiwarePlans={setArchiwarePlans}
      setPlan={setPlan}
      location={userInputObj.location}
      setLocation={setLocation}
      isSpecifyLocationChecked={isSpecifyLocationChecked}
      setIsSpecifyLocationChecked={setIsSpecifyLocationChecked}
      parseFileDeletionMethod={parseFileDeletionMethod}
      selectedFiles={selectedFiles}
      useSearchResultForActions={useSearchResultForActions}
      fileTypesToOnlyInclude={[
        "crawl.files",
        "NetworkVolume",
        "DellPowerScale",
      ]}
      isDirectory={isDirectory}
      allServers={allServers}
    />,
    <ArchiveSelectionStep2
      userInputObj={userInputObj}
      setClient={setClient}
      setDisableForwardActionButton={setDisableForwardActionButton}
      archiwareClients={archiwareClients}
      setArchiwareClients={setArchiwareClients}
      previousPlanId={previousPlanId}
      previousServerId={previousServerId}
      allArchiwareClients={allArchiwareClients}
      setAllArchiwareClients={setAllArchiwareClients}
    />,
    useSearchResultForActions ? (
      <SearchSelectionReview
        userInputObj={userInputObj}
        parseFileDeletionMethod={parseFileDeletionMethod}
        isDirectory={isDirectory}
        setDisableForwardActionButton={setDisableForwardActionButton}
        searchText={search}
        setDeltaScanAfter={setDeltaScanAfter}
      />
    ) : (
      <ManualSelectionReview
        userInputObj={userInputObj}
        parseFileDeletionMethod={parseFileDeletionMethod}
        isDirectory={isDirectory}
        setDisableForwardActionButton={setDisableForwardActionButton}
        selectedFiles={selectedFiles}
        setDeltaScanAfter={setDeltaScanAfter}
      />
    ),
  ];
  const STEPS_LENGTH = steps.length;

  const onWizardExit = () => {
    setUserInputObj(emptyUserInputObj);
    setArchiwarePlans([]);
    setArchiwareClients([]);
    setDisableForwardActionButton(false);
    setShowWizard(false);
    setStepIndex(0);
    setIsSpecifyLocationChecked(false);
  };

  const resolvedPromiseActions = (res) => {
    dispatch(
      setAlert(
        <>
          A job for archiving the selection is now running. You can monitor the
          job by clicking
          <a
            className="text-primary"
            onClick={() => navigate("/settings/jobs")}
          >
            {" "}
            here
          </a>
        </>,
        "success",
        9000
      )
    );

    onWizardExit();
  };

  const rejectedPromiseActions = (err) => {
    console.log(err);
    dispatch(
      setAlert(
        "Something went wrong while trying to start the archive job. Please try again.",
        "danger",
        9000
      )
    );
    onWizardExit();
  };

  const archiveSelection = () => {
    if (useSearchResultForActions) {
      archiveArchiwareSearchResult(
        dispatch,
        auth,
        userInputObj.planId,
        userInputObj.clientId,
        userInputObj.serverId,
        userInputObj.credentialsId,
        userInputObj.location,
        indexingDate,
        isDirectory,
        search,
        searchFilters,
        userInputObj.deltaScanAfter
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    } else {
      archiveArchiwareFiles(
        dispatch,
        auth,
        userInputObj.planId,
        userInputObj.clientId,
        userInputObj.serverId,
        userInputObj.credentialsId,
        userInputObj.location,
        selectedFiles,
        userInputObj.deltaScanAfter
      )
        .then((res) => resolvedPromiseActions(res))
        .catch((err) => rejectedPromiseActions(err));
    }
  };

  const navigateSteps = (event) => {
    switch (event.target.innerText) {
      case "Cancel":
        onWizardExit();
        break;

      case "Back":
        if (stepIndex > 0) setStepIndex((prev) => --prev);
        break;

      case "Next":
        if (stepIndex < STEPS_LENGTH - 1) setStepIndex((prev) => ++prev);
        break;

      case "Archive":
        setDisableForwardActionButton(true);
        archiveSelection();
        break;

      default:
        break;
    }
  };

  const handleLeftButtonName = () => {
    if (stepIndex === 0) {
      return "Cancel";
    } else {
      return "Back";
    }
  };

  const handleRightButtonName = () => {
    if (stepIndex === STEPS_LENGTH - 1) {
      return "Archive";
    } else {
      return "Next";
    }
  };

  return (
    <WizardModal
      showWizard={showWizard}
      title="Archive with Archiware P5"
      subTitle={STEP_TITLES[stepIndex]}
      onWizardExit={onWizardExit}
      stepsLength={STEPS_LENGTH}
      stepIndex={stepIndex + 1}
      leftButtonName={handleLeftButtonName()}
      rightButtonName={handleRightButtonName()}
      navigateSteps={navigateSteps}
      disableNextStep={disableForwardActionButton}
    >
      {steps[stepIndex]}
    </WizardModal>
  );
}
