import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDellPowerScaleCredentials, getDellPowerScaleCredentials, updateDellPowerScaleCredentials } from "../../../api/dellPowerScale";
import { setAlert } from "../../../actions/alert";
import ConfirmModal from "../../../components/ConfirmModal";
import { Col, Form, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function DellPowerScaleCredentialForm(props) {
  const {
    isOpen,
    handleConfirmationFunction,
    handleClose,
    id
  } = props;

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (isUpdate) {
      getDellPowerScaleCredentials(dispatch, auth, id)
        .then((res) => {
          setName(res.name);
          setUsername(res.username)
        })
        .catch((err) => {
          console.error(err);
          dispatch(setAlert("Unable to get Dell PowerScale credentials", "danger", 8000));
        });
    }
  }, [id]);

  useEffect(() => {
    if (
      name.length === 0 || name.length > 255 || 
      username.length === 0 || username.length > 255 || 
      password.length === 0 || password.length > 255
    ) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, [name, username, password]);

  const isUpdate = id !== null && id !== undefined;

  const handleConfirmation = () => {
    const dellPowerScaleCredentials = {
      name,
      username,
      password
    };

    if (isUpdate) {
      updateDellPowerScaleCredentials(dispatch, auth, id, dellPowerScaleCredentials)
        .then(() => {
          handleConfirmationFunction();
          onClose();
        })
        .catch((err) => {
          console.error(err);
          dispatch(setAlert("Unable to update Dell PowerScale credentials", "danger", 8000));
        });
    } else {
      createDellPowerScaleCredentials(dispatch, auth, dellPowerScaleCredentials)
        .then(() => {
          handleConfirmationFunction();
          onClose();
        })
        .catch((err) => {
          console.error(err);
          dispatch(setAlert("Unable to create Dell PowerScale credentials", "danger", 8000));
        });
    }
  };

  const onClose = () => {
    setName("");
    setUsername("");
    setPassword("");
    handleClose();
  };

  return (
    <ConfirmModal
      showConfirmation={isOpen}
      title={`${isUpdate ? "Update" : "Create"} Dell PowerScale Credentials`}
      buttonColor="primary"
      buttonText={`${isUpdate ? "Update" : "Create"}`}
      confirmAction={() => handleConfirmation()}
      closeConfirmation={() => onClose()}
      disableRightButton={isConfirmButtonDisabled}
    >
      <Form>
        <FormGroup row>
          <Label for="name" sm={4}>
            Name
            <FontAwesomeIcon
              id="nameLabel"
              className="ms-sm-1"
              icon={faQuestionCircle}
            />
            <UncontrolledTooltip placement="auto" target="nameLabel">
              User readable name for the Dell PowerScale credentials
            </UncontrolledTooltip>
          </Label>
          <Col sm={8}>
            <Input
              id="name"
              name="name"
              type="text"
              placeholder="Ex: name"
              value={name}
              onChange={e => {
                const value = e.target.value;
                setName(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="username" sm={4}>
            Username
          </Label>
          <Col sm={8}>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Ex: username"
              value={username}
              onChange={e => {
                const value = e.target.value;
                setUsername(value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="password" sm={4}>
            Password
          </Label>
          <Col sm={8}>
            <Input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={e => {
                const value = e.target.value;
                setPassword(value);
              }}
            />
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}