import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";
import { getSearchRequestParamsV2 } from "../ducks/search";

export const searchFiles = async (dispatch, auth, indexingDate, search = "", page = 1, size = 25, filters = {}, sort = "size", direction = "desc") => {
    const url = "/files/search";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
        search,
        page,
        size,
        sort: `${sort},${direction}`,
        indexingDate
    };
    
    const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, { ...queryParams, ...searchParams });

    return result;
}

export const getSizeOfSearchedFiles = async (dispatch, auth, indexingDate, search = "", filters = {}) => {
    const url = "/files/search/sizes";
    const searchParams = getSearchRequestParamsV2(filters);

    const queryParams = {
        search,
        indexingDate
    };

    const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, { ...queryParams, ...searchParams });

    return result;
}

export const getFilesOfDirectory = async (
    dispatch,
    auth,
    indexingDate,
    directoryPath,
    volumeId,
    sort = "size",
    direction = "desc",
    page = 1,
    size = 500
) => {
    const url = "/files/directory";
    
    const queryParams = {
        directoryPath,
        page,
        size,
        sort: `${sort},${direction}`,
        indexingDate 
    };

    if (volumeId) {
        queryParams.volumeId = volumeId;
    }
    
    const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, queryParams);

    return result;
}

export const update = async (
    dispatch,
    auth,
    id,
    indexingDate,
    file
) => {
    const url = `/files/${id}`;
    const queryParams = { indexingDate };
    const body = JSON.stringify(file);

    return await fetchWithAuthV2(dispatch, url, auth.accessToken, queryParams, "PUT", body);
}

export const getFilesByHash = async (
    dispatch,
    auth,
    indexingDate,
    hash,
    page = 1,
    size = 5,
    sort = "name",
    direction = "asc"
) => {
    const url = `/files/hash/${hash}?page=${page}&size=${size}&sort=${sort},${direction}&indexingDate=${indexingDate}`;
    const result = await fetchWithAuth(dispatch, url, auth.accessToken);

    return result;
}

export const getReports = async (
    dispatch,
    auth,
    indexingDate,
    size = 1000
) => {
    const url = "/v1/files/reports";
    const queryParams = { indexingDate, size };
    const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, queryParams);

    return result;
}

export const getDeltaFiles = async (
    dispatch,
    auth,
    indexingDate,
    previousIndexingDate,
    path,
    volumeId,
    page = 1,
    size = 500,
    sort = "size",
    direction = "desc"
) => {
    const url = `/v1/files/snapshots/${previousIndexingDate}`;
    const queryParams = {
        path,
        volumeId,
        page,
        size,
        sort,
        direction,
        indexingDate
    };

    const result = await fetchWithAuthV2(dispatch, url, auth.accessToken, queryParams);

    return result;
}