import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigurationByName } from "../../api/configuration";

export default function BetaWrapper(props) {
  const { children } = props;

  const [isBeta, setIsBeta] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    getConfigurationByName(dispatch, auth, "app.isBetaActive")
      .then(res => setIsBeta(res.value === "true"))
      .catch(err => console.error("Error while getting isBetaActive value", err));
  }, []);

  return (
    <>
      {isBeta && children}
    </>
  );
}