import { fetchWithAuth } from "../actions/requestHelper";

export const createNetworkVolume = async (
  dispatch,
  auth,
  networkVolume
) => {
  const url = `/v1/networkVolumes`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(networkVolume)
  );
};

export const getAllNetworkVolumesPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = 500,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/networkVolumes?page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
  const result = await fetchWithAuth(dispatch, url, auth.accessToken);

  return result;
};

export const deleteNetworkVolume = async (
  dispatch,
  auth,
  id
) => {
  const url = `/v1/networkVolumes/${id}`;
  await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "DELETE"
  );
};

export const updateNetworkVolume = async (
  dispatch,
  auth,
  id,
  networkVolume
) => {
  const url = `/v1/networkVolumes/${id}`;
  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(networkVolume)
  );
};

export const createNetworkVolumeCredentials = async (
  dispatch,
  auth,
  networkVolumeCredential
) => {
  const url = `/v1/networkVolumes/credentials`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(networkVolumeCredential)
  );
};

export const getNetworkVolumeCredential = async (
  dispatch,
  auth,
  volumeId
) => {
  const url = `/v1/networkVolumes/credentials/${volumeId}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const getAllNetworkVolumeCredentialsPaginated = async (
  dispatch,
  auth,
  page = 1,
  size = 500,
  sort = "id",
  direction = "asc"
) => {
  const url = `/v1/networkVolumes/credentials?page=${page}&size=${size}&sort=${sort}&direction=${direction}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const deleteNetworkVolumeCredential = async (dispatch, auth, id) => {
  const url = `/v1/networkVolumes/credentials/${id}`;

  await fetchWithAuth(dispatch, url, auth.accessToken, "DELETE");
};

export const updateNetworkVolumeCredential = async (
  dispatch,
  auth,
  id,
  networkVolumeCredential
) => {
  const url = `/v1/networkVolumes/credentials/${id}`;

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "PUT",
    JSON.stringify(networkVolumeCredential)
  );
};