import download from "downloadjs";
import { signOut } from "../ducks/login";
import { setAlert } from "./alert";
import { setIsLicenseValid } from "../ducks/appInfo";

function getBaseURL() {
  return `${window._env_.REACT_APP_APIURL}:${window._env_.REACT_APP_APIPORT}`;
}

function getHeaders(accessToken, contentType) {
  return {
    Accept: "application/json, text/plain, */*",
    "Content-Type": contentType,
    Authorization: `Bearer ${accessToken}`,
  };
}

export function downloadFileWithAuth(
  dispatch,
  path,
  filename,
  accessToken,
  method = "GET",
  body = null
) {
  return callAPI(
    getHeaders(accessToken),
    dispatch,
    path,
    method,
    (response, resolve) => {
      return response.blob().then(function (blob) {
        download(blob, filename, "text/plain");
        resolve();
      });
    },
    body
  );
}

export function fetchWithAuthV2(
  dispatch,
  path,
  accessToken,
  queryParams = {},
  method = "GET",
  body = null
) {
  const formattedPath = getFormattedPath(path, queryParams);

  return callAPIV2(
    getHeaders(accessToken, "application/json"),
    dispatch,
    formattedPath,
    method,
    handleResponse,
    body
  );
}

function getFormattedPath(path, queryParams) {
  if (Object.keys(queryParams).length === 0) {
    return path;
  }

  const formattedQueryParams = Object.keys(queryParams)
    .map(key => {
      const value = queryParams[key];

      if (Array.isArray(value)) {
        return value.map(v => `${key}=${encodeURIComponent(v)}`).join("&");
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return `${path}?${formattedQueryParams}`;
}

function callAPIV2(
  headers,
  dispatch,
  path,
  method = "GET",
  handleFunctionFunc,
  body = null
) {
  return new Promise((resolve, reject) => {
    fetch(`${getBaseURL()}${path}`, {
      method: method,
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response;
        }
        return response;
      })
      .then((response) => {
        return handleFunctionFunc(response, resolve);
      })
      .catch((err) => {
        handleCatchError(err, dispatch, reject);
      });
  });
}

/**
 * @deprecated since version 1.11.0
 */
export function fetchWithAuth(
  dispatch,
  path,
  accessToken,
  method = "GET",
  body = null
) {
  return callAPI(
    getHeaders(accessToken, "application/json"),
    dispatch,
    path,
    method,
    handleResponse,
    body
  );
}

export function fetchWithAuthText(
  dispatch,
  path,
  accessToken,
  method = "GET",
  body = null
) {
  return callAPI(
    getHeaders(accessToken, "text/plain"),
    dispatch,
    path,
    method,
    handleResponse,
    body
  );
}

/**
 * @deprecated since version 1.11.0
 */
function callAPI(
  headers,
  dispatch,
  path,
  method = "GET",
  handleFunctionFunc,
  body = null
) {
  return new Promise((resolve, reject) => {
    fetch(encodeUrlParams(`${getBaseURL()}${path}`), {
      method: method,
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (response.status >= 400) {
          throw response;
        }
        return response;
      })
      .then((response) => {
        return handleFunctionFunc(response, resolve);
      })
      .catch((err) => {
        handleCatchError(err, dispatch, reject);
      });
  });
}

function encodeUrlParams(url) {
  const [baseUrl, queryString] = url.split("?");

  if (!queryString) {
    return url;
  }

  const params = queryString.split("&");

  const encodedParams = params.map((param) => {
    const [key, value] = param.split("=");
    return `${key}=${encodeURIComponent(value)}`;
  });

  const encodedUrl = `${baseUrl}?${encodedParams.join("&")}`;
  return encodedUrl;
}
async function handleCatchError(err, dispatch, reject) {
  if (err instanceof TypeError) {
    console.log(err);
    reject(
      new TypeError(
        "An error occurred while trying to communicate with the API"
      )
    );
  } else {
    try {
      var text = await err.text();
      const data = JSON.parse(text);
      if (data.status === 401) {
        dispatch(signOut());
        dispatch(
          setAlert("You were disconnected from the application.", "danger")
        );
      } else if ("Invalid License" === data.debugMessage) {
        dispatch(setIsLicenseValid(false));
      } else {
        reject(data);
      }
    } catch (error) {
      reject(text);
    }
  }
}

function handleResponse(response, resolve) {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json().then((data) => {
      resolve(data);
    });
  } else {
    return response.text().then((text) => {
      resolve(text);
    });
  }
}
