export default function RestoreDestinationReview(props) {
  const { location } = props;

  return (
    <>
      <hr />
      <h3>Destination</h3>
      <ul
        style={{
          listStyle: "none",
          lineHeight: "2",
          paddingLeft: "0",
          marginBottom: "0",
        }}
      >
        <li>
          Restore location:{" "}
          <strong>
            {location.length ? location : "Same path as the archive index"}
          </strong>
        </li>
      </ul>
    </>
  );
}
