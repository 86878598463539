export const getKeys = () => {
  return {
    volumeId: { label: "Data Sources", type: "List" },
    path: { label: "Path", type: "String" },
    name: { label: "Name", type: "String" },
    extension: { label: "Extension", type: "String" },
    owner: { label: "Owner", type: "String" },
    group: { label: "Group", type: "String" },
    size: { label: "Size", type: "Long" },
    numberOfFiles: { label: "Number of Files", type: "Long" },
    numberOfFolders: { label: "Number of Folders", type: "Long" },
    lastAccessDate: { label: "Last Access Date", type: "Date" },
    lastModifiedDate: { label: "Last Modified Date", type: "Date" },
    creationDate: { label: "Creation Date", type: "Date" },
    isDupe: { label: "Is Dupe", type: "Boolean" },
    isDirectory: { label: "Is Directory", type: "Boolean" },
    isDeleted: { label: "Is Deleted", type: "Boolean" },
    inode: { label: "Inode", type: "Long" },
    indexingDate: { label: "Indexing Date", type: "Date" },
    folderDepth: { label: "Folder Depth", type: "Integer" },
    identificationHash: { label: "Indentification Hash", type: "String" },
    fileId: { label: "File ID", type: "String" }
  };
};

export const getFilterValues = (type) => {
  switch (type) {
    case "String":
      return {
        value: "Is",
        startingWith: "Starts With",
        wildcard: "Wildcard",
        regex: "Regex",
        values: "Is One of"
      };
    case "Long":
    case "Integer":
      return {
        min: "Minimum",
        max: "Maximum",
        value: "Is",
        values: "Is One of"
      };
    case "Date":
      return {
        after: "After",
        before: "Before"
      };
    case "Boolean":
      return {
        is: "Is"
      };
    case "List":
      return {
        values: "Is One of"
      };
    case "Other":
    default:
      return {};
  }
};