import { useState } from "react";
import SearchQueryNodes from "../components/advancedSearch/SearchQueryNodes";
import { FormGroup, Input, Label } from "reactstrap";

export default function SearchQueryStep(props) {
  const { searchQueryNodes, setSearchQueryNodes, isOptional = false } = props;

  const [isAddSearchQueriesActive, setIsAddSearchQueriesActive] = useState(
    searchQueryNodes !== null
  );

  return (
    <>
      {isOptional && (
        <FormGroup className={isAddSearchQueriesActive ? "mb-3" : ""} check>
          <Label check>
            <Input
              type="checkbox"
              checked={isAddSearchQueriesActive}
              onChange={(e) => {
                setIsAddSearchQueriesActive(e.target.checked);
                !e.target.checked && setSearchQueryNodes(null);
              }}
            />
            Add search queries to the report template
          </Label>
        </FormGroup>
      )}
      {(isAddSearchQueriesActive || !isOptional) && (
        <div>
          <SearchQueryNodes
            initialSearchQueryNodes={searchQueryNodes}
            setParentSearchQueryNodes={setSearchQueryNodes}
          />
        </div>
      )}
    </>
  );
}
