import moment from "moment";
import { returnNAOnNull } from "../../utils/returnNAOnNull";
import { Card, CardBody, CardHeader } from "reactstrap";
import FormatSize from "../../components/FormatSize";
import { REPORT_DESCRIPTIONS } from "../reportDescription";
import { formatName } from "../../utils/formatString";
import { getFormattedTime } from "../../utils/getFormattedTime";
import JobStatusColorScheme from "../../settings/components/JobStatusColorScheme";
import { useNavigate } from "react-router-dom";

export default function ReportDetails(props) {
  const {
    reportTemplate,
    numberOfFilesFound,
    numberOfFilesTotal,
    numberOfFoldersFound,
    numberOfFoldersTotal,
    sizeOfFilesFound,
    sizeOfFilesTotal,
    jobs
  } = props;

  const navigate = useNavigate();

  const formatDate = (value) => moment(value).format("lll");

  const getJobStatus = () => {
    if (jobs.length === 0) {
      return <span>No recent job</span>;
    }

    return <JobStatusColorScheme status={jobs[0].status} />;
  };

  const getJobDuration = () => {
    if (jobs.length === 0) {
      return "N/A";
    }

    const job = jobs[0];

    const startedAtTime = new Date(job.startedAt).getTime();
    const updatedAtTime = new Date(job.updatedAt).getTime();

    const completionTime = getFormattedTime(updatedAtTime - startedAtTime);
    const runningTime = getFormattedTime(Date.now() - startedAtTime);

    switch (job.status) {
      case "SUCCESS":
      case "FAILURE":
      case "WARNING":
        return completionTime;
      case "RUNNING":
        return runningTime;
      case "WAITING":
      default:
        return "N/A";
    }
  };

  const getJobLink = () => {
    let link = "/settings/jobs";

    if (jobs.length > 0) {
      link += `?id=${jobs[0].id}`;
    }

    return link;
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader tag="h5">
        <span>Details</span>
      </CardHeader>
      <CardBody>
        <div
          style={{
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "190px auto",
          }}
        >
          <div>Type:</div>
          <div>
            <strong>{formatName(reportTemplate.type)}</strong>
          </div>
          <div>Number of Files Found:</div>
          <div>
            <strong>
              {numberOfFilesFound.toLocaleString("en-US")} out of{" "}
              {numberOfFilesTotal.toLocaleString("en-US")}
            </strong>
          </div>
          <div>Number of Folders Found:</div>
          <div>
            <strong>
              {numberOfFoldersFound.toLocaleString("en-US")} out of{" "}
              {numberOfFoldersTotal.toLocaleString("en-US")}
            </strong>
          </div>
          <div>Size of Files Found:</div>
          <div>
            <strong>
              <FormatSize>{sizeOfFilesFound}</FormatSize> out of{" "}
              <FormatSize>{sizeOfFilesTotal}</FormatSize>
            </strong>
          </div>
          {reportTemplate.retention && (
            <>
              <div>Flagged Files Retention (Days):</div>

              <div>
                <strong>{reportTemplate.retention}</strong>
              </div>
            </>
          )}
        </div>
        <div
          onClick={() => navigate(getJobLink())}
          style={{
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "190px auto",
            cursor: "pointer"
          }}
        >
          <div>Execution Time:</div>
          <div>
            <strong>
              {returnNAOnNull(reportTemplate.lastExecutionDate, formatDate)}
            </strong>
          </div>
          <div>Execution Status:</div>
          <div>
            <strong>
              {getJobStatus()}
            </strong>
          </div>
          <div>Execution Duration:</div>
          <div>
            <strong>
              {getJobDuration()}
            </strong>
          </div>
        </div>

        <div className="mt-3">{REPORT_DESCRIPTIONS[reportTemplate.type]}</div>
      </CardBody>
    </Card>
  );
}
