import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function BucketPricingWarning() {
  return (
    <span>
      <FontAwesomeIcon id="volumesTooltip" className="me-1" icon={faExclamationCircle} />
      Scanning a bucket may affect your cloud pricing based on the list requests pricing.
      Check with your cloud provider for more information.
    </span>
  );
}

export default BucketPricingWarning;