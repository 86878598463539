import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap";

function TableTitleTooltip(props) {
  const { children, target } = props;

  return (
    <>
      <FontAwesomeIcon id={target} icon={faQuestionCircle} />
      <UncontrolledTooltip placement="auto" target={target}>
        {children}
      </UncontrolledTooltip>
    </>
  );
}

export default TableTitleTooltip;
