import React, { useState, useRef, useEffect } from "react";
import { Input, Label, Form, FormGroup, FormFeedback } from "reactstrap";

function StorageTypeForm({
    setStorageTypeObj,
    storagePrices,
    storageTypeObj,
    setDisableForwardActionButton
}) {
    const [errorObj, setErrorObj] = useState({
        storageName: "",
        storageCurrency: "",
        storagePrice: ""
    });
    const { current: localStorageObjForValidation } = useRef({
        storageName: "",
        storageCurrency: "",
        storagePrice: ""
    });

    useEffect(() => {
        if (
            localStorageObjForValidation.storageName.length === 0 ||
            localStorageObjForValidation.storageCurrency.length === 0 ||
            localStorageObjForValidation.storagePrice.length === 0
        ) {
            setDisableForwardActionButton(true);
        }
    }, [localStorageObjForValidation, setDisableForwardActionButton]);

    const handleError = (inputType) => {
        const storageNameDoNotFitCharacterRestriction = (
            localStorageObjForValidation.storageName.length === 0 ||
            localStorageObjForValidation.storageName.length > 25
        );

        const storageCurrencySymbolDoNotFitCharacterRestriction = (
            localStorageObjForValidation.storageCurrency.length === 0 ||
            localStorageObjForValidation.storageCurrency.length > 20
        );

        const isStoragePriceEmpty = localStorageObjForValidation.storagePrice.length === 0;
        const isStorageNameNotUnique = storagePrices.some(storage => storage.description === localStorageObjForValidation.storageName);
        const isPriceNegative = localStorageObjForValidation.storagePrice < 0;

        switch (inputType) {
            case "storageName":
                if (storageNameDoNotFitCharacterRestriction) {
                    setErrorObj(prev => ({
                        ...prev,
                        storageName: "Name must be between 1 and 25 characters"
                    }));
                } else if (isStorageNameNotUnique) {
                    setErrorObj(prev => ({
                        ...prev,
                        storageName: "Name must be unique"
                    }));
                } else {
                    setErrorObj(prev => ({
                        ...prev,
                        storageName: ""
                    }));
                }
                break;

            case "storageCurrency":
                if (storageCurrencySymbolDoNotFitCharacterRestriction) {
                    setErrorObj(prev => ({
                        ...prev,
                        storageCurrency: "Currency must be between 1 and 20 characters"
                    }));
                } else {
                    setErrorObj(prev => ({
                        ...prev,
                        storageCurrency: ""
                    }));
                }
                break;

            case "storagePrice":
                if (isStoragePriceEmpty) {
                    setErrorObj(prev => ({
                        ...prev,
                        storagePrice: "Price can't be empty"
                    }));
                } else if (isPriceNegative) {
                    setErrorObj(prev => ({
                        ...prev,
                        storagePrice: "Price can't be a negative number"
                    }));
                } else {
                    setErrorObj(prev => ({
                        ...prev,
                        storagePrice: ""
                    }));
                }
                break;

            default:
                break;
        }

        if (
            !storageNameDoNotFitCharacterRestriction &&
            !storageCurrencySymbolDoNotFitCharacterRestriction &&
            !isStoragePriceEmpty &&
            !isStorageNameNotUnique
        ) {
            setDisableForwardActionButton(false);
        } else {
            setDisableForwardActionButton(true);
        };
    }

    return (
        <Form>
            <FormGroup>
                <Label for="storage name">Name</Label>
                <Input
                    onChange={event => {
                        const inputValue = event.target.value;
                        localStorageObjForValidation.storageName = inputValue;
                        handleError("storageName");
                        setStorageTypeObj({ ...storageTypeObj, description: inputValue });
                    }}
                    value={storageTypeObj.name}
                    type="text"
                    name="storage name"
                    placeholder="Ex: HDD"
                    onBlur={() => handleError("storageName")}
                    invalid={errorObj["storageName"].length > 1}
                />
                <FormFeedback>{errorObj["storageName"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="storage currency">Currency</Label>
                <Input
                    onChange={event => {
                        const inputValue = event.target.value;
                        localStorageObjForValidation.storageCurrency = inputValue;
                        handleError("storageCurrency");
                        setStorageTypeObj({ ...storageTypeObj, currencySymbol: inputValue })
                    }}
                    value={storageTypeObj.currency}
                    type="text"
                    name="storage currency"
                    placeholder="Ex: $"
                    onBlur={() => handleError("storageCurrency")}
                    invalid={errorObj["storageCurrency"].length > 1}
                />
                <FormFeedback>{errorObj["storageCurrency"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="storage price">Price</Label>
                <Input
                    onChange={event => {
                        const inputValue = event.target.value;
                        localStorageObjForValidation.storagePrice = inputValue;
                        handleError("storagePrice");
                        setStorageTypeObj({ ...storageTypeObj, price: inputValue })
                    }}
                    value={storageTypeObj.price}
                    type="number"
                    name="storage price"
                    placeholder="Ex: 0.35"
                    step={0.1}
                    onBlur={() => handleError("storagePrice")}
                    invalid={errorObj["storagePrice"].length > 1}
                />
                <FormFeedback>{errorObj["storagePrice"]}</FormFeedback>
            </FormGroup>
        </Form>
    );
}

export default StorageTypeForm;