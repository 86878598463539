import CopyPath from "../CopyPath";
import FileDetailsTrigger from "../FileDetailsTrigger";
import FolderAnalyticsButton from "../FolderAnalyticsButton";
import ManageTags from "../tags/ManageTags";
import { getChildNodeFromTreeNodes } from "./FolderTree";

export default function NodeActions(props) {
  const {
    treeNodes,
    setTreeNodes,
    positionInTree,
    isTagFileOpen,
    setIsTagFileOpen,
    setShownFileDetails,
  } = props;

  const setUpdatedFileForChildren = (file, currentChild, index = 0) => {
    const updatedChildren = currentChild.children.map((child, i) => {
      if (i === positionInTree[index]) {
        if (index === positionInTree.length - 1) {
          child.file = file;
        } else {
          child.children = setUpdatedFileForChildren(file, child, index + 1);
        }
      }

      return child;
    });

    return updatedChildren;
  };

  const setTreeNodesStructure = (file) => {
    setTreeNodes((prev) => setUpdatedFileForChildren(file, { children: prev }));
  };

  const file = getChildNodeFromTreeNodes(treeNodes, positionInTree)?.file;

  return (
    <span style={{ cursor: "pointer" }}>

      <span className="me-1">
        <ManageTags
          file={file}
          updateSelectedFile={(file) => setTreeNodesStructure(file)}
          isTagFileOpen={isTagFileOpen}
          setIsTagFileOpen={setIsTagFileOpen}
        />
      </span>
      <span className="me-1">
        <CopyPath file={file} />
      </span>
      <span className="me-1">
        <FileDetailsTrigger
          setShownFileDetails={setShownFileDetails}
          file={file}
        />
      </span>
      {file.isDirectory && (
        <span className="me-1">
          <FolderAnalyticsButton volumeId={file.volumeId} path={file.path} />
        </span>
      )}
    </span>
  );
}
