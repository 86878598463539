import { useSelector } from "react-redux";
import OnPremisesCredentialList from "./OnPremisesCredentialList";
import VolumeSettingList from "./VolumeSettingList";

export default function OnPremises(props) {
  const {
    dispatch,
    serverList,
    storagePriceList,
    openChosenWizardFromSection,
  } = props;

  const dataVolumeSettings = useSelector(
    ({ dataVolumeSettings }) => dataVolumeSettings
  );

  return (
    <>
      <VolumeSettingList
        volumes={dataVolumeSettings.items}
        serverList={serverList}
        storagePriceList={storagePriceList}
        page={dataVolumeSettings.page}
        size={dataVolumeSettings.size}
        sort={dataVolumeSettings.sort}
        total={dataVolumeSettings.total}
        direction={dataVolumeSettings.direction}
        openChosenWizardFromSection={openChosenWizardFromSection}
        isFetching={dataVolumeSettings.isFetching}
      />
      <br />
      <OnPremisesCredentialList dispatch={dispatch} />
    </>
  );
}
