import { Svg, Path } from "@react-pdf/renderer";

export default function CloudSodaLogoSVG({ width, height, color }) {
  return (
    <Svg
      id="sodaLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 292 45.6"
      xmlSpace="preserve"
      style={{ width: width, height: height }}
    >
      <Path
        d="M177,31.7c0-1-.4-1.8-1.2-2.5-.8-.6-2.4-1.3-4.8-2l-2.8-.9c-3.3-.9-5.8-2.4-7.7-4.3-1.9-1.9-2.8-4.4-2.8-7.5s.6-4.9,1.9-6.8c1.3-2,3.1-3.5,5.4-4.7,2.3-1.1,5-1.7,8.1-1.7s5.7.5,8.3,1.4c2.6.9,4.8,2.2,6.5,3.9l-5.5,7.4c-2.7-2.4-5.7-3.6-9-3.6s-4.6,1-4.6,3,.4,1.6,1.2,2.2c.8.6,2.3,1.2,4.6,1.9l2.6.8c3.7,1.1,6.5,2.8,8.5,4.8,1.9,2.1,2.9,4.7,2.9,7.8s-.7,5-2.1,7c-1.4,2-3.3,3.5-5.9,4.6-2.5,1.1-5.5,1.6-8.9,1.6s-6.1-.5-8.9-1.5c-2.8-1-5.3-2.4-7.5-4.3l5.1-7.9c3.8,3,7.6,4.5,11.4,4.5s5.2-1.1,5.2-3.4Z"
        fill={color}
      />
      <Path
        d="M191.9,28.8c0-3,.7-5.7,2.1-8,1.4-2.3,3.3-4.1,5.7-5.4s5.2-2,8.3-2,5.9.7,8.3,2c2.4,1.3,4.3,3.1,5.7,5.4,1.4,2.3,2.1,5,2.1,8s-.7,5.6-2.1,8c-1.4,2.3-3.3,4.1-5.7,5.5-2.4,1.3-5.2,2-8.3,2s-5.9-.7-8.3-2c-2.4-1.3-4.3-3.1-5.7-5.4-1.4-2.3-2.1-5-2.1-8ZM202.6,28.9c0,1.9.5,3.4,1.5,4.5,1,1.1,2.3,1.7,3.9,1.7s2.9-.6,3.9-1.7c1-1.1,1.5-2.6,1.5-4.6s-.5-3.4-1.5-4.6c-1-1.1-2.3-1.7-3.9-1.7s-3,.6-4,1.7c-1,1.1-1.5,2.7-1.5,4.6Z"
        fill={color}
      />
      <Path
        d="M227.1,29.8c0-3.2.6-6.1,1.9-8.6,1.3-2.5,3.1-4.4,5.3-5.8,2.2-1.4,4.8-2.1,7.5-2.1s2.1.1,3,.4,1.8.6,2.6,1V2.4h10.7v40.9h-9.7l-.6-3.4c-1.8,2.9-4.7,4.4-8.6,4.4s-4.5-.6-6.4-1.8c-1.8-1.2-3.3-2.9-4.3-5-1-2.2-1.6-4.7-1.6-7.5ZM237.9,29c0,1.9.4,3.4,1.3,4.4.9,1.1,2.1,1.6,3.6,1.6s2.6-.5,3.4-1.5c.9-1,1.3-2.4,1.3-4.1v-5.9c-1.2-.7-2.4-1-3.7-1-1.8,0-3.3.6-4.4,1.7-1.1,1.1-1.6,2.7-1.6,4.8Z"
        fill={color}
      />
      <Path
        d="M262.7,35.3c0-3.2,1.2-5.8,3.5-7.6,2.4-1.8,5.7-2.7,10.1-2.7s1.3,0,2.1,0c.7,0,1.5.1,2.3.2v-.4c0-2.5-1.9-3.8-5.6-3.8s-5.6.8-7.7,2.3l-3.6-6.5c3.6-2.4,7.8-3.6,12.9-3.6s8.4,1,10.8,3.1c2.5,2.1,3.7,5.1,3.7,9v17.8h-9.4l-.5-3.3c-.9,1.3-2.2,2.3-3.7,3.1-1.5.7-3.3,1.1-5.4,1.1s-5.2-.8-6.9-2.4-2.6-3.8-2.6-6.5ZM272.8,33.9c0,2.1,1.2,3.1,3.6,3.1s2.4-.3,3.2-1c.8-.7,1.2-1.6,1.2-2.7v-2.6c-1.1-.2-2.1-.2-2.9-.2-3.4,0-5,1.1-5,3.4Z"
        fill={color}
      />
      <Path
        d="M.9,23c0-3.1.5-6,1.5-8.6,1-2.6,2.4-4.9,4.3-6.9,1.8-1.9,4-3.4,6.5-4.5,2.5-1.1,5.2-1.6,8.2-1.6s6.4.7,9.2,2.1,5,3.4,6.7,6l-4,3.3c-2.7-4.2-6.6-6.3-11.8-6.3s-5.4.7-7.6,2.1c-2.2,1.4-4,3.3-5.2,5.8-1.2,2.5-1.9,5.3-1.9,8.6s.6,6,1.8,8.5c1.2,2.5,2.9,4.4,5.1,5.8,2.2,1.4,4.7,2.1,7.5,2.1,4.8,0,8.9-2.1,12.2-6.3l3.9,3.2c-1.8,2.6-4.1,4.6-6.9,6s-5.9,2.1-9.3,2.1-7.4-.9-10.4-2.7c-3-1.8-5.4-4.3-7.2-7.5-1.7-3.2-2.6-6.9-2.6-11Z"
        fill={color}
      />
      <Path d="M43.3,2.4h5.2v40.9h-5.2V2.4Z" fill={color} />
      <Path
        d="M54.8,29c0-2.9.7-5.4,2-7.7,1.3-2.2,3.1-4,5.4-5.4,2.3-1.3,4.8-2,7.7-2s5.4.7,7.7,2c2.2,1.3,4,3.1,5.4,5.4,1.3,2.2,2,4.8,2,7.7s-.7,5.4-2,7.7c-1.3,2.3-3.1,4.1-5.4,5.4-2.2,1.3-4.8,2-7.7,2s-5.4-.7-7.7-2c-2.3-1.3-4.1-3.1-5.4-5.4-1.3-2.3-2-4.9-2-7.7ZM60.1,29c0,2,.4,3.9,1.3,5.5.8,1.6,2,2.8,3.5,3.7s3.1,1.3,5,1.3,3.5-.4,5-1.3c1.5-.9,2.6-2.1,3.5-3.7.9-1.6,1.3-3.4,1.3-5.5s-.4-3.9-1.3-5.5c-.8-1.6-2-2.8-3.5-3.7-1.5-.9-3.1-1.3-5-1.3s-3.6.4-5,1.3c-1.5.9-2.6,2.1-3.5,3.7-.8,1.6-1.3,3.4-1.3,5.5Z"
        fill={color}
      />
      <Path
        d="M91,32V14.8h5.2v16.7c0,2.6.6,4.6,1.7,6,1.1,1.4,2.7,2.1,4.9,2.1s4.6-1,6.2-2.9c1.6-2,2.4-4.6,2.4-7.8v-14.1h5.2v28.5h-4.8l-.2-4.4c-1,1.6-2.4,2.9-4.1,3.8-1.7.9-3.6,1.4-5.8,1.4-3.4,0-5.9-1.1-7.8-3.2-1.8-2.1-2.8-5.1-2.8-9Z"
        fill={color}
      />
      <Path
        d="M122.7,29.8c0-3.1.6-5.8,1.9-8.2,1.3-2.4,3-4.3,5.2-5.6,2.2-1.3,4.8-2,7.6-2s5,.7,7.4,2V2.4h5.2v40.9h-4.8l-.2-3.6c-.9,1.4-2.2,2.5-3.8,3.3-1.6.8-3.4,1.2-5.5,1.2s-4.8-.6-6.8-1.8c-2-1.2-3.5-2.9-4.6-5.1-1.1-2.2-1.7-4.7-1.7-7.5ZM128,29.4c0,3.1.8,5.6,2.3,7.4,1.5,1.8,3.6,2.8,6.2,2.8s4.5-.8,6-2.5c1.6-1.6,2.4-3.7,2.4-6.3v-10.2c-2.2-1.5-4.5-2.2-7.1-2.2s-5.4,1-7.2,3-2.7,4.6-2.7,7.9Z"
        fill={color}
      />
    </Svg>
  );
}
