import { fetchWithAuth } from "../actions/requestHelper";

export const getAllCrawlDates = async (
  dispatch,
  auth
) => {
  const url = `/administration/dates`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const deleteIndex = async (
  dispatch,
  auth,
  date
) => {
  const url = `/v1/administration/snapshots/${date}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "DELETE");
};

export const updateDataIntell = async (
  dispatch,
  auth,
  version,
  serverId
) => {
  const url = `/v1/administration/updates`;

  const body = {
    version: version,
    serverId: serverId
  }

  return await fetchWithAuth(
    dispatch,
    url,
    auth.accessToken,
    "POST",
    JSON.stringify(body)
  );
}