import { useState, forwardRef, useRef, useEffect } from "react";
import Loading from "../../components/Loading";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  FormGroup,
  Form,
  Row,
  Label,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import {
  CALENDAR_ICON_STYLE,
  DAY_IN_MILLISECONDS,
  DATE_FORMAT,
} from "../../constants/dateFormatting";
import PDFChartButton from "../../components/PDFChartButton";
import { useDispatch, useSelector } from "react-redux";
import { getProjectHistoryById } from "../../api/project";
import { formatSize } from "../../components/FormatSize";
import { CHART_COLORS } from "../../constants/chartColors";
import MultiLineChart from "../../components/MultiLineChart";

ProjectSizeChart.propTypes = {
  projectId: PropTypes.string,
  allocatedSize: PropTypes.number,
};

export function ProjectSizeChart(props) {
  const { projectId, allocatedSize } = props;

  const chartRef = useRef();

  const [size, setSize] = useState("30");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showCustomTimePeriod, setShowCustomTimePeriod] = useState(false);
  const [shownProjects, setShownProjects] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [deltaInfo, setDeltaInfo] = useState("");

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  useEffect(() => {
    setFromDate(Date.parse(indexingDate) - DAY_IN_MILLISECONDS * 29);
    setToDate(Date.parse(indexingDate) + DAY_IN_MILLISECONDS);
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      setFetching(true);
      getProjectHistoryById(
        dispatch,
        auth,
        projectId,
        indexingDate,
        Math.round((toDate - fromDate) / DAY_IN_MILLISECONDS) + 1
      )
        .then((res) => {
          const projectsInRange = [];
          res.forEach((project) => {
            if (
              Date.parse(project.indexingDate) >= fromDate &&
              Date.parse(project.indexingDate) <= toDate
            ) {
              projectsInRange.unshift(project);
            }
          });
          setShownProjects(projectsInRange);
          setFetching(false);
        })
        .catch((err) => {
          console.error(err);
          setFetching(false);
        });
    }
  }, [fromDate, toDate]);

  const sum = shownProjects.reduce(
    (acc, project) => acc + project.sizeOfFiles,
    0
  );
  const filesizeObject = formatSize(
    shownProjects.length > 0 ? sum / shownProjects.length : 0,
    baseSize,
    null,
    "object"
  );
  const getLabels = () => shownProjects.map((project) => project.indexingDate);
  const getSizes = () =>
    shownProjects.map(
      (project) =>
        formatSize(
          project.sizeOfFiles,
          baseSize,
          filesizeObject.exponent,
          "object"
        ).value
    );
  const getDatasets = () => {
    const folderGroupSizes = [
      { title: "Total", data: getSizes(), colors: "#333333" },
    ];

    if (shownProjects.length === 0) {
      return folderGroupSizes;
    }

    const folderGroupsObject = shownProjects[
      shownProjects.length - 1
    ].projectShares.reduce((obj, folder) => {
      obj[folder.volumeName] = [];
      return obj;
    }, {});

    shownProjects.forEach((project) =>
      Object.keys(folderGroupsObject).forEach((name) => {
        const folder = project.projectShares.find(
          (folder) => folder.volumeName === name
        );
        folder === undefined
          ? folderGroupsObject[name].push(
              formatSize(0, baseSize, filesizeObject.exponent, "object").value
            )
          : folderGroupsObject[name].push(
              formatSize(
                folder.sizeOfFiles,
                baseSize,
                filesizeObject.exponent,
                "object"
              ).value
            );
      })
    );

    Object.entries(folderGroupsObject).forEach(([key, value], index) =>
      folderGroupSizes.push({
        title: key,
        data: value,
        colors: CHART_COLORS[index],
      })
    );

    return folderGroupSizes;
  };

  const CustomDatePicker = forwardRef(({ onClick, value }, ref) => {
    return (
      <div ref={ref} style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={faCalendar} style={CALENDAR_ICON_STYLE} />
        <Input
          onClick={onClick}
          value={value}
          onChange={() => {}}
          style={{ paddingLeft: "2rem", width: "130px" }}
          bsSize="sm"
        />
      </div>
    );
  });

  return (
    <Card style={{ marginBottom: 10 }}>
      <CardHeader>
        <span style={{ fontSize: "1.25rem" }}>Total Size of Project</span>
        <div className="float-end" style={{ paddingLeft: 10 }}>
          <Form>
            <Row className="row-cols-lg-auto g-3 align-items-center">
              <Col>
                <Input
                  type="select"
                  value={size}
                  onChange={(event) => {
                    const newSize = event.target.value;

                    if (newSize === "custom") {
                      setShowCustomTimePeriod(true);
                    } else {
                      setFromDate(
                        Date.parse(indexingDate) -
                          DAY_IN_MILLISECONDS * (Number(newSize) - 1)
                      );
                      setToDate(Date.parse(indexingDate) + DAY_IN_MILLISECONDS);
                      setShowCustomTimePeriod(false);
                    }

                    setSize(newSize);
                  }}
                  bsSize="sm"
                >
                  <option value="7">Last 7 days</option>
                  <option value="30">Last 30 days</option>
                  <option value="90">Last 90 days</option>
                  <option value="custom">Custom</option>
                </Input>
              </Col>
              {showCustomTimePeriod && (
                <>
                  <Col className="ms-2">
                    <Label className="me-1" for="fromDate">
                      From
                    </Label>
                    <DatePicker
                      id="fromDate"
                      className="form-control"
                      type="text"
                      selected={fromDate}
                      onChange={(date) => setFromDate(Date.parse(date))}
                      maxDate={toDate}
                      dateFormat={DATE_FORMAT}
                      customInput={<CustomDatePicker />}
                      popperPlacement="auto-start"
                    />
                  </Col>
                  <Col className="ms-2">
                    <Label className="me-1" for="toDate">
                      To
                    </Label>
                    <DatePicker
                      id="toDate"
                      className="form-control"
                      type="text"
                      selected={toDate}
                      onChange={(date) => setToDate(Date.parse(date))}
                      minDate={fromDate}
                      maxDate={Date.parse(indexingDate) + DAY_IN_MILLISECONDS}
                      dateFormat={DATE_FORMAT}
                      customInput={<CustomDatePicker />}
                      popperPlacement="auto-start"
                    />
                  </Col>
                </>
              )}
              <div className="ms-1">
                <PDFChartButton
                  chartRef={chartRef}
                  title=""
                  subTitle={filesizeObject.unit}
                  deltaInfo={deltaInfo}
                />
              </div>
            </Row>
          </Form>
        </div>
      </CardHeader>
      <CardBody>
        {fetching ? (
          <Loading />
        ) : (
          <>
            <MultiLineChart
              lineChartRef={chartRef}
              unit={filesizeObject.unit}
              labels={getLabels()}
              height={70}
              showMinMax
              maxCapacity={
                formatSize(
                  allocatedSize,
                  baseSize,
                  filesizeObject.exponent,
                  "object"
                ).value
              }
              datasets={getDatasets()}
              showBadges={false}
              setDeltaInfo={setDeltaInfo}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default ProjectSizeChart;
