import { FormGroup, Col, Input, Label } from "reactstrap";

const jobNotificationType = require("../../../constants/jobNotificationType.json");
const jobNotificationStatus = require("../../../constants/jobNotificationStatus.json");

export default function NotificationModal({
  notificationObj,
  setNotificationObj,
  notificableJobTypes,
}) {
  return (
    <>
      <FormGroup row>
        <Label for="jobType" sm={3}>
          Job Type
        </Label>
        <Col sm={9}>
          <Input
            id="jobType"
            name="jobType"
            type="select"
            value={notificationObj.type}
            onChange={(event) => {
              const value = event.target.value;

              setNotificationObj((prev) => ({
                ...prev,
                type: value,
              }));
            }}
          >
            {notificableJobTypes.sort().map((jobType) => (
              <option key={jobType} value={jobType}>
                {jobNotificationType[jobType] ?? jobType}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="jobStatus" sm={3}>
          Status
        </Label>
        <Col sm={9}>
          <Input
            id="jobStatus"
            name="jobStatus"
            type="select"
            value={notificationObj.status}
            onChange={(event) => {
              const value = event.target.value;

              setNotificationObj((prev) => ({
                ...prev,
                status: value,
              }));
            }}
          >
            {["ALWAYS", "ON_SUCCESS", "ON_WARNING", "ON_ERROR"].map(
              (jobStatus) => (
                <option key={jobStatus} value={jobStatus}>
                  {jobNotificationStatus[jobStatus]}
                </option>
              )
            )}
          </Input>
        </Col>
      </FormGroup>
    </>
  );
}
