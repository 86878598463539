import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../components/ConfirmModal";
import { updateConfigurations } from "../../../ducks/configurations";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";

export default function ChangeIconikSecretModal(props) {
  const { isOpen, setIsOpen } = props;

  const [applicationToken, setApplicationToken] = useState("");

  const dispatch = useDispatch();

  const configurations = useSelector(({ configurations }) => configurations);

  const onClose = () => {
    setApplicationToken("");
    setIsOpen(false);
  };

  return (
    <ConfirmModal
      confirmAction={() => {
        dispatch(updateConfigurations([{
          ...configurations.iconikAuthToken,
          description: "Iconik Auth-Token",
          name: "iconik.authtoken",
          value: applicationToken
        }]));
        onClose();
      }}
      showConfirmation={isOpen}
      title="Change Application Token"
      closeConfirmation={() => onClose()}
      buttonColor="primary"
      buttonText="Save"
    >
      <Form>
        <FormGroup row>
          <Label sm={4}>
            Application Token
          </Label>
          <Col sm={8}>
            <Input
              value={applicationToken}
              onChange={e => setApplicationToken(e.target.value)}
            />
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}