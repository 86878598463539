import { useState } from "react";
import { Input, FormGroup, Label, Col, InputGroup, Button, Badge, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

IndexFolderOnlyPathsInput.propTypes = { indexFolderOnlyPaths: PropTypes.array, handleChange: PropTypes.func };

export default function IndexFolderOnlyPathsInput(props) {
  const { indexFolderOnlyPaths, handleChange } = props;

  const [inputIndexFolderOnlyPaths, setInputIndexFolderOnlyPaths] = useState("");

  const addIndexFolderOnlyPaths = () => {
    if (inputIndexFolderOnlyPaths.length > 0 && !indexFolderOnlyPaths.some(path => path === inputIndexFolderOnlyPaths)) {
      handleChange("indexFolderOnlyPaths", [ ...indexFolderOnlyPaths, inputIndexFolderOnlyPaths ]);
      setInputIndexFolderOnlyPaths("");
    }
  };

  return (
    <FormGroup row>
      <Col sm={4}>
        <Label for="inputIndexFolderOnlyPaths">Index Folder Only Paths</Label>
        <FontAwesomeIcon
          id="inputIndexFolderOnlyPathsLabel"
          className="ms-1"
          icon={faQuestionCircle}
        />
        <UncontrolledTooltip placement="auto" target="inputIndexFolderOnlyPathsLabel">
          Paths that will only index the folders during the scan.
        </UncontrolledTooltip>
      </Col>
      <Col sm={8}>
        <InputGroup className="mb-1">
          <Input
            type="text"
            value={inputIndexFolderOnlyPaths}
            placeholder="Ex: /index/folder/only/path"
            onChange={e => setInputIndexFolderOnlyPaths(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                addIndexFolderOnlyPaths();
              }
            }}
          />
          <Button
            color="success"
            onClick={() => addIndexFolderOnlyPaths()}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </InputGroup>
      </Col>
      <Col sm={4} />
      <Col sm={8} className="mt-2">
        <div style={{ maxHeight: "150px", overflowY: "auto" }}>
        {indexFolderOnlyPaths.map(indexFolderOnlyPath => {
          return (
            <Badge color="primary" style={{ marginRight: 5, marginBottom: 5, fontSize: "100%" }} key={indexFolderOnlyPath}>
              {indexFolderOnlyPath}
              <Badge
                onClick={() => handleChange("indexFolderOnlyPaths", indexFolderOnlyPaths.filter(path => indexFolderOnlyPath != path))}
                color="light"
                className="text-dark"
                style={{ cursor: "pointer", marginLeft: 10 }}
              >
                X
              </Badge>
            </Badge>
          );
        })}
        </div>
      </Col>
    </FormGroup>
  );
}