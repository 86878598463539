import { useRef } from "react";
import { Doughnut, Pie, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Col, Row } from "reactstrap";
import { getCustomLegend, getCustomLegendStyle } from "./customLegend";
import { useSelector } from "react-redux";
import { formatSize } from "./FormatSize";

ChartJS.register(
  CategoryScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  Title
);

// Values greater than the threshold will be displayed
const DISPLAY_THRESHOLD = 0.05;

export default function CircleChart(props) {
  const {
    circleChartRef,
    data,
    dataCount,
    dataCountFolder,
    title,
    labels,
    color,
    height,
    width,
    pie,
    onClick = () => { },
    onLegendClick = () => { },
    maintainAspectRatio = true,
    unit,
    customLegendId,
    showTotal = true,
    datasets = [],
    options = null,
    valueCenterOfChart,
    isCustomLabel = false,
  } = props;

  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);

  const getDatasets = () => {
    if (datasets.length === 0) {
      return [
        {
          label: title,
          fill: false,
          lineTension: 0.1,
          backgroundColor: color,
          borderColor: color,
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: color,
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          data: data,
        },
      ];
    }

    const result = [];

    datasets.forEach((dataset) => {
      result.push({
        fill: false,
        lineTension: 0.1,
        backgroundColor: dataset.colors,
        borderColor: "rgba(0,0,0,0)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: dataset.colors,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: dataset.colors,
        pointHoverBorderColor: dataset.colors,
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: dataset.data,
      });
    });

    return result;
  };

  const chartRef = circleChartRef ?? useRef();
  const doughnutChartData = {
    labels: labels,
    datasets: getDatasets(),
    options: {
      xAxes: [
        {
          type: "time",
          ticks: {
            autoSkip: true,
          },
        },
      ],
    },
  };

  const tooltip = {
    callbacks: {
      title: function (context) {
        return [labels[context[0].dataIndex]];
      },
      label: function (context) {
        const formattedLabel = [
          "Size: " + data[context.dataIndex] + ` ${unit}`,
        ];

        if (typeof dataCount !== "undefined") {
          formattedLabel.push(
            "Number of files: " +
            dataCount[context.dataIndex].toLocaleString("en-US")
          );
        }

        if (typeof dataCountFolder !== "undefined") {
          formattedLabel.push(
            "Number of folders: " +
            dataCountFolder[context.dataIndex].toLocaleString("en-US")
          );
        }

        return formattedLabel;
      },
    },
  };

  const option = {
    onHover: (event, chartElement) => {
      if (onClick.toString() != (() => { }).toString()) {
        event.native.target.style.cursor = chartElement[0]
          ? "pointer"
          : "default";
      }
    },
    maintainAspectRatio: maintainAspectRatio,
    plugins: {
      tooltip,
      datalabels: {
        font: {
          size: isCustomLabel ? 7 : 10,
        },
        textAlign: "center",
        color: function (context) {
          return isCustomLabel ? "black" : "white";
        },
        display: function (context) {
          const totalValue = context.dataset.data
            .map((value, index) =>
              context.chart.getDataVisibility(index) ? parseInt(value) : 0
            )
            .reduce((prev, curr) => prev + curr);
          return (
            context.dataset.data[context.dataIndex] / totalValue >
            DISPLAY_THRESHOLD
          );
        },

        formatter: function (value, context) {
          if (isCustomLabel) {
            return (
              data[context.dataIndex] +
              " " +
              unit +
              " \n" +
              dataCount[context.dataIndex].toLocaleString("en-US")
            );
          } else {
            return Math.round(value);
          }
        },
      },
      htmlLegend: {
        containerID: customLegendId ?? "legend-container",
      },
      legend: {
        display: customLegendId ? false : true,
      },
    },
  };

  const elementIndex = (e) =>
    getElementAtEvent(chartRef.current, e).length
      ? getElementAtEvent(chartRef.current, e)[0].index
      : null;

  const getSumOfValuesInDatasets = (data, legendItems) => {
    if (data.datasets.length === 1) {
      return data.datasets[0].data.reduce(
        (total, value, index) =>
          total +
          parseFloat(
            legendItems[index].hidden ||
              index + 1 === data.datasets[0].data.length
              ? 0
              : value
          ),
        0
      );
    }
    return data.datasets.reduce(
      (total, dataset, index) =>
        total +
        dataset.data.reduce(
          (sum, current, i) =>
            sum +
            parseFloat(
              data.datasets[index].backgroundColor[i]?.includes("#FFFFFF")
                ? 0
                : current ?? 0
            ),
          0
        ),
      0
    );
  };

  const totalCenter = {
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.font = "bold 12px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      chart.getDatasetMeta(0).data[0] &&
        ctx.fillText(
          valueCenterOfChart ??
          `${getSumOfValuesInDatasets(data, chart.legend.legendItems).toFixed(
            2
          )} ${unit}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
    },
  };

  const getChartWithLegend = () => {
    if (customLegendId) {
      return (
        <Row>
          <Col sm={8} md={12} xl={8} className="pb-1">
            <Doughnut
              ref={chartRef}
              height={height}
              width={width}
              data={doughnutChartData}
              options={options ?? option}
              plugins={[getCustomLegend(onLegendClick)]}
              onClick={(e) => onClick(elementIndex(e))}
            />
          </Col>
          <Col sm={4} md={12} xl={4}>
            <div
              id={customLegendId ?? "legend-container"}
              className="ms-auto"
              style={getCustomLegendStyle(ChartJS)}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Doughnut
        ref={chartRef}
        height={height}
        width={width}
        plugins={showTotal ? [totalCenter] : []}
        data={doughnutChartData}
        options={options ?? option}
        onClick={(e) => onClick(elementIndex(e))}
      />
    );
  };

  if (pie) {
    return (
      <Pie
        ref={chartRef}
        height={height}
        data={doughnutChartData}
        options={options ?? option}
        plugins={[getCustomLegend(onLegendClick)]}
        onClick={(e) => onClick(elementIndex(e))}
      />
    );
  } else {
    return getChartWithLegend();
  }
}
