import "babel-polyfill";
import React from "react";
import { createRoot } from 'react-dom/client';
import "./bootstrap-5.0.2/css/bootstrap.min.css";
import "./signin.css";
import "./index.css";
import Root from "./containers/Root";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);
