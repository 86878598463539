import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { cancelJobs } from "../../api/job";
import { setAlert } from "../../actions/alert";
import { restartServers } from "../../api/server";

export default function CancelJobsModal(props) {
  const {
    isModalOpen,
    onCloseModal
  } = props;

  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  const cancelAllJobs = () => {
    cancelJobs(dispatch, auth)
      .then(res => {
        restartServers(dispatch, auth, [{ id: 1, name: "DataIntell" }])
          .then(res => {
            onCloseModal();
            dispatch(setAlert("All the jobs were cancelled successfully. Please note that the DataIntell API will be down for a couple of minutes.", "success"));
          })
          .catch(err => dispatch(setAlert("Error while cancelling the running jobs. The waiting jobs were cancelled successfully", "warning")));
      })
      .catch(err => dispatch(setAlert("Error while cancelling all the jobs", "danger")));
  };

  const cancelRunningJobs = () => {
    restartServers(dispatch, auth, [{ id: 1, name: "DataIntell" }])
      .then(res => {
        onCloseModal();
        dispatch(setAlert("Running jobs were cancelled successfully. Please note that the DataIntell API will be down for a couple of minutes.", "success"));
      })
      .catch(err => dispatch(setAlert("Error while cancelling running jobs", "danger")));
  };

  const cancelWaitingJobs = () => {
    cancelJobs(dispatch, auth)
      .then(res => {
        onCloseModal();
        dispatch(setAlert("Waiting jobs were cancelled successfully", "success"));
      })
      .catch(err => dispatch(setAlert("Error while cancelling waiting jobs", "danger")));
  };

  return (
    <Modal toggle={onCloseModal} isOpen={isModalOpen}>
      <ModalHeader toggle={onCloseModal}>Cancel Jobs</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <span className="pe-4">
            <h6>Cancel all jobs</h6>
            Cancel all running and waiting jobs. This will necessitate a brief downtime for the DataIntell API as it will undergo a restart.
          </span>
          <Button
            className="ms-auto"
            color="danger"
            onClick={cancelAllJobs}
          >
            <FontAwesomeIcon icon={faCancel} />
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <span className="pe-4">
            <h6>Cancel all running jobs</h6>
            Cancel all running jobs. This will necessitate a brief downtime for the DataIntell API as it will undergo a restart.
          </span>
          <Button
            className="ms-auto"
            color="danger"
            onClick={cancelRunningJobs}
          >
            <FontAwesomeIcon icon={faCancel} />
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <span className="pe-4">
            <h6>Cancel all waiting jobs</h6>
            Cancel all waiting jobs.
          </span>
          <Button
            className="ms-auto"
            color="danger"
            onClick={cancelWaitingJobs}
          >
            <FontAwesomeIcon icon={faCancel} />
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCloseModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}