import { useState } from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";

export default function ChangeSodaSecretModal(props) {
  const {
    isOpen,
    setIsOpen,
    updateClientSecretFunc
  } = props;
  
  const [clientSecret, setClientSecret] = useState("");

  const onClose = () => {
    setClientSecret("");
    setIsOpen(false);
  };

  return (
    <ConfirmModal
      confirmAction={() => {
        updateClientSecretFunc([{
          name: "soda.secret",
          description: "Client secret",
          value: clientSecret
        }]);
        onClose();
      }}
      showConfirmation={isOpen}
      title="Change client secret"
      closeConfirmation={() => onClose()}
      buttonColor="primary"
      buttonText="Save"
    >
      <Form>
        <FormGroup row>
          <Label sm={4}>
            Client secret
          </Label>
          <Col sm={8}>
            <Input
              value={clientSecret}
              onChange={e => setClientSecret(e.target.value)}
            />
          </Col>
        </FormGroup>
      </Form>
    </ConfirmModal>
  );
}