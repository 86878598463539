import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchFolders } from "../ducks/fileManagement";
import { fetchVolumes } from "../actions";
import FolderNavigation from "../components/FolderNavigation";

function FolderTree(props) {
  const {
    dispatch,
    folders,
    volumes,
    path,
    volumeId,
    volumeFilter,
    folderFilter,
    selectVolume,
    changePath,
    listVolumes,
    AddFolderComponent,
    getVolumeAnalytics,
    moveToSearch,
    volumeSettings,
    updateFolder,
    updateTags,
    hideActionColumn,
    addBreadcrumb,
  } = props;

  const [filteredVolumes, setFilteredVolumes] = useState(volumes);
  const [filteredFolders, setFilteredFolders] = useState(folders);
  const [foldersSize, setFoldersSize] = useState(25);
  const [foldersPage, setFoldersPage] = useState(1);

  useEffect(() => {
    const numberOfElementsFolders = window.localStorage.getItem("numberOfElementsFileManagementFolders");
    
    if (numberOfElementsFolders) {
      setFoldersSize(Number(numberOfElementsFolders));
    }
  }, []);

  useEffect(() => {
    if (volumeId) {
      const folderSortValue = window.localStorage.getItem("folderSort");
      const folderDirectionValue = window.localStorage.getItem("folderDirection");

      dispatch(
        fetchFolders(
          path, 
          volumeId, 
          foldersPage, 
          folderSortValue ?? "size", 
          folderDirectionValue ?? "desc", 
          true, 
          foldersSize
        )
      );
    } else {
      const volumeSortValue = window.localStorage.getItem("volumeSort");
      const volumeDirectionValue = window.localStorage.getItem("volumeDirection");

      dispatch(
        fetchVolumes(
          1,
          volumeSortValue ?? "name",
          volumeDirectionValue ?? "asc"
        )
      );
    }
  }, [path, volumeId, dispatch, foldersPage]);

  useEffect(() => {
    if (undefined !== volumeFilter) {
      const filteredVolumesItems = volumes.items.filter((volume) =>
        volume.name.toLowerCase().includes(volumeFilter.toLowerCase())
      );
      setFilteredVolumes({ ...volumes, items: filteredVolumesItems });
    }
  }, [volumeFilter, volumes]);

  useEffect(() => {
    if (undefined !== folderFilter) {
      const filteredFoldersItems = folders.items.filter((folder) =>
        folder.name.toLowerCase().includes(folderFilter.toLowerCase())
      );
      setFilteredFolders({ ...folders, items: filteredFoldersItems });
    }
  }, [folderFilter, folders]);

  const changeFoldersOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === folders.sort) {
      newDirection = folders.direction === "asc" ? "desc" : "asc";
    }

    window.localStorage.setItem("folderSort", newSort);
    window.localStorage.setItem("folderDirection", newDirection);

    dispatch(
      fetchFolders(
        path,
        volumeId,
        folders.page,
        newSort,
        newDirection,
        true,
        foldersSize
      )
    );
  };

  const changeVolumesOrder = (newSort) => {
    let newDirection = "asc";
    if (newSort === volumes.sort) {
      newDirection = volumes.direction === "asc" ? "desc" : "asc";
    }

    window.localStorage.setItem("volumeSort", newSort);
    window.localStorage.setItem("volumeDirection", newDirection);

    dispatch(fetchVolumes(1, newSort, newDirection));
  };

  const handleChangeNumberOfElementsFolders = (numberOfElements) => {
    window.localStorage.setItem("numberOfElementsFileManagementFolders", numberOfElements);
    setFoldersSize(numberOfElements);
    setFoldersPage(1);

    dispatch(
      fetchFolders(
        path,
        volumeId,
        1,
        folders.sort,
        folders.direction,
        true,
        numberOfElements
      )
    );
  };

  return (
    <FolderNavigation
      volumeId={volumeId}
      volumes={filteredVolumes}
      folders={filteredFolders}
      folderFilter={folderFilter}
      changePath={changePath}
      listVolumes={listVolumes}
      selectFoldersPage={(page) => setFoldersPage(page)}
      setNumberOfElements={numberOfElements => handleChangeNumberOfElementsFolders(numberOfElements)}
      selectVolume={selectVolume}
      changeVolumesOrder={changeVolumesOrder}
      changeFoldersOrder={changeFoldersOrder}
      path={path}
      getVolumeAnalytics={getVolumeAnalytics}
      AddFolderComponent={AddFolderComponent}
      moveToSearch={moveToSearch}
      volumeSettings={volumeSettings}
      updateFolder={updateFolder}
      updateTags={updateTags}
      hideActionColumn={hideActionColumn}
      addBreadcrumb={addBreadcrumb}
    />
  );
}

function mapStateToProps(state) {
  const { fileManagement, volumeSettings, volumes } = state;
  const { folders } = fileManagement;

  return {
    folders,
    volumes,
    volumeSettings,
  };
}

export default connect(mapStateToProps)(FolderTree);
