import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { fetchVolumeSettings } from "./volumeSettings";
import { formatError } from "../reducers/formatError";

// === Actions ===

// READ
const REQUEST_ARCHIWARE_LIST = "REQUEST_ARCHIWARE_LIST";
const RECEIVE_ARCHIWARE_LIST = "RECEIVE_ARCHIWARE_LIST";
// UPDATE
const REQUEST_UPDATE_ARCHIWARE = "REQUEST_UPDATE_ARCHIWARE";
const RECEIVE_UPDATE_ARCHIWARE = "RECEIVE_UPDATE_ARCHIWARE";
// DELETE
const REQUEST_DELETE_ARCHIWARE = "REQUEST_DELETE_ARCHIWARE";
const RECEIVE_DELETE_ARCHIWARE = "RECEIVE_DELETE_ARCHIWARE";
// ERRORS
const RECEIVE_INVALID_ARCHIWARE = "RECEIVE_INVALID_ARCHIWARE";

// === Reducers ===
export default function archiwares(
  state = {
    items: [],
    page: 1,
    total: 0,
    size: 0,
    isFetching: false,
    errors: {},
    formValid: true,
  },
  action
) {
  switch (action.type) {
    case REQUEST_ARCHIWARE_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ARCHIWARE_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.archiwares.elements,
        page: action.archiwares.page,
        total: action.archiwares.total,
        size: action.archiwares.size,
        sort: action.archiwares.sort,
        direction: action.archiwares.direction,
        errors: {},
        formValid: true,
      });
    case RECEIVE_INVALID_ARCHIWARE:
      return Object.assign({}, state, {
        errors: formatError(action.errors),
        formValid: false,
      });

    default:
      return state;
  }
}

// === Action Creators ===
function requestArchiwareList() {
  return {
    type: REQUEST_ARCHIWARE_LIST,
  };
}

function receiveArchiwareList(archiwares) {
  return {
    type: RECEIVE_ARCHIWARE_LIST,
    archiwares,
  };
}

function requestUpdateArchiware() {
  return {
    type: REQUEST_UPDATE_ARCHIWARE,
  };
}

function receiveUpdateArchiware(updatedArchiware) {
  return {
    type: RECEIVE_UPDATE_ARCHIWARE,
    archiware: updatedArchiware,
  };
}

function requestDeleteArchiware(id) {
  return {
    type: REQUEST_DELETE_ARCHIWARE,
    id,
  };
}

function receiveDeleteArchiware(id) {
  return {
    type: RECEIVE_DELETE_ARCHIWARE,
    id,
  };
}
function receiveInvalidArchiware(errors) {
  return {
    type: RECEIVE_INVALID_ARCHIWARE,
    errors,
  };
}

// === Side Effects ===
export function fetchArchiwareList(
  page = 1,
  sort = "id",
  direction = "asc",
  size = window.localStorage.getItem("numberOfElementsDataSourceArchiware")
    ? Number(window.localStorage.getItem("numberOfElementsDataSourceArchiware"))
    : 10
) {
  return async (dispatch, getState) => {
    window.localStorage.setItem("numberOfElementsDataSourceArchiware", size);
    dispatch(requestArchiwareList());
    const url = `/v1/archiwares?page=${page}&size=${size}&sort=${sort}&direction=${direction}`;
    try {
      const json = await fetchWithAuth(
        dispatch,
        url,
        getState().auth.accessToken
      );
      dispatch(receiveArchiwareList(json));
    } catch (err) {
      const message = err.statusText || `Unable to get the list of archiwares`;
      dispatch(setAlert(message, "danger"));
    }
  };
}

export function deleteArchiware(id) {
  return async (dispatch, getState) => {
    dispatch(requestDeleteArchiware(id));
    try {
      await fetchWithAuth(
        dispatch,
        `/v1/archiwares/${id}`,
        getState().auth.accessToken,
        "DELETE"
      );
      dispatch(
        fetchArchiwareList(
          1,
          getState().archiwares.sort,
          getState().archiwares.direction,
          getState().archiwares.size
        )
      );
      dispatch(receiveDeleteArchiware(id));
      dispatch(fetchVolumeSettings());
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
  };
}

export function updateArchiware(id, archiware) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateArchiware());
    try {
      const updatedArchiware = await fetchWithAuth(
        dispatch,
        `/v1/archiwares/${id}`,
        getState().auth.accessToken,
        "PUT",
        JSON.stringify(archiware)
      );
      dispatch(receiveUpdateArchiware(updatedArchiware));
      dispatch(
        fetchArchiwareList(
          1,
          getState().archiwares.sort,
          getState().archiwares.direction,
          getState().archiwares.size
        )
      );
      dispatch(fetchVolumeSettings());
    } catch (err) {
      if (err.subErrors) dispatch(receiveInvalidArchiware(err));
      else {
        const message = err.message || "Unable to update archiware";
        dispatch(setAlert(message, "danger"));
      }
    }
  };
}
