import { fetchWithAuth } from "../actions/requestHelper";

export const getVolumeById = async (
  dispatch,
  auth,
  id,
  from,
  to,
  page = 1,
  size = 1000,
  sort = "indexingDate",
  direction = "desc"
) => {
  const url = `/v1/detailedVolumes/${id}?page=${page}&sort=${sort}&direction=${direction}&size=${size}&from=${from}&to=${to}`;
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};

export const getVolumeSettings = async (
  dispatch,
  auth,
  page = 1,
  sort = "name",
  direction = "asc",
  size = 10,
  filterType = []
) => {
  var url = `/volumeSettings?page=${page}&sort=${sort},${direction}&size=${size}`;
  filterType.forEach((filter) => (url += `&filterType=${filter}`));
  return await fetchWithAuth(dispatch, url, auth.accessToken);
};
