import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from "reactstrap";
import EditFileTags from "./EditFileTags";
import { updateTags } from "../../ducks/tag";
import { updateFileManagementFile } from "../../ducks/fileManagement";
import { updateSearchFile } from "../../ducks/search";

export default function ManageTags(props) {
  const {
    file,
    updateSelectedFile = () => {},
    isTagFileOpen,
    setIsTagFileOpen
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const updateFile = tags => {
    const updatedFile = { ...file, customTag: tags };

    if (location.pathname === "/filemanagement") {
      dispatch(updateFileManagementFile(file.fileId, updatedFile))
    } else {
      dispatch(updateSearchFile(file.fileId, updatedFile));
    }

    updateSelectedFile(updatedFile);
  };

  return (
    <>
      <FontAwesomeIcon
        id="tagFile"
        icon={faTag}
        onClick={e => e.stopPropagation()}
      />
      <UncontrolledTooltip target="tagFile" placement="auto">
        Manage tags
      </UncontrolledTooltip>
      <Popover
        target="tagFile"
        placement="right"
        trigger="legacy"
        isOpen={isTagFileOpen}
        toggle={() => setIsTagFileOpen(!isTagFileOpen)}
        onClick={e => e.stopPropagation()}
      >
        <PopoverHeader>Save tags</PopoverHeader>
        <PopoverBody>
          <EditFileTags
            fileTags={file.customTag ?? []}
            updateFile={tags => updateFile(tags)}
            updateTags={tags => dispatch(updateTags(tags))}
            closeFunc={() => setIsTagFileOpen(false)}
            isSave
          />
        </PopoverBody>
      </Popover>
    </>
  );
}