import { Card, CardBody, CardHeader } from "reactstrap";
import ParameterList from "../parameters/ParameterList";

export default function ReportParameters(props) {
  const { references, comparison, reportType, searchQueryNode } = props;

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader tag="h5">
        <span>Parameters</span>
      </CardHeader>
      <CardBody>
        <ParameterList
          references={references}
          comparison={comparison}
          reportType={reportType}
          searchQueryNode={searchQueryNode}
        />
      </CardBody>
    </Card>
  );
}
