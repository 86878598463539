import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import RectangleChart from "../../components/RectangleChart";
import moment from "moment";
import { getStatistics } from "../../utils/getStatistics";
import FormatSize from "../../components/FormatSize";

const COLOR_DATE = [
  "#000000",
  "#070D12",
  "#0F1A24",
  "#162636",
  "#1E3348",
  "#25405B",
  "#2C4D6B",
  "#34597F",
  "#3B6691",
  "#4373A3",
  "#4A80B5",
  "#5C8CBC",
  "#6E99C4",
  "#80A6CB",
  "#92B3D3",
  "#A4BFDA",
  "#B7CCE1",
  "#C9D9E9",
  "#DBE6F0",
  "#EDF2F8",
  "#FBFBFB"
];

export default function VolumeDateChart(props) {
  const {
    latestDetailedVolume,
    before,
    after,
    name,
    title
  } = props;

  const navigate = useNavigate();

  const getSizeForDate = (key) => {
    return latestDetailedVolume.statistics[name] && latestDetailedVolume.statistics[name][key] ?
      latestDetailedVolume.statistics[name][key].sizeOfFiles : 0;
  };

  const date10Years = getSizeForDate("10 year");
  const date9Years = getSizeForDate("9 year") - getSizeForDate("10 year");
  const date8Years = getSizeForDate("8 year") - getSizeForDate("9 year");
  const date7Years = getSizeForDate("7 year") - getSizeForDate("8 year");
  const date6Years = getSizeForDate("6 year") - getSizeForDate("7 year");
  const date5Years = getSizeForDate("5 year") - getSizeForDate("6 year");
  const date4Years = getSizeForDate("4 year") - getSizeForDate("5 year");
  const date3Years = getSizeForDate("3 year") - getSizeForDate("4 year");
  const date2Years9Months = getSizeForDate("2 year 9 month") - getSizeForDate("3 year");
  const date2Years6Months = getSizeForDate("2 year 6 month") - getSizeForDate("2 year 9 month");
  const date2Years3Months = getSizeForDate("2 year 3 month") - getSizeForDate("2 year 6 month");
  const date2Years = getSizeForDate("2 year") - getSizeForDate("2 year 3 month");
  const date1Year9Months = getSizeForDate("1 year 9 month") - getSizeForDate("2 year");
  const date1Year6Months = getSizeForDate("1 year 6 month") - getSizeForDate("1 year 9 month");
  const date1Year3Months = getSizeForDate("1 year 3 month") - getSizeForDate("1 year 6 month");
  const date1Year = getSizeForDate("1 year") - getSizeForDate("1 year 3 month");
  const date6Months = getSizeForDate("6 month") - getSizeForDate("1 year");
  const date3Months = getSizeForDate("3 month") - getSizeForDate("6 month");
  const date1Month = getSizeForDate("1 month") - getSizeForDate("3 month");
  const date1Week = getSizeForDate("1 week") - getSizeForDate("1 month");
  const other = latestDetailedVolume.sizeOfFiles - getSizeForDate("1 week");

  const linksForChart = [
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(10, "years")
      .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(9, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(10, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(8, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(9, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(7, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(8, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(6, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(7, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(5, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(6, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(4, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(5, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(3, "years")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(4, "years")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(33, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(36, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(30, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(33, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(27, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(30, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(24, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(27, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(21, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(24, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(18, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(21, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(15, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(18, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(12, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(15, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(6, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(12, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(3, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(6, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(1, "months")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(3, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}&${after}=${moment(latestDetailedVolume.indexingDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD")}`,
    `/search?volumeId=${latestDetailedVolume.id}&${after}=${moment(latestDetailedVolume.indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}`
  ];

  const linksForLabels = {
    "10 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(10, "years")
      .format("YYYY-MM-DD")}`,
    "9 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(9, "years")
      .format("YYYY-MM-DD")}`,
    "8 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(8, "years")
      .format("YYYY-MM-DD")}`,
    "7 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(7, "years")
      .format("YYYY-MM-DD")}`,
    "6 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(6, "years")
      .format("YYYY-MM-DD")}`,
    "5 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(5, "years")
      .format("YYYY-MM-DD")}`,
    "4 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(4, "years")
      .format("YYYY-MM-DD")}`,
    "3 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(3, "years")
      .format("YYYY-MM-DD")}`,
    "2 year 9 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(33, "months")
      .format("YYYY-MM-DD")}`,
    "2 year 6 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(30, "months")
      .format("YYYY-MM-DD")}`,
    "2 year 3 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(27, "months")
      .format("YYYY-MM-DD")}`,
    "2 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(24, "months")
      .format("YYYY-MM-DD")}`,
    "1 year 9 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(21, "months")
      .format("YYYY-MM-DD")}`,
    "1 year 6 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(18, "months")
      .format("YYYY-MM-DD")}`,
    "1 year 3 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(15, "months")
      .format("YYYY-MM-DD")}`,
    "1 year": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(12, "months")
      .format("YYYY-MM-DD")}`,
    "6 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(6, "months")
      .format("YYYY-MM-DD")}`,
    "3 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(3, "months")
      .format("YYYY-MM-DD")}`,
    "1 month": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(1, "months")
      .format("YYYY-MM-DD")}`,
    "1 week": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD")}`,
    "today": `/search?volumeId=${latestDetailedVolume.id}&${before}=${moment(latestDetailedVolume.indexingDate)
      .format("YYYY-MM-DD")}`
  };

  const labels = [
    <>Older than 10 years: <FormatSize>{date10Years}</FormatSize></>,
    <>Between 9 years and 10 years: <FormatSize>{date9Years}</FormatSize></>,
    <>Between 8 years and 9 years: <FormatSize>{date8Years}</FormatSize></>,
    <>Between 7 years and 8 years: <FormatSize>{date7Years}</FormatSize></>,
    <>Between 6 years and 7 years: <FormatSize>{date6Years}</FormatSize></>,
    <>Between 5 years and 6 years: <FormatSize>{date5Years}</FormatSize></>,
    <>Between 4 years and 5 years: <FormatSize>{date4Years}</FormatSize></>,
    <>Between 3 years and 4 years: <FormatSize>{date3Years}</FormatSize></>,
    <>Between 2 years 9 months and 3 years: <FormatSize>{date2Years9Months}</FormatSize></>,
    <>Between 2 years 6 months and 2 years 9 months: <FormatSize>{date2Years6Months}</FormatSize></>,
    <>Between 2 years 3 months and 2 years 6 months: <FormatSize>{date2Years3Months}</FormatSize></>,
    <>Between 2 years and 2 years 3 months: <FormatSize>{date2Years}</FormatSize></>,
    <>Between 1 year 9 months and 2 years: <FormatSize>{date1Year9Months}</FormatSize></>,
    <>Between 1 year 6 months and 1 year 9 months: <FormatSize>{date1Year6Months}</FormatSize></>,
    <>Between 1 year 3 months and 1 year 6 months: <FormatSize>{date1Year3Months}</FormatSize></>,
    <>Between 1 year and 1 year 3 months: <FormatSize>{date1Year}</FormatSize></>,
    <>Between 6 months and 1 year: <FormatSize>{date6Months}</FormatSize></>,
    <>Between 3 months and 6 months: <FormatSize>{date3Months}</FormatSize></>,
    <>Between 1 month and 3 months: <FormatSize>{date1Month}</FormatSize></>,
    <>Between 1 week and 1 month: <FormatSize>{date1Week}</FormatSize></>,
    <>Younger than 1 week: <FormatSize>{other}</FormatSize></>
  ];

  const data = [
    date10Years,
    date9Years,
    date8Years,
    date7Years,
    date6Years,
    date5Years,
    date4Years,
    date3Years,
    date2Years9Months,
    date2Years6Months,
    date2Years3Months,
    date2Years,
    date1Year9Months,
    date1Year6Months,
    date1Year3Months,
    date1Year,
    date6Months,
    date3Months,
    date1Month,
    date1Week,
    other
  ];

  return (
    <>
      {latestDetailedVolume.statistics[name] &&
        <Card className="mb-3">
          <CardHeader>
            <CardTitle tag="h5">
              <b>{title} Date</b>
            </CardTitle>
            <CardSubtitle tag="h6">
              <span className="mb-2 text-muted">
                Size of files
              </span>
            </CardSubtitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={12} className="mb-2">
                <RectangleChart
                  links={linksForChart}
                  handleClick={(path) => navigate(path)}
                  labels={labels}
                  data={data}
                  colors={COLOR_DATE}
                />
              </Col>

              {getStatistics({
                [`Older than 10 years`]: { size: getSizeForDate("10 year"), link: linksForLabels["10 year"] },
                [`Older than 9 years`]: { size: getSizeForDate("9 year"), link: linksForLabels["9 year"] },
                [`Older than 8 years`]: { size: getSizeForDate("8 year"), link: linksForLabels["8 year"] },
                [`Older than 7 years`]: { size: getSizeForDate("7 year"), link: linksForLabels["7 year"] },
                [`Older than 6 years`]: { size: getSizeForDate("6 year"), link: linksForLabels["6 year"] },
                [`Older than 5 years`]: { size: getSizeForDate("5 year"), link: linksForLabels["5 year"] },
                [`Older than 4 years`]: { size: getSizeForDate("4 year"), link: linksForLabels["4 year"] },
                [`Older than 3 years`]: { size: getSizeForDate("3 year"), link: linksForLabels["3 year"] },
                [`Older than 2 years 9 months`]: { size: getSizeForDate("2 year 9 month"), link: linksForLabels["2 year 9 month"] },
                [`Older than 2 years 6 months`]: { size: getSizeForDate("2 year 6 month"), link: linksForLabels["2 year 6 month"] },
                [`Older than 2 years 3 months`]: { size: getSizeForDate("2 year 3 month"), link: linksForLabels["2 year 3 month"] },
                [`Older than 2 years`]: { size: getSizeForDate("2 year"), link: linksForLabels["2 year"] },
                [`Older than 1 year 9 months`]: { size: getSizeForDate("1 year 9 month"), link: linksForLabels["1 year 9 month"] },
                [`Older than 1 year 6 months`]: { size: getSizeForDate("1 year 6 month"), link: linksForLabels["1 year 6 month"] },
                [`Older than 1 year 3 months`]: { size: getSizeForDate("1 year 3 month"), link: linksForLabels["1 year 3 month"] },
                [`Older than 1 year`]: { size: getSizeForDate("1 year"), link: linksForLabels["1 year"] },
                [`Older than 6 months`]: { size: getSizeForDate("6 month"), link: linksForLabels["6 month"] },
                [`Older than 3 months`]: { size: getSizeForDate("3 month"), link: linksForLabels["3 month"] },
                [`Older than 1 month`]: { size: getSizeForDate("1 month"), link: linksForLabels["1 month"] },
                [`Older than 1 week`]: { size: getSizeForDate("1 week"), link: linksForLabels["1 week"] },
                [`Older than today`]: { size: latestDetailedVolume.sizeOfFiles, link: linksForLabels["today"] }
              })}
            </Row>
          </CardBody>
        </Card>
      }
    </>
  );
}