import { useEffect, useState } from "react";
import { getReportJobs, getReportTemplate } from "../api/report";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../actions/alert";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { search, searchSize } from "../api/search";
import { formatSize } from "../components/FormatSize";
import { fetchVolumes } from "../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTrash,
  faEdit,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import ReportDetails from "./components/ReportDetails";
import ReportCircleChart from "./components/ReportCircleChart";
import ReportParameters from "./components/ReportParameters";
import ReportLargestElements from "./components/ReportLargestElements";
import { fetchProjectList } from "../ducks/project";
import Loading from "../components/Loading";
import { getAllReportTemplatesPaginated } from "../api/report";
import UpdateReportModal from "./UpdateReportModal";
import DeleteReportModal from "./DeleteReportModal";
import LaunchReportModal from "./LaunchReportModal";
import { useParams, useNavigate } from "react-router-dom";

export const REPORT_HASH = "e98d2f001da";

export default function Report(props) {
  const [showLaunchReportModal, setShowLaunchReportModal] = useState(false);
  const [showUpdateReportModal, setShowUpdateReportModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [reportTemplate, setReportTemplate] = useState(null);
  const [filesFound, setFilesFound] = useState({
    elements: [],
    total: 0,
    page: 1,
    size: 10,
    sort: "size",
    direction: "desc",
  });
  const [filesTotal, setFilesTotal] = useState({
    elements: [],
    total: 0,
    page: 1,
    size: 0,
    sort: "size",
    direction: "desc",
  });
  const [foldersFound, setFoldersFound] = useState({
    elements: [],
    total: 0,
    page: 1,
    size: 10,
    sort: "size",
    direction: "desc",
  });
  const [foldersTotal, setFoldersTotal] = useState({
    elements: [],
    total: 0,
    page: 1,
    size: 0,
    sort: "size",
    direction: "desc",
  });
  const [sizeOfFilesFound, setSizeOfFilesFound] = useState(null);
  const [sizeOfFilesTotal, setSizeOfFilesTotal] = useState(null);
  const [reportTag, setReportTag] = useState("");
  const [filesizeObject, setFilesizeObject] = useState(null);
  const [reports, setReports] = useState({
    elements: [],
    page: 1,
    total: 0,
    size: 25,
    sort: "name",
    direction: "asc",
  });
  const [jobs, setJobs] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const indexingDate = useSelector(({ dates }) => dates.activeDate);
  const baseSize = useSelector(({ userSettings }) => userSettings.baseValue);
  const volumes = useSelector(({ volumes }) => volumes);
  const projects = useSelector(({ projectList }) => projectList);

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    getReportTemplate(dispatch, auth, params.id)
      .then((res) => {
        setReportTemplate(res);
        setReportTag(`${res.name}-${res.lastExecutionDate}`);
      })
      .catch((err) => {
        console.error(err);
        dispatch(setAlert("Error while getting the report.", "danger", 8000));
      });

    getReportJobs(dispatch, auth, params.id)
      .then((res) => setJobs(res))
      .catch((err) => {
        console.error(err);
        dispatch(setAlert("Error while getting the jobs for the report.", "danger", 8000));
      })

    dispatch(fetchVolumes());
    dispatch(fetchProjectList(1, "name", "asc", 200));
  }, [params.id, reports]);

  useEffect(() => {
    if (
      reportTemplate !== null &&
      !volumes.isFetching &&
      !projects.isFetching
    ) {
      const formattedReportTag = REPORT_HASH + "-" + reportTag;

      const searchFiltersForFilesFound = [
        {
          customTags: [{ value: formattedReportTag }],
          isDirectory: false,
          isDeleted: false,
        },
      ];
      const searchFiltersForFoldersFound = [
        {
          customTags: [{ value: formattedReportTag }],
          isDirectory: true,
          isDeleted: false,
        },
      ];

      const searchFiltersForFilesTotal = [];
      const searchFiltersForFoldersTotal = [];
      if (reportTemplate.parameters.references) {
        reportTemplate.parameters.references.forEach((reference) => {
          if (reference.type === "VOLUME") {
            searchFiltersForFilesTotal.push(
              ...getSearchFiltersForVolume(reference, false)
            );
            searchFiltersForFoldersTotal.push(
              ...getSearchFiltersForVolume(reference, true)
            );
          } else {
            searchFiltersForFilesTotal.push(
              ...getSearchFiltersForProject(reference, false)
            );
            searchFiltersForFoldersTotal.push(
              ...getSearchFiltersForProject(reference, true)
            );
          }
        });
      } else {
        searchFiltersForFilesTotal.push({
          isDirectory: false,
          isDeleted: false,
        });
        searchFiltersForFoldersTotal.push({
          isDirectory: true,
          isDeleted: false,
        });
      }

      search(
        dispatch,
        auth,
        indexingDate,
        searchFiltersForFilesFound,
        1,
        10,
        "size",
        "DESC"
      )
        .then((res) => setFilesFound(res))
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the files found for this report.",
              "danger",
              8000
            )
          );
        });

      search(
        dispatch,
        auth,
        indexingDate,
        searchFiltersForFoldersFound,
        1,
        10,
        "size",
        "DESC"
      )
        .then((res) => setFoldersFound(res))
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the folders found for this report.",
              "danger",
              8000
            )
          );
        });

      searchSize(dispatch, auth, indexingDate, searchFiltersForFilesFound)
        .then((res) => setSizeOfFilesFound(res))
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the size of files found for this report.",
              "danger",
              8000
            )
          );
        });

      search(
        dispatch,
        auth,
        indexingDate,
        searchFiltersForFilesTotal,
        1,
        0,
        "size",
        "DESC"
      )
        .then((res) => setFilesTotal(res))
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the total files for this report.",
              "danger",
              8000
            )
          );
        });

      search(
        dispatch,
        auth,
        indexingDate,
        searchFiltersForFoldersTotal,
        1,
        0,
        "size",
        "DESC"
      )
        .then((res) => {
          setFoldersTotal(res);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the total folders for this report.",
              "danger",
              8000
            )
          );
        });

      searchSize(dispatch, auth, indexingDate, searchFiltersForFilesTotal)
        .then((res) => {
          setSizeOfFilesTotal(res);
          setFilesizeObject(formatSize(res, baseSize, null, "object"));
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            setAlert(
              "Error while getting the size of total files for this report.",
              "danger",
              8000
            )
          );
        });
    }
  }, [reportTemplate, volumes.isFetching, projects.isFetching]);

  const fetchReports = (page, size, sort, direction) => {
    getAllReportTemplatesPaginated(dispatch, auth, page, size, sort, direction)
      .then((res) => setReports(res))
      .catch((err) =>
        dispatch(setAlert("Error while fetching the list of reports", "danger"))
      );
  };

  const getSearchFiltersForVolume = (reference, isDirectory) => {
    return [
      {
        volumeId: {
          value: reference.id,
        },
        path: {
          value: reference.path,
        },
        isDirectory: isDirectory,
        isDeleted: false,
      },
      {
        volumeId: {
          value: reference.id,
        },
        path: {
          startingWith: (reference.path === "/" ? "" : reference.path) + "/",
        },
        isDirectory: isDirectory,
        isDeleted: false,
      },
    ];
  };

  const getSearchFiltersForProject = (reference, isDirectory) => {
    const searchFiltersList = [];
    const project = projects.items.find(
      (project) => project.id === reference.id
    );

    if (project === undefined) {
      return [];
    }

    const projectPaths = project.paths;

    projectPaths.forEach((path) =>
      searchFiltersList.push(
        {
          volumeId: {
            value: path.volumeSetting.id,
          },
          path: {
            value: path.path,
          },
          isDirectory: isDirectory,
          isDeleted: false,
        },
        {
          volumeId: {
            value: path.volumeSetting.id,
          },
          path: {
            startingWith: (path.path === "/" ? "" : path.path) + "/",
          },
          isDirectory: isDirectory,
          isDeleted: false,
        }
      )
    );

    return searchFiltersList;
  };

  return (
    <>
      {volumes.isFetching || projects.isFetching ? (
        <Loading />
      ) : (
        reportTemplate !== null && (
          <>
            <div className="d-flex">
              <h1>
                {reportTemplate.name}
              </h1>
              <div className="ms-auto">
                <Button
                  id="search"
                  color="info"
                  onClick={() => navigate(`/search?report=${reportTag}`)}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
                <UncontrolledTooltip placement="auto" target="search">
                  Show content as Search result
                </UncontrolledTooltip>

                <Button
                  id="launch"
                  className="ms-1"
                  color="success"
                  onClick={() => setShowLaunchReportModal(true)}
                >
                  <FontAwesomeIcon icon={faPlay} />
                </Button>
                <UncontrolledTooltip placement="auto" target="launch">
                  Launch Report
                </UncontrolledTooltip>
                <Button
                  id="edit"
                  className="ms-1"
                  color="secondary"
                  onClick={() => setShowUpdateReportModal(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <UncontrolledTooltip placement="auto" target="edit">
                  Edit Report
                </UncontrolledTooltip>
                <Button
                  id="delete"
                  className="ms-1"
                  color="danger"
                  onClick={() => setShowDeleteReportModal(true)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <UncontrolledTooltip placement="auto" target="delete">
                  Delete Report
                </UncontrolledTooltip>
              </div>
            </div>
            <Row>
              <Col md={6} lg={4} className="pb-3">
                <ReportDetails
                  reportTemplate={reportTemplate}
                  numberOfFilesFound={filesFound.total}
                  numberOfFilesTotal={filesTotal.total}
                  numberOfFoldersFound={foldersFound.total}
                  numberOfFoldersTotal={foldersTotal.total}
                  sizeOfFilesFound={sizeOfFilesFound}
                  sizeOfFilesTotal={sizeOfFilesTotal}
                  jobs={jobs}
                />
              </Col>
              <Col md={6} lg={4} className="pb-3">
                <ReportCircleChart
                  filesizeObject={filesizeObject}
                  baseSize={baseSize}
                  numberOfFilesFound={filesFound.total}
                  numberOfFilesTotal={filesTotal.total}
                  numberOfFoldersFound={foldersFound.total}
                  numberOfFoldersTotal={foldersTotal.total}
                  sizeOfFilesFound={sizeOfFilesFound}
                  sizeOfFilesTotal={sizeOfFilesTotal}
                  reportType={reportTemplate.type}
                />
              </Col>
              <Col md={12} lg={4} className="pb-3">
                <ReportParameters
                  references={reportTemplate.parameters.references}
                  comparison={reportTemplate.parameters.comparison}
                  reportType={reportTemplate.type}
                  searchQueryNode={reportTemplate.parameters.searchQueryNode}
                />
              </Col>
            </Row>
            <ReportLargestElements
              reportTag={reportTag}
              fileFoundElements={filesFound.elements}
              folderFoundElements={foldersFound.elements}
            />

            <LaunchReportModal
              id={reportTemplate.id}
              fetchReports={() =>
                fetchReports(1, reports.size, reports.sort, reports.direction)
              }
              name={reportTemplate.name}
              showLaunchReportModal={showLaunchReportModal}
              closeModal={() => {
                setShowLaunchReportModal(false);
              }}
            />

            <UpdateReportModal
              reportTemplate={reportTemplate}
              fetchReports={() => {
                fetchReports(1, reports.size, reports.sort, reports.direction);
              }}
              showUpdateReportModal={showUpdateReportModal}
              closeModal={() => {
                setShowUpdateReportModal(false);
              }}
            />

            <DeleteReportModal
              id={reportTemplate.id}
              fetchReports={() =>
                fetchReports(1, reports.size, reports.sort, reports.direction)
              }
              name={reportTemplate.name}
              showDeleteReportModal={showDeleteReportModal}
              closeModal={() => {
                setShowDeleteReportModal(false);
              }}
              onDelete={() => {
                navigate("/reports");
              }}
            />
          </>
        )
      )}
    </>
  );
}
