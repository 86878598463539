import { fetchWithAuth, fetchWithAuthV2 } from "../actions/requestHelper";

export const getConfigurationByName = async (
  dispatch,
  auth,
  name
) => {
  const url = `/v1/configurations/${name}`;

  return await fetchWithAuth(dispatch, url, auth.accessToken, "GET");
};

export const isOktaAPITokenValid = async (
  dispatch,
  auth,
  domain,
  apiToken
) => {
  const url = "/v1/configurations/validate/okta";

  return await fetchWithAuthV2(
    dispatch,
    url,
    auth.accessToken,
    {},
    "POST",
    JSON.stringify({
      domain,
      apiToken
    })
  );
}