import { connect } from "react-redux";
import Projects from "./Projects";

function mapStateToProps(state) {
  const { projectHandling } = state;
  const isFetchingProjectCreation = projectHandling.isFetching;

  return { isFetchingProjectCreation };
}

export default connect(mapStateToProps)(Projects);
