import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../actions/alert";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../components/ConfirmModal";
import { generateReport } from "../api/report";

export default function LaunchReportModal(props) {
  const {
    id,
    name,
    showLaunchReportModal,
    closeModal
  } = props;

  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const navigate = useNavigate();

  const launchReportFunc = () => {
    generateReport(dispatch, auth, id)
      .then(res => {
        closeModal();
        dispatch(setAlert(<>
          A job for the report template {name} has been launched successfully. You can monitor the
          job by clicking
          <a
            className="text-primary"
            onClick={() => navigate(`/settings/jobs?id=${res.id}`)}
          >
            {" "}
            here
          </a>
        </>,
          "success",
          9000));
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(`An error occurred while launching the report template [${name}].`, "danger", 8000));
      })
  };

  return (
    <ConfirmModal
      showConfirmation={showLaunchReportModal}
      title="Launch Confirmation"
      closeConfirmation={() => closeModal()}
      buttonColor="primary"
      buttonText="Launch"
      confirmAction={() => launchReportFunc()}
    >
      <p>Are you sure you want to launch the report template {name}?</p>
      <span>Note that it can take a while for the tags to appear on the files and folders.</span>
    </ConfirmModal>
  );
}