import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Input, FormGroup, Label, InputGroup, Button } from "reactstrap";
import { getReports } from "../../api/file";
import { setAlert } from "../../actions/alert";

function TagField(props) {
    const { handleChange, isReport = false } = props;

    const [availableTags, setAvailableTags] = useState([]);
    const [inputTag, setInputTag] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const tags = useSelector(state => state.tags);
    const selected = useSelector(state => state.search.filters);

    const dispatch = useDispatch();
    const auth = useSelector(({ auth }) => auth);
    const indexingDate = useSelector(({ dates }) => dates.activeDate);

    useEffect(() => {
        if (isReport) {
            getReports(dispatch, auth, indexingDate)
                .then(res => setAvailableTags(res))
                .catch(err => {
                    console.error(err);
                    dispatch(setAlert("Unable to get the list of reports.", "danger", 8000));
                });

            if (selected.reports) {
                setSelectedTags(selected.reports);
            }
        }
    }, [selected]);

    useEffect(() => {
        if (!isReport) {
            if (!tags.isFetching) {
                setAvailableTags(tags.items);
            }
    
            if (selected.tags) {
                setSelectedTags(selected.tags);
            }
        }
    }, [tags, selected]);

    // This will sort alphabetically the list of tags or reports. 
    // It will also filter out tags if there is a value in the filter input.
    const getSortedTags = () => {
        return availableTags
            .sort((a, b) => a.localeCompare(b))
            .filter(tag => tag.toLowerCase().includes(inputTag.toLowerCase()));
    };

    return (
        <>
            <InputGroup className="mb-2">
                <Input
                    type="text"
                    value={inputTag}
                    placeholder={`Filter for specific ${isReport ? "reports" : "tags"}`}
                    onChange={e => setInputTag(e.target.value)}
                />
                <Button
                    outline
                    color="secondary"
                    onClick={() => {
                        setSelectedTags([]);
                        handleChange([]);
                    }}
                >
                    Clear selection
                </Button>
            </InputGroup>

            <Card style={{ maxHeight: "11.1rem", overflowY: "auto" }}>
                {
                    availableTags.length ?
                        <div style={{ margin: "10px 0", display: "flex", flexWrap: "wrap" }}>
                            {getSortedTags().map(tag => {
                                return (
                                    <FormGroup key={tag} check>
                                        <Label check style={{ margin: "5px 8px", overflowWrap: "anywhere" }}>
                                            <Input
                                                type="checkbox"
                                                onChange={() => {
                                                    if (undefined === selectedTags.find(t => t === tag)) {
                                                        const updatedSelectedTags = [...selectedTags, tag];
                                                        setSelectedTags(updatedSelectedTags);
                                                        handleChange(updatedSelectedTags);
                                                    } else {
                                                        const updatedSelectedTags = selectedTags.filter(t => t !== tag);
                                                        setSelectedTags(updatedSelectedTags);
                                                        handleChange(updatedSelectedTags);
                                                    }

                                                }}
                                                checked={selectedTags.some(t => t === tag)}
                                            />
                                            {tag}
                                        </Label>
                                    </FormGroup>
                                );
                            })}
                        </div> :
                        <h6 className="text-center mt-2 py-3">No available {isReport ? "reports" : "tags"} for this current date</h6>
                }
            </Card>
        </>
    )
}

export default TagField;