import { fetchWithAuth } from "../actions/requestHelper";
import { setAlert } from "../actions/alert";
import { fetchVolumeSettings } from "./volumeSettings";

// === Actions ===
// READ
const REQUEST_DATA_VOLUME_SETTINGS = "REQUEST_DATA_VOLUME_SETTINGS";
const RECEIVE_DATA_VOLUME_SETTINGS = "RECEIVE_DATA_VOLUME_SETTINGS";

// === Reducers ===
export default function dataVolumeSettings(
  state = {
    items: [],
    page: 1,
    total: 0,
    isFetching: false,
    size: window.localStorage.getItem("numberOfElementsDataSourceOnPrem")
      ? Number(window.localStorage.getItem("numberOfElementsDataSourceOnPrem"))
      : 10,
  },
  action
) {
  switch (action.type) {
    case REQUEST_DATA_VOLUME_SETTINGS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_DATA_VOLUME_SETTINGS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.volumes.elements,
        page: action.volumes.page,
        total: action.volumes.total,
        size: action.volumes.size,
        sort: action.volumes.sort,
        direction: action.volumes.direction.toLowerCase(),
      });
    default:
      return state;
  }
}

// === Action Creators ===
function requestDataVolumeSettings() {
  return {
    type: REQUEST_DATA_VOLUME_SETTINGS,
  };
}

function receiveDataVolumeSettings(volumes) {
  return {
    type: RECEIVE_DATA_VOLUME_SETTINGS,
    volumes,
  };
}

// === Side Effects ===
export function fetchDataVolumeSettings(
  page = 1,
  sort = "name",
  direction = "asc",
  size = window.localStorage.getItem("numberOfElementsDataSourceOnPrem")
    ? Number(window.localStorage.getItem("numberOfElementsDataSourceOnPrem"))
    : 10
) {
  const filterType = ["crawl.files", "NetworkVolume"];
  window.localStorage.setItem("numberOfElementsDataSourceOnPrem", size);
  return (dispatch, getState) => {
    dispatch(requestDataVolumeSettings());
    var url = `/volumeSettings?page=${page}&sort=${sort},${direction}&size=${size}`;
    filterType.forEach((filter) => (url += `&filterType=${filter}`));
    return fetchWithAuth(dispatch, url, getState().auth.accessToken).then(
      (json) => {
        dispatch(receiveDataVolumeSettings(json));
      }
    ).catch(err => console.error(err));
  };
}

export function addVolumeSetting(name, path, serverId, editStoragePriceId) {
  return (dispatch, getState) => {
    const body = {
      name,
      path,
      type: "crawl.files",
      server: { id: serverId },
      storagePrice: { id: editStoragePriceId },
    };
    const url = `/volumeSettings`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "POST",
      JSON.stringify(body)
    )
      .then((response) => {
        if (response.status >= 400) {
          throw response;
        }
        dispatch(
          fetchDataVolumeSettings(
            1,
            getState().dataVolumeSettings.sort,
            getState().dataVolumeSettings.direction,
            getState().dataVolumeSettings.size
          )
        );
        dispatch(fetchVolumeSettings());
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Unable to create volume for name [${name}], path [${path}] and serverId [${serverId}] : ${error.title}`,
            "danger"
          )
        );
      });
  };
}

export function updateVolumeSetting(
  id,
  name,
  path,
  serverId,
  editStoragePriceId,
  ignoredPaths,
  scanType,
  indexFolderOnlyPaths
) {
  return (dispatch, getState) => {
    const body = {
      id,
      name,
      path,
      type: "crawl.files",
      server: { id: serverId },
      storagePrice: { id: editStoragePriceId },
      volumeIgnoreRules: ignoredPaths.map((path) => ({ path })),
      scanType,
      volumeIndexFolderOnlyPaths: indexFolderOnlyPaths.map((path) => ({ path }))
    };

    const url = `/volumeSettings/${id}`;
    return fetchWithAuth(
      dispatch,
      url,
      getState().auth.accessToken,
      "PUT",
      JSON.stringify(body)
    )
      .then((response) => {
        dispatch(
          fetchDataVolumeSettings(
            1,
            getState().dataVolumeSettings.sort,
            getState().dataVolumeSettings.direction,
            getState().dataVolumeSettings.size
          )
        );
        dispatch(fetchVolumeSettings());
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Unable to update volume for id [${id}], name [${name}], path [${path}] and serverId [${serverId}]: ${error.title}`,
            "danger"
          )
        );
      });
  };
}

export function deleteVolumeSetting(id) {
  return (dispatch, getState) => {
    return fetchWithAuth(
      dispatch,
      `/volumeSettings/${id}`,
      getState().auth.accessToken,
      "DELETE"
    )
      .then((response) => {
        dispatch(
          fetchDataVolumeSettings(
            1,
            getState().dataVolumeSettings.sort,
            getState().dataVolumeSettings.direction,
            getState().dataVolumeSettings.size
          )
        );
        dispatch(fetchVolumeSettings());
      })
      .catch((error) => {
        const message =
          error.message || `Unable to delete volume for id [${id}]`;
        dispatch(setAlert(message, "danger"));
      });
  };
}
