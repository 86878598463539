import React, { useState } from "react";
import TableResult from "../../components/TableResult";
import ConfirmModal from "../../components/ConfirmModal";
import { Button, FormGroup, Input, FormFeedback } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

function StoragePriceList(props) {
  const {
    storagePrices,
    errors,
    createStoragePriceSetting,
    updateStoragePriceSetting,
    deleteStoragePriceSetting,
  } = props;
  const [editId, setEditId] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [editCurrencySymbol, setEditCurrencySymbol] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newCurrencySymbol, setNewCurrencySymbol] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [deleteId, setDeleteId] = useState(null);

  const fileTitles = [
    { name: "Id" },
    { name: "Name" },
    { name: "Currency Symbol" },
    { name: "Price (GB/Year)" },
    { name: "Action" },
  ];

  const editLine = (id) => {
    const storagePriceToUpdate = storagePrices.find(
      (storagePrice) => storagePrice.id === id
    );
    setEditId(id);
    setEditDescription(storagePriceToUpdate.description);
    setEditCurrencySymbol(storagePriceToUpdate.currencySymbol);
    setEditPrice(storagePriceToUpdate.price);
  };

  const listFormated = storagePrices.map((storagePrice) => {
    const lineObject = {
      columnData: [],
      style: {},
      options: {},
    };
    if (editId === storagePrice.id) {
      lineObject.columnData = [
        storagePrice.id,
        <FormGroup>
          <Input
            type="text"
            value={editDescription}
            placeholder="Ex: HDD"
            onChange={(e) => setEditDescription(e.target.value)}
            invalid={editDescription === ""}
          />
          <FormFeedback>Description must not be blank</FormFeedback>
        </FormGroup>,
        <FormGroup>
          <Input
            type="text"
            value={editCurrencySymbol}
            placeholder="Ex: $"
            onChange={(e) => setEditCurrencySymbol(e.target.value)}
            invalid={editCurrencySymbol === ""}
          />
          <FormFeedback>Currency symbol must not be blank</FormFeedback>
        </FormGroup>,
        <FormGroup>
          <Input
            type="number"
            step={0.1}
            value={editPrice}
            placeholder="Ex: 0.35"
            onChange={(e) => setEditPrice(e.target.value)}
            invalid={editPrice === "" || editPrice < 0}
          />
          <FormFeedback>Price must be a positive number</FormFeedback>
        </FormGroup>,
        <span>
          <Button
            disabled={
              editDescription === "" ||
              editCurrencySymbol === "" ||
              editPrice === "" ||
              editPrice < 0
                ? true
                : false
            }
            onClick={() => {
              updateStoragePriceSetting(
                editId,
                editDescription,
                editCurrencySymbol,
                editPrice
              );
              setEditId(null);
            }}
            color="primary"
          >
            Update
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={() => setEditId(null)}>
            Cancel
          </Button>
        </span>,
      ];
    } else {
      lineObject.columnData = [
        storagePrice.id,
        storagePrice.description,
        storagePrice.currencySymbol,
        storagePrice.price,
        <span>
          <Button onClick={() => editLine(storagePrice.id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            onClick={() => {
              setDeleteId(storagePrice.id);
              setModalMessage(
                `Are you sure you want to delete storage price ${storagePrice.description}?`
              );
              setShowConfirmation(true);
            }}
            color="danger"
            style={{ marginLeft: 10 }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </span>,
      ];
    }

    return lineObject;
  });

  listFormated.push({
    columnData: [
      "Add new storage type",
      <FormGroup>
        <Input
          type="text"
          value={newDescription}
          placeholder="Ex: HDD"
          onChange={(e) => setNewDescription(e.target.value)}
          invalid={errors["description"] !== undefined}
        />
        <FormFeedback>{errors["description"]}</FormFeedback>
      </FormGroup>,
      <FormGroup>
        <Input
          type="text"
          value={newCurrencySymbol}
          placeholder="Ex: $"
          onChange={(e) => setNewCurrencySymbol(e.target.value)}
          invalid={errors["currencySymbol"] !== undefined}
        />
        <FormFeedback>{errors["currencySymbol"]}</FormFeedback>
      </FormGroup>,
      <FormGroup>
        <Input
          type="number"
          step={0.1}
          value={newPrice}
          placeholder="Ex: 0.35"
          onChange={(e) => setNewPrice(e.target.value)}
          invalid={errors["price"] !== undefined}
        />
        <FormFeedback>{errors["price"]}</FormFeedback>
      </FormGroup>,
      <Button
        onClick={() => {
          createStoragePriceSetting(
            newDescription,
            newCurrencySymbol,
            newPrice
          );
          setNewDescription("");
          setNewCurrencySymbol("");
          setNewPrice("");
        }}
        color="success"
      >
        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
      </Button>,
    ],
    style: {},
    options: {},
  });

  return (
    <>
      <TableResult title={"Files"} data={listFormated} titles={fileTitles} />
      <ConfirmModal
        showConfirmation={showConfirmation}
        title="Delete Confirmation"
        closeConfirmation={() => {
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
        }}
        buttonColor="danger"
        buttonText="Delete"
        confirmAction={() => {
          setDeleteId(null);
          setModalMessage("");
          setShowConfirmation(false);
          deleteStoragePriceSetting(deleteId);
        }}
      >
        {modalMessage}
      </ConfirmModal>
    </>
  );
}

export default StoragePriceList;
